export const NOTIFICATIONS = [
  'O gestor estadual $GESTOR fez um comentário',
  'O Gestor estadual $GESTOR anexou um arquivo',
  'O Gestor municipal $GESTOR fez um comentário',
  'O Gestor municipal $GESTOR anexou um arquivo',
  'O Gestor municipal $GESTOR criou o plano de ação $PLANO_ACAO',
  'O Gestor municipal $GESTOR anexou um arquivo no plano de ação $PLANO_ACAO',
  'O Gestor municipal $GESTOR bloqueou  plano de ação $PLANO_ACAO',
  'O status do plano de ação $PLANO_ACAO foi alterado para concluído',
  'O status do plano de ação $PLANO_ACAO foi alterado para concluído com atraso',
  'O status do plano de ação $PLANO_ACAO foi alterado para não concluído',
  'O Gestor estadual $GESTOR respondeu o seu comentário',
  'O Gestor municipal $GESTOR respondeu o seu comentário',
  'O Gestor estadual $GESTOR anexou um arquivo na resposta',
  'O Gestor municipal $GESTOR anexou um arquivo na resposta'
];
