import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Content = styled.div`
  article {
    h1 {
      color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.green600)};
    }

    span.tag {
      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#4e7345`)};
      font-weight: 700;
      font-size: 1rem;

      text-transform: uppercase;
    }

    p {
      margin-top: 27px;

      font-size: 1rem;
      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#2d3436`)};
    }
  }

  .table-wrapper {
    min-height: 520px;
    overflow-x: auto;
    margin-bottom: 20px;

    .czrm-table {
      width: 100%;
      height: 100%;
      border-collapse: separate;
      text-align: center;
      border-spacing: 4px;
      overflow-x: auto;

      th,
      td {
        border-radius: 7px;
        background: ${({ theme }) => (theme.darkMode ? `#000` : `#F7FAFC`)};
        padding: 8px;
        font-size: 0.875rem; //14px
        width: 25%;
        max-height: 40px;

        &:first-of-type {
          width: 30%;
        }
      }

      th {
        border: 1px solid ${({ theme }) => (theme.darkMode ? `#fff` : 'none')};
        font-weight: bold;
        color: ${({ theme }) => (theme.darkMode ? `#fff` : `#2D4040`)};
        text-align: center;
      }

      tbody tr {
        transition: filter 0.3s;

        &.redirect-cell {
          cursor: pointer;
        }

        &:hover {
          filter: brightness(0.9);
        }
      }

      td {
        color: ${({ theme }) => (theme.darkMode ? `#fff` : `#475569`)};

        a {
          flex: 1;
          width: inherit;
          height: inherit;
          color: inherit;
        }

        &.czrm-cell-share {
          .czrm-cell-share-icon {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  .czrm-input-search {
    border-radius: 7px;
    border: 1px solid #dfe6e9;
    margin: 20px 0;
    position: relative;

    svg {
      fill: #4e7345;
    }

    &.ant-input-search {
      input {
        color: ${({ theme }) => theme.text};
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .ant-input-group-addon {
      border-radius: 7px;
    }
    .ant-btn {
      border: 0;
      background: transparent;
    }
  }

  ${mediaQuery.smallScreen} {
    margin: 0;
    width: 100%;
    gap: 0;
    flex-direction: column;

    .czrm-input-search {
      height: max-content;
      width: 100%;
    }
  }
`;
