import { Data } from 'big-data';
import { getQuery } from '../config/utils';
import { baseRequest, baseResource } from './baseResource';

const service = () => {
  const resource = baseResource;

  async function getNames(ibge?: string): Promise<Data[]> {
    const aggregations = ['NOME_ORGANIZACAO'];

    const data = await baseRequest<{
      nome_organizacao: string;
      quantidade: number;
    }>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.nome_organizacao,
      value: d.quantidade
    }));
  }

  async function getCities(ibge?: string) {
    const path = `${resource}/municipios-organizacoes`;

    const data = await baseRequest<any>(path, {
      ibge
    });

    return data.map((d) => ({
      name: d.regiao_organizacao,
      value: d.quantidade,
      codIbge: d.ibge_organizacao,
      city: d.municipio_organizacao
    }));
  }

  async function getLevels(ibge?: string) {
    const path = `${resource}/nivel-organizacao`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.nivel_organizacao,
      value: d.quantidade
    }));
  }

  async function getTypes(ibge?: string) {
    const aggregations = ['TIPO_ORGANIZACAO'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.tipo_organizacao,
      value: d.porcentagem
    }));
  }

  async function getHasAwards(ibge?: string) {
    const aggregations = ['RECEBEU_PREMIO'];

    const data = await baseRequest<any>(resource, {
      aggregations: getQuery(aggregations),
      ibge
    });

    return data.map((d) => ({
      name: d.recebeu_premio,
      value: d.quantidade
    }));
  }

  async function getAwardNames(ibge?: string) {
    const aggregations = ['QUAL_PREMIO'];

    const data = await baseRequest<any>(resource, {
      aggregations: getQuery(aggregations),
      ibge
    });

    return data.map((d) => {
      const name = d.qual_premio ?? 'Outros';

      return {
        name,
        value: d.quantidade
      };
    });
  }

  async function getInitiatives(ibge?: string) {
    const path = `${resource}/iniciativas-organizacoes`;

    const data = await baseRequest<any>(path, {
      ibge
    });

    return data.map((d) => ({
      name: d.iniciativas_organizacoes,
      value: d.quantidade
    }));
  }

  async function getInitiativeImpacts(ibge?: string) {
    const path = `${resource}/impactos-organizacoes`;

    const data = await baseRequest<any>(path, {
      ibge
    });

    return data.map((d) => ({
      name: d.impactos_iniciativas_organizacoes,
      value: d.quantidade
    }));
  }

  async function getLinkedOrgs(ibge?: string) {
    const path = `${resource}/organizacao-ligada`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.organizacao_ligada,
      value: d.quantidade
    }));
  }

  async function getActingArea(ibge?: string) {
    const path = `${resource}/area-atuacao-organizacao`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.area_atuacao,
      value: d.quantidade
    }));
  }

  async function getTotalInterviews(ibge?: string) {

    const data = await baseRequest<any>(resource, {
      aggregations: getQuery(['']),
      ibge
    });

    return data.map((d) => ({
      value: d.quantidade,
      name: ''
    }));
  }

  return {
    getNames,
    getCities,
    getLevels,
    getTypes,
    getHasAwards,
    getAwardNames,
    getInitiatives,
    getInitiativeImpacts,
    getLinkedOrgs,
    getActingArea,
    getTotalInterviews
  };
};

export const organizationsService = service();
