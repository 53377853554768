import styled from 'styled-components';

import { CardTagged, ServiceBox } from '@/components/shared';
import { TitleText, Text, ColorText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantData } from '@/servicesHooks/profileSearch';
import { colors } from '@/theme/colors';
import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';

export function CardPregant() {
  const { tag } = useDashboardFilter();

  const { pregnantData, pregnantError, pregnantLoading } = usePregnantData();
  const {
    pregnantData: pregnantWithAgeGroup,
    pregnantError: pregnantWithAgeGroupError,
    pregnantLoading: pregnantWithAgeGroupLoading
  } = usePregnantData(true);

  const total = sumDataTotal(pregnantData);
  const getYesData = pregnantData.filter((data) => data.name === 'Sim');
  const yesTotal = sumDataTotal(getYesData);
  const percentage = percentageFormatter((yesTotal / total) * 100);

  const dataAgeGroupTotal = sumDataTotal(pregnantWithAgeGroup);
  const dataAgeGroupPercentage = pregnantWithAgeGroup
    .map((data) => {
      return {
        ...data,
        percentage: percentageFormatter((data.value / dataAgeGroupTotal) * 100)
      };
    })
    .sort((a, b) => {
      return b.value - a.value;
    });

  return (
    <CardTagged
      tag={tag}
      title="Grávidas por domicílio visitado
    "
    >
      <Box>
        <div>
          <ServiceBox
            empty={pregnantData.length === 0}
            loading={pregnantLoading}
            error={pregnantError}
          >
            <Text>
              Das famílias em situação de vulnerabilidade social entrevistadas,{' '}
              <ColorText>{percentage}</ColorText> dos domicílios possuem uma
              mulher grávida.
            </Text>
          </ServiceBox>
        </div>

        <div>
          <ServiceBox
            empty={pregnantWithAgeGroup.length === 0}
            loading={pregnantWithAgeGroupLoading}
            error={pregnantWithAgeGroupError}
          >
            <TitleText>
              Média de idade das grávidas nos domicílios visitados
            </TitleText>

            {dataAgeGroupPercentage.map((data) => (
              <Content key={data.name}>
                <Text className="content-percentage">{data.percentage}</Text>
                <Text>{data.name}.</Text>
              </Content>
            ))}
          </ServiceBox>
        </div>
      </Box>
    </CardTagged>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div:last-child {
    margin-top: 40px;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 10px;

  .content-percentage {
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
    font-weight: bold;
    font-size: 1.8rem;
  }

  & + div {
    margin-top: 10px;
  }
`;
