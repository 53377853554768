import { ReactNode } from 'react';
import { Loading } from '../Loading';
import { Show } from '../Show';
import { ErrorBox, LoadingBox, EmptyBox } from './styles';

interface ServiceBoxProps {
  empty: boolean;
  error: Error | null;
  children: ReactNode;
  loading?: boolean;
}

export function ServiceBox({
  empty,
  error,
  children,
  loading
}: ServiceBoxProps) {
  function renderError() {
    return (
      <ErrorBox>
        <p>Não foi possível carregar a informação</p>
        <p>{error?.message}</p>
      </ErrorBox>
    );
  }

  function renderLoading() {
    return (
      <LoadingBox>
        <Loading />
      </LoadingBox>
    );
  }

  function renderEmpty() {
    return (
      <EmptyBox>
        <p>Não existem informações para serem visualizadas</p>
      </EmptyBox>
    );
  }

  return (
    <Show when={!loading} fallback={renderLoading()}>
      <Show when={!empty} fallback={renderEmpty()}>
        <Show when={error === null} fallback={renderError()}>
          {children}
        </Show>
      </Show>
    </Show>
  );
}
