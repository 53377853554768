import { colors } from '@/theme/colors';
import styled from 'styled-components';

interface FeedbackButtonProps {
  width?: string;
}

export const FeedbackButton = styled.button<FeedbackButtonProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 300px;
  max-height: 40px;
  width: ${({ width }) => width ?? `100%`};
  border: 0;

  padding: 10px;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;

  background: ${colors.ciano700};
  border-radius: 4px;
  transition: filter 0.2s;

  &:hover:not(:disabled) {
    filter: brightness(0.7);
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;
