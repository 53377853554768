import styled from 'styled-components';

import { PageSection } from '@/layout';
import { mediaQuery } from '@/theme/mediaQuery';

export const Wrapper = styled((props) => <PageSection {...props} />)`
  display: flex;
  padding: 150px 0 50px 0;
  text-align: center;
  ${mediaQuery.mediumScreen} {
    position: relative;
    z-index: 1;
  }
`;

export const Columns = styled.div<{ visible: boolean }>`
  flex: 2;
  padding: 0 20px 30px 20px;
  .rect-effect {
    transform: ${(props) => !props.visible && `translate(30px,0)`};
  }
  ${mediaQuery.smallScreen} {
    padding-left: 30px;
  }
`;

export const Grid = styled.div`
  margin-top: 60px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 80px;
  justify-content: center;
  justify-items: start;
  padding-top: 20px;
  & > div {
    padding: 20px;
  }
  ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr;
    & > div {
      padding: 10px;
      .rect-effect {
        margin-left: 7px;
        margin-top: 12px;
        width: 5px;
        height: 50px;
      }
    }
  }
`;
