import { getQuery } from '../config/utils';
import { baseRequest, baseResource } from './baseResource';

const service = () => {
  const resource = baseResource;

  async function getCities(ibge?: string) {
    const path = `${resource}/municipios-liderancas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.regiao_lideranca,
      city: d.municipio_liderancas,
      value: d.quantidade,
      codIbge: d.ibge_lideranca
    }));
  }

  async function getImpairmentNames(ibge?: string) {
    const aggregations = ['QUAL_DEFICIENCIA_LIDERANCA'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.qual_deficiencia_lideranca ?? 'Outras',
      value: d.porcentagem
    }));
  }

  async function getFunctionNames(ibge?: string) {
    const aggregations = ['FUNCAO_LIDERANCA'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.funcao_lideranca,
      value: d.porcentagem
    }));
  }

  async function getAgeGroup(ibge?: string) {
    const aggregations = ['LIDERANCA_FAIXA_ETARIA'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.lideranca_faixa_etaria,
      value: d.quantidade
    }));
  }

  return {
    getAgeGroup,
    getCities,
    getImpairmentNames,
    getFunctionNames
  };
};

export const leaderProfileService = service();
