import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQuery } from '@/theme/mediaQuery';

import cearaDarkModeLogo from '@/assets/logos/ceara-darkmode.svg';
import socialLogo from '@/assets/logos/logo-social.svg';

export function Logo() {
  return (
    <Wrapper>
      <LogoSocial
        to="/"
        data-test="big-data-logo"
        aria-label="Ir para página inicial"
      />
      <LogoCeara aria-label="Governo do ceará logo" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;

  ${mediaQuery.mediumScreen} {
    justify-content: center;
  }

  ${mediaQuery.smallScreen} {
    justify-content: center;
  }
`;

const LogoSocial = styled((props) => <Link {...props} />)`
  margin-right: 40px;
  width: 180px;
  height: 70px;
  background: url(${socialLogo}) no-repeat center;
  background-size: 180px;
  &:hover {
    opacity: 0.9;
    &:active {
      opacity: 1;
    }
  }

  ${mediaQuery.smallScreen} {
    width: 100%;
    margin-right: 0;
    background-size: 110px;
    background-position: center;
  }
`;

const LogoCeara = styled.div`
  width: 220px;
  height: 70px;
  background: url(${cearaDarkModeLogo}) no-repeat center;
  background-position: center 70%;
  background-size: 220px;
  ${mediaQuery.smallScreen} {
    width: 100%;
    background-size: 150px;
    background-position: center;
  }
`;
