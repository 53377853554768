import { colors } from '@/theme/colors';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Pagination, Show } from '@/components/shared';
import { FeedbackSkeletons } from '../FeedbackSkeletons';
import { FeedbackStatusBox } from '@/components/shared/FeedbackStatusBox';
import { mediaQuery } from '@/theme/mediaQuery';
import { PaginationProps } from '@/components/shared/Pagination';

interface FeedbackCardProps {
  title: string;
  question?: ReactNode;
  children: ReactNode;
  pagination?: PaginationProps;
  status?: {
    error?: Error | null;
    loading?: boolean;
    empty?: boolean;
  };
}

export function FeedbackCard({
  title,
  children,
  question,
  pagination,
  status
}: FeedbackCardProps) {
  return (
    <Box>
      <header>
        <h1>{title}</h1>
      </header>

      <main>
        {!!question && <strong className="question">{question}</strong>}

        <div className="content">
          <Show when={!!status?.loading}>
            <FeedbackSkeletons />
          </Show>

          <Show when={!!status?.error}>
            <FeedbackStatusBox type="error" />
          </Show>

          <Show when={!!status?.empty && !status?.loading}>
            <p>Não existem dados para serem visualizados</p>
          </Show>

          <Show when={!status?.loading && !status?.empty && !status?.error}>
            {children}
          </Show>
        </div>
      </main>

      {!!pagination && !status?.error && !status?.empty && (
        <footer>
          <Pagination
            onPaginate={pagination.onPaginate}
            page={pagination.page}
            totalPages={pagination.totalPages}
            resetOnChange={pagination.resetOnChange}
          />
        </footer>
      )}
    </Box>
  );
}

const Box = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#000' : '#ffffff')};
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  padding-bottom: 20px;

  header,
  main {
    padding: 15px 25px;
  }

  header {
    border-bottom: 1px solid #dfe6e9;

    h1 {
      color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }

  main {
    .question {
      font-size: 1rem;
      font-weight: 700;
      line-height: 140%;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .card-service-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  ${mediaQuery.mediumScreen} {
    .card-service-box {
      flex-direction: column;
    }
  }

  ${mediaQuery.smallScreen} {
    .card-service-box {
      flex-direction: column;
    }
  }
`;
