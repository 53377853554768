import styled, { css } from 'styled-components';

interface LabelMainProps {
  align?: 'left' | 'right';
}

interface LabelProps {
  selected?: boolean;
  filterCity?: boolean;
}

export const LabelMain = styled.div.attrs({
  className: `label-main`
})<LabelMainProps>`
  position: absolute;
  bottom: 0;
  ${({ align }) => (align === 'left' ? 'left: 0' : 'right: 0')};
`;

export const Label = styled.p<LabelProps>`
  transition: visibility 0.1s, opacity 0.5s, transform 0.8s;
  opacity: ${({ selected, filterCity }) => (selected || filterCity ? 1 : 0)};
  visibility: ${({ selected, filterCity }) =>
    selected || filterCity ? 'visible' : 'hidden'};
  transform: ${({ selected, filterCity }) =>
    `translateY(${!selected || filterCity ? '5px' : '10px'})`};

  span {
    display: block;
    text-align: right;

    &.value {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 21px;

      letter-spacing: 0.02em;
    }

    &.label {
      font-size: 1rem;
    }
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      span {
        &.value,
        &.label {
          color: #fff;
        }
      }
    `}
`;
