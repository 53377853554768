import { SenderButton, Box } from './styles';
import feedbackSenderImg from '@/assets/icons/feedback-sender.svg';
import { FeedbackBox } from './components/FeedbackBox';
import { useState } from 'react';

export function FeedbackWidget() {
  const [clicked, setClicked] = useState(false);

  return (
    <Box>
      {clicked && <FeedbackBox onClose={() => setClicked(false)} />}

      <SenderButton
        aria-label="Abrir feedback"
        title="Abrir feedback"
        onClick={() => setClicked(true)}
      >
        <img src={feedbackSenderImg} alt="Enviar Feedback" />
      </SenderButton>
    </Box>
  );
}
