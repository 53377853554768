import { Manager } from 'big-data';
import { mainApi } from '../config/api';
import { callService } from '../config/service';

const service = () => {
  const resource = `/manager/sign-term`;

  async function post() {
    const path = resource;
    await callService(() => mainApi.post<Manager>(path));
  }

  return { post };
};

export const signTermManager = service();
