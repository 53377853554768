import { useDashboardFilter } from '@/hooks';
import { socialAssistance } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useBenefitsData(name?: string) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    if (name) {
      setLoading(true);
      socialAssistance
        .getBenefits(name, ibge, year, semester, zone)
        .then((data) => {
          setData(data);
        })
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }
  }, [name, ibge, year, semester, zone]);

  return useMemo(
    () => ({
      benefits: data,
      loadingBenefits: loading,
      errorBenefits: error
    }),
    [data, loading, error]
  );
}
