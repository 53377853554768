import styled from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';

interface LegendItem {
  title: string;
  color: string;
}

interface Props {
  items: LegendItem[];
}

export const LegendPanel = ({ items }: Props) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      {items.map((i) => (
        <Row key={`${i.color}${i.title}`}>
          <Label>{i.title}</Label>
          <GridCell color={i.color} />
        </Row>
      ))}
    </Wrapper>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2px;
  color: ${(props) => (props.theme.darkMode ? `#fffd` : `#2d4040cc`)};
`;

const Label = styled.span`
  white-space: nowrap;
  font-size: 1.1rem;
`;

const GridCell = styled.div<{ color: string }>`
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 5px;
  background: ${(props) => props.color};
  border-radius: 4px;
  ${mediaQuery.smallScreen} {
    display: flex;
    width: 100%;
    margin-right: 0;
    width: 1rem;
    height: 1rem;
    order: -1;
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -20px;
  right: 0;
  padding: 20px 0;

  ${mediaQuery.mediumScreen} {
    right: 10px;
    padding: 20px 0;
  }
  ${mediaQuery.smallScreen} {
    right: 0;
    padding: 20px 0 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
