import * as d3 from 'd3';
import { Root } from './Arcs';

type D3Svg = d3.Selection<d3.BaseType, unknown, HTMLElement, any>;

export const getXScale = () => d3.scaleLinear().range([0, 2 * Math.PI]);

export const getYScale = (radius: number) => d3.scaleSqrt().range([0, radius]);

export const getRadius = (svg: D3Svg) => {
  const width = Number(svg.attr('width'));
  const height = Number(svg.attr('height'));

  return Math.max(width, height) / 2;
};

export const getArcFunction = <T>(radius: number) => {
  const xScale = getXScale();
  const yScale = getYScale(radius);

  return d3
    .arc<d3.HierarchyRectangularNode<T>>()
    .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, xScale(d.x0))))
    .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, xScale(d.x1))))
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 4)
    .innerRadius((d) => yScale(d.y0))
    .outerRadius((d) => {
      return yScale(d.y1);
    });
};

export const getArcFunction2 = (radius: number) => {
  const xScale = getXScale();
  const yScale = getYScale(radius);

  return d3
    .arc<Root>()
    .startAngle((d) => Math.max(0, Math.min(2 * Math.PI, xScale(d.x0))))
    .endAngle((d) => Math.max(0, Math.min(2 * Math.PI, xScale(d.x1))))
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 4)
    .innerRadius((d) => yScale(d.y0))
    .outerRadius((d) => {
      return yScale(d.y1);
    });
};

export const isSameNode = (node?: Root, current?: Root) => {
  return node?.data.name === current?.data.name;
};
