import styled, { keyframes } from 'styled-components';

const FadeIn = (x: number, y: number) => keyframes`
  0% {
    transform: translate(${x}px, ${y}px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const Delay = (ms?: number) => `${ms ?? 0}s`;

interface AnimatedBoxProps {
  x?: number;
  y?: number;
  delay?: number;
}

export const AnimatedBox = styled.div<AnimatedBoxProps>`
  opacity: 0;
  height: 100%;
  width: 100%;
  animation: ${({ x, y }) => FadeIn(x ?? 0, y ?? 0)} 0.5s
    ${({ delay }) => Delay(delay)} forwards;
`;
