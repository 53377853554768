import styled from 'styled-components';

export const Box = styled.div<{ gridTemplateAreas: string }>`
  display: grid;
  gap: 1rem;

  .map-content,
  .donut-content {
    margin-top: 8rem;
  }

  grid-template-columns: repeat(4, minmax(250px, 1fr));

  grid-template-areas: ${(props) => props.gridTemplateAreas};

  .interview-map {
    grid-area: map;
  }

  .interview {
    grid-area: interview;
  }

  .interview-goal {
    grid-area: goal;
  }

  .interview-city {
    grid-area: city;

    .interview-city-text {
      margin-bottom: 1rem;
    }
  }

  .interview-profile {
    grid-area: profile;
  }

  .outdoor-box {
    display: grid;
    grid-gap: 1rem;

    margin-top: 3rem;

    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .qualification-course {
    grid-area: q-course;
  }

  .pregnant {
    grid-area: pregnant;
  }

  .ranking {
    grid-area: ranking;
  }

  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;

    .map-content {
      margin-top: 0;
    }

    .donut-content {
      margin-top: 5rem;
    }

    .outdoor-box {
      margin-top: 0;
    }

    grid-template-areas:
      'map map'
      'interview goal'
      'city city'
      'profile profile'
      'q-course q-course'
      'pregnant ranking';
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;

    .donut-content {
      margin-top: 0;
    }

    .outdoor-box {
      grid-template-columns: 1fr;
    }

    grid-template-areas:
      'map'
      'interview'
      'goal'
      'city'
      'profile'
      'q-course'
      'pregnant'
      'ranking';
  }
`;
