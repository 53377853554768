import { colors } from '@/theme/colors';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

const darkModeBox = css`
  .heading {
    h1 {
      color: #fff;
    }
  }

  .button-group {
    button {
      background: #000;
      border: 1px solid ${colors.yellow500};

      span {
        color: #fff;
      }

      &:hover {
        background: ${darken(0.1, colors.yellow500)};
      }
    }
  }
`;

export const Box = styled.div`
  max-width: 336px;
  min-height: 264px;
  width: 100%;
  height: 100%;
  position: relative;

  background: ${({ theme }) => (theme.darkMode ? `#000` : `#ffffff`)};
  border: 1px solid #dfe6e9;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 16px;

  .close {
    border: 0;
    background: transparent;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h1 {
      font-weight: 700;
      color: ${colors.gray700};
      font-size: 1.25rem; // 20px
      text-align: center;
      flex: 1;
    }

    .back {
      border: 0;
      background: transparent;
    }
  }

  .button-group {
    margin: 32px 0;

    display: flex;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 112px;
      border: 0;
      border-radius: 8px;
      background: #f4f4f5;
      transition: background 0.5s;

      span {
        color: ${colors.gray700};
        font-weight: 700;
        line-height: 24px;
        font-size: 0.875rem;
      }

      & + button {
        margin-left: 8px;
      }

      &:hover {
        background: ${darken(0.1, '#f4f4f5')};
      }
    }
  }

  ${({ theme }) => theme.darkMode && darkModeBox};
`;
