import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const CommentContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  & + div {
    margin-top: 40px;
  }

  .comment-container-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
  }
`;

export const CommentAvatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;

  background: ${colors.green200};

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: #ffffff;
`;

export const CommentCard = styled.div`
  width: 100%;
  background: ${({ theme }) => (theme.darkMode ? `#000` : `#f7fafc`)};
  border: 1px solid #f7fafc;
  border-radius: 7px;

  text-align: left;

  padding: 13px 19px;

  p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.green700)};
  }
`;

export const CommentFile = styled.a`
  background: ${({ theme }) => (theme.darkMode ? `#000` : `#f7fafc`)};
  border: 1px solid #f7fafc;

  border-radius: 7px;
  padding: 10px;

  width: max-content;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.875rem;

  display: flex;
  align-items: center;
  text-decoration-line: underline;
  gap: 4px;

  color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.green700)};

  ${mediaQuery.smallScreen} {
    max-width: 200px;
    width: 100%;
  }
`;

export const CommentAuthor = styled.span`
  font-weight: 700;
  font-size: 0.75rem;
  font-style: italic;
  line-height: 0.875rem;

  color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.green700)};
`;
