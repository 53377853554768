import { useEffect } from 'react';
import { Data } from 'big-data';
import { Card } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
interface WaterOutFlowProps {
  color?: string;
}

export function WaterOutFlow({ color }: WaterOutFlowProps) {
  const { data, loading, request } = useService<Data>();
  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getWaterOutFlow(city, zone));
  }, [request, city, zone]);

  return (
    <Section>
      <Card
        title="Tipo de escoamento sanitário das moradias"
        empty={data.length === 0}
        loading={loading}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <HorizontalBarSimple data={data} isPercentage color={color} sizeRatio={0.7} maxValue={5} />
      </Card>
    </Section>
  );
}
