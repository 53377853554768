import { AxeEnum } from '@/constants/axes';
import {
  EducationEnum,
  HealthEnum,
  InsecurityFoodEnum,
  LivingConditionEnum,
  SocialAssistanceEnum,
  WorkEnum
} from '@/services';

interface Options {
  label: string;
  value: string;
}

export const INDICATORS_OPTIONS: Record<string, Options[]> = {
  [AxeEnum.INSEGURANCA_ALIMENTAR]: [
    {
      value: InsecurityFoodEnum.FORA_DE_INSEGURANCA_ALIMENTAR,
      label: 'Fora de Insegurança Alimentar'
    },
    {
      value: InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_LEVE,
      label: 'Insegurança Alimentar Leve'
    },
    {
      value: InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_MODERADA,
      label: 'Insegurança Alimentar Moderada'
    },
    {
      value: InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_GRAVE,
      label: 'Insegurança Alimentar Grave'
    }
  ],
  [AxeEnum.EDUCACAO]: [
    {
      value: EducationEnum.PRE_ESCOLA,
      label: 'Crianças não matriculadas em pré-escola'
    },
    {
      value: EducationEnum.ESCOLA,
      label: 'Crianças não matriculadas em escola pública'
    },
    {
      value: EducationEnum.CRECHE,
      label: 'Crianças não matriculadas em creche'
    }
  ],
  [AxeEnum.ASSISTENCIA_SOCIAL]: [
    {
      value: SocialAssistanceEnum.CRAS,
      label: 'Famílias não acompanhadas pelo CRAS'
    },
    {
      value: SocialAssistanceEnum.CREAS,
      label: 'Famílias não acompanhadas pelo CREAS'
    }
  ],
  [AxeEnum.SAUDE]: [
    {
      value: HealthEnum.GESTANTE_PRE_NATAL,
      label: 'Gestantes sem acompanhamento pré-natal'
    },
    {
      value: HealthEnum.ACOMPANHAMENTO_CRIANCA,
      label: 'Crianças sem acompanhamento de equipe de saúde'
    },
    {
      value: HealthEnum.CRIANCA_VACINA_EM_DIA,
      label: 'Crianças com esquema vacinal incompleto'
    }
  ],
  [AxeEnum.TRABALHO_RENDA]: [
    {
      value: WorkEnum.TRABALHO_REMUNERADO,
      label: 'Representantes familiares sem trabalho remunerado'
    },
    {
      value: WorkEnum.SALARIO_MINIMO,
      label: 'Representantes familiares que possuem até meio salário mínimo'
    },
    {
      value: WorkEnum.FAZ_CURSO,
      label:
        'Representantes familiares que não realizaram cursos de capacitação'
    },
    {
      value: WorkEnum.QUER_FAZER_CURSO,
      label:
        'Representantes familiares que têm interesse em realizar curso de capacitação'
    },
    {
      value: WorkEnum.RENDA,
      label:
        'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância'
    }
  ],
  [AxeEnum.MORADIA]: [
    {
      value: LivingConditionEnum.AGUA_CANALIZADA,
      label: 'Famílias que não possuem água canalizada em pelo menos 1 cômodo'
    },
    {
      value: LivingConditionEnum.BANHEIRO,
      label: 'Famílias que não possuem banheiro em suas residências'
    },
    {
      value: LivingConditionEnum.COLETA_SELETIVA,
      label: 'Famílias que não possuem acesso à coleta seletiva'
    },
    {
      value: LivingConditionEnum.LUGARES_PUBLICOS_CRIANCA_BRINCAR,
      label:
        'Famílias que não possuem lugares públicos para brincar próximo às residências'
    },
    {
      value: LivingConditionEnum.ATIVIDADE_CULTURAL,
      label:
        'Famílias que não possuem atividades culturais próximo às residências'
    },
    {
      value: LivingConditionEnum.ATIVIDADE_ESPORTIVA,
      label:
        'Famílias que não possuem atividades esportivas próximo às residências'
    },
    {
      value: LivingConditionEnum.AREA_CONFLITO,
      label: 'Famílias que estão localizadas em área de conflito/violência'
    }
  ]
};
