import { mainApi } from './config/api';
import { callService } from './config/service';
import { CMICProject, Data, DataWithProject, VOJProject } from 'big-data';
import { getQuery } from './config/utils';
import { strToInt } from '@/utils/types';

type ProjectOptions = { cmic?: boolean; voj?: boolean };

const service = () => {
  const resource = `/vale-gas`;

  async function amount() {
    const path = `${resource}`;
    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data[0].quantidade;
  }

  async function getCities(options?: ProjectOptions) {
    const { cmic, voj } = options ?? {};

    const aggregations = [
      'MUNICIPIO',
      'IBGE',
      cmic ? 'CMIC' : undefined,
      voj ? 'VOJ' : undefined
    ];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .map<DataWithProject>((d) => ({
        value: d.quantidade,
        name: d.municipio,
        cmic: d.cmic ?? undefined,
        voj: d.voj ?? undefined,
        codIbge: strToInt(d.ibge),
        city: d.municipio
      }))
      .filter((d) => {
        if (cmic && voj) return d.cmic === true && d.voj === true;
        if (cmic) return d.cmic === true;
        if (voj) return d.voj === true;

        return d;
      });
  }

  async function getRegions() {
    const aggregations = ['REGIAO'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map<Data>((d) => ({
      value: d.quantidade,
      name: d.regiao
    }));
  }

  async function getCMICProfiles() {
    const aggregations = ['CMIC'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map<CMICProject>((d) => ({
      value: d.quantidade,
      cmic: d.cmic
    }));
  }

  async function getVOJProfiles() {
    const aggregations = ['VOJ'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map<VOJProject>((d) => ({
      value: d.quantidade,
      voj: d.voj
    }));
  }

  return {
    amount,
    getCities,
    getRegions,
    getCMICProfiles,
    getVOJProfiles
  };
};

export const ticketGas = service();
