import styled, { css } from 'styled-components';

export const Box = styled.div`
  .allSvg {
    width: 100%;
  }
  .percentage-box {
    min-height: 50px;
    height: 100%;

    position: relative;

    > div {
      left: 0;
      right: initial;
    }
  }

  ul {
    list-style: none;

    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
`;

export const Li = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;

  cursor: pointer;

  font-size: 0.9rem;
  margin: 0 0.5rem;

  transition: opacity 0.2s ease;
  color: ${({ theme }) => theme.text};

  ${({ active }) => {
    if (!active) {
      return css`
        opacity: 1;
      `;
    }

    return css`
      opacity: ${active ? 1 : 0.5};
    `;
  }}

  &::before {
    content: '';
    display: inline-block;

    width: 15px;
    height: 15px;
    border-radius: 0.2rem;
    margin-right: 0.3rem;
    background: ${({ color }) => color};
  }
`;
