import { useDashboardFilter } from '@/hooks';
import { healthAgents } from '@/services/healthAgents';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useVaccineUpToDateData() {
  const [vaccinationData, setVaccinationData] = useState<Data[]>([]);
  const [vaccinatonError, setVaccinationError] = useState<Error | null>(null);
  const [vaccinationLoading, setVaccionationLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setVaccionationLoading(true);
    healthAgents
      .getVaccineUpToDate({ ibge, year, semester, zone })
      .then((data) => {
        const sortedData = [...data].sort((a, b) => b.value - a.value);
        setVaccinationData(sortedData);
      })
      .catch((error) => setVaccinationError(error))
      .finally(() => setVaccionationLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      vaccinationData,
      vaccinatonError,
      vaccinationLoading
    }),
    [vaccinationData, vaccinatonError, vaccinationLoading]
  );
}
