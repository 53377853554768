import React, { useMemo, useCallback } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector } from 'd3-array';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Data } from 'big-data';
import { Box } from './styles';

import { sumDataTotal } from '@/utils/data';
import { percentageFormatter } from '@/utils/string';
import { format, timeFormat } from 'd3';
import { colors } from '@/theme/colors';

import * as R from 'ramda';

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { ParentSize } from '@visx/responsive';
import { useTheme } from 'styled-components';
dayjs.extend(minMax);

export const background = '#3b6978';
export const background2 = '#204051';
export const accentColorDark = '#75daad';

type SVGEvent =
  | React.TouchEvent<SVGRectElement>
  | React.MouseEvent<SVGRectElement>;

type TooltipData = Data;

export type AreaProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: Data[];
  isPercentage?: boolean;
};

const tooltipStyles = {
  ...defaultStyles,
  background: '#ffffff',
  border: '1px solid white',
  color: colors.green700
};

const tooltipDarkStyles = {
  ...defaultStyles,
  background: `#000`,
  color: colors.white
};

// util
const formatDate = timeFormat('%d/%m/%y');

// accessors
const getDate = (d: Data) => new Date(d.name);
const getYValue = (d: Data) => d.value;
const bisectDate = bisector<Data, Date>((d) => new Date(d.name)).left;

const groupByDate = R.groupBy((data: Data) => data.name);

function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

const getLocalPoint = (event: SVGEvent) => {
  return localPoint(event) ?? { x: 0 };
};

const getD = (d0: Data, d1: Data, x0: Date) => {
  if (d1 && getDate(d1)) {
    const params1 = x0.valueOf() - getDate(d0).valueOf();
    const params2 = getDate(d1).valueOf() - x0.valueOf();

    return params1 > params2 ? d1 : d0;
  }

  return d0;
};

const Area = withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    data,
    isPercentage = false,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    const { darkMode: currentInDarkTheme, ...theme } = useTheme();
    const accentColor = currentInDarkTheme ? theme.yellowDark : '#3CC3CD';

    const dataPercentage = useMemo(() => {
      const datesInRangeToFormat = getDatesInRange(
        new Date(data[0].name),
        new Date(data[data.length - 1].name)
      ).map((date) => ({ name: dayjs(date).format('YYYY-MM-DD'), value: 0 }));

      const dataFilled = [...data, ...datesInRangeToFormat].sort((a, b) => {
        const aDate = new Date(a.name);
        const bDate = new Date(b.name);

        return aDate.getTime() - bDate.getTime();
      });

      const dataGroupedByDate = groupByDate(dataFilled);
      const dataEntries = Object.entries(dataGroupedByDate).map(
        ([key, data]) => {
          const sumValues = isPercentage
            ? sumDataTotal(data) / data.length
            : sumDataTotal(data);
          return {
            name: key,
            value: sumValues
          };
        }
      );

      return isPercentage
        ? dataEntries.map((d) => ({
            ...d,
            value: d.value / 100
          }))
        : dataEntries;
    }, [data, isPercentage]);

    // bounds
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // scales
    const xScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(dataPercentage, getDate) as [Date, Date],
          clamp: true
        }),
      [innerWidth, margin.left, dataPercentage]
    );

    const yScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [
            0,
            isPercentage
              ? 1
              : (max(dataPercentage, getYValue) || 0) + innerHeight / 6
          ]
        }),
      [margin.top, innerHeight, dataPercentage, isPercentage]
    );

    // tooltip handler
    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        const { x } = getLocalPoint(event);
        const x0 = xScale.invert(x);
        const index = bisectDate(dataPercentage, x0, 1);
        const d0 = dataPercentage[index - 1];
        const d1 = dataPercentage[index];
        const d = getD(d0, d1, x0);

        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: yScale(getYValue(d))
        });
      },
      [showTooltip, yScale, xScale, dataPercentage]
    );

    const gridStrokeColor = currentInDarkTheme
      ? `rgba(255, 255, 255, 0.1)`
      : 'rgba(45, 52, 54, 0.2)';

    const axisStrokeColor = currentInDarkTheme ? '#fff' : '#96999B';

    if (width < 10) return null;

    return (
      <Box>
        <svg width={width} height={height} overflow="overlay">
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            to={accentColor}
            toOpacity={0.5}
          />
          <AreaClosed<Data>
            data={dataPercentage}
            x={(d) => xScale(getDate(d)) ?? 0}
            y={(d) => yScale(getYValue(d)) ?? 0}
            yScale={yScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />
          <GridRows
            left={margin.left}
            scale={yScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={gridStrokeColor}
            strokeOpacity={1}
            pointerEvents="none"
            numTicks={5}
          />
          <GridColumns
            top={margin.top}
            scale={xScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={gridStrokeColor}
            strokeOpacity={1}
            pointerEvents="none"
            numTicks={8}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={hideTooltip}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <AxisLeft
            scale={yScale}
            tickFormat={isPercentage ? format('~%') : undefined}
            numTicks={isPercentage ? 5 : 4}
            left={0}
            top={1}
            stroke={axisStrokeColor}
            tickStroke={axisStrokeColor}
            tickLength={2}
            labelClassName="area-axis-label"
          />
          <AxisBottom
            scale={xScale}
            top={height + 1}
            numTicks={5}
            stroke={axisStrokeColor}
            tickStroke={axisStrokeColor}
            tickLength={2}
            labelClassName="area-axis-label"
            tickFormat={(label) => {
              return formatDate(label as Date);
            }}
          />
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={tooltipData.name}
              top={tooltipTop - 50}
              left={tooltipLeft >= 425 ? tooltipLeft + 70 : tooltipLeft - 70}
              style={currentInDarkTheme ? tooltipDarkStyles : tooltipStyles}
              className="area-tooltip"
            >
              {isPercentage
                ? percentageFormatter(tooltipData.value * 100)
                : tooltipData.value}{' '}
              - {dayjs(tooltipData.name).format('DD/MM/YYYY')}
            </TooltipWithBounds>
          </div>
        )}
      </Box>
    );
  }
);

const AreaComponent = Area;

export function AreaResponsive({
  data,
  isPercentage,
  height,
  margin
}: Omit<AreaProps, 'width'>) {
  return (
    <ParentSize>
      {({ width }) => (
        <AreaComponent
          data={data}
          width={width}
          height={height}
          isPercentage={isPercentage}
          margin={margin}
        />
      )}
    </ParentSize>
  );
}

export { AreaResponsive as Area };
