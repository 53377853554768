import { Fragment, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ButtonBurger } from '../../../UI/ButtonBurger';

import linkIcon from '@/assets/icons/link.svg';
import { mediaQuery } from '@/theme/mediaQuery';

type TLink = { name: string; href: string };

const ROW_LINK_HEIGHT = 32;

const LINKS: TLink[] = [
  {
    name: `Portal do Governo`,
    href: `http://www.ceara.gov.br/`
  },
  {
    name: `Ceará Transparente`,
    href: `https://cearatransparente.ce.gov.br/`
  },
  {
    name: `Ouvidoria`,
    href: `https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR`
  },
  {
    name: `Acesso à Informação`,
    href: `https://www.ceara.gov.br/acesso-a-informacao/`
  }
];

export const ExternalLinks = () => {
  const theme = useTheme();
  return theme.screen.large ? <InlineLinks /> : <CollapsedLinks />;
};

function InlineLinks() {
  return (
    <Wrapper>
      {LINKS.map(({ href, name }: TLink, index: number) => (
        <Fragment key={name}>
          <LinkDark href={href} target="_blank">
            {name}
          </LinkDark>
          {index < LINKS.length - 1 ? <Separator /> : null}
        </Fragment>
      ))}
    </Wrapper>
  );
}

function CollapsedLinks() {
  const [opened, setOpened] = useState<boolean>(false);
  function clickHandle() {
    setOpened((prev) => !prev);
  }
  return (
    <PanelArea maximized={opened} numRows={LINKS.length}>
      <ButtonBurger isOpen={opened} onClick={clickHandle} />
      <ContainerLinks>
        {LINKS.map(({ href, name }: TLink, index: number) => (
          <Fragment key={name}>
            <LinkDark href={href} target="_blank">
              {name}
            </LinkDark>
            {index < LINKS.length - 1 ? <Separator /> : null}
          </Fragment>
        ))}
      </ContainerLinks>
    </PanelArea>
  );
}

const LinkDark = styled.a`
  font-size: 1rem;
  color: ${(props) =>
    props.theme.darkMode ? props.theme.yellowDark : props.theme.textDark};
  opacity: 0.8;
`;

const Separator = styled.span`
  width: 1px;
  height: 20px;
  margin: 3px 10px;
  background: ${(p) => p.theme.textDark};
  opacity: 0.5;
`;

const PanelArea = styled.div<{ maximized: boolean; numRows: number }>`
  height: ${(p) =>
    p.maximized ? `${100 + p.numRows * ROW_LINK_HEIGHT}px` : `30px`};
  border-radius: 10px;
  transition: 0.2s height;
  & > button {
    position: absolute;
    top: 8px;
    left: 20px;
  }

  ${mediaQuery.smallScreen} {
    & > button {
      left: 10px;
    }
  }
`;
const ContainerLinks = styled.div`
  position: absolute;
  top: 70px;
  left: 10px;
  width: calc(100% - 10px - 30px);
  ${LinkDark} {
    display: block;
    width: 100%;
    padding-left: 50px;
    line-height: 1.9;
    text-align: left;
    text-decoration: none;
    background: url(${linkIcon}) no-repeat 22px 40%;
    background-size: 15px;
  }
  ${Separator} {
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0.2;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  text-rendering: geometricPrecision;
  white-space: normal;
  padding: 1px 10px;
  a {
    margin: 0 10px;
    font-size: 0.9rem;
    text-decoration: ${(props) =>
      props.theme.darkMode ? 'underline' : 'none'};
    color: ${(props) =>
      props.theme.darkMode ? props.theme.yellowDark : props.theme.textDark};

    &:hover {
      text-decoration: underline;
    }
  }
`;
