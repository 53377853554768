import { SelectBox, Tabs } from '@/components/shared';
import { Select } from '@/components/shared/SelectBox';

import { dateMonthInterval } from './date';

import { Box } from './styles';

export interface TabsItem {
  id: number;
  name: string;
}

interface FeedbackTabsSectionProps {
  tabId?: number;
  onClickTab?: (item: TabsItem, id: number) => void;
  filter: {
    value: string;
    onChange(value: string): void;
  };
}

const items = [
  {
    id: 0,
    name: 'Visão Geral',
    value: ''
  },
  {
    id: 1,
    name: 'Problemas',
    value: ''
  },
  {
    id: 2,
    name: 'Ideias',
    value: ''
  },
  {
    id: 3,
    name: 'Outros',
    value: ''
  }
];

const selectOptions = [
  {
    id: 0,
    name: 'Há 1 mês',
    value: dateMonthInterval(1)
  },
  {
    id: 1,
    name: 'Há 3 meses',
    value: dateMonthInterval(3)
  },
  {
    id: 2,
    name: 'Há 6 meses',
    value: dateMonthInterval(6)
  },
  {
    id: 3,
    name: 'Há 1 ano',
    value: dateMonthInterval(12)
  }
];

export function FeedbackTabsSection({
  tabId,
  onClickTab,
  filter
}: FeedbackTabsSectionProps) {
  return (
    <Box>
      <Tabs items={items} onClickTab={onClickTab} tabId={tabId} />

      <SelectBox onClear={() => filter.onChange('')}>
        <Select
          bordered={false}
          className="tab-select-options"
          placeholder="Selecione um tempo"
          value={filter.value || undefined}
          onChange={(value) => filter.onChange(String(value))}
        >
          {selectOptions.map((option) => (
            <Select.Option key={option.name} value={option.value}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </SelectBox>
    </Box>
  );
}
