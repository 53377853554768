import { Loading, Show } from '@/components/shared';
import { useFamilies } from '@/servicesHooks/visits';

import { dotsNumber } from '@/utils/string';
import { Box, ImageBox } from './styles';

import mapIcon from './mapIcon.svg';

export function BannerCount() {
  const { loading, error } = useFamilies();

  return (
    <Box>
      {loading ? (
        <Loading size={40} />
      ) : (
        <Show when={!error}>
          <strong>{dotsNumber(119819)}</strong>
          <p>famílias visitadas em {182} municípios do ceará</p>
          <ImageBox>
            <img src={mapIcon} alt="Mapa" />
          </ImageBox>
        </Show>
      )}

      <Show when={!!error}>
        <p>Erro ao buscar informações</p>
      </Show>
    </Box>
  );
}
