import { useAuth } from '@/hooks';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface FilterContextProviderProps {
  children: ReactNode;
}

type City = {
  name: string;
  ibge: string;
};

interface FilterContextData {
  city?: City;
  handleChangeCity(city?: City): void;
  onClear(): void;
}

const FilterContext = createContext({} as FilterContextData);

const DEFAULT_CITY_FILTER_VALUE = { name: '', ibge: '' };

export function FilterContextProvider({
  children
}: FilterContextProviderProps) {
  const { userCity, userIbge, isAdm } = useAuth();

  const defaultCity = isAdm
    ? DEFAULT_CITY_FILTER_VALUE
    : { ibge: userIbge, name: userCity ?? '' };

  const [city, setCity] = useState<City>(defaultCity);

  function handleChangeCity(city: City) {
    setCity(city);
  }

  function onClear() {
    setCity(defaultCity);
  }

  const contextProviderValue = useMemo(
    () => ({ city, handleChangeCity, onClear }),
    [city] // eslint-disable-line
  );

  return (
    <FilterContext.Provider value={contextProviderValue}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  return useContext(FilterContext);
}
