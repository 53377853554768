import { CzRMCitizen } from 'big-data';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

export const replacer = (value: string) => value.replace(/\D/g, '');

export const nisReplacer = (value: string) =>
  value.replace(/^(\d{3})(\d{5})(\d{2})(\d)/, '$1.$2.$3-$4');

export const exportToCSV = (_data: CzRMCitizen[], filename: string) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(_data);
  const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename + fileExtension);
};
