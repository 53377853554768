import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  .explanation {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: ${({ theme }) => (theme.darkMode ? '#fff' : '#4c5469')};
    margin-top: 5px;
  }

  .panels {
    display: flex;
    gap: 0.8rem;

    @media (max-width: 1230px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .details {
    display: grid;
    margin-top: 1rem;

    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .board {
    margin-top: 3rem;

    &-title {
      font-size: 1.5rem;
      color: ${({ theme }) =>
        theme.darkMode ? colors.white : colors.ciano700};
    }

    &-description {
      font-size: 0.875rem;
      color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
    }
  }
`;
