import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  background-color: #2d4040;
  min-height: 500px;
  padding-top: 80px;

  position: relative;
  border-bottom: 7px solid #87c878;

  display: flex;
  justify-content: center;

  overflow: hidden;

  @media (max-width: 1199px) {
    align-items: center;
  }

  .content-card {
    display: flex;
    flex-direction: column;

    max-width: 1400px;
    width: 90%;
    margin: 0px auto;
    padding-inline: 24px;

    @media (max-width: 400px) {
      padding-inline: 0;
    }
  }
`;

export const Content = styled.div`
  padding-inline: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery.mediumScreen} {
    flex-direction: column;
    gap: 50px;
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;
    gap: 30px;
    padding-inline: 0;
  }
`;

export const Cards = styled.div`
  position: relative;
  z-index: 3;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 1.5rem;

  margin-bottom: 5rem;

  margin-top: -50px;
`;
