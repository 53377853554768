import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { LgbtPhobiaSchool } from './LgbtPhobiaSchool';
import { LgbtPhobiaTypes } from './LgbtPhobiaTypes';
import { SchoolingData } from './SchoolingData';
import { SchoolPreparationLevel } from './SchoolPreparationLevel';
import { Box } from './style';

export function EducationData() {
  return (
    <Box>
      <ThirdTitle>Educação da população LGBTQIA+</ThirdTitle>
      <GroupBlockBox>
        <SchoolingData />
        <LgbtPhobiaSchool />
      </GroupBlockBox>
      <GroupBlockBox>
        <LgbtPhobiaTypes />
        <SchoolPreparationLevel />
      </GroupBlockBox>
    </Box>
  );
}
