import styled from 'styled-components';

export const GroupBlockBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;
