import { Box } from './styles';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import { BdsCookie } from '@/bdsIcons';

export function CookieWarning() {
  return (
    <Box>
      <CookieConsent
        overlay
        buttonText="OK"
        contentStyle={{
          fontSize: '1rem',
          maxWidth: 'calc(1345px - 5rem)',
          margin: '15px 0'
        }}
        buttonStyle={{
          fontSize: '1rem',
          borderRadius: '7px',
          margin: '15px 0',
          padding: '10px',
          width: '50px'
        }}
        expires={230}
        style={{
          justifyContent: 'center',
          borderRadius: '7px',
          margin: '1rem',
          width: 'calc(100% - 2rem)',
          padding: '0 20px',
          gap: '1rem'
        }}
        buttonClasses="btn"
      >
        <div className="content">
          <BdsCookie />
          <span>
            Nós usamos cookies para melhorar sua experiência de navegação. Ao
            utilizar o Big Data Social, você concorda com a política de
            monitoramento de cookies. Se você concorda, clique em OK. Para ter
            mais informações sobre como isso é feito, acesse nosso{' '}
            <Link to="/info/aviso-privacidade">
              <b>Aviso de Privacidade</b>
            </Link>
            .
          </span>
        </div>
      </CookieConsent>
    </Box>
  );
}
