import { home } from './home';
import { topPages } from './topPages';
import { cityTaxes } from './cityTaxes';
import { rejection } from './rejection';
import { meanTimePage } from './meanTimePage';
import { temporalRejection } from './temporalRejection';
import { temporalSession } from './temporalSession';
import { pageSessionTime } from './pageSessionTime';

export const googleAnalytics = {
  home,
  topPages,
  cityTaxes,
  rejection,
  meanTimePage,
  temporalRejection,
  temporalSession,
  pageSessionTime
};

export type { CityTaxes } from './cityTaxes';
