import styled from 'styled-components';

interface ThermometerWrapperProps {
  position?: 'start' | 'end';
}

export const ThermometerWrapper = styled.div<ThermometerWrapperProps>`
  margin: 50px 0;
  width: 100%;

  display: grid;
  gap: 2rem;

  grid-template-columns: 1fr 2fr 1fr;

  justify-items: center;

  .sunburst-box {
    max-width: 400px;
    width: 100%;
    align-self: center;
  }

  @media (max-width: 1240px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'sun legend'
      'card card';
  }

  @media (max-width: 950px) {
    grid-template-areas:
      'sun'
      'legend'
      'card';
  }
`;
