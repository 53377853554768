import { useDashboardFilter } from '@/hooks';
import { insecurityLevelBenefits } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

function getRankingData(data: Data<boolean, number>[], label: string): Data {
  const valuesTrue = data.reduce(
    (acc, cur) => (cur.name === true ? cur.value : acc),
    0
  );

  const valuesFalse = data.reduce(
    (acc, cur) => (cur.name === false ? cur.value : acc),
    0
  );

  const percentage = (valuesTrue / (valuesFalse + valuesTrue)) * 100

  return { name: label, value: parseFloat(percentage.toFixed(2)) };
}

export function useBenefitsData(name?: string) {
  const [benefits, setBenefits] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [benefitsRejecteds, setBenefitsRejecteds] = useState<string[]>([])

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    const requests = [
      insecurityLevelBenefits.getTicketGas,
      insecurityLevelBenefits.getEnergyTax,
      insecurityLevelBenefits.getWaterTax,
      insecurityLevelBenefits.getFoodPackage,
      insecurityLevelBenefits.getChangingGame
    ];

    setLoading(true);
    Promise.allSettled(
      requests.map((request) => request(name, ibge, year, semester, zone))
    )
      .then((data) => {
        const verify = data.filter((d) => {
          return d.status === "fulfilled" && d.value.length > 0;
        });
        if (verify.length > 0) {
          const labels = [
            'ticketGas',
            'energyTax',
            'waterTax',
            'foodPackage',
            'changingGame'
          ];

          const ranking = data
            .map((d, i) => {
              if (d.status === "fulfilled") return getRankingData(d.value, labels[i])
              else return { name: labels[i], value: "rejected" } as unknown as Data
            })
            .filter(d => d.value.toString() !== "rejected")
            .sort((a, b) => b.value - a.value)

          const rejecteds = data.map((d, i) => {
            if (d.status === "rejected") return labels[i]
            else return ""
          }).filter(d => d !== "")

          setBenefitsRejecteds(rejecteds)

          setBenefits(ranking);
        } else setBenefits([]);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [name, ibge, year, semester, zone]);

  return useMemo(
    () => ({
      benefits,
      benefitsRejecteds,
      loadingBenefits: loading,
      errorBenefits: error
    }),
    [benefits, benefitsRejecteds, loading, error]
  );
}
