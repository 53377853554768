import { colors } from '@/theme/colors';
import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';

const showUp = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  .group {
    display: flex;
    align-items: center;

    .form-control {
      width: 100%;

      label {
        font-size: 0.9rem;
      }

      .czrm-input-search {
        border-radius: 7px;
        border: 1px solid #dfe6e9;
        margin: 0;
      }

      p {
        font-size: 0.8rem;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      flex: 1;
      border: 0;
      margin-left: 10px;
      padding: 5px 20px;
      border-radius: 7px;
      color: #ffffff;
      background: ${colors.green400};

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .error,
  .success {
    display: flex;
    align-items: center;
    padding: 10px;

    margin-top: 10px;
    width: 100%;
    background: ${lighten(0.4, colors.red400)};

    border-radius: 5px;
    animation: ${showUp} 0.5s ease-in-out alternate;

    p {
      margin-left: 5px;
      font-size: 0.9rem;
    }
  }

  .success {
    background: ${lighten(0.4, colors.green200)};
  }
`;

export const ExportToCSVButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;

  border: 0;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 5px;
  color: #000;
  font-weight: 700;
  transition: filter 0.2s;
  background: ${({ theme }) => (theme.darkMode ? colors.yellow400 : `#eff4ee`)};

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
