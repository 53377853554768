import { Data } from 'big-data';

export const fixedValueGenerator = () => 0.5;

export function getWordFreq(words: Data[]) {
  return words
    .filter((w) => w.name !== null)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: (acc[cur.name] ?? 0) + cur.value
      }),
      {} as Record<string, number>
    );
}

export function getDataParsed(
  wordFreq: Record<string, number>,
  options: { total: number; isPercentage?: boolean }
) {
  const { isPercentage, total } = options;

  return Object.entries(wordFreq).map(([text, value]) => ({
    text,
    value: isPercentage ? (value * 100) / total : value
  }));
}
