import { AxiosRequestConfig } from 'axios';
import { mainApi } from './config/api';
import { callService } from './config/service';

export enum WorkEnum {
  TRABALHO_REMUNERADO = 'TRABALHO_REMUNERADO',
  SALARIO_MINIMO = 'SALARIO_MINIMO',
  FAZ_CURSO = 'FAZ_CURSO',
  QUER_FAZER_CURSO = 'QUER_FAZER_CURSO',
  RENDA = 'RENDA'
}

export enum LivingConditionEnum {
  AGUA_CANALIZADA = 'AGUA_CANALIZADA',
  BANHEIRO = 'BANHEIRO',
  COLETA_SELETIVA = 'COLETA_SELETIVA',
  LUGARES_PUBLICOS_CRIANCA_BRINCAR = 'LUGARES_PUBLICOS_CRIANCA_BRINCAR',
  ATIVIDADE_CULTURAL = 'ATIVIDADE_CULTURAL',
  ATIVIDADE_ESPORTIVA = 'ATIVIDADE_ESPORTIVA',
  AREA_CONFLITO = 'AREA_CONFLITO'
}

export enum HealthEnum {
  ACOMPANHAMENTO_CRIANCA = 'ACOMPANHAMENTO_CRIANCA',
  GESTANTE_PRE_NATAL = 'GESTANTE_PRE_NATAL',
  CRIANCA_VACINA_EM_DIA = 'CRIANCA_VACINA_EM_DIA'
}

export enum EducationEnum {
  CRECHE = 'CRECHE',
  PRE_ESCOLA = 'PRE_ESCOLA',
  ESCOLA = 'ESCOLA'
}

export enum SocialAssistanceEnum {
  CREAS = 'CREAS',
  CRAS = 'CRAS'
}

export enum InsecurityFoodEnum {
  FORA_DE_INSEGURANCA_ALIMENTAR = 'FORA_DE_INSEGURANCA_ALIMENTAR',
  INSEGURANCA_ALIMENTAR_LEVE = 'INSEGURANCA_ALIMENTAR_LEVE',
  INSEGURANCA_ALIMENTAR_MODERADA = 'INSEGURANCA_ALIMENTAR_MODERADA',
  INSEGURANCA_ALIMENTAR_GRAVE = 'INSEGURANCA_ALIMENTAR_GRAVE'
}

export interface IActionPercentage {
  group: string;
  value: number;
  percentage: number;
}

interface RequestOptions<T> {
  column: T;
  columnValues?: string[];
  ibge?: string;
}

type SimpleRequestOptions = Omit<RequestOptions<string>, 'column'>;

const service = () => {
  const resource = `eixos/porcentagem`;

  const getWorkValues = async (options: RequestOptions<WorkEnum>) => {
    const path = `${resource}/trabalho-renda`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(', ')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  const getLivingConditionValues = async (
    options: RequestOptions<LivingConditionEnum>
  ) => {
    const path = `${resource}/condicoes-moradia`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(', ')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  const getHealthValues = async (options: RequestOptions<HealthEnum>) => {
    const path = `${resource}/saude`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(',')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  const getInsecurityFoodValues = async (options: SimpleRequestOptions) => {
    const path = `${resource}/inseguranca-alimentar`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(', ')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  const getEducationValues = async (options: RequestOptions<EducationEnum>) => {
    const path = `${resource}/educacao`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(', ')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  const getSocialAssistanceValues = async (
    options: RequestOptions<SocialAssistanceEnum>
  ) => {
    const path = `${resource}/assistencia-social`;

    const { columnValues } = options;

    const config: AxiosRequestConfig = {
      params: {
        ...options,
        columnValues: columnValues?.join(', ')
      }
    };

    const response = await callService(() =>
      mainApi.get<IActionPercentage[]>(path, config)
    );

    return response.data;
  };

  return {
    getWorkValues,
    getLivingConditionValues,
    getHealthValues,
    getInsecurityFoodValues,
    getEducationValues,
    getSocialAssistanceValues
  };
};

export const actionPercentage = service();
