import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `/familia-agentes`;

  async function getTotal() {
    const path = `${resource}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data[0].quantidade;
  }

  async function getTotalCities() {
    const path = `${resource}/cidades`;

    const response = await callService(() => mainApi.get<any>(path));

    return response.data.municipios;
  }

  return {
    getTotal,
    getTotalCities,
  };
};

export const familiesService = service();
