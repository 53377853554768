import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = '/movimentos-sociais';

  async function getTypeMovements() {
    const path = `${resource}/tipos-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.tipo_de_movimento,
        value: d.quantidade
      };
    });
  }

  async function bondMovements() {
    const path = `${resource}/vinculo-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.movimento_e_vinculado,
        value: d.quantidade
      };
    });
  }

  async function localMovements() {
    const path = `${resource}/sede-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.municipio,
        value: d.quantidade,
        codIbge: d.ibge
      };
    });
  }

  async function networkMovements() {
    const path = `${resource}/redes-vinculo-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.rede
      };
    });
  }

  async function mainPrizeMovements() {
    const path = `${resource}/principais-premios-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.se_sim_quais_premios,
        value: d.nome_movimento,
        year: d.se_sim_quais_anos
      };
    });
  }

  async function prizeMovements() {
    const path = `${resource}/premios-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.movimento_recebeu_premios,
        value: d.quantidade
      };
    });
  }

  async function participationMovements() {
    const path = `${resource}/participacao-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.participa_espaco_controle_social,
        value: d.quantidade
      };
    });
  }

  async function levelOperationMovements() {
    const path = `${resource}/niveis-atuacao-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.nivel_atuacao_movimento,
        value: d.quantidade
      };
    });
  }

  async function listMovements() {
    const path = `${resource}/lista-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.movimento
      };
    });
  }

  async function regionsMovements() {
    const path = `${resource}/regioes-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.regiao,
        value: d.quantidade
      };
    });
  }

  async function othersChannelMovements() {
    const path = `${resource}/outros-canais-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.canal,
        value: d.quantidade
      };
    });
  }

  async function groupOperationMovements() {
    const path = `${resource}/grupos-atuacao-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.publico_alvo,
        value: d.quantidade
      };
    });
  }

  async function linkMovements() {
    const path = `${resource}/canais-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.canal,
        value: d.quantidade
      };
    });
  }

  async function controlSocialMovements() {
    const path = `${resource}/espacos-controle-social-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.espaco,
        value: d.quantidade
      };
    });
  }

  async function areaOperationMovements() {
    const path = `${resource}/areas-atuacao-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.area,
        value: d.quantidade
      };
    });
  }

  async function getTypeMovementsFilter() {
    const path = `${resource}/lista-tipos-movimentos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.tipo_movimento
      };
    });
  }

  return {
    getTypeMovements,
    bondMovements,
    localMovements,
    networkMovements,
    prizeMovements,
    participationMovements,
    levelOperationMovements,
    listMovements,
    groupOperationMovements,
    linkMovements,
    areaOperationMovements,
    mainPrizeMovements,
    controlSocialMovements,
    getTypeMovementsFilter,
    othersChannelMovements,
    regionsMovements
  };
};

export const socialMovements = service();
