import { Box, IconBox, TextBox } from './style';
import { Tap, Toilet } from './Icons';
import { Sanitation } from 'big-data';

interface SanitaryCardProps {
  data: Sanitation;
  x?: number;
  y?: number;
  delay?: number;
  verticalIcons?: boolean;
}

const noWord = (condition: boolean) =>
  condition && <span className="no-word">não</span>;

export function SanitaryCard({
  data,
  x,
  y,
  delay,
  verticalIcons
}: SanitaryCardProps) {
  const { pipedWater, bathroom } = data;

  return (
    <Box x={x} y={y} delay={delay}>
      <IconBox vertical={verticalIcons}>
        <Tap selected={data.pipedWater} />
        <Toilet selected={data.bathroom} />
      </IconBox>
      <TextBox>
        <span className="percentage">{data.value.toFixed(2)}%</span>
        <span className="text">
          {noWord(!pipedWater)} tem água encanada e
          <br />
          {noWord(!bathroom)} tem banheiro.
        </span>
      </TextBox>
    </Box>
  );
}
