import { Text } from '@visx/text';
import { Group } from '@visx/group';
import { BarGroupHorizontal, Bar } from '@visx/shape';
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale';
import { AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Fragment, useState } from 'react';
import { useTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { DataGroup } from 'big-data';

import { useBoxWidth } from '@/hooks';

import { colors as themeColors } from '@/theme/colors';

import { Box, Li } from './styles';
import { percentageFormatter } from '@/utils/string';

interface BarGroupProps {
  data: DataGroup[];
  colors: string[];
  legend?: string[];
  height?: number;
  labelVisible?: boolean;
}

const MARGIN = Object.freeze({ left: 20, right: 20, top: 10, bottom: 20 });

const getCleanData = (dataGroup: DataGroup[]) => {
  return dataGroup.map((group) => ({
    ...group.data.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: cur.percentageValue
      }),
      { label: group.label } as Record<string, string | number>
    )
  }));
};

const getInnerKey = (data: Record<string, string | number>) => {
  return Object.keys(data).filter((d) => d !== 'label');
};

export function BarGroup({
  data,
  colors,
  legend,
  height,
  labelVisible = false
}: BarGroupProps) {
  const { boxRef, width } = useBoxWidth<SVGSVGElement>();
  const { darkMode } = useTheme();

  const [selected, setSelected] = useState<string>();

  if (legend && legend.length !== colors.length)
    throw new Error('Properties legend and color must be of the same length!');

  const dataOrder = [...data]
    .sort((a, b) => b.data.length - a.data.length)
    .map((d) => {
      const orderedValue = [...d.data];
      orderedValue.sort((a, b) => a.percentageValue - b.percentageValue);

      return {
        ...d,
        data: orderedValue
      };
    });

  const cleanData = getCleanData(dataOrder);

  const maxWidth = width - MARGIN.left - MARGIN.right;
  const maxHeight = 380 - MARGIN.top - MARGIN.bottom;

  const innerKeys =
    legend ?? getInnerKey(cleanData[0]).sort((a, b) => b.localeCompare(a));

  const percentageScale = scaleLinear<number>({
    domain: [0, 100],
    range: [0, maxWidth]
  });

  const labelsScale = scaleBand({
    domain: cleanData.map((d) => d.label),
    range: [0, maxHeight],
    padding: 0.3
  });

  const innerLabelsScale = scaleBand({
    domain: innerKeys,
    range: [0, labelsScale.bandwidth()],
    padding: 0.05
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: innerKeys,
    range: colors
  });

  const onSelected = (key: string) => {
    if (key === selected) setSelected(undefined);
    else setSelected(key);
  };

  const colorDarkMode = darkMode ? '#fff' : '#000';

  return (
    <Box>
      <svg className="allSvg" ref={boxRef} width={width} height={370}>
        <Group top={MARGIN.top} left={MARGIN.left}>
          <GridColumns
            scale={percentageScale}
            top={-40}
            height={370}
            strokeDasharray="10"
            stroke={'#000'}
            strokeOpacity={0.2}
            pointerEvents="none"
            numTicks={5}
          />
          <BarGroupHorizontal
            width={maxWidth}
            data={cleanData}
            keys={innerKeys}
            y0={(d) => d.label}
            y0Scale={labelsScale}
            y1Scale={innerLabelsScale}
            xScale={percentageScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup, i) => {
                return (
                  <Group
                    key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                    top={barGroup.y0}
                  >
                    <Text fill={colorDarkMode}>{cleanData[i].label}</Text>
                    {barGroup.bars
                      .sort((a, b) => b.value - a.value)
                      .map((bar) => {
                        const valueFormatted = percentageFormatter(bar.value);
                        const fill =
                          selected === bar.key || !selected
                            ? bar.color
                            : themeColors.gray500;

                        return (
                          <Fragment
                            key={`${barGroup.index}-${bar.index}-${bar.key}`}
                          >
                            {labelVisible && (
                              <Text
                                fill={colorDarkMode}
                                x={bar.x + bar.width + 5}
                                y={bar.y + 3 + bar.height / 2}
                              >
                                {valueFormatted}
                              </Text>
                            )}

                            {!labelVisible && (
                              <Tooltip
                                placement="topLeft"
                                title={`${bar.key}: ${valueFormatted}`}
                              >
                                <Bar
                                  x={bar.x}
                                  y={bar.y + 3}
                                  width={'100%'}
                                  height={height ?? bar.height}
                                  fill={'transparent'}
                                  rx={4}
                                />
                              </Tooltip>
                            )}

                            <Tooltip
                              placement="topLeft"
                              title={`${bar.key}: ${valueFormatted}`}
                              zIndex={labelVisible ? -1 : 1}
                            >
                              <Bar
                                key={`${barGroup.index}-${bar.index}-${bar.key}`}
                                x={bar.x}
                                y={bar.y + 3}
                                width={bar.width}
                                height={height ?? bar.height}
                                fill={fill}
                                rx={4}
                              />
                            </Tooltip>
                          </Fragment>
                        );
                      })}
                  </Group>
                );
              })
            }
          </BarGroupHorizontal>

          <AxisBottom
            scale={percentageScale}
            top={maxHeight - 20}
            numTicks={5}
            tickFormat={(d) => `${d}%`}
            tickLabelProps={(d) => ({
              textAnchor: 'middle',
              fontWeight: 'normal',
              fontSize: '12px',
              fill: colorDarkMode
            })}
            tickStroke={colorDarkMode}
            stroke={colorDarkMode}
          />
        </Group>
      </svg>

      <ul>
        {innerKeys.map((key, idx) => (
          <Li
            key={key}
            onClick={() => onSelected(key)}
            color={colorScale(colors[idx])}
          >
            {key}
          </Li>
        ))}
      </ul>
    </Box>
  );
}
