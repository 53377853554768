import { CardTagged, ServiceBox } from '@/components/shared';
import { RankingDashboard } from '@/components/shared/RankingDashboard';
import { FullPeriodIcon } from '@/components/shared/RankingDashboard/Icons/FullPeriod.icon';
import { HalfPeriodIcon } from '@/components/shared/RankingDashboard/Icons/HalfPeriod.icon';
import { NoProgramIcon } from '@/components/shared/RankingDashboard/Icons/NoProgramIcon.icon';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePeriodData } from '@/servicesHooks/education';
import { getDashboardRanking } from '@/utils/data';
import { RankingModel } from 'big-data';
import { Axe } from '../EducationInsights/type';

const DESCRIPTION_FIX = 'das crianças estão matriculadas em ';

const rankingModel = new Map<string, RankingModel>([
  [
    'período integral',
    {
      description: () => DESCRIPTION_FIX,
      img: <FullPeriodIcon />,
      name: 'período integral',
      key: 'integral',
      color: '#57AC4B'
    }
  ],
  [
    'meio período',
    {
      description: () => DESCRIPTION_FIX,
      img: <HalfPeriodIcon />,
      name: 'meio período',
      key: 'meio',
      color: '#EBAD29'
    }
  ],
  [
    'não',
    {
      description: () => DESCRIPTION_FIX,
      img: <NoProgramIcon />,
      name: ' nenhum período',
      key: 'não',
      color: '#F53C3E'
    }
  ]
]);

export function EducationPeriod({ color, selected }: Axe) {
  const { tag } = useDashboardFilter();

  const { periods, periodsError, periodsLoading } = usePeriodData({
    axeSelected: selected
  });
  const data = getDashboardRanking(periods, rankingModel);

  return (
    <CardTagged
      tag={tag}
      color={color}
      title="Crianças matriculadas na escola pública
    "
    >
      <ServiceBox
        empty={periods.length === 0}
        error={periodsError}
        loading={periodsLoading}
      >
        <TitleText>
          Qual a porcentagem de crianças matriculadas em escolas de tempo
          integral ou de meio período nas famílias visitadas?
        </TitleText>

        <RankingDashboard
          color={color}
          data={data}
          loadingBenefits={false}
          selected={undefined}
        />
      </ServiceBox>
    </CardTagged>
  );
}
