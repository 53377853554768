import { Data } from 'big-data';

import { CardTagged, DonutPopulated, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useBreastfeedData } from '@/servicesHooks/healthAgents';
import { colors } from '@/theme/colors';

const COLORS = [
  colors.green200,
  colors.red400,
  colors.yellow200,
  colors.gray200
];

const DONUT_THICKNESS = 20;

const generateColorsBySize = (data?: Data[]) => {
  if (!data) return COLORS;
  return data.map((_, i) => COLORS[i]);
};

export function CardBreastfeedDonut() {
  const { tag } = useDashboardFilter();

  const { breastfeedData, breastfeedError, breastfeedLoading } =
    useBreastfeedData();

  const [bigger] = breastfeedData;

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={breastfeedData.length === 0}
        loading={breastfeedLoading}
        error={breastfeedError}
      >
        <TitleText>
          Qual a porcentagem de crianças, com até 8 anos de idade, que mamaram
          na primeira hora após nascer?
        </TitleText>

        <DonutPopulated
          data={breastfeedData}
          label="crianças"
          subLabel="mamaram"
          thickness={DONUT_THICKNESS}
          flexDirection="column"
          percentage={true}
          initialRenderData={bigger}
          highlight={(data) => data.name === 'Sim'}
          colors={generateColorsBySize(breastfeedData)}
          exactColors
        />
      </ServiceBox>
    </CardTagged>
  );
}
