import * as R from 'ramda';
import { RichedAction } from 'big-data';

import { ServiceBox2 } from '@/components/shared/ServiceBox2';

import { Box } from './styles';
import { LastGoalsItem } from '../LastGoalsItem';
import { Title3 } from '../styles';

interface LastGoalsBoardProps {
  actions: RichedAction[];
  loading?: boolean;
  error?: string;
}

export function LastGoalsBoard({
  actions,
  loading,
  error
}: LastGoalsBoardProps) {
  const isEmpty = R.isEmpty(actions);

  return (
    <Box>
      <Title3>Planos de ação recentemente atualizados</Title3>
      <ServiceBox2
        empty={isEmpty}
        error={error}
        loading={loading}
        emptyMessage="Nenhuma ação cadastrada"
        minHeight={500}
      >
        {actions.map((action, i) => (
          <LastGoalsItem key={action.id} action={action} />
        ))}
      </ServiceBox2>
    </Box>
  );
}
