import styled from 'styled-components';

export const Box = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;

  width: 100%;
  height: 100%;
  min-width: 300px;

  img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
  }

  .content {
    padding: 1rem;

    .tag {
      color: #1d696f;
      font-weight: 700;
      font-size: 0.8125rem; // 13px
      display: block;
    }
  }
`;
