import { useState, useCallback } from 'react';
import { ActionHistory } from 'big-data';
import { sort, uniqWith, eqProps } from 'ramda';
import dayjs from 'dayjs';

import { actions } from '@/services';

const sortHistories = sort<ActionHistory>((a, b) =>
  dayjs(a.creationDate).isAfter(dayjs(b.creationDate)) ? 1 : -1
);

export function useHistories() {
  const [histories, setHistories] = useState<ActionHistory[]>([]);
  const [loadingHistories, setLoadingHistories] = useState(false);
  const [errorHistories, setErrorHistories] = useState('');

  const refreshHistories = useCallback((id: number, page: number = 0) => {
    setLoadingHistories(true);
    return actions
      .getHistory({ id, page })
      .then((response) => sortHistories(response.content))
      .then((response) => {
        if (page === 0) {
          setHistories(response);
        }

        setHistories((current) => {
          const newHistories = [...current, ...response];
          return uniqWith(eqProps('id'), newHistories);
        });
      })
      .catch((err) =>
        setErrorHistories(
          err.response ?? 'Houve um erro interno. Tente novamente mais tarde'
        )
      )
      .finally(() => setLoadingHistories(false));
  }, []);

  return { histories, loadingHistories, errorHistories, refreshHistories };
}
