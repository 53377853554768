import styled from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';

import cearaLogo from '@/assets/logos/ceara.svg';
import cearaDarkModeLogo from '@/assets/logos/ceara-darkmode.svg';

function imageFromTheme(darkMode: boolean) {
  return darkMode ? cearaDarkModeLogo : cearaLogo;
}

export const ColumnCeara = () => {
  return (
    <Wrapper>
      <Link 
        href="https://www.ceara.gov.br/" 
        target="_blank">
          <Logo />
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${mediaQuery.mediumScreen} {
    grid-column: span 2;
  }
`;

const Link = styled.a``;

const Logo = styled.div`
  height: 120px;
  max-width: 250px;
  background: url(${({ theme }) => imageFromTheme(theme.darkMode)}) no-repeat
    center;
  background-size: contain;
  ${mediaQuery.smallScreen} {
    height: 50px;
    max-width: 100%;
    background-size: 200px;
  }

  ${mediaQuery.mediumScreen} {
    margin: 0 auto;
  }
`;
