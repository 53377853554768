import { useDashboardFilter } from '@/hooks';
import { jobIncome } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataGroup } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const {
  getQualificationCoursesInTheLast12Months,
  getWouldDoQualificationCourses
} = jobIncome;

const labels = [
  `Pessoas que fizeram cursos de qualificação no último ano`,
  `Pessoas interessadas em fazer cursos de qualificação`
];

export function useQualificationCoursesData() {
  const [data, setData] = useState<DataGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  const empty = data.length === 0;

  useEffect(() => {
    setLoading(true);
    Promise.all(
      [
        getQualificationCoursesInTheLast12Months,
        getWouldDoQualificationCourses
      ].map((request) => request({ ibge, year, semester, zone }))
    )
      .then((data) => data.map((d) => getDataWithPercentage(d)))
      .then((dataPercentage) =>
        dataPercentage.map((d, i) => ({ label: labels[i], data: d }))
      )
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      error,
      empty,
      loading
    }),
    [data, error, empty, loading]
  );
}
