import { Data } from 'big-data';
import { DashboardRequestParams } from 'big-data/service';

import { callService } from './config/service';
import { mainApi } from './config/api';
import { getQuery } from './config/utils';

import { ensureAxiosParamOptions } from '@/utils/routes';
import { getAssistanceInfo, filterAssistanceData } from '@/utils/data';

interface SocialAssistanceQueryParams extends DashboardRequestParams {
  aggregations: string[];
}

const NO_CREAS = 'O município não possui CREAS'

const service = () => {
  const resource = 'assistencia-social';

  async function generateGeneralRequest(
    path: string,
    params: SocialAssistanceQueryParams
  ) {
    const { aggregations, ibge, year, semester, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations: getQuery(aggregations),
        ibge,
        zone
      }
    });

    return await callService(() => mainApi.get<any[]>(path, options));
  }

  async function getByCREAS(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CREAS'];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    if (response.status === 204) return [];

    return response.data.map<Data>((d) => ({
      name: d.creas,
      value: d.quantidade,
      type: 'CREAS'
    }));
  }

  async function getFiveBiggestByCity(name: string) {
    const aggregations = ['MUNICIPIO', name.toUpperCase()];

    const path = `${resource}?aggregations=${aggregations.join(',')}`;
    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => d[name.toLowerCase()] === 'Não')
      .sort((a, b) => b.quantidade - a.quantidade)
      .map<Data>((d) => ({ name: d.municipio, value: d.quantidade }))
      .slice(0, 10);
  }

  async function getByCity(
    name?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MUNICIPIO', 'IBGE', 'CRAS', 'CREAS'];

    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    const data = response.data.map((d) => ({
      name: d.municipio,
      value: d.quantidade,
      codIbge: d.ibge,
      cras: d.cras,
      creas: d.creas
    }));

    return filterAssistanceData(data, name);
  }

  async function getByZone() {
    const aggregations = ['ZONA', 'CRAS', 'CREAS'];
    const path = `${resource}?aggregations=${aggregations.join(',')}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => d.cras === 'Não' || d.creas === 'Não')
      .sort((a, b) => b.quantidade - a.quantidade)
      .map((d) => ({ name: d.zona, value: d.quantidade }));
  }

  async function getProgramCras(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data[]> {
    const aggregations = ['SE_SIM_QUAIS_PARTICIPOU_CRAS', 'CRAS'];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    const data = response.data
      .filter(
        (d) => d.cras === 'Sim' && d.se_sim_quais_participou_cras !== 'null'
      )
      .sort((a, b) => b.quantidade - a.quantidade)
      .map((d) => ({
        name: d.se_sim_quais_participou_cras,
        value: d.quantidade
      }));

    return getAssistanceInfo(data);
  }

  async function getProgramCreas(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data[]> {
    const aggregations = ['SE_SIM_QUAIS_PARTICIPOU_CREAS', 'CREAS'];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    const data = response.data
      .filter(
        (d) => d.creas === 'Sim' && d.se_sim_quais_participou_creas !== 'null'
      )
      .sort((a, b) => b.quantidade - a.quantidade)
      .map((d) => ({
        name: d.se_sim_quais_participou_creas,
        value: d.quantidade
      }));

    return getAssistanceInfo(data);
  }

  async function getZoneByName(name: string, filter?: string): Promise<Data[]> {
    const aggregations = ['ZONA', name.toUpperCase()];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge: filter,
      year: '',
      semester: ''
    });

    return response.data
      .filter(
        (d) =>
          d[name.toLowerCase()] === 'Não' ||
          d[name.toLowerCase()] === NO_CREAS
      )
      .sort((a, b) => b.quantidade - a.quantidade)
      .map((d) => ({ name: d.zona, value: d.quantidade }));
  }

  async function getBenefits(
    name: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data[]> {
    const aggregations = ['BENEFICIOS', name.toUpperCase()];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data
      .filter((d) => d[name.toLowerCase()] === 'Não')
      .sort((a, b) => b.quantidade - a.quantidade)
      .map((d) => ({ name: d.beneficios, value: d.quantidade }));
  }

  async function getByCRAS(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CRAS'];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    if (response.status === 204) return [];

    return response.data.map<Data>((d) => ({
      name: d.cras,
      value: d.quantidade,
      type: 'CRAS'
    }));
  }

  async function getBySingle(name: string) {
    const aggregations = [name?.toLocaleUpperCase()];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter(
        (d) =>
          d[name?.toLowerCase()] === 'Não' ||
          d[name?.toLowerCase()] === NO_CREAS
      )
      .sort((a, b) => b.quantidade - a.quantidade)
      .reduce((acc, cur) => acc + cur.quantidade, 0);
  }

  async function getTotalByBoth(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CRAS', 'CREAS'];
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    if (response.status === 204) return [];

    return response.data
      .filter(
        (d) =>
          d.cras === 'Não' &&
          (d.creas === 'Não' || d.creas === NO_CREAS)
      )
      .map<Data>((d) => ({
        name: 'cras ou creas',
        value: d.quantidade
      }))
      .sort((a, b) => b.value - a.value);
  }

  async function getTotal(filter?: string) {
    let path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      ibge: filter,
      aggregations: [],
      year: '',
      semester: ''
    });
    const { quantidade } = response.data[0];

    return quantidade;
  }

  return {
    getTotal,
    getByCREAS,
    getByCRAS,
    getByZone,
    getByCity,
    getFiveBiggestByCity,
    getBenefits,
    getZoneByName,
    getTotalByBoth,
    getBySingle,
    getProgramCreas,
    getProgramCras
  };
};

export const socialAssistance = service();
