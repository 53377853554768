import { useDashboardFilter } from '@/hooks';
import { healthPregnant } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const { getPregnantBook, getPreNatal } = healthPregnant;

function resolveColor(name: string) {
  if (name === 'Sim') return '#57AC4B';
  else if (name === 'Não') return '#D4282A';
  else return '#FFE86F';
}

export function usePregnantBook() {
  const [data, setData] = useState<Data[][]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  const empty = data.length === 0;

  useEffect(() => {
    setLoading(true);
    Promise.all(
      [getPregnantBook, getPreNatal].map((request) =>
        request({ ibge, year, semester, zone })
      )
    )
      .then(([pregnantData, preNatalData]) => {
        const pregnantDataTotal = pregnantData.reduce(
          (acc, cur) => acc + cur.value,
          0
        );
        const preNatalDataTotal = preNatalData.reduce(
          (acc, cur) => acc + cur.value,
          0
        );

        const pregnantDataFinal = pregnantData.map((d) => {
          const color = resolveColor(d.name);

          return {
            ...d,
            value: d.value / pregnantDataTotal,
            color
          };
        });

        const preNatalDataFinal = preNatalData.map((d) => {
          const color = resolveColor(d.name);

          return {
            ...d,
            value: d.value / preNatalDataTotal,
            color
          };
        });
        setData([pregnantDataFinal, preNatalDataFinal]);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      error,
      empty,
      loading
    }),
    [data, error, empty, loading]
  );
}
