import { ColorText } from '@/components/styled';
import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';
import { colors } from '@/theme/colors';

import smileIcon from '@/assets/icons/feedback/smile.svg';
import neutralIcon from '@/assets/icons/feedback/neutral.svg';
import sadIcon from '@/assets/icons/feedback/sad.svg';

import { Box, Line } from './styles';

type Note = 'promoter' | 'detractor' | 'neutral';

interface DataNote {
  name: Note;
  value: number;
}
interface FeedbackNoteDistributionProps {
  data: DataNote[];
  onHighlight?: (data: DataNote) => boolean | undefined;
}

interface Values {
  title: string;
  icon: string;
  description: JSX.Element;
  fillColor: string;
}

const componentMap = new Map<Note, Values>([
  [
    'promoter',
    {
      title: 'Promotores',
      icon: smileIcon,
      description: (
        <p>
          Pessoas que atribuíram{' '}
          <ColorText color={colors.green600}>notas</ColorText> entre{' '}
          <ColorText color={colors.green600}>9 a 10</ColorText>. Ou seja,
          tiveram uma ótima experiência e podem se tornar divulgadores naturais
          da plataforma.{' '}
        </p>
      ),
      fillColor: '#68b354'
    }
  ],
  [
    'neutral',
    {
      title: 'Neutros',
      icon: neutralIcon,
      description: (
        <p>
          Pessoas que deram <ColorText color={colors.yellow500}>nota</ColorText>{' '}
          entre <ColorText color={colors.yellow500}>7 e 8</ColorText> para a
          questão apontada. Não fazem propaganda negativa, mas também podem não
          fazer divulgação positiva.
        </p>
      ),
      fillColor: '#FDC729'
    }
  ],
  [
    'detractor',
    {
      title: 'Detratores',
      icon: sadIcon,
      description: (
        <p>
          Pessoas que avaliaram as questões com{' '}
          <ColorText color={colors.red500}>notas</ColorText> entre{' '}
          <ColorText color={colors.red500}>0 e 6</ColorText>. Esse comportamento
          indica insatisfação.
        </p>
      ),
      fillColor: '#B72224'
    }
  ]
]);

export function FeedbackNoteDistribution({
  data,
  onHighlight
}: FeedbackNoteDistributionProps) {
  const total = sumDataTotal(data);
  const dataPercentage = data.map((d) => {
    const percentage = (d.value / total) * 100;
    return {
      ...d,
      percentage,
      percentageFormatted: percentageFormatter(percentage)
    };
  });

  return (
    <div>
      {dataPercentage.map((d) => {
        const { description, fillColor, icon, title } = componentMap.get(
          d.name
        ) as Values;

        return (
          <Box key={d.name} highlight={onHighlight?.(d)}>
            <div className="container-up">
              <div>
                <img src={icon} alt="" />

                <span>{title}</span>
              </div>

              <span>{d.percentageFormatted}</span>
            </div>
            <Line fill={fillColor} percentage={`${d.percentage}%`}>
              <div className="line-fill"></div>
            </Line>
            {description}
          </Box>
        );
      })}
    </div>
  );
}
