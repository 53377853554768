import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { schoolingAdult } from '@/services';
import { DataProps } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { resolveFilters } from '../resolvedFilter';

export function useData() {
  const [data, setData] = useState<DataProps[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [dataLoading, setDataLoading] = useState(false);

  const { gender, colorSkinType, ibge } = useAnalyticsFilter();

  useEffect(() => {
    setDataLoading(true);
    schoolingAdult
      .getMapResults(resolveFilters([gender ?? '', colorSkinType ?? '']))
      .then((res) => setData(res))
      .catch((err) => setError(err))
      .finally(() => setDataLoading(false));
  }, [gender, colorSkinType]);

  const filteredData = useMemo(() => {
    return data
      .filter((d) => (!!gender ? d.sex === gender : d))
      .filter((d) => (!!colorSkinType ? d.skinColor === colorSkinType : d))
      .filter((d) => (!!ibge ? String(d.codIbge) === ibge : d));
  }, [data, gender, colorSkinType, ibge]);

  return { filteredData, error, loading: dataLoading };
}
