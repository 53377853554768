import { BarGroup, CardTagged, ServiceBox } from '@/components/shared';
import { Text } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useQualificationCourse } from '@/servicesHooks/profileSearch';
import { colors } from '@/theme/colors';

const COLORS = [colors.green200, colors.red500, colors.orange500];
const LEGEND = ['Sim', 'Não', 'Não sabe'];

export function CardQualificationCourse() {
  const { tag } = useDashboardFilter();

  const { qualificationData, qualificationError, qualificationLoading } =
    useQualificationCourse();

  return (
    <CardTagged
      tag={tag}
      title="Qualificação profissional das famílias entrevistadas
    "
    >
      <ServiceBox
        empty={qualificationData.length === 0}
        error={qualificationError}
        loading={qualificationLoading}
      >
        <Text>
          <b>
            Veja a quantidade de pessoas que fizeram ou estão interessadas em
            fazer um curso de qualificação.
          </b>{' '}
          Passe o mouse pelo gráfico.
        </Text>

        <BarGroup data={qualificationData} colors={COLORS} legend={LEGEND} />
      </ServiceBox>
    </CardTagged>
  );
}
