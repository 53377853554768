import styled, { css } from 'styled-components';

export const NavBar = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

export const Ul = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

export const Li = styled.li`
  white-space: nowrap;
  & + li {
    margin-left: 1rem;
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      a {
        color: ${theme.yellowDark};
        text-decoration: underline;
      }
    `};
`;
