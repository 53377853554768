import styled from 'styled-components';

import { Box as ParentBox } from '../styles';

export const Box = styled(ParentBox)`
  svg {
    margin-block: 2rem;

    .page {
      cursor: pointer;
      transition: filter 0.2s;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover {
        filter: brightness(0.95);
      }
    }
  }

  .svg-box {
    max-width: 700px;
    margin: 0 auto;
  }
`;
