import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { leaderMovements } from '@/services/leaderMovements';
import { useEffect, useMemo } from 'react';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

export function LeaderAges() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderMovements.ages());
  }, [request]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      let name = '';
      if (d.name.includes('Jovens')) name = 'Jovem (15 a 29 anos)';
      else if (d.name.includes('Adultos')) name = 'Adulto (30 a 59 anos)';
      else name = 'Idoso (a partir de 60 anos)';

      return {
        ...d,
        name
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Faixa etária das lideranças"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <HorizontalBarSimple
          maxValue={6}
          sizeRatio={0.7}
          grid
          isPercentage
          data={formattedData}
          percentageCalculatedOut
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
