export function FilterIcon() {
  return (
    <svg
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.45111 4H14.9022V6H3.45111V4ZM0.0157776 0H18.3376V2H0.0157776V0ZM6.88645 8H11.4669V10H6.88645V8Z"
        fill="currentColor"
      />
    </svg>
  );
}
