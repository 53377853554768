import { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { AiOutlineSearch, AiOutlineMenu } from 'react-icons/ai';
import { Action } from 'big-data';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import { Box } from './styles';
import { tabItems } from './tabs';

import { Tabs, TabsItem } from '../Tabs';
import { GoalsColumn } from '../GoalsColumn';

interface GoalsBoardProps {
  actionsUndone: Action[];
  actionsBlocked: Action[];
  actionsInProgress: Action[];
  actionsDone: Action[];
  loadingUndone?: boolean;
  loadingBlocked?: boolean;
  loadingInProgress?: boolean;
  loadingDone?: boolean;
  errorUndone?: string;
  errorBlocked?: string;
  errorInProgress?: string;
  errorDone?: string;
}

const ALL_PLAN_LABEL = 'Todos os planos';

export function GoalsBoard({
  actionsUndone,
  actionsBlocked,
  actionsInProgress,
  actionsDone,
  loadingUndone,
  loadingBlocked,
  loadingInProgress,
  loadingDone,
  errorUndone,
  errorBlocked,
  errorInProgress,
  errorDone
}: GoalsBoardProps) {
  const [selectedTab, setSelectedTab] = useState<TabsItem | null>(null);
  const [searchText, setSearchText] = useState('');

  const [params] = useSearchParams();
  const axe = params.get('axe');

  const boardRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const scrollToBegin = useCallback(() => {
    const { current } = boardRef;

    if (!current) return;

    const x = (current.scrollWidth - current.clientWidth) / 2;

    current.scrollTo(x, 0);
  }, []);

  useEffect(() => {
    scrollToBegin();
  }, [scrollToBegin]);

  function defineScrollPositionByTabs(tabName: string) {
    const { current } = boardRef;

    if (!current) return;

    const width = current.clientWidth;

    const middleTabs = [
      ALL_PLAN_LABEL,
      'Planos bloqueados',
      'Planos em andamento'
    ];

    if (middleTabs.includes(tabName) && width > 576) {
      return scrollToBegin();
    }

    if (tabName === ALL_PLAN_LABEL && width <= 576) {
      return scrollToBegin();
    }

    if (tabName === 'Planos bloqueados' && width <= 576) {
      const x = (current.scrollWidth - width) / 2;
      return current.scrollTo(x - 0.33 * x, 0);
    }

    if (tabName === 'Planos em andamento' && width <= 576) {
      const x = (current.scrollWidth - width) / 2;
      return current.scrollTo(x + 0.33 * x, 0);
    }

    if (tabName === 'Planos não concluídos') {
      return current.scrollTo(0, 0);
    }

    if (tabName === 'Planos concluídos') {
      return current.scrollTo(current.scrollWidth, 0);
    }
  }

  function handleClickTab(item: TabsItem) {
    if (item.name === ALL_PLAN_LABEL) {
      setSelectedTab(null);
    } else {
      setSelectedTab(item);
    }

    defineScrollPositionByTabs(item.name);
  }

  function handleClickItem(action: Action) {
    navigate(`/dashboard/gestores-acoes/planos-de-acao/${action.id}`);
  }

  return (
    <Box>
      <Tabs
        items={tabItems}
        value={selectedTab?.id}
        onClickTab={handleClickTab}
      />

      <div className="board-content">
        <div className="input-container">
          <Input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Pesquisar plano de ação por título"
            suffix={<AiOutlineSearch />}
          />

          <Link
            className="see-complete-list-Link"
            to={`lista-de-planos-unificada?axe=${axe}`}
          >
            <AiOutlineMenu />
            Ver lista completa
          </Link>
        </div>

        <div className="board-content-tabs" ref={boardRef}>
          <GoalsColumn
            title="Planos não concluídos"
            color="#D4282A"
            actions={actionsUndone}
            loading={loadingUndone}
            error={errorUndone}
            searchText={searchText}
            isSelected={selectedTab ? selectedTab.id === 1 : undefined}
            onClickItem={handleClickItem}
          />

          <GoalsColumn
            title="Planos bloqueados"
            color="#FF7800"
            actions={actionsBlocked}
            loading={loadingBlocked}
            error={errorBlocked}
            searchText={searchText}
            isSelected={selectedTab ? selectedTab.id === 2 : undefined}
            onClickItem={handleClickItem}
          />

          <GoalsColumn
            title="Planos em andamento"
            color="#E0A427"
            actions={actionsInProgress}
            loading={loadingInProgress}
            error={errorInProgress}
            searchText={searchText}
            isSelected={selectedTab ? selectedTab.id === 3 : undefined}
            onClickItem={handleClickItem}
          />

          <GoalsColumn
            title="Planos concluídos"
            color="#4E7345"
            actions={actionsDone}
            loading={loadingDone}
            error={errorDone}
            searchText={searchText}
            isSelected={selectedTab ? selectedTab.id === 4 : undefined}
            onClickItem={handleClickItem}
          />
        </div>
      </div>
    </Box>
  );
}
