import { cois } from '@/services';
import { Data } from 'big-data';
import { useState, useEffect } from 'react';

export function useYearFilterData() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    cois
      .getAvailableYears()
      .then((data) => {
        const sortedData = [...data].sort((a, b) => a.value - b.value);
        setData(sortedData);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return { yearData: data, yearLoading: loading, error };
}
