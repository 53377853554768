import styled, { css } from 'styled-components';
import { Modal as AntModal } from 'antd';
import { colors } from '@/theme/colors';

const anchorStyles = css`
  color: inherit;
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
  }
`;

export const Modal = styled(AntModal)`
  ${({ theme }) =>
    theme.darkMode &&
    css`
      border: 1px solid ${colors.white};
    `}

  .ant-modal {
    &-header,
    &-body,
    &-footer {
      background-color: ${({ theme }) => (theme.darkMode ? colors.black : ``)};
    }

    &-close-x {
      color: ${({ theme }) => (theme.darkMode ? colors.white : ``)};
    }
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;

  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => (theme.darkMode ? colors.white : '#4c5469')};
    letter-spacing: 0.02em;

    a {
      ${anchorStyles};
    }

    &.error {
      color: ${({ theme }) => (theme.darkMode ? colors.red300 : colors.red500)};
      font-size: 0.875rem;
    }
  }

  .agreement {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1rem;

      letter-spacing: 0.02em;

      color: ${({ theme }) => (theme.darkMode ? colors.white : '#4c5469')};

      cursor: pointer;

      a {
        ${anchorStyles};
      }
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    border-radius: 7px;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    padding: 6px 1.5rem;

    &:hover {
      filter: brightness(0.9);
    }

    &.deny {
      border: 2px solid
        ${({ theme }) => (theme.darkMode ? theme.yellowDark : colors.ciano700)};
      background-color: ${({ theme }) =>
        theme.darkMode ? colors.black : colors.white};
      color: ${({ theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
    }

    &.accept {
      border: none;
      background-color: ${({ theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
      color: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
    }
  }
`;
