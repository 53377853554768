import { Data, DataTree } from 'big-data';

enum TreeColors {
  DISABLED = '#8B8C8D',
  BAD = '#A71416',
  WARN = '#EA9753',
  NORMAL = '#EBAD29',
  GOOD = '#57AC4B'
}

const { format: percentFormat } = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const socialAssistanceLegend = [
  {
    name: '0 a 30% não acompanhadas',
    color: TreeColors.GOOD
  },
  {
    name: '30% a 50% não acompanhadas',
    color: TreeColors.NORMAL
  },
  {
    name: '50% a 80% não acompanhadas',
    color: TreeColors.WARN
  },
  {
    name: 'Acima de 80% não acompanhadas',
    color: TreeColors.BAD
  },
  {
    name: 'Sem Informação',
    color: TreeColors.DISABLED
  }
];

const legendCheckers: Record<string, (value: number) => boolean> = {
  '0 a 30% não acompanhadas': (value: number) => value < 0.3,
  '30% a 50% não acompanhadas': (value: number) => value >= 0.3 && value < 0.5,
  '50% a 80% não acompanhadas': (value: number) => value >= 0.5 && value < 0.8,
  'Acima de 80% não acompanhadas': (value: number) => value >= 0.8
};

const isDisabled = (name: string) => name === 'Sem Informação';

const isSelected = (selectedName: string) => (name: string, value: number) => {
  const fn = legendCheckers[name];

  return fn ? fn(value) : isDisabled(selectedName);
};

export function getTreeLegend(selected?: DataTree) {
  if (!selected) return socialAssistanceLegend;

  const checkSelected = isSelected(selected.name);

  return socialAssistanceLegend.map((legend) => ({
    ...legend,
    selected: checkSelected(legend.name, selected.percentageValue)
  }));
}

function getColorByPercentage(percentage: number, colors: typeof TreeColors) {
  if (percentage <= 0.3) return colors.GOOD;
  else if (percentage > 0.3 && percentage <= 0.5) return colors.NORMAL;
  else if (percentage > 0.5 && percentage <= 0.8) return colors.WARN;
  else return colors.BAD;
}

export function getTree(data: Data[], total: number): DataTree {
  return {
    name: 'Assistência Social',
    value: 0,
    percentageValue: 0,
    color: 'transparent',
    children: data.map((d) => {
      const percentage = d.value / total;
      const disabled = isDisabled(d.name);

      const color = disabled
        ? TreeColors.DISABLED
        : getColorByPercentage(percentage, TreeColors);

      return {
        ...d,
        percentageValue: percentage,
        color: color,
        tooltip: `${percentFormat(percentage)}`,
        disabled,
        children: []
      };
    })
  };
}
