import { useState } from 'react';

import styled from 'styled-components';
import { useAccessDataListManagers } from '@/servicesHooks/accessDataActions';
import { cpfReplacer } from '@/utils/string';
import { useAuth } from '@/hooks';
import { Select } from '@/components/shared/SelectBox';
import { ServiceBox } from '@/components/shared';

import {
  CardBase,
  CardContent,
  CardFooter,
  CardHeader,
  Table,
  Pagination
} from '../../components';
import { filterOrderOptions, generateTag } from '../../utils';
import { useFilterContext, useTabContext } from '../../contexts';
import { usePagination } from '../../hooks/usePagination';

export function CardListManagers() {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<string>();

  const { user, isAdm } = useAuth();
  const { city } = useFilterContext();
  const { tab } = useTabContext();

  const { data, loading, error } = useAccessDataListManagers(
    isAdm ? undefined : user?.client.userId,
    city?.ibge,
    tab?.value,
    order
  );

  const tag = generateTag([city?.name, tab?.name]);

  const { dataPaginated, pageSize, totalPages } = usePagination(data, page);

  return (
    <Box>
      <CardHeader title="Listagem de gestores" subtitle={tag} />

      <CardContent>
        <ServiceBox empty={data.length === 0} loading={loading} error={error}>
          <Table
            className="responsive"
            data={dataPaginated.map((d, i) => ({
              name: d.name,
              cpf: cpfReplacer(d.cpf, 'normal'),
              city: d.city,
              numberOfAccess: `${d.numberOfAccess} acessos`
            }))}
            headers={['Nome', 'CPF', 'Município', 'Número de acessos']}
            clickableCell={{
              anchor: {
                redirectFn() {
                  return 'usuario';
                },
                sendData: ({ cpf }) => cpf.replace(/\D/g, '')
              }
            }}
            countStart={(page - 1) * pageSize}
          />
        </ServiceBox>
      </CardContent>

      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            onChange={(value) => setOrder(String(value))}
            value={order}
            options={filterOrderOptions}
            placeholder="Ordenar por..."
          />
          <Pagination
            onPaginate={(page) => setPage(page)}
            page={page}
            totalPages={totalPages}
            resetOnChange={city?.ibge || tab?.value}
            initialPage={1}
          />
        </div>
      </CardFooter>
    </Box>
  );
}

const Box = styled(CardBase)``;
