import styled from 'styled-components';
import { Button } from 'antd';
import { mediaQuery } from '@/theme/mediaQuery';

interface ContainerProps {
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  width: ${(props) => (props.width ? props.width : '100%')};
  height: auto;
  padding: 10px 5px;
  margin: 0 10px;
  border-radius: 5px;

  background: transparent;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  ${mediaQuery.smallScreen} {
    margin: 0;
    padding: 0;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
  gap: 20px;

  width: 100%;

  ${mediaQuery.mediumScreen} {
    flex-wrap: wrap;
  }

  ${mediaQuery.smallScreen} {
    flex-wrap: wrap;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.4;

  color: #ffffff;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
