import { Popover, Tooltip } from 'antd';
import { Data } from 'big-data';

import { Margin } from '@/theme/utils';
import { percentageFormatter } from '@/utils/string';

import { Box, Container, Percentage, ViewBox } from './styles';

interface BarProps {
  colors: string[];
  data: Data[];
}

export function OneBar({ colors, data }: BarProps) {
  const newData = [...(data ?? [])]
    ?.sort((a, b) => b.name.localeCompare(a.name))
    ?.map((d, i) => {
      return {
        ...d,
        percentual: percentageFormatter(d.value),
        color: colors[i]
      };
    });

  return (
    <Box>
      <Container>
        {newData?.map((d, i) => (
          <Popover
            key={d.name}
            getPopupContainer={(trigger) => trigger}
            content={<strong className="bar-name">{d.name}</strong>}
            className="box-popover"
            visible={true}
            placement={i % 2 === 0 ? 'top' : 'bottom'}
          >
            <Tooltip
              title={d.percentual}
              placement={i % 2 === 0 ? 'bottom' : 'top'}
            >
              <ViewBox
                className="box-acomplished"
                style={{ background: d.color }}
                percent={d.value}
              />
            </Tooltip>
          </Popover>
        ))}
      </Container>
      <Percentage />

      <Margin bottom={20} />
    </Box>
  );
}
