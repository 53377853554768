import { googleAnalytics } from '@/services/googleAnaltyics';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

const { topPages } = googleAnalytics;

export function useTopPages(
  minimumDate?: string,
  ibge?: string,
  order?: string,
  pageNumber?: number
) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    topPages
      .get(minimumDate, ibge, order, pageNumber)
      .then((data) => {
        setData(data.content);
        setTotalPages(data.totalPages);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [minimumDate, ibge, order, pageNumber]);

  return {
    data,
    loading,
    error,
    totalPages
  };
}
