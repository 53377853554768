import { useState } from 'react';

import { Select } from '@/components/shared/SelectBox';
import { Box } from './styles';
import { useRejections } from '@/servicesHooks/googleAnalytics';
import { ServiceBox } from '@/components/shared';
import { percentageFormatter } from '@/utils/string';

import {
  CardContent,
  CardFooter,
  CardHeader,
  Pagination,
  Table
} from '../../../components';
import { useTabContext, useFilterContext } from '../../../contexts';
import { generateTag, filterOrderOptionsRejections } from '../../../utils';

const PAGE_SIZE = 5;

export function CardRejectionTable() {
  const [page, setPage] = useState(0);
  const [orderFilterSelected, setOrderFilterSelected] = useState<
    'asc' | 'desc'
  >();

  const { tab } = useTabContext();
  const { city } = useFilterContext();
  const { data, loading, error, totalPages } = useRejections(
    tab?.value,
    city?.ibge,
    orderFilterSelected,
    page,
    PAGE_SIZE
  );

  return (
    <Box>
      <CardHeader
        title="Rejeição por página"
        subtitle={generateTag([city?.name, tab?.name])}
      />

      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent>
          <Table
            data={data.map((d) => ({
              page: d.name,
              rejectionTaxe: percentageFormatter(d.value)
            }))}
            headers={['Páginas', 'Rejeição em %']}
            countStart={page * PAGE_SIZE}
          />
        </CardContent>
      </ServiceBox>

      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            value={orderFilterSelected}
            onChange={(value) =>
              setOrderFilterSelected(value as 'asc' | 'desc')
            }
            placeholder="Filtrar por rejeição"
            options={filterOrderOptionsRejections}
          />
          <Pagination
            page={page}
            onPaginate={(page) => setPage(page)}
            totalPages={totalPages - 1}
            resetOnChange={city?.ibge || tab?.value}
          />
        </div>
      </CardFooter>
    </Box>
  );
}
