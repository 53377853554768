import {
  NormalCardHeader,
  CardBase,
  LargeCardFooter,
  CardContent
} from './styles';

interface CardProps {
  title: string;
  subtitle: string;
}

export function CardHeader({ title, subtitle }: CardProps) {
  return (
    <NormalCardHeader>
      <h4>{title}</h4>
      <span>{subtitle}</span>
    </NormalCardHeader>
  );
}

export { CardBase, LargeCardFooter as CardFooter, CardContent };
