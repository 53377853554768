import { Box } from './styles';
import { useTemporalRejection } from '@/servicesHooks/googleAnalytics';
import { Area, ServiceBox } from '@/components/shared';

import { CardContent, CardHeader } from '../../../components';
import { useTabContext, useFilterContext } from '../../../contexts';
import { generateTag } from '../../../utils';

export function CardRejectionByCity() {
  const { tab } = useTabContext();
  const { city } = useFilterContext();
  const { data, error, loading } = useTemporalRejection(tab?.value, city?.ibge);

  const tag = generateTag([city?.name, tab?.name]);
  const title = `taxa de rejeição ${
    !!city?.name ? `no municipio de ${city.name}` : 'no estado do Ceará'
  }`;

  return (
    <Box>
      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardHeader title={title} subtitle={tag} />

        <CardContent style={{ paddingLeft: '3rem' }}>
          <Area data={data} height={250} isPercentage />
        </CardContent>
      </ServiceBox>
    </Box>
  );
}
