import { showRouteByEnv } from '@/utils/routes';

export interface ILink {
  name: string;
  path: string;
  linkType: 'external' | 'same-page' | 'same-app';
  isPrincipal?: boolean;
  description?: string;
  termPage?: boolean;
}

export const links: ILink[] = [
  {
    name: 'Início',
    path: '#Banner',
    linkType: 'same-page',
    isPrincipal: true
  },
  {
    name: 'Impactos',
    path: '#Impacts',
    linkType: 'same-page'
  },
  {
    name: 'Famílias',
    path: '#Family',
    linkType: 'same-page'
  },
  {
    name: 'Proteção Social',
    path: '#Contact',
    linkType: 'same-page'
  }
];

export const termsLinks: ILink[] = [
  {
    name: 'Início',
    path: '/#Banner',
    linkType: 'same-app',
    isPrincipal: true,
    termPage: true
  },
  {
    name: 'Impactos',
    path: '/#Impacts',
    linkType: 'same-app',
    termPage: true
  },
  {
    name: 'Famílias',
    path: '/#Family',
    linkType: 'same-app',
    termPage: true
  },
  {
    name: 'Proteção Social',
    path: '/#Contact',
    linkType: 'same-app',
    termPage: true
  }
];

const AXE_DASHBOARD_PATH = '/dashboard/eixos';

export const homeCards: ILink[] = [
  {
    name: 'Analíticos e Anuários',
    path: '/analiticos',
    linkType: 'same-app',
    description:
      'Painéis visuais com indicadores como auxílios sociais, educação, moradia, trabalho e renda.',
    isPrincipal: false
  },
  {
    name: 'Termômetro de Dados',
    path: AXE_DASHBOARD_PATH,
    linkType: 'same-app',
    description:
      'Visualizações e estimativas de ações, benefícios e auxílios para enfrentamento da pobreza no Ceará.',
    isPrincipal: true
  },
  {
    name: 'Integra Social',
    path: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
    linkType: 'external',
    description:
      'Sistema de recomendações de políticas públicas para famílias em situação de vulnerabilidade.',
    isPrincipal: true
  }
];

export const bigDataSocialLinks: ILink[] = [
  {
    name: 'Auxílios',
    path: '/analiticos/auxilios/cartao-mais-infancia',
    linkType: 'same-app'
  },
  {
    name: 'Educação',
    path: '/analiticos/educacao/capacitacao',
    linkType: 'same-app'
  },
  {
    name: 'Moradia',
    path: '/analiticos/moradia',
    linkType: 'same-app'
  },
  {
    name: 'Anuário dos Movimentos Sociais',
    path: '/analiticos/anuario',
    linkType: 'same-app'
  },
  {
    ...showRouteByEnv(process.env.REACT_APP_SHOW_LGBT_PAGE!, {
      name: 'População LGBTQIA+',
      path: '/analiticos/lgbtqia+',
      linkType: 'same-app'
    })
  },
  {
    name: 'Termômetro',
    path: AXE_DASHBOARD_PATH,
    linkType: 'same-app'
  }
];

export const dashboardMapSiteLink: ILink[] = [
  {
    name: 'Mapa',
    path: '/dashboard/mapa',
    linkType: 'same-app'
  },
  {
    name: 'Planos de Ação',
    path: '/dashboard/gestores-acoes',
    linkType: 'same-app'
  },
  {
    name: 'Termômetro',
    path: AXE_DASHBOARD_PATH,
    linkType: 'same-app'
  },
  {
    name: 'Integra Social',
    path: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
    linkType: 'external'
  },
  {
    name: 'Feedbacks',
    path: '/dashboard/feedbacks',
    linkType: 'same-app'
  },
  {
    name: 'Dados de Acesso',
    path: '/dashboard/dados-de-acesso',
    linkType: 'same-app'
  }
];

export const thermometerMapSiteLinks: ILink[] = [
  {
    name: 'Assistência Social',
    path: '/dashboard/eixos/assistencia-social',
    linkType: 'same-app'
  },
  {
    name: 'Educação',
    path: '/dashboard/eixos/educacao',
    linkType: 'same-app'
  },
  {
    name: 'Insegurança Alimentar',
    path: '/dashboard/eixos/inseguranca-alimentar',
    linkType: 'same-app'
  },
  {
    name: 'Condições de Moradia',
    path: '/dashboard/eixos/condicoes-de-moradia',
    linkType: 'same-app'
  },
  {
    name: 'Saúde',
    path: '/dashboard/eixos/saude',
    linkType: 'same-app'
  },
  {
    name: 'Trabalho e Renda',
    path: '/dashboard/eixos/trabalho-e-renda',
    linkType: 'same-app'
  }
];

export const analyticsMapSiteLink: ILink[] = [
  {
    name: 'Cartão Mais Infância',
    path: '/analiticos/auxilios/cartao-mais-infancia',
    linkType: 'same-app'
  },
  {
    name: 'Vale Gás',
    path: '/analiticos/auxilios/vale-gas',
    linkType: 'same-app'
  },
  {
    name: 'Visitas Domiciliares',
    path: '/analiticos/auxilios/visitas-domiciliares',
    linkType: 'same-app'
  },
  {
    name: 'Capacitação',
    path: '/analiticos/educacao/capacitacao',
    linkType: 'same-app'
  },
  {
    name: 'Escolaridade Adulto',
    path: '/analiticos/educacao/escolaridade-adulto',
    linkType: 'same-app'
  },
  {
    name: 'Moradia',
    path: '/analiticos/moradia',
    linkType: 'same-app'
  },
  {
    name: 'Movimentos Sociais',
    path: '/analiticos/anuario',
    linkType: 'same-app'
  },
  {
    ...showRouteByEnv(process.env.REACT_APP_SHOW_LGBT_PAGE!, {
      name: 'População LGBTQIA+',
      path: '/analiticos/lgbtqia+',
      linkType: 'same-app'
    })
  }
];
