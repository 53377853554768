import { Layout, Menu, MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { pipe, split, last, equals } from 'ramda';

import { Logo, Logout } from './components';
import { LargeBox } from './styles';

type MenuItem = Required<MenuProps>['items'][number];

const { Sider } = Layout;

interface ScreenLargeProps {
  open: boolean;
  items?: MenuItem[];
  onClickItem?: (e: any) => void;
  onOpen: () => void;
  onClose?: () => void;
}

export function ScreenLarge({
  open,
  items,
  onClickItem,
  onOpen,
  onClose
}: ScreenLargeProps) {
  const { pathname } = useLocation();

  const lastPath = pipe<[string], string[], string>(split('/'), last)(pathname);
  const isLastPathAxe =
    equals(lastPath, 'eixos') || equals(lastPath, 'analiticos');

  return (
    <LargeBox $collapsed={!open}>
      <Sider collapsible collapsed={!open} width={300} trigger={null}>
        <Logo collapsed={!open} />
        <Menu
          theme="dark"
          items={items}
          mode="vertical"
          onClick={onClickItem}
          rootClassName="menu-dashboard"
          selectedKeys={[isLastPathAxe ? '' : lastPath]}
        />
        <Logout collapsed={!open} onSiderCollapse={open ? onClose : onOpen} />
      </Sider>
    </LargeBox>
  );
}
