import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  .sunburst-svg {
    grid-area: sun;
  }

  .arc-loading-group {
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    animation: spin 3s linear forwards infinite;
  }

  .details {
    width: 150px;
    height: 200px;
    position: absolute;

    transform: translateY(50%);
  }

  @media (max-width: 415px) {
    .sunburst-svg {
      width: 270px;
      height: 270px;
    }

    .details {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const LegendBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;

  .source {
    text-align: left;
    margin: 1rem 0;
    font-weight: 700;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
  }
  .legend-title {
    font-size: 0.8rem;
    margin-top: 1.5rem;
  }

  @media (max-width: 1240px) {
    grid-area: legend;
  }
`;
