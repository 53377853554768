import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { schoolingAdult } from '@/services';
import { DataProps } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { resolveFilters } from '../resolvedFilter';

export function useData() {
  const [data, setData] = useState<DataProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { gender, colorSkinType, ibge } = useAnalyticsFilter();

  useEffect(() => {
    setLoading(true);
    schoolingAdult
      .getCourses(
        resolveFilters([gender ?? '', colorSkinType ?? '', ibge ?? ''])
      )
      .then((res) => setData(res))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [gender, colorSkinType, ibge]);

  const filtered = useMemo(() => {
    return data
      .filter((d) => (!!gender ? d.sex === gender : d))
      .filter((d) => (!!colorSkinType ? d.skinColor === colorSkinType : d))
      .filter((d) => (!!ibge ? String(d.codIbge) === ibge : d));
  }, [data, ibge, gender, colorSkinType]);

  return {
    data: filtered,
    loading,
    error
  };
}
