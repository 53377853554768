import styled, { css } from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';
import { darken } from 'polished';

const color = css`
  color: ${(props) => (props.theme.darkMode ? '#fff' : `#555`)};
`;

export const Box = styled.div`
  ${color}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  ${mediaQuery.smallScreen} {
    justify-content: center;
    margin: 10px 0 5px;
  }
`;

export const Svg = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;

  .arrow {
    fill: ${({ theme }) => (theme.darkMode ? '#fff' : `#bababa`)};
  }

  &:hover .arrow {
    fill: ${({ theme }) => (theme.darkMode ? darken(0.2, '#fff') : `#979494`)};
  }

  .arrow-group {
    transform-origin: center center;
  }

  &:active .arrow-group {
    transform: scale(0.96);
  }
`;

export const PageNumber = styled.input`
  ${color}
  margin: 0 5px;
  max-width: 55px;

  border: 1px solid #bababa;
  padding: 2px 5px;
  border-radius: 2px;
  outline: none;

  text-align: right;

  font-size: 0.9rem; ;
`;

export const PageTotal = styled.span`
  ${color}
  margin: 0 5px;

  font-size: 1.1rem;
`;
