import { useConnectObserver } from '@/hooks';

import { Particles } from '../Particles';
import { BannerImage } from '../BannerImage';
import { BannerText } from '../BannerText';
import { Box, Cards, Content } from './styles';
import { PageCard } from '../PageCard';
import { BdsAnalytics, BdsAtom, BdsCzrm } from '@/bdsIcons';
import { colors } from '@/theme/colors';
import { homeCards, ILink } from '@/pages/links';

const Icon: Record<string, () => JSX.Element> = {
  'Analíticos e Anuários': () => <BdsAnalytics color={colors.ciano700} />,
  'Termômetro de Dados': () => <BdsAtom color={colors.ciano700} />,
  'Integra Social': () => <BdsCzrm color={colors.ciano700} />
};

export function Banner() {
  const bannerRef = useConnectObserver<HTMLDivElement>();

  function renderCard(item: ILink) {
    const ItemIcon = Icon[item.name];
    const icon = () => <ItemIcon />;

    return (
      <PageCard
        key={item.name}
        title={item.name}
        description={item.description}
        icon={icon}
        link={item.path}
        linkType={item.linkType}
        isPrincipal={item.isPrincipal}
      />
    );
  }

  return (
    <Box id="Banner" ref={bannerRef}>
      <div className="content-card">
        <Content>
          <BannerText />
          <BannerImage />
        </Content>
        <Cards>{homeCards.map(renderCard)}</Cards>
      </div>
      <Particles />
    </Box>
  );
}
