import { AnimatedTitle, AnimatedTopic } from '@/layout';
import { Collapse } from 'antd';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { RiErrorWarningFill } from 'react-icons/ri';
import {
  Box,
  Title,
  Text,
  BoxText,
  GroupBox,
  ListStyled,
  List,
  BoxTextIcon
} from '../styles';
import { CearaIcon } from './CearaIcon';
import { Accordion } from '../components/accordion';

const { Panel } = Collapse;

export function PrivacityWarn() {
  return (
    <Box>
      <Title>Aviso de privacidade do Big Data Social</Title>
      <Text>
        Olá, seja bem-vinda(o) e obrigado por fazer uso do{' '}
        <b>BIG DATA SOCIAL</b>. Temos a missão de melhorar a gestão pública ao
        integrar e disponibilizar, de forma transparente, informações
        estatísticas da área de Proteção Social do Estado do Ceará.
      </Text>
      <Text>
        Nós entendemos que garantir a sua privacidade e a proteção dos seus
        dados pessoais vai muito além do cumprimento da legislação: é respeitar
        os nossos usuários, bem como honrar os princípios de transparência,
        segurança e proteção. Tudo isso sem perder a simplicidade e a sua
        experiência positiva!
      </Text>
      <Text>Ao ler este Aviso de Privacidade, você fica sabendo:</Text>
      <GroupBox>
        <BoxText>
          O que é feito com seus dados quando você acessa o site{' '}
          <b>www.bigdatasocial.irislab.ce.gov.br/</b>
        </BoxText>
        <BoxText>
          Quais direitos você tem sobre os dados informados e como exercê-los.
        </BoxText>
      </GroupBox>
      <Text>
        Se ainda restarem quaisquer dúvidas sobre o tratamento dos seus dados,
        os canais de atendimento indicados abaixo estão à sua disposição, em
        formato gratuito e facilitado, ok?
      </Text>
      <article>
        <AnimatedTitle text="O que é a plataforma?" />
        <article>
          <Text>
            O <b>BIG DATA SOCIAL</b> é uma plataforma analítica que tem a missão
            de melhorar a gestão pública ao integrar, de forma transparente,
            informações estatísticas da área de Proteção Social do Estado do
            Ceará. Para isso, ele usa tecnologia de big data (“computação em
            nuvem, banco de dados e analíticos”), mostrando indicadores de
            categorias como auxílios sociais, educação, moradia, trabalho e
            renda.
          </Text>
          <BoxTextIcon>
            <CearaIcon />
            <Text>
              Oferecemos à população informações estatísticas claras sobre o
              andamento das políticas públicas do Estado em cada uma dessas
              áreas. Além disso, procuramos fornecer aos gestores públicos
              subsídios para que possam acompanhar programas e auxílios públicos
              e tomar decisões de forma mais eficiente.
            </Text>
          </BoxTextIcon>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Quem gerencia a plataforma?" />
        <article>
          <Text>
            O <b>BIG DATA SOCIAL</b> do Estado do Ceará é gerenciado pela
            Secretaria da Proteção Social, Justiça, Cidadania, Mulheres e
            Direitos Humanos do Estado do Ceará (SPS), inscrita no CNPJ n°
            08.675.169/0001-53, com sede na Rua Soriano Albuquerque, n° 230,
            Bairro Joaquim Távora, CEP 60160-130, a qual figura como
            Controladora de Dados para todos os fins relacionados à plataforma.
          </Text>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Definições importantes" />
        <Text>
          Antes de iniciar o uso do aplicativo e para facilitar a leitura dos
          seguintes termos, saiba que:
        </Text>
        <article>
          <List>
            <li>
              <AnimatedTopic delay={0} number={1}>
                <b>
                  “<b>BIG DATA SOCIAL</b>”
                </b>
                : é uma plataforma analítica que tem a missão de melhorar a
                gestão pública ao integrar informações estatísticas da área de
                Proteção Social do Estado do Ceará. Para isso, ele usa
                tecnologia de big data (“computação em nuvem, banco de dados e
                analíticos”), mostrando indicadores de categorias como auxílios
                sociais, educação, moradia, trabalho e renda. Com o{' '}
                <b>BIG DATA SOCIAL</b>, Você terá acesso a esses indicadores de
                forma transparente, prática e segura;
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={2}>
                <b>“Você”</b>: é o usuário do <b>BIG DATA SOCIAL</b>;
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={3}>
                <b>“Usuário”</b>: significa a pessoa física ou representante,
                preposto ou funcionário autorizado de pessoa jurídica que, em
                geral, efetivamente utiliza o <b>BIG DATA SOCIAL</b>. O Usuário
                pode ser “Usuário Cidadão” ou “Usuário Gestor Público”, tendo,
                de acordo com seu tipo de acesso, navegabilidade mais ou menos
                restrita, conforme explicaremos a seguir. Quando mencionam
                apenas “Usuários”, estes Termos referem-se tanto ao Usuário
                Cidadão quanto ao Usuário Gestor Público.
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={4}>
                <b>“Serviços Ofertados”</b> ou “Serviços”: significa todos os
                serviços ofertados pela SPS por meio do <b>BIG DATA SOCIAL</b>.
              </AnimatedTopic>
            </li>
          </List>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Quais dados pessoais coletamos?" />
        <article>
          <BoxTextIcon>
            <RiErrorWarningFill />
            <Text>
              Em primeiro lugar, é importante esclarecer que o{' '}
              <b>BIG DATA SOCIAL</b> não coleta nenhum tipo de dado pessoal
              diretamente do titular de dados!
            </Text>
          </BoxTextIcon>
          <Text>
            Todos os dados tratados pelo <b>BIG DATA SOCIAL</b> são provenientes
            do compartilhamento das bases de dados da Secretaria de Proteção
            Social, Justiça, Cidadania, Mulheres e Direitos Humanos (SPS),
            provenientes, por exemplo, do Programa Mais Infância, pela coleta
            direta dos agentes do programa, da base do Cadastro Único (CadÚnico)
            ou da base do Cartão Mais Infância (CMIC).
          </Text>
          <Text>
            Até mesmo no momento de realizar seu cadastro na plataforma do BIG
            DATA SOCIAL, para entrar na área logada do sistema, não coletamos
            qualquer tipo de dado pessoal seu. O acesso é autenticado por meio
            do Acesso Cidadão (login único de acesso dos cidadãos aos serviços e
            ferramentas digitais do Governo do Ceará), plataforma distinta do
            <b> BIG DATA SOCIAL</b>, em relação à qual aconselhamos, caso você
            queira saber mais, a leitura do{' '}
            <a href="https://acesso.cearadigital.ce.gov.br/login/aviso-de-privacidade">
              Aviso de Privacidade
            </a>{' '}
            próprio. Ainda, seu acesso poderá ser autenticado pela plataforma do
            GOV.BR, outra plataforma distinta do <b>BIG DATA SOCIAL</b>, e,
            nesse caso, aconselhamos a leitura do{' '}
            <a href="https://www.gov.br/anatel/pt-br/acesso-a-informacao/tratamento-de-dados-pessoais/aviso-de-privacidade">
              Aviso de Privacidade
            </a>{' '}
            dela, caso haja qualquer dúvida sobre o tratamento de dados pessoais
            nesse âmbito.
          </Text>
          <Text>
            Desse modo, o <b>BIG DATA SOCIAL</b> funciona, única e
            exclusivamente, como uma plataforma demonstrativa de informações:
          </Text>
          <GroupBox>
            <BoxText>
              Apenas estatísticas para o público geral, tais como índices,
              percentuais, números de cidadão em tais situações (sem identificar
              diretamente nenhum deles!) etc.
            </BoxText>
            <BoxText>
              Com informações detalhadas e compartilhamento de dados pessoais,
              destinada exclusivamente a gestores públicos do Ceará.
            </BoxText>
          </GroupBox>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Quais dados pessoais tratamos?" />
        <article>
          <Text>
            O <b>BIG DATA SOCIAL</b> trata dados pessoais de duas formas:
          </Text>
          <List>
            <li>
              <AnimatedTopic delay={0} number={1}>
                Tratamos dados pessoais para fornecer à população, de forma
                clara e transparente, informações estatísticas relacionadas à
                Proteção Social do Estado do Ceará, como, por exemplo: dados de
                educação, moradia e auxílios sociais. Esses dados são
                devidamente anonimizados antes de qualquer tipo de
                disponibilização no site do
                <b> BIG DATA SOCIAL</b>;
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={2}>
                Na área logada do <b>BIG DATA SOCIAL</b>, no espaço Integra
                Social, cujo acesso é restrito aos gestores públicos autorizados
                pela SPS, são tratados e visualizados diversos dados pessoais.
                Conforme também esclarecemos em nossos Termos de Uso, apenas
                gestores públicos autorizados conseguem realizar o cadastro e
                ter essa usabilidade e acesso aos dados pessoais.
              </AnimatedTopic>
            </li>
          </List>
          <Text>
            Na área logada do <b>Integra Social</b>, restrita aos gestores
            públicos autorizados, possibilitamos a <b>visualização</b> dos
            seguintes dados pessoais de pessoas em vulnerabilidade social
            (clique em cada área para expandir o conteúdo):
          </Text>
          <Accordion>
            <Panel header="Documentação" key="1">
              <ListStyled>
                <li>Código Familiar</li>
                <li>CPF do responsável familiar</li>
                <li>NIS do responsável domiciliar</li>
              </ListStyled>
            </Panel>
            <Panel header="Endereço" key="2">
              <ListStyled>
                <li>Nome da rua ou avenida e número</li>
                <li>Complemento</li>
                <li>Bairro</li>
                <li>Cidade</li>
                <li>CEP</li>
                <li>Zona</li>
              </ListStyled>
            </Panel>
            <Panel header="Contato" key="3">
              <ListStyled>
                <li>Telefone</li>
                <li>E-mail</li>
              </ListStyled>
            </Panel>
            <Panel header="Programas e auxílios recebidos" key="4">
              <ListStyled>
                <li>CRAS</li>
                <li>CREAS</li>
                <li>Pagamento do Cartão Mais Infância</li>
                <li>Demais programas do Governo do Ceará</li>
              </ListStyled>
            </Panel>
            <Panel header="Alertas*" key="5">
              <ListStyled>
                <li>
                  Situações de alerta específicas de cada caso, como coleta de
                  lixo ocorrida com baixa frequência na semana, água utilizada
                  para beber etc.
                </li>
              </ListStyled>
            </Panel>
            <Panel header="Dados dos membros da família" key="6">
              <ListStyled>
                <li>Nome</li>
                <li>Idade</li>
                <li>CPF</li>
                <li>NIS</li>
                <li>Informação sobre convívio ou não com parceiro(a)</li>
                <li>Endereço completo</li>
                <li>Telefone</li>
                <li>E-mail</li>
                <li>Programas e auxílios recebidos</li>
              </ListStyled>
            </Panel>
            <Panel header="Dados sobre renda e trabalho" key="7">
              <ListStyled>
                <li>Renda do último mês</li>
                <li>Se há curso de qualificação feito</li>
                <li>Se trabalha</li>
                <li>Fonte de renda</li>
                <li>Se deseja fazer um curso de qualificação</li>
                <li>Número de trabalhadores nos membros da família</li>
                <li>Média de capital familiar</li>
                <li>Despesa total da família</li>
                <li>Se há trabalho infantil na família</li>
                <li>Média de capital familiar</li>
                <li>Despesa total da família</li>
                <li>Despesas com água</li>
                <li>Despesas com comida</li>
                <li>Despesas com remédios</li>
                <li>Despesas com gás</li>
                <li>Despesas com energia</li>
                <li>Despesas com aluguel</li>
                <li>Despesas com transporte</li>
                <li>Se está afastado do trabalho</li>
                <li>Se trabalhou na última semana</li>
                <li>Renda do trabalho no último mês</li>
                <li>Se trabalha em zona rural</li>
                <li>Tipo de trabalho</li>
                <li>Se trabalhou no último ano</li>
                <li>Quantos meses trabalhou no último ano</li>
                <li>Renda do trabalho no último ano </li>
                <li>Outras rendas mensais</li>
                <li>Renda de aposentadoria mensal</li>
                <li>Rendas de doações mensais</li>
                <li>Pensão alimentícia mensal</li>
                <li>Renda de seguro-desemprego mensal</li>
              </ListStyled>
            </Panel>
            <Panel header="Dados sobre educação" key="8">
              <ListStyled>
                <li>Se está matriculado em uma escola</li>
                <li>Série e o grau de ensino</li>
                <li>Nome da escola atual</li>
                <li>Se frequentou escola e, se sim, até qual série</li>
                <li>Se sabe ler e escrever</li>
                <li>Curso atual</li>
              </ListStyled>
            </Panel>
            <Panel header="Dados sobre habitação" key="9">
              <ListStyled>
                <li>Endereço completo </li>
                <li>Zona</li>
                <li>Espécie de domicílio</li>
                <li>Características do local do domicílio</li>
                <li>Número de residentes na casa</li>
                <li>Número de famílias na casa</li>
                <li>
                  Se existe calçamento/pavimentação no trecho do logradouro
                </li>
                <li>Se água é utilizada para beber</li>
                <li>Forma de abastecimento de água</li>
                <li>Se possui água canalizada em pelo menos um cômodo</li>
                <li>Total de cômodos do domicílio</li>
                <li>
                  Número de cômodos usados permanentemente como dormitório para
                  os moradores do domicílio
                </li>
                <li>Frequência na semana de coleta do lixo</li>
                <li>Forma de coleta/destino do lixo</li>
                <li>Se é utilizada iluminação no domicílio</li>
                <li>Material predominante das paredes externas</li>
                <li>Material predominante do piso</li>
                <li>Se existe banheiro ou sanitário</li>
                <li>Forma de escoamento do banheiro sanitário</li>
                <li>
                  Se existem lugares públicos para as crianças brincarem próximo
                  à casa
                </li>
                <li>Se existem atividades culturais próximas ao domicílio</li>
                <li>
                  Se existem locais para atividades esportivas próximos à casa
                </li>
                <li>Se a casa é localizada em área de conflito/violência</li>
              </ListStyled>
            </Panel>
            <Panel header="Dados sobre saúde" key="10">
              <ListStyled>
                <li>Se a criança possui alguma deficiência congênita </li>
                <li>Se a criança possui assistência de terceiros</li>
                <li>Se possui caderneta de vacina da criança</li>
                <li>Se a criança está com as vacinas em dia</li>
                <li>
                  Se a criança participa de algum programa de visitação
                  domiciliar
                </li>
                <li>
                  Se a criança é acompanhada pela equipe da saúde da família
                </li>
                <li>Se a criança mamou na primeira hora após nascer</li>
                <li>Até que idade a criança mamou no peito</li>
                <li>Se há gestante no domicílio</li>
              </ListStyled>
            </Panel>
            <Panel header="Dados sobre nutrição" key="11">
              <ListStyled>
                <li>Se o alimento diminuiu após a pandemia de COVID-19 </li>
                <li>Se passou a comer menos por falta de dinheiro</li>
                <li>Se possui preocupação relacionada a falta de alimento</li>
                <li>Se a alimentação acabou antes de haver dinheiro</li>
                <li>Se falta dinheiro para ter alimentação saudável</li>
                <li>Se há plantação de alimentos para consumo</li>
                <li>Se falta refeição </li>
              </ListStyled>
            </Panel>
            <Panel
              header="Dados para recomendação a ser criada pelo Usuário Gestor Público*"
              key="12"
            >
              <Text>
                *Neste caso, caso queira, o Usuário Gestor Público pode, com
                base nos dados visualizados, criar e enviar recomendação a uma
                instituição ou agente.
              </Text>
              <ListStyled>
                <li>Nome da instituição a quem se direciona a recomendação</li>
                <li>Nome do agente (opcional)</li>
                <li>E-mail da instituição ou do agente</li>
                <li>Telefone da instituição ou do agente</li>
                <li>
                  Informação sobre qual ação deve ser tomada pela instituição ou
                  pelo agente
                </li>
              </ListStyled>
            </Panel>
          </Accordion>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Como utilizamos os dados pessoais?" />
        <article>
          <Text>
            Além das finalidades já narradas acima, de acordo com nossa missão,
            também utilizamos dados pessoais para:
          </Text>
          <ListStyled>
            <li>
              <b>Executar políticas públicas</b>: como esclarecemos, o
              tratamento de dados (inclusive sensíveis, como, por exemplo, dados
              de gênero, etnia, existência de deficiência etc.) realizado pelo{' '}
              <b>BIG DATA SOCIAL</b> ocorre para que possamos possibilitar a
              execução de políticas públicas assertivas, de acordo com a nossa
              missão institucional.
            </li>

            <li>
              <b>
                Assegurar o cumprimento de obrigações legais e o exercício de
                nossos direitos
              </b>
              : seus dados pessoais poderão ser utilizados para atender
              obrigações dispostas em lei, regulamentações de órgãos
              governamentais, autoridades fiscais, Poder Judiciário e/ou
              qualquer outra autoridade competente. Podem ser utilizados também
              para resguardar nossos direitos e nossas atuações em processos
              judiciais, administrativos e arbitrais.
            </li>
            <li>
              <b>Legítimo Interesse do Estado do Ceará</b>: o tratamento de
              dados também poderá ocorrer para atender aos legítimos interesses
              do Estado do Ceará.
            </li>
          </ListStyled>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Com quem compartilhamos os dados pessoais?" />
        <article>
          <Text>
            Conheça algumas das hipóteses em que os dados pessoais poderão ser
            compartilhados:
          </Text>
          <ListStyled>
            <li>
              Com autoridades, entidades governamentais ou outros terceiros,
              para executar os propósitos que este Aviso de Privacidade
              descreve, desenvolver parcerias e proteger os interesses do{' '}
              <b>BIG DATA SOCIAL</b>;
            </li>

            <li>
              Com autoridades e entidades governamentais para a proteção dos
              interesses do <b>BIG DATA SOCIAL</b> em qualquer tipo de conflito,
              incluindo ações judiciais e processos administrativos;
            </li>

            <li>
              Por meio de ordem judicial ou a pedido de autoridades
              administrativas que tenham o direito legal para isso.
            </li>
          </ListStyled>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Como protegemos os dados pessoais?" />
        <article>
          <Text>
            Os dados tratados pelo <b>BIG DATA SOCIAL</b> são armazenados em
            servidores próprios ou contratados por nós, o que garantirá sempre a
            segurança de tais informações.
          </Text>
          <Text>
            Nesse sentido, aplicamos as medidas técnicas e organizacionais
            adequadas para proteger os seus dados pessoais, em observância às
            boas práticas no mercado.
          </Text>
          <Text>Os seguintes padrões de segurança serão adotados:</Text>
          <GroupBox>
            <BoxText>
              O acesso aos dados será restrito, e somente gestores públicos
              específicos serão autorizados a acessá-los, nos estritos limites
              da necessidade para atingirem as finalidades legítimas as quais os
              dados se destinam e desde que se comprometam legalmente a cumprir
              com os limites deste Aviso e dos Termos de Uso da plataforma.
            </BoxText>
            <BoxText>
              Os aparelhos utilizados para realizar o tratamento de dados
              pessoais pelo <b>BIG DATA SOCIAL</b> possuem senhas, antivírus e
              serão manuseados por pessoas que possuem boas práticas em
              segurança da informação.
            </BoxText>
          </GroupBox>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Por quanto tempo armazenamos os dados pessoais?" />
        <article>
          <Text>
            Pelo tempo que for necessário para cumprir as finalidades pelas
            quais são tratados, a menos que um período de retenção mais longo
            seja necessário para cumprir obrigações legais ou resguardar
            direitos – os nossos e os dos titulares de dados.
          </Text>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Quais são os direitos dos titulares dos dados pessoais?" />
        <article>
          <Text>
            Você, enquanto titular de dados pessoais, possui os seguintes
            direitos relativos às suas informações pessoais:
          </Text>
          <ListStyled>
            <li>
              Ter a confirmação de que os seus dados são tratados pelo{' '}
              <b>BIG DATA SOCIAL</b>.
            </li>
            <li>Acessar os dados coletados.</li>
            <li>
              Corrigir os seus dados caso estejam incompletos, desatualizados ou
              não forem exatos.
            </li>
            <li>
              Pedir que seus dados sejam excluídos, bloqueados, eliminados ou
              tornados anônimos, se forem desnecessários, excessivos ou tratados
              de forma diferente do que garante a lei.
            </li>
            <li>
              Pedir a portabilidade dos seus dados, ou seja: solicitar que os
              dados armazenados sejam transferidos para outra instituição.
            </li>
            <li>
              Pedir que os dados tratados com consentimento sejam devidamente
              excluídos.
            </li>
            <li>
              Receber informações sobre as entidades públicas ou privadas com as
              quais o <b>BIG DATA SOCIAL</b> compartilha os seus dados pessoais.
            </li>
            <li>
              Se for o caso, ser informada(o) sobre a possibilidade de consentir
              o uso de dados, além de ser informada(o) sobre as consequências no
              caso de não consentir.
            </li>
            <li>Revogar o consentimento.</li>
          </ListStyled>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Como entrar em contato para esclarecimentos?" />
        <article>
          <Text>
            Para enviar qualquer dúvida, problema, sugestão ou elogio sobre o
            <b> BIG DATA SOCIAL</b>, Você pode entrar em contato por meio de:
          </Text>

          <div className="box-icon">
            <div className="icon">
              <HiOutlineMail size={50} />
            </div>

            <Text>assessoria.gabinete@sps.ce.gov.br</Text>
          </div>

          <div className="box-icon">
            <div className="icon">
              <BsTelephone size={50} />
            </div>

            <Text>(85) 3101.4597 / (85) 3101.4601</Text>
          </div>
        </article>
      </article>

      <article>
        <AnimatedTitle text="Atualizações" />
        <article>
          <Text>
            Pode ser que algumas informações aqui disponibilizadas sejam
            atualizadas ou alteradas de tempos em tempos. Por isso, aconselhamos
            que você consulte este <b>Aviso de Privacidade</b> periodicamente.
          </Text>
        </article>
      </article>
    </Box>
  );
}
