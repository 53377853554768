import { Link } from 'react-router-dom';
import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const ColumnLinks = () => {
  return (
    <Wrapper>
      <Row>
        <Link to="/info/mapa-site">Mapa do site</Link>
      </Row>
      <Row>
        <Link to="/info/aviso-privacidade">Aviso de privacidade</Link>
      </Row>
      <Row>
        <Link to="/info/termos">Termos de uso</Link>
      </Row>
    </Wrapper>
  );
};

const Row = styled.div`
  padding: 2px 0;
`;

const Wrapper = styled.div`
  & > ${Row} > a {
    display: inline-block;
    padding: 5px 0;
    font-size: 1rem;
    font-weight: bold;
    text-rendering: geometricPrecision;
    text-decoration: none;
    color: ${(props) => props.theme.text};
    opacity: 0.9;
    &:hover {
      text-decoration: underline;
      opacity: 1;
      &:active {
        opacity: 0.8;
      }
    }
  
  }

  ${mediaQuery.largeScreen} {
    width: fit-content;
    justify-self: center;
  }

  ${mediaQuery.mediumScreen} {
    width: fit-content;
    justify-self: center;
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      & > ${Row} > a {
        color: ${theme.yellowDark};
        text-decoration: underline;
      }
    `}
`;
