import { useState, useEffect } from 'react';
import { Svg, Box, PageNumber, PageTotal } from './Pagination.styles';

interface SVGButton {
  onClick?: () => void;
}

const Next = ({ onClick }: SVGButton) => (
  <Svg viewBox="0 0 19 37" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <g className="arrow-group">
      <path
        className="arrow"
        d="m0.81198 32.879c-0.7534 0.8072-0.77621 2.1374-0.050935 2.9709 0.72528 0.8336 1.924 0.8549 2.6774 0.0476l14.492-15.529c0.1521-0.163 0.2744-0.3472 0.3668-0.5444 0.6348-0.7508 0.6343-1.8739-0.0413-2.626l-14.395-16.024c-0.73816-0.82171-2.0027-0.88944-2.8244-0.15129-0.82171 0.73815-0.88944 2.0027-0.15128 2.8244l13.253 14.753z"
      />
    </g>
  </Svg>
);

const Back = ({ onClick }: SVGButton) => (
  <Svg viewBox="0 0 19 37" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <g className="arrow-group">
      <path
        className="arrow"
        d="m18.188 32.879c0.7534 0.8072 0.77621 2.1374 0.05093 2.9709-0.72528 0.8336-1.924 0.8549-2.6774 0.0476l-14.492-15.529c-0.1521-0.163-0.2744-0.3472-0.3668-0.5444-0.6348-0.7508-0.6343-1.8739 0.0413-2.626l14.395-16.024c0.73816-0.82171 2.0027-0.88944 2.8244-0.15129 0.82171 0.73815 0.88944 2.0027 0.15128 2.8244l-13.253 14.753z"
      />
    </g>
  </Svg>
);

interface PaginationProps {
  page: number;
  size: number;
  onBack?: () => void;
  onNext?: () => void;
  onChange?: (page: number) => void;
}

export { usePagination } from './usePagination';

export function Pagination({
  page,
  size,
  onBack,
  onNext,
  onChange
}: PaginationProps) {
  const [text, setText] = useState(page.toString());

  useEffect(() => {
    setText(page.toString());
  }, [page]);

  function handleChange(e: any) {
    const newPage = parseInt(e.target.value, 10);

    setText(e.target.value);

    if (onChange) {
      onChange(isNaN(newPage) ? page : newPage);
    }
  }

  function handleBlur() {
    if (text === '') {
      setText(page.toString());
    }
  }

  return (
    <Box>
      <Back onClick={onBack} />
      <PageNumber value={text} onChange={handleChange} onBlur={handleBlur} />/
      <PageTotal>{size}</PageTotal>
      <Next onClick={onNext} />
    </Box>
  );
}
