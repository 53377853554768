import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
  border: 1px solid ${colors.gray400};
  border-radius: 7px;
  padding: 1.6rem;

  min-height: 245px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      min-width: 240px;
      .card-title {
        h4 {
          font-size: 0.8rem;
          text-transform: uppercase;
          letter-spacing: 1.2px;
          line-height: 1;
          color: ${({ theme }) => (theme.darkMode ? '#FFF' : colors.green700)};
        }

        span {
          font-size: 1.2rem;
          font-weight: bold;
          color: ${({ theme }) => (theme.darkMode ? '#FFF' : colors.green700)};
        }
      }
      .card-icon {
        svg {
          width: 35px;
          height: 35px;
          path {
            fill: ${({ color }) => (color ? color : colors.black)};
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 0.4rem;
      .register.card-btn {
        background: ${({ theme }) =>
          theme.darkMode ? colors.black : 'transparent'};
        color: white;
        align-items: center;
        display: flex;
        padding: 0.5rem 1rem;

        path {
          fill: ${({ theme }) =>
            theme.darkMode ? colors.yellow500 : colors.ciano700};
        }

        &:hover {
          background-color: ${({ theme }) =>
            theme.darkMode ? colors.white : colors.ciano700};

          path {
            fill: ${({ theme }) =>
              theme.darkMode ? theme.textDark : colors.white};
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }
      .card-btn {
        background: ${({ theme }) =>
          theme.darkMode ? colors.black : 'transparent'};
        color: ${({ theme }) =>
          theme.darkMode ? colors.white : colors.ciano700};
        border: 1px solid
          ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
        border-radius: 7px;
        padding: 0.5rem 1.5rem;
        transition: all 0.3s;

        &:hover {
          background-color: ${({ theme }) =>
            theme.darkMode ? colors.white : colors.ciano700};

          color: ${({ theme }) =>
            theme.darkMode ? theme.textDark : colors.white};

          &:active {
            transform: scale(0.98);
          }
        }
      }
    }

    @media (max-width: 475px) {
      flex-direction: column;
      gap: 0.8rem;
      align-items: baseline;
    }
  }

  .card-content {
    background: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.gray100};
    border-left: 10px solid ${({ color }) => (color ? color : '#000')};
    border-radius: 7px;
    padding: 1rem;
    margin-top: 1rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 630px) {
      grid-template-columns: 1fr;
    }

    .ods {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      transition: color 0.2s;

      h4 {
        letter-spacing: 1.25625px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.8rem;
      }

      &:hover h4 {
        color: ${({ theme }) => colors.green600};
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }
  }
`;
