import { AntSelect } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import {
  educationCzRM,
  insecurityFoodCzRM,
  socialAssistanceCzRM
} from '@/services';
import { healthChildCzrm } from '@/services/healthAgentsCzrm';
import { healthPregnantCzRm } from '@/services/healthPregnantCzRm';
import { jobIncomeCzRM } from '@/services/jobIncomeCzRM';
import { livingConditionsCzrm } from '@/services/livingConditionsCZrm';
import { Margin } from '@/theme/utils';
import citiesCE from '@/constants/ceara.json';
import { Input, Select } from 'antd';
import { CzRMData } from 'big-data';
import { DashboardCzrmTableProps } from 'big-data/service';
import { useEffect, useMemo, useState } from 'react';
import { ServiceBox, Show } from '..';
import { AntModal } from '../AntModal';
import { ExportToCSV } from './ExportToCSV';
import { replacer } from './helpers';
import { category, indicators, situation } from './indicators';
import { Content, Row } from './styles';
import { Table } from './Table';
import { Pagination as TablePagination } from '../Pagination';

type Axe =
  | 'socialAssistance'
  | 'education'
  | 'insecurityFood'
  | 'health'
  | 'healthChild'
  | 'jobIncome'
  | 'livingConditions';

interface CzRMTableModalProps {
  isOpen: boolean;
  onCancel: () => void;
  title: string;
  axe: Axe;
  selectedSubAxe?: string;
  hasIndicators?: boolean;
  hasCategory?: boolean;
}

const { Option } = Select;
const { Search } = Input;

const requests = new Map<
  Axe,
  (params: DashboardCzrmTableProps) => Promise<CzRMData>
>([
  ['health', healthPregnantCzRm.getCitizens],
  ['healthChild', healthChildCzrm.getCitizens],
  ['socialAssistance', socialAssistanceCzRM.getCitizens],
  ['education', educationCzRM.getCitizens],
  ['insecurityFood', insecurityFoodCzRM.getCitizens],
  ['jobIncome', jobIncomeCzRM.getCitizens],
  ['livingConditions', livingConditionsCzrm.getCitizens]
]);

const PAGE_SIZE = 10;

export function CzRMTableModal({
  isOpen,
  onCancel,
  title,
  axe,
  selectedSubAxe,
  hasIndicators,
  hasCategory
}: CzRMTableModalProps) {
  const {
    ibge: filterIbge,
    city: filterCity,
    year: filterYear,
    semester: filterSemester,
    zone: filterZone,
    tag
  } = useDashboardFilter();

  const [czrmData, setCzRMData] = useState<CzRMData>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [selectedIbge, setSelectedIbge] = useState('');
  const [cpf, setCpf] = useState('');
  const [nis, setNis] = useState('');
  const [page, setPage] = useState(0);
  const [currentAxe, setCurrentAxe] = useState(axe);

  const [selectedIndicator, setSelectedIndicator] = useState('');

  const request = useMemo(() => {
    return requests.get(currentAxe);
  }, [currentAxe]);

  const totalPages = useMemo(() => {
    return czrmData?.totalPages || 0;
  }, [czrmData]);

  function onSelectIndicator(value?: string) {
    if (value) {
      const [, indicator] = value.split('*');
      setSelectedIndicator(indicator);
      setPage(0);
    }
  }

  function onSelectCategory(value?: string) {
    if (value) {
      const [, category] = value.split('*');
      setCurrentAxe(category as Axe);
      setPage(0);
      setSelectedIndicator('');
    }
  }

  function onSelectChange(value?: string) {
    if (value) {
      const [, _ibge] = value.split('_');
      setPage(0);
      setSelectedIbge(_ibge);
    }
  }

  function onClear() {
    setPage(0);
    setSelectedIbge('');
    setCpf('');
    setNis('');
    setSelectedIndicator('');
  }

  function onCpfSearch(value: string) {
    setCpf(replacer(value));
    setPage(0);
  }

  function onNisSearch(value: string) {
    setNis(replacer(value));
    setPage(0);
  }

  useEffect(() => {
    if (isOpen && request) {
      const subAxe = hasIndicators ? selectedIndicator : selectedSubAxe;
      const situationValue = hasIndicators
        ? situation[currentAxe][selectedIndicator]
        : undefined;

      setLoading(true);
      request({
        pageNumber: page,
        subAxeName: subAxe,
        ibge: selectedIbge || filterIbge,
        year: filterYear,
        semester: filterSemester,
        zone: filterZone,
        cpf,
        nis,
        situation: situationValue
      })
        .then((response) => {
          setCzRMData(response);
          setError(null);
        })
        .catch((error) => {
          setCzRMData(undefined);
          setError(error);
        })
        .finally(() => setLoading(false));
    } else {
      onClear();
    }
    // eslint-disable-next-line
  }, [
    page,
    selectedSubAxe,
    selectedIbge,
    filterYear,
    filterSemester,
    filterZone,
    filterIbge,
    cpf,
    nis,
    isOpen,
    selectedIndicator,
    currentAxe
  ]);

  const indicatorsAxe = hasIndicators ? indicators[currentAxe] : [];
  const categoryAxe = hasCategory ? category[axe] : [];
  const categoryDefaultValue =
    categoryAxe.length > 0 ? categoryAxe[0].name : '';

  return (
    <AntModal visible={isOpen} onCancel={onCancel} width={1100}>
      <Content>
        <article>
          <h1>{title}</h1>
          <Show when={!!filterCity || !!filterYear || !!filterZone}>
            <span className="tag">{tag}</span>
          </Show>
          <p>
            Big Data Social reúne dados fornecidos pelo <b>Integra Social</b>{' '}
            (Gestão de Relacionamento com o Cidadão) e a Plataforma Mais
            Infância — banco de informações do Programa Mais Infância do Ceará.
            Busque informações através dos campos abaixo para obter detalhes:
          </p>
        </article>

        <Row>
          <Show
            when={!filterCity && !filterSemester && !filterYear}
            fallback={<Margin bottom={20} />}
          >
            <AntSelect
              allowClear
              showSearch
              placeholder="Buscar por município"
              bordered={false}
              className="czrm-input-search"
              onChange={(value) => onSelectChange(String(value))}
              defaultValue={filterCity}
            >
              {citiesCE.features.map((city) => (
                <Option
                  key={city.properties.id}
                  value={`${city.properties.name}_${city.properties.id}`}
                >
                  {city.properties.name}
                </Option>
              ))}
            </AntSelect>
          </Show>

          <Show when={!!hasCategory} fallback={<Margin bottom={20} />}>
            <AntSelect
              defaultValue={categoryDefaultValue}
              showSearch
              placeholder="Buscar por categoria"
              bordered={false}
              className="czrm-input-search"
              onChange={(value) => onSelectCategory(String(value))}
            >
              {categoryAxe.map((category, i) => (
                <Option
                  key={category.name}
                  value={`${category.name}*${category.value}`}
                >
                  {category.name}
                </Option>
              ))}
            </AntSelect>
          </Show>

          <Show when={!!hasIndicators} fallback={<Margin bottom={20} />}>
            <AntSelect
              allowClear
              showSearch
              placeholder="Buscar por indicador"
              bordered={false}
              className="czrm-input-search"
              onChange={(value) => onSelectIndicator(String(value))}
            >
              {indicatorsAxe.map((indicator, i) => (
                <Option
                  key={indicator.name}
                  value={`${indicator.name}*${indicator.value}`}
                >
                  {indicator.name}
                </Option>
              ))}
            </AntSelect>
          </Show>

          <Search
            bordered={false}
            className="czrm-input-search"
            placeholder="Buscar por CPF"
            onSearch={onCpfSearch}
            allowClear
            maxLength={14}
          />

          <Search
            bordered={false}
            className="czrm-input-search"
            placeholder="Buscar por NIS"
            onSearch={onNisSearch}
            allowClear
            maxLength={14}
          />
        </Row>

        <div className="table-wrapper">
          <ServiceBox empty={!!!czrmData} error={error} loading={loading}>
            <Table data={czrmData ? czrmData.data : []} />
          </ServiceBox>
        </div>

        <ExportToCSV
          request={request}
          params={{
            subAxeName: selectedSubAxe,
            ibge: selectedIbge || filterIbge,
            year: filterYear,
            semester: filterSemester,
            zone: filterZone,
            cpf,
            nis,
            totalPages,
            pageSize: PAGE_SIZE
          }}
          fileTitle={`listagem ${selectedSubAxe?.toLowerCase()}`}
        />

        <TablePagination
          page={page}
          totalPages={totalPages}
          onPaginate={(page) => setPage(page)}
        />
      </Content>
    </AntModal>
  );
}
