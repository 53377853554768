import { Observation } from 'big-data';
import { isNil } from 'ramda';
import { AiOutlineClose } from 'react-icons/ai';
import { CommentDetails } from '../CommentDetails';

import { Box } from './styles';

interface CommentToAnswerProps {
  comment?: Observation;
  onRemove?: () => void;
}

export function CommentToAnswer({ comment, onRemove }: CommentToAnswerProps) {
  function handleRemove() {
    if (onRemove) {
      onRemove();
    }
  }

  if (isNil(comment)) return null;

  function handleClick() {
    const element = document.getElementById(`${comment?.id}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Box onClick={handleClick}>
      <div className="comment">
        <p className="label">Resposta para: </p>
        <p className="note">{comment.note}</p>

        <CommentDetails comment={comment} />
      </div>

      <div className="close-button">
        <AiOutlineClose onClick={handleRemove} />
      </div>
    </Box>
  );
}
