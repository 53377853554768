import { AntSelect } from '@/components/styled';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useProfilesData } from '../../hooks';

const { Option } = AntSelect;

export function Filter() {
  const { profilesData, profilesLoading } = useProfilesData();

  const { profilePopulation, onChangeProfilePopulation } = useAnalyticsFilter();

  return (
    <>
      <BdSelect
        placeholder="Selecione o perfil de beneficiados"
        onChange={onChangeProfilePopulation}
        value={!!profilePopulation ? profilePopulation : undefined}
        loading={profilesLoading}
      >
        {profilesData?.map((p, i) => (
          <Option key={p.name} value={p.name}>
            {p.name}
          </Option>
        ))}
      </BdSelect>
    </>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
