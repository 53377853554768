import { Box, Gradient } from './styles';

const colors = ['#0080FF', '#00FF00', '#FF8000', '#FF0000'];

export function HeatLegend() {
  return (
    <Box>
      <Gradient colors={colors} />

      <p>
        Escala de cores representando a concentração de famílias no mapa. Azul
        representa uma baixa concentração de famílias enquanto vermelho
        representa uma alta concentração de famílias
      </p>
    </Box>
  );
}
