import { BarSimple, Card } from '@/components/shared';
import { useService } from '@/hooks';
import { leaderProfileService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card1() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderProfileService.getAgeGroup(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Faixa etária dos líderes das organizações"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <BarSimple data={data} makeValuesBetweenZeroAndOne alwaysShowValue />
    </Card>
  );
}
