import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  gap: 1rem;

  grid-template-columns: repeat(4, calc(25% - 0.75rem)); // 3rem / 4 = 0.75rem

  grid-template-areas:
    'a a b c'
    'd d e f'
    'g h h i'
    'j j z z';

  .g-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 1520px) {
    grid-template-columns: initial;
    grid-template-areas:
      'a a b'
      'd d c'
      'e f f'
      'g g g'
      'h h h'
      'i j j';
  }

  @media (max-width: 960px) {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'a a'
      'b c'
      'd d'
      'e f'
      'g i'
      'h h'
      'j j';
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g'
      'h'
      'i'
      'j';
  }
`;
