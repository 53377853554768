import { BigDataRoute } from '@/types/routes';

export function getHref(pathname: string, path: string) {
  const paths = pathname.replace('/', '').split('/');

  const findIndex = paths.findIndex((p) => p === path);

  return `/${paths.splice(0, findIndex + 1).join('/')}`;
}

export function findRouteNameByPath(
  path: string,
  routes: BigDataRoute[]
): string {
  if (!routes) return '';

  let foundedPathname =
    routes.find((route) => route.path === path && !route.hiddenAtBreadcrumb)
      ?.name ?? '';

  routes.forEach((route) => {
    foundedPathname += findRouteNameByPath(path, route.children ?? []);
  });

  return foundedPathname.trim();
}

export function findRouteByName(name: string, routes: BigDataRoute[]): string {
  if (!routes) return '';

  let foundedPathname =
    routes.find((route) => route.name === name && !route.hiddenAtBreadcrumb)
      ?.name ?? '';

  routes.forEach((route) => {
    foundedPathname += findRouteByName(name, route.children ?? []);
  });

  return foundedPathname.trim();
}
