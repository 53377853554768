import { useDashboardFilter } from '@/hooks';
import { foodInsecurity } from '@/services/foodInsecurity';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useTotalCities(hasFilter = true) {
  const [cities, setCities] = useState<Data[]>([]);

  const [loadingTotal, setLoadingTotal] = useState(false);
  const [errorTotal, setErrorTotal] = useState<Error | null>(null);

  const total = useMemo(
    () => cities.reduce((acc, cur) => acc + cur.value, 0),
    [cities]
  );

  const totalCities = useMemo(() => cities.length, [cities]);

  const { city, year, semester, zone, isNull } = useDashboardFilter();

  useEffect(() => {
    setLoadingTotal(true);
    if (hasFilter) {
      foodInsecurity
        .getAllCitiesThermometerValue(city, year, semester, zone)
        .then((data) => {
          setCities(data);
          if (data.length === 0) isNull();
        })
        .catch((err) => setErrorTotal(err))
        .finally(() => setLoadingTotal(false));
    } else {
      foodInsecurity
        .getAllCitiesThermometerValue()
        .then((data) => setCities(data))
        .catch((err) => setErrorTotal(err))
        .finally(() => setLoadingTotal(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, year, semester, hasFilter, zone]);

  return { total, totalCities, loadingTotal, errorTotal };
}
