import styled from 'styled-components';

import { GenericGrid, GenericGridItem } from '@/components/styled';

export const Grid = styled(GenericGrid)`
  grid-template-columns: repeat(2, minmax(150px, 1fr));

  grid-template-areas: 'a b';
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b';
  }

  .piped-water-answers {
    padding: 2rem 0;
  }
`;

export const GridItem = styled(GenericGridItem)``;
