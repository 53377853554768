import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { Attendance } from './Attendance';
import { HealthCareType } from './HealthCareType';
import { HealthLgbtPhobia } from './HealthLgbtPhobia';
import { HealthRespect } from './HealthRespect';
import { HealthService } from './HealthService';
import { MainAttendance } from './MainAttendance';
import { PreventionTypes } from './PreventionTypes';
import { ProfessionalsPreparation } from './ProfessionalsPreparation';
import { Box } from './style';

export function HealthData() {
  return (
    <Box>
      <ThirdTitle>Saúde da população LGBTQIA+</ThirdTitle>
      <GroupBlockBox>
        <HealthCareType />
        <Attendance />
      </GroupBlockBox>
      <GroupBlockBox>
        <MainAttendance />
        <PreventionTypes />
      </GroupBlockBox>
      <GroupBlockBox>
        <ProfessionalsPreparation />
        <HealthService />
      </GroupBlockBox>
      <GroupBlockBox>
        <HealthRespect />
        <HealthLgbtPhobia />
      </GroupBlockBox>
    </Box>
  );
}
