import styled from 'styled-components';

export const Box = styled.div`
  p {
    color: #ab1e11;
    font-style: italic;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
`;

interface GradientProps {
  colors: string[];
}

export const Gradient = styled.div<GradientProps>`
  background-image: linear-gradient(
    to right,
    ${({ colors }) => colors.join(', ')}
  );

  height: 20px;
  width: 100%;
`;
