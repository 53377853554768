import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useWhoAnswerSearch() {
  const [whoAnswers, setWhoAnswers] = useState<Data[]>([]);
  const [loadingWhoAnswers, setLoadingWhoAnswers] = useState(false);
  const [errorWhoAnswers, setErrorWhoAnswers] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingWhoAnswers(true);
    profileSearch
      .getWomanAnswer(ibge, year, semester, zone)
      .then((data) => setWhoAnswers(data))
      .catch((err) => setErrorWhoAnswers(err))
      .finally(() => setLoadingWhoAnswers(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      whoAnswers,
      loadingWhoAnswers,
      errorWhoAnswers
    }),
    [whoAnswers, loadingWhoAnswers, errorWhoAnswers]
  );
}
