import { CardAccessedPages } from './CardAccessedPages';
import { CardListManagers } from './CardListManager';
import { CardMeanTimePerPage } from './CardMeanTimePerPage';
import { CardPermanencyTime } from './CardPermanencyTime';
import { CardPlanActionsByCity } from './CardPlanActionsByCity';
import { CardRejectionByCity } from './CardRejectionByCity';
import { CardRejectionTable } from './CardRejectionTable';
import { CardSessionsByCity } from './CardSessionsByCity';
import { CardSessionsByCityMap } from './CardSessionsByCityMap';
import { CardUsedDevices } from './CardUsedDevices';
import { CardUserType } from './CardUserType';

export const analyticsHomeComponents = [
  {
    id: 1,
    area: 'a',
    components: [CardUserType]
  },
  {
    id: 2,
    area: 'b',
    components: [CardPermanencyTime, CardMeanTimePerPage]
  },
  {
    id: 3,
    area: 'c',
    components: [CardRejectionTable]
  },
  {
    id: 4,
    area: 'd',
    components: [CardRejectionByCity, CardSessionsByCity],
    direction: 'row'
  },
  {
    id: 5,
    area: 'e',
    components: [CardSessionsByCityMap]
  },
  {
    id: 6,
    area: 'f',
    components: [CardAccessedPages]
  },
  {
    id: 7,
    area: 'g',
    components: [CardPlanActionsByCity]
  },
  {
    id: 8,
    area: 'h',
    components: [CardUsedDevices]
  },
  {
    id: 9,
    area: 'i',
    components: [CardListManagers]
  }
];
