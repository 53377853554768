import { useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { Select, Switch, Tooltip } from 'antd';

import { capitalize } from '@/utils/string';
import { useAuth } from '@/hooks';

import { Box } from './styles';
import cearaCities from './cities.json';

import { MultipleSelect } from '../MultipleSelect';
import { INDICATORS_OPTIONS, AXE_OPTIONS } from '../../utils/objects';
import { inject, getID } from '../../utils/functions';

interface FilterProps {
  city?: string;
  disabled?: boolean;
  loading?: boolean;
  enabledCity?: boolean;
  onChangeCity?: (value: string) => void;
  onEnabledCity?: (value: boolean) => void;
  onRemoveAxe?: (value?: string) => void;
  onSubmit?: (values: string[]) => void;
  onReset?: () => void;
}

const MAX_SELECT = 3;

const cities = Object.entries(cearaCities).map(([key, value]) => ({
  label: capitalize(key),
  value
}));

export function Filter({
  city,
  disabled,
  loading,
  enabledCity,
  onChangeCity,
  onEnabledCity,
  onRemoveAxe,
  onSubmit,
  onReset
}: FilterProps) {
  const [axes, setAxes] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [indicators, setIndicators] = useState<string[]>([]);

  function getIndicatorOptions(idx: number) {
    const options = INDICATORS_OPTIONS[selected[idx + 1]] ?? [];

    return options.filter((option) => !indicators.includes(option.value));
  }

  function handleSelectedChange(idx: number, value: string) {
    setSelected((current) => inject(current, idx, value));
    setIndicators((current) => inject(current, idx, ''));
  }

  function handleIndicatorChange(idx: number, value: string) {
    setIndicators((current) =>
      inject(current, idx, value).map((indicator, i) =>
        indicator === value && idx !== i ? '' : indicator
      )
    );
  }

  function handleAddAxe() {
    setAxes((current) =>
      current.length < MAX_SELECT ? [...current, getID()] : current
    );
  }

  function handleRemoveAxe(idx: number) {
    setAxes((current) => current.filter((_, cidx) => cidx !== idx - 1));
    setSelected((current) => current.filter((_, cidx) => cidx !== idx));
    setIndicators((current) => current.filter((_, cidx) => cidx !== idx));

    if (onRemoveAxe) {
      onRemoveAxe(indicators[idx]);
    }
  }

  function handleRemoveAllFilters() {
    setAxes([]);
    setSelected([]);
    setIndicators([]);

    if (onReset) {
      onReset();
    }
  }

  function handleChangeCity(value: string) {
    setAxes([]);
    setSelected([]);
    setIndicators([]);

    if (onChangeCity) {
      onChangeCity(value);
    }
  }

  function handleSubmit() {
    if (onSubmit) {
      onSubmit(indicators);
    }
  }

  const hasEmptyIndicatorValue = indicators.some((indicator) => !indicator);
  const isEmptyIndicator = indicators.length === 0;
  const hasSameAxesAndIndicators = axes.length + 1 === indicators.length;
  const hasNoIndicators = isEmptyIndicator || hasEmptyIndicatorValue;

  const isFilterButtonDisabled =
    loading || hasNoIndicators || !hasSameAxesAndIndicators;

  const isResetButtonDisabled = loading || (!disabled && hasNoIndicators);
  const { isAdm } = useAuth();

  return (
    <Box>
      {isAdm && (
        <div className="city">
          <span className="label">
            Município
            <span className="optional-text"> (Opcional)</span>
          </span>
          <div className="inputs">
            <Select
              options={cities}
              optionFilterProp="label"
              disabled={!enabledCity}
              className="city-select"
              value={city}
              showSearch
              onChange={handleChangeCity}
            />
            <Switch
              checked={enabledCity}
              onChange={onEnabledCity}
              size="small"
            />
          </div>
        </div>
      )}

      <div className="select">
        <MultipleSelect
          label="Eixo 1"
          indicatorLabel="Indicador 1"
          axesOptions={AXE_OPTIONS}
          indicatorOptions={INDICATORS_OPTIONS[selected[0]] ?? []}
          axesValue={selected[0] ?? ''}
          indicatorValue={indicators[0]}
          showIndicator={selected[0] !== undefined}
          showAdd={axes.length === 0}
          onChangeAxe={(value) => handleSelectedChange(0, value)}
          onChangeIndicator={(value) => handleIndicatorChange(0, value)}
          onAddAxe={handleAddAxe}
        />

        {axes.map((select, idx) => (
          <MultipleSelect
            key={select}
            label={`Eixo ${idx + 2}`}
            indicatorLabel={`Indicador ${idx + 2}`}
            axesValue={selected[idx + 1] ?? ''}
            indicatorValue={indicators[idx + 1]}
            axesOptions={AXE_OPTIONS}
            indicatorOptions={getIndicatorOptions(idx)}
            showIndicator={selected[idx + 1] !== undefined}
            showAdd={axes.length < MAX_SELECT && idx === axes.length - 1}
            showDelete={true}
            onChangeAxe={(value) => handleSelectedChange(idx + 1, value)}
            onChangeIndicator={(value) => handleIndicatorChange(idx + 1, value)}
            onAddAxe={handleAddAxe}
            onRemoveAxe={() => handleRemoveAxe(idx + 1)}
          />
        ))}
      </div>

      <div className="buttons">
        <button disabled={isFilterButtonDisabled} onClick={handleSubmit}>
          Aplicar filtros
        </button>

        <Tooltip title="Clique para limpar todos os eixos">
          <button
            className="remove-button"
            onClick={handleRemoveAllFilters}
            disabled={isResetButtonDisabled}
          >
            <FiTrash /> Limpar filtros
          </button>
        </Tooltip>
      </div>
    </Box>
  );
}
