import { actions } from '@/services';
import { CityData } from 'big-data';
import { useEffect, useState } from 'react';

import * as R from 'ramda';
import { sumDataTotal } from '@/utils/data';

const groupByCity = R.groupBy((data: CityData) => data.name);

export function useActionAnalytics(
  minimumDate?: string,
  ibge?: string,
  status?: string
) {
  const [data, setData] = useState<CityData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    actions
      .getActionAnalytics(minimumDate, ibge, status)
      .then((data) => {
        const dataGroupedByCity = groupByCity(data);
        const entries = Object.values(dataGroupedByCity).map<CityData>(
          (data) => {
            return {
              ...data[0],
              value: sumDataTotal(data)
            };
          }
        );
        return entries.sort((a, b) => b.value - a.value);
      })
      .then((cityData) => setData(cityData))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [minimumDate, ibge, status]);

  return {
    data,
    loading,
    error
  };
}
