import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

export interface Visit {
  total: number;
  totalDone: number;
  cities: {
    name: string;
    semester: number;
    year: number;
    codIbge: string;
    total: number;
    value: number;
    urbanValue: number;
    ruralValue: number;
  }[];
}

const service = () => {
  const resource = `/proporcao-visitas`;
  const abortController = new AbortController();

  function cancelRequest() {
    abortController.abort();
  }

  async function getVisitedFamilies(
    ibge?: string,
    year?: string,
    semester?: string
  ) {
    const aggregations = ['MUNICIPIO', 'IBGE'];

    const path = `${resource}`;

    const configRequest = ensureAxiosParamOptions({
      signal: abortController.signal,
      params: {
        aggregations: getQuery(aggregations),
        ibge,
        year,
        semester
      }
    });
    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    const { data } = response;

    const total = data.reduce((acc, cur) => acc + cur.visitas_planejadas, 0);
    const totalDone = data.reduce(
      (acc, cur) =>
        acc +
        cur.qtd_visita_rural +
        cur.qtd_visita_urbana +
        cur.qtd_visita_null,
      0
    );

    const cities = data.map((d) => ({
      name: d.municipio,
      semester: d.semestre,
      year: d.ano,
      codIbge: d.ibge,
      total: d.visitas_planejadas,
      value: d.qtd_visita_rural + d.qtd_visita_urbana + d.qtd_visita_null,
      ruralValue: d.qtd_visita_rural,
      urbanValue: d.qtd_visita_urbana + d.qtd_visita_null
    }));

    return {
      total,
      totalDone,
      cities
    } as Visit;
  }

  return {
    cancelRequest,
    getVisitedFamilies
  };
};

export const visits = service();
