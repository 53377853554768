import { CardTagged, Outdoor, ServiceBox } from '@/components/shared';
import { ColorText, TitleText, Text } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantSupply } from '@/servicesHooks/healthPregnant';
import { colors } from '@/theme/colors';
import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';

import supplyIcon from '@/assets/icons/supply.svg';
import xIcon from '@/assets/icons/x-icon.svg';
import questionIcon from '@/assets/icons/question.svg';

const dataMap = new Map([
  [
    'Sim',
    {
      color: colors.green200,
      description: 'receberam suplementação',
      img: supplyIcon,
      alt: 'Icone de suplementos',
      textColor: colors.green600
    }
  ],
  [
    'Não',
    {
      color: colors.red400,
      description: 'não receberam suplementação',
      img: xIcon,
      alt: 'icone de x',
      textColor: colors.red500
    }
  ],
  [
    'Não sabe',
    {
      color: colors.yellow400,
      description: 'não sabem se receberam suplementação',
      img: questionIcon,
      alt: 'icone de não sabe',
      textColor: colors.yellow500
    }
  ]
]);

export function CardPregnantSupply() {
  const { tag } = useDashboardFilter();

  const { data, ...rest } = usePregnantSupply();
  const total = sumDataTotal(data);

  const pregnantSupply = data
    ?.filter((data) => data.name !== 'Não respondeu')
    ?.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: percentageFormatter(((item.value ?? 0) / total) * 100)
      }),
      {} as Record<string, string>
    );

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a porcentagem de grávidas nos domicílios que receberam
          suplementação de ácido fólico e sulfato ferroso?
        </TitleText>

        {Object.entries(pregnantSupply).map(([key, value]) => {
          const data = dataMap.get(key);

          return (
            <Outdoor
              key={key}
              flexDirection="column"
              alignItems="center"
              src={data?.img ?? ''}
              alt={data?.alt ?? 'Icone'}
              error={rest.error}
              color={data?.color}
            >
              <Text>
                <ColorText color={data?.textColor}>{value}</ColorText> das
                grávidas{' '}
                <ColorText color={data?.textColor}>
                  {data?.description}
                </ColorText>
              </Text>
            </Outdoor>
          );
        })}
      </ServiceBox>
    </CardTagged>
  );
}
