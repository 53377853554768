import { CardTagged, HorizontalGridBar, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useCities } from '@/servicesHooks/healthAgents';
import { colors } from '@/theme/colors';
import { Margin } from '@/theme/utils';
import { useTheme } from 'styled-components';

const filterBy = 'Não'; //  Crianças não acompanhadas

export function CardBar() {
  const { darkMode } = useTheme();
  const { tag, city } = useDashboardFilter();

  const { cities, citiesError, citiesLoading } = useCities(filterBy);
  const citiesParsed = cities
    .map((data) => ({
      name: data.name,
      value: data.percentual
    }))
    .sort((a, b) => b.value - a.value);

  const cityName = !!city ? `no município de ${city}` : `por município`;

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={cities.length === 0}
        error={citiesError}
        loading={citiesLoading}
      >
        <TitleText>
          Qual a porcentagem de crianças, com até 8 anos de idade, sem
          acompanhamento de saúde da família, {cityName}?
        </TitleText>
        <Margin top={40} />
        <HorizontalGridBar
          data={citiesParsed}
          color={darkMode ? colors.ming : colors.ciano700}
          grid
          isPercentage
          percentageCalculatedOut
          hasPagination
        />
      </ServiceBox>
    </CardTagged>
  );
}
