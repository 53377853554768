import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const ErrorBox = styled.div`
  min-height: 180px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #ab1e11;
`;

export const LoadingBox = styled.div`
  min-height: 180px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyBox = styled.div`
  min-height: 180px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors.gray500};
  text-align: center;
`;
