import { HeaderAccessibility, HeaderApresentation, Footer } from '@/layout';
import { ObserverProvider } from '@/hooks';
import { links } from '@/pages/links';

import { Banner, Benefits, Impacts } from './components';
import { Header } from './styles';
import { useEffect, useMemo, useState } from 'react';

type IIntersectedSection = 'Banner' | 'Impacts' | 'Family' | 'Contact';

const paths: IIntersectedSection[] = ['Banner', 'Impacts', 'Family', 'Contact'];

export function Home() {
  const [intersectedSection, setIntersectedSection] =
    useState<IIntersectedSection>('Banner');

  const linksWithActivation = useMemo(() => {
    return links.map((link) => ({
      ...link,
      isActive: `#${intersectedSection}` === link.path
    }));
  }, [intersectedSection]);

  function handleObserver(entries: IntersectionObserverEntry[]) {
    const entry = entries[0];

    if (entry.isIntersecting) {
      setIntersectedSection(entry.target.id as IIntersectedSection);
    }
  }

  useEffect(() => {
    const fullPath = window.location.href;

    const path = paths.find((path) => fullPath.includes(path)) || paths[0];

    const scrollDiv = document.getElementById(path)?.offsetTop ?? 0;
    window.scrollTo(0, scrollDiv - 141);
    setIntersectedSection(path);
  }, []);

  return (
    <ObserverProvider onObserver={handleObserver}>
      <Header>
        <HeaderAccessibility />
        <HeaderApresentation links={linksWithActivation} animateColor />
      </Header>
      <Banner />
      <Impacts />
      <Benefits />
      <Footer />
    </ObserverProvider>
  );
}
