import { Loading, ServiceBox2 } from '@/components/shared';
import { colors } from '@/theme/colors';
import { Action } from 'big-data';
import { useEffect, useRef, useState } from 'react';
import { IoAlertCircle } from 'react-icons/io5';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { CityTag, GoalsTag } from '../Tags';
import { ActionCommentCounter } from './ActionCommentCounter';
import {
  AccordionContainer,
  AccordionItem as SAccordionItem,
  Pointer
} from './styles';

type ActionStatus = 'UNDONE' | 'BLOCKED' | 'IN_PROGRESS' | 'DONE';

interface AccordionProps {
  opened?: ActionStatus;
  status: ActionStatus;
  actions: Action[];
  toggle(currentAccordionOpened: ActionStatus): void;
  name: string;
  color: string;
  onEndReached(visible: boolean): void;
  planActionsTotal: number;
  loading?: boolean;
  error?: string;
  hasMore?: boolean;
}

export function AccordionItem({
  opened,
  actions,
  onEndReached,
  toggle,
  status,
  name,
  color,
  planActionsTotal,
  loading,
  error,
  hasMore
}: AccordionProps) {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [intersectionVisible, setIntersectionVisible] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const { current: scrollCurrent } = scrollRef;
    const { current: intersectionCurrent } = intersectionRef;

    if (!scrollCurrent || !intersectionCurrent) return;

    const options = {
      root: scrollCurrent,
      rootMargin: '0px',
      threshold: 0.75
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      setIntersectionVisible(entry.isIntersecting);
    }, options);

    observer.observe(intersectionCurrent);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    onEndReached(intersectionVisible);
  }, [intersectionVisible, onEndReached]);

  useEffect(() => {
    const { current: scrollCurrent } = scrollRef;
    if (scrollCurrent) scrollCurrent.scrollTo(0, 0);
  }, [opened]);

  return (
    <AccordionContainer
      className={`unified-list-container ${
        opened === status ? 'expanded' : ''
      }`}
    >
      <button
        className={`accordion-button-control ${
          opened === status ? 'expanded' : ''
        }`}
        onClick={() => toggle(status)}
      >
        <Pointer color={color}>{name}</Pointer>
        <div className="indicators">
          <span>({planActionsTotal})</span>
          <MdOutlineArrowDropDown
            size={24}
            color={theme.darkMode ? colors.white : colors.black}
          />
        </div>
      </button>

      <div
        ref={scrollRef}
        className={`accordion-content ${opened === status ? 'expanded' : ''}`}
      >
        <ServiceBox2 error={error} empty={actions.length === 0}>
          {actions.map((action) => (
            <SAccordionItem
              key={action.id}
              to={`/dashboard/gestores-acoes/planos-de-acao/${action.id}`}
              className="plan-action-anchor-card"
            >
              <div className="left-container">
                <strong>{action.title}</strong>
                <div className="status-container">
                  <CityTag city={action.city} />
                  <GoalsTag action={action} />
                </div>
              </div>

              <div className="right-container">
                <ActionCommentCounter counter={action.qtdObservation} />

                {action.qtdNotification > 0 && (
                  <span className="warning">
                    <IoAlertCircle color={colors.yellow500} size={21} />
                    Novas atualizações
                  </span>
                )}
              </div>
            </SAccordionItem>
          ))}
        </ServiceBox2>
        <div ref={intersectionRef} className="loader-container">
          {hasMore && <Loading size={25} />}
        </div>
      </div>
    </AccordionContainer>
  );
}
