import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const Box = styled.div`
  width: 40px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 0;
    background: transparent;

    transition: background 0.2s;

    &.active {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: ${({ theme }) => (theme.darkMode ? '2px solid #fff' : 0)};

      background: ${({ theme }) => theme.background};
      z-index: 1;

      svg {
        fill: ${(props) => props.theme.greenDark};
        ${({ theme }) =>
          theme.darkMode &&
          css`
            fill: white;
          `};
      }
    }
  }

  ${mediaQuery.mediumScreen} {
    order: -1;
  }

  ${mediaQuery.smallScreen} {
    order: -1;
  }
`;
