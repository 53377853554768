import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

interface DashboardTitleContextProps {
  title: string;
  setTitle: (title: string) => void;
}

interface DasboardTitleProviderProps {
  children: ReactNode;
}

const DashboardTitleContext = createContext<DashboardTitleContextProps>(
  {} as DashboardTitleContextProps
);

export function DashboardTitleProvider({
  children
}: DasboardTitleProviderProps) {
  const [title, setTitle] = useState('');

  const contextProviderValue = useMemo(() => ({ title, setTitle }), [title]);

  return (
    <DashboardTitleContext.Provider value={contextProviderValue}>
      {children}
    </DashboardTitleContext.Provider>
  );
}

export function useDashboardTitle(newTitle: string) {
  const { title, setTitle } = useContext(DashboardTitleContext);

  useEffect(() => {
    setTitle(newTitle);
  }, [newTitle, setTitle]);

  return title;
}
