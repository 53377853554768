import { max, min } from '@/utils/math';
import { Data } from 'big-data';
import * as d3 from 'd3';

export const MapColors: Record<string, string[]> = {
  '#8B8C8D': ['#8B8C8D1A', '#8B8C8D80', '#8B8C8DE6', '#344E56'],
  '#2C403F': ['#d3e0ce', '#acd4a1', '#87c878', '#344E56'],
  '#87c878': ['#1872091A', '#18720980', '#187209E6', '#344E56'],
  '#BC6117': ['#F4C8A4', '#EFB280', '#EA9753', '#BC6117'],
  '#B47C07': ['#FFD897', '#FCC85A', '#EBAD29', '#B47C07'],
  '#4E7345': ['#CAE6C6', '#8AC881', '#57AC4B', '#4E7345'],
  '#A71416': ['#EB989A', '#E26567', '#F53C3E', '#A71416'],
  '#1D696F': ['#D5F9FC', '#2A9AA2', '#1D696F', '#081F20'],
  '#2A9AA2': ['#D5F9FC', '#2A9AA2', '#1D696F', '#081F20'],
  '#D8F9FD': ['#B1F4FB', '#8BEEF9', '#51E5F6', '#16DDF3'],
  '#FFCA15': ['#FFFFFF', '#FFECAD', '#FFE285', '#FFCA15']
};

export const ColorScale = (max: number, color: string) =>
  d3
    .scaleQuantize()
    .domain([0, max])
    .range(MapColors[color] as any[]);

const num = new Intl.NumberFormat('pt-BR', {
  maximumFractionDigits: 1
});

export const legendByColor = (
  data: Data[],
  color: string,
  percentage?: boolean,
  maxValue?: number
) => {
  const values = data.map((d) => d.value);
  const maxOfValues = max(values);
  const minOfValues = min(values);

  const percentageOrMax = percentage ? 100 : maxOfValues;
  const percentageOrMin = percentage ? 0 : minOfValues;

  const maxDataValue = maxValue ? maxValue : percentageOrMax;
  const minDataValue = maxValue ? 0 : percentageOrMin;

  if (!isFinite(maxDataValue) || !isFinite(minDataValue)) {
    return [];
  }

  const interval = data.length > 1 ? 5 : 2;
  const intervalSize = Math.ceil((maxDataValue - minDataValue) / 4);

  return Array(interval)
    .fill(0)
    .map((_, idx) => {
      if (idx === 0) {
        return {
          min: num.format(0),
          max: num.format(0),
          color: '#FFFFFF'
        };
      }
      const isSecondInterval = interval === 2;
      const idxVar = idx === 1 ? 1 : intervalSize * (idx - 1);

      const min = isSecondInterval ? 1 : idxVar;
      const max = isSecondInterval ? maxDataValue : intervalSize * idx;

      return {
        min: num.format(min),
        max: num.format(max > maxDataValue ? maxDataValue : max),
        color: MapColors[color][idx - 1]
      };
    });
};
