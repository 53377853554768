import { useDashboardFilter } from '@/hooks';
import { foodInsecurity } from '@/services/foodInsecurity';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useAnimalConsume(filter?: string) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    foodInsecurity
      .getAnimalsConsume(filter, ibge, year, semester)
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [filter, ibge, year, semester]);

  return useMemo(
    () => ({
      animalConsume: data,
      loadingAnimalConsume: loading,
      errorAnimalConsume: error
    }),
    [data, loading, error]
  );
}
