import { Section } from '@/layout';
import { Card, D3Map } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { socialMovements } from '@/services/socialMovements';
import { useEffect, useState } from 'react';
import { Data } from 'big-data';
import { D3MapMacroregions } from '@/components/shared/D3MapMacroregions';
import { RadioChangeEvent } from 'antd';
import { useTheme } from 'styled-components';
import { RadioGroup } from '@/components/styled';

const options = [
  { label: 'Municípios', value: 'municipios' },
  { label: 'Macrorregiões', value: 'macrorregioes' }
];

export function MovementsMap() {
  const [mapaType, setMapaType] = useState<'municipios' | 'macrorregioes'>(
    'municipios'
  );

  const { darkMode } = useTheme();
  const color = darkMode ? colors.ming100 : colors.ciano700;

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setMapaType(value);
  };

  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.localMovements());
  }, [request]);

  const {
    data: regions,
    loading: loadingRegions,
    request: requestRegions
  } = useService<Data>();

  useEffect(() => {
    requestRegions(() => socialMovements.regionsMovements());
  }, [requestRegions]);

  return (
    <Section>
      <Card
        title={`Distribuição das sedes por ${
          mapaType ? 'macrorregião' : 'município'
        }`}
        loading={loading && loadingRegions}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <RadioGroup
          options={options}
          onChange={onChange}
          optionType="button"
          defaultValue={mapaType}
        />
        {mapaType === 'municipios' ? (
          <D3Map
            mapDescription="Passe o mouse pelo mapa para visualizar a quantidade de sedes por município no Ceará"
            data={data}
            color={color}
            rotule="Sede"
            filteringCity={city}
          />
        ) : (
          <D3MapMacroregions
            data={regions}
            color={color}
            intervalCostume={[
              { min: 1, max: 9 },
              { min: 10, max: 24 },
              { min: 25, max: 50 },
              { min: 50, max: 50 }
            ]}
          />
        )}
      </Card>
    </Section>
  );
}
