import styled, { keyframes } from 'styled-components';
import { colors } from '@/theme/colors';

const showIn = keyframes`
  0% {
    height: 0px;
  }

  90% {
    height: 100px;
  }

  100% {
    height: max-content;
  }
`;

const showIn2 = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Box = styled.div`
  background-color: ${colors.ciano700}4D;
  animation: ${showIn} 0.2s linear forwards;
  border-radius: 7px;

  display: grid;
  grid-auto-flow: column;

  grid-template-rows: minmax(70px, 1fr);
  grid-template-columns: 1fr 15px;

  padding: 0.5rem;
  cursor: pointer;

  .comment,
  .close-button {
    opacity: 0;
    animation: ${showIn2} 0.1s 0.2s linear forwards;
  }

  .comment {
    border-left: 2px solid ${colors.ciano700};
    padding-block: 0.1rem;
    padding-inline: 0.7rem 0;

    .label {
      font-weight: 700;
    }

    .note {
      max-width: 900px;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &-details {
      width: 100%;
      margin-top: 0.6rem;

      font-size: 0.7rem;

      .author {
        font-weight: 700;
        margin-inline-end: 0.6rem;
      }
    }
  }

  .close-button {
    svg {
      cursor: pointer;
      transition: fill 0.2s;

      &:hover {
        fill: ${colors.red500};
      }
    }
  }
`;
