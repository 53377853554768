import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';

const infoMapping = new Map([
  [
    'Sim, todos frequentam.',
    {
      color: colors.ciano700
    }
  ],
  [
    'Sim, alguns frequentam.',
    {
      color: colors.green400
    }
  ],
  [
    'Não, não tem idade para frequentar.',
    {
      color: colors.yellow600
    }
  ],
  [
    'Não, nenhum frequenta.',
    {
      color: colors.red400
    }
  ]
]);

export function Card10() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getStudentChildren(ibge));
  }, [request, ibge]);

  const donutData = useMemo(
    () =>
      data.map((d) => {
        const { color } = infoMapping.get(d.name) || {};

        return {
          ...d,
          color
        };
      }),
    [data]
  );

  return (
    <Card
      title="Porcentagem dos filhos das mulheres atendidas que frequentam escola"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <DonutPopulated
        data={donutData}
        thickness={40}
        donutHeight={300}
        donutWidth={300}
        flexDirection="column"
      />
    </Card>
  );
}
