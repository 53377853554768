import { Axes, HealthIndicators, JobIncomingIndicators } from './constants';
import { getData } from './requests';
import { DataWithType, RequestDataConfig } from './types';

export const getTotal = (results: DataWithType[]) => {
  return results.reduce((acc, cur) => acc + cur.value, 0);
};

export const getPercentage = (total: number, results: DataWithType[]) => {
  return results.map((result) => ({
    ...result,
    value: (result.value * 100) / total
  }));
};

export const getResultsKey = (axe: string) => {
  const healthIndicators = [
    HealthIndicators.GSPN,
    HealthIndicators.CSES,
    HealthIndicators.CEVI
  ];

  const jobIndicators = [
    JobIncomingIndicators.Salary,
    JobIncomingIndicators.Job
  ];

  switch (axe) {
    case Axes.InsecurityFood:
      return ['INSEGURANÇA ALIMENTAR'];
    case Axes.Education:
      return ['EDUCAÇÃO'];
    case Axes.SocialAssistance:
      return ['ASSISTÊNCIA SOCIAL'];
    case Axes.JobIncoming:
      return jobIndicators;
    case Axes.Health:
      return healthIndicators;
    default:
      return [] as string[];
  }
};

const DEFAULT_RESULT = {} as Record<string, number>;

const HEALTH_INDICATORS = [
  HealthIndicators.GSPN,
  HealthIndicators.CSES,
  HealthIndicators.CEVI
];

const JOB_INDICATORS = [
  JobIncomingIndicators.Salary,
  JobIncomingIndicators.Job
];

const getResultsWhenSimple = (results: DataWithType[][]) =>
  results
    .flatMap((result) => {
      const total = getTotal(result);
      return getPercentage(total, result);
    })
    .reduce((acc, cur) => {
      return { ...acc, [cur.name.toUpperCase()]: cur.value };
    }, DEFAULT_RESULT);

const getResultsWhenSocialAssistance = (results: DataWithType[][]) =>
  results
    .flatMap((result) => {
      const total = getTotal(result);
      return getPercentage(total, result);
    })
    .filter((result) => result.name === 'Não')
    .reduce(
      (acc, cur) => ({ ...acc, [cur.type as string]: cur.value }),
      DEFAULT_RESULT
    );

const getResultsWhenHealth = (results: DataWithType[][]) =>
  results
    .flatMap((result, idx) => {
      const total = getTotal(result);

      return getPercentage(total, result)
        .filter((cur) => cur.name !== 'Não sabe')
        .filter((cur) => cur.name.includes('Não'))
        .map((cur) => ({ ...cur, type: HEALTH_INDICATORS[idx] }));
    })
    .reduce(
      (acc, cur) => ({ ...acc, [cur.type]: (acc[cur.type] ?? 0) + cur.value }),
      DEFAULT_RESULT
    );

const getResultsWhenJob = (results: DataWithType[][]) =>
  results
    .flatMap((result, idx) => {
      const total = getTotal(result);
      return getPercentage(total, result)
        .filter((cur) => cur.name !== 'Não sabe')
        .filter((cur) => cur.name.includes('Até meio') || cur.name === 'Não')
        .map((cur) => ({ ...cur, type: JOB_INDICATORS[idx] }));
    })
    .reduce(
      (acc, cur) => ({ ...acc, [cur.type]: (acc[cur.type] ?? 0) + cur.value }),
      DEFAULT_RESULT
    );

export const getCurrentResults = ({
  axle,
  ibge,
  classificationType
}: RequestDataConfig) => {
  return getData({ axle, ibge, classificationType }).then((results) => {
    switch (axle) {
      case Axes.InsecurityFood:
        return getResultsWhenSimple(results);
      case Axes.Education:
        return getResultsWhenSimple(results);
      case Axes.SocialAssistance:
        return getResultsWhenSocialAssistance(results);
      case Axes.Health:
        return getResultsWhenHealth(results);
      case Axes.JobIncoming:
        return getResultsWhenJob(results);
      default:
        return DEFAULT_RESULT;
    }
  });
};
