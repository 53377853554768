import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { percentageFormatter } from '@/utils/string';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card4() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getTypes(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Tipos de organizações de apoio a mulheres"
      tags={[city ?? 'CEARÁ']}
      error={error}
      empty={data.length === 0}
      loading={loading}
    >
      <ListTable
        data={data.map((d) => ({ ...d, value: percentageFormatter(d.value) }))}
        columnTitle={[
          { key: 'org', name: 'Organização' },
          { key: 'percentage', name: 'Porcentagem' }
        ]}
        numberValues
      />
    </Card>
  );
}
