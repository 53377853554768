import { PhotoEffect } from './PhotoEffect';
import { Summary } from './Summary';
import { Box } from './styles';

import cmicImg from '@/assets/images/cartao-mais-infancia.jpg';
import { useConnectObserver } from '@/hooks';

export function Benefits() {
  const footerRef = useConnectObserver<HTMLDivElement>();

  return (
    <Box id="Contact" ref={footerRef}>
      <div className="benefits-box">
        <div className="content-box">
          <h2>Dados da Proteção Social</h2>
          <Summary />
        </div>
        <PhotoEffect src={cmicImg} />
      </div>
    </Box>
  );
}
