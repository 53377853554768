import { Show } from '@/components/shared';
import React from 'react';
import { ClusterLegend } from '../ClusterLegend';
import { HeatLegend } from '../HeatLegend';
import { Box } from './styles';

interface LegendProps {
  filters: string[];
  zoom: number;
}

export const Legend = React.memo(({ filters, zoom }: LegendProps) => {
  return (
    <Box>
      <Show when={zoom >= 9} fallback={<HeatLegend />}>
        <ClusterLegend filters={filters} />
      </Show>
    </Box>
  );
});
