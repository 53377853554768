import { ActionRegister } from 'big-data';

import foodIcon from '@/assets/icons/actions/food.svg';
import educationIcon from '@/assets/icons/actions/education.svg';
import socialAssistanceIcon from '@/assets/icons/actions/social-assistance.svg';
import healthIcon from '@/assets/icons/actions/saude.svg';
import jobIncomingIcon from '@/assets/icons/actions/trabalho-e-renda.svg';
import livingIcon from '@/assets/icons/actions/moradia.svg';

import { AxeEnum } from './axes';

const ACTION_PATH = '/dashboard/gestores-acoes/planos-de-acao';

export const actions: ActionRegister[] = [
  {
    id: 0,
    path: ACTION_PATH,
    name: AxeEnum.INSEGURANCA_ALIMENTAR,
    color: '#EBAF2D',
    icon: foodIcon,
    ods: [1, 2]
  },
  {
    id: 1,
    path: ACTION_PATH,
    name: AxeEnum.EDUCACAO,
    color: '#CC37E4',
    icon: educationIcon,
    ods: [1, 4]
  },
  {
    id: 2,
    path: ACTION_PATH,
    name: AxeEnum.ASSISTENCIA_SOCIAL,
    color: '#37B9E4',
    icon: socialAssistanceIcon,
    ods: [1, 5, 10, 16]
  },
  {
    id: 3,
    path: ACTION_PATH,
    name: AxeEnum.SAUDE,
    color: '#57AC4B',
    icon: healthIcon,
    ods: [1, 3]
  },
  {
    id: 4,
    path: ACTION_PATH,
    name: AxeEnum.TRABALHO_RENDA,
    color: '#373EE4',
    icon: jobIncomingIcon,
    ods: [1, 8, 9]
  },
  {
    id: 5,
    path: ACTION_PATH,
    name: AxeEnum.MORADIA,
    color: '#FE5E72',
    icon: livingIcon,
    ods: [1, 6, 11]
  }
];

const actionsObj = actions.reduce((acc, action) => {
  return {
    ...acc,
    [action.name]: action
  };
}, {} as Record<string, ActionRegister>);

export const actionRecord = actionsObj;
