import { ensureAxiosParamOptions } from '@/utils/routes';
import { strToInt } from '@/utils/types';
import { AxiosRequestConfig } from 'axios';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

function capitalize(str: string) {
  let result = str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });

  if (
    (result.includes('Da') ||
      result.includes('De') ||
      result.includes('Do') ||
      result.includes('Du')) &&
    !result.startsWith('Da') &&
    !result.startsWith('De') &&
    !result.startsWith('Do') &&
    !result.startsWith('Du')
  ) {
    return result
      .replace('Da', 'da')
      .replace('De', 'de')
      .replace('Do', 'do')
      .replace('Du', 'du');
  }

  return result;
}

const service = () => {
  const resource = `/proporcao-visitas`;

  async function getVisitsProportion(city?: string) {
    const aggregations = ['MUNICIPIO', 'SEMESTRE', 'ANO', 'IBGE'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;
    const pathConfig: AxiosRequestConfig = {
      params: {
        city: city ? capitalize(city) : undefined,
        semester: 2,
        year: 2021
      }
    };

    const response = await callService(() =>
      mainApi.get<any[]>(path, ensureAxiosParamOptions(pathConfig))
    );

    return response.data.map((data) => ({
      city: String(data.municipio),
      planVisitsQuantity: strToInt(data.visitas_planejadas),
      currentVisitsQuantity: strToInt(data.quantidade_visitada),
      codIbge: strToInt(data.ibge)
    }));
  }

  return {
    getVisitsProportion
  };
};

export const visitsProportion = service();
