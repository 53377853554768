import { useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';

import { useAuth, useDashboardTitle } from '@/hooks';
import { Loading, Show } from '@/components/shared';
import { Geolocation } from '@/services';

import {
  EventLayer,
  Filter,
  GeoJSONCity,
  Header,
  HeatList,
  Legend,
  VisibilityPanel
} from './components';

import cearaGeoJson from './cearaGeoJson.json';
import { getGeolocationMax } from './utils/functions';
import { HEAT_COLORS, Options } from './utils/objects';
import { getRequestFilter, getLocations } from './utils/requests';
import { Box } from './styles';

type Bounds = {
  northEast: number[];
  southWest: number[];
};

const geoJsonLayerStyle = {
  fill: false,
  weight: 2,
  color: '#55A546'
};

const LoadingMap = () => (
  <div className="loading">
    <Loading />
  </div>
);

const DEFAULT_GEOLOCATION_DATA = {} as Record<string, Geolocation[]>;
const DEFAULT_VISIBILITIES = {} as {} as Record<string, boolean>;

export function IndicatorsMap() {
  useDashboardTitle('Mapa de Dados da Proteção Social');

  const [geolocationData, setGeolocationData] = useState(
    DEFAULT_GEOLOCATION_DATA
  );
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<Error | null>(null);
  const [visibilityPanels, setVisibilityPanels] =
    useState(DEFAULT_VISIBILITIES);
  const [filters, setFilters] = useState<string[]>([]);
  const [boundBoxRequested, setBoundBoxRequested] = useState(false);
  const [zoom, setZoom] = useState(7.4);
  const [city, setCity] = useState('');
  const [enabledCity, setEnabledCity] = useState(false);
  const [reseted, setReseted] = useState(false);

  const geolocationMax = useMemo(
    () => getGeolocationMax(geolocationData),
    [geolocationData]
  );
  const panels = useMemo(() => Object.keys(geolocationData), [geolocationData]);

  const { userIbge, isAdm } = useAuth();

  useEffect(() => {
    if (!isAdm) {
      setEnabledCity(true);
      setGeolocationData({} as Record<Options, Geolocation[]>);
      setCity(userIbge ?? '');
    } // eslint-disable-next-line
  }, [userIbge]);

  function handleResetCity() {
    if (isAdm) {
      setCity('');
    }
  }

  function handleReset() {
    if (isAdm) {
      setGeolocationData({} as Record<Options, Geolocation[]>);
      setError(null);
      setReseted(true);
      setBoundBoxRequested(false);
      handleEnabledCity(false);
      handleResetCity();
    }
  }

  function handleEnabledCity(value: boolean) {
    setEnabledCity(value);
  }

  function handleChangeCity(value: string) {
    setGeolocationData({} as Record<Options, Geolocation[]>);
    setCity(value);
  }

  function handleSubmit(values: string[]) {
    const filters = getRequestFilter(values);

    setFilters(filters);
    setLoading(true);
    getLocations({ filters, city })
      .then((location) => {
        setGeolocationData(location.data);
        setVisibilityPanels(location.visibilities);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
        setBoundBoxRequested(false);

        !city && setReseted(true);
      });
  }

  function handleZoomEnd() {
    setReseted(false);
  }

  function getBoundRequest(bounds: Bounds) {
    const min = bounds.southWest;
    const max = bounds.northEast;

    setLoading(true);
    setGeolocationData(DEFAULT_GEOLOCATION_DATA);
    getLocations({ filters, city, min, max })
      .then((location) => {
        setGeolocationData(location.data);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
        setBoundBoxRequested(true);
      });
  }

  function getNormalRequest() {
    getLocations({ filters, city })
      .then((location) => {
        setGeolocationData(location.data);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setBoundBoxRequested(false);
      });
  }

  function handleZoom(zoom: number, bounds: Bounds) {
    setZoom(zoom);

    if (city || reseted) return;

    const isBoundRequestZoom = zoom >= 9;

    if (isBoundRequestZoom && !boundBoxRequested) {
      return getBoundRequest(bounds);
    }

    if (!isBoundRequestZoom && boundBoxRequested) {
      return getNormalRequest();
    }
  }

  function handleDrag(zoom: number, bounds: Bounds) {
    if (city || reseted) return;

    const isBoundRequestZoom = zoom >= 9;

    if (isBoundRequestZoom) {
      getBoundRequest(bounds);
    }
  }

  function handleVisibility(key: string) {
    setVisibilityPanels((current) => ({ ...current, [key]: !current[key] }));
  }

  function handleRemoveAxe(indicator?: string) {
    if (!indicator) return;

    const [filter] = getRequestFilter([indicator]);

    setGeolocationData((current) =>
      Object.entries(current).reduce((acc, [key, value]) => {
        return key === filter ? { ...acc } : { ...acc, [key]: value };
      }, {} as Record<string, Geolocation[]>)
    );

    setFilters((current) => current.filter((value) => value !== filter));
  }

  return (
    <Box>
      <div className="info-box">
        <Header
          title="Filtros"
          description="Adicione filtros para combinar informações no mapa e visualize as famílias fora da proteção social."
        />

        <Filter
          city={city}
          enabledCity={enabledCity}
          disabled={!!city}
          loading={loading}
          onChangeCity={handleChangeCity}
          onEnabledCity={handleEnabledCity}
          onRemoveAxe={handleRemoveAxe}
          onReset={handleReset}
          onSubmit={handleSubmit}
        />

        <Header
          title="Legenda"
          description="As legendas irão auxiliar a compreender os significados dos símbolos existentes no mapa. "
        />

        <Legend filters={filters} zoom={zoom} />
      </div>

      <div className="map-box">
        <MapContainer
          center={[-5.300195, -39.52694]}
          zoom={7.3}
          zoomSnap={0.2}
          zoomDelta={0.2}
          minZoom={7.3}
          attributionControl={false}
          maxBounds={[
            [-2.714417, -41.552225],
            [-8.03847, -37.189405]
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <EventLayer.Component
            reseted={reseted}
            onZoom={handleZoom}
            onZoomEnd={handleZoomEnd}
            onDrag={handleDrag}
          />

          <GeoJSON data={cearaGeoJson as any} style={geoJsonLayerStyle} />
          <GeoJSONCity codIbge={city} loading={loading} />

          <HeatList
            locations={geolocationData}
            visibilities={visibilityPanels}
            max={geolocationMax}
            gradient={HEAT_COLORS}
            zoom={zoom}
            loading={loading}
          />
        </MapContainer>

        <VisibilityPanel
          panels={panels}
          visibilities={visibilityPanels}
          onClick={handleVisibility}
        />

        <Show when={loading} fallback={null}>
          <LoadingMap />
        </Show>
      </div>
    </Box>
  );
}
