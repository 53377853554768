import { Section } from '@/layout';
import { Card, WordCloud } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { socialMovements } from '@/services/socialMovements';

export function MainChannelWordCloud() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.linkMovements());
  }, [request]);

  return (
    <Section>
      <Card
        title="Principais canais que os movimentos recebem informações sobre áreas de interesse"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <WordCloud percentageSymbol words={data} scaleSize={[10, 100]} />
      </Card>
    </Section>
  );
}
