import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { percentageFormatter } from '@/utils/string';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card1() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getThemes(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Temas das áreas de atuação das organizações"
      tags={[city ?? 'CEARÁ']}
      error={error}
      empty={data.length === 0}
      loading={loading}
    >
      <ListTable
        data={data.map((m) => ({ ...m, value: percentageFormatter(m.value) }))}
        columnTitle={[
          { name: 'Temas', key: 'themes' },
          { name: 'Porcentagem', key: 'percentage' }
        ]}
        numberValues
        pageSize={8}
      />
    </Card>
  );
}
