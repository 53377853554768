import styled from 'styled-components';

export const Box = styled.div`
  .last-actions {
    margin-top: 2rem;

    .actions {
      text-align: center;
      p {
        margin-bottom: 1rem;
      }
    }
  }

  .axes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 1rem;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    p {
      color: ${(props) => (props.theme.darkMode ? '#fff' : '#4c5469')};
    }
  }
`;
