import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  .img-box {
    background: ${({ color, theme }) =>
      theme.darkMode ? colors.white : color ?? colors.ciano700};

    max-width: 40px;
    max-height: 40px;

    min-width: 40px;
    min-height: 40px;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: contain;
      width: 60%;
      height: 60%;
      filter: ${({ theme }) => (theme.darkMode ? `invert(1)` : '')};
    }
  }
  /* 
  .content-box {
    max-width: 210px;
  } */
`;
