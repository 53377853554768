import { useState } from 'react';
import styled from 'styled-components';
import { Select } from '@/components/shared/SelectBox';
import { useCitizenAccessUserPageRank } from '@/servicesHooks/citizenAccessUser';
import { ServiceBox } from '@/components/shared';
import { cpfReplacer } from '@/utils/string';

import {
  CardBase,
  CardContent,
  CardFooter,
  CardHeader,
  Table
} from '../../components';
import { useTabContext, useManager } from '../../contexts';
import { generateTag, filterOrderOptions } from '../../utils';

type Order = 'asc' | 'desc';

export function CardAccessedPages() {
  const [orderFilter, setOrderFilter] = useState<Order>();
  const { manager } = useManager();
  const { tab } = useTabContext();

  const { data, error, loading } = useCitizenAccessUserPageRank({
    minimumDate: tab?.value,
    order: orderFilter,
    userId: manager?.cpf
  });

  const tag = generateTag([
    `CPF: ${cpfReplacer(manager?.cpf ?? '', "secret")}`,
    tab?.name
  ]);

  return (
    <Box>
      <CardHeader title="Páginas acessadas do usuário" subtitle={tag} />

      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent>
          <Table
            data={data
              .slice(0, 5)
              .map((d) => ({ pages: d.name, numberOfExhibitions: d.value }))}
            headers={['Páginas', 'Número de exibições']}
            highlight={{
              by: 'pages',
              value: manager?.name ?? ''
            }}
            countStart={0}
          />
        </CardContent>
      </ServiceBox>

      <CardFooter>
        <span>Categoria de exibição: </span>
        <Select
          placeholder="Mais acessados"
          options={filterOrderOptions}
          value={orderFilter}
          onChange={(value) => setOrderFilter(value as Order)}
        />
      </CardFooter>
    </Box>
  );
}

const Box = styled(CardBase)``;
