import styled from 'styled-components';

import { GenericGrid, GenericGridItem } from '@/components/styled';
import { mediaQuery } from '@/theme/mediaQuery';

export const Box = styled.div`
  background: ${(props) => props.theme.background};
`;

export const Grid = styled(GenericGrid)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'left-charts right-charts'
    'left-charts right-charts'
    'left-charts right-charts'
    'bottom-charts bottom-charts';

  @media (max-width: 1400px) {
    grid-template-areas:
      'header header'
      'left-charts left-charts'
      'right-charts right-charts'
      'bottom-charts bottom-charts';
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: 100%;

    grid-template-areas:
      'header'
      'left-charts'
      'right-charts'
      'bottom-charts';
  }
`;

export const GridItem = styled(GenericGridItem)`
  width: 100%;
  height: 100%;
`;

export const LeftChartsBox = styled.div``;
export const RightChartsBox = styled.div``;
export const BottomChartsBox = styled.div``;
