import { useDashboardFilter } from '@/hooks';
import { livingConditionAgents } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataPercentageComputed } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useWatterSupplyData() {
  const [waterSupplyTypes, setWaterSupplyTypes] = useState<
    DataPercentageComputed[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    livingConditionAgents
      .getWaterSupplyTypes(ibge, year, semester, zone)
      .then((data) => setWaterSupplyTypes(getDataWithPercentage(data)))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      waterSupplyTypes,
      loading,
      error
    }),
    [waterSupplyTypes, loading, error]
  );
}
