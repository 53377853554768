import { BigDataRoute } from '@/types/routes';
import { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

export function createMenu(children?: BigDataRoute[]): MenuItem[] | undefined {
  if (!children) {
    return undefined;
  }

  return children
    ?.filter((child) => !!child.Element)
    ?.map(({ iconImg: Icon, ...child }) =>
      getItem(
        child.name,
        child.link ?? child.path ?? '',
        <Icon />,
        child.noDropdown ? undefined : createMenu(child.children)
      )
    );
}
