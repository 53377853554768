import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 0.75rem)); // 3rem / 4 = 0.75rem
  gap: 1rem;

  grid-template-areas:
    'a a b b'
    'f f d e'
    'c c none none';

  @media (max-width: 1250px) {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'a d'
      'b e'
      'c c'
      'f f';
  }

  @media (max-width: 960px) {
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'a a'
      'b b'
      'c c'
      'd e'
      'f f';
  }

  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f';
  }
`;
