import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '@/theme/colors';

export const Box = styled(Link)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  color: ${(props) => (props.theme.darkMode ? '#fff' : '#636e72')};

  cursor: pointer;
  border-bottom: 1px solid #dfe6e9;

  &:last-child {
    border-bottom: none;
  }

  .title {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
  }

  .indicator {
    display: inline-block;
    font-size: 0.8375rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray800)};
  }
`;
