import { FloorMaterial } from './FloorMaterial';
import { Eletricity } from './Eletricity';
import { ExternalSituation } from './ExternalSituation';
import { HouseInfo } from './HouseInfo';
import { Demography } from './Demography';
import { WallMaterial } from './WallMaterial';

export const HouseSection = Object.freeze({
  Demography,
  ExternalSituation,
  FloorMaterial,
  Eletricity,
  HouseInfo,
  WallMaterial
});
