import { useDashboardFilter } from '@/hooks';
import { education } from '@/services/education';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

interface UseEducation extends Data {
  schoolMode: string;
}

export function useEducation() {
  const [data, setData] = useState<UseEducation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone, isNull } = useDashboardFilter();

  useEffect(() => {
    const promises = [
      education.getNursery,
      education.getPreSchool,
      education.getSchool
    ];

    setLoading(true);
    Promise.all(
      promises.map((promise) =>
        promise({
          hasFilter: false,
          // variants: ['não'],
          ibge,
          year,
          semester,
          zone
        })
      )
    )
      .then((data) => data.flat())
      .then((data) => data.filter((d) => d.schoolMode !== 'null'))
      .then((data) => {
        return [...data].sort((a, b) => b.value - a.value);
      })
      .then((_data) => {
        setData(_data);
        if (_data.length === 0) isNull();
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      loading,
      error
    }),
    [data, loading, error]
  );
}
