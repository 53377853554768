import { ReactNode } from 'react';
import styled from 'styled-components';

const Box = styled.section`
  height: 100%;
`;

interface SectionProps {
  children?: ReactNode;
}

export function Section({ children }: SectionProps) {
  return <Box>{children}</Box>;
}
