import { mediaQuery } from '@/theme/mediaQuery';
import { getSize } from '@/theme/utils';
import styled from 'styled-components';

interface Gap {
  row: string;
  column: string;
}

export enum Align {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Stretch = 'stretch'
}

export interface BoxProps {
  columns?: string[];
  rows?: string[];
  height?: string | number;
  gap?: Gap;
  align?: Align[];
  marginTop?: number;
}

const getColumn = (array?: string[]) => {
  if (!array) return `repeat(24, 1fr)`;

  const size = array.length;

  if (size < 24) {
    return `${array.join(' ')} repeat(${24 - size}, 1fr)`;
  }

  return array.join(' ');
};

const getRow = (array?: string[]) => {
  if (!array) return `repeat(24, 1fr)`;

  return array.join(' ');
};

const getAlign = (alignType?: Align[]) => {
  if (!alignType || alignType.length < 2) {
    return `${Align.Stretch} ${Align.Stretch}`;
  }

  return `${alignType[0]} ${alignType[1]}`;
};

export const Box = styled.div<BoxProps>`
  width: 100%;
  height: ${({ height }) => getSize(height ?? 'max-content')};
  max-width: 1360px;
  margin: ${(props) => props.marginTop ?? 10}px 0 0;

  display: grid;
  grid-template-columns: ${({ columns }) => getColumn(columns)};
  grid-template-rows: ${({ rows }) => getRow(rows)};

  grid-auto-rows: minmax(0, max-content);

  column-gap: ${({ gap }) => gap?.column ?? '10px'};
  row-gap: ${({ gap }) => gap?.row ?? '10px'};
  place-items: ${({ align }) => `${getAlign(align)}`};

  ${mediaQuery.mediumScreen} {
    grid-template-rows: ${({ rows }) =>
      `repeat(${rows?.length ?? 0}, max-content)`};
  }

  @media (max-width: 1100px) {
    column-gap: 0;
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: ${getColumn()};
    grid-template-rows: ${({ rows }) =>
      `repeat(${rows?.length ?? 0}, max-content)`};
  }
`;

export interface CellPos {
  sm: string;
  md: string;
  lg: string;
}

export interface BoxItemProps {
  column?: string | CellPos;
  row?: string | CellPos;
  align?: Align[];
}

const getCellPos = (cell?: string | CellPos, key?: keyof CellPos) => {
  if (!cell) return '1/25';

  if (typeof cell === 'string') return cell;

  return key ? cell[key] : cell.lg;
};

export const BoxItem = styled.div<BoxItemProps>`
  width: 100%;
  height: 100%;

  grid-column: ${({ column }) => getCellPos(column)};
  grid-row: ${({ row }) => getCellPos(row)};
  place-self: ${({ align }) => getAlign(align)};

  @media (min-width: 651px) and (max-width: 1100px) {
    grid-column: ${({ column }) => getCellPos(column, 'md')};
    grid-row: ${({ row }) => getCellPos(row, 'md')};
  }

  /* @media (max-width: 650px) { */
  ${mediaQuery.smallScreen} {
    grid-column: ${getCellPos()};
    grid-row: ${({ row }) => getCellPos(row, 'sm')};
  }
`;
