import { DashboardInsightProps, RankingModel } from 'big-data';
import { useMemo } from 'react';

import {
  CardTagged,
  HorizontalGridBar,
  InlineImageRanking,
  ServiceBox
} from '@/components/shared';
import { D3Map } from '@/components/shared/D3Map';
import { RankingDashboard } from '@/components/shared/RankingDashboard';
import {
  useBenefitsData,
  useFamilyCities,
  usePlantConsume,
  useAnimalConsume
} from '@/servicesHooks/foodInsecurity';
import { ColorText, Text, TextI } from '@/components/styled';
import { capitalize } from '@/utils/string';
import { useDashboardFilter } from '@/hooks';
import { colors as colorsObject } from '@/theme/colors';

import pig from '@/assets/icons/pig.svg';
import corn from '@/assets/icons/corn.svg';

import { getInsecurityFoodRanking, rankingModel } from './ranking';
import { modifierColors } from '../../utils';

import { Box } from './styles';
import { LegendContainer, Legend } from '@/theme/utils';

const formatter = new Intl.NumberFormat('pt-br');

export function InsecurityFoodInsights({ selected }: DashboardInsightProps) {
  const { city, tag } = useDashboardFilter();

  const name = selected?.name.toLocaleLowerCase();
  const { cities, loading, error } = useFamilyCities(selected?.name);

  const { plantConsume, loadingPlantConsume, errorPlantConsume } =
    usePlantConsume(selected?.name);
  const { animalConsume, loadingAnimalConsume } = useAnimalConsume(
    selected?.name
  );

  const color = selected ? modifierColors.get(selected.color) : undefined;

  const { benefits, benefitsRejecteds, loadingBenefits, errorBenefits } = useBenefitsData(
    name?.replace('ç', 'c').toUpperCase()
  );

  const totalFamilyAbsolut = useMemo(() => {
    return cities.reduce(
      (acc, cur) => {
        return {
          percent: acc.percent + (cur.percentual ?? 0),
          total: acc.total + (cur?.total ?? 0)
        };
      },
      { percent: 0, total: 0 }
    );
  }, [cities]);

  const total = useMemo(() => {
    return (totalFamilyAbsolut.percent * 100) / totalFamilyAbsolut.total;
  }, [totalFamilyAbsolut]);

  const foodConsume = useMemo(() => {
    const valuesPlant = plantConsume.reduce(
      (acc, cur) => {
        const plantAnswer = cur.name === 'Sim' ? cur.value : 0;
        return {
          percent: acc.percent + plantAnswer,
          total: acc.total + cur.value
        };
      },
      { percent: 0, total: 0 }
    );

    const valuesAnimal = animalConsume.reduce(
      (acc, cur) => {
        const plantAnswer = cur.name === 'Sim' ? cur.value : 0;
        return {
          percent: acc.percent + plantAnswer,
          total: acc.total + cur.value
        };
      },
      { percent: 0, total: 0 }
    );

    return [
      {
        img: corn,
        name: 'Famílias plantam seus próprios alimentos',
        percentageValue: (valuesPlant.percent * 100) / valuesPlant.total,
        value: valuesPlant.total
      },
      {
        img: pig,
        name: 'Famílias criam animais para consumo próprio',
        percentageValue: (valuesAnimal.percent * 100) / valuesAnimal.total,
        value: valuesAnimal.total
      }
    ];
  }, [plantConsume, animalConsume]);

  const bindedBenefits = useMemo(
    () => getInsecurityFoodRanking(benefits),
    [benefits]
  );

  function getTypeFoodInsecurityText(name?: string) {
    if (!!name) {
      if (name === 'fora de insegurança alimentar') return name.toLowerCase();
      else return ` em ${capitalize(name)}`;
    }
    return 'em algum nível de Insegurança Alimentar';
  }

  const bindedBenefitsRejecteds = useMemo(
    () => benefitsRejecteds.map(d => rankingModel.get(d)) as RankingModel[],
    [benefitsRejecteds]
  )

  const typeFoodInsecurity = getTypeFoodInsecurityText(name);

  return (
    <Box>
      {selected && (
        <div className="map">
          <CardTagged
            tag={tag}
            color={color}
            title={`Famílias ${typeFoodInsecurity}`}
          >
            <ServiceBox
              empty={cities.length === 0}
              loading={loading}
              error={error}
            >
              <Text>
                <ColorText color={color}>
                  {' ' +
                    formatter.format(
                      Number(
                        selected
                          ? selected.percentageValue.toFixed(2)
                          : total.toFixed(2)
                      )
                    )}
                  %
                </ColorText>{' '}
                das famílias em situação de vulnerabilidade social, com crianças
                de 0 a 5 anos e 11 meses, estão em {typeFoodInsecurity}
              </Text>
              <D3Map
                filteringCity={city}
                data={cities}
                color={color as any}
                percentage
              />
            </ServiceBox>
          </CardTagged>
        </div>
      )}

      <div className="ranking">
        <CardTagged
          tag={tag}
          color={color}
          title="Benefícios sociais para a insegurança alimentar
"
        >
          <ServiceBox
            empty={benefits.length === 0}
            loading={loadingBenefits}
            error={errorBenefits}
          >
            <Text>
              Quais os principais benefícios que as famílias em situação de
              insegurança alimentar recebem?
            </Text>
            <RankingDashboard
              loadingBenefits={loadingBenefits}
              data={bindedBenefits}
              selected={selected}
              rejecteds={bindedBenefitsRejecteds}
              color={color}
              calculatedOut
            />
            <LegendContainer>
              <Legend>
                Uma família pode receber um ou mais benefícios.
              </Legend>
            </LegendContainer>
          </ServiceBox>
        </CardTagged>
      </div>

      <div className="foodFamily">
        <CardTagged
          tag={tag}
          color={color}
          title="Agricultura familiar
"
        >
          <ServiceBox
            empty={plantConsume.length === 0 && animalConsume.length === 0}
            loading={loadingPlantConsume && loadingAnimalConsume}
            error={errorPlantConsume}
          >
            <Text>
              Qual a porcentagem de famílias que plantam alimentos ou criam
              animais para consumo próprio?
            </Text>
            <InlineImageRanking
              data={foodConsume}
              color={color ?? colorsObject.ciano700}
            />
          </ServiceBox>
        </CardTagged>
      </div>
      {selected && (
        <div className="graph">
          <CardTagged
            tag={tag}
            color={color}
            title={`Municípios com mais famílias em ${typeFoodInsecurity}`}
          >
            <ServiceBox
              empty={cities.length === 0}
              loading={loading}
              error={error}
            >
              <Text>
                <strong>
                  {city
                    ? `Qual a porcentagem de famílias que estão ${typeFoodInsecurity}?`
                    : `Quais os municípios com o maior número de famílias que estão ${typeFoodInsecurity}?`}
                </strong>
                {city && (
                  <>
                    <ColorText color={color}>
                      {' ' +
                        formatter.format(
                          Number(
                            selected
                              ? selected.percentageValue.toFixed(2)
                              : total.toFixed(2)
                          )
                        )}
                      %
                    </ColorText>{' '}
                    das famílias em situação de vulnerabilidade social, com
                    crianças de 0 a 5 anos e 11 meses, estão em{' '}
                    {typeFoodInsecurity} no município de {' ' + city}
                  </>
                )}
              </Text>

              <TextI>
                Passe o mouse pelo gráfico de barras para visualizar a
                porcentagem por município.
              </TextI>
              <HorizontalGridBar
                data={cities}
                grid
                color={color}
                isPercentage
                percentageCalculatedOut
                hasPagination
              />
            </ServiceBox>
          </CardTagged>
        </div>
      )}
    </Box>
  );
}
