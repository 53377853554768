export function BdsJobIncome({ color }: { color?: string }) {
  const selected = color ?? `#fff`;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_804_1627)">
        <path
          d="M9.90565 20.861C8.78288 22.4488 7.30799 22.5485 5.91947 21.1666L1.66755 16.908C1.13451 16.4295 0.708201 15.8441 0.416363 15.1899C0.124525 14.5358 -0.0263062 13.8275 -0.0263062 13.1112C-0.0263062 12.3949 0.124525 11.6866 0.416363 11.0324C0.708201 10.3782 1.13451 9.79283 1.66755 9.31432C2.77039 8.19819 3.87324 7.07541 4.98937 5.9925C5.83759 5.13168 6.96447 4.60083 8.16833 4.49493C9.37219 4.38903 10.5744 4.715 11.5599 5.41451C11.7432 5.52926 11.899 5.68291 12.0163 5.86457C12.1336 6.04623 12.2095 6.25146 12.2387 6.46572C12.2678 6.67998 12.2495 6.89801 12.185 7.10441C12.1206 7.31081 12.0115 7.50051 11.8655 7.66006L15.1475 10.9154C15.9514 11.7193 16.742 12.5299 17.5658 13.3138C17.9825 13.6502 18.2707 14.1198 18.382 14.6437C18.4933 15.1675 18.4209 15.7137 18.177 16.1905C17.965 16.6416 17.5952 16.9994 17.1375 17.1965C16.6797 17.3936 16.1656 17.4162 15.6923 17.2601C15.4395 17.844 15.0865 18.3791 14.6492 18.8413C14.0502 19.1215 13.4075 19.2967 12.7491 19.3595C12.2376 20.9872 11.4935 21.4323 9.90565 20.861Z"
          fill={selected}
        />
        <path
          d="M17.2535 11.5067L13.5464 7.80626C13.5367 7.44021 13.4832 7.07663 13.3869 6.72334C12.5365 4.73025 12.9551 3.04942 14.7157 1.72733C15.8645 0.807646 17.2429 0.219525 18.7018 0.026565C19.5249 0.00513283 20.348 0.062964 21.16 0.199286L21.2264 0.491607L18.4693 3.2155C17.8049 3.87987 17.8049 3.87987 18.4693 4.59074C19.1337 5.30161 19.798 5.91947 20.4624 6.58383C20.8477 6.99574 21.16 7.00238 21.5453 6.58383C22.4754 5.60057 23.4255 4.63724 24.4154 3.61412C24.7162 4.09014 24.9028 4.62936 24.9604 5.18953C25.018 5.74971 24.9452 6.31561 24.7475 6.84292C24.3782 7.98728 23.8671 9.08092 23.2262 10.0983C21.9639 11.9918 20.2697 12.3904 18.1637 11.5134C17.882 11.4336 17.5924 11.3846 17.3 11.3673L17.2535 11.5067Z"
          fill={selected}
        />
        <path
          d="M6.57719 23.1065C5.69358 24.216 4.58409 25.186 2.86339 24.8405C2.18576 24.7362 1.55701 24.4249 1.06338 23.9491C0.569752 23.4733 0.235426 22.8564 0.106283 22.1831C-0.252474 20.6019 0.538116 19.4393 1.7539 18.4294L6.57719 23.1065Z"
          fill={selected}
        />
      </g>
      <defs>
        <clipPath id="clip0_804_1627">
          <rect width="25" height="24.9136" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
