import { Breadcrumb } from '@/components/shared/Breadcrumb';
import { SecondTitle } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';
import { routesDashboard } from '@/pages/routes.dashboard';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Box } from './styles';
import {
  findRouteByName,
  findRouteNameByPath,
  getHref
} from './utils/functions';

export function HeaderDashboard() {
  const title = useDashboardTitle('Termômetro de Dados da Proteção Social');
  const { pathname } = useLocation();

  const params = useParams();
  const paramsEntries = Object.entries(params);

  const routes = routesDashboard[0]?.children ?? [];

  const [search] = useSearchParams();
  const urlSearchEntries = [...search.entries()];
  const urlSearchParams = urlSearchEntries
    .map(([key, value]) => `${key}=${value}&`)
    .join('')
    .replace(/&$/, '');

  const paths = pathname
    .replace('/', '')
    .split('/')
    .filter((path) => path !== '')
    .map((path) => {
      const isPlanActionWithAxe =
        !!urlSearchParams && path === 'planos-de-acao';
      const href = getHref(pathname, path);
      const hrefWithSearchParams = `${href}?${urlSearchParams}`;

      if (isPlanActionWithAxe) {
        const currentSearchParams = urlSearchEntries
          .map((entry) => findRouteByName(entry[1], routes))
          .join('');

        return {
          label: currentSearchParams,
          href: hrefWithSearchParams
        };
      }

      const paramFounded = paramsEntries.find(([_, value]) => value === path);
      if (!!paramFounded) {
        return {
          label: findRouteNameByPath(`:${paramFounded[0]}`, routes),
          href: pathname
        };
      }

      return {
        label: findRouteNameByPath(path, routes),
        href: !!urlSearchParams ? hrefWithSearchParams : href
      };
    })
    .filter((path) => !!path.label);

  return (
    <Box>
      <Breadcrumb paths={paths} />
      <SecondTitle className="header-dashboard-title">{title}</SecondTitle>
    </Box>
  );
}
