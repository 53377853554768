interface MoradiaIconProps {
  color?: string;
}

export function BdsLivingCondition({ color }: MoradiaIconProps) {
  const selectColor = color ?? '#55A546';

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fill={selectColor}
        d="M33.1003 13.8433L18.1003 0.510016C17.7961 0.242818 17.4051 0.095459 17.0003 0.095459C16.5954 0.095459 16.2044 0.242818 15.9003 0.510016L0.90028 13.8433C0.645339 14.0689 0.465559 14.367 0.385112 14.6978C0.304664 15.0285 0.327406 15.3759 0.45028 15.6933C0.571143 16.0066 0.783788 16.276 1.06036 16.4663C1.33694 16.6567 1.66454 16.759 2.00028 16.76H3.66695V31.76C3.66695 32.202 3.84254 32.626 4.1551 32.9385C4.46766 33.2511 4.89159 33.4267 5.33361 33.4267H28.6669C29.109 33.4267 29.5329 33.2511 29.8455 32.9385C30.158 32.626 30.3336 32.202 30.3336 31.76V16.76H32.0003C32.336 16.759 32.6636 16.6567 32.9402 16.4663C33.2168 16.276 33.4294 16.0066 33.5503 15.6933C33.6732 15.3759 33.6959 15.0285 33.6154 14.6978C33.535 14.367 33.3552 14.0689 33.1003 13.8433ZM18.6669 30.0933H15.3336V25.0933C15.3336 24.6513 15.5092 24.2274 15.8218 23.9148C16.1343 23.6023 16.5583 23.4267 17.0003 23.4267C17.4423 23.4267 17.8662 23.6023 18.1788 23.9148C18.4914 24.2274 18.6669 24.6513 18.6669 25.0933V30.0933ZM27.0003 30.0933H22.0003V25.0933C22.0003 23.7673 21.4735 22.4955 20.5358 21.5578C19.5981 20.6201 18.3264 20.0933 17.0003 20.0933C15.6742 20.0933 14.4024 20.6201 13.4647 21.5578C12.5271 22.4955 12.0003 23.7673 12.0003 25.0933V30.0933H7.00028V16.76H27.0003V30.0933ZM6.38361 13.4267L17.0003 3.99335L27.6169 13.4267H6.38361Z"
      />
    </svg>
  );
}
