import styled from 'styled-components';

import { GenericGrid, GenericGridItem } from '@/components/styled';

export const Grid = styled(GenericGrid)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  grid-template-areas: 'a';
`;

export const GridItem = styled(GenericGridItem)``;
