import styled, { css } from 'styled-components';

import { colors } from '@/theme/colors';

interface ColorTextProps {
  color?: string;
}

export const Text = styled.p<{ mb?: string }>`
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  font-size: 1rem;
  color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.gray700)};
`;

export const TextI = styled.p<{ mb?: string }>`
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: ${({ mb }) => mb ?? `10px`};
  font-size: 0.9rem;
  color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.gray700)};
`;

const colorTextDark = css`
  color: #fff;
`;

export const ColorText = styled.span<ColorTextProps>`
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  font-size: 1rem;

  color: ${({ color }) => color ?? colors.ciano700};
  ${({ theme }) => theme.darkMode && colorTextDark}
`;

export const TitleText = styled.h1`
  font-weight: bold;
  line-height: 1.375rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => (theme.darkMode ? colorTextDark : colors.green700)};
`;
