import { DataWithCity } from 'big-data';
import * as d3 from 'd3';

import { max } from '@/utils/math';
import { percentageFormatter } from '@/utils/string';
import { EventStack } from './EventStack';
import { NumberValue } from 'd3';

type SVGD3 = d3.Selection<SVGSVGElement, unknown, null, undefined>;
type SVGChart = d3.Selection<SVGGElement, unknown, null, undefined>;
type TSpanD3 = d3.Selection<SVGTSpanElement, unknown, null, undefined>;
type XScale = d3.ScaleLinear<number, number, never>;
type YScale = d3.ScaleBand<string>;
type Margin = { left: number; right: number; top: number; bottom: number };
type Config = {
  width: number;
  sizeRatio: number;
  isPercentage?: boolean;
  percentageCalculatedOut?: boolean;
  selected?: (label?: string) => void;
  maxValue?: number;
  selectedValue?: DataWithCity;
  total: number;
};

const MARGIN: Margin = { left: 0, right: 0, top: 0, bottom: 0 };

const getHeight = (width: number, ratio: number) => {
  return width < 450 ? width * 0.8 : width * ratio;
};

export class HorizontalBarBuilder {
  private box: SVGSVGElement;
  private width: number;
  private height: number;
  private labelWidth: number;
  private margin: Margin = MARGIN;
  private isPercentage: boolean;
  private percentageCalculatedOut: boolean;
  private maxValue?: number;
  private EVENT_STACK: EventStack<SVGChart>;
  private selectedValue?: DataWithCity;

  private data: DataWithCity[] = [];
  private svg: SVGD3;
  private chart: SVGChart;

  private xScale?: XScale;
  private yScale?: YScale;
  private selected?: (label?: string) => void;
  private total: number;

  constructor(box: SVGSVGElement, config: Config) {
    this.box = box;
    this.width = config.width;
    this.height = getHeight(config.width, config.sizeRatio) + 30;
    this.isPercentage = !!config.isPercentage;
    this.percentageCalculatedOut = !!config.percentageCalculatedOut;
    this.maxValue = config.maxValue;

    this.labelWidth = this.width < 450 ? 80 : 120;
    this.selected = config.selected;

    const svg = d3
      .select(box)
      .attr('width', this.width)
      .attr('height', this.height);

    const chart = svg
      .append('g')
      .attr('class', 'chart')
      .style('user-select', 'none');

    this.svg = svg;
    this.chart = chart;
    this.EVENT_STACK = new EventStack<SVGChart>();
    this.selectedValue = config.selectedValue;
    this.total = config.total;
  }

  getMouseEnterValue = (value: number) => {
    if (this.isPercentage) {
      return this.getPercentage(value);
    }

    return value;
  };

  getMouseEnterLabel = (value: number) => {
    return this.isPercentage ? percentageFormatter(value) : value;
  };

  removeOnMouseLeave = (element: any) => {
    if (element) {
      element.element.remove();
    }
  };

  getPercentage = (value: number) => {
    if (this.isPercentage) {
      if (this.percentageCalculatedOut) {
        return value;
      }
      return (value / this.total) * 100;
    }
    return 0;
  };

  putMargin(margin: Margin) {
    this.margin = margin;

    return this;
  }

  putData(data: DataWithCity[]) {
    const orderedData = [...data].sort((a, b) => b.value - a.value);

    this.data = orderedData;

    return this;
  }

  putGrid() {
    this.chart
      .selectAll('g.x-axis g.tick + g.tick')
      .append('line')
      .attr('class', 'x-grid')
      .attr('x1', 0)
      .attr('y1', -this.height)
      .attr('x2', 0)
      .attr('y2', 0);
    return this;
  }

  buildScales() {
    const values = this.data.map((d) => d.value);
    const names = this.data.map((d) => d.name);

    const baseValue = this.isPercentage ? 100 : max(values);

    const xMaxValue = this.maxValue
      ? this.maxValue + (this.maxValue % 50)
      : baseValue;

    const widthWithMargins =
      this.width - this.margin.right - this.margin.left - this.labelWidth;
    const heightWithMargins =
      this.height - this.margin.bottom - this.margin.top;

    const xScale = d3
      .scaleLinear()
      .domain([0, xMaxValue])
      .range([0, widthWithMargins]);

    const yScale = d3
      .scaleBand()
      .domain(names)
      .range([0, heightWithMargins])
      .padding(window.innerWidth > 1200 ? 0.4 : 0.5);

    this.xScale = xScale;
    this.yScale = yScale;

    return this;
  }

  drawLabel(x: number, y: number, text: string, d?: string) {
    const tooltip = this.chart
      .append('g')
      .attr('class', 'x-tooltip')
      .style('user-select', 'none');

    const getWidth = () => {
      if (!this.xScale) return 0;

      return x > 40 ? this.xScale(x) + 60 : this.width - 110;
    };

    tooltip
      .append('foreignObject')
      .attr('transform', `translate(${this.labelWidth + this.margin.left}, 0)`)
      .attr('x', 3)
      .attr('y', this.yScale ? (this.yScale(d as string) ?? 0) - 22 ?? 0 : 0)
      .attr('width', getWidth())
      .attr('height', this.yScale ? 22 : 0)
      .style('text-align', x > 40 ? 'center' : 'left')
      .append('xhtml:div')
      .text(text.toUpperCase())
      .attr('class', 'text-tooltip')
      .style('margin', x > 40 ? '0 auto' : 0)
      .style('font-family', 'IBM Plex Sans');

    return tooltip;
  }

  plotAxis(ticks: number = 5) {
    const getTickValue = (d: NumberValue): string => {
      return this.isPercentage ? `${d}%` : `${d}`;
    };

    if (this.xScale && this.yScale) {
      // Executes the code if both scales, xScale and yScale, are defined
      const xAxis = d3
        .axisBottom(this.xScale)
        .tickSizeOuter(0)
        .ticks(ticks)
        .tickFormat(getTickValue);

      const yAxis = d3.axisLeft(this.yScale);
      const finalHeight = this.height - this.margin.top - this.margin.bottom;
      const left = this.margin.left + this.labelWidth;

      this.chart
        .append('g')
        .attr('transform', `translate(${left}, ${finalHeight})`)
        .attr('class', 'axis x-axis')
        .call(xAxis)
        .selectAll('text')
        .attr('class', 'axis-text x-axis-text');

      this.chart
        .append('g')
        .attr('transform', `translate(${left}, ${0})`)
        .attr('class', 'axis y-axis')
        .call(yAxis)
        .selectAll('text')
        .attr('class', 'axis-text y-axis-text')
        .call((texts, width) => {
          texts.each(function () {
            const text = d3.select(this);

            const dy = parseFloat(text.attr('dy'));

            const tspans: TSpanD3[] = [];
            const texts = text.text().split(' ');

            text.text(null);

            let tspan = text
              .append('tspan')
              .text(texts[0])
              .style('text-anchor', 'end')
              .attr('x', -15)
              .attr('dy', `-${dy}em`);

            texts.slice(1).forEach((t) => {
              const currentText = tspan.text();
              const newText = currentText ? `${currentText} ${t}` : `${t}`;

              tspan.text(newText);

              const textLength = (tspan.node() as any).getComputedTextLength();
              const isTextLengthBigger = textLength > width - 20;

              if (isTextLengthBigger) {
                tspan.text(currentText);
                tspans.push(tspan);

                tspan = text
                  .append('tspan')
                  .text(t)
                  .style('text-anchor', 'end')
                  .style('font-family', 'IBM Plex Sans')
                  .attr('x', -15)
                  .attr('dy', `${dy + 1}em`);
              }
            });

            const isLengthEqualsToZero = tspans.length === 0;
            if (isLengthEqualsToZero) tspan.attr('y', 8.5);
          });
        }, left)
        .on('mouseenter', (event, d) => {
          this.selected && this.selected(d as string);

          if (!this.selectedValue) return;

          const value = this.getMouseEnterValue(this.selectedValue.value);
          const label = `${d}: ${this.getMouseEnterLabel(value)}`;
          const t = this.drawLabel(value, event.y, label, d as string);

          this.EVENT_STACK.push('mouseenter', t);
        })
        .on('mouseout', () => {
          this.selected && this.selected(undefined);

          const top = this.EVENT_STACK.popIfTopIs('mouseenter');
          this.removeOnMouseLeave(top);
        });
    }

    return this;
  }

  plotData(color: string = '#000', rx: number = 0) {
    this.chart
      .selectAll('.rect-bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('transform', `translate(${this.labelWidth + this.margin.left}, 0)`)
      .attr('class', 'bar')
      .attr('x', this.xScale ? this.xScale(0) : 0)
      .attr('y', (d) => (this.yScale ? this.yScale(d.name) ?? 0 : 0))
      .attr('width', (d) => {
        const xValue = this.isPercentage
          ? this.getPercentage(d.value)
          : d.value;

        return this.xScale ? this.xScale(xValue) : 0;
      })
      .attr('height', this.yScale ? this.yScale.bandwidth() : 0)
      .attr('rx', rx)
      .attr('fill', (d) => d.color ?? color)
      .on('mouseenter', (event, d) => {
        const value = this.getMouseEnterValue(d.value);
        const label = `${d.name}: ${this.getMouseEnterLabel(value)}`;
        const t = this.drawLabel(value, event.y, label, d.name);

        this.EVENT_STACK.push('mouseenter', t);
      })
      .on('mouseleave', () => {
        const top = this.EVENT_STACK.popIfTopIs('mouseenter');

        this.removeOnMouseLeave(top);
      });

    return this;
  }

  animateBar() {
    const bars = this.chart.selectAll('.bar').attr('class', 'bar init');

    const id = setTimeout(() => {
      bars.attr('class', 'bar');

      clearTimeout(id);
    }, 0);

    return this;
  }

  disableAxisLine() {
    d3.select('.axis .domain').style('display', 'none');

    return this;
  }

  disableXAxisLine() {
    this.chart.selectAll('.x-axis .domain').style('display', 'none');

    return this;
  }

  disableYAxisLine() {
    this.chart.selectAll('.y-axis .domain').style('display', 'none');

    return this;
  }

  unmount() {
    this.chart.remove();

    return this;
  }
}
