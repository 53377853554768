import { useMemo } from 'react';

import {
  CardTagged,
  DonutPopulated as Donut,
  HorizontalGridBar,
  ServiceBox,
  ImageRanking,
  BarGroup,
  InlineImageRanking
} from '@/components/shared';
import { Text, ColorText } from '@/components/styled';

import {
  useBathroomData,
  useLightTypesData,
  useCookWaysData,
  useViolenceAreaSituationData,
  useActivitiesData,
  useGarbageCollectionData,
  useWatterSupplyData,
  useDrankWaterData,
  useHouseTypeData,
  usePipedWaterData
} from '@/servicesHooks/livingConditionAgents';

import { useBiggest, useDashboardFilter } from '@/hooks';
import { colors } from '@/theme/colors';

import { Grid, GridItem, WrapperGridItem } from './styles';

import { getBathroomAmountsValues } from './getBathroomAmountsValues';
import { getLightsDataWithImage } from './getLightsDataWithImage';
import { getCookWaysWithImage } from './getCookWaysWithImage';
import { getGarbageWithImages } from './getGarbageWithImages';
import { getPipedWaterAnswersValues } from './getPipedWaterAnswersValues';
import { useTheme } from 'styled-components';
import { Legend, LegendContainer } from '@/theme/utils';

const { green200, yellow500, orange300, red400 } = colors;

const THICKNESS = 20;

export function LivingConditionBottomSection() {
  const { tag } = useDashboardFilter();
  const { darkMode } = useTheme();
  const defaultChartColor = darkMode ? colors.ming : colors.ciano700;
  const {
    bathroomAmounts,
    loading: loadingBathroomAmounts,
    error: errorBathroomAmounts
  } = useBathroomData();
  const {
    lightTypes,
    loading: loadingLightTypes,
    error: errorLightTypes
  } = useLightTypesData();
  const {
    cookWays,
    loading: loadingCookWays,
    error: errorCookWays
  } = useCookWaysData();
  const {
    violenceAreaSituation,
    loading: loadingViolenceAreaSituation,
    error: errorViolenceAreaSituation
  } = useViolenceAreaSituationData();
  const {
    activities,
    loading: loadingActivities,
    error: errorActivities
  } = useActivitiesData();
  const {
    garbageCollectionPeriods,
    loading: loadingGarbageCollectionPeriods,
    error: errorGarbageCollectionPeriods
  } = useGarbageCollectionData();
  const {
    waterSupplyTypes,
    loading: loadingWaterSupplyTypes,
    error: errorWaterSupplyTypes
  } = useWatterSupplyData();
  const {
    drankWaterTypes,
    loading: loadingDrankWaterTypes,
    error: errorDrankWaterTypes
  } = useDrankWaterData();

  const {
    houseTypes,
    loading: loadingHouseTypes,
    error: errorHouseTypes
  } = useHouseTypeData();

  const {
    pipedWaterAnswers,
    loading: loadingPipedWaterAnswers,
    error: errorPipedWaterAnswers
  } = usePipedWaterData();

  const [yesToBathroom, noToBathroom] = useMemo(
    () => getBathroomAmountsValues(bathroomAmounts),
    [bathroomAmounts]
  );
  const lightTypesWithImage = useMemo(
    () => getLightsDataWithImage(lightTypes),
    [lightTypes]
  );
  const cookWaysWithImage = useMemo(
    () => getCookWaysWithImage(cookWays),
    [cookWays]
  );
  const garbageCollectionPeriodsWithImage = useMemo(
    () => getGarbageWithImages(garbageCollectionPeriods),
    [garbageCollectionPeriods]
  );

  const [yesToPipedWater, noToPipedWater] = useMemo(
    () => getPipedWaterAnswersValues(pipedWaterAnswers),
    [pipedWaterAnswers]
  );

  const biggestLightTypes = useBiggest(lightTypes);
  const biggestCookWays = useBiggest(cookWays);

  return (
    <Grid>
      <GridItem area="a">
        <CardTagged tag={tag}>
          <Text>
            <strong>As casas onde as famíliam moram são em maioria</strong>
          </Text>
          <ServiceBox
            empty={houseTypes.length === 0}
            loading={loadingHouseTypes}
            error={errorHouseTypes}
          >
            <Donut
              data={houseTypes.filter((houseType) => houseType.name !== 'null')}
              colors={[green200, yellow500, orange300, red400]}
              exactColors={true}
              label="famílias"
              thickness={THICKNESS}
              flexDirection="column"
              percentage
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="b">
        <CardTagged tag={tag}>
          <Text>
            <strong>Quais as informações sobre abastecimento de água?</strong>
          </Text>

          <ServiceBox
            empty={waterSupplyTypes.length === 0}
            loading={loadingWaterSupplyTypes}
            error={errorWaterSupplyTypes}
          >
            <HorizontalGridBar
              data={waterSupplyTypes}
              color={defaultChartColor}
              sizeRatio={0.8}
              isPercentage
              grid
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="c">
        <WrapperGridItem>
          <CardTagged tag={tag}>
            <Text>
              <strong>
                Os domicílios possuem água canalizada em pelo menos um cômodo?
              </strong>
            </Text>

            <ServiceBox
              empty={pipedWaterAnswers.length === 0}
              loading={loadingPipedWaterAnswers}
              error={errorPipedWaterAnswers}
            >
              <div className="piped-water-answers">
                <Text>
                  <ColorText>{yesToPipedWater}%</ColorText> das famílias
                  visitadas{' '}
                  <ColorText>
                    possuem água encanada em pelo menos um cômodo
                  </ColorText>{' '}
                  de suas residências, enquanto{' '}
                  <ColorText>{noToPipedWater}% não possuem</ColorText>
                </Text>
              </div>
            </ServiceBox>
          </CardTagged>

          <CardTagged tag={tag}>
            <Text>
              <strong>
                As famílias visitadas possuem banheiros ou sanitários nos
                domicílios?
              </strong>
            </Text>
            <ServiceBox
              empty={bathroomAmounts.length === 0}
              loading={loadingBathroomAmounts}
              error={errorBathroomAmounts}
            >
              <Text>
                <ColorText>{yesToBathroom}%</ColorText> das famílias visitadas{' '}
                <ColorText>possuem banheiro ou sanitário</ColorText> em suas
                residências, enquanto{' '}
                <ColorText>{noToBathroom}% não possuem</ColorText>
              </Text>
            </ServiceBox>
          </CardTagged>
        </WrapperGridItem>
      </GridItem>

      <GridItem area="d">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              Quantas vezes por semana há coleta de lixo nas residências?
            </strong>
          </Text>

          <ServiceBox
            empty={garbageCollectionPeriods.length === 0}
            loading={loadingGarbageCollectionPeriods}
            error={errorGarbageCollectionPeriods}
          >
            <InlineImageRanking
              data={garbageCollectionPeriodsWithImage}
              color={colors.ciano700}
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="e">
        <CardTagged tag={tag}>
          <Text>
            <strong>Quais o tipos de água para beber no domicílio?</strong>
          </Text>

          <ServiceBox
            empty={drankWaterTypes.length === 0}
            loading={loadingDrankWaterTypes}
            error={errorDrankWaterTypes}
          >
            <HorizontalGridBar
              data={drankWaterTypes}
              color={defaultChartColor}
              sizeRatio={0.6}
              isPercentage
              grid
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="f">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              Quais as formas de iluminação utilizadas nos municípios?
            </strong>
          </Text>

          <ServiceBox
            empty={lightTypes.length === 0}
            loading={loadingLightTypes}
            error={errorLightTypes}
          >
            <Text>
              <ColorText>{biggestLightTypes?.value}%</ColorText> das famílias
              visitadas{' '}
              <ColorText>
                possuem{' '}
                {biggestLightTypes?.name.toLowerCase().includes('elétrica') &&
                  ` energia `}{' '}
                {biggestLightTypes?.name.toLowerCase()}
              </ColorText>{' '}
              em suas residências.
            </Text>

            <ImageRanking data={lightTypesWithImage} />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="g">
        <CardTagged tag={tag}>
          <Text>
            <strong>Como as famílias cozinham nos domicílios?</strong>
          </Text>

          <ServiceBox
            empty={cookWays.length === 0}
            loading={loadingCookWays}
            error={errorCookWays}
          >
            <Text>
              <ColorText>{biggestCookWays?.value}%</ColorText> das famílias
              visitadas{' '}
              <ColorText>
                cozinham com {biggestCookWays?.name.toLowerCase()}
              </ColorText>{' '}
              em suas residências
            </Text>
            <ImageRanking data={cookWaysWithImage} />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="h">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              Existem lugares públicos para lazer, prática de atividades físicas
              e atividades culturais para crianças, jovens e adolescentes
              próximo aos domicílios?{' '}
            </strong>
          </Text>

          <ServiceBox
            empty={activities.length === 0}
            loading={loadingActivities}
            error={errorActivities}
          >
            <BarGroup
              data={activities}
              colors={[colors.green200, colors.red400]}
              legend={['Sim', 'Não']}
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="i">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              O domicílio está localizado em área de conflito/violência?
            </strong>
          </Text>

          <ServiceBox
            empty={violenceAreaSituation.length === 0}
            loading={loadingViolenceAreaSituation}
            error={errorViolenceAreaSituation}
          >
            <Donut
              data={violenceAreaSituation.map((situation) => ({
                ...situation
              }))}
              exactColors={true}
              label="famílias"
              thickness={THICKNESS}
              flexDirection="column"
              percentage
            />
          </ServiceBox>
          <LegendContainer>
            <Legend>
              Considerada apenas as respostas válidas.
            </Legend>
          </LegendContainer>
        </CardTagged>
      </GridItem>
    </Grid>
  );
}
