import { Card, D3Map } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import styled from 'styled-components';
import { useData } from './useData';

interface SectionMapProps {
  color: string;
}
export function SectionMap({ color }: SectionMapProps) {
  const { ibge, year, actionType, city, onChangeCity } = useAnalyticsFilter();

  const { data, loading, error } = useData({
    codeIbge: ibge,
    deedLine: actionType,
    year: year
  });

  return (
    <Main>
      <Card
        title="Número de cursos promovidos pela Coordenadoria de Inclusão Social"
        loading={loading}
        noMargin
        empty={data.length === 0}
        error={error}
        date={year ? year?.toString() : undefined}
        tags={[city ?? 'Ceará', actionType ?? '']}
      >
        <D3Map
          width={500}
          data={data}
          color={color}
          filteringCity={ibge}
          handleSelectedCity={onChangeCity}
        />
      </Card>
    </Main>
  );
}

export const Main = styled.main`
  padding-top: 50px;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  place-content: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: max-content;
  }
`;
