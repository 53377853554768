import { Select, Tooltip } from 'antd';
import { FiPlus, FiTrash } from 'react-icons/fi';
import { Show } from '@/components/shared/Show';
import { Box } from './styles';

interface Option {
  label: string;
  value: string;
}

interface MultipleSelectProps {
  label: string;
  indicatorLabel: string;
  axesOptions: Option[];
  indicatorOptions: Option[];
  axesValue: string;
  indicatorValue: string;
  showIndicator?: boolean;
  showAdd?: boolean;
  showDelete?: boolean;
  onAddAxe?: () => void;
  onRemoveAxe?: () => void;
  onChangeAxe?: (value: string) => void;
  onChangeIndicator?: (value: string) => void;
}

export function MultipleSelect({
  label,
  indicatorLabel,
  axesOptions,
  indicatorOptions,
  axesValue,
  indicatorValue,
  showIndicator,
  showAdd,
  showDelete,
  onAddAxe,
  onRemoveAxe,
  onChangeAxe,
  onChangeIndicator
}: MultipleSelectProps) {
  return (
    <Box>
      <span className="label">{label}</span>

      <div className="axe">
        <Select
          className="field"
          options={axesOptions}
          value={axesValue}
          onChange={onChangeAxe}
        />

        <Show when={!!showAdd} fallback={null}>
          <Tooltip title="Clique para adicionar um novo eixo">
            <button className="add" onClick={onAddAxe}>
              <FiPlus />
            </button>
          </Tooltip>
        </Show>

        <Show when={!!showDelete} fallback={null}>
          <Tooltip title="Clique para deletar o eixo">
            <button className="remove" onClick={onRemoveAxe}>
              <FiTrash />
            </button>
          </Tooltip>
        </Show>
      </div>

      <Show when={!!showIndicator} fallback={null}>
        <div className="indicator">
          <span className="label">{indicatorLabel}</span>

          <Select
            className="field"
            options={indicatorOptions}
            value={indicatorValue}
            onChange={onChangeIndicator}
          />
        </div>
      </Show>
    </Box>
  );
}
