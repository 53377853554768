import {
  EducationEnum,
  HealthEnum,
  InsecurityFoodEnum,
  LivingConditionEnum,
  SocialAssistanceEnum,
  WorkEnum
} from '@/services';

export const LABEL_BY_VALUES: Record<string, string> = {
  [InsecurityFoodEnum.FORA_DE_INSEGURANCA_ALIMENTAR]:
    'Fora de Insegurança Alimentar',
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_LEVE]: 'Insegurança Alimentar Leve',
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_MODERADA]:
    'Insegurança Alimentar Moderada',
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_GRAVE]:
    'Insegurança Alimentar Grave',
  [EducationEnum.CRECHE]: 'Crianças não matriculadas em creche',
  [EducationEnum.PRE_ESCOLA]: 'Crianças não matriculadas em pré-escola',
  [EducationEnum.ESCOLA]: 'Crianças não matriculadas em escola',
  [SocialAssistanceEnum.CRAS]: 'Famílias não acompanhadas pelo CRAS',
  [SocialAssistanceEnum.CREAS]: 'Famílias não acompanhadas pelo CREAS',
  [HealthEnum.GESTANTE_PRE_NATAL]: 'Gestantes sem acompanhamento pré-natal',
  [HealthEnum.ACOMPANHAMENTO_CRIANCA]:
    'Crianças sem acompanhamento de equipe de saúde',
  [HealthEnum.CRIANCA_VACINA_EM_DIA]: 'Crianças com esquema vacinal incompleto',
  [WorkEnum.TRABALHO_REMUNERADO]:
    'Representantes familiares sem trabalho remunerado',
  [WorkEnum.SALARIO_MINIMO]:
    'Representantes familiares que possuem até meio salário mínimo',
  [WorkEnum.FAZ_CURSO]:
    'Representantes familiares que não realizaram cursos de capacitação',
  [WorkEnum.QUER_FAZER_CURSO]:
    'Representantes familiares que têm interesse em realizar curso de capacitação',
  [WorkEnum.RENDA]:
    'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância', // considerar o 'Bolsa família / Cartão Mais Infância; Outros?'
  [LivingConditionEnum.AGUA_CANALIZADA]:
    'Famílias que não possuem água canalizada em pelo menos 1 cômodo',
  [LivingConditionEnum.BANHEIRO]:
    'Famílias que não possuem banheiro em suas residências',
  [LivingConditionEnum.COLETA_SELETIVA]:
    'Famílias que não possuem acesso à coleta seletiva',
  [LivingConditionEnum.LUGARES_PUBLICOS_CRIANCA_BRINCAR]:
    'Famílias que não possuem lugares públicos para brincar próximo às residências',
  [LivingConditionEnum.ATIVIDADE_CULTURAL]:
    'Famílias que não possuem atividades culturais próximo às residências',
  [LivingConditionEnum.ATIVIDADE_ESPORTIVA]:
    'Famílias que não possuem atividades esportivas próximo às residências',
  [LivingConditionEnum.AREA_CONFLITO]:
    'Famílias que estão localizadas em área de conflito/violência' // Tem que ser o sim?,
};
