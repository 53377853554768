import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const CardBase = styled.div`
  border-radius: 7px;
  background: ${({ theme }) => (theme.darkMode ? `#000` : `#ffffff`)};
  border: 1px solid #dfe6e9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const NormalCardHeader = styled.header`
  padding: 1rem;

  h4,
  span {
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
  }

  h4 {
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray700)};
  }

  span {
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.ciano700)};
  }
`;

export const LargeCardHeader = styled.header`
  border-bottom: 1px solid #dfe6e9;
  padding: 1rem;

  h4 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.ciano700)};
  }
`;

export const LargeCardFooter = styled.footer`
  border-top: 1px solid #dfe6e9;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    font-size: 1rem;
    font-weight: 700;
    line-height: 22px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray700)};
  }

  .search-pagination {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;

    & > span {
      align-self: flex-start;
    }

    & > div {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
  }
`;

interface CardContentProps {
  direction?: 'horizontal' | 'vertical';
  gap?: string;
}

export const CardContent = styled.div<CardContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ direction }) =>
    direction === 'vertical' ? 'column' : 'row'};
  gap: ${({ gap }) => gap ?? '1rem'};
  padding: 1rem 1rem 2rem;
  min-height: 220px;

  ${mediaQuery.smallScreen} {
    flex-direction: column;

    & > span {
      align-self: flex-start;
    }

    & > div {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
