import styled from 'styled-components';

export const MainCards = styled.div`
  width: 100%;

  margin: 1rem 0;

  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;
