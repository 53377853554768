import { Rule } from 'antd/lib/form';

export const formRules: Record<string, Rule> = {
  minCharTitle: {
    min: 15,
    message: 'O título deve ter pelo menos 15 caracteres'
  },
  title: {
    required: true,
    message: 'Você deve inserir um título para a ação'
  },
  indicator: {
    required: true,
    message: 'Você deve selecionar um indicador'
  },
  polarity: {
    required: true,
    message: 'Você deve selecionar uma polaridade'
  },
  goal: {
    required: true,
    message: 'Você deve selecionar um valor'
  },
  time: {
    required: true,
    message: 'Você deve selecionar um semestre'
  },
  description: {
    required: true,
    message: 'Você deve detalhar seu plano de ação'
  },
  file: {
    required: true,
    message: 'Você precisa anexar um arquivo'
  }
};
