import { ReactNode } from 'react';
import { Box, Select } from './styles';

import { FilterIndicatorIcon } from '..';

interface SelectBoxProps {
  children: ReactNode;
  onClear(): void;
}

export function SelectBox({ children, onClear }: SelectBoxProps) {
  return (
    <Box className="tab-select-container">
      <FilterIndicatorIcon />
      {children}
      <button className="clear" onClick={onClear}>
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.71119 0.75H4.2194C3.57749 0.75 3.05546 1.31062 3.05546 2V3.25H0.7276V4.5H1.89153V12C1.89153 12.6894 2.41356 13.25 3.05546 13.25H8.87513C9.51704 13.25 10.0391 12.6894 10.0391 12V4.5H11.203V3.25H8.87513V2C8.87513 1.31062 8.3531 0.75 7.71119 0.75ZM4.2194 2H7.71119V3.25H4.2194V2ZM8.87513 12H3.05546V4.5H8.87513V12Z"
            fill="#1D696F"
          />
        </svg>
        Limpar
      </button>
    </Box>
  );
}

export { Select };
