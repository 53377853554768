import { RankingModel } from 'big-data';

import { CardTagged, RankingDashboard, ServiceBox } from '@/components/shared';
import { TitleText, ColorText, Text } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useSourceOfIncomeData } from '@/servicesHooks/jobIncome';
import { percentageFormatter } from '@/utils/string';
import { getDashboardRanking } from '@/utils/data';

import { AgricultorIcon } from '@/components/shared/RankingDashboard/Icons/Agricultor.icon';
import { ChildhoodCardIcon } from '@/components/shared/RankingDashboard/Icons/ChildhoodCard.icon';
import { MultipleIcon } from '@/components/shared/RankingDashboard/Icons/Multiple.icon';
import { PensionIcon } from '@/components/shared/RankingDashboard/Icons/Pension.icon';
import { RetirimentIcon } from '@/components/shared/RankingDashboard/Icons/Retirement.icon';
import { SalariedWorkIcon } from '@/components/shared/RankingDashboard/Icons/SalariedWork.icon';
import { Legend, LegendContainer } from '@/theme/utils';

const rankingModel = new Map<string, RankingModel>([
  [
    'agricultura, pecuária, pesca ou aquicultura',
    {
      description: () => 'vivem de',
      img: <AgricultorIcon />,
      name: ' Agricultura, Pecuária, Pesca e Aquicultura',
      key: 'agriculture'
    }
  ],
  [
    'aposentadoria',
    {
      description: () => 'vivem de',
      img: <RetirimentIcon />,
      name: ' Aposentadoria',
      key: 'aposentadoria'
    }
  ],
  [
    'pensão',
    {
      description: () => 'vivem de',
      img: <PensionIcon />,
      name: ' Pensão',
      key: 'pension'
    }
  ],
  [
    'bolsa família / cartão mais infância',
    {
      description: () => 'vivem de',
      img: <ChildhoodCardIcon />,
      name: ' Bolsa Família / Cartão Mais Infância',
      key: 'family_bag'
    }
  ],
  [
    'trabalho como assalariado',
    {
      description: () => 'vivem de',
      img: <SalariedWorkIcon />,
      name: ' Trabalho como assalariado',
      key: 'salaried_work'
    }
  ],
  [
    'outros',
    {
      description: () => 'vivem de',
      img: <MultipleIcon />,
      name: ' Outras ocupações',
      key: 'others'
    }
  ]
]);

export function CardRanking() {
  const { tag } = useDashboardFilter();
  const { data, ...rest } = useSourceOfIncomeData();

  const ranking = getDashboardRanking(data, rankingModel).sort(
    (a, b) => b.value - a.value
  );

  const total = data[0]?.total ?? 0;
  const [bigger] = ranking;

  const biggerDescription = (
    <Text>
      <ColorText>
        {percentageFormatter(((bigger?.value ?? 0) / total) * 100)} vivem de{' '}
        {bigger?.name ?? ''}
      </ColorText>
      , como mostram os dados a seguir:
    </Text>
  );

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>Quais as principais fontes de renda das famílias?</TitleText>
        {biggerDescription}
        <RankingDashboard
          data={ranking}
          loadingBenefits={false}
          selected={undefined}
          total={total}
        />
        <LegendContainer>
          <Legend>
            Uma família pode ter uma ou mais fontes de renda.
          </Legend>
        </LegendContainer>
      </ServiceBox>
    </CardTagged>
  );
}
