import { Box, Bar } from './styles';

export interface TabsItem {
  id: number;
  name: string;
}

interface TabsProps {
  items: TabsItem[];
  value?: number;
  onClickTab?: (item: TabsItem) => void;
}

export function Tabs({ items, value = 0, onClickTab }: TabsProps) {
  function handleClick(idx: number) {
    if (onClickTab) {
      onClickTab(items[idx]);
    }
  }

  const left = (value ?? 0) * 100;

  return (
    <Box>
      <ul className="tabs">
        {items.map((item, idx) => (
          <li
            key={item.id}
            data-state={item.id === value ? 'active' : 'inactive'}
            onClick={() => handleClick(idx)}
          >
            {item.name}
          </li>
        ))}
      </ul>
      <Bar left={left} />
    </Box>
  );
}
