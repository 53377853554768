import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { CitizenAccessUserParams } from './types';

const service = () => {
  const resource = `/dados-acesso-usuario/acesso`;

  async function get(params?: CitizenAccessUserParams) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate: params?.minimumDate,
        // cpf: params?.userId,
        order: params?.order ?? 'desc',
        ibge: params?.ibge,
        size: 5
      }
    });
    const response = await callService(() =>
      mainApi.get<{ name: string; cpf: string; quantidade: number }[]>(
        path,
        options
      )
    );
    return response.data.map((data) => ({
      ...data,
      value: data.quantidade
    }));
  }

  return {
    get
  };
};

export const citizenAccessUsers = service();
