import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { colorGenerator } from '@/pages/Lgbt/utils/colors';
import { useEducation } from '@/services/lgbt/education';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';

const nameMap = new Map([
  ['Sim', 'Sofreu algum tipo de LGBTfobia'],
  ['Não', 'Não sofreu LGBTfobia']
]);

export function LgbtPhobiaSchool() {
  const { ibge, orientation, city, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useEducation.lgbtPhobiaSchool({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, ibge, orientation, identity]);

  const formatedDataDonut = useMemo(() => {
    return data.map((d) => {
      const color = colorGenerator(d.name, true);
      const name = nameMap.get(d.name) || 'Não informado';

      return {
        ...d,
        color,
        name
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="LGBTfobias sofridas nas escolas pelas pessoas LGBTQIA+ entrevistadas"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <DonutPopulated
          calculatedOut
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedDataDonut}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
