
export enum AxeEnum {
  INSEGURANCA_ALIMENTAR = 'Insegurança Alimentar',
  EDUCACAO = 'Educação',
  ASSISTENCIA_SOCIAL = 'Assistência Social',
  SAUDE = 'Saúde',
  TRABALHO_RENDA = 'Trabalho e Renda',
  MORADIA = 'Moradia'
}


