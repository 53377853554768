import { SelectBox, Select } from '@/components/shared/SelectBox';
import { TabBox } from '@/components/styled';
import { TabPaneProps, Tabs } from 'antd';
import { LeaderProfile } from '../TabPanes/LeaderProfile';

import { Organizations } from '../TabPanes/Organizations';
import { TargetAudience } from '../TabPanes/TargetAudience';
import { Box } from './styles';

import { CITIES } from '@/constants/cities';
import { useFilter } from '../hooks/useFilter';

const { TabPane } = Tabs;

const tabPanes: TabPaneProps[] = [
  { tabKey: '1', tab: 'Organizações', children: <Organizations /> },
  { tabKey: '2', tab: 'Público-alvo', children: <TargetAudience /> },
  { tabKey: '3', tab: 'Perfil das lideranças', children: <LeaderProfile /> }
];

export function Insights() {
  const { city, ibge, handleChangeCity, onClear } = useFilter();

  return (
    <Box>
      <TabBox>
        <Tabs
          tabBarExtraContent={{
            right: (
              <SelectBox onClear={onClear}>
                <Select
                  className="tab-select-options"
                  showSearch
                  placeholder="Ceará"
                  value={
                    city !== undefined ? { key: ibge, label: city } : undefined
                  }
                  onChange={(value: any) => {
                    handleChangeCity(
                      value ? `${value.label}_${value.key}` : undefined
                    );
                  }}
                  labelInValue
                  onClear={onClear}
                >
                  {CITIES.map((city) => (
                    <Select.Option
                      key={city.ibge}
                      value={`${city.municipio}_${city.ibge}`}
                    >
                      {city.municipio}
                    </Select.Option>
                  ))}
                </Select>
              </SelectBox>
            )
          }}
        >
          {tabPanes.map((tabPane) => (
            <TabPane key={tabPane.tabKey} {...tabPane} />
          ))}
        </Tabs>
      </TabBox>
    </Box>
  );
}
