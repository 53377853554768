import { PlatformSatisfactionReportProps } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `/avaliacoes`;

  async function report(data: PlatformSatisfactionReportProps) {
    const path = resource;
    await callService(() => mainApi.post(path, data));
  }

  return {
    report
  };
};

export const platformSatisfaction = service();
