import { SelectBox, Tabs } from '@/components/shared';
import { Select } from '@/components/shared/SelectBox';
import { GenericGridItem } from '@/components/styled';
import { useAuth } from '@/hooks';

import { InsightsBox } from '../../components/insights-box.styles';
import { TabFilterBox } from '../../components/tab-filter-box.styles';
import { useFilterContext, useTabContext } from '../../contexts';
import { tabItems } from '../../utils';
import { analyticsHomeComponents } from './components';
import { Box, Column } from './styles';

import citiesCE from '@/constants/ceara.json';
import { useMemo } from 'react';

export function SectionContent() {
  const { isAdm } = useAuth();

  const { handleChangeTab, tab } = useTabContext();
  const { city, handleChangeCity, onClear } = useFilterContext();

  const analyticsHomeComponentsWithId = useMemo(
    () =>
      analyticsHomeComponents.map((analytic, i) => ({
        ...analytic,
        components: analytic.components.map((component, i) => ({
          Component: component,
          id: i
        }))
      })),
    []
  );

  return (
    <Box>
      <TabFilterBox>
        <Tabs
          items={tabItems}
          activeTab={tab?.value}
          onClickTab={handleChangeTab}
        />
        <SelectBox onClear={onClear}>
          <Select
            bordered={false}
            className="tab-select-options"
            placeholder="Selecione uma cidade"
            labelInValue
            value={
              city?.ibge && city?.name
                ? { label: city.name, value: city.ibge, key: city.ibge }
                : undefined
            }
            onChange={
              (({ label, key }: { label: string; key: string }) =>
                handleChangeCity({ ibge: key, name: label })) as any
            }
            showSearch
            disabled={!isAdm}
            aria-disabled={!isAdm}
          >
            {citiesCE.features.map((city) => (
              <Select.Option
                key={city.properties.id}
                value={city.properties.name}
              >
                {city.properties.name}
              </Select.Option>
            ))}
          </Select>
        </SelectBox>
      </TabFilterBox>

      <InsightsBox>
        {analyticsHomeComponentsWithId.map(
          ({ id, area, components, direction = 'column' }) => (
            <GenericGridItem key={id} area={area}>
              <Column
                direction={direction}
                className="grid-item-column-analytics-panel"
              >
                {components.map((component) => {
                  const { Component, id } = component;

                  return <Component key={id} />;
                })}
              </Column>
            </GenericGridItem>
          )
        )}
      </InsightsBox>
    </Box>
  );
}
