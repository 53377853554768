import { ReactNode } from 'react';
import { ServiceBox } from '..';
import { Box } from './styles';

interface DashboardCardProps {
  loading?: boolean;
  error?: Error | null;
  empty?: boolean;
  children: ReactNode;
  color?: string;
}

export function DashboardCard({
  children,
  empty = false,
  error = null,
  loading,
  color
}: DashboardCardProps) {
  return (
    <Box color={color} className="dashboard-card-box">
      <ServiceBox loading={loading} error={error} empty={empty}>
        {children}
      </ServiceBox>
    </Box>
  );
}
