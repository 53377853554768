import { useEffect, useMemo } from 'react';
import { DataWithIbgeCode } from 'big-data';

import { Section } from '@/layout';
import { Card, D3Map } from '@/components/shared';
import { cmic } from '@/services';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { max } from '@/utils/math';

interface FamiliesMapProps {
  color: string;
}

export function FamiliesMap({ color }: FamiliesMapProps) {
  const { data, loading, error, request } = useService<DataWithIbgeCode>();
  const { ibge, city, onChangeCity } = useAnalyticsFilter();

  useEffect(() => {
    request(() => cmic.getCitiesWithCodIbge());
  }, [request]);

  const filteredCity = useMemo(() => {
    if (!!ibge) {
      return data.filter((_city) => _city.codIbge === Number(ibge));
    }

    return data;
  }, [data, ibge]);

  const maxValue = useMemo(() => max(data.map((d) => d.value)), [data]);

  return (
    <Section>
      <Card
        title="Número de famílias beneficiadas pelo Cartão Mais Infância por município no Ceará"
        empty={filteredCity.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ? city : 'Ceará']}
      >
        <D3Map
          color={color}
          maxValue={maxValue}
          filteringCity={ibge}
          handleSelectedCity={onChangeCity}
          data={filteredCity}
        />
      </Card>
    </Section>
  );
}
