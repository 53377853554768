import styled from 'styled-components';

export const AxeDescriptionText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  color: ${({ theme }) => (theme.darkMode ? '#fff' : '#4c5469')};
  margin-top: 5px;
  margin-bottom: 1.5rem;
`;
