import styled from 'styled-components';

export const Box = styled.section`
  .thermometer {
    display: flex;
    justify-content: center;
  }

  .left-cards {
    @media (max-width: 1240px) {
      grid-area: card;
    }
  }
`;
