import styled from 'styled-components';
import { mediaQuery } from './mediaQuery';
import { colors } from './colors';

export const getSize = (size: string | number) => {
  if (typeof size === 'string') return size;

  return `${size}px`;
};

export const ContentBox = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 20px;

  ${mediaQuery.mediumScreen} {
    width: 95%;
  }

  ${mediaQuery.smallScreen} {
    padding: 40px 0;
  }
`;

interface MarginProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const Margin = styled.div<MarginProps>`
  margin: ${({ top, bottom, left, right }) =>
    `${top ?? 0}px ${right ?? 0}px ${bottom ?? 50}px ${left ?? 0}px`};
`;

export const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
`;

export const Legend = styled.p`
  width: 50%;

  text-align: right;

  color: ${(props) => (props.theme.darkMode ? `#fff` : colors.green700)};
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;

  letter-spacing: 0.02em;
`;