import { useAnalyticsTitle } from '@/hooks/useAnalyticsTitle';
import {
  ApresentationCard,
  TextIcon,
  LastUpdateMessage
} from '@/components/shared';

import { AdultSchoolingInsights } from '../components';
import { useLastUpdate } from '../hooks/useLastUpdate';
import { Box } from './styles';

const text = () => (
  <p>
    A garantia do acesso à educação formal para jovens e adultos permite o
    usufruto das produções científicas, culturais e tecnológicas desenvolvidas
    em sociedade. Além disso, a escolarização se associa à estratificação
    social, à distribuição de oportunidades ocupacionais e de reconhecimento. O
    Cadastro Único é um instrumento que identifica e caracteriza as famílias de
    baixa renda, permitindo que o governo conheça melhor a realidade
    socioeconômica dessa população.
  </p>
);

export function AdultSchooling() {
  const { lastUpdate, loading, error } = useLastUpdate('cadunico');
  useAnalyticsTitle('Escolaridade entre Adultos no Ceará');
  return (
    <Box>
      <LastUpdateMessage
        value={lastUpdate?.insercao}
        loading={loading}
        error={error}
      />
      <ApresentationCard title="Informações Gerais" source="CadÚnico">
        <TextIcon text={text} />
      </ApresentationCard>
      <AdultSchoolingInsights />
    </Box>
  );
}
