import { useMemo } from 'react';
import { DashboardInsightProps, Data } from 'big-data';

import {
  CardTagged,
  D3Map,
  DonutPopulated,
  HorizontalGridBar,
  RankingDashboard,
  ServiceBox,
  Table
} from '@/components/shared';
import { ColorText, Text, TextI } from '@/components/styled';
import {
  useBenefitsData,
  useFamilyCities,
  useResponseData,
  useProgramData
} from '@/servicesHooks/socialAssistance';
import { useDashboardFilter } from '@/hooks';
import { colors } from '@/theme/colors';
import { percentageFormatter } from '@/utils/string';

import { modifierColors, resolveMapColor } from '../../utils';
import { useRanking } from './useRanking';
import { Box } from './styles';
import { useTheme } from 'styled-components';
import { Legend, LegendContainer } from '@/theme/utils';

interface SocialAssistanceInsightProps extends DashboardInsightProps {
  familyTotal: number;
}

const DONUT_THICKNESS = 25;
const DONUT_HEIGHT = 300;
const DONUT_WIDTH = 300;

const FAMILY_NO_LABEL = 'famílias não participaram';

const commonLabels = new Map([
  [FAMILY_NO_LABEL, '#D4282A'],
  ['famílias participaram', '#55A546'],
  ['famílias não sabem informar', '#FBC34A']
]);

const tableColumn = [
  { name: 'Atividade', key: 'name' },
  { name: 'Quantidade', key: 'value' }
];

const crasLabels = new Map([
  ...commonLabels,
  ['famílias não possuem CRAS no município', '#B9C0C3']
]);

const creasLabels = new Map([
  ...commonLabels,
  ['famílias não possuem CREAS no município', '#B9C0C3']
]);

const generateSocialAssistenceColors = (
  model: Map<string, string>,
  data: Data[]
) => {
  return (
    data
      .map((data) => {
        return model.get(data.name.trim()) ?? '';
      })
      .filter(Boolean) ?? []
  );
};

export function SocialAssistanceInsight({
  selected,
  familyTotal
}: SocialAssistanceInsightProps) {
  const theme = useTheme();
  const { city, tag } = useDashboardFilter();

  const color = modifierColors.get(selected?.color ?? '');
  const name = selected ? selected?.name : 'CRAS, nem pelo CREAS ';
  const { cras, creas, loadingData, errorData } = useResponseData();

  const { dataPrograCreas, loading, error, dataProgramCras } = useProgramData();

  const { benefits, loadingBenefits, errorBenefits } = useBenefitsData(
    selected?.name?.toUpperCase()
  );
  const { cities, loadingCities, errorCities } = useFamilyCities(
    selected?.name
  );
  const bindedBenefits = useRanking(benefits);

  const generateCrasDonutColors = generateSocialAssistenceColors(
    crasLabels,
    cras
  );
  const generateCreasDonutColors = generateSocialAssistenceColors(
    creasLabels,
    creas
  );

  const nameAnd = selected ? selected?.name : 'CRAS ou pelos CREAS ';

  const total = useMemo(() => {
    return (
      (cities.reduce((acc, cur) => acc + (cur.percentual ?? 0), 0) /
        familyTotal) *
      100
    );
  }, [cities, familyTotal]);

  const totalBenefit = useMemo(() => {
    return cities.reduce((acc, cur) => acc + (cur.percentual ?? 0), 0);
  }, [cities]);

  const hasCreas = useMemo(() => {
    if (cities.length === 1) return cities[0].hasCreas;
  }, [cities]);

  return (
    <Box>
      <div className="map">
        <CardTagged
          tag={tag}
          color={color}
          title={`Famílias com CMIC não atendidas pelos ${name}`}
        >
          <ServiceBox
            empty={cities.length === 0}
            loading={loadingCities}
            error={errorCities}
          >
            <Text>
              <ColorText color={color}>
                {selected
                  ? percentageFormatter(selected.percentageValue, true)
                  : percentageFormatter(total)}
              </ColorText>{' '}
              das famílias em situação de vulnerabilidade social, com crianças
              de 0 a 5 anos e 11 meses, não foram assistidas pelos {name}.
            </Text>

            <D3Map
              hasCreas={hasCreas}
              data={cities}
              color={resolveMapColor(!!selected, color ?? '', theme)}
              filteringCity={city}
              percentage={true}
              mapDescription={
                'Passe o mouse pelo mapa para visualizar a porcentagem de famílias não acompanhadas por município.'
              }
            />
          </ServiceBox>
        </CardTagged>
      </div>

      {selected && (
        <div className="ranking">
          <CardTagged
            tag={tag}
            color={color}
            title="Principais benefícios sociais entre as famílias visitadas
            "
          >
            <ServiceBox
              empty={benefits.length === 0}
              loading={loadingBenefits}
              error={errorBenefits}
            >
              <Text>
                <strong>
                  Quais são os benefícios que as famílias mais estão tendo
                  acesso?
                </strong>
              </Text>
              <RankingDashboard
                loadingBenefits={loadingBenefits}
                data={bindedBenefits}
                selected={selected}
                color={color}
                total={totalBenefit}
              />
              <LegendContainer>
                <Legend>
                  Uma família pode receber um ou mais benefícios.
                </Legend>
              </LegendContainer>
            </ServiceBox>
          </CardTagged>
        </div>
      )}

      {selected && (
        <>
          {name === 'CREAS' && (
            <CardTagged
              tag={tag}
              color={color}
              title="Principais atividades do CREAS realizadas entre as famílias visitadas
              "
            >
              <ServiceBox
                empty={dataPrograCreas.length === 0}
                loading={loading}
                error={error}
              >
                <Text>
                  <strong>
                    Nos últimos três meses, entre as famílias que FORAM
                    atendidas pelos CREAS, qual a quantidade de atividades
                    desenvolvidas?
                  </strong>
                </Text>
                <Table
                  percentage={false}
                  data={dataPrograCreas}
                  columns={tableColumn}
                />
              </ServiceBox>
            </CardTagged>
          )}

          {name === 'CRAS' && (
            <CardTagged
              tag={tag}
              color={color}
              title="Principais atividades do CRAS realizadas entre as famílias visitadas
              "
            >
              <ServiceBox
                empty={dataProgramCras.length === 0}
                loading={loading}
                error={error}
              >
                <Text>
                  <strong>
                    Nos últimos três meses, entre as famílias que FORAM
                    atendidas pelos CRAS, qual a quantidade de atividades
                    desenvolvidas?
                  </strong>
                </Text>
                <Table
                  percentage={false}
                  data={dataProgramCras}
                  columns={tableColumn}
                />
              </ServiceBox>
            </CardTagged>
          )}
        </>
      )}

      {name !== 'CREAS' && (
        <>
          <div className="comparation">
            <CardTagged
              tag={tag}
              color={color}
              title="Famílias não envolvidas em atividades dos CRAS"
            >
              <ServiceBox
                empty={cras.length === 0}
                loading={loadingData}
                error={errorData}
              >
                <Text>
                  Quantas famílias entrevistadas não participaram de atividades
                  do CRAS nos últimos 3 meses?
                </Text>
                <DonutPopulated
                  data={cras}
                  thickness={DONUT_THICKNESS}
                  label="famílias"
                  subLabel="entrevistadas"
                  percentage
                  flexDirection="column"
                  colors={generateCrasDonutColors}
                  exactColors={true}
                  donutWidth={DONUT_WIDTH}
                  donutHeight={DONUT_HEIGHT}
                  highlight={(data) => data.name === FAMILY_NO_LABEL}
                />
              </ServiceBox>
            </CardTagged>
          </div>
        </>
      )}

      {name !== 'CRAS' && (
        <>
          <div className="comparation">
            <CardTagged
              tag={tag}
              color={color}
              title="Famílias não envolvidas em atividades dos CREAS"
            >
              <ServiceBox
                empty={cras.length === 0}
                loading={loadingData}
                error={errorData}
              >
                <Text>
                  Quantas famílias entrevistadas não participaram de atividades
                  do CREAS nos últimos 3 meses?
                </Text>
                <DonutPopulated
                  data={creas}
                  thickness={DONUT_THICKNESS}
                  label="famílias"
                  subLabel="entrevistadas"
                  flexDirection="column"
                  colors={generateCreasDonutColors}
                  exactColors
                  percentage
                  donutWidth={DONUT_WIDTH}
                  donutHeight={DONUT_HEIGHT}
                  highlight={(data) => data.name === FAMILY_NO_LABEL}
                />
              </ServiceBox>
            </CardTagged>
          </div>
        </>
      )}

      <div className="graph">
        <CardTagged
          tag={tag}
          color={color}
          title="Municípios com menos famílias assistidas"
        >
          <ServiceBox
            empty={cities.length === 0}
            loading={loadingCities}
            error={errorCities}
          >
            <Text>
              Quais os municípios com menos famílias assistidas pelos {nameAnd}{' '}
              nos últimos 3 meses?
            </Text>
            <Text>
              <ColorText color={color}>{cities[0]?.name}</ColorText> é a cidade
              com menos famílias assistidas atualmente.
            </Text>

            <TextI>
              Passe o mouse pelos municípios para ver a porcentagem de famílias
              que não foram acompanhadas pelos centros de referência em cada
              município.
            </TextI>

            <HorizontalGridBar
              data={cities}
              grid
              color={theme.darkMode ? colors.ming : color ?? colors.ciano700}
              isPercentage
              percentageCalculatedOut
              hasPagination
            />
          </ServiceBox>
        </CardTagged>
      </div>
    </Box>
  );
}
