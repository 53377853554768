import { Slider as AntSlider, Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { ReactNode } from 'react';

interface SliderProps {
  rules: Rule[];
  name: string;
  label: string;
  dependencies: string[];
  max: number;
  tip: (value: number) => ReactNode;
  tipVisible?: boolean;
  decrease?: boolean;
  disabled?: boolean;
}

const { Item: FormItem } = Form;

const getMarks = (max: number) => {
  const steps = 4;
  const stepsJump = Math.ceil(max / steps);

  return Array(steps + 1)
    .fill(0)
    .map((value, idx) => {
      if (idx === 0) return [0, '0%'];
      if (idx === steps) return [max, `${max}%`];

      const label = idx * stepsJump;

      return [label, `${label}%`];
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export function Slider({
  rules,
  name,
  label,
  dependencies,
  max,
  tip,
  tipVisible,
  decrease,
  disabled
}: SliderProps) {
  function renderTooltip(value?: number) {
    if (value === undefined) return;

    if (value === Number(max.toFixed(1))) return tip(max);

    return `${value}%`;
  }

  return (
    <FormItem
      rules={rules}
      name={name}
      label={label}
      dependencies={dependencies}
      style={{ marginBottom: '50px' }}
    >
      <AntSlider
        max={decrease ? max : 100}
        disabled={disabled}
        step={0.1}
        marks={getMarks(decrease ? max : 100)}
        tipFormatter={renderTooltip}
        tooltipVisible={tipVisible}
        tooltipPlacement="bottom"
        getTooltipPopupContainer={(trigger) =>
          trigger.parentNode as HTMLElement
        }
      />
    </FormItem>
  );
}
