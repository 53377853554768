import { useState, useCallback } from 'react';
import { Action } from 'big-data';

import { actions } from '@/services';

export function useForumDetails() {
  const [forumDetails, setForumDetails] = useState<Action | null>(null);
  const [loadingForumDetails, setLoadingForumDetails] = useState(false);
  const [errorForumDetails, setErrorForumDetails] = useState('');

  const refreshForumDetails = useCallback((id: number) => {
    actions
      .getAction(id)
      .then((response) => setForumDetails(response))
      .catch((err) =>
        setErrorForumDetails(
          err.message ??
            'Houve um problema interno! Por favor tente novamente em alguns minutos'
        )
      )
      .finally(() => setLoadingForumDetails(false));
  }, []);

  return {
    forumDetails,
    loadingForumDetails,
    errorForumDetails,
    refreshForumDetails
  };
}
