import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

const darkModeBox = css`
  background: #000;

  .information-title,
  .information-about {
    color: #fff;
  }

  ul {
    li {
      p {
        color: #fff;

        span,
        strong {
          color: #fff;
        }
      }

      img {
        background-color: ${({ theme }) => theme.background};
        border: 1px solid ${colors.white};
      }
    }
  }
`;

interface BoxProps {
  color?: string;
}

export const Box = styled.div<BoxProps>`
  padding: 1.5rem;
  max-width: 450px;
  min-width: 300px;
  width: 100%;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 7px;
  height: max-content;

  display: flex;
  flex-direction: column;

  .information-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: ${({ color, theme }) => color ?? theme.greenDefault};

    margin-bottom: 25px;
  }

  .information-about {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;

    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    li {
      display: flex;
      align-items: flex-start;

      margin-bottom: 0.5rem;

      .ant-progress-inner {
        border-radius: 7px;
      }

      .ant-progress-bg {
        border-radius: 7px;
      }
      img,
      svg {
        width: 40px;
        height: 40px;

        min-width: 40px;
        max-width: 40px;

        min-height: 40px;
        max-height: 40px;

        margin-right: 16px;

        background: ${({ theme }) =>
          theme.darkMode ? colors.white : colors.ciano700};
        border-radius: 100%;
        padding: 0.5rem;
      }
      svg path {
        fill: ${({ theme }) => (theme.darkMode ? theme.background : '')};
      }

      p {
        font-style: normal;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 30px;

        color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
        text-align: left;
        margin-top: 0.2rem;

        .numberPercent {
          font-size: 20px;
          color: ${({ color, theme }) =>
            theme.darkMode ? colors.white : color ?? theme.greenDefault};
        }
      }
    }
  }

  ${(props) => props.theme.darkMode && darkModeBox}

  @media (max-width: 1240px) {
    max-width: 100%;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;

      li {
        & + li {
          margin-top: 0;
        }
      }
    }
  }
`;
