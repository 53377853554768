import { strToInt } from '@/utils/types';
import { DataWithCity, DataWithProfile } from 'big-data';
import { Goal } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

const goals: Record<Goal, string> = {
  visited: 'QTD_VISITAS',
  beneficiaries: 'QTD_BENEFICIARIOS'
};

function removeNumbers(arg: string) {
  return arg.replace(/\d+/g, '');
}

function cleanEmptyString<T = {}>(data: T[], field: keyof T) {
  return data.filter((d) => String(d[field]).trim() !== '');
}

const service = () => {
  const resource = `/visitas-domiciliares`;

  async function beneficiariesAmount(filter?: string) {
    const goalQuery = 'QTD_BENEFICIARIOS';
    const aggregations = ['MUNICIPIO', 'PERFIL'];

    const path = `${resource}?aggregations=${getQuery(
      aggregations
    )}&goal=${goalQuery}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return cleanEmptyString<DataWithProfile>(
      response.data.map((res) => ({
        profile: res.perfil ?? 'none',
        name: removeNumbers(res.municipio),
        value: res.quantidade
      })),
      'name'
    );
  }

  async function visitedAmount() {
    const goalQuery = 'QTD_VISITAS';
    const aggregations = ['MUNICIPIO', 'PERFIL'];

    const path = `${resource}?aggregations=${getQuery(
      aggregations
    )}&goal=${goalQuery}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map((res) => ({
      profile: res.perfil ?? 'none',
      name: removeNumbers(res.municipio),
      value: res.quantidade
    }));
  }

  async function getCities(goal: Goal, profile?: string) {
    const aggregations = ['MUNICIPIO', 'IBGE', profile ? 'PERFIL' : ''];
    const goalQuery = goals[goal];

    const path = `${resource}?aggregations=${getQuery(
      aggregations
    )}&goal=${goalQuery}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return cleanEmptyString(
      response.data.map<DataWithProfile>((d) => ({
        profile: d.perfil ?? 'none',
        value: d.quantidade,
        name: removeNumbers(String(d.municipio)),
        codIbge: strToInt(d.ibge)
      })),
      'name'
    );
  }

  async function getProfiles(goal: Goal, city?: string) {
    const aggregations = ['PERFIL', city ? 'MUNICIPIO' : undefined];
    const goalQuery = goal === 'visited' ? 'QTD_VISITAS' : 'QTD_BENEFICIARIOS';

    const path = `${resource}?aggregations=${getQuery(
      aggregations
    )}&goal=${goalQuery}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => d.perfil !== '-')
      .map<DataWithCity>((d) => ({
        value: d.quantidade,
        name: d.perfil,
        city: d.municipio
      }));
  }

  return {
    beneficiariesAmount,
    visitedAmount,
    getCities,
    getProfiles
  };
};

export const homeVisits = service();
