import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useAuth } from './useAuth';

type ActionContextProps = {
  checkAction: boolean;
  checkObservation: boolean;
  checkActionRegister: () => void;
  checkObservationRegister: () => void;
  onChangeCity: (value: string) => void;
  onChangeIndicator: (value: string) => void;
  onChangeActionTitle: (title: string) => void;
  onEraseFilter: () => void;
  addResetListener: (fn: ResetListener) => void;
  cleanResetListener: () => void;
  indicator: string;
  city: string;
  ibge: string;
  actionSearchTitle: string;
};

type ResetListener = () => void;

const ActionContext = createContext<ActionContextProps>(
  {} as ActionContextProps
);

interface ActionProviderProps {
  children: ReactNode;
}

export function ActionProvider({ children }: ActionProviderProps) {
  const [checkAction, setCheckAction] = useState(false);
  const [checkObservation, setCheckObservation] = useState(false);

  const [indicator, setIndicator] = useState('');
  const [city, setCity] = useState('');
  const [ibge, setIbge] = useState('');
  const [actionSearchTitle, setActionSearchTitle] = useState('');

  const [resetListeners, setResetListeners] = useState<ResetListener[]>([]);

  const { isAdm } = useAuth();

  const onChangeCity = useCallback((value: string) => {
    const [name, ibge] = value?.split('_') ?? [];
    setCity(name);
    setIbge(ibge);
  }, []);

  const onChangeIndicator = useCallback((value: string) => {
    setIndicator(value);
  }, []);

  const onChangeActionTitle = useCallback((title: string) => {
    setActionSearchTitle(title);
  }, []);

  const onEraseFilter = useCallback(() => {
    resetListeners.forEach((fn) => fn());

    if (isAdm) {
      setCity('');
      setIbge('');
    }
    setIndicator('');
    setActionSearchTitle('');
  }, [resetListeners, isAdm]);

  const checkActionRegister = useCallback(() => {
    setCheckAction(!checkAction);
  }, [checkAction]);

  const checkObservationRegister = useCallback(() => {
    setCheckObservation(!checkObservation);
  }, [checkObservation]);

  const addResetListener = useCallback((fn: ResetListener) => {
    setResetListeners((current) => [...current, fn]);
  }, []);

  const cleanResetListener = useCallback(() => setResetListeners([]), []);

  const providerValues = useMemo(
    () => ({
      checkAction,
      checkObservation,
      checkActionRegister,
      checkObservationRegister,
      indicator,
      city,
      ibge,
      onChangeCity,
      onChangeIndicator,
      actionSearchTitle,
      onChangeActionTitle,
      onEraseFilter,
      addResetListener,
      cleanResetListener
    }),
    [
      checkAction,
      checkObservation,
      checkActionRegister,
      checkObservationRegister,
      indicator,
      city,
      ibge,
      onChangeCity,
      onChangeIndicator,
      actionSearchTitle,
      onChangeActionTitle,
      onEraseFilter,
      addResetListener,
      cleanResetListener
    ]
  );

  return (
    <ActionContext.Provider value={providerValues}>
      {children}
    </ActionContext.Provider>
  );
}

export function useAction() {
  return useContext(ActionContext);
}
