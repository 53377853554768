export function TarifaAgua() {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2449_5461)">
        <path
          d="M43.0454 2.93476C42.235 2.93476 41.578 3.59174 41.578 4.40218V8.80436H32.5213C32.0784 7.55569 31.0875 6.56481 29.8389 6.12193V2.93476H34.241C35.0514 2.93476 35.7085 2.27777 35.7085 1.46733C35.7085 0.656895 35.0514 0 34.241 0C33.5551 0 17.0814 0 16.6323 0C15.822 0 15.1649 0.656982 15.1649 1.46742C15.1649 2.27786 15.8219 2.93484 16.6323 2.93484H21.0345V6.12202C19.7858 6.5649 18.795 7.55578 18.3521 8.80444H7.82796C5.4006 8.80444 3.42578 10.7793 3.42578 13.2066V22.011C3.42578 22.8213 4.08268 23.4784 4.8932 23.4784H10.7628C11.5732 23.4784 12.2302 22.8214 12.2302 22.011V17.6088H18.3522C18.9579 19.3168 20.589 20.5436 22.5019 20.5436H28.3715C30.2845 20.5436 31.9155 19.3167 32.5213 17.6088H41.5781V22.011C41.5781 22.8213 42.235 23.4784 43.0455 23.4784C43.8559 23.4784 44.5129 22.8214 44.5129 22.011C44.5129 21.3251 44.5129 4.85139 44.5129 4.40227C44.5127 3.59183 43.8557 2.93476 43.0454 2.93476ZM18.0997 14.674H10.7628C9.95245 14.674 9.29538 15.3309 9.29538 16.1414V20.5436H6.36054V13.2066C6.36054 12.3975 7.01884 11.7392 7.82796 11.7392H18.0997V14.674ZM23.9693 2.93476H26.904V5.86951H23.9693V2.93476ZM29.8389 16.1413C29.8389 16.9504 29.1806 17.6087 28.3714 17.6087H22.5018C21.6927 17.6087 21.0344 16.9504 21.0344 16.1413V10.2718C21.0344 9.46266 21.6926 8.80436 22.5018 8.80436H28.3714C29.1806 8.80436 29.8389 9.46266 29.8389 10.2718V16.1413ZM41.578 14.674H32.7736V11.7392H41.578V14.674Z"
          fill="#D42929"
        />
        <path
          d="M13.5663 33.092L8.97435 27.3325C8.3875 26.5963 7.26672 26.5961 6.67961 27.3325L2.08767 33.092C-1.71465 37.8608 1.67599 45 7.82694 45C13.9765 45 17.3696 37.8622 13.5663 33.092ZM7.82694 42.0653C4.1386 42.0653 2.10033 37.7837 4.38224 34.9216L7.82694 30.6012L11.2716 34.9216C13.5517 37.7813 11.5175 42.0653 7.82694 42.0653Z"
          fill="#D42929"
        />
      </g>
      <defs>
        <clipPath id="clip0_2449_5461">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
