import { loginCount, ManagerLoginProps } from '@/services';
import { useEffect, useState } from 'react';

export function useManagerLogin() {
  const [data, setData] = useState<ManagerLoginProps>();
  const [error, setError] = useState<Error | null>(null);

  async function onLogin() {
    try {
      const data = await loginCount.getLoginCount();
      setData(data);
    } catch (error: any) {
      setError(error);
    }
  }

  useEffect(() => {
    onLogin();
  }, []);

  return {
    data,
    error
  };
}
