import styled from 'styled-components';

export const Box = styled.section`

  .left-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 1240px) {
      grid-area: card;
    }
  }
`;
