const THEME_MODE_DARK = `@BIG_DATA_SOCIAL_THEME:dark`;
const THEME_FONT_LEVEL = `@BIG_DATA_SOCIAL_THEME:font-level`;

function storage() {
  function get<T>(key: string, defaultValue?: T) {
    const value = localStorage.getItem(key);

    return value !== null ? (JSON.parse(value) as T) : defaultValue;
  }

  function set<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function setDarkMode(mode: boolean) {
    set(THEME_MODE_DARK, mode);
  }

  function getDarkMode(defaultValue: boolean) {
    return get(THEME_MODE_DARK, defaultValue) as boolean;
  }

  function setFontLevel(fontLevel: number) {
    set(THEME_FONT_LEVEL, fontLevel);
  }

  function getFontLevel(defaultValue: number) {
    return get(THEME_FONT_LEVEL, defaultValue) as number;
  }

  return {
    setDarkMode,
    getDarkMode,
    setFontLevel,
    getFontLevel
  };
}

export const StyleStorage = storage();
