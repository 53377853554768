import { Pie as VisxPie } from '@visx/shape';
import { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { animated, useTransition, to } from 'react-spring';
import { Data } from 'big-data';

interface PieProps {
  data: Data[];
  radius: number;
  thickness: number;
  colorScale: (data: Data) => string;
  animate?: boolean;
  onClick?: (data: Data) => void;
}

export function Pie({
  data,
  radius,
  thickness,
  colorScale,
  animate = true,
  onClick
}: PieProps) {
  return (
    <VisxPie
      data={data}
      pieValue={(d) => d.value}
      outerRadius={radius}
      innerRadius={radius - thickness}
      cornerRadius={3}
      padAngle={0.005}
    >
      {(pie) =>
        animate && (
          <Group className="pie">
            <AnimatedPie<Data>
              {...pie}
              animate
              getKey={(arc) => arc.data.name}
              onClickDatum={({ data }) => {
                if (animate && onClick) {
                  onClick(data);
                }
              }}
              getColor={(arc) => colorScale(arc.data)}
            />
          </Group>
        )
      }
    </VisxPie>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        // compute interpolated path d attribute from intermediate angle values
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle,
            endAngle
          })
        )}
        fill={getColor(arc)}
        onClick={() => onClickDatum(arc)}
        onTouchStart={() => onClickDatum(arc)}
      />
    </g>
  ));
}
