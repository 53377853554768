import styled from 'styled-components';
import * as R from 'ramda';

type BoxProps = {
  minHeight?: number;
  minWidth?: number;
};

export const Box = styled.div<BoxProps>`
  position: relative;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 200)}px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 200)}px;
`;

interface ContentBoxProps {
  color?: string;
}

export const ContentBox = styled.div<ContentBoxProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: ${({ color }) => (R.isNil(color) ? 'inherit' : color)};

  text-align: center;

  p {
    margin: 0;
  }
`;
