import { useUserType } from '@/servicesHooks/googleAnalytics';
import styled, { useTheme } from 'styled-components';
import { DonutNoLegend, ServiceBox } from '@/components/shared';
import { colors } from '@/theme/colors';
import { sumDataTotal } from '@/utils/data';

import { CardBase, CardHeader, CardContent } from '../../components';
import { useFilterContext, useTabContext } from '../../contexts';
import { generateTag } from '../../utils/helper';

export function CardUserType() {
  const { darkMode } = useTheme();
  const { city } = useFilterContext();
  const { tab } = useTabContext();
  const deviceColors = darkMode
    ? [colors.yellow200, colors.yellow500, colors.yellow800]
    : [colors.ciano700, colors.ciano500, colors.blue400];

  const { data, error, loading } = useUserType(tab?.value, city?.ibge);

  const total = sumDataTotal(data);
  const dataColored = data.map((d, i) => {
    return {
      ...d,
      color: deviceColors[i] || '#000',
      percentage: (d.value / total) * 100
    };
  });

  const tag = generateTag([city?.name, tab?.name]);

  return (
    <Box>
      <CardHeader title="Tipos de usuários" subtitle={tag} />
      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent className="card-content">
          <DonutNoLegend data={dataColored} width={234} legend />
        </CardContent>
      </ServiceBox>
    </Box>
  );
}

const Box = styled(CardBase)`
  .card-content {
    flex: 1;
  }
`;
