import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar as AntdAvatar, Badge, Popover } from 'antd';
import { useTheme } from 'styled-components';
import { isNil } from 'ramda';

import { Notification } from 'big-data';
import { UserInfo } from 'acesso-cidadao-oauth2-lib';

import { capitalize } from '@/utils/string';
import { colors } from '@/theme/colors';
import { actionNotification } from '@/services';

import { InnerBox, Name, Title } from './styles';
import { Notifications } from '../Notifications';
import { NOTIFICATIONS } from '@/constants/notifications';

interface AvatarProps {
  client?: UserInfo;
  collapsed?: boolean;
}

export function Avatar({ client, collapsed }: AvatarProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);

  const { screen } = useTheme();

  const hasNotification = useMemo(
    () => notifications.some((notification) => !notification.isRead),
    [notifications]
  );

  const getNotifications = useCallback(() => {
    setLoading(true);
    actionNotification
      .getNotReadNotifications()
      .then(setNotifications)
      .finally(() => setLoading(false));
  }, []);

  const updateNotifications = useCallback(
    (page: number = 0, size: number = 10) => {
      setLoading(true);
      actionNotification
        .getNotReadNotifications(page, size)
        .then((notifications) => {
          setNotifications((current) => [...current, ...notifications]);
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const markAsRead = useCallback((ids: number[]) => {
    setNotifications((current) =>
      current.map((notification) => ({
        ...notification,
        isRead: ids.includes(notification.notificationId)
      }))
    );
  }, []);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_BIG_DATA_APP_KEY}_token`
    );

    const eventSource = new EventSource(
      `${process.env.REACT_APP_MAIN_API}/sse/notificacoes?token=${token}`
    );

    eventSource.addEventListener('message', (ev) => {
      const data = JSON.parse(ev.data);

      if (data.type === 'MESSAGE') {
        const notification = {
          ...data.content,
          message: NOTIFICATIONS[data.content.message],
          createdDate: new Date(data.content.createdDate)
        };

        setNotifications((current) => [notification, ...current]);
      }
    });

    eventSource.addEventListener('error', (ev) => {
      eventSource.close();
    });

    return () => {
      eventSource.close();
    };
  }, []);

  function hadleVisibleChange(visible: boolean) {
    if (notifications.every((notif) => notif.isRead) && !visible) {
      return getNotifications();
    }
  }

  if (isNil(client)) return null;

  const placement = screen.small ? 'top' : 'rightBottom';

  return (
    <Popover
      title={<Title>Notificações dos planos de ação</Title>}
      content={
        <Notifications
          notifications={notifications}
          loading={loading}
          updateNotifications={updateNotifications}
          markAsRead={markAsRead}
        />
      }
      placement={placement}
      trigger="click"
      destroyTooltipOnHide
      onVisibleChange={hadleVisibleChange}
    >
      <InnerBox $collapsed={collapsed}>
        <Badge dot={hasNotification}>
          <AntdAvatar
            src={client.image}
            style={{ backgroundColor: colors.green200 }}
            size="large"
          >
            {client.name[0].toUpperCase()}
          </AntdAvatar>
        </Badge>
        {!collapsed && <Name>{capitalize(client.name)}</Name>}
      </InnerBox>
    </Popover>
  );
}
