import { useEffect } from 'react';
import { useChart } from '../Provider';
import { Root } from '../Arcs';
import { getTooltip } from './tooltip';
import { useLocation } from 'react-router-dom';

export function Tooltip() {
  const { arcs } = useChart();
  const location = useLocation();

  useEffect(() => {
    if (!arcs) return;

    const tooltip = getTooltip();

    arcs
      .on('mouseenter', (event, node: Root) => {
        const tooltipValue = node.data?.tooltip;

        if (location.pathname === '/dashboard/eixos' && !tooltipValue)
          return null;

        tooltip.showTooltip(tooltipValue ?? 'Voltar para todos os eixos');
      })
      .on('mousemove', (event, node) => {
        tooltip.setPosition(event.clientX + 10, event.clientY + 60);
      })
      .on('mouseleave', (event, node) => {
        tooltip.hiddenTooltip();
      });

    return () => {
      tooltip.hiddenTooltip();
    };
  }, [arcs, location]);

  return null;
}
