export function BdsLgbtqiaplus() {
  return (
    <svg
      width="33"
      height="21"
      viewBox="0 0 33 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="lgbtqia-plus"
    >
      <path
        d="M0 0L11.2947 10.5L0 21V17.8694L8.19317 10.5L0 2.90206V0Z"
        fill="white"
      />
      <path d="M0 2.90206V17.8883L8.19317 10.5189L0 2.90206Z" fill="#EDE658" />
      <path
        d="M0 0H2.78385L14.0392 10.5L2.82412 21H0L11.2947 10.5L0 0Z"
        fill="#E97F86"
      />
      <path
        d="M2.77399 0H5.55784L16.8132 10.5L5.59811 21H2.77399L14.0687 10.5L2.77399 0Z"
        fill="#79B6E5"
      />
      <path
        d="M5.46839 0H8.25224L19.5076 10.5L8.29251 21H5.46839L16.7631 10.5L5.46839 0Z"
        fill="#6E513B"
      />
      <path d="M33 17.64V21H11.0961L14.636 17.64H33Z" fill="#883C81" />
      <path
        d="M33 14.3187L33 17.64L14.6154 17.64L17.9765 14.4708H17.9908L33 14.3187Z"
        fill="#095CA8"
      />
      <path
        d="M33 14.3187L17.9908 14.4708H17.9765L22.2314 10.5L33 10.4227L33 14.3187Z"
        fill="#58B057"
      />
      <path
        d="M22.1921 10.5L18.4534 7.10222H33L33 10.4227L22.1921 10.5Z"
        fill="#EFE755"
      />
      <path
        d="M33 3.62716L33 7.10222L18.4937 7.10224L14.7551 3.62716H33Z"
        fill="#E57542"
      />
      <path d="M33 0L33 3.62716H14.7551L11.0164 0H33Z" fill="#E0383A" />
      <path
        d="M8.19316 0H10.977L22.2314 10.5L11.0164 21H8.19316L19.4879 10.5L8.19316 0Z"
        fill="#221E1F"
      />
      <path
        d="M3.17044 12.6864C1.91497 12.6864 0.89396 11.7062 0.89396 10.5008C0.89396 9.29552 1.91497 8.31528 3.17044 8.31528C4.4259 8.31528 5.44691 9.29552 5.44691 10.5008C5.44691 11.7062 4.4259 12.6864 3.17044 12.6864ZM3.17044 8.74397C2.16195 8.74397 1.34138 9.53177 1.34138 10.5C1.34138 11.4682 2.16195 12.256 3.17044 12.256C4.17892 12.256 4.99949 11.4682 4.99949 10.5C4.99949 9.53177 4.17892 8.74397 3.17044 8.74397Z"
        fill="#883C81"
      />
    </svg>
  );
}
