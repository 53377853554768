import { useEffect, useState } from 'react';

export const useObserverVisibility = (
  ref: any,
  once = false,
  options?: IntersectionObserverInit
) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const element = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
      if (once) {
        observer.unobserve(element);
      }
    }, options);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [ref, once]); // eslint-disable-line

  return visible;
};
