import {
  Data,
  DataPercentageComputed,
  DataWithCity,
  DataWithTotal,
  RankingBindWithTotal,
  RankingModel
} from 'big-data';
import { maxData } from './math';

const programTypes = [
  {
    name: 'Atendimento individual',
    value: 0
  },
  {
    name: 'Acompanhamento familiar',
    value: 0
  },
  {
    name: 'Reunião dos grupos PAIF',
    value: 0
  },
  {
    name: 'SCFV',
    value: 0
  },
  {
    name: 'Atividades de Programas Específicos',
    value: 0
  },
  {
    name: 'Ações Comunitárias',
    value: 0
  }
];

const NO_CREAS = 'O município não possui CREAS';

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export function formatMax(datas: Data[]) {
  const m = maxData(datas);

  return { ...m, value: formatter.format(m.value) };
}

export function getAssistanceInfo(data: Data[]) {
  return programTypes.map((type) => {
    const value = data
      .filter((d) =>
        d.name.toLocaleLowerCase().includes(type.name.toLocaleLowerCase())
      )
      .reduce((acc, cur) => acc + cur.value, 0);

    return { ...type, value };
  });
}

export function getPercentualData(data: Record<string, DataWithCity>) {
  return Object.values(data)
    .map((d) => {
      const percent = d.percentual ?? 1;
      return {
        codIbge: d.codIbge,
        name: d.name,
        value: Number(((100 * percent) / d.value).toFixed(2)),
        total: d.value,
        percentual: percent,
        hasCreas: d.hasCreas
      };
    })
    .sort((a, b) => b.value - a.value);
}

export function filterAssistanceData(data: DataWithCity[], filter?: string) {
  const finalResult = data.reduce((acc, cur: any) => {
    const hasCreas = cur.creas === NO_CREAS;
    const filterData = filter
      ? cur[filter.toLocaleLowerCase()] === 'Não' ||
      cur[filter.toLocaleLowerCase()] === NO_CREAS
      : cur.cras === 'Não' &&
      (cur.creas === 'Não' || cur.creas === NO_CREAS);
    if (acc[cur.name]) {
      let part = acc[cur.name].percentual ?? cur.value;
      if (filterData) {
        part = part + cur.value;
      }
      return {
        ...acc,
        [cur.name]: {
          ...acc[cur.name],
          value: acc[cur.name].value + cur.value,
          percentual: part
        }
      };
    }
    return {
      ...acc,
      [cur.name]: {
        ...cur,
        percentual: filterData ? cur.value : 0,
        hasCreas: hasCreas
      }
    };
  }, {} as Record<string, DataWithCity>);
  return getPercentualData(finalResult);
}

export function filterInsecurityFoodData(
  data: DataWithCity[],
  filter?: string
) {
  function getFilter(classification?: string) {
    if (filter) return classification === filter;
    return classification !== 'Fora de insegurança alimentar';
  }

  const finalResult = data.reduce((acc, cur) => {
    if (acc[cur.name]) {
      let part = acc[cur.name].percentual ?? cur.value;
      if (getFilter(cur.classification)) {
        part = part + cur.value;
      }
      return {
        ...acc,
        [cur.name]: {
          ...acc[cur.name],
          value: acc[cur.name].value + cur.value,
          percentual: part
        }
      };
    }
    return {
      ...acc,
      [cur.name]: {
        ...cur,
        percentual: getFilter(cur.classification) ? cur.value : 0
      }
    };
  }, {} as Record<string, DataWithCity>);

  return getPercentualData(finalResult);
}

export function getDataWithPercentage(
  values: Data[]
): DataPercentageComputed[] {
  const total = values.reduce((acc, cur) => acc + cur.value, 0);

  return values
    .map((v) => ({
      ...v,
      percentageValue: (v.value / total) * 100
    }))
    .sort((a, b) => b.percentageValue - a.percentageValue);
}

export function getDashboardRanking(
  data: DataWithTotal[],
  rankingModel: Map<string, RankingModel>
): RankingBindWithTotal[] {
  return data
    .map((benefit) => {
      const { name } = benefit;

      const model = rankingModel.get(name?.toLowerCase());

      if (!model) {
        return null;
      }

      return {
        ...benefit,
        ...model
      };
    })
    .filter(Boolean) as RankingBindWithTotal[];
}

export function splitNames(data: Data[]) {
  let separatedData: Data[] = [];

  data.forEach((data) => {
    const names = data.name.split('; ');
    separatedData = [
      ...separatedData,
      ...names.map((name) => ({ name, value: data.value }))
    ];
  });

  return Object.entries(
    separatedData.reduce((acc, cur) => {
      const name = cur.name.toLowerCase();
      return {
        ...acc,
        [name]: (acc[name] ?? 0) + cur.value
      };
    }, {} as Record<string, number>)
  ).map(([key, value]) => ({ name: key.toLowerCase(), value }));
}

export function sumDataTotal(data: Data[]) {
  return data.reduce((acc, cur) => acc + cur.value, 0);
}
