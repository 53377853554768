import styled from 'styled-components';
import * as R from 'ramda';
import { colors } from '@/theme/colors';

interface BoxProps {
  selected?: boolean;
}

export const Box = styled.div<BoxProps>`
  transition: opacity 0.3s;

  opacity: ${({ selected }) => {
    if (R.isNil(selected)) return undefined;

    return selected ? 1 : 0.5;
  }};

  border: 1px solid ${colors.gray400};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  .goals-column-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid ${colors.gray400};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};

    position: sticky;
    top: 0;

    cursor: pointer;

    h2 {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }

  .goals-column-body {
    max-height: 500px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;

      &:hover {
        background-color: #555;
      }
    }

    .intersection {
      height: 30px;

      display: flex;
      justify-content: center;
    }
  }
`;

interface BallProps {
  color: string;
}

export const Ball = styled.div<BallProps>`
  background-color: ${({ color }) => color};

  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: 0.1rem;
`;
