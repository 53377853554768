interface Data {
  name: string;
  value: string;
}

export const category: Record<string, Data[]> = {
  health: [
    {
      name: 'GESTANTES',
      value: 'health'
    },
    {
      name: 'CRIANÇAS',
      value: 'healthChild'
    }
  ]
};

export const indicators: Record<string, Data[]> = {
  jobIncome: [
    {
      name: 'REPRESENTANTES FAMILIARES SEM TRABALHO REMUNERADO',
      value: 'ALGUEM_FAMILIA_TEM_TRAB_REMUNERADO'
    },
    {
      name: 'REPRESENTANTES FAMILIARES QUE POSSUEM ATÉ MEIO SALÁRIO MÍNIMO',
      value: 'RENDA_FAMILIA'
    },
    {
      name: 'REPRESENTANTES FAMILIARES QUE NÃO REALIZARAM CURSO DE CAPACITAÇÃO',
      value: 'ULTIMOS_12MESES_ALGUEM_FEZ_CURSO_QUALIFICACAO'
    },
    {
      name: 'REPRESENTANTES FAMILIARES CUJA PRINCIPAL FONTE DE RENDA É O BOLSA FAMÍLIA / CARTÃO MAIS INFÂNCIA',
      value: 'FONTES_RENDA'
    },
    {
      name: 'REPRESENTANTES FAMILIARES QUE TÊM INTERESSE EM REALIZAR CURSO DE CAPACITAÇÃO',
      value: 'GOSTARIA_FAZER_CURSO_QUALIFICACAO'
    },
    {
      name: 'POSSUEM 1 PESSOA TRABALHANDO NA RESIDÊNCIA',
      value: 'SE_SIM_QUANTOS_TRABALHAM'
    }
  ],
  health: [
    {
      name: 'GESTANTES QUE NÃO POSSUEM ACOMPANHAMENTO PRÉ-NATAL',
      value: 'FAZ_ACOMPANHAMENTO_PRE_NATAL'
    },
    {
      name: 'GESTANTES QUE NÃO POSSUEM CARDENETA DA GESTANTE',
      value: 'POSSUI_CADERNETE_GESTANTE'
    },
    {
      name: 'GESTANTES QUE RECEBERAM SUPLEMENTAÇÃO DE ÁCIDO FÓLICO E SULFATO FERROSO',
      value: 'RECEBEU_SUPLEMENTACAO_ACIDOFOLICO_SULFATOFERROSO'
    },
    {
      name: 'GESTANTES DO DOMICÍLIO',
      value: 'EXISTE_GRAVIDA_DOMICILIO'
    }
  ],
  healthChild: [
    {
      name: 'CRIANÇAS DE ATÉ 8 ANOS QUE NÃO SÃO ACOMPANHADAS POR EQUIPES DE SAÚDE DA FAMÍLIA',
      value: 'CRIANCA_ACOMPANHADA_EQUIPE_SAUDE_FAMILIA'
    },
    {
      name: 'CRIANÇAS DE ATÉ 8 ANOS QUE NÃO ESTÃO COM O ESQUEMA VACINAL EM DIA',
      value: 'CRIANCA_ESTA_VACINA_EM_DIA'
    },
    {
      name: 'CRIANÇAS DE ATÉ 8 ANOS COM ALGUMA DEFICIÊNCIA CONGÊNITA',
      value: 'CRIANCA_POSSUI_DEFICIENCIA'
    }
  ],
  livingConditions: [
    {
      name: 'Famílias localizadas na zona rural',
      value: 'ZONA_RURAL'
    },
    {
      name: 'Famílias localizadas na zona urbana',
      value: 'ZONA_URBANA'
    },
    {
      name: 'Famílias que não possuem água canalizada em pelo menos 1 cômodo',
      value: 'DOMICILIO_POSSUI_AGUA_CANALIZADA_PELOMENOS_UMCOMODO'
    },
    {
      name: 'Famílias que não possuem banheiro em suas residências',
      value: 'EXISTE_BANHEIRO'
    },
    {
      name: 'Famílias que não possuem acesso à coleta seletiva',
      value: 'QTS_VEZES_LIXO_PASSA_SEMANA'
    },
    {
      name: 'Famílias que não possuem lugares públicos para brincar próximo às residências',
      value: 'EXISTEM_LUGARES_PARA_CRIANCA_BRINCAR_PROXIMA_CASA'
    },
    {
      name: 'Famílias que não possuem atividades culturas próximo às residências',
      value: 'EXISTEM_ATIVIDADES_CULTURAIS_PARA_JOVENS_PROXIMO_CASA'
    },
    {
      name: 'Famílias que não possuem atividades esportivas próximo às residências',
      value: 'EXISTEM_LOCAIS_PARA_ATIVIDADES_ESPORTIVAS_PROXIMA_CASA'
    },
    {
      name: 'Famílias que estão localizadas em área de conflito/violência',
      value: 'DOMICILIO_LOCALIZADO_AREA_VIOLENCIA'
    }
  ]
};

export const situation: Record<string, Record<string, Data>> = {
  jobIncome: {
    ALGUEM_FAMILIA_TEM_TRAB_REMUNERADO: {
      name: 'hasPaidWork',
      value: 'Não'
    },
    RENDA_FAMILIA: {
      name: 'familyIncome',
      value: 'Até meio salário mínimo'
    },
    ULTIMOS_12MESES_ALGUEM_FEZ_CURSO_QUALIFICACAO: {
      name: 'hadGraduateLastTwelveMonths',
      value: 'Não'
    },
    FONTES_RENDA: {
      name: 'incomeSource',
      value: 'Bolsa família / Cartão Mais Infância'
    },
    GOSTARIA_FAZER_CURSO_QUALIFICACAO: {
      name: 'likeToGraduate',
      value: 'Sim'
    },
    SE_SIM_QUANTOS_TRABALHAM: {
      name: 'howMuchPeopleWork',
      value: '1'
    }
  },
  health: {
    FAZ_ACOMPANHAMENTO_PRE_NATAL: {
      name: 'hadPrenatalCare',
      value: 'Não'
    },
    POSSUI_CADERNETE_GESTANTE: {
      name: 'hasPregnantBook',
      value: 'Não'
    },
    RECEBEU_SUPLEMENTACAO_ACIDOFOLICO_SULFATOFERROSO: {
      name: 'hasPregnantSupplements',
      value: 'Sim'
    },
    EXISTE_GRAVIDA_DOMICILIO: {
      name: 'hasPregnant',
      value: 'Sim'
    }
  },
  healthChild: {
    CRIANCA_ACOMPANHADA_EQUIPE_SAUDE_FAMILIA: {
      name: 'childHasHealthAttended',
      value: 'Não'
    },
    CRIANCA_ESTA_VACINA_EM_DIA: {
      name: 'childVaccinesUpToDate',
      value: ' '
    },
    CRIANCA_POSSUI_DEFICIENCIA: {
      name: 'childHasDisability',
      value: ' '
    }
  },
  livingConditions: {
    ZONA_RURAL: {
      name: 'zone',
      value: 'Rural'
    },
    ZONA_URBANA: {
      name: 'zone',
      value: 'Urbana'
    },
    DOMICILIO_POSSUI_AGUA_CANALIZADA_PELOMENOS_UMCOMODO: {
      name: 'hasPipedWater',
      value: 'Não'
    },
    EXISTE_BANHEIRO: {
      name: 'hasBathroom',
      value: 'Não'
    },
    QTS_VEZES_LIXO_PASSA_SEMANA: {
      name: 'hasGarbageCollection',
      value: 'Nenhuma vez'
    },
    EXISTEM_LUGARES_PARA_CRIANCA_BRINCAR_PROXIMA_CASA: {
      name: 'hasPublicPlacesPlay',
      value: 'Não'
    },
    EXISTEM_ATIVIDADES_CULTURAIS_PARA_JOVENS_PROXIMO_CASA: {
      name: 'hasCulturalActivities',
      value: 'Não'
    },
    EXISTEM_LOCAIS_PARA_ATIVIDADES_ESPORTIVAS_PROXIMA_CASA: {
      name: 'hasSportingActivities',
      value: 'Não'
    },
    DOMICILIO_LOCALIZADO_AREA_VIOLENCIA: {
      name: 'existsConflictArea',
      value: 'Sim'
    }
  }
};
