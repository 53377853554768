import { useTemporalSession } from '@/servicesHooks/googleAnalytics';
import { Area, ServiceBox } from '@/components/shared';

import { CardBase, CardContent, CardHeader } from '../../components/';
import { useFilterContext, useTabContext } from '../../contexts';
import { generateTag } from '../../utils';

export function CardSessionsByCity() {
  const { tab } = useTabContext();

  const { city } = useFilterContext();
  const { data, error, loading } = useTemporalSession(tab?.value, city?.ibge);
  const tag = generateTag([city?.name, tab?.name]);
  const title = `Sessões ${
    !!city?.name ? `no municipio de ${city.name}` : 'no estado do Ceará'
  }`;

  return (
    <CardBase>
      <CardHeader title={title} subtitle={tag} />
      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent style={{ paddingLeft: '3rem' }}>
          <Area data={data} height={250} />
        </CardContent>
      </ServiceBox>
    </CardBase>
  );
}
