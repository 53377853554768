import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { capitalize } from '@/utils/string';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';

const COLUMNS = [
  {
    name: 'Nome da organização',
    key: 'name'
  }
];

export function Card1() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getNames(ibge));
  }, [request, ibge]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return { name: capitalize(d.name) };
    });
  }, [data]);

  return (
    <Card
      title="Lista de organizações de apoio a mulheres"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <ListTable
        data={formattedData}
        columnTitle={COLUMNS}
        pageSize={8}
        numberValues
      />
    </Card>
  );
}
