import { ILink } from '@/pages/links';

import { ExternalLink, InternalLink } from './styles';

type IItem = ILink & { isActive: boolean };

interface MenuItemProps {
  mobile?: boolean;
  item: IItem;
}

export function MenuItem({ item, mobile = false }: MenuItemProps) {
  const { path, name, linkType, isActive, termPage } = item;

  const itemColor = mobile ? `#000` : `#fff`;

  if (linkType === 'external' && !termPage) {
    return (
      <ExternalLink color={itemColor} href={path} isActive={isActive}>
        {name}
      </ExternalLink>
    );
  }

  if (linkType === 'same-page' && !termPage) {
    return (
      <ExternalLink color={itemColor} href={path} isActive={isActive}>
        {name}
      </ExternalLink>
    );
  }

  return (
    <InternalLink color={itemColor} to={path} isActive={isActive}>
      {name}
    </InternalLink>
  );
}
