import { Form, Input, Select, Upload, Button, Alert } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import * as R from 'ramda';
import dayjs from 'dayjs';

import { getSemester } from '@/utils/date';

import { TextArea } from '../styles';
import { Slider } from '../Slider';
import { FixedRadio } from '../FixedRadio';

import { Box } from './styles';
import { formRules } from './rules';
import { useIndicatorValue } from './useIndicatorValue';
import { LABEL_BY_VALUES } from './labelsByIndicator';
import { POLARITY_BY_INDICATOR } from './polarityByIndicator';

const { Option } = Select;
const { useForm } = Form;

interface SubmitData {
  title: string;
  indicator: string;
  polarity: number;
  file?: any;
  goal: string;
  time: string;
  description: string;
}

interface RegisterFormProps {
  axe: string;
  indicator: string;
  loading?: boolean;
  disabled?: boolean;
  onSubmit?: (fields: SubmitData) => void;
  handleClose?: () => void;
}

const polarityOptions = [
  { label: 'Melhora quando aumenta', value: 1 },
  { label: 'Melhora quando diminui', value: 0 }
];

const getSemesters = () => {
  const today = dayjs();
  const currentYear = today.get('year');
  const currentSemester = getSemester(today.toDate());
  const years = R.range(0, 4).map((value) => currentYear + value);

  const current = currentYear + currentSemester / 10;

  const getYears = R.pipe(
    R.concat(years),
    R.sort((a, b) => a - b),
    R.groupWith<number>(R.equals),
    R.map((value) => [value[0] + 0.1, value[1] + 0.2]),
    R.flatten,
    R.filter<number>((value) => value > current),
    R.map((value) => value.toString())
  );

  return getYears(years);
};

export function RegisterForm({
  axe,
  indicator,
  loading,
  disabled,
  onSubmit,
  handleClose
}: RegisterFormProps) {
  const [form] = useForm<SubmitData>();

  const { indicatorValue } = useIndicatorValue({ axe, indicator });

  const [isValidForm, setIsValidForm] = useState(false);

  const polarity = useMemo(() => POLARITY_BY_INDICATOR[indicator], [indicator]);

  const completedActionPlan = useMemo(() => {
    if (indicatorValue) {
      const { percentage } = indicatorValue;

      return polarity ? percentage === 100 : percentage === 0;
    }

    return false;
  }, [indicatorValue, polarity]);

  useEffect(() => {
    if (disabled) return;

    if (indicatorValue !== null) {
      const sliderRange = String(indicatorValue.percentage);
      form.setFieldsValue({ goal: sliderRange });
    }
  }, [polarity, indicatorValue, form, disabled]);

  function handleFormChange() {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length);

    const hasValues = Object.entries(form.getFieldsValue())
      .filter(([key, value]) => key !== 'file')
      .map(([_, value]) => value)
      .every((value) => value !== undefined);

    setIsValidForm(!hasError && hasValues);
  }

  function handleFinish(fields: SubmitData) {
    if (onSubmit) {
      onSubmit({ ...fields, polarity });
    }
  }

  function getTip(value: number, indicator: string) {
    return (
      <span>
        {`${LABEL_BY_VALUES[indicator]}:` ?? ''}{' '}
        <strong style={{ color: 'white' }}>{value}%</strong>
      </span>
    );
  }

  const semesters = getSemesters();

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      onFieldsChange={handleFormChange}
      layout="vertical"
      disabled={disabled || completedActionPlan}
    >
      {completedActionPlan && (
        <Alert
          type="warning"
          message={
            polarity
              ? `O indicador selecionado melhora quando aumenta e já encontra-se em 100%`
              : `O indicador selecionado melhora quando diminui e já encontra-se em 0%`
          }
        />
      )}

      <Box disabled={disabled}>
        <Form.Item
          rules={[formRules.title, formRules.minCharTitle]}
          name="title"
          label="Qual é o título da ação?"
        >
          <Input placeholder="Adicione o título da ação" />
        </Form.Item>

        <Form.Item label="Seu indicador...">
          <FixedRadio<number> options={polarityOptions} value={polarity} />
        </Form.Item>

        <Slider
          rules={[formRules.goal]}
          name="goal"
          label="Onde você deseja que seu indicador chegue?"
          dependencies={['indicator', 'polarity']}
          max={indicatorValue?.percentage ?? 100}
          decrease={polarity === 0}
          disabled={disabled}
          tipVisible={!!indicator && !disabled}
          tip={(value) => getTip(value, indicator)}
        />

        <Form.Item
          name="time"
          rules={[formRules.time]}
          label="Em qual semestre você pretende atingir essa meta?"
        >
          <Select placeholder="Selecione um semestre">
            {semesters.map((s) => {
              return (
                <Option key={s} value={s}>
                  {s}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          rules={[formRules.description]}
          label="Detalhe seu plano de ação:"
        >
          <TextArea
            placeholder="Insira uma descrição para o plano de ação"
            autoSize={{
              minRows: 4,
              maxRows: 6
            }}
          />
        </Form.Item>

        <Form.Item name="file" label="Anexar arquivos:">
          <Upload beforeUpload={() => false} maxCount={1}>
            <Button icon={<MdOutlineFileUpload />}>Selecionar arquivo</Button>
          </Upload>
        </Form.Item>
        <div className="buttons">
          <Form.Item className="submit-button">
            <button type="submit" disabled={disabled || !isValidForm}>
              <AiOutlineCheckCircle size={18} />
              <span>Criar plano de ação</span>
            </button>
          </Form.Item>

          <Form.Item className="cancel-button">
            <button onClick={handleClose}>
              <span>Cancelar</span>
            </button>
          </Form.Item>
        </div>
      </Box>
    </Form>
  );
}
