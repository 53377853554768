import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, LegendItem, Ball } from './styles';

type Item = {
  name: string;
  color: string;
  selected?: boolean;
  redirect?: string;
};
interface LegendProps {
  items: Item[];
  onClick?: (color?: string) => void;
}

const renderItem = (item: Item) => (
  <>
    <Ball className="ball" fill={item.color} />
    <span className="text">{item.name.toUpperCase()}</span>
  </>
);

export function Legend({ items, onClick }: LegendProps) {
  const boxRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        onClick?.();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box ref={boxRef}>
      {items.map((item) => (
        <LegendItem key={item.name} active={item.selected}>
          {!!item.redirect ? (
            <Link to={item.redirect} className="legend-button">
              {renderItem(item)}
            </Link>
          ) : (
            <button
              onClick={() => onClick?.(item.color)}
              className="legend-button"
            >
              {renderItem(item)}
            </button>
          )}
        </LegendItem>
      ))}
    </Box>
  );
}
