import { CityTaxes } from '@/services/googleAnaltyics';
import { meanTimePage } from '@/services/googleAnaltyics/meanTimePage';
import { useEffect, useState } from 'react';

export function useMeanTimePerPage(minimumDate?: string, ibge?: string) {
  const [data, setData] = useState<Pick<CityTaxes, 'meanTimePerPage'>[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    meanTimePage
      .get(minimumDate, ibge)
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [minimumDate, ibge]);

  return {
    data,
    loading,
    error
  };
}
