import { colors } from '@/theme/colors';

const colorNameMap = new Map([
  ['Sim', '#55A546'],
  ['Não', '#FFB02E'],
  ['Invalida', colors.gray500],
  ['Não informado', colors.gray500]
]);

export function resolveColor(name: string) {
  return colorNameMap.get(name) || '#D4282A';
}
