import { max } from '@/utils/math';
import { Geolocation } from '@/services';
import { AxeFunction, IndicatorOptions, ResponseMap } from '../@types';
import { Options } from './objects';

export function inject<T>(arr: T[], idx: number, value: T): T[] {
  const newArr = arr.slice();
  newArr[idx] = value;

  return newArr;
}

export function getID() {
  return '_' + Math.random().toString(36).substring(2, 9);
}

export function getMaxIndicatorOptionSize(
  options: Record<string, IndicatorOptions[]>
) {
  const lengths = Object.values(options).map((option) => option.length);

  return max(lengths);
}

export const isEducation = ((value: string) => {
  if (!isEducation.indicators) {
    isEducation.indicators = [
      Options.BABYSCHOOL,
      Options.CHILDSCHOOL,
      Options.SCHOOL
    ];
  }

  return isEducation.indicators.includes(value);
}) as AxeFunction;

export const isSocialAssistence = ((value: string) => {
  if (!isSocialAssistence.indicators) {
    isSocialAssistence.indicators = [
      Options.NOWATCHCRAS,
      Options.DONTKNOWCRAS,
      Options.CREAS,
      Options.NOWATCHCREAS,
      Options.DONTKNOWCREAS,
      Options.NOBUILDCREAS
    ];
  }

  return isSocialAssistence.indicators.includes(value);
}) as AxeFunction;

export const isInsecurityFood = ((value: string) => {
  if (!isInsecurityFood.indicators) {
    isInsecurityFood.indicators = [
      Options.NOIA,
      Options.IALIGHT,
      Options.IAMODERATE,
      Options.IASERIOUS
    ];
  }

  return isInsecurityFood.indicators.includes(value);
}) as AxeFunction;

export const isWork = ((value: string) => {
  if (!isWork.indicators) {
    isWork.indicators = [Options.NOWORK, Options.DONTKNOWORK];
  }

  return isWork.indicators.includes(value);
}) as AxeFunction;

export const isIncome = ((value: string) => {
  if (!isIncome.indicators) {
    isIncome.indicators = [
      Options.MORETHREEINCOME,
      Options.THREETWOINCOME,
      Options.TWOONEINCOME,
      Options.ONEINCOME,
      Options.HALFINCOME
    ];
  }

  return isIncome.indicators.includes(value);
}) as AxeFunction;

export const isHealth = ((value: string) => {
  if (!isHealth.indicators) {
    isHealth.indicators = [
      Options.NOVACCINE,
      Options.NOHEALTHATTENDED,
      Options.PRENATALPREGNANT
    ];
  }

  return isHealth.indicators.includes(value);
}) as AxeFunction;

export function getVisibilityRecord(
  response: ResponseMap<Geolocation[]>[],
  defaultValue: boolean = true
) {
  return response.reduce((acc, cur) => {
    const key = cur.filter;

    return { ...acc, [key]: defaultValue };
  }, {} as Record<string, boolean>);
}

export function getGeolocationMax(
  geolocationData: Record<string, Geolocation[]>
) {
  return Object.entries(geolocationData)
    .map(([key, data]) => [key, data.map((d) => d.heat_level)])
    .reduce((acc, cur) => {
      const [key, heatLevel] = cur as [string, number[]];

      return { ...acc, [key]: max(heatLevel) };
    }, {} as Record<string, number>);
}
