import { BarGroup, CardTagged, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useQualificationCoursesData } from '@/servicesHooks/jobIncome';
import { colors } from '@/theme/colors';

const COLORS = [colors.green200, colors.red500, colors.orange500];
const LEGEND = ['Sim', 'Não', 'Não sabe'];

export function CardBarGroup() {
  const { tag } = useDashboardFilter();
  const { data, ...rest } = useQualificationCoursesData();

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Entre as famílias entrevistadas, qual a porcentagem de pessoas que
          fizeram um curso de qualificação no último ano? Qual a porcentagem de
          interessados?
        </TitleText>

        <BarGroup colors={COLORS} legend={LEGEND} data={data} />
      </ServiceBox>
    </CardTagged>
  );
}
