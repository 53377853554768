import { Outlet } from 'react-router-dom';
import { Dashboard } from '@/layout';
import { BigDataPrivateRoute, BigDataRoute } from '@/types/routes';

import {
  BdsAtom,
  BdsAction,
  BdsCearaMap,
  BdsCzrm,
  BdsEducation,
  BdsFood,
  BdsHands,
  BdsHealth,
  BdsHome,
  BdsJobIncome,
  // BdsFeedbacks,
  BdsAnalyticsPanel
} from '@/bdsIcons';

import {
  DashboardHome,
  InsecurityFood,
  SocialAssistance,
  Education,
  LivingConditions,
  JobIncome,
  // PlatformFeedbacksPrivate,
} from './Dashboard';

import { AnalyticsPanelHome, AnalyticsPanelUser } from './AnalyticsPanel';

import {
  Home as ActionsHome,
  Indicator as ActionIndicator,
  Forum as ActionForum
} from './Actions';

import { IndicatorsMap } from './IndicatorsMap';

import { Health } from './Dashboard/Health';
import { UnifiedList } from './Actions/UnifiedList';

export function findNameByPath(path: string) {
  return (
    routesDashboard[0].children?.find((child) => {
      return child.path?.includes(path);
    })?.name ?? ''
  );
}

export function findNamByPath2(path: string) {
  function find(path: string, routes?: BigDataRoute[]): any {
    if (!routes) return null;
    routes.forEach((route) => {
      if (route.path === path) return route.name;
      find(path, route.children);
    });
  }

  find(path, routesDashboard);
}

const TERMOMETHER = 'Termômetro';

export const routesDashboard: BigDataPrivateRoute[] = [
  {
    path: `/dashboard`,
    Element: () => <Dashboard />,
    name: TERMOMETHER,
    iconImg: (props) => <BdsAtom {...props} />,
    children: [
      {
        Element: () => <Outlet />,
        name: TERMOMETHER,
        iconImg: (props) => <BdsAtom {...props} />,
        path: 'eixos',
        children: [
          {
            iconImg: (props) => <BdsAtom {...props} />,
            name: TERMOMETHER,
            Element: () => <DashboardHome />
          },
          {
            path: 'assistencia-social',
            Element: () => <SocialAssistance />,
            name: 'Assistência Social',
            iconImg: (props) => <BdsHands {...props} />
          },
          {
            path: 'educacao',
            Element: () => <Education />,
            name: 'Educação',
            iconImg: (props) => <BdsEducation {...props} />
          },
          {
            path: 'inseguranca-alimentar',
            Element: () => <InsecurityFood />,
            name: 'Insegurança Alimentar',
            iconImg: (props) => <BdsFood {...props} />
          },
          {
            path: 'condicoes-de-moradia',
            Element: () => <LivingConditions />,
            name: 'Condições Moradia',
            iconImg: (props) => <BdsHome {...props} />
          },
          {
            path: 'saude',
            Element: () => <Health />,
            name: 'Saúde',
            iconImg: (props) => <BdsHealth {...props} />
          },
          {
            path: 'trabalho-e-renda',
            Element: () => <JobIncome />,
            name: 'Trabalho e Renda',
            iconImg: (props) => <BdsJobIncome {...props} />
          }
        ]
      },
      {
        path: 'gestores-acoes',
        Element: () => <Outlet />,
        name: 'Planos de Ação',
        iconImg: (props) => <BdsAction {...props} />,
        noDropdown: true,
        children: [
          {
            Element: () => <ActionsHome />,
            name: 'Planos de Ação',
            iconImg: () => null
          },
          {
            path: 'planos-de-acao',
            Element: () => <Outlet />,
            name: 'Plano de Ação dos Gestores',
            iconImg: (props) => <BdsAction {...props} />,
            hiddenAtBreadcrumb: true,
            children: [
              {
                name: 'Plano de Ação',
                Element: () => <ActionIndicator />,
                iconImg: (props) => null
              },
              {
                path: 'lista-de-planos-unificada',
                name: 'Lista de Planos de Ação',
                Element: () => <UnifiedList />,
                iconImg: () => null
              },
              {
                path: ':forumId',
                name: 'Forum',
                Element: () => <ActionForum />,
                iconImg: (props) => null
              }
            ]
          }
        ]
      },
      {
        path: 'mapa',
        Element: () => <IndicatorsMap />,
        name: 'Mapa',
        iconImg: (props) => <BdsCearaMap {...props} />
      },
      {
        link: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
        name: 'Integra Social',
        iconImg: (props) => <BdsCzrm {...props} />,
        Element: () => <></>
      },
      // {
      //   name: 'Feedbacks',
      //   path: 'feedbacks',
      //   iconImg: (props) => <BdsFeedbacks {...props} />,
      //   Element: () => <PlatformFeedbacksPrivate />,
      //   roles: [`${process.env.REACT_APP_ROLE_PREFIX}ADMIN`]
      // },
      {
        name: 'Dados de Acesso',
        path: 'dados-de-acesso',
        iconImg: (props) => <BdsAnalyticsPanel {...props} />,
        Element: () => <Outlet />,
        noDropdown: true,
        children: [
          {
            name: 'Dados de Acesso',
            Element: () => <AnalyticsPanelHome />,
            iconImg: () => null,
            path: ''
          },
          {
            name: 'Dados de Acesso de Usuário',
            Element: () => <AnalyticsPanelUser />,
            iconImg: () => null,
            path: 'usuario'
          }
        ]
      }
    ]
  }
];
