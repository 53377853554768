import styled from 'styled-components';

export const Box = styled.div`
  .header {
    width: 100%;
    position: sticky;
    inset: 0;
    z-index: 998;
  }
`;
