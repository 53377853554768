import { colors } from '@/theme/colors';
import { fontFamily } from '@/theme/fontsFamily';
import styled from 'styled-components';

export const Box = styled.div`
  height: fit-content;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: flex-end;
  min-height: 65px;
  height: 100%;

  justify-content: end;

  position: relative;

  .city-selected-value {
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

export const SvgBox = styled.svg`
  width: 100% !important;
  .axis {
    &.x-axis {
      .domain {
        stroke-width: 0.5;
      }
    }

    &.y-axis {
      .domain {
        stroke: ${(props) => (props.theme.darkMode ? `#fff` : `#4c5469`)};
        stroke-width: 0.5;
      }

      .tick line {
        stroke: #4c5469;
      }
    }

    .tick {
      .x-grid {
        stroke-linecap: square;
        stroke-dasharray: 10;
        stroke: ${(props) => (props.theme.darkMode ? `#FFF` : `#4c5469bb`)};
        stroke-width: 0.5;
      }
    }
  }

  .text-tooltip {
    font-weight: 700;
    font-size: 11px;
    background: white;
    padding: 2px;
    width: fit-content;
    border-radius: 3px;
    color: ${colors.green700};
    -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.39);
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.39);
  }

  text.axis-text {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    color: ${(props) => props.theme.text};
    ${fontFamily.IBMSans}
  }

  .axis-text {
    transition: width 1s;

    &.init {
      width: 0;
    }
  }
`;
