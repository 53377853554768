import { ensureAxiosParamOptions } from '@/utils/routes';
import { DashboardRequestParams } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

interface JobIncomeParams extends DashboardRequestParams {
  aggregations: string[];
}

const service = () => {
  const resource = `/trabalho-renda`;

  async function request(path: string, params: JobIncomeParams) {
    const { aggregations, ...rest } = params;

    const options = ensureAxiosParamOptions({
      params: {
        ...rest,
        aggregations: getQuery(aggregations)
      }
    });

    return await callService(() => mainApi.get<any[]>(path, options));
  }

  async function getNoJob({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['ALGUEM_FAMILIA_TEM_TRAB_REMUNERADO'];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade,
      type: 'Trabalho'
    }));
  }

  async function getMapData({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = [
      'IBGE',
      'MUNICIPIO',
      'ALGUEM_FAMILIA_TEM_TRAB_REMUNERADO'
    ];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      city: data.municipio,
      value: data.quantidade,
      option: data[aggregations[2].toLowerCase()]
    }));
  }

  async function getPaidJobActionRegistration({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = [
      'IBGE',
      'MUNICIPIO',
      'ALGUEM_FAMILIA_TEM_TRAB_REMUNERADO'
    ];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[2].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getWorkersByAmount({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['SE_SIM_QUANTOS_TRABALHAM'];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade
      }))
      .filter((data) => data.name !== 'null');
  }

  async function getSourceOfIncome({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {

    const path = '/fontes-renda'

    const response = await callService(() => mainApi.get<any[]>(path, ensureAxiosParamOptions({
      params: {
        ibge,
        year,
        semester,
        zone
      }
    })))

    const data = await response.data;

    return data.map((d) => ({
      name: d.fontes_renda.toLowerCase(),
      value: d.quantidade,
      total: d.quantidade_registros
    }));
  }

  async function getWouldDoQualificationCourses({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['GOSTARIA_FAZER_CURSO_QUALIFICACAO'];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getQualificationCoursesInTheLast12Months({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['ULTIMOS_12MESES_ALGUEM_FEZ_CURSO_QUALIFICACAO'];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getIncome({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['RENDA_FAMILIA'];

    const response = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade,
        type: 'Renda'
      }))
      .filter((data) => data.name !== 'null');
  }

  return {
    getMapData,
    getWorkersByAmount,
    getSourceOfIncome,
    getWouldDoQualificationCourses,
    getQualificationCoursesInTheLast12Months,
    getIncome,
    getNoJob,
    getPaidJobActionRegistration
  };
};

export const jobIncome = service();
