import {
  isEducation,
  isIncome,
  isInsecurityFood,
  isSocialAssistence,
  isWork
} from '../../utils/functions';

type Legend = {
  color: string;
  label: string;
};

const LEGENDS: Record<string, Legend> = {
  'insecurity-food': { color: '#fbc34a', label: 'Insegurança Alimentar' },
  'social-assistance': { color: '#0ba2d3', label: 'Assistência Social' },
  education: { color: '#cc37e4', label: 'Educação' },
  work: { color: '#324BE1', label: 'Trabalho' },
  income: { color: '#324BE1', label: 'Renda' },
  health: { color: '#57AC4B', label: 'Saúde' }
};

export function getLegends(filters: string[]) {
  return Array.from(
    new Set(
      filters.map((filter) => {
        if (isInsecurityFood(filter)) return 'insecurity-food';
        if (isSocialAssistence(filter)) return 'social-assistance';
        if (isEducation(filter)) return 'education';
        if (isWork(filter)) return 'work';
        if (isIncome(filter)) return 'income';

        return 'health';
      })
    )
  ).map((axe) => LEGENDS[axe]);
}
