import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: calc(100% / 2);

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .icons {
    display: flex;

    .icon {
      display: block;
      height: 60px;
      margin: 5px 10px 0 0;
      fill: #bababa;
      stroke: #bababa;
      circle {
        fill: none;
        stroke-width: 10px;
      }
      &.selected {
        background-color: #3f31;
        border-radius: 50%;
        circle,
        path {
          stroke: ${colors.green200};
        }
      }
    }
  }

  p {
    width: 250px;
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#555`)};

    ${mediaQuery.smallScreen} {
      text-align: center;
    }

    .percentage {
      font-size: 1.4rem;
      font-weight: 700;
      display: block;
    }
  }
`;
