import { LoginButton } from '@/components/shared';
import { ILink } from '@/pages/links';

import { MenuItem } from '..';
import { NavBar, Li, Ul } from './styles';

type IItem = ILink & { isActive: boolean };

interface DesktopMenuProps {
  items: IItem[];
}

export function DesktopMenu({ items }: DesktopMenuProps) {
  return (
    <NavBar>
      <Ul>
        {items.map((item) => (
          <Li key={item.name}>
            <MenuItem item={item} />
          </Li>
        ))}

        <Li>
          <LoginButton />
        </Li>
      </Ul>
    </NavBar>
  );
}
