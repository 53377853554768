import { IndicatorLabel, IndicatorOptions } from '../@types';

export enum Options {
  BABYSCHOOL = 'CRECHE',
  CHILDSCHOOL = 'PRE_ESCOLA',
  SCHOOL = 'ESCOLA',

  CRAS = 'CRAS',
  CREAS = 'CREAS',
  NOWATCHCRAS = 'NO_WATCH_CRAS',
  DONTKNOWCRAS = 'DONT_KNOW_CRAS',
  NOWATCHCREAS = 'NO_WATCH_CREAS',
  DONTKNOWCREAS = 'DONT_KNOW_CREAS',
  NOBUILDCREAS = 'NO_BUILD_CREAS',

  NOIA = 'FORA_DE_INSEGURANCA_ALIMENTAR',
  IALIGHT = 'INSEGURANCA_ALIMENTAR_LEVE',
  IAMODERATE = 'INSEGURANCA_ALIMENTAR_MODERADA',
  IASERIOUS = 'INSEGURANCA_ALIMENTAR_GRAVE',

  NOWORK = 'NO_WORK',
  DONTKNOWORK = 'DONT_KNOW_WORK',

  MORETHREEINCOME = 'MORE_THREE_INCOME',
  THREETWOINCOME = 'TWO_THREE_INCOME',
  TWOONEINCOME = 'ONE_TWO_INCOME',
  ONEINCOME = 'ONE_INCOME',
  HALFINCOME = 'HALF_INCOME',

  NOVACCINE = 'NO_VACCINE_CARD',
  NOHEALTHATTENDED = 'NO_HEALTH_ATTENDED',
  PRENATALPREGNANT = 'PRE_NATAL_PREGNANT'
}

export const AXE_OPTIONS = [
  { label: 'Insegurança Alimentar', value: 'Insegurança Alimentar' },
  { label: 'Educação', value: 'Educação' },
  { label: 'Assistência Social', value: 'Assistência Social' },
  { label: 'Trabalho', value: 'Trabalho' },
  { label: 'Renda', value: 'Renda' },
  { label: 'Saúde', value: 'Saúde' }
];

const SOCIAL_ASSISTANCE_KEYS = [
  'Não assistido pelo CRAS',
  'Não sabe se tem CRAS',
  'Não assistido pelo CREAS',
  'Não sabe se tem CREAS',
  'O município não possui CREAS'
];

const INSECURITY_FOOD_KEYS = [
  'Fora de Insegurança Alimentar',
  'Insegurança Alimentar Leve',
  'Insegurança Alimentar Moderada',
  'Insegurança Alimentar Grave'
];

const JOB_KEYS = [
  'Não tem trabalho remunerado',
  'Não sabe se alguém tem trabalho remunerado'
];

const INCOME_KEYS = [
  'Acima de três salários',
  'Entre dois e três salários',
  'Entre um e dois salários',
  'Entre meio e um salário',
  'Até meio salário mínimo'
];

const HEALTH_KEYS = [
  'Crianças com calendário vacinal incompleto',
  'Crianças sem acompanhamento da equipe de saúde',
  'Grávidas sem acompanhamento pré natal'
];

function getEqualLabelsAndValues(keys: string[]) {
  return keys.map((key) => ({ value: key, label: key }));
}

export const INDICATORS_OPTIONS: Record<string, IndicatorOptions[]> = {
  Educação: [
    { label: 'Não tem acesso a creche', value: 'Creche' },
    { label: 'Não tem acesso a pré-escola', value: 'Pré-escola' },
    { label: 'Não tem acesso a escola pública', value: 'Escola' }
  ],
  'Assistência Social': getEqualLabelsAndValues(SOCIAL_ASSISTANCE_KEYS),
  'Insegurança Alimentar': getEqualLabelsAndValues(INSECURITY_FOOD_KEYS),
  Trabalho: getEqualLabelsAndValues(JOB_KEYS),
  Renda: getEqualLabelsAndValues(INCOME_KEYS),
  Saúde: getEqualLabelsAndValues(HEALTH_KEYS)
};

export const INDICATORS_LABELS: Record<string, IndicatorLabel> = {
  [Options.NOWATCHCRAS]: {
    initial: 'Não CRAS',
    label: 'Não assistido pelo CRAS'
  },
  [Options.DONTKNOWCRAS]: {
    initial: 'Não sabe CRAS',
    label: 'Não sabe se tem CRAS'
  },
  [Options.NOWATCHCREAS]: {
    initial: 'Não CREAS',
    label: 'Não assistido pelo CREAS'
  },
  [Options.DONTKNOWCREAS]: {
    initial: 'Não sabe CREAS',
    label: 'Não sabe se tem CREAS'
  },
  [Options.NOBUILDCREAS]: {
    initial: 'Não tem CREAS',
    label: 'O município não possui CREAS'
  },

  [Options.BABYSCHOOL]: { initial: 'Creche', label: 'Não tem acesso a creche' },
  [Options.CHILDSCHOOL]: {
    initial: 'Pré-Escola',
    label: 'Não tem acesso a pré-Escola'
  },
  [Options.SCHOOL]: { initial: 'Escola', label: 'Não tem acesso a escola' },

  [Options.NOIA]: { initial: 'FIA', label: 'Fora de Insegurança Alimentar' },
  [Options.IALIGHT]: { initial: 'IAL', label: 'Insegurança Alimentar Leve' },
  [Options.IAMODERATE]: {
    initial: 'IAM',
    label: 'Insegurança Alimentar Moderada'
  },
  [Options.IASERIOUS]: { initial: 'IAG', label: 'Insegurança Alimentar Grave' },

  [Options.NOWORK]: { initial: 'Não tem trabalho', label: 'Não tem trabalho' },
  [Options.DONTKNOWORK]: {
    initial: 'Não sabe se alguém tem trabalho',
    label: 'Não sabe se alguém tem trabalho'
  },

  [Options.MORETHREEINCOME]: {
    initial: '+3 salários',
    label: 'Acima de 3 salários mínimos'
  },
  [Options.THREETWOINCOME]: {
    initial: '2 e 3 salários',
    label: 'Entre dois e três salários'
  },
  [Options.TWOONEINCOME]: {
    initial: '1 e 2 salários',
    label: 'Entre um e dois salários'
  },
  [Options.ONEINCOME]: {
    initial: '0,5 e 1 salário',
    label: 'Entre meio e um salário'
  },
  [Options.HALFINCOME]: {
    initial: '0,5 salário',
    label: 'Até meio salário mínimo'
  },

  [Options.NOVACCINE]: {
    initial: 'Não vacinado',
    label: 'Crianças com calendário vacinal incompleto'
  },
  [Options.NOHEALTHATTENDED]: {
    initial: 'Não acompanhamento',
    label: 'Crianças sem acompanhamento da equipe de saúde'
  },
  [Options.PRENATALPREGNANT]: {
    initial: 'Grávida pré natal',
    label: 'Grávidas sem acompanhamento pré natal'
  }
};

export const HEAT_COLORS: Record<number, string> = {
  0.4: '#418CFB',
  0.54: '#01FE01',
  0.75: '#FFB201',
  0.85: '#D30001',
  1.0: '#B241D7'
};
