import { useState } from 'react';

import { CzrmCard, CzRMTableModal, FilterDashboard } from '@/components/shared';
import { AxeDescriptionText } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';

import { JobIncomeInsights } from '../components';

import { MainCards } from '../style';
import { Box } from './styles';

const description =
  'Garantir políticas de renda voltadas predominantemente para a população em idade ativa, assim como as iniciativas de inserção no trabalho para este grupo, que vêm sendo progressivamente aprimoradas e integradas, tendo em vista o objetivo do combate à pobreza, de promoção do emprego e de dinamização do mercado de trabalho. ';

export function JobIncome() {
  useDashboardTitle('Pesquisa dos Agentes Sociais sobre Trabalho e Renda');
  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleOpenCzRMModal() {
    setIsModalOpen(true);
  }
  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>
      <FilterDashboard />
      <MainCards>
        <CzrmCard
          color="#1D696F"
          title="das famílias em "
          name="trabalho e renda"
          onClick={handleOpenCzRMModal}
        />
      </MainCards>
      <JobIncomeInsights />

      <CzRMTableModal
        hasIndicators
        axe="jobIncome"
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={`Trabalho e Renda`}
      />
    </Box>
  );
}
