import { colors } from '@/theme/colors';

export interface LoadingTree {
  value: number;
  color: string;
  children: LoadingTree[];
}

export const loadingTree: LoadingTree = {
  value: 0,
  color: '#fff',
  children: [
    {
      value: 25,
      color: colors.yellow300,
      children: []
    },
    {
      value: 25,
      color: colors.green200,
      children: []
    },
    {
      value: 25,
      color: colors.ciano700,
      children: []
    },
    {
      value: 25,
      color: colors.red200,
      children: []
    }
  ]
};
