import { Loading } from '@/components/shared';
import { colors } from '@/theme/colors';
import { dotsNumber } from '@/utils/string';
import { AiOutlineLineChart } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { IconType } from 'react-icons/lib';
import { Box } from './styles';

interface CountLabelProps {
  value: number;
  loading: boolean;
  error?: any;
  size?: number;
  icon?: IconType;
  label?: string;
  isFiltring?: boolean;
}

export function CountLabel({
  value,
  loading,
  error,
  size,
  icon,
  label,
  isFiltring = false
}: CountLabelProps) {
  const { darkMode } = useTheme();
  const Icon = icon ?? AiOutlineLineChart;
  const iconColor = darkMode ? colors.white : colors.ciano700;

  function renderContent() {
    if (!!!error) return `${dotsNumber(value)} ${label ?? 'pessoas'}`;
    return error;
  }

  return (
    <Box size={size}>
      <Icon size={size ?? 50} color={iconColor} />

      <span>
        {loading ? <Loading size={size ?? size} /> : renderContent()}
        {isFiltring && !loading && !error ? (
          <i> (de acordo com o filtro aplicado)</i>
        ) : null}
      </span>
    </Box>
  );
}
