import { useDashboardFilter } from '@/hooks';
import { cmic } from '@/services/cmic';
import { useEffect, useMemo, useState } from 'react';

export function useTotalFamilies(hasFilter = true) {
  const [totalFamilies, setTotalFamilies] = useState(0);
  const [loadingTotalFamilies, setLoadingTotalFamilies] = useState(false);
  const [errorTotalFamilies, setErrorTotalFamilies] = useState<Error | null>(
    null
  );

  const { ibge } = useDashboardFilter();

  useEffect(() => {
    setLoadingTotalFamilies(true);
    if (hasFilter) {
      cmic
        .amount(ibge)
        .then((total) => setTotalFamilies(total))
        .catch((err) => setErrorTotalFamilies(err))
        .finally(() => setLoadingTotalFamilies(false));
    } else {
      cmic
        .amount()
        .then((total) => setTotalFamilies(total))
        .catch((err) => setErrorTotalFamilies(err))
        .finally(() => setLoadingTotalFamilies(false));
    }
  }, [ibge, hasFilter]);

  return useMemo(
    () => ({
      totalFamilies,
      loadingTotalFamilies,
      errorTotalFamilies
    }),
    [totalFamilies, loadingTotalFamilies, errorTotalFamilies]
  );
}
