import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface FilterContextProviderProps {
  children: ReactNode;
}

interface FilterContextData {
  city?: string;
  ibge?: string;
  orientation?: string;
  identity?: string;
  handleChangeOrientation(orientation?: string): void;
  handleChangeCity(city?: string): void;
  handleChangeIdentity(identity?: string): void;
  onClear(): void;
}

const FilterContext = createContext({} as FilterContextData);

export function FilterContextProvider({
  children
}: FilterContextProviderProps) {
  const [city, setCity] = useState<string | undefined>(undefined);
  const [ibge, setIbge] = useState<string | undefined>(undefined);
  const [orientation, setOrientation] = useState<string | undefined>(undefined);
  const [identity, setIdentity] = useState<string | undefined>(undefined);

  function handleChangeIdentity(i?: string) {
    setIdentity(i);
  }

  function handleChangeOrientation(o?: string) {
    setOrientation(o);
  }

  function handleChangeCity(city?: string) {
    if (city) {
      const c = city.split('_');
      setCity(c[0]);
      setIbge(c[1]);
    } else {
      setCity(undefined);
      setIbge(undefined);
    }
  }

  function onClear() {
    setCity(undefined);
    setOrientation(undefined);
    setIbge(undefined);
    setIdentity(undefined);
  }

  const contextValues = useMemo(
    () => ({
      city,
      ibge,
      orientation,
      identity,
      handleChangeOrientation,
      handleChangeCity,
      handleChangeIdentity,
      onClear
    }),
    [city, ibge, orientation, identity]
  );

  return (
    <FilterContext.Provider value={contextValues}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  return useContext(FilterContext);
}
