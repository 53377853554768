interface BdsHandsProps {
  width?: number;
  height?: number;
  color?: string;
}

export function BdsHands({ width = 24, height = 24, color }: BdsHandsProps) {
  const selectedColor = color ?? '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        fill={selectedColor}
        d="M10.368 2.488a6.673 6.673 0 0 0-7.72 4.794 6.636 6.636 0 0 0-.009 3.434 2.33 2.33 0 0 1 3.775 1.166 2.324 2.324 0 0 1 2.65 2.128 2.319 2.319 0 0 1 2.149 2.143 2.318 2.318 0 0 1 1.477 3.953l-.378.376 1.11 1.049a.782.782 0 0 0 1.32-.516.79.79 0 0 0-.153-.527l-.061-.06a.807.807 0 0 1-.076-.088l-.162-.164a.806.806 0 0 1 .566-1.374.8.8 0 0 1 .567.235l.225.225c.048.048.093.097.135.148l.12.122a.759.759 0 0 0 1.077 0 .766.766 0 0 0 0-1.081l-.241-.241a.804.804 0 0 1-.235-.59.803.803 0 0 1 1.369-.572l.182.183a.842.842 0 0 0 1.44-.598.85.85 0 0 0-.247-.599l-.04-.042c-.05-.043-.099-.089-.146-.136l-1.245-1.25a.807.807 0 0 1 .567-1.373.8.8 0 0 1 .567.235l1.17 1.173a.822.822 0 0 1 .079.07l.096.097a1.295 1.295 0 0 0 1.735-.09 1.306 1.306 0 0 0 0-1.843l-1.186-1.19a.795.795 0 0 1-.046-.044L15.99 6.754h-1.23L12.01 8.846a3.029 3.029 0 0 1-3.67-4.817l2.028-1.543v.002zm5.96 2.659a.803.803 0 0 1 .573.241l4.332 4.402a5.067 5.067 0 0 0-2.434-6.902 3.511 3.511 0 0 0-1.763-.466h-3.937a.802.802 0 0 1-.078.066l-3.707 2.82a1.417 1.417 0 0 0-.27 1.989 1.424 1.424 0 0 0 1.994.27l2.966-2.256a.803.803 0 0 1 .485-.164h1.84zm-6.926 11.68-1.055 1.052a.717.717 0 0 1-1.223-.491.712.712 0 0 1 .191-.499l.02-.02 1.055-1.053.02-.018a.719.719 0 0 1 1.148.25.712.712 0 0 1-.156.778zm.082 3.195a.717.717 0 0 0 1.012 0l1.056-1.052a.712.712 0 0 0-.579-1.215.716.716 0 0 0-.433.206l-1.056 1.052a.711.711 0 0 0 0 1.01zm-4.796-7.065L3.634 14.01a.717.717 0 0 1-1.222-.491.712.712 0 0 1 .19-.499l.021-.02 1.054-1.053.02-.018a.717.717 0 0 1 1.2.524.712.712 0 0 1-.209.504zm2.373 1.789-1.054 1.05a.717.717 0 0 1-1.221-.49.712.712 0 0 1 .19-.498l.02-.02 1.055-1.053.019-.02a.717.717 0 0 1 1.147.799.712.712 0 0 1-.156.232z"
      />
    </svg>
  );
}
