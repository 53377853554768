interface CzrmIconProps {
  color?: string;
}
export function CzrmIcon({ color }: CzrmIconProps) {
  const colorIcon = color ?? '#FFFFFF';
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34034 15C9.22406 15.0001 9.1089 14.9726 9.00146 14.9189C8.89402 14.8653 8.79639 14.7866 8.71417 14.6874C8.63195 14.5882 8.56675 14.4705 8.5223 14.3408C8.47784 14.2112 8.45501 14.0723 8.4551 13.932C8.4551 13.6487 8.54837 13.3771 8.71438 13.1768C8.8804 12.9765 9.10556 12.864 9.34034 12.864C12.7552 12.864 14.542 11.2406 14.542 9.64457C14.542 8.04853 12.7552 6.4243 9.34034 6.4243C9.10556 6.4243 8.8804 6.31178 8.71438 6.11149C8.54837 5.9112 8.4551 5.63955 8.4551 5.35629C8.45501 5.21601 8.47784 5.07707 8.5223 4.94745C8.56675 4.81782 8.63195 4.70004 8.71417 4.60084C8.79639 4.50165 8.89402 4.42298 9.00146 4.36935C9.1089 4.31572 9.22406 4.28817 9.34034 4.28828C14.1305 4.28828 16.3125 7.06425 16.3125 9.64457C16.3125 12.2249 14.1305 15 9.34034 15Z"
        fill={colorIcon}
      />
      <path
        d="M11.7191 10.7126C6.92824 10.7126 4.74631 7.93575 4.74631 5.35629C4.74631 2.77683 6.9247 0 11.7191 0C11.9539 0 12.1791 0.112522 12.3451 0.312813C12.5111 0.513104 12.6044 0.784757 12.6044 1.06801C12.6044 1.35126 12.5111 1.62292 12.3451 1.82321C12.1791 2.0235 11.9539 2.13602 11.7191 2.13602C8.30355 2.13602 6.51678 3.7594 6.51678 5.35629C6.51678 6.95318 8.30355 8.57655 11.7191 8.57655C11.9539 8.57655 12.1791 8.68908 12.3451 8.88937C12.5111 9.08966 12.6044 9.36131 12.6044 9.64456C12.6044 9.92781 12.5111 10.1995 12.3451 10.3998C12.1791 10.6 11.9539 10.7126 11.7191 10.7126Z"
        fill={colorIcon}
      />
      <path
        d="M1.91638 7.06511C2.69863 7.06511 3.33276 6.30004 3.33276 5.35629C3.33276 4.41253 2.69863 3.64747 1.91638 3.64747C1.13414 3.64747 0.5 4.41253 0.5 5.35629C0.5 6.30004 1.13414 7.06511 1.91638 7.06511Z"
        fill={colorIcon}
      />
      <path
        d="M19.0836 11.3534C19.8659 11.3534 20.5 10.5883 20.5 9.64456C20.5 8.70081 19.8659 7.93575 19.0836 7.93575C18.3014 7.93575 17.6672 8.70081 17.6672 9.64456C17.6672 10.5883 18.3014 11.3534 19.0836 11.3534Z"
        fill={colorIcon}
      />
    </svg>
  );
}
