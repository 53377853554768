import { useDashboardFilter } from '@/hooks';
import { socialAssistance } from '@/services/socialAssistance';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

function resolveLabel(label: string, considerLastStatement = false) {
  if (label === 'Sim') return 'famílias participaram';
  else if (label === 'Não') return 'famílias não participaram';
  else if (label === 'Não sei') return ' famílias não sabem informar';
  else
    return considerLastStatement
      ? 'famílias não possuem CREAS no município'
      : '';
}

export function useResponseData() {
  const [cras, setCras] = useState<Data[]>([]);
  const [creas, setCreas] = useState<Data[]>([]);
  const [loadingData, setloadingData] = useState(false);
  const [errorData, setErrorData] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    const promises = [socialAssistance.getByCREAS, socialAssistance.getByCRAS];

    setloadingData(true);
    Promise.all(promises.map((promise) => promise(ibge, year, semester, zone)))
      .then(([dataCreas, dataCras]) => {
        const creasData = dataCreas
          .map((data) => {
            const label = resolveLabel(data.name, true);
            return { name: label, value: data.value };
          })
          .sort();
        setCreas(creasData);

        const crasData = dataCras
          .map((data) => {
            const label = resolveLabel(data.name);
            return { name: label, value: data.value };
          })
          .sort();
        setCras(crasData);
      })
      .catch((err) => setErrorData(err))
      .finally(() => setloadingData(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      cras,
      creas,
      loadingData,
      errorData
    }),
    [cras, creas, loadingData, errorData]
  );
}
