import { Geolocation } from '@/services';
import { interpolate } from '@/utils/math';
import * as L from 'leaflet';
import 'leaflet.heat';

import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface HeatProps {
  latLngs: (Geolocation & { id: string })[];
  max: number;
  options?: L.HeatMapOptions;
}

export function Heat({ latLngs, max, options = {} }: HeatProps) {
  const map = useMap();

  useEffect(() => {
    const heatLevelFn = interpolate([0, max], [0, 1]);

    const latLngsFormatted = latLngs.map(
      (coord) =>
        [coord.latitude, coord.longitude, heatLevelFn(coord.heat_level)] as
          | L.LatLng
          | L.HeatLatLngTuple
    );

    const heat = L.heatLayer(latLngsFormatted, options).addTo(map);

    return () => {
      heat.remove();
    };
  }, [latLngs, options, max, map]);

  return null;
}
