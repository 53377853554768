import { lastUpdate as lastUpdateService } from '@/services';
import { DataLastUpdate } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useLastUpdate(type: string) {
  const [lastUpdate, setLastUpdate] = useState<DataLastUpdate>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    if (type === 'cmic') {
      lastUpdateService
        .getLastUpdatedCmic()
        .then((data) => setLastUpdate(data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    } else (
      lastUpdateService
        .getLastUpdated(type)
        .then((data) => setLastUpdate(data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    )
  }, [type]);

  return useMemo(
    () => ({
      lastUpdate,
      loading,
      error
    }),
    [lastUpdate, loading, error]
  );
}
