import { useService } from '@/hooks';
import { homeVisits } from '@/services';
import { DataWithProfile } from 'big-data';
import { useEffect, useMemo } from 'react';

export function useProfilesData() {
  const { data, loading, error, request } = useService<DataWithProfile>();

  useEffect(() => {
    request(() => homeVisits.getProfiles('beneficiaries'));
  }, [request]);

  return useMemo(() => {
    return {
      profilesData: data,
      profilesLoading: loading,
      profilesError: error
    };
  }, [data, loading, error]);
}
