import { livingConditionAgents } from '@/services';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

const requests = [
  livingConditionAgents.getPipedWaterAnswers,
  livingConditionAgents.getBathroomAmount,
  livingConditionAgents.getGarbageCollection,
  livingConditionAgents.getKidsRecreation,
  livingConditionAgents.getKidsCulturalActivities,
  livingConditionAgents.getSportActivities,
  livingConditionAgents.getHouseViolenceSituation
];

export function useLivingConditionActionRegistration() {
  const [data, setData] = useState<Record<string, Data[]>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    Promise.all(requests.map((request) => request()))
      .then((responses) => {
        const [
          pipedWater,
          bathroom,
          garbageCollection,
          kidsRecreation,
          culturalActivities,
          sportActivities,
          violenceArea
        ] = responses;

        const _data = {
          'Famílias que não possuem água canalizada em pelo menos 1 cômodo':
            pipedWater,
          'Famílias que não possuem banheiro em suas residências': bathroom,
          'Famílias que não possuem acesso à coleta seletiva':
            garbageCollection.map((gc) => {
              if (gc.name === 'Nenhuma vez') gc.name = 'Não';
              return gc;
            }),
          'Famílias que não possuem lugares públicos para brincar próximo às residências':
            kidsRecreation,
          'Famílias que não possuem atividades culturas próximo às residências':
            culturalActivities,
          'Famílias que não possuem atividades esportivas próximo às residências':
            sportActivities,
          'Famílias que estão localizadas em área de conflito/violência':
            violenceArea.map((va) => {
              if (va.name === 'Não') va.name = 'Sim';
              else if (va.name === 'Sim') va.name = 'Não';
              return va;
            })
        };

        setData(_data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return {
    granularData: data,
    loading,
    error
  };
}
