import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css, useTheme } from 'styled-components';
import { PageBody } from '../UI/PageBody';
import { PageSection } from '../UI/PageSection';

import { ExternalLinks, ButtonsFontSize, ButtonTheme } from './components';

export const HeaderAccessibility = () => {
  const theme = useTheme();

  return (
    <Box>
      <Section>
        <ExternalLinks />
        <Accessibility withLabels={!theme.screen.small} />
      </Section>
    </Box>
  );
};

function Accessibility({ withLabels }: { withLabels: boolean }) {
  return (
    <AccessibilityControls>
      {withLabels && <TextSpan>Acessibilidade</TextSpan>}
      <ButtonsFontSize />
      <ButtonTheme />
    </AccessibilityControls>
  );
}

const Box = styled(PageBody)`
  width: 100%;
  overflow: hidden;
  background: ${(props) => (props.theme.darkMode ? `#000` : `#f2f2f2`)};
  border-bottom: 1px solid #aaaa;
  grid-area: acessibility;

  ${mediaQuery.smallScreen} {
    padding: 0 10px;
  }
  ${mediaQuery.mediumScreen} {
    padding-left: 0;
  }
`;

const Section = styled(PageSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  padding: 2px 0;
`;

const AccessibilityControls = styled.div`
  user-select: none;
  display: flex;
  align-items: start;
  width: max-content;

  justify-self: end;

  ${mediaQuery.mediumScreen} {
    align-self: flex-start;
    padding-top: 4.25px;
  }

  ${mediaQuery.smallScreen} {
    align-self: flex-start;
    padding-top: 3.75px;
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      button {
        border: 1px solid ${theme.yellowDark};
        background-color: #000;
        color: #fff;
      }
    `}
`;

const TextSpan = styled.span`
  padding-top: 6px;
  padding-right: 10px;
  line-height: 1;
  font-size: 0.9rem;
  color: ${(props) =>
    props.theme.darkMode ? props.theme.text : props.theme.textDark};
`;
