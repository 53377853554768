import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${(props) =>
    props.theme.darkMode ? colors.black : colors.white};
  padding: 2rem;

  min-height: 330px;
  width: 320px;

  border-bottom: 0.564767px solid
    ${(props) => (props.theme.darkMode ? colors.black : ' #dfe6e9')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  margin-top: -1px;

  @media (max-width: 787px) {
    /* margin: 0 3.5rem; */
  }

  h2 {
    &:after {
      content: ' ';
      display: block;
      height: 2px;
      width: 50px;
      top: 50%;
      left: 0;
      background: ${(props) =>
        props.theme.darkMode ? colors.white : colors.ciano700};
    }
  }

  p {
    color: ${(props) => (props.theme.darkMode ? colors.white : '#4C5469')};
    font-weight: 400;
    margin: 1rem 0;
    height: 70px;
  }

  a {
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.theme.darkMode ? colors.white : colors.ciano700};
    gap: 0.3rem;
    width: min-content;
    span {
      font-weight: 700;
      color: ${(props) =>
        props.theme.darkMode ? colors.white : colors.ciano700};
    }
  }

  .icon-card {
    svg {
      width: 55px;
      height: 55px;

      background: ${(props) =>
        props.theme.darkMode ? colors.white : '#1d69701a'};
      padding: 1rem;
      border-radius: 10px;

      path {
        fill: ${(props) =>
          props.theme.darkMode ? colors.black : colors.ciano700};
        stroke: ${(props) =>
          props.theme.darkMode ? colors.black : colors.ciano700};
        stroke-width: 0;
      }
    }
  }
`;
