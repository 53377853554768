import { CardTagged, D3Map, ServiceBox } from '@/components/shared';
import { TitleText, Text, ColorText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useMapData } from '@/servicesHooks/jobIncome';
import { colors } from '@/theme/colors';
import { dotsNumber } from '@/utils/string';
import { useTheme } from 'styled-components';

export function CardMap() {
  const { darkMode } = useTheme();
  const { tag, city } = useDashboardFilter();
  const { data, total, ...rest } = useMapData();
  const totalFormatted = dotsNumber(total);

  const cityText = !!city ? `no município de ${city}` : `por município`;

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a quantidade de famílias com CMIC visitadas{' '}
          {city ? `no município de ${city}` : 'por município'} que NÃO possuem
          trabalho remunerado?
        </TitleText>

        <Text>
          <ColorText>{totalFormatted}</ColorText> famílias em situação de
          vulnerabilidade social, não possuem trabalho remunerado. Acompanhe a
          distribuição {cityText}:
        </Text>

        <D3Map
          data={data}
          color={darkMode ? colors.ming100 : colors.ciano700}
          percentage={true}
          filteringCity={city}
        />
      </ServiceBox>
    </CardTagged>
  );
}
