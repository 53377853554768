import styled from 'styled-components';

export const Box = styled.div`
  svg {
    path {
      cursor: pointer;
    }
  }
`;

export const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  width: 85%;
  margin: 1.5rem auto 0;

  .legend-item {
    width: calc(95% / 2);
    min-width: 200px;
    height: 28px;
    display: flex;
    align-items: center;

    transition: opacity 0.2s;

    &.not-active {
      /* filter: blur(2px); */
      opacity: 0.6;
    }

    p {
      font-size: 1.1rem;
      margin-left: 0.5rem;
      line-height: 0;
    }
  }
`;

interface ColorBoxProps {
  color: string;
}

export const ColorBox = styled.div<ColorBoxProps>`
  background: ${({ color }) => color};
  width: 1.7rem;
  height: 1.7rem;
`;
