import { HorizontalGridBar, ServiceBox } from '@/components/shared';
import { useAccessPlanActions } from '@/servicesHooks/accessDataActions';
import { cpfReplacer } from '@/utils/string';
import styled from 'styled-components';
import { CardBase, CardContent, CardHeader } from '../../components';
import { useManager, useTabContext } from '../../contexts';
import { generateTag } from '../../utils/';

export function CardUserPlanActions() {
  const { tab } = useTabContext();
  const { manager } = useManager();
  const { data, error, loading } = useAccessPlanActions(
    manager?.cpf,
    tab?.value
  );

  const tag = generateTag([
    `CPF: ${cpfReplacer(manager?.cpf ?? '', 'secret')}`,
    tab?.name
  ]);

  return (
    <Box>
      <CardHeader title="Planos de ações do usuário" subtitle={tag} />

      <ServiceBox empty={data.length === 0} loading={loading} error={error}>
        <CardContent>
          <HorizontalGridBar data={data} sizeRatio={1} isPercentage />
        </CardContent>
      </ServiceBox>

      <div />
    </Box>
  );
}

const Box = styled(CardBase)``;
