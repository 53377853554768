interface BdsHomeProps {
  color?: string;
}

export function BdsHome({ color }: BdsHomeProps) {
  const selectedColor = color ?? '#fff';

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12.6667C21.9123 12.6672 21.8253 12.6504 21.7441 12.6172C21.6629 12.584 21.589 12.5351 21.5267 12.4733L12 2.94L2.47335 12.4733C2.34582 12.5825 2.18177 12.6396 2.01398 12.6331C1.8462 12.6267 1.68704 12.5571 1.56831 12.4384C1.44958 12.3196 1.38002 12.1605 1.37354 11.9927C1.36706 11.8249 1.42413 11.6609 1.53335 11.5333L11.5333 1.53333C11.6583 1.40916 11.8272 1.33947 12.0033 1.33947C12.1795 1.33947 12.3484 1.40916 12.4733 1.53333L22.4733 11.5333C22.5651 11.6269 22.6272 11.7455 22.6519 11.8742C22.6767 12.0029 22.6629 12.136 22.6124 12.2569C22.5619 12.3779 22.4769 12.4812 22.368 12.5541C22.2591 12.627 22.1311 12.6661 22 12.6667Z"
        fill={selectedColor}
      />
      <path
        d="M12 5.19334L4 13.22V21.3333C4 21.687 4.14048 22.0261 4.39052 22.2761C4.64057 22.5262 4.97971 22.6667 5.33333 22.6667H10V16H14V22.6667H18.6667C19.0203 22.6667 19.3594 22.5262 19.6095 22.2761C19.8595 22.0261 20 21.687 20 21.3333V13.1733L12 5.19334Z"
        fill={selectedColor}
      />
    </svg>
  );
}
