import { useAnalyticsTitle } from '@/hooks';
import {
  ApresentationCard,
  LastUpdateMessage,
  TextIcon
} from '@/components/shared';

import { Box } from './styles';
import { SocialMovementsInsights } from '../components';

const text = () => (
  <p>
    O tempo presente traz como desafio para a gestão pública o aprimoramento do
    diálogo com a sociedade civil. Diante da fragilização da política nacional
    de participação social e dos impactos da pandemia de covid-19, os movimentos
    sociais foram forçados a redefinirem suas iniciativas de mobilização,
    organização e ações socio políticas. Na busca de melhor compreender esses
    processos, a Asemov- Assessoria Especial de Acolhimento aos Movimentos
    Sociais, cuja missão é fortalecer o sistema de participação social e
    assessorar ações dos mecanismos de controle social das políticas públicas,
    considerou necessário produzir o I Anuário dos Movimentos Sociais do Ceará
    (2022). O objetivo central do Anuário é apresentar os movimentos sociais
    institucionalizados ou não com suas dinâmicas de organização e ação socio
    educativa e o perfil das lideranças na realidade cearense.
  </p>
);

export function SocialMovements() {
  useAnalyticsTitle('Anuário dos Movimentos Sociais do Ceará');
  return (
    <Box>
      <LastUpdateMessage
        message="Ano de referência: "
        year="2022"
        loading={false}
        error={null}
      />
      <ApresentationCard
        title="Apresentação"
        source="Assessoria Especial de Acolhimento aos Movimentos Sociais (ASEMOV)"
      >
        <TextIcon text={text} />
      </ApresentationCard>
      <SocialMovementsInsights />
    </Box>
  );
}
