import { forwardRef } from 'react';
import styled from 'styled-components';

interface PageBodyProps {
  padding?: number;
  color?: string;
  children: any;
}

export const PageBody = forwardRef<HTMLDivElement, PageBodyProps>(
  (props, ref) => {
    return <PageBodyWrapper {...props} ref={ref} />;
  }
);

const PageBodyWrapper = styled.div<PageBodyProps>`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${(props) => (props.padding ? props.padding + `px` : null)};
  background: ${(props) => props.color ?? props.theme.background};
`;
