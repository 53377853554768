import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { GeolocationBack } from './@types';
import { getGeolocationFront } from './utils';

type AssistanceParams = { aggregation: string; key: string; value: string };

const PARAMS: Record<string, AssistanceParams> = {
  NO_WATCH_CRAS: { aggregation: 'CRAS', key: 'CRASsituation', value: 'Não' },
  DONT_KNOW_CRAS: {
    aggregation: 'CRAS',
    key: 'CRASsituation',
    value: 'Não sei'
  },
  NO_WATCH_CREAS: { aggregation: 'CREAS', key: 'CREASsituation', value: 'Não' },
  DONT_KNOW_CREAS: {
    aggregation: 'CREAS',
    key: 'CREASsituation',
    value: 'Não sei'
  },
  NO_BUILD_CREAS: {
    aggregation: 'CREAS',
    key: 'CREASsituation',
    value: 'O município não possui CREAS'
  }
};

const service = () => {
  const resource = `/assistencia-social-geolocalizacao`;

  const assistance = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const params = PARAMS[filter];

        const path = `${resource}`;
        const options = {
          params: {
            aggregations: params.aggregation,
            [params.key]: params.value,
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<GeolocationBack[]>(() =>
          mainApi.get(path, options)
        );

        return getGeolocationFront(response.data);
      }
    );

  return Object.freeze({ assistance });
};

export const socialAssistance = service();
