import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${colors.blue500};
  padding: 0.2rem 1.3rem;
  border-radius: 1.2rem;
  width: max-content;
  height: min-content;

  text-align: center;
  color: white;
`;
