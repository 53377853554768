import { ThemeBase } from './ThemeBase';
import { colors } from './colors';
import { Theme } from 'big-data/theme';

export const ThemeDark: Theme = {
  ...ThemeBase,

  darkMode: true,

  text: colors.white,
  background: colors.black,

  greenDefault: colors.green200,
  redDefault: colors.red500,
  yellowDefault: colors.yellow500
};
