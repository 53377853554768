import { useMemo, useState } from 'react';
import { DataTree } from 'big-data';

import {
  FilterDashboard,
  SunBurstText,
  SunBurstReact,
  CzRMTableModal
} from '@/components/shared';
import {
  AxeDescriptionText,
  ThermometerWrapper,
  ThirdTitle
} from '@/components/styled';
import { useDashboardTitle, useFoodInsecurity } from '@/hooks';
import { capitalize } from '@/utils/string';

import { InsecurityFoodByCovid, InsecurityFoodInsights } from '../components';
import { modifierColors } from '../utils';
import { descriptions } from './description';
import { colors, getTree, getLegendTree } from './tree';
import { Box } from './styles';

const getLabelFormatted = (label: string) => {
  if (label === 'Fora de insegurança alimentar') {
    return `FAMÍLIAS ${label.toUpperCase()}`;
  }

  if (label === 'FAMÍLIAS ENTREVISTADAS') {
    return `${label.toLocaleUpperCase()}`;
  }

  return `FAMÍLIAS EM ${label.toLocaleUpperCase()}`;
};

const formatter = new Intl.NumberFormat('pt-br');

export function InsecurityFood() {
  useDashboardTitle('Termômetro de Dados – Eixo Insegurança Alimentar');

  const { data, loading } = useFoodInsecurity();

  const [selected, setSelected] = useState<DataTree>();
  const [isCzrmModalOpen, setIsCzrmModalOpen] = useState(false);

  const dataTotal = data.reduce((acc, cur) => acc + cur.value, 0);
  const interviewedFamiliesTotal = selected
    ? `${selected.percentageValue.toFixed(2)}%`
    : formatter.format(dataTotal);

  const name = selected ? selected.name.toLowerCase() : 'Insegurança Alimentar';

  const color = modifierColors.get(colors[name] ?? '') ?? '#1D696F';

  const description = selected
    ? descriptions[name]
    : 'Este eixo apresenta as dimensões da segurança alimentar e nutricional entre as famílias com CMIC visitadas a partir da percepção e da experiência delas com a fome. Para isso, usa uma versão curta da Escala Brasileira de Insegurança Alimentar (EBIA).';

  const treeClassification = useMemo(() => getTree(data), [data]);

  const legend = useMemo(
    () => getLegendTree(treeClassification.children, selected),
    [treeClassification, selected]
  );

  function handleSunburstClick(data?: DataTree) {
    if (!data || data.name === 'Insegurança alimentar') {
      setSelected(undefined);
    } else {
      setSelected(data);
    }
  }

  function handleOpenCzRMModal() {
    if (!selected) return;
    setIsCzrmModalOpen(true);
  }

  const sunburstLabel = getLabelFormatted(
    selected ? selected.name : 'FAMÍLIAS ENTREVISTADAS'
  );

  const sunburstBtnLabel = selected
    ? null
    : 'CLIQUE EM CADA EIXO PARA DETALHAR';

  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>
      <FilterDashboard hidden={!!selected} />

      <ThermometerWrapper>
        <div className="left-cards">
          <InsecurityFoodByCovid color={color} />
        </div>
        <SunBurstReact
          data={treeClassification}
          dataLegend={legend}
          onClick={handleSunburstClick}
          selected={selected !== undefined}
          loading={loading}
          legendTitle="Escala brasileira de Insegurança Alimentar (versão curta)"
          labelWithInitials={true}
          sizeByValue={true}
        >
          <SunBurstText
            value={interviewedFamiliesTotal}
            color={color}
            label={sunburstLabel}
            buttonLabel={sunburstBtnLabel}
            isButtonCzrm={!!selected}
            selected={selected}
            handleOpenFamilyTable={handleOpenCzRMModal}
          />
        </SunBurstReact>
      </ThermometerWrapper>

      <div className="profile">
        <ThirdTitle>
          Pesquisa dos Agentes Sociais sobre {capitalize(name)}
        </ThirdTitle>

        <InsecurityFoodInsights selected={selected} />
      </div>

      <CzRMTableModal
        isOpen={isCzrmModalOpen}
        axe="insecurityFood"
        onCancel={() => setIsCzrmModalOpen(false)}
        title={selected?.name || ''}
        selectedSubAxe={selected?.name}
      />
    </Box>
  );
}
