import { Skeleton, Space as AntdSpace } from 'antd';
import styled from 'styled-components';

const array = Array.from({ length: 4 }).map((_, index) => index);

export function FeedbackSkeletons() {
  return (
    <Box>
      {array.map((item) => (
        <Space key={item} align="start" style={{ width: '100%' }}>
          <Skeleton.Avatar active shape="circle" size="large" />
          <div className="column">
            <Input active size="large" className="input-large" />
            <Input active size="small" />
          </div>
        </Space>
      ))}
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

const Space = styled(AntdSpace)`
  width: 100%;

  .ant-space-item:last-child {
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 4px;

    width: 100%;
    flex: 1;
  }
`;

const Input = styled(Skeleton.Input)`
  border-radius: 7px;

  &.input-large {
    width: 100%;
  }
`;
