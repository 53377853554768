import { Radio, RadioChangeEvent, Space } from 'antd';


import { Box } from './styles';
import { INDICATORS_OPTIONS } from './indicators';

interface IndicatorGroupProps {
  axe?: string;
  value?: string;
  onChange?: (value: string) => void;
}

const { Group: RadioGroup } = Radio;

export function IndicatorGroup({ axe, value, onChange }: IndicatorGroupProps) {
  const axeValue = axe ?? '';

  const indicators = INDICATORS_OPTIONS[axeValue] ?? [];

  function handleChange(e: RadioChangeEvent) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <Box>
      <p className="label">
        Para qual indicador a ação é destinada?{' '}
        <span className="required">(Obrigatório)</span>
      </p>
      <RadioGroup value={value} onChange={handleChange}>
        <Space direction="vertical">
          {indicators.map((indicator) => (
            <Radio key={indicator.label} value={indicator.value}>
              {indicator.label}
            </Radio>
          ))}
        </Space>
      </RadioGroup>
    </Box>
  );
}
