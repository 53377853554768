import { useDashboardFilter } from '@/hooks';
import { foodInsecurity } from '@/services/foodInsecurity';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function usePlantConsume(classification?: string) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    foodInsecurity
      .getPlantsConsume(classification, ibge, year, semester, zone)
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [classification, ibge, year, semester, zone]);

  return useMemo(
    () => ({
      plantConsume: data,
      loadingPlantConsume: loading,
      errorPlantConsume: error
    }),
    [data, loading, error]
  );
}
