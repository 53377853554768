import {
  analyticsMapSiteLink,
  dashboardMapSiteLink,
  thermometerMapSiteLinks
} from '../../links';
import { colors } from '@/theme/colors';

interface ISVGPage {
  x: number;
  y: number;
  fill: string;
  stroke: string;
  text: Omit<ISVGPage, 'text' | 'stroke'> & { label: string };
}

const SOCIAL_INTEGRA = 'Integra Social';

const SVG_PAGE: Record<string, ISVGPage> = {
  'Big Data Social': {
    x: 173,
    y: 1,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Big Data Social',
      x: 258,
      y: 21,
      fill: colors.white
    }
  },

  Dashboard: {
    x: 1,
    y: 74,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Dashboard',
      x: 86,
      y: 94,
      fill: colors.white
    }
  },

  Mapa: {
    x: 25,
    y: 147,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Mapa',
      x: 110,
      y: 167,
      fill: colors.white
    }
  },

  'Planos de Ação': {
    x: 25,
    y: 200,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Planos de Ação',
      x: 110,
      y: 220,
      fill: colors.white
    }
  },

  Termômetro: {
    x: 25,
    y: 253,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Termômetro',
      x: 110,
      y: 273,
      fill: colors.white
    }
  },

  'Integra Social': {
    x: 25,
    y: 306,
    fill: colors.yellow600,
    stroke: colors.black,
    text: {
      label: SOCIAL_INTEGRA,
      x: 110,
      y: 326,
      fill: colors.white
    }
  },

  Feedbacks: {
    x: 25,
    y: 359,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Feedbacks',
      x: 110,
      y: 379,
      fill: colors.white
    }
  },

  'Dados de Acesso': {
    x: 25,
    y: 412,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Dados de Acesso',
      x: 110,
      y: 432,
      fill: colors.white
    }
  },

  'Assistência Social': {
    x: 39,
    y: 518,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Assistência Social',
      x: 124,
      y: 538,
      fill: colors.white
    }
  },

  Educação: {
    x: 39,
    y: 571,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Educação',
      x: 124,
      y: 591,
      fill: colors.white
    }
  },

  'Insegurança Alimentar': {
    x: 39,
    y: 624,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Insegurança Alimentar',
      x: 124,
      y: 644,
      fill: colors.white
    }
  },

  'Condições de Moradia': {
    x: 39,
    y: 677,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Condições de Moradia',
      x: 124,
      y: 697,
      fill: colors.white
    }
  },

  Saúde: {
    x: 39,
    y: 730,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Saúde',
      x: 124,
      y: 750,
      fill: colors.white
    }
  },

  'Trabalho e Renda': {
    x: 39,
    y: 783,
    fill: colors.ciano700,
    stroke: colors.black,
    text: {
      label: 'Trabalho e Renda',
      x: 124,
      y: 803,
      fill: colors.white
    }
  },

  Analíticos: {
    x: 345,
    y: 74,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Analíticos',
      x: 430,
      y: 94,
      fill: colors.white
    }
  },

  'Cartão Mais Infância': {
    x: 368,
    y: 147,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Cartão Mais Infância',
      x: 453,
      y: 167,
      fill: colors.white
    }
  },

  'Vale Gás': {
    x: 368,
    y: 200,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Vale Gás',
      x: 453,
      y: 220,
      fill: colors.white
    }
  },

  'Visitas Domiciliares': {
    x: 368,
    y: 253,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Visitas Domiciliares',
      x: 453,
      y: 273,
      fill: colors.white
    }
  },

  Capacitação: {
    x: 368,
    y: 306,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Capacitação',
      x: 453,
      y: 326,
      fill: colors.white
    }
  },

  'Escolaridade Adulto': {
    x: 368,
    y: 359,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Escolaridade Adulto',
      x: 453,
      y: 379,
      fill: colors.white
    }
  },

  Moradia: {
    x: 368,
    y: 412,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Condições de Moradia',
      x: 453,
      y: 432,
      fill: colors.white
    }
  },

  'Movimentos Sociais': {
    x: 368,
    y: 465,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'Movimentos Sociais',
      x: 453,
      y: 485,
      fill: colors.white
    }
  },
  'População LGBTQIA+': {
    x: 368,
    y: 518,
    fill: colors.green200,
    stroke: colors.black,
    text: {
      label: 'População LGBTQIA+',
      x: 453,
      y: 538,
      fill: colors.white
    }
  }
};

export const dashboardLinks = dashboardMapSiteLink
  .map((link) => ({
    ...SVG_PAGE[link.name],
    path: link.path,
    linkType: link.linkType
  }))
  .concat([
    {
      x: 25,
      y: 306,
      fill: colors.yellow600,
      stroke: colors.black,
      text: {
        label: SOCIAL_INTEGRA,
        x: 110,
        y: 326,
        fill: colors.white
      },
      path: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
      linkType: 'external'
    }
  ]);

export const analyticsLinks = analyticsMapSiteLink
  .map((link) => {
    const svgPage = SVG_PAGE[link.name];

    if (svgPage) {
      return {
        ...svgPage,
        path: link.path,
        linkType: link.linkType
      };
    }
    return undefined;
  })
  .filter(Boolean)
  .concat([
    {
      x: 368,
      y: 571,
      fill: colors.yellow600,
      stroke: colors.black,
      text: {
        label: SOCIAL_INTEGRA,
        x: 453,
        y: 591,
        fill: colors.white
      },
      path: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
      linkType: 'external'
    }
  ]) as (ISVGPage & { path: string; linkType: string })[];

export const thermometerLinks = thermometerMapSiteLinks.map((link) => ({
  ...SVG_PAGE[link.name],
  path: link.path,
  linkType: link.linkType
}));
