import { Data } from 'big-data';

export function max(values: number[]) {
  return Math.max(...values);
}

export function maxData(datas: Data[]) {
  const INITIAL_MAX = { name: '', value: 0 };
  const total = datas.reduce((acc, cur) => acc + cur.value, 0);

  const percentages = datas.map((data) => ({
    ...data,
    value: data.value / total
  }));

  return percentages.reduce(
    (acc, cur) => (cur.value > acc.value ? cur : acc),
    INITIAL_MAX
  );
}

export function min(values: number[]) {
  return Math.min(...values);
}

export function interpolate(domain: number[], minmax: number[]) {
  const [x1, x2] = domain;
  const [min, max] = minmax;

  const k = max - min;

  const a = k / (x2 - x1);
  const b = min - (k * x1) / (x2 - x1);

  return (x: number) => a * x + b;
}
