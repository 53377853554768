import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import * as d3 from 'd3';
import { Root } from '../Arcs';

type D3Svg = d3.Selection<d3.BaseType, unknown, HTMLElement, any> | null;

type D3G = d3.Selection<SVGPathElement, Root, SVGGElement, unknown> | null;

type D3Arc = d3.Selection<
  d3.BaseType | SVGGElement,
  Root,
  SVGGElement,
  unknown
> | null;

interface ProviderProps {
  children: ReactNode;
}

interface SunBurstContextProps {
  arcs: D3Arc;
  chart: D3Svg;
  paths: D3G;
  setPaths: (value: D3G) => void;
  setArcs: (value: D3Arc) => void;
}

const SunBurstContext = createContext<SunBurstContextProps>(
  {} as SunBurstContextProps
);

export function Provider({ children }: ProviderProps) {
  const [chart, setChart] = useState<D3Svg>(null);
  const [paths, setPaths] = useState<D3G>(null);
  const [arcs, setArcs] = useState<D3Arc>(null);

  const defineArcs = useCallback((arcs: D3Arc) => {
    setArcs(arcs);
  }, []);

  const definePaths = useCallback((paths: D3G) => {
    setPaths(paths);
  }, []);

  useEffect(() => {
    const svg = d3
      .select('.sunburst-svg')
      .attr('width', 400)
      .attr('height', 400);

    setChart(svg);

    return () => {
      svg.selectAll('*').remove();
    };
  }, []);

  const sunburstInfo = useMemo(
    () => ({
      chart,
      arcs,
      paths,
      setPaths: definePaths,
      setArcs: defineArcs
    }),
    [chart, paths, definePaths, arcs, defineArcs]
  );

  return (
    <SunBurstContext.Provider value={sunburstInfo}>
      {children}
    </SunBurstContext.Provider>
  );
}

export function useChart() {
  return useContext(SunBurstContext);
}
