import { Link } from 'react-router-dom';

import bigdatasimplelogo from '@/assets/logos/iris-simple.svg';
import bigdatalargelogo from '@/assets/logos/logo-social.svg';
import { Box } from './styles';

interface LogoProps {
  collapsed?: boolean;
}

export function Logo({ collapsed = false }: LogoProps) {
  return (
    <Box>
      <Link to="/">
        {collapsed ? (
          <img
            src={bigdatasimplelogo}
            alt="logo do big data social simplificado"
          />
        ) : (
          <img src={bigdatalargelogo} alt="logo do big data social largo" />
        )}
      </Link>
    </Box>
  );
}
