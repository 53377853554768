import styled from 'styled-components';

export const GenericGrid = styled.div`
  display: grid;
  grid-gap: 1rem;

  height: 100%;
`;

interface GenericGridItemProps {
  area: string;
}

export const GenericGridItem = styled.div<GenericGridItemProps>`
  grid-area: ${({ area }) => area};
`;
