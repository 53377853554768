import { Data } from 'big-data';
import * as d3 from 'd3';

import ceara from './ceara-macroregioes.json';
import { ColorScale } from './colors';
import { maxValue } from './utils';

type GeoJson = {
  properties: {
    center: [number, number];
    id: number;
    name: string;
    total: number;
    color: string;
  };
  type: string;
  geometry: {
    type: string;
    coordinates: number[][][];
  };
};
export type SVG = d3.Selection<SVGSVGElement, unknown, null, undefined>;
export type Path = d3.Selection<SVGPathElement, GeoJson, SVGGElement, unknown>;

const RATIO = 1.2;

export function createSVG(container: HTMLDivElement, width: number): SVG {
  const height = width * RATIO;

  return d3
    .select(container)
    .append('svg')
    .attr('class', 'map')
    .attr('width', width)
    .attr('height', height)
    .attr(`preserveAspectRatio`, `xMidYMid meet`)
    .attr(`viewBox`, `0 0 ${width} ${height}`);
}

export function createMap(
  svg: SVG,
  data: Record<string, number>,
  color: string,
  interval: number[]
): Path | undefined {
  const node = svg.node();
  if (!node) return;

  const widthAttr = node.getAttribute('width');
  const heightAttr = node.getAttribute('height');
  const width = widthAttr ? parseInt(widthAttr, 10) : 0;
  const height = heightAttr ? parseInt(heightAttr, 10) : 0;

  const values = Object.values(data);
  const max = maxValue(values);

  const colorScale = ColorScale(max, color, interval);

  const geoJson = {
    ...ceara,
    features: (ceara as any).features
      .map((feature: any) => ({
        ...feature,
        properties: {
          ...feature.properties,
          center: d3.geoCentroid(feature),
          total: data[feature.properties.regiao.toLocaleLowerCase()] ?? 0,
          color: colorScale(
            data[feature.properties.regiao.toLocaleLowerCase()]
          ) as any
        }
      }))
      .sort((a: any, b: any) =>
        d3.descending(a.properties.center, b.properties.center)
      )
  };

  const projection = d3.geoMercator().fitSize([width, height], geoJson as any);
  const path = d3.geoPath().projection(projection);

  const paths = svg
    .append('g')
    .selectAll(`path.city`)
    .data(geoJson.features)
    .enter()
    .append('path')
    .attr('d', path as any)
    .attr('class', 'city')
    .style('fill', (d: any) => d.properties.color);

  return paths as Path;
}

export function onHover(path: Path | undefined, fn: (data?: Data) => void) {
  if (!path) return;

  const nodes = path.nodes();
  const colors = path.data();

  path.on('mouseenter', function (e: any, d: GeoJson) {
    const currentIdx = nodes.indexOf(this);

    nodes
      .filter((_, i) => i !== currentIdx)
      .forEach((node) => {
        node.style.fill = '#fff';
      });

    nodes
      .filter((_, i) => i === currentIdx)
      .forEach((node) => {
        node.style.fill = d.properties.color;
      });

    fn({ name: d.properties.name, value: d.properties.total });
  });

  path.on('mouseleave', () => {
    nodes.forEach((node, i) => {
      node.style.fill = colors[i].properties.color;
    });

    fn();
  });
}
