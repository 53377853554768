import { BiTrashAlt } from 'react-icons/bi';
import citiesCE from '@/constants/ceara.json';
import { Box } from './styles';

import { useAuth, useDashboardFilter } from '@/hooks';
import { Show } from '../Show';
import {
  useYearsSemestersData,
  useZoneData
} from '@/servicesHooks/profileSearch';
import { AntSelect } from '@/components/styled';
import { AntModal } from '../AntModal';
import { AlertCityModal } from '../AlertCityModal';
import { FilterIndicatorIcon } from '..';

const { Option } = AntSelect;

type FilterDashboardProps = {
  hidden?: boolean;
  description?: string;
};

export function FilterDashboard({ hidden = false }: FilterDashboardProps) {
  const {
    onChangeYearSemester,
    yearSemester,
    onEraseFilter,
    onChangeCity,
    city,
    onChangeZone,
    zone,
    isData,
    onCancel
  } = useDashboardFilter();

  const { yearsSemesters, yearsSemestersLoading } = useYearsSemestersData();
  const { zones, zonesLoading } = useZoneData();
  const { isAdm } = useAuth();

  return (
    <Show when={!hidden} fallback={null}>
      <Box>
        <div className="icon-text-wrapper">
          <FilterIndicatorIcon />
          <span>Veja informações detalhadas sobre cada município:</span>
        </div>

        <div className="select-wrapper">
          <div className="select-group">
            <Show when={isAdm} fallback={null}>
              <BdSelect
                placeholder="Selecione um município"
                onChange={onChangeCity}
                value={!!city ? city : undefined}
              >
                {citiesCE.features.map((city) => (
                  <Option
                    key={city.properties.id}
                    value={`${city.properties.name}_${city.properties.id}`}
                  >
                    {city.properties.name}
                  </Option>
                ))}
              </BdSelect>
            </Show>
            <BdSelect
              placeholder="Selecione um semestre"
              onChange={onChangeYearSemester}
              value={!!yearSemester ? yearSemester : undefined}
              loading={yearsSemestersLoading}
            >
              {yearsSemesters.map((semester) => (
                <Option key={semester.name} value={semester.name}>
                  {semester.name}
                </Option>
              ))}
            </BdSelect>

            <BdSelect
              placeholder="Selecione uma zona"
              onChange={onChangeZone}
              value={!!zone ? zone : undefined}
              loading={zonesLoading}
            >
              {zones.map((zone) => (
                <Option key={zone.name} value={zone.name}>
                  {zone.name}
                </Option>
              ))}
            </BdSelect>
          </div>
          <button onClick={onEraseFilter} className="clear-filter">
            <BiTrashAlt size={15} />
            <span>LIMPAR</span>
          </button>
        </div>
        <AntModal
          closable={false}
          width={400}
          visible={isData}
          onCancel={onCancel}
          footer={null}
        >
          <AlertCityModal text='Não há dados para o município selecionado!' onCancel={onCancel} />
        </AntModal>
      </Box>
    </Show>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
