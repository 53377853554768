import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = '/avaliacoes';

  async function get(minimumDate: string) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((data) => ({
      rate: data.nota,
      quantity: data.quantidade
    }));
  }

  return {
    get
  };
};

export const ratings = service();
