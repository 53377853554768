import { DashboardCard } from '@/components/shared';
import styled from 'styled-components';

import { ColorText } from '@/components/styled';
import { useFamilyPandemic } from '@/servicesHooks/foodInsecurity';
import { dotsNumber } from '@/utils/string';
import { colors } from '@/theme/colors';

const colorDefault = colors.green600;

interface InformationBodyProps {
  color?: string;
}

export function InsecurityFoodByCovid({ color }: InformationBodyProps) {
  const { pandemicValue, loadingBenefits } = useFamilyPandemic();

  return (
    <DashboardCard color={color} loading={loadingBenefits}>
      <Box color={color}>
        {/* <img src={virusIcon} alt="Indice pandemico" /> */}
        <p className="information-about">COVID-19 e Insegurança Alimentar</p>
      </Box>

      <ul>
        <li>
          <p>
            A pandemia de COVID-19 diminuiu a disponibilidade de alimentos entre{' '}
            <ColorText color={color ?? colorDefault}>
              {dotsNumber(pandemicValue)} famílias.
            </ColorText>
          </p>
        </li>
      </ul>
    </DashboardCard>
  );
}

const Box = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;

  img {
    width: 17px;
    height: 17px;
  }
`;
