export function VirandoOJogo() {
  return(
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.0156 44.7539C17.7363 44.7539 18.2988 44.1738 18.2988 43.4707C18.2988 42.75 17.7188 42.1875 17.0156 42.1875C16.2949 42.1875 15.7324 42.7676 15.7324 43.4707C15.7324 44.1738 16.3125 44.7539 17.0156 44.7539Z" fill="#D42929"/>
      <path d="M27.4395 44.7188C28.1602 44.7188 28.7227 44.1387 28.7227 43.418C28.7227 42.6973 28.1426 42.1348 27.4395 42.1348C26.7188 42.1348 26.1562 42.7148 26.1562 43.418C26.1563 44.1563 26.7188 44.7188 27.4395 44.7188Z" fill="#D42929"/>
      <path d="M14.4668 40.9219C14.4668 40.9219 13.7109 40.8867 13.7109 40.0957V40.0254C13.7109 39.252 14.4668 39.1992 14.4668 39.1992H29.9355C29.9355 39.1992 30.6914 39.2695 30.6914 40.0254V40.0957C30.6914 40.8516 29.9355 40.9219 29.9355 40.9219H14.4668Z" fill="#D42929"/>
      <path d="M25.207 7.24219C27.1055 7.24219 28.6348 5.71289 28.6348 3.81445C28.6348 1.91602 27.1055 0.404297 25.207 0.404297C23.3086 0.404297 21.7793 1.93359 21.7793 3.81445C21.7969 5.69531 23.3262 7.24219 25.207 7.24219Z" fill="#D42929"/>
      <path d="M6.62695 37.1777C6.46875 37.4766 6.38086 37.8281 6.38086 38.1973C6.38086 39.3926 7.36523 40.377 8.57812 40.377C9.43945 40.377 10.1953 39.8672 10.5469 39.1465L18.0176 26.2969L14.9238 23.2031L6.62695 37.1777Z" fill="#D42929"/>
      <path d="M22.834 36.5976C22.4824 37.3184 21.7266 37.8281 20.8652 37.8281C19.6699 37.8281 18.6855 36.8437 18.6855 35.6484C18.6855 35.2793 18.7734 34.9277 18.9316 34.6289L22.1836 28.916L15.8379 22.6055C13.3945 20.1621 15.1348 17.5078 15.1348 17.5078L18.5449 11.4785H9.49219C8.49023 11.4785 7.69922 10.6699 7.69922 9.68555C7.69922 8.70117 8.49023 7.89258 9.49219 7.89258H36.9141C37.8984 7.89258 38.707 8.70117 38.707 9.68555C38.707 10.6699 37.916 11.4785 36.9141 11.4785H26.4551L21.4629 20.2148L26.543 25.2422C28.3184 26.9473 27.1758 28.8633 27.1758 28.8633L22.834 36.5976Z" fill="#D42929"/>
    </svg>
  )

}