import { Box } from './styles';
import { BiTrashAlt } from 'react-icons/bi';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { AntSelect } from '@/components/styled';
import citiesCE from '@/constants/ceara.json';
import { ReactNode } from 'react';
import { FilterIndicatorIcon } from '../FilterIndicatorIcon';

const { Option } = AntSelect;

interface FilterDashboardProps {
  children?: ReactNode;
  noStyle?: boolean;
  title?: string;
}

const ZONES = [
  { name: 'Urbana', value: 'Urbana' },
  { name: 'Rural', value: 'Rural' }
];

export function FilterAnalytic({
  children,
  noStyle = false,
  title
}: FilterDashboardProps) {
  const { city, onChangeCity, onEraseFilter, zone, onChangeZone } =
    useAnalyticsFilter();

  return (
    <Box noStyle={noStyle}>
      <div className="icon-text-wrapper">
        <FilterIndicatorIcon />
        <span>
          {title ?? 'Configure o filtro para obter informações detalhadas:'}
        </span>
      </div>
      <div className="select-wrapper">
        <div className="select-group">
          <BdSelect
            placeholder="Selecione um município"
            onChange={onChangeCity}
            value={!!city ? city : undefined}
          >
            {citiesCE.features.map((city) => (
              <Option
                key={city.properties.id}
                value={`${city.properties.name}_${city.properties.id}`}
              >
                {city.properties.name}
              </Option>
            ))}
          </BdSelect>
          <BdSelect
            placeholder="Selecione uma zona"
            onChange={onChangeZone}
            value={!!zone ? zone : undefined}
          >
            {ZONES.map((zone) => (
              <Option key={zone.name} value={zone.value}>
                {zone.name}
              </Option>
            ))}
          </BdSelect>
          {children}
        </div>

        <button onClick={onEraseFilter} className="clear-filter">
          <BiTrashAlt size={15} />
          <span>LIMPAR</span>
        </button>
      </div>
    </Box>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
