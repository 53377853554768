import { DataPercentageComputed } from 'big-data';
import { Box } from './styles';

interface DataWithImage extends DataPercentageComputed {
  img: string;
}

interface ImageRankingProps {
  data: DataWithImage[];
}

export function ImageRanking({ data }: ImageRankingProps) {
  return (
    <Box>
      {data.map((d, i) => (
        <li className={`ranking ranking-${i}`} key={d.name}>
          <div className="ranking-img-box">
            <img src={d.img} alt={d.name} />
          </div>
          <div className="ranking-text-box">
            <p className="title">{d.name}</p>
            <p className="value">{d.percentageValue.toFixed(2)}%</p>
          </div>
        </li>
      ))}
    </Box>
  );
}
