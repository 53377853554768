interface BdsSocialMovementsProps {
  color?: string;
}
export function BdsSocialMovements({ color }: BdsSocialMovementsProps) {
  const colorSelected = color ?? '#FFF';
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 70 70">
      <g>
        <g>
          <path
            fill={colorSelected}
            d="M19.59,8.2c0-0.73-0.02-1.41,0-2.1c0.18-4.86,5.19-7.62,9.34-5.11c0.7,0.42,1.11,0.41,1.79-0.01
           c2.07-1.28,4.24-1.31,6.33-0.04c0.71,0.43,1.16,0.5,1.91,0.03c2.12-1.31,4.33-1.28,6.44,0.06c0.64,0.41,1.03,0.39,1.67-0.01
           c4.13-2.53,9.18,0.14,9.41,5.03c0.14,3.05,0.09,6.11,0.01,9.16c-0.06,2.3-1.15,4.12-3.2,5.21c-0.75,0.4-0.89,0.87-0.88,1.61
           c0.03,3.69,0.01,7.38,0.01,11.07c0,3.22-1.11,5.93-3.46,8.18c-0.42,0.4-0.66,1.17-0.66,1.77c-0.04,8.43-0.03,16.86-0.03,25.29
           c0,0.54,0,1.07,0,1.67c-9.16,0-18.17,0-27.4,0c0-0.53,0-1.05,0-1.58c0-7.47,0.02-14.94-0.04-22.42c-0.01-0.73-0.37-1.57-0.84-2.15
           c-1.11-1.38-2.36-2.66-3.58-3.95c-2.05-2.16-3.01-4.72-3.01-7.68c0.01-5.7,0-11.39,0-17.09c0-4.02,1.69-6.03,5.65-6.73
           C19.19,8.39,19.31,8.31,19.59,8.2z M23.26,17.07c0.55,0,1.04,0,1.52,0c2.23,0,4.47,0.05,6.7-0.03c2.46-0.08,4.23-1.9,4.36-4.39
           c-0.17-0.03-0.34-0.1-0.51-0.1c-5.24-0.01-10.48-0.02-15.72,0c-1.5,0.01-2.06,0.64-2.06,2.26c-0.01,5.88-0.01,11.76,0,17.64
           c0,1.76,0.62,3.28,1.83,4.58c1.59,1.7,3.12,3.45,4.74,5.11c0.69,0.71,0.93,1.46,0.92,2.44c-0.04,6.56-0.02,13.13-0.02,19.69
           c0,0.53,0,1.05,0,1.6c6.47,0,12.74,0,19.04,0c0-7.4,0-14.71,0-22.14c-1.37,0-2.66,0-3.99,0c0-1.37,0-2.62,0-3.96
           c0.47-0.03,0.87-0.06,1.27-0.07c4.58-0.04,6.96-2.45,6.97-7.04c0-3.1-0.09-6.2,0.04-9.3c0.07-1.77-0.2-3.12-2.19-3.56
           c-3.48,2.12-5.39,1.95-9.01-0.88c-1.97,1.8-4.39,2.39-7.02,2.28c1.18,4.57,0.4,9.04,0.66,13.45c-1.54,0-2.79,0-4.16,0
           c0-2.92,0-5.74,0-8.55c0-3.41-0.26-3.81-3.36-5.08C23.26,19.77,23.26,18.51,23.26,17.07z M40.12,10.58c0,1.41-0.02,2.82,0.01,4.23
           c0.03,1.44,0.77,2.25,2,2.25c1.16,0,2.03-0.78,2.05-2.09c0.05-2.91,0.06-5.82,0-8.73c-0.03-1.28-0.96-2.1-2.09-2.07
           c-1.12,0.03-1.93,0.9-1.96,2.18C40.1,7.76,40.12,9.17,40.12,10.58z M48.33,10.6c0,1.46-0.03,2.91,0.01,4.37
           c0.03,1.26,0.76,2.03,1.88,2.09c1.17,0.07,2.13-0.7,2.16-1.97c0.07-2.96,0.07-5.91,0.01-8.87c-0.03-1.27-0.98-2.1-2.11-2.05
           c-1.12,0.04-1.91,0.91-1.94,2.2C48.31,7.78,48.33,9.19,48.33,10.6z M27.77,8.31c-0.07-1.45,0.48-3.01-1.1-3.82
           c-0.55-0.29-1.58-0.22-2.11,0.13c-1.4,0.91-0.75,2.38-0.83,3.7C25.16,8.31,26.41,8.31,27.77,8.31z M36,8.33
           c-0.1-1.48,0.44-3.02-1.07-3.82c-0.56-0.29-1.58-0.26-2.12,0.07c-1.44,0.89-0.78,2.38-0.88,3.75C33.33,8.33,34.57,8.33,36,8.33z"
          />
        </g>
      </g>
    </svg>
  );
}
