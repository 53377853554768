import { useAuth } from '@/hooks';
import { colors } from '@/theme/colors';
import { useNavigate } from 'react-router-dom';
import { Title } from '../styles';

import { analyticsLinks, dashboardLinks, thermometerLinks } from './pages';
import { Box } from './styles';

export function MapSite() {
  const { authenticated } = useAuth();

  const navigate = useNavigate();

  function goToPage(path: string, authenticated?: boolean) {
    if (authenticated === false) return;

    navigate(path);
  }

  function goToSite(path: string, authenticated?: boolean) {
    if (authenticated === false) return;

    window.location.assign(path);
  }

  return (
    <Box>
      <Title>Mapa do site</Title>

      <div className="svg-box">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 539 824"
        >
          <g className="page" onClick={() => goToPage('/')}>
            <rect
              width="170"
              height="40"
              x="173"
              y="1"
              fill="#1D696F"
              rx="2"
              stroke="#000"
              strokeWidth="2"
            />
            <text
              x="258"
              y="21"
              textAnchor="middle"
              dominantBaseline="central"
              fill="#fff"
            >
              Big Data Social
            </text>
          </g>

          <g
            className={`page ${!authenticated ? 'disabled' : ''}`}
            onClick={() => goToPage('/dashboard/eixos', authenticated)}
          >
            <rect
              width="170"
              height="40"
              x="1"
              y="74"
              fill={authenticated ? colors.green200 : colors.gray500}
              rx="2"
              stroke={authenticated ? colors.black : colors.gray400}
              strokeWidth="2"
            />
            <text
              x="86"
              y="94"
              textAnchor="middle"
              dominantBaseline="central"
              fill="#fff"
            >
              Dashboard
            </text>
          </g>

          <g
            className="page"
            onClick={() =>
              goToPage('/analiticos/auxilios/cartao-mais-infancia')
            }
          >
            <rect
              width="170"
              height="40"
              x="345"
              y="74"
              fill="#1D696F"
              rx="2"
              stroke="#000"
              strokeWidth="2"
            />
            <text
              x="430"
              y="94"
              textAnchor="middle"
              dominantBaseline="central"
              fill="#fff"
            >
              Analíticos
            </text>
          </g>

          {/* Dashboard Tree */}

          {authenticated &&
            dashboardLinks.map((link) => (
              <g
                key={link.path}
                fill={link.fill}
                stroke={link.stroke}
                strokeWidth={2}
                className="page"
                onClick={() =>
                  link.linkType === 'external'
                    ? goToSite(link.path, authenticated)
                    : goToPage(link.path, authenticated)
                }
              >
                <rect width={170} height={40} x={link.x} y={link.y} rx={2} />
                <text
                  fill={link.text.fill}
                  stroke="none"
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={link.text.x}
                  y={link.text.y}
                >
                  {link.text.label}
                </text>
              </g>
            ))}

          {authenticated &&
            thermometerLinks.map((link) => (
              <g
                key={link.path}
                fill={link.fill}
                stroke={link.stroke}
                strokeWidth={2}
                className="page"
                onClick={() =>
                  link.linkType === 'external'
                    ? goToSite(link.path, authenticated)
                    : goToPage(link.path, authenticated)
                }
              >
                <rect width={170} height={40} x={link.x} y={link.y} rx={2} />
                <text
                  fill={link.text.fill}
                  stroke="none"
                  textAnchor="middle"
                  dominantBaseline="central"
                  x={link.text.x}
                  y={link.text.y}
                >
                  {link.text.label}
                </text>
              </g>
            ))}

          {/* Analíticos Tree */}

          {analyticsLinks.map((link) => (
            <g
              key={link.path}
              fill={link.fill}
              stroke={link.stroke}
              strokeWidth={2}
              className="page"
              onClick={() =>
                link.linkType === 'external'
                  ? goToSite(link.path)
                  : goToPage(link.path)
              }
            >
              <rect width={170} height={40} x={link.x} y={link.y} rx={2} />
              <text
                fill={link.text.fill}
                stroke="none"
                textAnchor="middle"
                dominantBaseline="central"
                x={link.text.x}
                y={link.text.y}
              >
                {link.text.label}
              </text>
            </g>
          ))}

          <g transform="translate(80, 41)">
            <path
              fill="#000"
              fillRule="evenodd"
              d="m15.071 25.343-6.364 6.364a1 1 0 0 1-1.414 0L.929 25.343a1 1 0 1 1 1.414-1.414L7 28.586V17h2v11.586l4.657-4.657a1 1 0 0 1 1.414 1.414Z"
              clipRule="evenodd"
            />
            <path
              fill="#000"
              fillRule="evenodd"
              d="M179 16V0h2v16h170v12.586l4.657-4.657a1 1 0 1 1 1.414 1.414l-6.364 6.364a1 1 0 0 1-1.414 0l-6.364-6.364a1 1 0 1 1 1.414-1.414L349 28.586V18H7v-2h172Z"
              clipRule="evenodd"
            />
          </g>

          {authenticated && (
            <g transform="translate(7, 115)">
              <path
                fill="#000"
                fillRule="evenodd"
                d="M0 320V0h2v320H0ZM10.343 44.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 0 1-1.414-1.414L13.586 53H2v-2h11.586l-4.657-4.657a1 1 0 0 1 1.414-1.414ZM10.343 99.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L13.586 108H2v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM10.343 152.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L13.586 161H2v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM10.343 207.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L13.586 216H2v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM10.343 256.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L13.586 265H2v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM10.343 311.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L13.586 320H2v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414Z"
                clipRule="evenodd"
              />
            </g>
          )}

          {authenticated && (
            <g transform="translate(196, 272)">
              <path
                fill="#000"
                fillRule="evenodd"
                d="M37 2H0V0h37v2Z"
                clipRule="evenodd"
              />
              <path
                fill="#000"
                fillRule="evenodd"
                d="M35 532V1h2v531h-2ZM19.657 275.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 267H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414ZM19.657 326.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 318H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414ZM19.657 381.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 373H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414ZM19.657 432.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 424H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414ZM19.657 487.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 479H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414ZM19.657 538.071l-6.364-6.364a1 1 0 0 1 0-1.414l6.364-6.364a1 1 0 1 1 1.414 1.414L16.414 530H35v2H16.414l4.657 4.657a1 1 0 1 1-1.414 1.414Z"
                clipRule="evenodd"
              />
            </g>
          )}

          <g transform="translate(349, 115)">
            <path
              fill="#000"
              fillRule="evenodd"
              d="M.996 480V0H3v480H.996ZM11.343 44.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 0 1-1.414-1.414L14.586 53H3v-2h11.586l-4.657-4.657a1 1 0 0 1 1.414-1.414ZM11.343 99.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 108H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 152.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 161H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 207.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 216H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 256.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 265H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 310.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 319H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 364.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 373H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 416.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 425H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414ZM11.343 471.929l6.364 6.364a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 1 1-1.414-1.414L14.586 480H3v-2h11.586l-4.657-4.657a1 1 0 1 1 1.414-1.414Z"
              clipRule="evenodd"
            />
          </g>
        </svg>
      </div>
    </Box>
  );
}
