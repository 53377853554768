import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import * as R from 'ramda';
import { ActionRegister } from 'big-data';

import { odsDetails } from '@/constants/ods';
import { useAuth } from '@/hooks';

import { Box } from './styles';
import { RegisterIcon } from './RegisterIcon';
import { RegisterModal } from '../RegisterModal';

interface IndicatorCardProps {
  indicator: ActionRegister;
}

export function IndicatorCard({ indicator }: IndicatorCardProps) {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const odsItems = R.map((odId: number) => odsDetails[odId], indicator.ods);

  function handleChangeRegisterModal() {
    setIsRegisterModalOpen(!isRegisterModalOpen);
  }

  const { isAdm } = useAuth();

  const searchParams = new URLSearchParams([['axe', indicator.name]]);
  const linkTo = `${indicator.path}?${searchParams.toString()}`;

  return (
    <>
      <Box color={indicator.color}>
        <header>
          <div className="title">
            <div className="card-icon">
              <img src={indicator.icon} alt="name" />
            </div>
            <div className="card-title">
              <h4>Eixo</h4>
              <span>{indicator.name}</span>
            </div>
          </div>
          <div className="buttons">
            {!isAdm && (
              <Tooltip placement="top" title={'Cadastrar plano de ação'}>
                <button
                  className="register card-btn"
                  onClick={() => handleChangeRegisterModal()}
                >
                  <RegisterIcon />
                </button>
              </Tooltip>
            )}

            <Link className="card-btn" to={linkTo}>
              Ver planos de ação
            </Link>
          </div>
        </header>

        <div className="card-content">
          {odsItems.map((item) => (
            <a
              key={`${indicator.name}_${item.name}`}
              href={item.path}
              rel="noreferrer"
              target="_blank"
              className="ods"
            >
              <img src={item.img} alt={item.name} />
              <h4>{item.name}</h4>
            </a>
          ))}
        </div>
      </Box>

      <RegisterModal
        axe={indicator.name}
        isOpen={isRegisterModalOpen}
        onClose={handleChangeRegisterModal}
      />
    </>
  );
}
