export function removeSpecialCharAndAddUnderscore(str: string) {
  return str
    .replace('é', 'e')
    .replace('ç', 'c')
    .replace(/[^\w]+/g, '_')
    .replace(/(_$)/, '');
}

export const cpfReplacer = (value: string, type: "normal" | "secret") => {

  const valueReplaced = value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+$/, '$1');

  if(type === "normal") return valueReplaced

  return valueReplaced.substring(0, 4).concat('***.***').concat(valueReplaced.substring(11))
};

export function isCPFFormat(cpf: string) {
  const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  return cpfRegex.test(cpf);
}


export function capitalize(value: string) {
  if (!!value) {
    return value
      .replace('  ', ' ')
      .split(' ')
      .map((v) => {
        if (v.length > 2) return v[0].toUpperCase() + v.slice(1).toLowerCase();
        return v.toLowerCase();
      })
      .join(' ');
  }

  return value;
}

export function ellipsisWord(value: string, size = 13) {
  const words = value.split(' ');

  if (words.length === 3 && value.length >= size) {
    return `${words[0][0]}. ${words[1]} ${words[2]}`;
  }

  if (value.length >= size) {
    return `${value.slice(0, size - 1)}...`;
  }

  return value;
}

export function dotsNumber(value: number) {
  const { format } = new Intl.NumberFormat('pt-BR');

  return format(value);
}

export function percentageFormatter(value = 0, betweenZeroAndOne = false) {
  let newValue = value;

  if (!betweenZeroAndOne) {
    newValue /= 100;
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(newValue);
}
