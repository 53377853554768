import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `condicoes-moradia-acoes`;
  async function getActions() {
    const path = `${resource}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data;
  }
  return { getActions };
};

export const livingConditionsAction = service();
