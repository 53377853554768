import { Card, RankingDashboard } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useJobIncome } from '@/services/lgbt/jobIncome';
import { colors } from '@/theme/colors';
import { getDashboardRanking } from '@/utils/data';
import { Data, RankingModel } from 'big-data';
import { useEffect } from 'react';
import preconceito from '../../../assets/icons/preconceito.svg';
import inclusao from '../../../assets/icons/inclusao.svg';
import ausenciaQualificacao from '../../../assets/icons/ausenciaQualificacao.svg';
import baixaInstrucao from '../../../assets/icons/baixaInstrucao.svg';

const rankingModel = new Map<string, RankingModel>([
  [
    'preconceito das organizações e empresas em contratar pessoas lgbt+',
    {
      description: () => 'relataram ',
      img: <img src={preconceito} alt="Icon de preconceito" />,
      name: 'preconceito das organizações e empresas em contratar pessoas LGBTQIA+',
      key: 'preconceito'
    }
  ],
  [
    'falta de políticas públicas para a inclusão dessa população no mercado de trabalho formal',
    {
      description: () => 'relataram ',
      img: <img src={inclusao} alt="Icon de inclusao" />,
      name: 'falta de políticas públicas para a inclusão dessa população no mercado de trabalho formal',
      key: 'inclusao'
    }
  ],
  [
    'ausência de qualificação profissional',
    {
      description: () => 'relataram ',
      img: (
        <img
          src={ausenciaQualificacao}
          alt="Icon de ausencia de qualificacao"
        />
      ),
      name: 'ausência de qualificação profissional',
      key: 'ausencia_qualificacao'
    }
  ],
  [
    'baixo grau de instrução',
    {
      description: () => 'relataram ',
      img: <img src={baixaInstrucao} alt="Icon de Baixa Instrução" />,
      name: 'baixo grau de instrução',
      key: 'baixaInstrucao'
    }
  ]
  // [
  //   '',
  //   {
  //     description: () => 'das crianças estão matriculadas em',
  //     img: <NoProgramIcon />,
  //     name: ' nenhum período',
  //     key: 'não',
  //     color: '#F53C3E'
  //   }
  // ]
]);

export function JobChallenges() {
  const { city, orientation, ibge, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useJobIncome.jobChallenges({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, ibge, orientation, identity]);

  const rankingData = getDashboardRanking(data, rankingModel);

  return (
    <Section>
      <Card
        title="Desafios enfrentados pela população LGBTQIA+ para entrar no mercado de trabalho, segundo as pessoas entrevistadas"
        loading={loading}
        description="Como uma mesma pessoa pode responder mais de uma vez, a soma total dos percentuais é superior a 100%."
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <RankingDashboard
          color={colors.ciano700}
          data={rankingData}
          loadingBenefits={loading}
          selected={undefined}
          calculatedOut
        />
      </Card>
    </Section>
  );
}
