import { ThemeBase } from './ThemeBase';
import { colors } from './colors';
import { Theme } from 'big-data/theme';

export const ThemeLight: Theme = {
  ...ThemeBase,

  darkMode: false,

  text: colors.black,
  background: colors.gray300
};
