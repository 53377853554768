import { useAuth } from '@/hooks';
import { oauth2Agent } from '@/services/auth';
import { useTheme } from 'styled-components';
import inIcon from '../../../assets/icons/in.svg';
import outIcon from '../../../assets/icons/out.svg';
import { DesktopButton, MobileButton } from './styles';

export function LoginButton() {
  const theme = useTheme();
  const { authenticated } = useAuth();
  const { login, logout } = oauth2Agent;

  const isMobile = theme.screen.medium || theme.screen.small;

  function handleLoginButtonClick() {
    login();
  }

  if (isMobile) {
    return authenticated ? (
      <MobileButton
        className="sair"
        onClick={() =>
          logout({ redirectUrl: process.env.REACT_APP_REDIRECT_URL })
        }
      >
        <img src={outIcon} alt="icon de sair" />
        <span>Sair</span>
      </MobileButton>
    ) : (
      <MobileButton className="entrar" onClick={handleLoginButtonClick}>
        <img src={inIcon} alt="icon de entrar" />
        <span>Entrar</span>
      </MobileButton>
    );
  }

  return (
    <DesktopButton>
      {authenticated ? (
        <button
          className="sair"
          onClick={() =>
            logout({ redirectUrl: process.env.REACT_APP_REDIRECT_URL })
          }
        >
          <img src={outIcon} alt="icon de sair" />
          <span>Sair</span>
        </button>
      ) : (
        <button className="entrar" onClick={handleLoginButtonClick}>
          <img src={inIcon} alt="icon de entrar" />
          <span>Entrar</span>
        </button>
      )}
    </DesktopButton>
  );
}
