import { useEffect } from 'react';
import { Sanitation } from 'big-data';

import { Card, SanitaryCard } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { cmic } from '@/services';

import { GroupBox } from './styles';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function SanitaryInfo() {
  const { ibge, city } = useAnalyticsFilter();
  const { data, loading, error, request } = useService<Sanitation>();

  useEffect(() => {
    request(() => cmic.getSanitation(ibge ? Number(ibge) : undefined));
  }, [request, ibge]);

  const total = data.reduce((acc, cur) => acc + cur.value, 0);
  const percentageData = data.map(({ value, ...d }, i) => ({
    ...d,
    id: i,
    value: (value * 100) / total
  }));

  return (
    <Section>
      <Card
        title="Porcentagem de famílias com abastecimento de água e acesso a esgotamento sanitário"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ? city : 'Ceará']}
      >
        <GroupBox>
          {percentageData.map((d) => (
            <SanitaryCard key={d.id} data={d} />
          ))}
        </GroupBox>
      </Card>
    </Section>
  );
}
