import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { Campaign } from './Campaign';
import { Daily } from './Daily';
import { DataUsage } from './DataUsage';
import { LgbtPhobiaComplaint } from './LgbtPhobiaComplaint';
import { LgbtPhobiaTypes } from './LgbtPhobiaTypes';
import { PopulationFear } from './PopulationFear';
import { ProfessionalPreparation } from './ProfessionalPreparation';
import { ReasonNoComplaint } from './ReasonNoComplaint';
import { SecurityLevel } from './SecurityLevel';
import { Box } from './style';

export function LgbtPhobiaData() {
  return (
    <Box>
      <ThirdTitle>LGBTfobia e Violência contra a população LGBTQIA+</ThirdTitle>
      <GroupBlockBox>
        <Daily />
        <LgbtPhobiaTypes />
      </GroupBlockBox>
      <GroupBlockBox>
        <Campaign />
        <ReasonNoComplaint />
      </GroupBlockBox>
      <GroupBlockBox>
        <ProfessionalPreparation />
        <PopulationFear />
      </GroupBlockBox>
      <GroupBlockBox>
        <SecurityLevel />
        <LgbtPhobiaComplaint />
      </GroupBlockBox>
      <GroupBlockBox>
        {/* <SugestionQuiz /> */}
        <DataUsage />
      </GroupBlockBox>
    </Box>
  );
}
