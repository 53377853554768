import dayjs from 'dayjs';
import * as R from 'ramda';
import { Feedback } from 'big-data';

const DATE_FORMAT = 'YYYY-MM-DD';

export const groupByDate = R.groupBy((data: Feedback) => {
  const createdAt = String(data.createdAt).split('T')[0];
  const now = dayjs().format(DATE_FORMAT);
  const isSame = now === createdAt;
  return isSame
    ? 'Hoje'
    : dayjs(data.createdAt).format('D [de] MMMM [de] YYYY');
});

export function dateMonthInterval(monthInterval: number) {
  const datePassed = new Date(
    new Date().setMonth(new Date().getMonth() - monthInterval)
  );
  return dayjs(datePassed).format(DATE_FORMAT);
}

export function dateFormatter(date?: string) {
  if (!date) return '';

  return dayjs(date).format(DATE_FORMAT);
}
