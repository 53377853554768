import { useMemo } from 'react';

export function usePagination<T>(data: T[], page: number, pageSize = 5) {
  const dataPaginated = useMemo(
    () => data.slice((page - 1) * pageSize, page * pageSize),
    [data, page, pageSize]
  );
  const totalPages = Math.ceil(data.length / pageSize);
  return { dataPaginated, totalPages, pageSize };
}
