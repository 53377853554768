import { Data } from 'big-data';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Label, LabelMain, Text } from '@/components/styled';

import { getCityRecord } from './data';
import { createSVG, createMap, Path, onHover } from './map';
import { MapColors, legendByColor } from './colors';

import { Box, LegendText } from './styles';
import { useTheme } from 'styled-components';
import { capitalize } from '@/utils/string';

interface D3MapMacroregionsProps {
  data: Data[];
  color: keyof typeof MapColors;
  width?: number;
  intervalCostume?: { min: number; max: number }[];
  label?: string;
}

const DARKMODE_KEY_COLOR = `#FFCA15`;

export function D3MapMacroregions({
  data,
  color,
  width = 300,
  intervalCostume,
  label
}: D3MapMacroregionsProps) {
  const [map, setMap] = useState<Path>();
  const [selectedCity, setSelectedCity] = useState<Data>();

  const boxRef = useRef<HTMLDivElement>(null);

  const cityRecord = useMemo(() => getCityRecord(data), [data]);
  const {
    screen: { small },
    darkMode
  } = useTheme();
  const trueColor = darkMode ? DARKMODE_KEY_COLOR : color;

  useEffect(() => {
    const { current } = boxRef;

    if (!current) return;

    const i = intervalCostume?.map((d) => d.min);
    const svg = createSVG(current, width);
    setMap(createMap(svg, cityRecord, trueColor, i ?? []));

    return () => {
      setMap(undefined);
      svg.remove();
    };
  }, [boxRef, cityRecord, trueColor, width, intervalCostume]);

  useEffect(() => {
    if (map) {
      onHover(map, (d) => setSelectedCity(d));
    }
  }, [map, trueColor]);

  const legendItems = useMemo(
    () => legendByColor(data, trueColor, intervalCostume),
    [data, trueColor, intervalCostume]
  );

  const isSelected = (min: number, max: number) => {
    if (selectedCity === undefined) return undefined;
    if (selectedCity.value >= max && min === max) return true;
    return selectedCity.value >= min && selectedCity.value <= max;
  };

  const labelValue = selectedCity?.value !== 1 ? `${label}s` : label;

  return (
    <Box ref={boxRef}>
      <div className="right-map-content">
        <LabelMain align={small ? 'left' : 'right'}>
          <Label selected={selectedCity ? !!selectedCity : undefined}>
            <span className="label">{selectedCity?.name}</span>
            <span className="value">
              {selectedCity?.value}{' '}
              {label ? capitalize(labelValue as string) : 'Sedes'}
            </span>
          </Label>
        </LabelMain>

        <Text className="tips">
          Passe o mouse pelo mapa para visualizar a quantidade de{' '}
          {label ?? 'sedes'} por macrorregião
        </Text>

        <div className="legend">
          <h3>LEGENDA</h3>
          <ul>
            {legendItems.map((item, i) => (
              <li key={item.color}>
                <LegendText
                  $stroke={i === 0}
                  color={item.color.toString()}
                  selected={isSelected(
                    Number(item.min.replace('.', '')),
                    Number(item.max.replace('.', ''))
                  )}
                >
                  {item.min === item.max
                    ? `> ${item.min}`
                    : `${item.min} - ${item.max}`}
                </LegendText>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}
