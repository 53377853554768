import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const BoxList = styled.ul`
  list-style: none;

  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;

    &:hover {
      background-color: #555;
    }
  }

  .intersection {
    height: 14px;
  }

  .empty {
    min-height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.gray500};
    text-align: center;
  }
`;
