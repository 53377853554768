import { useEffect } from 'react';
import { Card } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
interface WaterSupplyProps {
  color: string;
}

export function WaterSupply({ color }: WaterSupplyProps) {
  const { data, loading, error, request } = useService<Data>();
  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getWaterSupply(city, zone));
  }, [request, city, zone]);

  return (
    <Section>
      <Card
        title="Tipo de abastecimento de água das moradias"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <HorizontalBarSimple data={data} isPercentage color={color} sizeRatio={0.7} maxValue={5} />
      </Card>
    </Section>
  );
}
