import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { colors } from '@/theme/colors';

interface ILink {
  color: string;
  isActive?: boolean;
}

const linkStyle = (color: string) => css`
  align-items: center;

  text-decoration: none;
  text-transform: uppercase;
  color: ${color};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: bold;
  font-style: normal;

  ${({ theme }) =>
    theme.darkMode &&
    css`
      color: ${theme.yellowDark} !important;
      text-decoration: underline;
    `}
`;

export const ExternalLink = styled.a<ILink>`
  transition: color 0.2s;

  ${({ color }) => linkStyle(color)}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${colors.green200};
    `};
`;

export const InternalLink = styled(Link)<ILink>`
  ${({ color }) => linkStyle(color)}
`;
