export function QuestionIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9152 4.98913C12.5413 5.69348 12.8348 6.65217 12.6978 7.66957C12.463 9.54783 11.0935 10.213 9.88043 10.213C9.82174 10.213 9.78261 10.213 9.78261 10.213V10.6826C9.78261 11.113 9.43043 11.4652 9 11.4652C8.56956 11.4652 8.21739 11.113 8.21739 10.6826V10.0565C8.21739 9.41087 8.4913 8.64783 9.88043 8.64783C10.6435 8.64783 11.0348 8.25652 11.1326 7.47391C11.1717 7.21957 11.1913 6.53478 10.7217 6.02609C10.35 5.61522 9.74348 5.4 8.92174 5.4C7.16087 5.4 7.10217 6.55435 7.10217 6.67174C7.10217 7.10217 6.75 7.45435 6.31956 7.45435C5.88913 7.45435 5.53696 7.10217 5.53696 6.67174C5.53696 5.88913 6.14348 3.83478 8.92174 3.83478C10.5457 3.83478 11.4457 4.46087 11.9152 4.98913ZM8.9413 12.1304C8.68696 12.1304 8.43261 12.2283 8.25652 12.4239C8.08043 12.6 7.96304 12.8543 7.96304 13.1087C7.96304 13.363 8.06087 13.6174 8.25652 13.7935C8.43261 13.9696 8.68696 14.087 8.9413 14.087C9.19565 14.087 9.45 13.9891 9.62609 13.7935C9.80217 13.6174 9.91957 13.363 9.91957 13.1087C9.91957 12.8543 9.82174 12.6 9.62609 12.4239C9.45 12.2478 9.21522 12.1304 8.9413 12.1304ZM18 9C18 13.9696 13.9696 18 9 18C4.03043 18 0 13.9696 0 9C0 4.03043 4.03043 0 9 0C13.9696 0 18 4.03043 18 9ZM16.4348 9C16.4348 4.8913 13.1087 1.56522 9 1.56522C4.8913 1.56522 1.56522 4.8913 1.56522 9C1.56522 13.1087 4.8913 16.4348 9 16.4348C13.1087 16.4348 16.4348 13.1087 16.4348 9Z"
        fill="white"
      />
    </svg>
  );
}
