import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';
import { CardBase } from './Card';

export const TabFilterBox = styled(CardBase)`

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 54px;

  .tabs-box-nav {
    width: 60%;
  }

  @media (max-width: 1380px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;
    align-items: flex-start;
    .tabs-box-nav {
      width: 100%;
      height: 100%;
    }
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;
    height: auto;
    padding: 1rem 0;

    .tabs-box-nav {
      width: 100%;
      height: 100%;
    }
  }
`;
