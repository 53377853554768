import { mainApi } from './config/api';
import { callService } from './config/service';

export interface ManagerLoginProps {
  ratingDate: string | null;
  numberOfLogins: number;
}

const service = () => {
  const resource = `/manager/login`;

  async function getLoginCount() {
    const path = resource;
    const response = await callService(() =>
      mainApi.get<ManagerLoginProps>(path)
    );
    return response.data;
  }

  return {
    getLoginCount
  };
};

export const loginCount = service();
