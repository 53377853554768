import { Text } from '@/components/styled';
import { colors } from '@/theme/colors';
import React, { ReactNode } from 'react';
import { H4, Loading } from '..';
import {
  Box,
  ContentBox,
  MainBox,
  SecondaryBox,
  TagBox,
  ErrorText,
  EmptyText
} from './style';

interface CardProps {
  title?: JSX.Element | string;
  tags?: string[];
  empty?: boolean;
  loading?: boolean;
  error?: any;
  children?: ReactNode;
  noMargin?: boolean;
  description?: string;
  date?: string;
  subtitle?: string;
  noPadding?: boolean;
}

export function Card({
  title,
  tags,
  empty,
  loading,
  error,
  date,
  children,
  description,
  subtitle,
  noPadding = false
}: CardProps) {
  const renderTitle = () => {
    if (typeof title === 'string')
      return (
        <H4 className="title" color={colors.ciano700} mb={subtitle ? '0' : ''}>
          {title}
        </H4>
      );

    if (title === undefined) return null;

    return title;
  };

  const renderSubtitle = () => {
    return <span className="subtitle">{subtitle}</span>;
  };

  const renderContent = () => {
    if (error) {
      return (
        <SecondaryBox>
          <ErrorText>Falha ao carregar informações</ErrorText>
        </SecondaryBox>
      );
    }

    if (loading) {
      return (
        <SecondaryBox>
          <Loading />
        </SecondaryBox>
      );
    }

    if (empty) {
      return (
        <SecondaryBox>
          <EmptyText>Informações não encontradas</EmptyText>
        </SecondaryBox>
      );
    }

    return (
      <MainBox>
        <TagBox className='tag-box'>
          <span className="tags">
            {tags?.map((t, i) => (
              <React.Fragment key={t}>
                {t}
                {i !== tags.length - 1 && tags[i + 1] !== '' && ' / '}
              </React.Fragment>
            ))}
          </span>
          {date && <span>Dados de {date}</span>}
        </TagBox>
        {description && <Text>{description}</Text>}

        {children}
      </MainBox>
    );
  };

  return (
    <Box $noPadding={noPadding}>
      {renderTitle()}
      {subtitle && renderSubtitle()}
      <ContentBox className="card-content-box">{renderContent()}</ContentBox>
    </Box>
  );
}
