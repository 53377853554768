import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  /* height: 95%; */
  justify-content: center;
  align-items: center;
  text-align: left;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  p {
    padding-top: 0.3rem;
    max-width: 300px;
    line-height: 1.3;
    font-size: 1rem;
    color: #fff;

    @media (max-width: 700px) {
      padding: 0.3rem;
      text-align: center;
      max-width: none;
      font-size: 0.96rem;
    }
  }

  .benefits {
    flex: 1;

    ${mediaQuery.mediumScreen} {
      padding-right: 10px;
      padding-bottom: 20px;
    }

    @media (max-width: 700px) {
      padding: 5px;
    }

    & + .benefits {
      padding-top: 40px;

      @media (max-width: 700px) {
        padding-top: 0;
      }
    }
  }
`;
