import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

const darkModeBox = css`
  background: #000;

  .information-title,
  .information-about {
    color: #fff;
  }

  ul {
    li {
      p {
        color: #fff;

        span,
        strong {
          color: #fff;
        }
      }

      img {
        filter: brightness(0) invert(1);
      }

      svg path {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
`;

interface BoxProps {
  hasCircle?: boolean;
}

const iconStyle = css`
  display: flex;
`;

export const Box = styled.div<BoxProps>`
  padding: 2rem;
  max-width: 320px;
  width: 100%;

  .information-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: ${({ color, theme }) => color ?? theme.greenDefault};

    margin-bottom: 25px;
  }

  .information-about {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;

    color: ${({ color, theme }) => color ?? theme.greenDefault};

    margin-bottom: 20px;
  }

  ul {
    list-style: ${(props) => (props.hasCircle ? 'none' : 'initial')};
    li {
      ${(props) => (props.hasCircle ? iconStyle : '')};
      margin-left: ${(props) => (props.hasCircle ? 0 : 1)}rem;
      margin-bottom: 0.5rem;
      img,
      svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
        margin-top: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 24px;

        color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
        text-align: left;
      }
    }
  }

  ${(props) => props.theme.darkMode && darkModeBox}

  @media (max-width: 1240px) {
    max-width: 100%;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;

      li {
        & + li {
          margin-top: 0;
        }
      }
    }
  }
`;
