import { AxeEnum } from "./axes";

interface Indicator {
  label: string;
  value: string;
}

export const indicatorsBySearchParams: Record<string, Indicator[]> = {
  [AxeEnum.INSEGURANCA_ALIMENTAR]: [
    {
      value: 'FORA DE INSEGURANÇA ALIMENTAR',
      label: 'Fora de Insegurança Alimentar'
    },
    {
      value: 'INSEGURANÇA ALIMENTAR LEVE',
      label: 'Insegurança Alimentar Leve'
    },
    {
      value: 'INSEGURANÇA ALIMENTAR MODERADA',
      label: 'Insegurança Alimentar Moderada'
    },
    {
      value: 'INSEGURANÇA ALIMENTAR GRAVE',
      label: 'Insegurança Alimentar Grave'
    }
  ],
  [AxeEnum.EDUCACAO]: [
    {
      value: 'PRÉ-ESCOLA',
      label: 'Crianças não matriculadas em pré-escola'
    },
    {
      value: 'ESCOLA',
      label: 'Crianças não matriculadas em escola'
    },
    {
      value: 'CRECHE',
      label: 'Crianças não matriculadas em creche'
    }
  ],
  [AxeEnum.ASSISTENCIA_SOCIAL]: [
    { value: 'CRAS', label: 'Famílias não acompanhadas pelos CRAS' },
    { value: 'CREAS', label: 'Famílias não acompanhadas pelos CREAS' }
  ],
  [AxeEnum.SAUDE]: [
    {
      value: 'GESTANTES SEM ACOMPANHAMENTO PRÉ-NATAL',
      label: 'Gestantes sem acompanhamento pré-natal'
    },
    {
      value: 'CRIANÇAS SEM ACOMPANHAMENTO DE EQUIPE DE SAÚDE',
      label: 'Crianças sem acompanhamento de equipe de saúde'
    },
    {
      value: 'CRIANÇAS COM ESQUEMA VACINAL INCOMPLETO',
      label: 'Crianças com esquema vacinal incompleto'
    }
  ],
  [AxeEnum.TRABALHO_RENDA]: [
    {
      value: 'Representantes familiares sem trabalho remunerado',
      label: 'Representantes familiares sem trabalho remunerado'
    },
    {
      value: 'Representantes familiares que possuem até meio salário mínimo',
      label: 'Representantes familiares que possuem até meio salário mínimo'
    },
    {
      value:
        'Representantes familiares que não realizaram cursos de capacitação',
      label:
        'Representantes familiares que não realizaram cursos de capacitação'
    },
    {
      value:
        'Representantes familiares que têm interesse em realizar curso de capacitação',
      label:
        'Representantes familiares que têm interesse em realizar curso de capacitação'
    },
    {
      value:
        'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância',
      label:
        'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância'
    }
  ],
  [AxeEnum.MORADIA]: [
    {
      value: 'Famílias que não possuem água canalizada em pelo menos 1 cômodo',
      label: 'Famílias que não possuem água canalizada em pelo menos 1 cômodo'
    },
    {
      value: 'Famílias que não possuem banheiro em suas residências',
      label: 'Famílias que não possuem banheiro em suas residências'
    },
    {
      value: 'Famílias que não possuem acesso à coleta seletiva',
      label: 'Famílias que não possuem acesso à coleta seletiva'
    },
    {
      value:
        'Famílias que não possuem lugares públicos para brincar próximo às residências',
      label:
        'Famílias que não possuem lugares públicos para brincar próximo às residências'
    },
    {
      value:
        'Famílias que não possuem atividades culturais próximo às residências',
      label:
        'Famílias que não possuem atividades culturais próximo às residências'
    },
    {
      value:
        'Famílias que não possuem atividades esportivas próximo às residências',
      label:
        'Famílias que não possuem atividades esportivas próximo às residências'
    },
    {
      value: 'Famílias que estão localizadas em área de conflito/violência',
      label: 'Famílias que estão localizadas em área de conflito/violência'
    }
  ]
};
