import { useEffect, useMemo, useState } from 'react';
import { Card, InfoCard } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { Data } from 'big-data';

import { Box } from './HouseInfo.styles';

import houseIcon from '@/assets/icons/comodo.svg';
import collectiveIcon from '@/assets/icons/coletivo.svg';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

const labels: Record<string, string> = {
  coletivo: 'são cômodos coletivos',
  'particular improvisado': 'são cômodos particulares improvisados',
  'particular permanente': 'são cômodos particulares permanentes'
};
const images: Record<string, string> = {
  coletivo: collectiveIcon,
  'particular improvisado': 'IMP.',
  'particular permanente': `PER.`
};

export function HouseInfo() {
  const [average, setAverage] = useState(0);
  const [averageLoading, setAverageLoading] = useState(false);
  const [averageError, setAverageError] = useState<Error | null>(null);

  const { data, loading, error, request } = useService<Data>();

  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getRooms(city, zone));
  }, [request, city, zone]);

  useEffect(() => {
    setAverageLoading(true);
    livingCondition
      .getRoomsAverage(city)
      .then((data) => setAverage(data))
      .catch((err) => setAverageError(err))
      .finally(() => setAverageLoading(false));
  }, [city]);

  const total = useMemo(
    () => data.reduce((acc, cur) => acc + cur.value, 0),
    [data]
  );

  const percentageData = useMemo(
    () =>
      data
        .map(({ name, value }) => ({
          name: name.toLowerCase(),
          value: (value * 100) / total
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [data, total]
  );

  return (
    <Section>
      <Card
        title="Média e tipos de cômodos das residências"
        empty={data.length === 0}
        loading={averageLoading || loading}
        error={averageError ?? error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <Box>
          <InfoCard
            label="é a média de cômodos existentes nas residências dos inscritos"
            value={average}
            image={houseIcon}
            float={1}
          />

          {percentageData.map((d) => (
            <InfoCard
              key={d.name}
              label={labels[d.name]}
              value={d.value}
              image={d.name.includes('coletivo') ? images[d.name] : undefined}
              suffix="%"
              textIcon={images[d.name]}
            />
          ))}
        </Box>
      </Card>
    </Section>
  );
}
