import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as locale from 'dayjs/locale/pt-br';

dayjs.extend(relativeTime);
dayjs.locale(locale);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
