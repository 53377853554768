import { colors } from '@/theme/colors';
import { Tooltip } from 'antd';
import { Action } from 'big-data';
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillQuestionCircle
} from 'react-icons/ai';

import { Box } from './styles';

interface GoalsTagProps {
  action: Action;
}

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  maximumFractionDigits: 2
});

const getInfo = (goal: number, polarity: boolean, value?: number) => {
  if (!value) {
    return {
      text: 'Analisando valores do plano de ação. Aguarde até um dia útil',
      icon: <AiFillQuestionCircle color={colors.gray500} />,
      color: colors.gray500,
      label: 'Calculando...'
    };
  }

  const isCompleted = polarity ? value >= goal : value <= goal;
  const formattedGoal = formatter.format(goal / 100);
  const formattedValue = formatter.format(value / 100);
  const polarityText = polarity
    ? 'Melhora quando aumenta'
    : 'Melhora quando diminui';

  return {
    text: (
      <p>
        Valor atual: {formattedValue} <br />
        Meta: {formattedGoal} <br />
        {polarityText}
      </p>
    ),
    icon: isCompleted ? (
      <AiFillCheckCircle color="#55A546" />
    ) : (
      <AiFillCloseCircle color="#D4282A" />
    ),
    color: isCompleted ? '#55A546' : '#D4282A',
    label: isCompleted ? 'Meta concluída' : 'Meta não concluída'
  };
};

export function GoalsTag({ action }: GoalsTagProps) {
  const { goal, polarity, actualValue } = action;

  const goalsInfo = getInfo(goal, polarity, actualValue);

  return (
    <Tooltip
      title={goalsInfo.text}
      color={goalsInfo.color}
      overlayInnerStyle={{
        width: '220px'
      }}
    >
      <Box>
        {goalsInfo.icon}
        <span>{goalsInfo.label}</span>
      </Box>
    </Tooltip>
  );
}
