import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;

  gap: 2rem;

  .ranking-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;

    margin: 0 1rem;

    .ranking-text-box {
      text-align: center;
      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#4c5469`)};

      p + p {
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }
`;

export const ImgColor = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ color, theme }) =>
    theme.darkMode ? colors.white : color};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    width: 60%;
    height: 60%;

    filter: ${({ theme }) => (theme.darkMode ? `invert(1)` : '')};
  }
`;
