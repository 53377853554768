import styled from 'styled-components';

export const Box = styled.section`
  .dashboard-card-box {
    min-height: 340px;
  }
  .left-cards {
    @media (max-width: 1240px) {
      grid-area: card;
    }
  }
`;
