import { useDashboardFilter } from '@/hooks';
import { education, EducationResolver } from '@/services';
import { DataIbge } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

function groupData(data: EducationResolver) {
  const name = data.city ?? '';
  const codIbge = data.codIbge ?? '';

  return {
    value: data.value,
    filter: data.schoolMode,
    name,
    codIbge
  };
}

export function useCityData(programName?: string, percentualTerms = true) {
  const [cities, setCities] = useState<DataIbge[]>([]);
  const [citiesError, setCitiesError] = useState<Error | null>(null);
  const [citiesLoading, setCitiesLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    if (!!programName) {
      setCitiesLoading(true);
      education
        .getCities({ filter: programName, ibge, year, semester, zone })
        .then((data) => {
          const sortedData = [...data].sort((a, b) => b.value - a.value);
          setCities(sortedData);
        })
        .catch((error) => setCitiesError(error))
        .finally(() => setCitiesLoading(false));
    }
  }, [programName, ibge, percentualTerms, year, semester, zone]);

  useEffect(() => {
    if (!programName) {
      setCitiesLoading(true);

      const requests = [
        education.getNursery,
        education.getPreSchool,
        education.getSchool
      ];

      Promise.all(
        requests.map((request) =>
          request({ hasFilter: true, ibge, semester, year, zone })
        )
      )
        .then((responses) => {
          const [nursery, preSchool, school] = responses;

          const dataInMapFormat = {
            creche: nursery.map((n) => groupData(n)),
            'pré-escola': preSchool.map((n) => groupData(n)),
            escola: school.map((n) => groupData(n))
          };

          const educationFrequency = [
            ...dataInMapFormat.creche,
            ...dataInMapFormat['pré-escola'],
            ...dataInMapFormat.escola
          ];

          const educationByCity = educationFrequency.reduce((acc, cur) => {
            const {
              filter: curFilter,
              name: curName,
              codIbge: curCodIbge
            } = cur;

            const currentFilterLowerCase = curFilter
              ? curFilter.toLowerCase()
              : '';
            const currentCityWithIbge = `${curName}_${curCodIbge}`;

            if (!!acc[currentCityWithIbge]) {
              const value = cur.value ?? 0;

              if (!!acc[currentCityWithIbge][currentFilterLowerCase])
                acc[currentCityWithIbge][currentFilterLowerCase] += value;
              else acc[currentCityWithIbge][currentFilterLowerCase] = value;

              return acc;
            } else {
              return {
                ...acc,
                [currentCityWithIbge]: {
                  ...acc[currentCityWithIbge],
                  [currentFilterLowerCase]:
                    Number(acc[currentFilterLowerCase] ?? 0) + cur.value
                }
              };
            }
          }, {} as Record<string, Record<string, number>>);

          const parsed = Object.entries(educationByCity).map(
            ([key, valueObj]) => {
              const noFrequencyValue = valueObj['não'] ?? 0;
              const total = Object.values(valueObj).reduce(
                (acc, cur) => acc + cur,
                0
              );

              const value = percentualTerms
                ? (noFrequencyValue / total) * 100
                : noFrequencyValue;

              return {
                name: key.split('_')[0],
                codIbge: key.split('_')[1],
                value
              };
            }
          );

          setCities(parsed);
        })
        .finally(() => setCitiesLoading(false));
    }
  }, [programName, ibge, percentualTerms, year, semester, zone]);

  return useMemo(
    () => ({
      cities,
      citiesError,
      citiesLoading
    }),
    [cities, citiesError, citiesLoading]
  );
}
