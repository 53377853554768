import { useEffect, useState } from 'react';
import { Alert, notification } from 'antd';
import * as R from 'ramda';

import { actions } from '@/services';

import { Box } from './styles';
import { RegisterForm } from '../RegisterForm';
import { IndicatorGroup } from '../IndicatorGroup';
import { Link, useSearchParams } from 'react-router-dom';
import { Action, ActionCreation } from 'big-data';
import { Loading } from '@/components/shared';

interface SubmitData {
  title: string;
  polarity: number;
  file?: {
    file: File;
    fileList: FileList;
  };
  goal: string;
  time: string;
  description: string;
}

interface RegisterModalProps {
  axe?: string;
  isOpen: boolean;
  onClose?: () => void;
  onRegistered?: () => void;
}

const isNotNil = R.complement(R.isNil);

export function RegisterModal({
  axe,
  isOpen,
  onClose,
  onRegistered
}: RegisterModalProps) {
  const [indicator, setIndicator] = useState('');
  const [lastAction, setLastAction] = useState<Action | null>(null);
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const axeValue = searchParams.get('axe') ?? axe ?? '';

  function handleChangeIndicator(value: string) {
    setIndicator(value);
  }

  async function handleSubmit(fields: SubmitData) {
    const [yearDeadline, semesterDeadLine] = R.split('.', fields.time);
    const file = fields.file;

    const getAction = R.pipe(
      R.omit(['time', 'file']),
      R.assoc('yearDeadline', Number(yearDeadline)),
      R.assoc('semesterDeadline', Number(semesterDeadLine)),
      R.assoc('polarity', Boolean(fields.polarity)),
      R.assoc('axle', axeValue),
      R.assoc('indicator', indicator)
    );

    const action = getAction(fields) as unknown as ActionCreation;

    console.log(action);

    setLoading(true);
    actions
      .postAction(action, file?.file)
      .then(() => {
        handleClose();
        notification.success({
          message: 'Plano de ação criado com sucesso',
          top: 50
        });
      })
      .then(() => {
        if (onRegistered) {
          onRegistered();
        }
      })
      .catch((err) => {
        handleClose();
        const data = err?.response.data;

        notification.error({
          message: 'Houve um problema na criação do plano de ação',
          description: data?.message ?? 'Tente novamente mais tarde',
          top: 50
        });
      })
      .finally(() => setLoading(false));
  }

  function handleClose() {
    setIndicator('');
    setLastAction(null);

    if (onClose) {
      onClose();
    }
  }

  useEffect(() => {
    if (R.isEmpty(indicator)) return;

    actions
      .getLastActive(indicator)
      .then((response) => setLastAction(response));
  }, [indicator]);

  function renderContent() {
    return (
      <>
        <IndicatorGroup
          axe={axeValue}
          value={indicator}
          onChange={handleChangeIndicator}
        />

        {lastAction && (
          <Alert
            message={
              <p>
                Existe um plano de ação cadastrado para esse indicador. Defina
                um novo indicador ou{' '}
                <Link
                  to={`/dashboard/gestores-acoes/planos-de-acao/${lastAction.id}`}
                >
                  clique aqui para visualizar
                </Link>
              </p>
            }
            type="warning"
            showIcon
          />
        )}

        <RegisterForm
          axe={axeValue}
          indicator={indicator}
          loading={loading}
          disabled={R.isEmpty(indicator) || isNotNil(lastAction)}
          onSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </>
    );
  }

  return (
    <Box
      title="Criar plano de ação"
      visible={isOpen}
      onCancel={handleClose}
      destroyOnClose
    >
      {loading ? <Loading display="block" /> : renderContent()}
    </Box>
  );
}
