interface IconProps {
  less?: boolean;
}

export function RuralIcon({ less }: IconProps) {
  return (
    <svg
      className={`icon ${less ? 'less' : ''}`}
      width="51"
      height="43"
      viewBox="0 0 51 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.35 15.9353V2.52941H35.7V9.10588L25.5 0L0 22.7647H7.65V43H20.4V27.8235H30.6V43H43.35V22.7647H51L43.35 15.9353ZM20.4 17.7059C20.4 14.9235 22.695 12.6471 25.5 12.6471C28.305 12.6471 30.6 14.9235 30.6 17.7059H20.4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function UrbanIcon({ less }: IconProps) {
  return (
    <svg
      className={`icon ${less ? 'less' : ''}`}
      width="58"
      height="46"
      viewBox="0 0 58 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1 4.3125C26.1 1.93164 28.0484 0 30.45 0H39.15C41.5516 0 43.5 1.93164 43.5 4.3125V17.25H47.125V10.7812C47.125 9.58633 48.0947 8.625 49.3 8.625C50.5053 8.625 51.475 9.58633 51.475 10.7812V17.25H53.65C56.0516 17.25 58 19.1816 58 21.5625V41.6875C58 44.0684 56.0516 46 53.65 46H30.45C28.0484 46 26.1 44.0684 26.1 41.6875V4.3125ZM31.9 7.1875V10.0625C31.9 10.8531 32.5525 11.5 33.35 11.5H36.25C37.0475 11.5 37.7 10.8531 37.7 10.0625V7.1875C37.7 6.39687 37.0475 5.75 36.25 5.75H33.35C32.5525 5.75 31.9 6.39687 31.9 7.1875ZM33.35 14.375C32.5525 14.375 31.9 15.0219 31.9 15.8125V18.6875C31.9 19.4781 32.5525 20.125 33.35 20.125H36.25C37.0475 20.125 37.7 19.4781 37.7 18.6875V15.8125C37.7 15.0219 37.0475 14.375 36.25 14.375H33.35ZM31.9 24.4375V27.3125C31.9 28.1031 32.5525 28.75 33.35 28.75H36.25C37.0475 28.75 37.7 28.1031 37.7 27.3125V24.4375C37.7 23.6469 37.0475 23 36.25 23H33.35C32.5525 23 31.9 23.6469 31.9 24.4375ZM47.85 23C47.0525 23 46.4 23.6469 46.4 24.4375V27.3125C46.4 28.1031 47.0525 28.75 47.85 28.75H50.75C51.5475 28.75 52.2 28.1031 52.2 27.3125V24.4375C52.2 23.6469 51.5475 23 50.75 23H47.85ZM46.4 33.0625V35.9375C46.4 36.7281 47.0525 37.375 47.85 37.375H50.75C51.5475 37.375 52.2 36.7281 52.2 35.9375V33.0625C52.2 32.2719 51.5475 31.625 50.75 31.625H47.85C47.0525 31.625 46.4 32.2719 46.4 33.0625ZM20.3 14.375C20.3 14.9141 20.2094 15.3633 20.1188 15.8125C21.9313 17.0703 23.2 19.2266 23.2 21.5625C23.2 25.6055 19.9375 28.75 15.95 28.75H14.5V43.125C14.5 44.7422 13.1406 46 11.6 46C9.96875 46 8.7 44.7422 8.7 43.125V28.75H7.25C3.17188 28.75 0 25.6055 0 21.5625C0 19.2266 1.17813 17.0703 2.99063 15.8125C2.9 15.3633 2.9 14.9141 2.9 14.375C2.9 9.61328 6.70625 5.75 11.6 5.75C16.4031 5.75 20.3 9.61328 20.3 14.375Z"
        fill="currentColor"
      />
    </svg>
  );
}
