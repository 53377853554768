import { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useSearchParams } from 'react-router-dom';
import { Radio, RadioChangeEvent } from 'antd';

import { ServiceBox2 } from '@/components/shared';
import { useAuth, useAction } from '@/hooks';

import { getChartTitle, refineData } from './utils';
import { chartOptions } from './options';
import { Box } from './styles';

import { Title3 } from '../styles';
import { DonutGraph } from '../DonutGraph';
import { getData } from '../LastGoalsBoard/requests';
import { DataWithType } from '../LastGoalsBoard/types';

export function ActionChart() {
  const [data, setData] = useState<DataWithType[][]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [radioValue, setRadioValue] = useState(0);

  const { city, ibge, indicator } = useAction();

  const [searchParams] = useSearchParams();
  const axle = searchParams.get('axe') ?? '';

  const options = chartOptions[axle];

  useEffect(() => {
    if (R.isEmpty(axle)) return;

    setLoading(true);
    getData({ axle, ibge: ibge, classificationType: indicator })
      .then((data) => setData(data))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [axle, ibge, indicator]);

  const refinedData = useMemo(() => {
    if (!axle) return [];

    return refineData(axle, data);
  }, [data, axle]);

  const selectedRefinedData = refinedData[radioValue];

  const { userCity } = useAuth();

  const title = getChartTitle(axle, city, userCity, indicator);

  const isDataEmpty = R.isEmpty(data);

  function handleRadioChange(e: RadioChangeEvent) {
    setRadioValue(e.target.value);
  }

  function renderMainContent() {
    return (
      <ServiceBox2 empty={isDataEmpty} error={error} loading={loading}>
        <div className="chart-menu">
          {options && (
            <Radio.Group
              options={options}
              optionType="button"
              value={radioValue}
              onChange={handleRadioChange}
            />
          )}
        </div>
        <DonutGraph data={selectedRefinedData} axle={axle} />
      </ServiceBox2>
    );
  }

  return (
    <Box>
      <Title3>{title}</Title3>

      {renderMainContent()}
    </Box>
  );
}
