import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

interface AnalyticsTitleContextProps {
  title: string;
  setTitle: (title: string) => void;
}

interface AnalyticsTitleProviderProps {
  children: ReactNode;
}

const AnalyticsTitleContext = createContext<AnalyticsTitleContextProps>(
  {} as AnalyticsTitleContextProps
);

export function AnalyticsTitleProvider({
  children
}: AnalyticsTitleProviderProps) {
  const [title, setTitle] = useState('');

  const providerValue = useMemo(() => ({ title, setTitle }), [title]);

  return (
    <AnalyticsTitleContext.Provider value={providerValue}>
      {children}
    </AnalyticsTitleContext.Provider>
  );
}

export function useAnalyticsTitle(newTitle: string) {
  const { title, setTitle } = useContext(AnalyticsTitleContext);

  useEffect(() => {
    setTitle(newTitle);
  }, [newTitle, setTitle]);

  return title;
}
