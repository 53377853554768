import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = '/perfis-lideranca';

  async function deficiencyTypes() {
    const path = `${resource}/tipos-deficiencias`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.se_sim_qual_deficiencia,
        value: d.quantidade
      };
    });
  }

  async function race() {
    const path = `${resource}/raca`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.raca_cor,
        value: d.quantidade
      };
    });
  }
  async function sexualOrientation() {
    const path = `${resource}/orientacao-sexual`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.ori_sexual,
        value: d.quantidade
      };
    });
  }
  async function genderIdentity() {
    const path = `${resource}/identidade-genero`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.identidade_genero,
        value: d.quantidade
      };
    });
  }
  async function ages() {
    const path = `${resource}/faixa-etaria`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.faixa_etaria,
        value: d.quantidade
      };
    });
  }

  async function schooling() {
    const path = `${resource}/nivel-escolaridade`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.nivel_escolaridade,
        value: d.quantidade
      };
    });
  }

  async function leaderFunction() {
    const path = `${resource}/funcao-lideranca`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.funcao_no_movimento,
        value: d.quantidade
      };
    });
  }

  async function deficiency() {
    const path = `${resource}/deficiencias`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.possui_deficiencia,
        value: d.quantidade
      };
    });
  }

  async function ethnicGroups() {
    const path = `${resource}/grupos-etnicos`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((d) => {
      return {
        name: d.grp_etnico,
        value: d.quantidade
      };
    });
  }

  return {
    deficiencyTypes,
    race,
    deficiency,
    sexualOrientation,
    genderIdentity,
    ages,
    ethnicGroups,
    schooling,
    leaderFunction
  };
};

export const leaderMovements = service();
