import {
  EducationEnum,
  HealthEnum,
  InsecurityFoodEnum,
  LivingConditionEnum,
  SocialAssistanceEnum,
  WorkEnum
} from '@/services';

// MELHORA QUANDO AUMENTA 1
// MELHORA QUANDO DIMINUI 0

export const POLARITY_BY_INDICATOR: Record<string, number> = {
  [InsecurityFoodEnum.FORA_DE_INSEGURANCA_ALIMENTAR]: 1,
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_LEVE]: 0,
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_MODERADA]: 0,
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_GRAVE]: 0,
  [EducationEnum.CRECHE]: 0,
  [EducationEnum.PRE_ESCOLA]: 0,
  [EducationEnum.ESCOLA]: 0,
  [SocialAssistanceEnum.CRAS]: 0,
  [SocialAssistanceEnum.CREAS]: 0,
  [HealthEnum.GESTANTE_PRE_NATAL]: 0,
  [HealthEnum.ACOMPANHAMENTO_CRIANCA]: 0,
  [HealthEnum.CRIANCA_VACINA_EM_DIA]: 0,
  [WorkEnum.TRABALHO_REMUNERADO]: 0,
  [WorkEnum.SALARIO_MINIMO]: 0,
  [WorkEnum.FAZ_CURSO]: 0,
  [WorkEnum.QUER_FAZER_CURSO]: 1,
  [WorkEnum.RENDA]: 0, // considerar o 'Bolsa família / Cartão Mais Infância; Outros?'

  [LivingConditionEnum.AGUA_CANALIZADA]: 0,
  [LivingConditionEnum.BANHEIRO]: 0,
  [LivingConditionEnum.COLETA_SELETIVA]: 0,
  [LivingConditionEnum.LUGARES_PUBLICOS_CRIANCA_BRINCAR]: 0,
  [LivingConditionEnum.ATIVIDADE_CULTURAL]: 0,
  [LivingConditionEnum.ATIVIDADE_ESPORTIVA]: 0,
  [LivingConditionEnum.AREA_CONFLITO]: 0
};
