import styled, { css } from 'styled-components';

import { colors } from '@/theme/colors';

const darkButton = css`
  ${({ theme }) =>
    theme.darkMode &&
    css`
      &.sair,
      &.entrar {
        background-color: ${theme.yellowDark};
        color: ${theme.background};
        text-decoration: underline;
      }

      img {
        filter: brightness(0%);
      }
    `};
`;

export const MobileButton = styled.button`
  width: 100%;
  border: 0;
  padding: 0.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 0.4rem;

  &.entrar {
    background: #55a546;
  }

  &.sair {
    background: #ff4d4f;
  }

  span {
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 22px;
    text-transform: uppercase;
    margin-left: 1rem;
  }

  ${darkButton}
`;

export const DesktopButton = styled.div`
  margin-left: 2rem;
  display: flex;

  button {
    display: flex;
    align-items: center;

    cursor: pointer;
    align-items: center;

    text-decoration: none;
    text-transform: uppercase;
    color: ${colors.white};
    font-size: 1rem;
    line-height: 1rem;
    font-weight: bold;
    font-style: normal;
    border: 0;

    padding: 0.8rem;
    border-radius: 0.7rem;

    span {
      margin-left: 0.5rem;
    }

    &:hover {
      color: white;
    }

    &.sair {
      background: #ff4d4f;
    }

    &.entrar {
      background: #55a546;
    }

    ${darkButton}
  }
`;
