import { TScreenType } from 'big-data/theme';

export const ScreenType = {
  small: `small` as TScreenType,
  medium: `medium` as TScreenType,
  large: `large` as TScreenType
};

const ScreenTypeQuery: Map<TScreenType, string> = new Map([
  [`small`, `(max-width: 700px)`],
  [`medium`, `(min-width: 651px) and (max-width: 1199px)`],
  [`large`, `(min-width: 1200px)`]
]);

const smallScreen = ScreenTypeQuery.get(`small`);
const mediumScreen = ScreenTypeQuery.get(`medium`);
const largeScreen = ScreenTypeQuery.get(`large`);

export const mediaQuery = {
  smallScreen: `@media screen and ${smallScreen}`,
  mediumScreen: `@media screen and ${mediumScreen}`,
  largeScreen: `@media screen and ${largeScreen}`
};

export const calcCurrentMediaType = (): TScreenType => {
  for (const [type, query] of ScreenTypeQuery) {
    if (window.matchMedia(query).matches) {
      return type;
    }
  }
  return ScreenType.small;
};
