import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { socialMovements } from '@/services/socialMovements';
import { useEffect } from 'react';
import { colors } from '@/theme/colors';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

export function MovementsOccupationList() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.areaOperationMovements());
  }, [request]);

  return (
    <Section>
      <Card
        title="Áreas de atuação"
        loading={loading}
        description="Um mesmo movimento social pode atuar em mais de uma área"
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <HorizontalBarSimple
          isPercentage
          hasPagination
          maxValue={6}
          sizeRatio={0.7}
          percentageCalculatedOut
          grid
          data={data}
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
