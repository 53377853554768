import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Box } from './styles';

interface AnalyticCardProps {
  title: string;
  category?: string;
  icon: () => JSX.Element;
  description?: string;
  path?: string;
}

export function AnalyticCard({
  title,
  category,
  icon,
  description,
  path
}: AnalyticCardProps) {
  const Icon = icon;
  return (
    <Box title={title}>
      <div className="header-content">
        <Icon />
        <div className="title">
          <h3>{title}</h3>
          <span>{category}</span>
        </div>
      </div>

      <p>{description}</p>

      <Link to={path ?? ''}>
        <MdKeyboardArrowRight size={22} />
        <span> Acessar</span>
      </Link>
    </Box>
  );
}
