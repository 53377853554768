import styled from 'styled-components';

import { GenericGrid, GenericGridItem } from '@/components/styled';

export const Grid = styled(GenericGrid)`
  grid-template-columns: 400px 2fr 1fr;

  grid-template-areas:
    'a b c'
    'd e f'
    'g h i';

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'a b'
      'c c'
      'd e'
      'f f'
      'i h'
      'g g';
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'b b'
      'a c'
      'd e'
      'f f'
      'i h'
      'g g';
  }

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g'
      'h'
      'i';
  }
`;

export const GridItem = styled(GenericGridItem)`
  .pizzas {
    display: flex;
  }
`;

export const WrapperGridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 100%;

  @media (max-width: 1500px) {
    flex-direction: row;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
