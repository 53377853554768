import { ServiceBox } from '@/components/shared';
import { Select } from '@/components/shared/SelectBox';
import { useCitizenAccessUsers } from '@/servicesHooks/citizenAccessUser';
import { cpfReplacer } from '@/utils/string';
import { useState } from 'react';
import styled from 'styled-components';

import {
  CardBase,
  CardContent,
  CardFooter,
  CardHeader,
  Pagination,
  Table
} from '../../components';
import { useManager, useTabContext } from '../../contexts';
import { usePagination } from '../../hooks/usePagination';
import { filterOrderOptions, generateTag } from '../../utils';

type Order = 'asc' | 'desc';

export function CardRankingAccess() {
  const { manager } = useManager();
  const { tab } = useTabContext();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<Order>();

  const { data, error, loading } = useCitizenAccessUsers({
    minimumDate: tab?.value,
    userId: manager?.cpf,
    order
  });

  const tag = generateTag([
    `CPF: ${cpfReplacer(manager?.cpf ?? '', 'secret')}`,
    tab?.name
  ]);

  const { dataPaginated, pageSize, totalPages } = usePagination(data, page);

  return (
    <Box>
      <CardHeader title="Ranking de acesso hoje" subtitle={tag} />

      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent>
          <Table
            data={dataPaginated.map((d) => ({
              user: d.name,
              numberOfAccess: d.value
            }))}
            headers={['Usuário', 'Número de acessos']}
            countStart={(page - 1) * pageSize}
            highlight={{
              by: 'user',
              value: manager?.name ?? ''
            }}
          />
        </CardContent>
      </ServiceBox>
      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            onChange={(value) => setOrder(value as Order)}
            value={order}
            options={filterOrderOptions}
            placeholder="Ordenar por..."
          />
          <Pagination
            onPaginate={(page) => setPage(page)}
            page={page}
            totalPages={totalPages}
            resetOnChange={tab?.value}
            initialPage={1}
          />
        </div>
      </CardFooter>
      <div />
    </Box>
  );
}

const Box = styled(CardBase)``;
