import { colors } from '@/theme/colors';
import { Drawer as AntDrawer, Layout } from 'antd';
import styled, { css } from 'styled-components';

const borderDark = css`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
`;

export const Drawer = styled(AntDrawer)`
  .ant-drawer-body,
  .ant-drawer-header {
    background: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.green700};
    border-right: ${({ theme }) =>
      theme.darkMode ? `1px solid ${colors.white}` : 0};
  }

  .ant-drawer-content-wrapper,
  .ant-drawer-content {
    max-width: 300px;
  }

  .ant-drawer-content {
    background-color: ${colors.green700};
  }

  .ant-drawer-header {
    padding: 1rem 0;

    &-title {
      justify-content: flex-end;

      .anticon-close {
        color: ${colors.white};
      }
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding: 2rem 0;
  }

  .ant-menu {
    background: inherit;
  }

  .ant-menu-item-selected {
    background: #57ac4b !important;
  }

  .ant-menu,
  .ant-menu-root,
  .ant-menu-vertical,
  .ant-menu-dark,
  .ant-menu-inline-collapsed {
    width: 100% !important;
  }

  li.ant-menu-item {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: initial !imporant; */
  }

  .ant-menu-submenu {
    margin: 0;

    li.ant-menu-item {
      padding-left: 3.8rem !important;
    }
  }

  li.ant-menu-item {
    margin: 0;
  }

  li.ant-menu-item-selected {
    position: relative;
    background: #000;
  }

  .ant-menu-submenu,
  .ant-menu-item {
    svg {
      width: 20px;
      height: 20px;

      &:not(.lgbtqia-plus) {
        path {
          fill: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
          stroke: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
        }
      }
    }

    &-selected {
      background-color: ${({ theme }) =>
        theme.darkMode ? theme.background : '#57ac4b'} !important;
      ${({ theme }) => theme.darkMode && borderDark}
    }

    span.ant-menu-title-content {
      display: block;
      margin-left: 10px;
      font-weight: 700;
      font-size: 1rem;
      color: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '#fff')};
      text-transform: uppercase;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
  }

  .logout {
    display: flex;
    flex-direction: column;
  }
`;

export const SmallBox = styled(Layout)`
  grid-area: menu;
  width: 100%;
  height: 70px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) =>
    theme.darkMode ? theme.background : colors.green700};
  border-bottom: ${({ theme }) =>
    theme.darkMode ? `1px solid ${colors.white}` : ''};
  padding-right: 1rem;

  img {
    height: 40px;
  }
`;

interface LargeBoxProps {
  $collapsed?: boolean;
}

export const LargeBox = styled(Layout)<LargeBoxProps>`
  grid-area: menu;
  width: 100%;
  min-height: 100vh;
  z-index: 999;

  position: relative;

  aside {
    .ant-layout-sider-children {
      background: ${({ theme }) =>
        theme.darkMode ? theme.background : colors.green700};
      border-right: ${({ theme }) =>
        theme.darkMode ? `1px solid ${colors.white}` : 0};
      position: fixed;
      width: inherit;
      padding: 1rem 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .ant-layout-sider-trigger {
      background: inherit;
    }
  }

  .ant-menu {
    background: inherit;
  }

  .ant-menu-item-selected {
    background: #57ac4b !important;
  }

  .ant-menu,
  .ant-menu-root,
  .ant-menu-vertical,
  .ant-menu-dark,
  .ant-menu-inline-collapsed {
    width: 100% !important;
  }

  li.ant-menu-item {
    display: flex;
    align-items: center;
  }

  li.ant-menu-item,
  .ant-menu-submenu {
    svg {
      width: 20px;
      height: 20px;

      &:not(.lgbtqia-plus) {
        fill: ${({ theme }) => (theme.darkMode ? theme.yellowDark : ``)};
      }
    }
  }

  .ant-menu-submenu {
    margin: 0;

    &-title {
      display: flex;
      align-items: center;
    }

    li.ant-menu-item {
      padding-left: 3.8rem !important;
    }
  }

  li.ant-menu-item {
    margin: 0;
  }

  li.ant-menu-item-selected {
    position: relative;
    background: #000;
  }

  .ant-menu-title-content {
    display: ${({ $collapsed }) => ($collapsed ? 'none' : 'inline-block')};
    margin-left: 10px;
    font-weight: 700;
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;

    max-width: 230px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    svg:not(.lgbtqia-plus),
    svg:not(.lgbtqia-plus) path {
      fill: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
      stroke: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
    }

    &-selected {
      background-color: ${({ theme }) =>
        theme.darkMode ? theme.background : '#57ac4b'} !important;
      ${({ theme }) => theme.darkMode && borderDark}
    }

    .ant-menu-title-content {
      color: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
      text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};
    }
  }

  .ant-menu-sub {
    background: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.green700} !important;
    color: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.green700} !important;
  }

  .ant-menu-submenu-title {
    color: ${({ theme }) =>
      theme.darkMode ? theme.yellowDark : ''} !important;
    text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};

    .ant-menu-title-content {
      color: inherit;
    }
  }
`;
