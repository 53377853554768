import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function usePreschoolData() {
  const [preschool, setPreschool] = useState<Data[]>([]);
  const [preschoolError, setPreschoolError] = useState<Error | null>(null);
  const [preschoolLoading, setPreschoolLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    const hasFilter = !!ibge;
    setPreschoolLoading(true);

    education
      .getPreSchool({
        hasFilter,
        variants: ['pública', 'particular'],
        ibge,
        year,
        semester,
        zone
      })
      .then((response) => {
        const total = response.reduce((acc, cur) => acc + cur.value, 0);

        const sumSchoolMode = response.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.schoolMode.toLowerCase()]:
              (acc[cur.schoolMode.toLowerCase()] ?? 0) + cur.value
          };
        }, {} as Record<string, number>);

        setPreschool(
          Object.entries(sumSchoolMode)
            .map(([key, value]) => ({
              name: 'estão matriculados na pré-escola ' + key,
              value: value / total
            }))
            .sort((a, b) => b.value - a.value)
        );
      })
      .catch((error) => setPreschoolError(error))
      .finally(() => setPreschoolLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(() => {
    return {
      preschool,
      preschoolError,
      preschoolLoading
    };
  }, [preschool, preschoolError, preschoolLoading]);
}
