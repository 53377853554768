import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const TableBox = styled.table`
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;

  thead {
    th {
      padding: 0.5rem 0;
      text-align: center;

      color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
    }
  }

  tbody {
    user-select: none;

    th,
    td {
      font-size: 1rem;
      padding: 0.5rem 0.2rem;
    }

    .titles {
      text-align: left;

      th + th {
        text-align: right;
      }
    }

    .rows {
      border-bottom: 1px solid #c4c4c4a2;
      color: ${(props) => (props.theme.darkMode ? `#fff` : `#2d4040`)};
      transition: background 0.5s;
      cursor: pointer;

      td + td {
        text-align: right;
      }

      &:hover {
        background: #1d696f63;
      }
    }
  }
`;
