import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useWomanMaritalStatus() {
  const [maritalStatus, setMaritalStatus] = useState<Data[]>([]);
  const [loadingMaritalStatus, setLoadingMaritalStatus] = useState(false);
  const [errorMaritalStatus, setErrorMaritalStatus] = useState<Error | null>(
    null
  );

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingMaritalStatus(true);
    profileSearch
      .getWomanMaritalStatus(ibge, year, semester, zone)
      .then((data) => setMaritalStatus(data))
      .catch((err) => setErrorMaritalStatus(err))
      .finally(() => setLoadingMaritalStatus(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      maritalStatus,
      loadingMaritalStatus,
      errorMaritalStatus
    }),
    [maritalStatus, loadingMaritalStatus, errorMaritalStatus]
  );
}
