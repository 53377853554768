import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  max-width: 360px;

  .title {
    display: flex;

    h3 {
      color: ${({ theme }) =>
        theme.darkMode ? colors.white : colors.green700};

      display: inline-block;
      margin-left: 1rem;
      font-size: 1.4rem;
    }
  }

  .description {
    margin-top: 0.5rem;
    color: ${({ theme }) => (theme.darkMode ? colors.white : '#636E72')};
  }
`;
