import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  async function deficiencyType(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['POSSUI_DEFICIENCIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.possui_deficiencia,
        value: d.porcentagem
      };
    });
  }

  async function ethnicGroup(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['GRUPO_ETNICO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.grupo_etnico,
        value: d.porcentagem
      };
    });
  }

  async function homeType(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['TIPO_RESIDENCIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.tipo_residencia,
        value: d.porcentagem
      };
    });
  }

  async function zone(params: LgbtParams) {
    const path = `${resource}/populacao-zonas`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.zona,
        value: d.porcentagem
      };
    });
  }

  async function city(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['MUNICIPIO', 'IBGE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.municipio,
        value: d.quantidade,
        codIbge: d.ibge
      };
    });
  }

  async function region(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['REGIAO_ESTADO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.regiao_estado,
        value: d.quantidade
      };
    });
  }

  async function identityGender(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['IDENTIDADE_GENERO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.identidade_genero,
        value: d.porcentagem
      };
    });
  }

  async function sexualOrientation(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ORIENTACAO_SEXUAL']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.orientacao_sexual,
        value: d.porcentagem
      };
    });
  }

  async function maritalStatus(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ESTADO_CIVIL']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.estado_civil,
        value: d.porcentagem
      };
    });
  }

  async function nacionality(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['NACIONALIDADE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.nacionalidade,
        value: d.porcentagem
      };
    });
  }

  async function age(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['FAIXA_ETARIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.faixa_etaria,
        value: d.porcentagem
      };
    });
  }

  async function documents(params: LgbtParams) {
    const path = `${resource}/quais-documentos-possui`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.quais_documentos_possui,
        value: d.porcentagem
      };
    });
  }

  async function orientacaoSexualFilter(params: LgbtParams) {
    const path = `${resource}/orientacao-sexual`;
    const { ibge } = params;
    const options = ensureAxiosParamOptions({
      params: {
        ibge
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.orientacao_sexual
      };
    });
  }

  async function genderIdentityFilter(params: LgbtParams) {
    const path = `${resource}/identidade-genero`;
    const { ibge } = params;
    const options = ensureAxiosParamOptions({
      params: {
        ibge
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.identidade_genero
      };
    });
  }

  return {
    age,
    nacionality,
    maritalStatus,
    deficiencyType,
    city,
    region,
    sexualOrientation,
    identityGender,
    zone,
    ethnicGroup,
    homeType,
    documents,
    orientacaoSexualFilter,
    genderIdentityFilter
  };
};

export const useGeneral = service();
