import { Button, Tabs } from 'antd';
import { AiOutlineFile } from 'react-icons/ai';
import dayjs from 'dayjs';

import { GroupBlockBox, TabBox, ThirdTitle } from '@/components/styled';

import {
  MainChannelWordCloud,
  MovementsGroupList,
  MovementsLinks,
  MovementsLinksList,
  MovementsList,
  MovementsMap,
  MovementsOccupationList,
  MovementsPrizes,
  MovementsPrizesList,
  MovementsTypesRanking,
  PerformanceBar,
  SocialControlBar,
  SocialControlParticipation
} from '../SocialMovementsMainData';
import {
  LeaderAges,
  LeaderByEthnicGroup,
  LeaderDeficiency,
  LeaderDeficiencyTypes,
  LeaderFunction,
  LeaderGenderIdentity,
  LeaderRace,
  LeaderSchooling,
  LeaderSexualOrientation
} from '../SocialMovementsLeaderData';

import { Box } from './styles';

const { TabPane } = Tabs;

export function SocialMovementsInsights() {
  function downloadPdf() {
    const link = document.createElement('a');

    const today = dayjs().format('DD-MM-YYYY_HH-mm-ss-SSS');

    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      'https://s3.sa-east-1.amazonaws.com/big-data-socia-publico/anuarios/movimentos_sociais/anuario.pdf'
    );
    link.setAttribute(
      'download',
      `anuário-dos-movimentos-sociais-${today}.pdf`
    );

    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  return (
    <Box>
      <div className="topTitle">
        <ThirdTitle>Dados gerais sobre os movimentos sociais</ThirdTitle>
      </div>
      <TabBox>
        <Tabs
          tabBarExtraContent={{
            right: (
              <div className="content-top">
                <Button className="downloadButton" onClick={downloadPdf}>
                  <AiOutlineFile />
                  <span>ACESSAR PDF</span>
                </Button>
              </div>
            )
          }}
        >
          <TabPane tab="Dados gerais" key={1}>
            <div>
              <GroupBlockBox>
                <MovementsMap />
                <MovementsList />
              </GroupBlockBox>
              <GroupBlockBox>
                <PerformanceBar />
                <MovementsTypesRanking />
              </GroupBlockBox>
              <GroupBlockBox>
                <MovementsOccupationList />
                <MovementsGroupList />
              </GroupBlockBox>
              <GroupBlockBox>
                <SocialControlParticipation />
                <SocialControlBar />
              </GroupBlockBox>
              <GroupBlockBox>
                <MovementsPrizes />
                <MovementsPrizesList />
              </GroupBlockBox>
              <GroupBlockBox>
                <MovementsLinks />
                <MovementsLinksList />
              </GroupBlockBox>
              <GroupBlockBox>
                <MainChannelWordCloud />
              </GroupBlockBox>
            </div>
          </TabPane>
          <TabPane tab="Perfil das Lideranças" key={2}>
            <div>
              <GroupBlockBox>
                <LeaderAges />
                <LeaderRace />
              </GroupBlockBox>
              <GroupBlockBox>
                <LeaderGenderIdentity />
                <LeaderSexualOrientation />
              </GroupBlockBox>
              <GroupBlockBox>
                <LeaderSchooling />
                <LeaderFunction />
              </GroupBlockBox>
              <GroupBlockBox>
                <LeaderDeficiency />
                <LeaderDeficiencyTypes />
              </GroupBlockBox>
              <GroupBlockBox>
                <LeaderByEthnicGroup />
              </GroupBlockBox>
            </div>
          </TabPane>
        </Tabs>
      </TabBox>
    </Box>
  );
}
