import { DashboardCard } from '@/components/shared';
import { ColorText } from '@/components/styled';
import {
  useTotalCities
} from '@/servicesHooks/foodInsecurity';
import { useTotalFamilies } from '@/servicesHooks/cmic';
import { useHousingConditionData } from '@/servicesHooks/livingConditionAgents';
import { dotsNumber, percentageFormatter } from '@/utils/string';
import { colors } from '@/theme/colors';

import totalIcon from '@/assets/icons/informationIcons/Ceara_Icon.svg';
import riskAreaIcon from '@/assets/icons/informationIcons/Risk_Icon.svg';
import ruralIcon from '@/assets/icons/informationIcons/rural.svg';
import houseIcon from '@/assets/icons/house.svg';

const color = colors.ciano700;

export function CearaStatistics() {
  const { totalCities, total, loadingTotal } = useTotalCities(false);
  const { loadingTotalFamilies } = useTotalFamilies(false);

  // const { total: foodInsecutityTotal, loading } =
  //   useFoodInsecurityTotalFamilies();

  const { zone } = useHousingConditionData(total);

  const rural = zone?.filter((d) => d.name === 'Rural')[0]?.value;
  const urban = zone?.filter((d) => d.name === 'Urbana')[0]?.value;

  const { areaCondition, houseCondition, loadingPerfil } =
    useHousingConditionData(total);

  const lendHouse = houseCondition?.filter(
    (d) => d.name === 'Emprestada/cedida'
  )[0]?.value;
  const rentedHouse = houseCondition?.filter((d) => d.name === 'Alugada')[0]
    ?.value;
  const ownHome = houseCondition?.filter((d) => d.name === 'Própria')[0]?.value;
  const riskHouse = areaCondition?.[0]?.value;

  return (
    <DashboardCard
      loading={loadingPerfil || loadingTotal || loadingTotalFamilies || loadingTotal}
    >
      <ul className="information-list">
        <li>
          <img src={totalIcon} alt="icone de familias totais" />
          <div>
            <p>
              Hoje,{' '}
              <ColorText color={color}>
                há {dotsNumber(150000)} famílias beneficiadas com o
                Cartão Mais Infância (CMIC)
              </ColorText>{' '}
              no Ceará.
            </p>
            <p>
              Desse total, foram entrevistadas{' '}
              <ColorText>
                {dotsNumber(total || 0)} famílias em situação de
                vulnerabilidade social
              </ColorText>{' '}
              em
              <ColorText color={color}>
                {' '}
                {totalCities} municípios
              </ColorText>{' '}
              cearenses, das quais:
            </p>
          </div>
        </li>
        <li>
          <img src={ruralIcon} alt="Icon de zona rural" />
          <p>
            <ColorText color={color}>{percentageFormatter(rural)}</ColorText>{' '}
            vivem na zona rural e{' '}
            <ColorText color={color}>{percentageFormatter(urban)}</ColorText>{' '}
            vivem na zona urbana.
          </p>
        </li>
        <li>
          <img src={houseIcon} alt="Icone de area de risco" />
          <p>
            <ColorText color={color}>
              {percentageFormatter(lendHouse)}
            </ColorText>{' '}
            moram em casas emprestadas ou cedidas,{' '}
            <ColorText color={color}>
              {percentageFormatter(rentedHouse)}
            </ColorText>{' '}
            moram em casas alugadas e{' '}
            <ColorText color={color}>{percentageFormatter(ownHome)}</ColorText>{' '}
            possuem casa própria.
          </p>
        </li>
        <li>
          <img src={riskAreaIcon} alt="Icone de area de risco" />
          <p>
            <ColorText color={color}>
              {percentageFormatter(riskHouse)}{' '}
            </ColorText>{' '}
            vivem em áreas de risco.
          </p>
        </li>
      </ul>
    </DashboardCard>
  );
}
