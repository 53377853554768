import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { googleAnalyticsResource } from './utils/resource';

export const service = () => {
  const resource = googleAnalyticsResource + '/rejeicao-paginas';

  async function get(
    minimuDate?: string,
    ibge?: string,
    order?: string,
    page?: number,
    pageSize?: number
  ) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimuDate,
        ibge,
        order,
        pageNumber: page,
        pageSize,
        fromCeara: true
      }
    });
    const response = await callService(() =>
      mainApi.get<{ numberOfPages: number; content: any[] }>(path, options)
    );
    const { content, numberOfPages } = response.data;

    return {
      content: content.map((data) => ({
        name: data.nome_pagina,
        value: data.taxa_rejeicao,
        totalPages: data.totalpages
      })),
      totalPages: numberOfPages
    };
  }

  return { get };
};

export const rejection = service();
