import { colors } from '@/theme/colors';
import { RichedAction } from 'big-data';
import { RiErrorWarningFill } from 'react-icons/ri';
import { CityTag, StatusTag, GoalsTag } from '../Tags';
import { Box } from './styles';

interface LastGoalsItemProps {
  action: RichedAction;
}

export function LastGoalsItem({ action }: LastGoalsItemProps) {
  return (
    <Box to={`/dashboard/gestores-acoes/planos-de-acao/${action.id}`}>
      <div className="action-main">
        <h4>
          {action.city.toUpperCase()} - {action.title}
        </h4>

        <div className="action-tags">
          <CityTag city={action.city} />
          <StatusTag status={action.status} />
          <GoalsTag action={action} />
        </div>
      </div>
      <div className="comments-warning">
        <svg className="forum-icon" x="0px" y="0px" viewBox="0 0 22 22">
          <path
            d="M11,4c-4.4,0-8,2.9-8,6.5c0,1.6,0.7,3,1.8,4.1c-0.4,1.6-1.7,3-1.7,3C3,17.6,3,17.8,3,17.8c0,0.1,0.1,0.1,0.2,0.1
	c2.1,0,3.6-1,4.4-1.6c1,0.4,2.2,0.6,3.4,0.6c4.4,0,8-2.9,8-6.5S15.4,4,11,4z"
          />
        </svg>

        {action.qtdNotification > 0 && (
          <RiErrorWarningFill size={16} color={colors.yellow500} />
        )}
      </div>
    </Box>
  );
}
