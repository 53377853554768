import { DataTree } from 'big-data';
import { useMemo, useState } from 'react';

import {
  FilterDashboard,
  SunBurstReact,
  SunBurstText,
  CzRMTableModal
} from '@/components/shared';
import {
  ThirdTitle,
  ThermometerWrapper,
  AxeDescriptionText
} from '@/components/styled';
import { useDashboardFilter, useDashboardTitle } from '@/hooks';
import { useSocialAssistance } from '@/servicesHooks/socialAssistance';
import { useResponseData } from '@/servicesHooks/socialAssistance/useResponseData';
import { colors } from '@/theme/colors';
import { percentageFormatter } from '@/utils/string';

import { modifierColors } from '../utils';
import {
  SocialAssistanceCRASMonitoring,
  SocialAssistanceCREASMonitoring,
  SocialAssistanceInsight
} from '../components';

import { descriptions } from './description';
import { getTree, getTreeLegend } from './tree';

import { Box } from './styles';

export function SocialAssistance() {
  useDashboardTitle('Termômetro de Dados – Eixo Assistência Social');

  const { data, total, loading } = useSocialAssistance();
  const { cras } = useResponseData();

  const [selected, setSelected] = useState<DataTree>();
  const { city } = useDashboardFilter();

  const [isCzRMModalOpen, setIsCzRMModalOpen] = useState(false);

  const cityText = city ? `em ${city}` : 'no Ceará';

  const name = selected ? selected.name : `Assistência Social ${cityText}`;

  const totalFamilies = useMemo(
    () => cras.reduce((acc, acur) => acc + acur.value, 0),
    [cras]
  );

  const assistanceTree = useMemo(() => {
    return getTree(data, totalFamilies);
  }, [data, totalFamilies]);

  const legend = useMemo(() => getTreeLegend(selected), [selected]);

  function handleSunburstClick(data?: DataTree) {
    if (!data || data.name === 'Assistência Social') {
      setSelected(undefined);
    } else {
      setSelected(data);
    }
  }

  const formatter = new Intl.NumberFormat('pt-br');

  const totalInterviewCenterValue = selected
    ? percentageFormatter(selected.percentageValue, true)
    : formatter
        .format(total.reduce((acc, cur) => acc + cur.value, 0))
        .toString();

  function handleOpenCzRMModal() {
    if (!selected) return;
    setIsCzRMModalOpen(true);
  }

  const sunburstLabel = selected
    ? `FAMÍLIAS NÃO ASSISTIDAS PELO ${selected?.name.toUpperCase()}`
    : 'FAMÍLIAS NÃO ASSISTIDAS';

  const sunburstBtnLabel = selected
    ? null
    : 'CLIQUE EM CADA EIXO PARA DETALHAR';

  const description = selected
    ? descriptions[name]
    : 'Este eixo monitora a oferta de assistência social pelo Centro de Referência da Assistência Social (CRAS) e pelo Centro de Referência Especializado de Assistência Social (CREAS) e a relação das ações socioassistenciais com outras políticas públicas.';
  const color = modifierColors.get(selected?.color ?? '') ?? colors.ciano700;

  const crasHidden = name.toLowerCase().includes('creas');
  const creasHidden = name.toLowerCase().includes('cras');

  const nameAxis = selected ? selected.name : 'CRAS e pelo CREAS';

  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>
      <FilterDashboard hidden={!!selected} />
      <ThermometerWrapper position="start">
        <div className="left-cards">
          <SocialAssistanceCRASMonitoring
            hasCircle
            crasHidden={crasHidden}
            color={color}
          />
          <SocialAssistanceCREASMonitoring
            creasHidden={creasHidden}
            color={color}
          />
        </div>
        <SunBurstReact
          data={assistanceTree}
          dataLegend={legend}
          legendTitle={`Nível de acompanhamento das famílias pelos ${nameAxis}`}
          loading={loading}
          selected={selected !== undefined}
          onClick={handleSunburstClick}
          sizeByValue
        >
          <SunBurstText
            value={totalInterviewCenterValue}
            label={sunburstLabel}
            buttonLabel={sunburstBtnLabel}
            color={color}
            isButtonCzrm={!!selected}
            handleOpenFamilyTable={handleOpenCzRMModal}
          />
        </SunBurstReact>
      </ThermometerWrapper>

      <div className="profile">
        <ThirdTitle>
          Dados da pesquisa dos agentes sociais sobre{' '}
          {selected ? 'o ' + name.toUpperCase() : name}
        </ThirdTitle>
        <SocialAssistanceInsight
          selected={selected}
          familyTotal={totalFamilies}
        />
      </div>

      <CzRMTableModal
        title={`Famílias sem Assistência Social nos ${
          selected?.name.toUpperCase() || 'CREAS'
        }`}
        isOpen={isCzRMModalOpen}
        onCancel={() => setIsCzRMModalOpen(false)}
        axe="socialAssistance"
        selectedSubAxe={selected?.name.toUpperCase()}
      />
    </Box>
  );
}
