import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { percentageFormatter } from '@/utils/string';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card5() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getActingArea(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Áreas de atuação das organizações de apoio a mulheres"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <ListTable
        data={data.map((m) => ({ ...m, value: percentageFormatter(m.value) }))}
        columnTitle={[
          { key: 'acting-area', name: 'Área de atuação' },
          { key: 'percentage', name: 'Porcentagem' }
        ]}
        numberValues
      />
    </Card>
  );
}
