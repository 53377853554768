import styled from 'styled-components';

export const Box = styled.div`
  .panels {
    display: flex;
    gap: 0.8rem;

    @media (max-width: 1230px) {
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  form {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
