import { Pie as VisxPie } from '@visx/shape';
import { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { animated, useTransition, to } from 'react-spring';
import { Data } from 'big-data';
import { useTheme } from 'styled-components';

interface PieProps {
  data: Data[];
  radius: number;
  thickness: number;
  colorScale: (data: Data) => string;
  animate?: boolean;
  onClick?: (data: Data) => void;
  percent?: boolean;
  labelOnDonut?: boolean;
}

export function Pie({
  data,
  radius,
  thickness,
  labelOnDonut,
  colorScale,
  animate = true,
  onClick,
  percent = false
}: PieProps) {
  const { darkMode } = useTheme();

  return (
    <VisxPie
      data={data}
      pieValue={(d) => d.value}
      outerRadius={radius}
      innerRadius={radius - thickness}
      cornerRadius={3}
      padAngle={0.005}
      className="visx-pie"
    >
      {(pie) =>
        animate && (
          <Group className="pie">
            <AnimatedPie<Data>
              labelOnDonut={labelOnDonut}
              {...pie}
              darkMode={darkMode}
              animate
              percent={percent}
              getKey={(arc) => (arc.data.value * 100).toLocaleString() + '%'}
              onClickDatum={({ data }) => {
                if (animate && onClick) {
                  onClick(data);
                }
              }}
              getColor={(arc) => colorScale(arc.data)}
            />
          </Group>
        )
      }
    </VisxPie>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  percent?: boolean;
  labelOnDonut?: boolean;
  darkMode: boolean;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  percent,
  labelOnDonut,
  darkMode
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle
            })
          )}
          fill={getColor(arc)}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
        {hasSpaceForLabel && labelOnDonut && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill={darkMode ? 'white' : 'black'}
              x={centroidX + (centroidX > 0 ? 35 : -35)}
              y={centroidY + (centroidY > 0 ? 35 : -35)}
              dy=".33em"
              fontSize={12}
              fontWeight={700}
              textAnchor="middle"
              pointerEvents="none"
            >
              {getKey(arc).replace('.', ',')}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
