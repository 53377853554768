import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px 30px 0;

  article {
    article {
      margin: 60px 0;
      p {
        font-size: 16px;
      }

      .box-icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        .icon {
          background: ${({ theme }) =>
            theme.darkMode ? `#fff` : colors.gray700};
          border-radius: 100%;
          width: 50px;
          height: 50px;
          svg {
            padding: 0.8rem;
            color: ${({ theme }) => (theme.darkMode ? `#000` : '#fff')};
          }
        }
      }
    }
  }
`;

export const List = styled.ul`
  li {
    list-style: none;
    margin: 3rem 0;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.green600)};
  font-size: 2.2rem;
  font-weight: bold;
  padding-top: 2rem;
  text-align: left;
  ${mediaQuery.smallScreen} {
    padding-top: 0.5rem;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.02em;
  margin: 32px 0;

  color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
`;

export const ListStyled = styled.ul`
  margin-left: 20px;
  li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    letter-spacing: 0.02em;

    color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
  }
`;

export const CountList = styled.ul`
  margin-left: 16px;
  li {
    list-style: decimal;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    letter-spacing: 0.02em;

    color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
  }
`;

export const BoxTextIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 500px) {
    svg {
      display: none;
    }
  }

  border: ${(props) => (props.theme.darkMode ? '1px solid white' : 'none')};

  svg {
    width: 70px;
    height: 70px;
    color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
    min-width: 80px;
  }

  padding: 1rem;

  background: ${(props) =>
    props.theme.darkMode ? '#000' : 'rgba(78, 115, 69, 0.1)'};
`;

export const BoxText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  border: ${(props) => (props.theme.darkMode ? '1px solid white' : 'none')};

  letter-spacing: 0.02em;

  padding: 2rem;

  color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
  background: ${(props) =>
    props.theme.darkMode ? '#000' : 'rgba(78, 115, 69, 0.1)'};
`;

export const GroupBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
