export function FisicalImpairmentIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9454 0C6.85203 0.00785 5.98578 0.847583 5.93915 1.9849C5.9471 3.0916 6.79573 3.94937 7.9454 3.99167C8.96917 3.9778 9.87855 3.13375 9.9303 1.9849C9.92253 0.8827 9.07888 0.05025 7.9454 0ZM8.50008 4.43958C8.04475 4.43958 7.66055 4.5925 7.34748 4.89843C7.09313 5.15372 6.80837 5.71678 6.89957 6.16875H6.87822L8.05217 11.825C8.17325 12.4729 8.70647 12.8906 9.41832 12.9349C11.0536 12.9304 12.8268 12.8933 14.498 12.9136L17.3157 17.8229C17.6328 18.4921 18.4446 18.633 19.034 18.1432C19.3167 17.8627 19.4311 17.3853 19.2365 16.9693L15.9709 11.2912C15.7356 10.8044 15.2714 10.5301 14.6902 10.5016H11.0615L10.635 8.4099H13.4735C14.0872 8.4553 14.5292 7.98698 14.4022 7.34272C14.3088 7.02792 13.9826 6.73808 13.6017 6.72347H10.272C10.1042 5.86575 10.1309 5.34725 9.47145 4.75992C9.19397 4.54645 8.87005 4.43958 8.50008 4.43958ZM5.4381 6.86042C5.34788 6.86172 5.25809 6.87307 5.17038 6.89427C3.91298 7.38352 2.78177 8.22428 1.90475 9.39167C1.13193 10.4815 0.699865 11.7933 0.666748 13.2339C0.679615 14.9412 1.41276 16.6933 2.72665 18.0151C4.00012 19.228 5.76773 19.9601 7.6678 20C9.05367 19.9924 10.439 19.5831 11.7022 18.7729C12.8367 18.0023 13.7258 16.8965 14.2631 15.5177C14.3627 15.2758 14.4482 15.0051 14.5194 14.7063C14.6235 14.2365 14.3599 13.8549 13.8579 13.7031C13.4013 13.602 12.993 13.8539 12.8334 14.3438C12.791 14.5561 12.7266 14.7635 12.6412 14.9625C12.2788 15.921 11.6041 16.7506 10.7095 17.3958C9.83972 17.9873 8.79807 18.3094 7.6678 18.3349C6.32365 18.3254 5.02893 17.813 3.94332 16.8412C3.0105 15.9368 2.42695 14.6426 2.39592 13.2339C2.40188 12.2296 2.72528 11.2386 3.33498 10.3417C3.9329 9.51155 4.76992 8.87047 5.78967 8.4526C6.21938 8.31167 6.45088 7.86677 6.30165 7.38542C6.1575 7.0394 5.81792 6.85528 5.4381 6.86042Z"
        fill="white"
      />
    </svg>
  );
}
