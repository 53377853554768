import { useEffect } from 'react';
import { Data } from 'big-data';
import { Card } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';

import { Text, IconBox } from './Demography.styles';
import { UrbanIcon, RuralIcon } from './Demography.icons';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function Demography() {
  const { data, loading, error, request } = useService<Data>();
  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getLocation(city, zone));
  }, [request, city, zone]);

  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const counts = data.map(({ name, value }) => ({
    name,
    value: Math.round((10 * value) / total)
  }));

  const max = counts.reduce(
    (acc, cur) => (cur.value >= acc.value ? cur : acc),
    { name: 'example', value: -Infinity }
  );

  const min = counts.reduce((acc, cur) => (cur.value < acc.value ? cur : acc), {
    name: 'example',
    value: Infinity
  });

  return (
    <Section>
      <Card
        title="Situação das residências"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <IconBox>
          {Array(isFinite(max.value) ? max.value : 5)
            .map((_, i) => ({ id: i }))
            .map((item) =>
              max.name === 'Urbana' ? (
                <UrbanIcon key={item.id} />
              ) : (
                <RuralIcon key={item.id} />
              )
            )}

          {Array(isFinite(min.value) ? min.value : 5)
            .map((_, i) => ({ id: i }))
            .map((item, i) =>
              min.name === 'Urbana' ? (
                <UrbanIcon key={item.id} less={true} />
              ) : (
                <RuralIcon key={item.id} less={true} />
              )
            )}
        </IconBox>

        <Text>
          <span>{max.value} em cada 10</span>
          <p>
            {' '}
            moradias estão localizadas em áreas{' '}
            {max.name === 'Urbana' ? 'urbanas' : 'rurais'}
          </p>
        </Text>
      </Card>
    </Section>
  );
}
