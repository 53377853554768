import styled, { keyframes } from 'styled-components';

import { colors } from '@/theme/colors';

interface BoxProps {
  $collapsed?: boolean;
}

export const InnerBox = styled.div<BoxProps>`
  transition: background-color 0.2s;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  border-radius: ${({ $collapsed }) => ($collapsed ? '50%' : '5px')};

  cursor: pointer;

  :hover {
    background-color: ${colors.green600}33;
  }
`;

const showName = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Name = styled.span`
  font-size: 1rem;
  font-weight: 500;

  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  margin-left: 1rem;
  transform: translateY(1px);

  opacity: 0;

  animation: ${showName} 1.2s linear forwards;
`;

export const Title = styled.h3`
  font-size: 1.2rem;
`;
