import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 30px minmax(400px, fit-content) 80px 1fr;
  grid-template-areas:
    'smallDetails smallDetails'
    'details timeline'
    'dangerZone timeline'
    'comments comments';
  gap: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows:
      30px minmax(400px, fit-content) 80px minmax(400px, fit-content)
      1fr;

    grid-template-areas:
      'smallDetails'
      'details'
      'dangerZone'
      'timeline'
      'comments';
  }

  .small-details {
    grid-area: smallDetails;

    display: flex;
    gap: 0.6rem;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .loading-feedback {
    grid-column: 1/3;
    grid-row: 1/5;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
  }

  .details {
    grid-area: details;
  }

  .danger-zone {
    grid-area: dangerZone;
  }

  .timeline {
    grid-area: timeline;
  }

  .comments {
    grid-area: comments;
  }
`;
