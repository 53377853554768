import {
  citizenAccessUserPageRank,
  CitizenAccessUserParams
} from '@/services/citizenAccessUser';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

export function useCitizenAccessUserPageRank(params?: CitizenAccessUserParams) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    citizenAccessUserPageRank
      .get(params)
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [params?.ibge, params?.minimumDate, params?.order, params?.userId]); // eslint-disable-line

  return {
    data,
    error,
    loading
  };
}
