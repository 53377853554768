import styled, { css } from 'styled-components';

import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';

interface HXProps {
  color?: string;
  mb?: string | number;
}

const titleCSS = css`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${(props) => props.theme.text};
  margin-bottom: 0;
  align-self: flex-start;
`;

export const H1 = styled.h1<HXProps>`
  ${titleCSS}

  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};
`;

export const H2 = styled.h2<HXProps>`
  ${titleCSS}

  font-size: 1.7rem;
  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};
`;

export const H3 = styled.h3<HXProps>`
  ${titleCSS}

  font-size: 1.6rem;
  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};
`;

export const H4 = styled.h4<HXProps>`
  ${titleCSS}
  min-height: 50px;

  font-size: 20px;
  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};

  ${mediaQuery.smallScreen} {
    font-size: 1.4rem;
  }

  font-weight: 700;

  line-height: 24px;
  margin-bottom: ${({ mb }) => (mb ? mb : '1rem')};
`;

export const H5 = styled.h5<HXProps>`
  ${titleCSS}

  font-size: 1.4rem;
  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};
`;

export const H6 = styled.h6<HXProps>`
  ${titleCSS}

  font-size: 1.3rem;
  color: ${({ theme, color }) =>
    theme.darkMode ? theme.text : color ?? theme.text};
`;

export const HBar = styled(H2)`
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: ${() => colors.gray700};
  text-transform: uppercase;
  &::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 3px;

    background-color: ${colors.green200};
  }
`;

export const HSection = styled(H3)`
  color: ${colors.ciano700};
  font-size: 1.6rem;

  margin-left: 0;
`;
