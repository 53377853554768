import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { leaderMovements } from '@/services/leaderMovements';
import { useEffect, useMemo } from 'react';
import { capitalize } from '@/utils/string';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

export function LeaderSexualOrientation() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderMovements.sexualOrientation());
  }, [request]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return { ...d, name: capitalize(d.name) };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Orientação sexual das lideranças"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <HorizontalBarSimple
          maxValue={6}
          sizeRatio={0.7}
          grid
          percentageCalculatedOut
          isPercentage
          data={formattedData}
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
