import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  h3 {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    letter-spacing: 0.03em;
    color: ${(props) =>
      props.theme.darkMode ? colors.white : colors.ciano700};
  }
`;
