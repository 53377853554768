import { Link } from 'react-router-dom';
import { Box } from './styles';

interface Path {
  label: string;
  href: string;
}

interface BreadcrumbProps {
  paths: Path[];
  beginLabel?: string;
}

export function Breadcrumb({ paths, beginLabel }: BreadcrumbProps) {
  return (
    <Box className="header-breadcumb">
      <li>
        <Link to="/">{beginLabel ?? 'Página Inicial'}</Link>
      </li>
      {paths.map((path) => (
        <li key={path.label}>
          <span className="breadcrumb-divider"></span>
          <Link to={path.href}>{path.label}</Link>
        </li>
      ))}
    </Box>
  );
}
