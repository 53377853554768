import { ensureAxiosParamOptions } from '@/utils/routes';
import { Data } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

const service = () => {
  const resource = `beneficio-nivel-inseguranca`;

  function getFilter(filter?: string) {
    if (!filter) return '';

    const formatedFilter = filter
      .replace('ç', 'c')
      .toUpperCase()
      .replaceAll(' ', '_');

    return `classificationFilter=${formatedFilter}`;
  }

  function formatter(filter?: string) {
    if (!filter) return '';

    return filter
      .replace('ç', 'c')
      .toUpperCase()
      .replaceAll(' ', '_');
  }

  async function getTicketGas(
    filter?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data<boolean, number>[]> {
    const filterQuery = getFilter(filter);
    const aggregations = ['VALE_GAS'];
    let path = `${resource}?${filterQuery}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations: getQuery(aggregations),
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((d) => ({
      name: d.vale_gas,
      value: d.quantidade
    }));
  }

  async function getEnergyTax(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data<boolean, number>[]> {
    const aggregations = getQuery(['ISENCAO_ENERGIA']);
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        classificationFilter: formatter(classification),
        aggregations,
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((d) => ({
      name: d.insencao_energia,
      value: d.quantidade
    }));
  }

  async function getWaterTax(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data<boolean, number>[]> {
    const aggregations = getQuery(['ISENCAO_AGUA']);
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations,
        classificationFilter: formatter(classification),
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((d) => ({
      name: d.isencao_agua,
      value: d.quantidade
    }));
  }

  async function getFoodPackage(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data<boolean, number>[]> {
    const aggregations = getQuery(['CESTA_BASICA']);
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations,
        classificationFilter: formatter(classification),
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((d) => ({
      name: d.cesta_basica,
      value: d.quantidade
    }));
  }

  async function getChangingGame(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<Data<boolean, number>[]> {
    const aggregations = getQuery(['VIRANDO_JOGO']);
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations,
        classificationFilter: formatter(classification),
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((d) => ({
      name: d.virando_jogo,
      value: d.quantidade
    }));
  }

  return {
    getTicketGas,
    getEnergyTax,
    getWaterTax,
    getFoodPackage,
    getChangingGame
  };
};

export const insecurityLevelBenefits = service();
