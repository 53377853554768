import { DataIbge } from 'big-data';

interface D3MapFormatProps extends DataIbge {
  filter?: string;
  isPercentage?: boolean;
}

export function toAxlesD3MapFormat(
  data: D3MapFormatProps[],
  filter?: string,
  isPercentage = true
): DataIbge[] {
  const dataToFilter = data.reduce((acc, cur) => {
    const {
      filter: curFilter,
      name: curName,
      value: curValue,
      codIbge: curCodIbge
    } = cur;
    const curFilterLowerCase = curFilter ? curFilter.toLowerCase() : '';
    const curCityWithIbge = `${curName}_${curCodIbge}`;

    return {
      ...acc,
      [curCityWithIbge]: {
        ...acc[curCityWithIbge],
        [curFilterLowerCase]: Number(acc[curFilterLowerCase] ?? 0) + curValue
      }
    };
  }, {} as Record<string, Record<string, number>>);

  const parsedDataToFilter = Object.entries(dataToFilter).map(
    ([key, valueObj]) => {
      const [cityName, cityIbgeCode] = key.split('_');
      const filteredData = filter ?? '';
      return {
        name: cityName,
        codIbge: cityIbgeCode,
        valueOfCurrentAxle: valueObj[filteredData.toLowerCase()] ?? 0,
        axlesSum: Object.values(valueObj).reduce((acc, cur) => acc + cur, 0)
      };
    }
  );

  return parsedDataToFilter.map((data) => ({
    name: data.name,
    value: isPercentage
      ? (data.valueOfCurrentAxle / data.axlesSum) * 100
      : data.axlesSum,
    codIbge: data.codIbge
  }));
}
