import { ensureAxiosParamOptions } from '@/utils/routes';
import { FeedbackOptionType } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';

interface OriginalFeedbackProps {
  nome: string;
  email: string;
  data_avaliacao: Date;
  comentario: string;
  caminho_arquivo: string;
  paginas_totais: number;
}

const feedbackOptionMap = new Map<FeedbackOptionType, string>([
  ['idea', 'Ideia'],
  ['other', 'Outro'],
  ['problem', 'Problema']
]);

const service = () => {
  const resource = `/feedbacks`;

  async function get(
    _feedbackType: FeedbackOptionType,
    minimumDate: string,
    pageNumber = 0,
    pageSize = 4
  ) {
    const path = resource;

    const feedbackType = feedbackOptionMap.get(_feedbackType);

    const options = ensureAxiosParamOptions({
      params: {
        pageNumber,
        pageSize,
        feedbackType,
        minimumDate
      }
    });

    const response = await callService(() =>
      mainApi.get<OriginalFeedbackProps[]>(path, options)
    );

    return response.data;
  }

  return {
    get
  };
};

export const feedbacks = service();
