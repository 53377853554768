import { colors } from '@/theme/colors';
import { dotsNumber } from '@/utils/string';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CzRMData } from 'big-data';
import { DashboardCzrmTableProps } from 'big-data/service';
import { FormEvent, useEffect, useState } from 'react';
import {
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiOutlineDownload
} from 'react-icons/ai';
import { Loading } from '..';
import { AntModal } from '../AntModal';
import { Container, ExportToCSVButton } from './exportToCSV.styles';
import { exportToCSV } from './helpers';

interface ExportToCSVProps {
  request?: (params: DashboardCzrmTableProps) => Promise<CzRMData>;
  params: Omit<DashboardCzrmTableProps, 'pageNumber'>;
  fileTitle: string;
}

export function ExportToCSV({ request, params, fileTitle }: ExportToCSVProps) {
  const { pageSize, totalPages } = params;

  const [modalOpen, setModalOpen] = useState(false);
  const [size, setSize] = useState(pageSize);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState(false);
  const [exportAllChecked, setExportAllChecked] = useState(false);

  const totalFamilies = (pageSize ?? 0) * ((totalPages ?? 0) + 1);

  function onModalOpen() {
    setModalOpen(true);
  }

  function onClear() {
    setSize(pageSize);
    setError(null);
    setTimeout(() => setSuccess(false), 3000);
    setExportAllChecked(false);
  }

  function onExportAllCheck(event: CheckboxChangeEvent) {
    const {
      target: { checked }
    } = event;

    if (checked) {
      setSize(totalFamilies);
    } else {
      setSize(pageSize);
    }

    setExportAllChecked(checked);
  }

  async function onSubmit(event: FormEvent) {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!request) return;

      const response = await request({
        ...params,
        pageNumber: 0,
        pageSize: size
      });

      exportToCSV(response.data, fileTitle);
      setSuccess(true);

      onClear();
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!modalOpen) onClear();
    //eslint-disable-next-line
  }, [modalOpen]);

  return (
    <>
      <ExportToCSVButton
        onClick={onModalOpen}
        title="Exportar lista de famílias"
        aria-label="Exportar lista de famílias"
      >
        Exportar <AiOutlineDownload size={24} />
      </ExportToCSVButton>

      <AntModal visible={modalOpen} onCancel={() => setModalOpen(false)}>
        <Container>
          <h1>Exportar tabela de famílias</h1>

          <form className="group" onSubmit={onSubmit}>
            <div className="form-control">
              <label htmlFor="size">Quantidade de famílias:</label>
              <Input
                type="number"
                name="size"
                placeholder="Quantidade de famílias"
                bordered={false}
                className="czrm-input-search"
                value={size}
                disabled={exportAllChecked}
                onChange={(e) => setSize(Number(e.target.value))}
                min={0}
              />
              <Checkbox onChange={onExportAllCheck} checked={exportAllChecked}>
                <p>
                  Exportar listagem completa de famílias? Total Aproximado:{' '}
                  <strong>{dotsNumber(totalFamilies)}</strong>
                </p>
              </Checkbox>
            </div>

            <button
              type="submit"
              onClick={onSubmit}
              aria-label="Fazer download"
            >
              {loading ? (
                <Loading size={20} color="#ffffff" />
              ) : (
                <AiOutlineDownload size={22} />
              )}
            </button>
          </form>

          {error && (
            <div className="error">
              <AiFillInfoCircle size={20} color={colors.red600} />
              <p>Não foi possível gerar a listagem de famílias!</p>
            </div>
          )}

          {success && (
            <div className="success">
              <AiFillCheckCircle size={20} color={colors.green600} />
              <p>Lista exportada com sucesso!</p>
            </div>
          )}
        </Container>
      </AntModal>
    </>
  );
}
