import { useState } from 'react';

import { AxeDescriptionText as DescriptionText } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';
import { PrivateRoute } from '@/middlewares/PrivateRoute';

import { Box } from './styles';

import { FeedbackTabsSection, FeedbackMainSection } from '../components';

const description =
  'O feedback é uma importante ferramenta para fazer a manutenção da performance da plataforma e aumentar a acertividade do sistema para auxiliar gestores em seus municípios na criação de políticas públicas na área de proteção social. ';

const routeRoles = [`${process.env.REACT_APP_ROLE_PREFIX}ADMIN`];

function PlatformFeedbacks() {
  useDashboardTitle('Feedbacks da Plataforma Big Data Social');
  const [currentTab, setCurrentTab] = useState(0);
  const [filterDateSelected, setFilterDateSelected] = useState('');

  return (
    <Box>
      <DescriptionText>{description}</DescriptionText>
      <FeedbackTabsSection
        tabId={currentTab}
        onClickTab={(_, id) => setCurrentTab(id)}
        filter={{
          onChange: setFilterDateSelected,
          value: filterDateSelected
        }}
      />
      <FeedbackMainSection
        currentTab={currentTab}
        filteredDate={filterDateSelected}
      />
    </Box>
  );
}

export const PlatformFeedbacksPrivate = () => (
  <PrivateRoute component={PlatformFeedbacks} roles={routeRoles} />
);
