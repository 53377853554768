import { Data, DataTree } from 'big-data';

export const colors: Record<string, string> = {
  'fora de insegurança alimentar': '#57AC4B',
  'insegurança alimentar leve': '#EBAD29',
  'insegurança alimentar moderada': '#EA9753',
  'insegurança alimentar grave': '#A71416'
};

const labels: Record<string, string> = {
  'fora de insegurança alimentar': 'FIA',
  'insegurança alimentar leve': 'IAL',
  'insegurança alimentar moderada': 'IAM',
  'insegurança alimentar grave': 'IAG'
};

const percentFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export function getLegendTree(data: DataTree[], selected?: DataTree) {
  return data.map((d) => ({
    name: d.name,
    color: d.color,
    selected: selected && selected.name === d.name
  }));
}

export function getTree(data: Data[]) {
  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const children = data.map((d) => {
    const percent = percentFormatter.format(d.value / total);
    const legend = `${d.name.toLowerCase().replace('insegurança', 'Ins.')} (${
      labels[d.name.toLowerCase()]
    })`;

    return {
      ...d,
      percentageValue: Number(percent.replace('%', '').replace(',', '.')),
      tooltip: `${d.name} ${percent}`,
      color: colors[d.name.toLowerCase()],
      nameInitials: labels[d.name.toLowerCase()],
      legend,
      children: []
    };
  });

  return {
    name: 'Insegurança alimentar',
    children,
    value: 0,
    percentageValue: 0,
    color: 'transparent'
  };
}
