import { Card, PaginatedTable } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useGeneral } from '@/services/lgbt/general';
import { Data } from 'big-data';
import { useEffect } from 'react';

const columns = [
  {
    name: 'Resposta',
    key: 'name'
  },
  {
    name: 'Percentual',
    key: 'value'
  }
];

export function DeficiencyType() {
  const { ibge, orientation, city, identity } = useFilterContext();
  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useGeneral.deficiencyType({ ibge, orientation, genderIdentity: identity })
    );
  }, [request, ibge, orientation, identity]);

  return (
    <Section>
      <Card
        title="Tipos de deficiência das pessoas LGBTQIA+ entrevistadas"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <PaginatedTable
          label="resultados"
          columnTitle={columns}
          pageSize={7}
          percentage
          data={data}
        />
      </Card>
    </Section>
  );
}
