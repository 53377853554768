import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { education } from '@/services/education';
import { useDashboardFilter } from '@/hooks';

const { getNurseryPeriod, getPreschoolPeriod, getSchoolPeriod } = education;

type AxeOptions = 'creche' | 'pré-escola' | 'escola';

const axeMap = new Map<AxeOptions, any>([
  ['creche', getNurseryPeriod],
  ['pré-escola', getPreschoolPeriod],
  ['pré-escola', getSchoolPeriod]
]);

export function usePeriodData({ axeSelected }: { axeSelected?: AxeOptions }) {
  const [periods, setPeriods] = useState<Data[]>([]);
  const [periodsLoading, setPeriodsLoading] = useState(false);
  const [periodsError, setPeriodsError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setPeriodsLoading(true);
    if (!!axeSelected) {
      const request = axeMap.get(axeSelected);

      if (request) {
        request({ ibge, year, semester, zone })
          .then((data: Data[]) => {
            const sortedData = [...data].sort((a, b) => b.value - a.value);
            setPeriods(sortedData);
          })
          .catch((error: Error) => setPeriodsError(error))
          .finally(() => setPeriodsLoading(false));
      }
    } else {
      education
        .getChildrenEnrolledPeriod({
          ibge,
          year,
          semester,
          zone
        })
        .then((data) => {
          const newData = data.map((d) => {
            return {
              name: d.periodo,
              value: d.sum
            }
          })
          setPeriods(newData)
        })
        .catch((error) => setPeriodsError(error))
        .finally(() => setPeriodsLoading(false));
    }
  }, [ibge, year, semester, axeSelected, zone]);

  return useMemo(
    () => ({
      periods,
      periodsLoading,
      periodsError
    }),
    [periods, periodsLoading, periodsError]
  );
}
