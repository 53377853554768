export const strToBool = (v: string) =>
  v.toLowerCase() === 'true' ? true : false;

export const strOrNullToBool = (v: string) => {
  const lowedValue = v.toLowerCase();

  if (lowedValue === 'null') return null;

  return lowedValue === 'true' ? true : false;
};

export const yesOrNoToBool = (v: string) => {
  if (v === 'null') return null;

  return v.toLowerCase() === 'sim' ? true : false;
};

export const strToInt = (v: string) => parseInt(v, 10);
