import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 1.25rem;

  ${mediaQuery.mediumScreen} {
    grid-template-columns: 100%;
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: 100%;
  }
`;
