import { useEffect, useState } from 'react';

import { useAnalyticsTitle } from '@/hooks';
import { livingCondition } from '@/services';
import { ApresentationCard, LastUpdateMessage } from '@/components/shared';

import { CountLabel, LivingConditionInsights } from '../components';
import { useLastUpdate } from '../hooks/useLastUpdate';
import { Box } from './styles';

export function LivingCondition() {
  const { lastUpdate, loading, error } = useLastUpdate('cadunico');
  useAnalyticsTitle('Condições de Moradia dos Inscritos');

  const [amount, setAmount] = useState(0);
  const [loadingAmount, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    livingCondition
      .amount()
      .then((res) => setAmount(res.quantidade))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box>
      <LastUpdateMessage
        value={lastUpdate?.insercao}
        loading={loading}
        error={error}
      />
      <ApresentationCard
        title="número de inscritos"
        source="CADÚNICO"
        text="O analítico de condições de moradia mostra como é o perfil e o tipo de moradia da parcela da população cearense cadastrada no sistema CADÚNICO."
      >
        <CountLabel label="Famílias" value={amount} loading={loadingAmount} />
      </ApresentationCard>
      <LivingConditionInsights />
    </Box>
  );
}
