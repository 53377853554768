import { BdsAid } from '@/bdsIcons';
import { useAnalyticsTitle } from '@/hooks';
import {
  ApresentationCard,
  LastUpdateMessage,
  TextIcon
} from '@/components/shared';

import { HomeVisitInsights } from '../components';
import { useLastUpdate } from '../hooks';

import { Box } from './styles';

const icon = () => <BdsAid />;
const text = () => (
  <p>
    O Programa Mais Infância Ceará, por meio do Programa de Apoio ao
    Desenvolvimento Infantil (Padin), da Secretaria da Educação do Estado, e do
    Primeira Infância no SUAS / Criança Feliz, do Governo Federal, beneficia
    famílias com crianças na primeira infância nos 184 municípios cearenses.
  </p>
);

export function HomeVisit() {
  const { lastUpdate, loading, error } = useLastUpdate('visitas-domiciliares');
  useAnalyticsTitle(
    'Famílias beneficiadas dos programas de Visitas Domiciliares'
  );
  return (
    <Box>
      <LastUpdateMessage
        value={lastUpdate?.insercao}
        loading={loading}
        error={error}
      />

      <ApresentationCard
        title="Perfil dos beneficiados"
        source="Criança Feliz + Padin"
      >
        <TextIcon icon={icon} text={text} />
      </ApresentationCard>
      <HomeVisitInsights />
    </Box>
  );
}
