import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

interface BoxProps {
  $noPadding?: boolean;
}

export const Box = styled.div<BoxProps>`
  background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
  border: 1px solid #dfe6e9;
  box-sizing: border-box;
  border-radius: 7px;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : '1.5rem')};

  width: 100%;
  height: 100%;
  margin: 1rem 0;

  .subtitle {
    display: flex;
    margin-bottom: 1rem;
  }

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      h4.title {
        padding: 1.5rem;
      }

      .tag-box {
        padding-inline: 1.5rem;
      }
    `}
`;

export const EmptyText = styled.p`
  color: #bababa;
`;
export const ErrorText = styled(EmptyText)``;

export const ContentBox = styled.div``;
export const MainBox = styled.div`
  width: 100%;
`;

export const SecondaryBox = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TagBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 1rem;

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    color: ${(props) => (props.theme.darkMode ? '#fff' : colors.ciano700)};
  }
`;
