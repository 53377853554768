import { useMemo, useState } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { Data } from 'big-data';
import { useBoxWidth } from '@/hooks';
import { colors as appColors } from '@/theme/colors';
import { max, min } from '@/utils/math';

import { Box } from './styles';
import { Pie } from './Pie';
import { Legend } from './Legend';

export interface DonutData extends Data {
  color: string;
}

interface VerticalDonutProps {
  data: Data[];
  colors?: string[];
  onClick?: (data: Data) => void;
}

const RANGE_COLORS = [
  appColors.yellow500,
  appColors.green200,
  appColors.orange500,
  appColors.blue600
];

const getColors = (data: Data[], colors?: string[]) => {
  if (!colors || data.length !== colors.length) {
    const minData = min(data.map((d) => d.value));
    const maxData = max(data.map((d) => d.value));

    const colorScale = scaleOrdinal({
      domain: [minData, maxData],
      range: RANGE_COLORS
    });

    return (d: Data) => colorScale(d.value);
  }

  const colorRelation = data.reduce((acc, cur, ind) => {
    return { ...acc, [cur.name]: colors[ind] };
  }, {} as Record<string, string>);

  return (d: Data) => colorRelation[d.name];
};

export function VerticalDonut({ data, colors, onClick }: VerticalDonutProps) {
  const { width, boxRef } = useBoxWidth<HTMLDivElement>(0);
  const height = width * 0.65;
  const centroid: [number, number] = [width / 2, height / 2];

  const [selectedSlice, setSelectedSlice] = useState<DonutData | null>(null);

  function handleClickPieSlice(d: DonutData) {
    setSelectedSlice(selectedSlice ? null : d);

    if (onClick) {
      onClick(d);
    }
  }

  const colorScale = getColors(data, colors);

  const dataWithColor = useMemo<DonutData[]>(
    () => data.map((d) => ({ ...d, color: colorScale(d) })),
    [data, colorScale]
  );

  const filteredData = useMemo(
    () => (selectedSlice ? [selectedSlice] : dataWithColor),
    [selectedSlice, dataWithColor]
  );

  return (
    <Box ref={boxRef}>
      <svg width={width} height={height}>
        <Pie
          data={filteredData}
          centroid={centroid}
          onClick={handleClickPieSlice}
        />
      </svg>

      <Legend data={dataWithColor} selected={selectedSlice} />
    </Box>
  );
}
