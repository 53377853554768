import imagem from '@/assets/images/criancas-acao.jpg';
import * as R from 'ramda';

import { Box } from './styles';

interface ApresentationCardProps {
  name?: string;
}

const getFormattedName = R.pipe(
  R.split(' '),
  R.head,
  R.toLower,
  R.replace(/^./, R.toUpper)
);

export function ApresentationCard({ name }: ApresentationCardProps) {
  const loggedName = getFormattedName(name ?? 'gestor');

  return (
    <Box>
      <div className="img-card">
        <img src={imagem} alt="Foto de crianças comendo e posando para foto" />
      </div>
      <div className="text-card">
        <h2>
          Olá, <span className="username">{loggedName}</span>, bem-vindo de
          volta!
        </h2>
        <p>
          Um plano de ação é uma forma organizada e que segue uma metodologia
          definida para definir as metas, objetivos e ações das atividades que
          devem ser realizadas para a melhoria dos{' '}
          <b>Eixos da Proteção Social</b>, para que se possa atingir os melhores
          resultados nos municípios cearenses entre as famílias em situação de
          vulnerabilidade social mapeadas através dos Agentes Sociais do
          Programa Mais Infância Ceará.
        </p>
        <p>
          Os planos de ação podem ser baseados nos{' '}
          <b>Objetivos de Desenvolvimento Sustentável (ODS)</b>, uma agenda
          mundial adotada durante a Cúpula das Nações Unidas sobre o
          Desenvolvimento Sustentável em setembro de 2015, composta por 17
          objetivos e 169 metas a serem atingidos até 2030.
        </p>
      </div>
    </Box>
  );
}
