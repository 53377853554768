export const filterOrderOptions = [
  {
    label: 'Mais acessados',
    value: 'desc'
  },
  {
    label: 'Menos acessados',
    value: 'asc'
  }
];

export const filterOrderOptionsRejections = [
  {
    label: 'Mais rejeitados',
    value: 'desc'
  },
  {
    label: 'Menos rejeitados',
    value: 'asc'
  }
];

export const statusPlanActionsOptions = [
  {
    label: 'Em progresso',
    value: 'IN_PROGRESS'
  },
  {
    label: 'Concluídos',
    value: 'DONE'
  },
  {
    label: 'Concluídos com atraso',
    value: 'DONE_LATE'
  },
  {
    label: 'Não concluídos',
    value: 'UNDONE'
  },
  {
    label: 'Bloqueados',
    value: 'BLOCKED'
  }
];
