import { capitalize } from '@/utils/string';
import * as R from 'ramda';
import { DonutData } from '../Donut';

import { Box, Ball, ListItem } from './styles';

interface LegendData {
  name: string;
  value: number;
  color: string;
}

interface PercentLegendData extends LegendData {
  percentageValue: string;
}

interface LegendProps {
  data: LegendData[];
  title: string;
  selected?: DonutData;
  onMouseEnter?: (data: LegendData) => void;
  onMouseLeave?: () => void;
  onClick?: (data: PercentLegendData) => void;
}

const percentageFormat = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const bigNumberFormat = new Intl.NumberFormat('pt-BR');

const getTotal = R.reduce((acc, cur: LegendData) => acc + cur.value, 0);

export function Legend({
  data,
  title,
  selected,
  onMouseEnter,
  onMouseLeave,
  onClick
}: LegendProps) {
  const total = getTotal(data);

  const percentData: PercentLegendData[] = R.map(
    (d: LegendData) =>
      R.assoc('percentageValue', percentageFormat.format(d.value / total), d),
    data
  );

  const formattedTotal = bigNumberFormat.format(total);

  function handleMouseEnter(idx: number) {
    if (onMouseEnter) {
      onMouseEnter(data[idx]);
    }
  }

  function handleMouseLeave() {
    if (onMouseLeave) {
      onMouseLeave();
    }
  }

  function handleClick(data: PercentLegendData) {
    if (onClick) {
      onClick(data);
    }
  }

  return (
    <Box>
      <h3>
        <span>{title}</span>
        <span>{formattedTotal}</span>
      </h3>
      <ul className="legend-list">
        {percentData.map((d, idx) => (
          <ListItem
            key={d.color}
            selected={R.isNil(selected) ? undefined : selected.name === d.name}
            onClick={() => handleClick(d)}
            onMouseEnter={() => handleMouseEnter(idx)}
            onMouseLeave={handleMouseLeave}
          >
            <Ball fill={d.color} />
            <span className="name">{capitalize(d.name)}</span>
            <span className="value">{d.percentageValue}</span>
          </ListItem>
        ))}
      </ul>
    </Box>
  );
}
