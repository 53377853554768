import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useEffect, useMemo } from 'react';

import { useService } from '@/hooks';
import { socialMovements } from '@/services/socialMovements';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { capitalize } from '@/utils/string';

const columns = [
  {
    name: 'Nome do vínculo',
    key: 'name'
  }
];

export function MovementsLinksList() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } =
    useService<{ name: string; value?: string }>();

  useEffect(() => {
    request(() => socialMovements.networkMovements());
  }, [request]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return { name: capitalize(d.name) };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Principais vínculos dos movimentos: fóruns, redes e outros"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <ListTable pageSize={6} columnTitle={columns} data={formattedData} />
      </Card>
    </Section>
  );
}
