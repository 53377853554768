import { useRef } from 'react';
import { Navigate, useSearchParams, Link } from 'react-router-dom';
import ReactTsParticles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { oauth2Agent } from '@/services/auth';
import outIcon from '@/assets/icons/out.svg';

import { Box } from './styles';
import { colors } from '@/theme/colors';

export function Error() {
  const boxRef = useRef<any>();
  const [searchParams] = useSearchParams();

  const message = searchParams.get('message');

  function handleLogout() {
    oauth2Agent.logout({
      redirectUrl: process.env.REACT_APP_REDIRECT_URL
    });
  }

  if (!message) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <div className="feedback">
        <AiOutlineExclamationCircle color={colors.green700} />
        <h2>Ops!</h2>
        <p>{message}</p>
        <div className="buttons">
          <Link to="/">Página inicial</Link>
          <button className="logout" onClick={handleLogout}>
            <img src={outIcon} alt="Sair" />
            Sair
          </button>
        </div>
      </div>
      <ReactTsParticles
        className="tsparticles"
        container={boxRef}
        init={async (engine) => await loadFull(engine)}
        height="80vh"
        width="100vw"
        options={{
          fullScreen: {
            enable: false,
            zIndex: 0
          },
          fpsLimit: 60,
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 900
              }
            },
            color: {
              value: 'transparent',
              animation: {
                enable: false,
                speed: 20,
                sync: true
              }
            },
            size: {
              value: {
                min: 0.1,
                max: 1
              }
            },

            links: {
              enable: true,
              distance: 150,
              color: '#2d4040',
              opacity: 0.1,
              width: 0.7
            },
            move: {
              enable: true,
              speed: 1,
              direction: 'none',
              outModes: {
                default: 'bounce'
              }
            }
          }
        }}
      />
    </Box>
  );
}
