interface HealthProps {
  color?: string;
}

export function BdsHealth({ color }: HealthProps) {
  const selectedColor = color ?? '#fff';
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.52 8.15998C23.52 7.89598 23.304 7.67998 23.04 7.67998H16.8C16.536 7.67998 16.32 7.46398 16.32 7.19998V0.95998C16.32 0.69598 16.104 0.47998 15.84 0.47998H8.16001C7.89601 0.47998 7.68001 0.69598 7.68001 0.95998V7.19998C7.68001 7.46398 7.46401 7.67998 7.20001 7.67998H0.960011C0.696011 7.67998 0.480011 7.89598 0.480011 8.15998V15.84C0.480011 16.104 0.696011 16.32 0.960011 16.32H7.20001C7.46401 16.32 7.68001 16.536 7.68001 16.8V23.04C7.68001 23.304 7.89601 23.52 8.16001 23.52H15.84C16.104 23.52 16.32 23.304 16.32 23.04V16.8C16.32 16.536 16.536 16.32 16.8 16.32H23.04C23.304 16.32 23.52 16.104 23.52 15.84V8.15998Z"
        fill={selectedColor}
      />
    </svg>
  );
}
