import { DataPercentageComputed } from 'big-data';

import oleoIcon from '@/assets/icons/oleo.svg';
import velaIcon from '@/assets/icons/vela.svg';
import eletricaIcon from '@/assets/icons/eletrica.svg';
import fosforoIcon from '@/assets/icons/fosforo.svg';

const imgs: Record<string, string> = {
  'Óleo, querosene ou gás': oleoIcon,
  Vela: velaIcon,
  Elétrica: eletricaIcon,
  Outra: fosforoIcon
};

export function getLightsDataWithImage(data: DataPercentageComputed[]) {
  return data.map((d) => ({
    ...d,
    img: imgs[d.name]
  }));
}
