import { useEffect } from 'react';
import { useChart } from '../Provider';

import { getArcFunction2 } from '../utils';

export function Icons() {
  const { chart, arcs } = useChart();

  useEffect(() => {
    if (!chart || !arcs) return;

    const width = Number(chart.attr('width'));
    const height = Number(chart.attr('height'));
    const radius = Math.max(width, height) / 2;

    const arc = getArcFunction2(radius);

    arcs
      .append('svg:image')
      .attr('xlink:href', (d) => d.data.img ?? '')
      .attr('transform', (d) => `translate(${arc.centroid(d)})`)
      .attr('x', -14)
      .attr('y', -14)
      .attr('width', '26px')
      .attr('height', '26px')
      .attr('cursor', 'pointer');
  }, [chart, arcs]);

  return null;
}
