import { timeInterval } from './helper';

export const tabItems = [
  {
    id: 0,
    name: 'Ontem',
    value: timeInterval('yesterday')
  },
  {
    id: 1,
    name: 'Última semana',
    value: timeInterval('week')
  },
  {
    id: 2,
    name: 'Último mês',
    value: timeInterval('month')
  },
  {
    id: 3,
    name: 'Último semestre',
    value: timeInterval('semester')
  },
  {
    id: 4,
    name: 'Todos os acessos',
    value: ''
  }
];
