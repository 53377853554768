import { mediaQuery } from '@/theme/mediaQuery';
import styled, { keyframes } from 'styled-components';

export const PhotoEffect = ({ src }: any) => {
  return (
    <Wrapper>
      <ImageBackground src={src} />
      <RingA />
      <RingB />
      <RingC />
    </Wrapper>
  );
};

const ringRotate = keyframes`
  from { transform: rotate(0) }
  to { transform: rotate(360deg) }
`;

const BaseRing = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  right: -350px;
  top: -110px;
  width: 850px;
  height: 850px;
  border: 20px solid #00b4c559;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${ringRotate} 60s linear infinite;
  transform-origin: center center;
`;

const RingA = styled(BaseRing)`
  right: -325px;
  top: -85px;
  width: 800px;
  height: 800px;
  border-width: 10px;
  animation-delay: -3s;
  animation-duration: 30s;
`;
const RingB = styled(BaseRing)`
  border-right-color: transparent;
`;

const RingC = styled(BaseRing)`
  right: -430px;
  top: -180px;
  width: 1000px;
  height: 1000px;
  border-width: 10px;
  animation-delay: -1s;
  animation-duration: 50s;
`;

const ImageBackground = styled.div<{ src: string }>`
  background: no-repeat center;
  background-image: ${(props) => `url(` + props.src + `)`};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 400px;
  height: 400px;
  ${ImageBackground} {
    position: absolute;
    right: 0;
    width: 400px;
    height: 400px;
    border-radius: 75% 10px 10px 20px;

    @media (max-width: 1250px) and (min-width: 1150px) {
      width: 350px;
      height: 350px;
    }
  }
  ${mediaQuery.smallScreen} {
    display: none;
  }
  ${mediaQuery.mediumScreen} {
    display: none;
  }
`;
