import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useOutSchoolReason() {
  const [outSchoolReason, setOutSchoolReason] = useState<Data[]>([]);
  const [outSchoolReasonError, setOutSchoolReasonError] =
    useState<Error | null>(null);
  const [outSchoolReasonLoading, setOutSchoolReasonLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setOutSchoolReasonLoading(true);
    education
      .getOutSchoolReason(ibge, year, semester, zone)
      .then((data) => {
        setOutSchoolReason(data.sort((a, b) => b.value - a.value));
      })
      .catch((error) => setOutSchoolReasonError(error))
      .finally(() => setOutSchoolReasonLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      outSchoolReason,
      outSchoolReasonLoading,
      outSchoolReasonError
    }),
    [outSchoolReason, outSchoolReasonLoading, outSchoolReasonError]
  );
}
