import { DonutNoLegend, ServiceBox2 } from '@/components/shared';
import { useUsersDeviceTypes } from '@/servicesHooks/googleAnalytics';
import { colors } from '@/theme/colors';
import { sumDataTotal } from '@/utils/data';
import { useTheme } from 'styled-components';
import { CardContent, CardHeader } from '../../../components';
import { useFilterContext, useTabContext } from '../../../contexts';
import { generateTag } from '../../../utils/helper';
import { Box } from './styles';

export function CardUsedDevices() {
  const { darkMode } = useTheme();
  const { city } = useFilterContext();
  const { tab } = useTabContext();
  const { data, error, loading } = useUsersDeviceTypes(tab?.value, city?.ibge);

  const deviceColors = darkMode
    ? [colors.yellow200, colors.yellow500, colors.yellow800]
    : [colors.ciano700, colors.ciano500, colors.blue400];

  const total = sumDataTotal(data);
  const dataColored = data.map((d, i) => {
    return {
      ...d,
      color: deviceColors[i] || '#000',
      percentage: (d.value / total) * 100
    };
  });

  return (
    <Box>
      <CardHeader
        title="Dispositivos utilizados"
        subtitle={generateTag([city?.name, tab?.name])}
      />

      <ServiceBox2
        empty={dataColored.length === 0}
        loading={loading}
        error={error?.message}
        className="service-box"
      >
        <CardContent direction="vertical">
          <DonutNoLegend data={dataColored} width={234} legend />
        </CardContent>
      </ServiceBox2>
    </Box>
  );
}
