import { useEffect } from 'react';
import { Sanitation } from 'big-data';

import { Card, SanitaryCard } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';

import { GroupBox } from './SanitaryInfo.styles';
import { SanitaryFalseCard } from '@/components/shared/InfoCharts/SanitaryFalseCard';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

const sanitaryMap = {
  'only-bathroom': { pipedWater: false, bathroom: true, value: 0 },
  'only-pipedwater': { pipedWater: true, bathroom: false, value: 0 },
  both: { pipedWater: true, bathroom: true, value: 0 }
};

const getKey = (pipedWater: boolean, bathroom: boolean) => {
  if (pipedWater && bathroom) return 'both';

  if (pipedWater) return 'only-pipedwater';

  if (bathroom) return 'only-bathroom';
};

export function SanitaryInfo() {
  const { data, loading, error, request } = useService<Sanitation>();
  const { city, zone } = useAnalyticsFilter();
  useEffect(() => {
    request(() => livingCondition.getSanitation(city, zone));
  }, [request, city, zone]);

  const total = data.reduce((acc, cur) => acc + cur.value, 0);
  const percentageData = Object.values(
    data
      .filter((d) => d.bathroom || d.pipedWater)
      .reduce((acc, cur) => {
        const key = getKey(cur.pipedWater, cur.bathroom);

        if (key) {
          const curValue = acc[key];
          return {
            ...acc,
            [key]: { ...curValue, value: curValue.value + cur.value }
          };
        }

        return acc;
      }, sanitaryMap)
  ).map(({ value, ...d }, i) => ({
    ...d,
    id: i,
    value: (value * 100) / total
  }));

  const percentageRest =
    100 - percentageData.reduce((acc, cur) => acc + cur.value, 0);

  return (
    <Section>
      <Card
        title="Porcentagem de famílias com abastecimento de água e acesso a esgotamento sanitário"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <GroupBox>
          {percentageData.map((d, i) => (
            <SanitaryCard key={d.id} data={d} />
          ))}

          <SanitaryFalseCard value={percentageRest} />
        </GroupBox>
      </Card>
    </Section>
  );
}
