import { googleAnalytics } from '@/services/googleAnaltyics';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

const { home: googleAnalyticsHome } = googleAnalytics;

export function useRegionAccess() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    googleAnalyticsHome
      .getRegionAccess()
      .then((sorted) => setData(sorted))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    loading,
    error
  };
}
