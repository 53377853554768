import styled from 'styled-components';
import { mediaQuery } from '../../theme/mediaQuery';
import { PageSection } from '../UI/PageSection';

import {
  ColumnCeara,
  ColumnLinks,
  ColumnSocial,
  Copyright,
  StripColors
} from './components';

const COPYRIGHT_APP_NAME = `Governo do Estado do Ceará`;

export function Footer() {
  return (
    <Wrapper>
      <StripColors />
      <Container margin={20} padding={20}>
        <ColumnCeara />
        <ColumnLinks />
        <ColumnSocial />
      </Container>
      <Copyright application={COPYRIGHT_APP_NAME} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${(props) => props.theme.background};
`;

const Container = styled(PageSection)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  ${mediaQuery.mediumScreen} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery.smallScreen} {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    a {
      margin: 0 5px;
    }
  }
`;
