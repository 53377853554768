import { useAuth, useDashboardTitle } from '@/hooks';

import { ThirdTitle } from '@/components/styled';
import { actions } from '@/constants/actions';

import { Box } from './styles';
import { ApresentationCard, IndicatorCard } from '../components';

export function Home() {
  useDashboardTitle('Plano de Ação dos Gestores');

  const { user } = useAuth();
  const client = user?.client;

  return (
    <Box>
      <ApresentationCard name={client?.name} />
      <ThirdTitle>Eixos da Proteção Social</ThirdTitle>
      <p>
        Conheça as ODS relacionadas aos eixos da proteção social e navegue para
        ter acesso aos planos de ação cadastrados pelos gestores.
      </p>
      <div className="axes">
        {actions.map((action) => {
          return <IndicatorCard key={action.id} indicator={action} />;
        })}
      </div>
    </Box>
  );
}
