import { Card, D3Map } from '@/components/shared';
import { D3MapMacroregions } from '@/components/shared/D3MapMacroregions';
import { RadioGroup } from '@/components/styled';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useGeneral } from '@/services/lgbt/general';
import { colors } from '@/theme/colors';
import { RadioChangeEvent } from 'antd';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

const options = [
  { label: 'Municípios', value: 'municipios' },
  { label: 'Macrorregiões', value: 'macrorregioes' }
];

export function StateMap() {
  const [mapaType, setMapaType] = useState<'municipios' | 'macrorregioes'>(
    'municipios'
  );

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setMapaType(value);
  };

  const { ibge, orientation, city, identity, handleChangeCity } =
    useFilterContext();
  const { data, loading, error, request } = useService<Data>();

  const { darkMode } = useTheme();
  const mapColor = darkMode ? colors.ming100 : colors.ciano700;

  useEffect(() => {
    request(() =>
      useGeneral.city({ ibge, orientation, genderIdentity: identity })
    );
  }, [request, ibge, orientation, identity]);

  const {
    data: regions,
    loading: loadingRegions,
    request: requestRegions
  } = useService<Data>();

  useEffect(() => {
    requestRegions(() =>
      useGeneral.region({ ibge, orientation, genderIdentity: identity })
    );
  }, [requestRegions, ibge, orientation, identity]);

  const dataFormatted = useMemo(() => {
    return regions.map((d) => {
      const s = d.name.split('Região d')[1];
      return { ...d, name: s.slice(2, s.length) };
    });
  }, [regions]);

  return (
    <Section>
      <Card
        title="Pessoas LGBTQIA+ por macrorregião e por município"
        loading={loading && loadingRegions}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <RadioGroup
          options={options}
          onChange={onChange}
          optionType="button"
          defaultValue={'municipios'}
        />
        {mapaType === 'municipios' ? (
          <D3Map
            mapDescription="Passe o mouse pelo mapa para visualizar a quantidade de sedes por município no Ceará"
            data={data}
            color={mapColor}
            rotule="Pessoa"
            filteringCity={city}
            handleSelectedCity={handleChangeCity}
          />
        ) : (
          <D3MapMacroregions
            label="pessoa"
            data={dataFormatted}
            color={mapColor}
            intervalCostume={[
              { min: 1, max: 99 },
              { min: 100, max: 249 },
              { min: 250, max: 499 },
              { min: 500, max: 500 }
            ]}
          />
        )}
      </Card>
    </Section>
  );
}
