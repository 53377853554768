import styled from 'styled-components';
import { getSize } from '@/theme/utils';

export type LegendItemProps = {
  fill: string;
};

export type LoadingBoxProps = {
  height?: number | string;
};

export type ContentBoxProps = {
  isClickable?: boolean
}

export const Box = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const ContentBox = styled.div<ContentBoxProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  /* 
  @media (max-width: 510px) {
    flex-direction: column;
    max-width: 260px;
    width: 100%;
  } */

  .pie {
    cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'nordefaultmal'};
  }
`;

export const LoadingBox = styled.div<LoadingBoxProps>`
  height: ${({ height }) => getSize(height ?? '200px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LegendBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 510px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.7rem;
    margin-left: 10px;
  }
`;

export const LegendItem = styled.div<LegendItemProps>`
  height: 70px;
  margin: 15px 0;

  display: flex;
  align-items: center;

  @media (max-width: 510px) {
    width: 140px;
    margin: 5px 0;
  }

  &::before {
    content: '';
    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-right: 10px;
    height: 100%;
    width: 6px;
    background-color: ${({ fill }) => fill};
  }
`;

export const LegendTextBox = styled.div``;

export const LegendPercent = styled.p`
  font-size: 1.6rem;
  font-weight: 600;

  .suffix {
    margin-left: 1px;
    font-size: 1.1rem;
  }
`;
export const LegendLabel = styled.p`
  font-size: 1rem;
`;

export const Svg = styled.svg`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const BaseText = styled.text`
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 22px;
  text-align: center;
`;

export const MiddleText = styled.text`
  font-weight: bold;
  font-size: 1.2rem;
`;
