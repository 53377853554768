import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Grid = styled.div`
  margin-top: 60px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 80px;
  justify-content: center;
  justify-items: start;
  padding-top: 20px;
  & > div {
    padding: 20px;
  }
  ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr;
    & > div {
      padding: 10px;
      .rect-effect {
        margin-left: 7px;
        margin-top: 12px;
        width: 5px;
        height: 50px;
      }
    }
  }
`;
