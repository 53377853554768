export function FontMinusIcon({ color = '#465564' }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      width="24"
      height="24"
      viewBox="0, 0, 24, 24"
    >
      <path
        d="M7.069,13.475 L9.947,5.554 L9.995,5.554 L12.825,13.475 z M8.758,3.509 L2.146,20.491 L4.453,20.491 L6.356,15.377 L13.539,15.377 L15.394,20.491 L17.891,20.491 L11.255,3.509 z"
        fill={color}
      />
      <path
        d="M15.116,7.107 L15.116,9.047 L22.442,9.047 L22.442,7.107 z"
        fill={color}
      />
    </svg>
  );
}
