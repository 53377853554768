import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAuth } from './useAuth';
import cities from '@/constants/cities.json';

interface DashboardFilterContextProviderProps {
  children: ReactNode;
}

interface DashboardFilterContextDataProps {
  city?: string;
  yearSemester?: string;
  loading?: boolean;
  onChangeYearSemester?: (yearSemester: string) => void;
  onEraseFilter: () => void;
  onCancel: () => void;
  onChangeCity?: (city: string) => void;
  onLoading: () => void;
  ibge?: string;
  semester?: string;
  year?: string;
  zone?: string;
  onChangeZone?: (zone: string) => void;
  tag: string;
  isData: boolean;
  isNull: () => void;
}

export const DashboardFilterContext = createContext(
  {} as DashboardFilterContextDataProps
);

export function DashboardFilterProvider({
  children
}: DashboardFilterContextProviderProps) {
  const [city, setCity] = useState<string>();
  const [ibge, setIbge] = useState<string>();
  const [year, setYear] = useState<string>();
  const [zone, setZone] = useState<string>();
  const [semester, setSemester] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isData, setIsData] = useState(false);

  const { userCity, isAdm } = useAuth();
  useEffect(() => {
    const city = userCity
      ? userCity.charAt(0).toUpperCase() + userCity.slice(1).toLowerCase()
      : undefined;
    setCity(city);
    const ibge = cities.find(
      (c) => c.municipio.toLowerCase() === city?.toLowerCase()
    );
    setIbge(ibge?.ibge);
  }, [userCity]);

  const yearDotSemester = year && semester ? `${year}.${semester}` : undefined;
  const zoneText = zone ? ` / ${zone}` : '';

  const chartTag = `${city || 'CEARÁ'}${
    yearDotSemester ? ` / ${yearDotSemester}` : ''
  }${zoneText}`;

  // here
  const onChangeCity = useCallback((city: string) => {
    const [name, ibge] = city?.split('_') ?? [];
    setCity(name);
    setIbge(ibge);
  }, []);

  const isNull = useCallback(() => {
    setIsData(!isData);
    if (isAdm) {
      setCity(undefined);
      setIbge(undefined);
    }
  }, [isData, isAdm]);

  const onCancel = useCallback(() => {
    setIsData(!isData);
  }, [isData]);

  const onChangeYearSemester = useCallback((yearSemester: string) => {
    const [year, semester] = yearSemester?.split('.') ?? [];
    setYear(year);
    setSemester(semester);
  }, []);

  const onChangeZone = useCallback((_zone: string) => {
    setZone(_zone);
  }, []);

  const onEraseFilter = useCallback(() => {
    if (isAdm) {
      setCity(undefined);
      setIbge(undefined);
    }
    setSemester(undefined);
    setYear(undefined);
    setZone(undefined);
  }, [isAdm]);

  const onLoading = useCallback(() => {
    setLoading((current) => !current);
  }, []);

  const contextProviderValue = useMemo(
    () => ({
      city,
      ibge,
      loading,
      yearSemester: yearDotSemester,
      semester,
      year,
      tag: chartTag,
      zone,
      isData,
      onChangeYearSemester,
      onEraseFilter,
      onChangeCity,
      onLoading,
      onChangeZone,
      onCancel,
      isNull
    }),
    [
      city,
      ibge,
      loading,
      yearDotSemester,
      semester,
      year,
      chartTag,
      zone,
      isData,
      onChangeYearSemester,
      onEraseFilter,
      onChangeCity,
      onLoading,
      onChangeZone,
      onCancel,
      isNull
    ]
  );

  return (
    <DashboardFilterContext.Provider value={contextProviderValue}>
      {children}
    </DashboardFilterContext.Provider>
  );
}

export function useDashboardFilter() {
  return useContext(DashboardFilterContext);
}
