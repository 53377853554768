import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useYearsSemestersData() {
  const [yearsSemesters, setyearsSemesters] = useState<Data[]>([]);
  const [yearsSemestersError, setyearsSemestersError] = useState<Error | null>(
    null
  );
  const [yearsSemestersLoading, setyearsSemestersLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setyearsSemestersLoading(true);
    profileSearch
      .getYearsSemesters()
      .then((yearsSemestersResponse) =>
        setyearsSemesters(yearsSemestersResponse)
      )
      .catch((error) => setyearsSemestersError(error))
      .finally(() => setyearsSemestersLoading(false));
  }, []);

  return useMemo(
    () => ({
      yearsSemesters,
      yearsSemestersError,
      yearsSemestersLoading
    }),
    [yearsSemesters, yearsSemestersError, yearsSemestersLoading]
  );
}
