export function HalfPeriodIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.52002C9.69012 0.52002 6.51578 1.83487 4.17533 4.17533C1.83487 6.51578 0.52002 9.69012 0.52002 13C0.520105 14.6389 0.842994 16.2618 1.47025 17.7759C2.09751 19.29 3.01685 20.6657 4.17579 21.8245C5.33472 22.9833 6.71055 23.9025 8.22473 24.5296C9.73891 25.1567 11.3618 25.4795 13.0007 25.4794C14.6396 25.4793 16.2624 25.1564 17.7765 24.5291C19.2906 23.9019 20.6664 22.9825 21.8252 21.8236C22.984 20.6647 23.9032 19.2888 24.5303 17.7747C25.1574 16.2605 25.4801 14.6376 25.48 12.9987C25.48 6.10742 19.8913 0.52002 13 0.52002ZM13 22.8787C11.7026 22.8787 10.4178 22.6232 9.21911 22.1267C8.02041 21.6301 6.93125 20.9024 6.0138 19.9849C5.09636 19.0675 4.36861 17.9783 3.87209 16.7796C3.37557 15.5809 3.12002 14.2962 3.12002 12.9987C3.12002 11.7013 3.37557 10.4165 3.87209 9.21781C4.36861 8.01911 5.09636 6.92995 6.0138 6.01251C6.93125 5.09506 8.02041 4.36731 9.21911 3.87079C10.4178 3.37427 11.7026 3.11872 13 3.11872V13L21.8296 8.58522C22.5199 9.95453 22.8797 11.4666 22.88 13C22.8797 15.6201 21.8386 18.1328 19.9858 19.9854C18.133 21.838 15.6201 22.8787 13 22.8787Z"
        fill="white"
      />
    </svg>
  );
}
