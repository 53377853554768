import { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  onClick: MouseEventHandler;
}

export const ButtonBurger = ({ isOpen, onClick }: Props) => {
  return (
    <Button onClick={onClick}>
      <SvgBurger cross={isOpen}>
        <LineTop />
        <LineMiddle />
        <LineBottom />
      </SvgBurger>
    </Button>
  );
};

const Button = styled.button`
  border: none;
  width: 30px;
  height: 30px;
`;

const SvgPath = styled.path`
  fill: #586572;
  transform-origin: center;
  transition: 0.1s;
`;

const LineTop = styled(SvgPath).attrs({
  d: `M0,2.761 L22,2.761 L22,4.792 L0,4.792 L0,2.761 z`
})``;

const LineMiddle = styled(SvgPath).attrs({
  d: `M-0,9.984 L16.438,9.984 L16.438,12.016 L-0,12.016 L-0,9.984 z`
})``;

const LineBottom = styled(SvgPath).attrs({
  d: `M0,17.208 L22,17.208 L22,19.239 L0,19.239 L0,17.208 z`
})``;

const SvgBurger = styled.svg.attrs({
  version: `1.1`,
  xmlns: `http://www.w3.org/2000/svg`,
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  width: 22,
  height: 22,
  viewBox: `0 0 22 22`
})<{ cross: boolean }>`
  ${LineMiddle} {
    opacity: ${(props) => (props.cross ? 0 : 1)};
  }
  ${LineTop} {
    transform: ${(props) =>
      props.cross ? `translate(-6px,5px) rotate(45deg)` : null};
  }
  ${LineBottom} {
    transform: ${(props) =>
      props.cross ? `translate(-6px,-5px) rotate(-45deg)` : null};
  }
`;
