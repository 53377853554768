import { ensureAxiosParamOptions } from '@/utils/routes';
import { strToInt } from '@/utils/types';
import { AxiosRequestConfig } from 'axios';
import { CoisBeneficiary } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

function calculteDeedLineByYearSum(item: object) {
  const totalToEachOne = Object.entries(item)
    .filter(([key]) => key.startsWith('ano'))
    .reduce((acc, [_, value]) => acc + value, 0);

  return { value: totalToEachOne };
}

const service = () => {
  const resource = `/cois`;

  async function getCoisInfo() {
    const path = resource;

    const response = await callService(() =>
      mainApi.get<CoisBeneficiary[]>(path)
    );

    return response.data;
  }

  async function getTotal() {
    const path = `${resource}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((data) => ({
      name: data.linha_de_acao,
      ...calculteDeedLineByYearSum(data)
    }));
  }

  async function getCities(
    codIbge: string | null = null,
    deedLine: string | null = null,
    year: string | null = null
  ) {
    const aggregations = [
      'MUNICIPIO',
      'IBGE',
      !!deedLine ? 'LINHA_DE_ACAO' : ''
    ];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const deedLineInQueryParamFormat = deedLine
      ?.replace(/ç/iu, 'C')
      .replace(/ã/iu, 'A')
      .replace(/[^\w]+/g, '_')
      .replace(/(_$)/, '');

    const options: AxiosRequestConfig = {
      params: {
        ibge: codIbge,
        lineOfAction: deedLineInQueryParamFormat,
        year: year?.toUpperCase()
      }
    };

    const response = await callService(() =>
      mainApi.get<any[]>(path, ensureAxiosParamOptions(options))
    );
    const data = response.data;

    const dataWithTotal = data.map((item) => ({
      ...item,
      ...calculteDeedLineByYearSum(item)
    }));

    return dataWithTotal.map((city) => ({
      name: city.municipio,
      codIbge: strToInt(city.ibge),
      value: city.value,
      deedLine: city.linha_de_acao
    }));
  }

  async function getAvailableYears() {
    const aggregations = ['LINHA_DE_ACAO'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    const data = response.data;

    return Object.keys(data[0])
      .filter((key) => key.startsWith('ano'))
      .map((key) => {
        const year = key.replace('ano_', '');
        return {
          name: key,
          value: strToInt(year)
        };
      });
  }

  async function getDeedLines() {
    const aggregations = ['LINHA_DE_ACAO'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));
    const data = response.data;
    return data.map((item) => ({
      name: item.linha_de_acao,
      ...calculteDeedLineByYearSum(item)
    }));
  }

  return {
    getCoisInfo,
    getTotal,
    getCities,
    getAvailableYears,
    getDeedLines
  };
};

export const cois = service();
