import { useDashboardFilter } from '@/hooks';
import { socialAssistance } from '@/services/socialAssistance';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useProgramData() {
  const [dataProgramCras, setDataProgramCras] = useState<Data[]>([]);
  const [dataPrograCreas, setDataPrograCreas] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);

    const promises = [
      socialAssistance.getProgramCras,
      socialAssistance.getProgramCreas
    ];

    Promise.all(promises.map((promise) => promise(ibge, year, semester, zone)))
      .then(([dataCras, dataCreas]) => {
        setDataProgramCras(dataCras);
        setDataPrograCreas(dataCreas);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      dataProgramCras,
      dataPrograCreas,
      loading,
      error
    }),
    [dataProgramCras, dataPrograCreas, loading, error]
  );
}
