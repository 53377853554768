import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

interface RadioProps {
  selected?: boolean;
  order: number;
}

export const Box = styled.ul`
  list-style: none;
  display: flex;
`;

const radioColorSelected = css<RadioProps>`
  ${({ theme }) =>
    theme.darkMode ? `color: ${colors.yellow500}` : `color: ${colors.ciano700}`}
`;

const radioColorNotSelected = css<RadioProps>`
  ${({ theme }) =>
    theme.darkMode ? `color: ${colors.white}` : `color: #000000d9`}
`;

export const Radio = styled.li<RadioProps>`
  padding: 0.4rem 1rem;
  border-style: solid;
  border-width: 1px;

  cursor: not-allowed;

  transition: border-color 0.2s;

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right-width: ${({ selected, order }) =>
      order === 1 && selected ? '1px' : '0'};
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &:not(:first-child) {
    border-left-width: ${({ selected, order }) =>
      order !== 1 && selected ? '1px' : '0'};
  }

  ${(props) => (props.selected ? radioColorSelected : radioColorNotSelected)};
  border-color: ${({ selected, theme }) => {
    if (selected) return theme.darkMode ? colors.yellow500 : colors.ciano700;

    return theme.darkMode ? colors.white : colors.gray400;
  }};
`;
