import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const AccordionRoot = styled.div`
  .unified-list-container {
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    border: 1px solid #dfe6e9;
    border-radius: 7px;
    padding: 0 1rem;
    margin-top: 20px;

    &.expanded {
      padding-bottom: 1rem;
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    padding-right: 0.5rem;

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;

      &:hover {
        background-color: #555;
      }
    }

    &.expanded {
      max-height: 500px;
      overflow: auto;
      transition: max-height 0.5s ease-in;
    }
  }
`;
