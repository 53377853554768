import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { cmic } from '@/services';
import { DataWithIbgeCode } from 'big-data';
import { useEffect, useMemo } from 'react';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

interface FamiliesBarChartProps {
  color: string;
}

export function FamiliesBarChart({ color }: FamiliesBarChartProps) {
  const { data, loading, request } = useService<DataWithIbgeCode>();

  const { ibge, city } = useAnalyticsFilter();

  useEffect(() => {
    request(() =>
      cmic.getCitiesWithCodIbge().then((data) =>
        data
          .map((d) => ({ ...d, value: Number(d.value) }))
          .sort((a, b) => b.value - a.value)
          .filter((d) => d.name !== 'null')
      )
    );
  }, [request]);

  const total = useMemo(() => {
    return data.reduce((acc, cur) => acc + cur.value, 0);
  }, [data]);

  const filteredCity = useMemo(() => {
    if (!!ibge)
      return data
        .map((d) => {
          return { ...d, value: (d.value * total) / 100 };
        })
        .filter((d) => d.codIbge === Number(ibge));

    return data;
  }, [data, ibge, total]);

  return (
    <Section>
      <Card
        title="Municípios com maior número de famílias beneficiadas pelo Cartão Mais Infância"
        empty={data.length === 0}
        loading={loading}
        date="2021"
        tags={[city ? city : 'Ceará']}
      >
        <HorizontalBarSimple
          sizeRatio={0.7}
          maxValue={5}
          color={color}
          data={filteredCity}
          isPercentage
          hasPagination
          grid
        />
      </Card>
    </Section>
  );
}
