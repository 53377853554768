import { Data } from 'big-data';
import { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';

import { colors as appColors } from '@/theme/colors';

import { BaseText, Box, ContentBox, MiddleText, Svg } from './style';
import { Pie } from './Pie';
import { Legend } from './Legend';
import { useTheme } from 'styled-components';
import { Show } from '../..';

interface DonutProps {
  data: Data[];
  thickness: number;
  labelOnDonut?: boolean;
  label?: string;
  subLabel?: string;
  percentage?: boolean;
  animate?: boolean;
  colors?: string[];
  exactColors?: boolean;
  onClick?: (data: Data) => void;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  donutWidth?: number;
  donutHeight?: number;
  hasMiddleValue?: boolean;
  initialRenderData?: Data;
  highlight?: (data: Data) => boolean;
  showPercentage?: boolean;
  hiddenValue?: boolean;
  calculatedOut?: boolean;
  noFilter?: boolean;
}

const RANGE_COLORS = [
  appColors.yellow500,
  appColors.green100,
  appColors.orange300,
  appColors.blue500
];

const margins = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20
};

const manualColor = (data: Data[], colors?: string[]) => {
  if (!colors || data.length !== colors.length) return;

  const colorRelation = data.reduce((acc, cur, ind) => {
    acc.set(cur.name, colors[ind]);

    return acc;
  }, new Map<string, string>());

  return (d: Data) => {
    return colorRelation.get(d.name) as string;
  };
};

const percentageFormat = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const numFormat = new Intl.NumberFormat('pt-BR');

const getMiddleText = (
  showHome: boolean,
  value: number,
  percentage: boolean = false
) => {
  if (!showHome) return '';
  if (percentage) return percentageFormat.format(value);

  return numFormat.format(value);
};

function DonutComponent({
  data,
  thickness,
  label,
  percentage = false,
  animate = true,
  colors = ['#87c878', '#2c403f'],
  exactColors,
  onClick,
  flexDirection = 'row',
  donutWidth,
  donutHeight = 250,
  hasMiddleValue = true,
  initialRenderData,
  subLabel,
  highlight,
  showPercentage = true,
  labelOnDonut = false,
  hiddenValue = false,
  calculatedOut = false,
  noFilter = false
}: DonutProps) {
  const boxRef = useRef<HTMLDivElement>(null);
  const { darkMode } = useTheme();

  const [width, setWidth] = useState(donutWidth ?? 250);
  const [selectedPiece, setSelectedPiece] = useState<Data | null>(null);

  const orderedData = [...data].sort((a, b) => b.value - a.value);

  const total = useMemo(() => {
    if (selectedPiece) return selectedPiece.value;

    return data.reduce((acc, cur) => acc + cur.value, 0);
  }, [data, selectedPiece]);

  const dataFormatted = useMemo(() => {
    if (!percentage) return data;
    const totalData = calculatedOut
      ? 100
      : data.reduce((acc, cur) => acc + cur.value, 0);
    return data.map((d) => {
      return { ...d, value: d.value / totalData };
    });
  }, [data, percentage, calculatedOut]);

  const values = dataFormatted.map((d) => d.value);
  const max = Math.max(...values);
  const min = Math.min(...values);

  const getManualColor = manualColor(dataFormatted, colors);
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: [min, max],
        range: RANGE_COLORS
      }),
    [min, max]
  );

  const getColor = (data: Data) => {
    if (data.color) return data.color;

    if (exactColors && getManualColor) {
      return getManualColor(data);
    }

    return colorScale(data.value);
  };

  const fillText = () => {
    if (darkMode) return '#fff';

    return initialRenderData
      ? getColor(selectedPiece ?? initialRenderData)
      : `#000`;
  };

  const resize = useCallback(() => {
    const box = boxRef.current;

    if (box) {
      const width = box.parentElement?.clientWidth;

      width && setWidth(width - margins.left - margins.right);
    }
  }, []);

  function handleClick(data: Data) {
    if (noFilter) return
    setSelectedPiece(
      selectedPiece && selectedPiece.name === data.name ? null : data
    );
  }

  useEffect(() => {
    resize();
  }, [resize]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  const pie = useMemo(() => {
    const relativeWidth = donutWidth ?? (width <= 374 ? width : width * 0.8);
    const height = donutHeight ?? relativeWidth * 1;

    const k1 = relativeWidth <= 374 ? 0.75 : 0.7;
    const k2 = relativeWidth <= 374 ? 1 : 0.8;

    return {
      width: relativeWidth,
      height,
      radius: Math.min(relativeWidth * k1, height * k1) / 2,
      center: [(relativeWidth * k2) / 2, height / 2],
      thickness
    };
  }, [width, thickness, donutWidth, donutHeight]);

  function getPercentage() {
    if (initialRenderData && !selectedPiece) {
      return getMiddleText(true, initialRenderData.value / total, percentage)
    } else if (percentage && !initialRenderData && !selectedPiece && !calculatedOut) {
      return getMiddleText(true, total, false)
    } else {
      return getMiddleText(!!selectedPiece || !labelOnDonut, total, percentage)
    }
  }

  function renderChartContent() {
    return (
      <Svg width={pie.width} height={pie.height}>
        <Group top={pie.center[1]} left={pie.center[0]}>
          <Pie
            labelOnDonut={labelOnDonut}
            percent={percentage}
            data={
              selectedPiece
                ? dataFormatted.filter((d) => d.name === selectedPiece.name)
                : dataFormatted
            }
            animate={animate}
            radius={pie.radius}
            thickness={pie.thickness}
            colorScale={getColor}
            onClick={handleClick}
          />
          <Show when={hasMiddleValue}>
            <Show when={showPercentage}>
              <MiddleText textAnchor="middle" fill={fillText()}>
                {getPercentage()}{' '}
              </MiddleText>
            </Show>

            <Show when={!!label}>
              <BaseText
                textAnchor="middle"
                y={showPercentage ? 20 : 0}
                fill={fillText()}
              >
                {label}{' '}
              </BaseText>
            </Show>

            <Show when={!!subLabel && !selectedPiece && pie.width > 220}>
              <BaseText textAnchor="middle" y={`35px`} fill={fillText()}>
                {subLabel}
              </BaseText>
            </Show>
          </Show>
        </Group>
      </Svg>
    );
  }

  function renderLegendContent() {
    return (
      <Legend
        items={orderedData.map((d) => ({
          ...d,
          color: d.color ?? getColor(d)
        }))}
        selected={selectedPiece}
        percentage={percentage}
        highlight={highlight as any}
        onClick={handleClick}
        hiddenValue={hiddenValue}
        noFilter={noFilter}
      />
    );
  }

  return (
    <Box ref={boxRef}>
      <ContentBox
        style={{
          flexDirection,
          alignItems: `center`,
          justifyContent: `center`
        }}
        isClickable={!noFilter}
      >
        {renderChartContent()}
        {renderLegendContent()}
      </ContentBox>
    </Box>
  );
}

export const DonutPopulated = DonutComponent;
