import styled from 'styled-components';
import { colors } from '@/theme/colors';

export const Box = styled.div`
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.1rem;
    color: ${colors.green200};
    text-transform: uppercase;

    &.secondary {
      color: ${colors.orange500};
    }

    &.date {
      text-transform: initial;
    }
  }
`;

export const TagBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
`;
