import { DataPercentageComputed } from 'big-data';
import { useMemo } from 'react';

export function useBiggest(data: DataPercentageComputed[]) {
  return useMemo(
    () =>
      data
        .filter((_, i) => i === 0)
        .map((d) => ({ ...d, value: Number(d.percentageValue.toFixed(2)) }))[0],
    [data]
  );
}
