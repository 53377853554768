import { DataLastUpdate, DataLastUpdateCmic } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = '/data-atualizacao';

  const getLastUpdated = async (name: string) => {
    const path = `${resource}`;
    const response = await callService(() =>
      mainApi.get<DataLastUpdate[]>(path)
    );

    return response.data.find((data) => {
      return data.nome === name;
    });
  };

  const getLastUpdatedCmic = async () => {
    const path = '/cmic/ultima-atualizacao-cmic';
    const response = await callService(() =>
      mainApi.get<DataLastUpdateCmic[]>(path)
    );

    const data: DataLastUpdate = {
      insercao: response.data[0]?.data_ultima_atualizacao,
      nome: response.data[0]?.nome
    }

    return data
  };

  return {
    getLastUpdated,
    getLastUpdatedCmic
  };
};

export const lastUpdate = service();
