import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
  border: 1px solid ${(props) => (props.theme.darkMode ? '#FFF' : '#DFE6E9')};
  padding: 1.5rem;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;

  header {
    h4 {
      font-weight: 700;
      font-size: 1rem;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.gray700)};
    }

    .source {
      color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
    }
  }

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 21px;

    color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.gray700)};

    letter-spacing: 0.02rem;
  }
`;
