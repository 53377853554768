import { Fragment, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { TableBox, Tr } from './styles';

import { BsArrowRight } from 'react-icons/bs';
import { cpfReplacer, isCPFFormat } from '@/utils/string';

interface Highlight<H> {
  value: string;
  by: keyof H;
}
interface TableProps<G> {
  data: G[];
  headers: string[];
  highlight?: Highlight<G>;
  clickableCell?: {
    onClick?: (data: G) => void;
    anchor?: {
      sendData?: (data: G) => LinkProps['state'];
      redirectFn?: (data: G) => LinkProps['to'];
    };
  };
  countStart: number;
  excludesOnRender?: (keyof G)[];
  className?: string;
}

export function Table<T>({
  className = '',
  data,
  headers,
  highlight,
  clickableCell,
  countStart = 1,
  excludesOnRender
}: TableProps<T>) {
  const isAnchor = !!clickableCell?.anchor;

  const dataWithIds = useMemo(
    () => data.map((d: any, i) => ({ ...d, id: d.id ?? i })),
    [data]
  );

  function onHighlight(highlighter: string) {
    return highlighter?.toLowerCase() === highlight?.value?.toLowerCase();
  }

  function content(data: T, _index: number) {
    return Object.keys(data)
      .filter((key) =>
        !!excludesOnRender ? !excludesOnRender.includes(key as keyof T) : key
      )
      .filter((key) => key !== 'id')
      .map((key, index) => {
        return (
          <td key={key}>
            {index === 0 && <span>{countStart + _index + 1}.</span>}
            {
              isCPFFormat(data[key as keyof T] as unknown as string) ? (
                cpfReplacer(data[key as keyof T] as unknown as string, "secret")
              ) : (
                data[key as keyof T]
              )
              
            }
          </td>
        );
      });
  }

  return (
    <TableBox className={className}>
      <thead>
        <Tr amountCells={headers.length}>
          {headers.map((header) => (
            <td key={header}>{header}</td>
          ))}
        </Tr>
      </thead>

      <tbody>
        {dataWithIds.map((d, i) => (
          <Tr
            key={d.id}
            $highlight={
              !!highlight ? onHighlight(String(d[highlight.by])) : false
            }
            amountCells={headers.length}
            $clickable={!!clickableCell}
            role={clickableCell ? 'button' : ''}
            onClick={() => clickableCell?.onClick?.(d)}
          >
            {isAnchor ? (
              <Link
                to={clickableCell?.anchor?.redirectFn?.(d) ?? ''}
                state={clickableCell?.anchor?.sendData?.(d)}
              >
                {content(d, i)}
              </Link>
            ) : (
              <Fragment>{content(d, i)}</Fragment>
            )}
            {isAnchor && (
              <div className="arrow-icon">
                <BsArrowRight size={20} />
              </div>
            )}
          </Tr>
        ))}
      </tbody>
    </TableBox>
  );
}
