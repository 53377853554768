import { Text } from '@visx/text';
import styled from 'styled-components';
import { colors } from '@/theme/colors';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;

  svg {
    margin: 1rem 0;
    cursor: pointer;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 300px;
  }
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.text};
  margin-left: 10px;
`;

interface AnimatedTextProps {
  animationx?: number;
  animationy?: number;
  delay?: number;
}

export const SText = styled(Text)<AnimatedTextProps>`
  text-transform: uppercase;
`;

export const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
`;

export const Legend = styled.p`
  width: 50%;

  text-align: right;

  color: ${(props) => (props.theme.darkMode ? `#fff` : colors.green700)};
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;

  letter-spacing: 0.02em;
`;

export const ValueBox = styled.p`
  color: ${(props) => (props.theme.darkMode ? `#fffa` : colors.green700)};
  position: absolute;
  bottom: 0;
  left: 0;

  font-size: 2.2rem;

  font-weight: 700;
  line-height: 38px;

  letter-spacing: 0.02em;
`;
