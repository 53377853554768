import styled from 'styled-components';

import { Card } from '../styles';

export const Box = styled(Card)`
  .chart-menu {
    padding: 1rem;
  }
`;

export const EmptyBox = styled.div`
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-message {
    color: ${({ theme }) => (theme.darkMode ? '#fff' : '#4c5469')};
    margin: 1rem 0;
    font-weight: 700;
  }
`;
