import { Card, WordCloud } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useSocialAssistance } from '@/services/lgbt/socialAssistance';
import { Data } from 'big-data';
import { useEffect } from 'react';

export function MainServices() {
  const { city, orientation, ibge, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useSocialAssistance.mainServices({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, ibge, orientation, identity]);

  return (
    <Section>
      <Card
        title="Principais serviços, programas, benefícios e projetos socioassistenciais acessados no último ano"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <WordCloud words={data} scaleSize={[10, 60]} percentageSymbol />
      </Card>
    </Section>
  );
}
