import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card6() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getRacialEthnics(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Distribuição das mulheres atendidas por grupos étnicos-raciais"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <HorizontalBarSimple
        data={data}
        isPercentage
        grid
        color={colors.ciano700}
      />
    </Card>
  );
}
