import { useTheme } from 'styled-components';
import { IconButton } from '../IconButton';

export const ButtonsFontSize = () => {
  const { font } = useTheme();

  return (
    <>
      <IconButton
        disabled={!font.limit.isNormalized}
        mode="font-normal"
        onClick={font.normalize}
        aria-label="Normal Fonte"
      />
      <IconButton
        disabled={!font.limit.isDecrease}
        mode="font-minus"
        onClick={font.decrease}
        aria-label="Diminuir fonte"
      />
      <IconButton
        disabled={!font.limit.isIncrease}
        mode="font-plus"
        onClick={font.increase}
        aria-label="Aumentar fonte"
      />
    </>
  );
};
