import styled, { css } from 'styled-components';

interface BoxProps {
  side?: 'left' | 'right';
}

export const Box = styled.div<BoxProps>`
  width: 100%;
  margin-top: 0.2rem;

  ${({ side }) => getPadding(side)}

  display: flex;
  justify-content: ${({ side }) => getXAlign(side)};
  gap: 0.6rem;
  font-size: 0.7rem;
  color: ${(props) => (props.theme.darkMode ? '#fff' : '#000')};

  .author {
    font-weight: 700;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const GAP = '1.2rem';
const PADDING_HORIZONTAL = '1.2rem';
const ICON_SIZE = '40px';

const getXAlign = (side?: 'left' | 'right') => {
  if (side === 'right') return 'flex-end';
  if (side === 'left') return 'flex-start';
};

const getPadding = (side?: 'left' | 'right') => {
  if (side === 'right') {
    return css`
      padding-right: calc(${ICON_SIZE} + ${GAP} + ${PADDING_HORIZONTAL});
    `;
  }

  if (side === 'left') {
    return css`
      padding-left: calc(${ICON_SIZE} + ${GAP} + ${PADDING_HORIZONTAL});
    `;
  }
};
