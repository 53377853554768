import styled from 'styled-components';

export const Box = styled.div`
  min-height: 240px;
`;

export const Title = styled.h3`
  font-size: 1.7rem;
  font-weight: bold;
  color: ${(props) => props.theme.text};
  margin-left: 10px;
`;

export const RankingBox = styled.div``;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 155px;
`;

export const RankingList = styled.ol`
  padding: 0 10px;
  list-style: none;
`;

export const RankingItem = styled.li`
  margin: 10px 0;
  color: ${(props) => props.theme.text};
  &.first {
    font-size: 1.5rem;
  }

  &.second {
    font-size: 1.3rem;
  }

  &.third {
    font-size: 1.2rem;
  }

  .percent {
    color: #87c878;
    font-size: 1.8rem;
  }

  .percent-symbol {
    font-size: 1rem;
    color: #87c878;
  }
`;
