import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  async function cadUnico(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['POSSUI_CADUNICO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.possui_cadunico,
        value: d.porcentagem
      };
    });
  }

  async function serviceAcess(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ACESSO_SERVICOS_BENEFICIOS']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.acesso_servicos_beneficios,
          value: d.quantidade
        };
      })
      .filter((d) => d.name !== 'Outras respostas');
  }

  async function mainServices(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['QUAL_BENEFICIO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.qual_beneficio,
        value: d.porcentagem
      };
    });
  }

  async function attendanceSocial(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ATENDIDO_PROGRAMA_ASSISTENCIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.atendido_programa_assistencia,
        value: d.porcentagem
      };
    });
  }

  async function humanAttendance(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['TEVE_ATENDIMENTO_HUMANIZADO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.teve_atendimento_humanizado,
          value: d.quantidade
        };
      })
      .filter((d) => d.name);
  }

  async function respectSocial(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ORIENTACAO_GENERO_RESPEITADA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.orientacao_genero_respeitada,
          value: d.quantidade
        };
      })
      .filter((d) => d.name);
  }

  return {
    cadUnico,
    serviceAcess,
    mainServices,
    attendanceSocial,
    humanAttendance,
    respectSocial
  };
};

export const useSocialAssistance = service();
