import {
  LivingConditionLeftSection,
  LivingConditionRightSection,
  LivingConditionBottomSection
} from '..';

import { Box, Grid, GridItem } from './styles';

export function LivingConditionInsights() {
  return (
    <Box>
      <Grid>
        <GridItem area="left-charts">
          <LivingConditionLeftSection />
        </GridItem>

        <GridItem area="right-charts">
          <LivingConditionRightSection />
        </GridItem>

        <GridItem area="bottom-charts">
          <LivingConditionBottomSection />
        </GridItem>
      </Grid>
    </Box>
  );
}
