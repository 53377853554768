import { useEffect } from 'react';
import { Card, WordCloud } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function Eletricity() {
  const { data, loading, error, request } = useService<Data>();

  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getElectricity(city, zone));
  }, [request, city, zone]);

  return (
    <Section>
      <Card
        title="Tipo de iluminação das residências"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <WordCloud words={data} height={500} percentage />
      </Card>
    </Section>
  );
}
