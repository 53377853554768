import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  async function campaign(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['FORMALIZOU_DENUNCIA_DELEGACIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.formalizou_denuncia_delegacia,
          value: d.quantidade
        };
      })
      .filter((d) => d.name);
  }

  async function daily(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['PASSOU_POR_LGBTFOBIA_COTIDIANO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.passou_por_lgbtfobia_cotidiano,
        value: d.porcentagem
      };
    });
  }

  async function dataUsage(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['IMPORTANCIA_FORMULARIO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.importancia_formulario,
        value: d.porcentagem
      };
    });
  }

  async function lgbtPhobiaComplaint(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['CONTRIBUICAO_CAMPANHA_PUBLICITARIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.contribuicao_campanha_publicitaria,
        value: d.porcentagem
      };
    });
  }

  async function generalLgbtPhobiaTypes(params: LgbtParams) {
    const path = `${resource}/nuvem-palavras-lgbtfobia-cotidiano`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.que_tipo_lgbtfobia_cotidiano,
        value: d.porcentagem
      };
    });
  }

  async function populationFear(params: LgbtParams) {
    const path = `${resource}/sentimento-lgbtfobia`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.sentimento_lgbtfobia,
        value: d.porcentagem
      };
    });
  }

  async function generalProfessionalPreparation(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['PREPARO_PROFISSIONAIS_SEGURANCA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.preparo_profissionais_seguranca,
        value: d.porcentagem
      };
    });
  }

  async function reasonNoComplaint(params: LgbtParams) {
    const path = `${resource}/nuvem-palavras-falta-denuncias`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.formalizou_denuncia_delegacia_termos,
          value: d.quantidade
        };
      })
      .filter((d) => d.name);
  }

  async function securityLeve(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['SENSACAO_SEGURANCA_CEARA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.sensacao_seguranca_ceara,
        value: d.porcentagem
      };
    });
  }

  async function sugestionQuiz(params: LgbtParams) {
    const path = `${resource}/sugestoes-reclamacoes`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.sugestoes_reclamacoes,
        value: d.quantidade
      };
    });
  }

  return {
    campaign,
    daily,
    dataUsage,
    lgbtPhobiaComplaint,
    generalLgbtPhobiaTypes,
    populationFear,
    generalProfessionalPreparation,
    reasonNoComplaint,
    securityLeve,
    sugestionQuiz
  };
};

export const useLgbtPhobia = service();
