import { listPlanActions } from '@/services/accessDataActions';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

export function useAccessPlanActions(cpf?: string, minimumDate?: string) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    listPlanActions
      .get(cpf, minimumDate)
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [cpf, minimumDate]);

  return {
    data,
    loading,
    error
  };
}
