import { AnimatedTopic } from '@/layout';
import { Grid } from './styles';

export function ImpactsDetailsMessage() {
  return (
    <Grid>
      <AnimatedTopic delay={0} number={1}>
        <b>Entender</b> as necessidades das famílias mais vulneráveis
      </AnimatedTopic>
      <AnimatedTopic delay={100} number={2}>
        <b>Orientar</b> a gestão pública na tomada de decisão baseada em dados
      </AnimatedTopic>
      <AnimatedTopic delay={200} number={3}>
        <b>Dar transparência</b> aos dados da Assistência Social
      </AnimatedTopic>
      <AnimatedTopic delay={300} number={4}>
        <b>Aproximar</b> governo e cidadão pelos meios digitais
      </AnimatedTopic>
      <AnimatedTopic delay={400} number={5}>
        <b>Monitorar</b> o impacto dos auxílios públicos fornecidos para a
        sociedade
      </AnimatedTopic>
    </Grid>
  );
}
