import { useAnalyticsTitle } from '@/hooks';
import { BdsAid } from '@/bdsIcons';
import { GroupBlockBox } from '@/components/styled';
import {
  ApresentationCard,
  LastUpdateMessage,
  TextIcon
} from '@/components/shared';

import { ChildhoodInsights, CountLabel } from '../components';
import { useFamilyData, useLastUpdate } from '../hooks';
import { Box } from './styles';

const icon = () => <BdsAid />;
const text = () => (
  <p>
    Inscritos no CadUNICO, beneficiários do Bolsa Família, com{' '}
    <b>crianças de 0 a 5 anos e 11 meses</b> e que possuam{' '}
    <b>
      renda <i>per</i> capita familiar de até R$ 89
    </b>
    .
  </p>
);

export function Childhood() {
  const { lastUpdate, loading, error } = useLastUpdate('cmic');
  const { amount, loadingAmount, errorAmount } = useFamilyData();
  useAnalyticsTitle('Famílias beneficiadas com o Cartão Mais Infância');

  return (
    <Box>
      <LastUpdateMessage
        value={lastUpdate?.insercao}
        loading={loading}
        error={error}
      />

      <GroupBlockBox>
        <ApresentationCard
          title="Número de beneficiados"
          source="CADÚNICO"
          text="Número de beneficiários de acordo com a população do Estado do Ceará cadastrada no Cadastro Único para Programas Sociais (CadUNICO)."
        >
          <CountLabel
            value={amount}
            loading={loadingAmount}
            error={errorAmount}
          />
        </ApresentationCard>
        <ApresentationCard
          title="Perfil dos beneficiados"
          source="Governo do Estado do Ceará"
        >
          <TextIcon icon={icon} text={text} />
        </ApresentationCard>
      </GroupBlockBox>
      <ChildhoodInsights />
    </Box>
  );
}
