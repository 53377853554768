import styled, { css } from 'styled-components';
import { useMemo, useState } from 'react';
import { mediaQuery } from '@/theme/mediaQuery';

export function SearchArea() {
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [text, setText] = useState<string>(``);

  const showResults = useMemo(() => {
    return inFocus && text.length > 0;
  }, [text, inFocus]);

  function keyDownHandle(e: any) {
    if (e.key === `Escape`) {
      setText(``);
    }
  }

  return (
    <Wrapper>
      <InputContainer>
        <IconArea>
          <IconSearch />
        </IconArea>
        <InputSearch
          placeholder="Pesquisar..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={keyDownHandle}
          onFocus={() => setInFocus(true)}
          onBlur={() => setInFocus(false)}
        />
        <ContainerResults visible={showResults}>
          <p>No momento nenhum resultado.</p>
        </ContainerResults>
      </InputContainer>
    </Wrapper>
  );
}

const IconSearch = () => {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24">
      <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
    </svg>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  ${mediaQuery.smallScreen} {
    padding: 0 0 20px 0;
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 80%;
  min-width: 250px;
  ${mediaQuery.mediumScreen} {
    width: 100%;
  }
`;

const IconArea = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 9px;
`;

const InputSearch = styled.input.attrs(() => ({
  type: `input`
}))`
  position: absolute;
  z-index: 1;
  width: 100%;
  padding: 7px 10px 8px 40px;
  line-height: 1;
  font-size: 1rem;
  color: ${(props) => props.theme.textDark};
  background: #fff;
  border: none;
  border-radius: 50px;
`;

const ContainerResults = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: 0;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  line-height: 1.3;
  text-align: center;
  text-rendering: geometricPrecision;
  padding: 60px 20px 30px 20px;
  background: #eaeaea;
  pointer-events: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px -10px #000;
  transition: 0.2s;
  ${(props) =>
    !props.visible &&
    css`
      opacity: 0;
      transform: translate(0, 5px);
      pointer-events: all;
    `};
  p {
    font-size: 1rem;
    font-style: italic;
    color: #555;
  }
`;
