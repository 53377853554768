import { colors } from '@/theme/colors';
import { ServiceBox } from '@/components/shared';
import { useMeanTimePerPage } from '@/servicesHooks/googleAnalytics';

import { NormalCard, CardBase } from '../../components';
import { TimeIcon } from '../../components/icons/Time';
import { useTabContext, useFilterContext } from '../../contexts';
import { generateTag, generateTime } from '../../utils';

export function CardMeanTimePerPage() {
  const { tab } = useTabContext();
  const { city } = useFilterContext();
  const { data, error, loading } = useMeanTimePerPage(tab?.value, city?.ibge);

  const { hours, minutes, seconds } = generateTime(data, 'meanTimePerPage');

  return (
    <CardBase>
      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <NormalCard
          title="Tempo médio por página"
          subtitle={generateTag([city?.name, tab?.name])}
          number={{
            color: colors.ciano700,
            value: `${hours}:${minutes}:${seconds}`,
            icon: TimeIcon
          }}
          description={
            'A média de tempo que os utilizadores passaram a ver uma página ou ecrã ou um conjunto de páginas especificados. '
          }
        />
      </ServiceBox>
    </CardBase>
  );
}
