import { useDashboardFilter } from '@/hooks';
import { jobIncome } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useJobIncomeData() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  const empty = data.length === 0;

  useEffect(() => {
    setLoading(true);
    jobIncome
      .getIncome({ ibge, year, semester, zone })
      .then((data) => setData(data.sort((a, b) => b.value - a.value)))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      loading,
      error,
      empty
    }),
    [data, loading, error, empty]
  );
}
