interface BdsEducationProps {
  width?: number;
  height?: number;
  color?: string;
}

export function BdsEducation({
  width = 24,
  height = 24,
  color
}: BdsEducationProps) {
  const selectedColor = color ?? '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill={selectedColor}>
        <path d="M12.001 17.133a.733.733 0 0 1-.364-.097l-6.419-3.669a.367.367 0 0 0-.55.318v3.448a.734.734 0 0 0 .377.641l6.6 3.667a.733.733 0 0 0 .712 0l6.6-3.667a.734.734 0 0 0 .377-.641v-3.448a.367.367 0 0 0-.55-.318l-6.419 3.67a.733.733 0 0 1-.364.096z" />
        <path d="M22.996 8.998v-.005a.733.733 0 0 0-.366-.563L12.363 2.563a.733.733 0 0 0-.727 0L1.369 8.43a.733.733 0 0 0 0 1.273l10.267 5.866a.733.733 0 0 0 .727 0l9.033-5.16a.092.092 0 0 1 .125.033.09.09 0 0 1 .012.046v6.624c0 .395.304.734.698.753a.734.734 0 0 0 .769-.732V9.066a.65.65 0 0 0-.004-.068z" />
      </g>
    </svg>
  );
}
