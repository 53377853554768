import { colors } from '@/theme/colors';
import { Collapse } from 'antd';
import styled from 'styled-components';

export const Accordion = styled(Collapse)`
  background-color: ${({ theme }) => (theme.darkMode ? theme.background : ``)};

  .ant-collapse-header {
    span {
      color: ${({ theme }) => (theme.darkMode ? colors.white : ``)};
    }
  }

  .ant-collapse-content {
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.background : ``};
  }
`;
