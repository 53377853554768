import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useLgbtPhobia } from '@/services/lgbt/lgbtPhobia';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';

const nameColorMap = new Map([
  ['Podem contribuir pouco', '#D4282A'],
  ['Podem contribuir muito', '#55A546']
]);

function resolveColor(name: string) {
  return nameColorMap.get(name) || colors.gray500;
}

export function LgbtPhobiaComplaint() {
  const { ibge, orientation, city, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useLgbtPhobia.lgbtPhobiaComplaint({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, ibge, orientation, identity]);

  const formatedDataDonut = useMemo(() => {
    return data.map((d) => {
      const color = resolveColor(d.name);

      return {
        ...d,
        color
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Uso de campanhas publicitárias de Promoção da Cidadania e Enfrentamento a LGBTfobia"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <DonutPopulated
          calculatedOut
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedDataDonut}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
