import { useDashboardFilter } from '@/hooks';
import { jobIncome } from '@/services';
import { sumDataTotal } from '@/utils/data';
import { DataCity } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const filterBy = 'Não'; // No Income job

export function useMapData() {
  const [data, setData] = useState<DataCity[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  const empty = data.length === 0;

  useEffect(() => {
    setLoading(true);
    jobIncome
      .getMapData({ ibge, year, semester, zone })
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  const total = sumDataTotal(data.filter((d) => d.option === filterBy));

  const formattedCities = useMemo(() => {
    const dataReduced = data.reduce((acc, city) => {
      const nameIbge = city.city + '_' + city.name;

      return {
        ...acc,
        [nameIbge]: {
          ...acc[nameIbge],
          [city.option ?? '']: (acc[city.option ?? ''] ?? 0) + city.value
        }
      };
    }, {} as Record<string, any>);

    return Object.entries(dataReduced).map(([k, v]) => {
      const [cityName, cityIbge] = k.split('_');
      const total = Object.values(v as number[]).reduce(
        (acc, value) => acc + value,
        0
      );

      return {
        name: cityName,
        codIbge: cityIbge,
        value: ((v[filterBy] ?? 0) / total) * 100
      };
    });
  }, [data]);

  return useMemo(
    () => ({
      data: formattedCities,
      loading,
      error,
      empty,
      total
    }),
    [formattedCities, loading, error, empty, total]
  );
}
