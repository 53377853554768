import { CardTagged, ServiceBox, Table } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantAgeGroupData } from '@/servicesHooks/healthPregnant';

const columns = [
  {
    name: 'Faixa etária',
    key: 'name'
  },
  {
    name: 'Percentual',
    key: 'value'
  }
];

export function CardPregnantTable() {
  const { tag } = useDashboardFilter();
  const { data, ...rest } = usePregnantAgeGroupData();

  const formattedData = data.map((d) => {
    if (d.name === 'Não respondeu') return d;

    return {
      ...d,
      name: d.name.concat(' anos')
    };
  });

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a porcentagem de grávidas por faixa etária nos domicílios?
        </TitleText>

        <Table columns={columns} data={formattedData} percentage />
      </ServiceBox>
    </CardTagged>
  );
}
