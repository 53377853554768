interface AuxilioIconProps {
  color?: string;
}

export function BdsAid({ color }: AuxilioIconProps) {
  const selectColor = color ?? '#55A546';
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      xmlns="http://www.w3.org/2000/svg"
      fill={selectColor}
    >
      <path
        fill={selectColor}
        d="M8.66634 16.7599H13.6663C14.1084 16.7599 14.5323 16.5843 14.8449 16.2718C15.1574 15.9592 15.333 15.5353 15.333 15.0933C15.333 14.6512 15.1574 14.2273 14.8449 13.9148C14.5323 13.6022 14.1084 13.4266 13.6663 13.4266H8.66634C8.22431 13.4266 7.80039 13.6022 7.48783 13.9148C7.17527 14.2273 6.99967 14.6512 6.99967 15.0933C6.99967 15.5353 7.17527 15.9592 7.48783 16.2718C7.80039 16.5843 8.22431 16.7599 8.66634 16.7599ZM28.6663 0.0932617H5.33301C4.00693 0.0932617 2.73516 0.620046 1.79747 1.55773C0.859792 2.49541 0.333008 3.76718 0.333008 5.09326V20.0933C0.333008 21.4193 0.859792 22.6911 1.79747 23.6288C2.73516 24.5665 4.00693 25.0933 5.33301 25.0933H28.6663C29.9924 25.0933 31.2642 24.5665 32.2019 23.6288C33.1396 22.6911 33.6663 21.4193 33.6663 20.0933V5.09326C33.6663 3.76718 33.1396 2.49541 32.2019 1.55773C31.2642 0.620046 29.9924 0.0932617 28.6663 0.0932617ZM30.333 20.0933C30.333 20.5353 30.1574 20.9592 29.8449 21.2718C29.5323 21.5843 29.1084 21.7599 28.6663 21.7599H5.33301C4.89098 21.7599 4.46706 21.5843 4.1545 21.2718C3.84194 20.9592 3.66634 20.5353 3.66634 20.0933V10.0933H30.333V20.0933ZM30.333 6.75993H3.66634V5.09326C3.66634 4.65123 3.84194 4.22731 4.1545 3.91475C4.46706 3.60219 4.89098 3.42659 5.33301 3.42659H28.6663C29.1084 3.42659 29.5323 3.60219 29.8449 3.91475C30.1574 4.22731 30.333 4.65123 30.333 5.09326V6.75993Z"
      />
    </svg>
  );
}
