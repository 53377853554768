import styled from 'styled-components';
import { AnimatedBox } from '../../AnimatedBox';
import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';

export const Box = styled(AnimatedBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: calc(100% / 2);

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const IconBox = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? `column` : `row`)};

  .icon {
    display: block;
    height: 60px;
    margin: 5px 10px 0 0;
    fill: #bababa;
    stroke: #bababa;
    circle,
    path {
      stroke: ${({ theme }) => (theme.darkMode ? colors.white : colors.red500)};
    }
    circle {
      fill: none;
      stroke-width: 10px;
    }
    path {
      fill: ${({ theme }) => (theme.darkMode ? colors.white : colors.red500)};
    }
    &.selected {
      background-color: none;
      border-radius: 50%;
      circle,
      path {
        stroke: ${({ theme }) =>
          theme.darkMode ? theme.yellowDark : colors.green200};
      }

      path {
        fill: ${({ theme }) =>
          theme.darkMode ? theme.yellowDark : colors.green200};
      }
    }
  }
`;

export const TextBox = styled.p`
  width: 250px;
  color: ${(props) => (props.theme.darkMode ? `#fff` : colors.gray700)};
  font-weight: 700;

  ${mediaQuery.smallScreen} {
    text-align: center;
  }

  .percentage {
    font-size: 1.4rem;
    display: block;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 0.2rem;

    letter-spacing: 0.02em;
    color: ${(props) => (props.theme.darkMode ? `#fff` : colors.ciano700)};
  }
`;
