import { useState } from 'react';

import newAction from '@/assets/icons/new-action.svg';
import { useAuth } from '@/hooks';

import { RegisterModal } from '../RegisterModal';
import { Box } from './styles';

interface RegisterButtonProps {
  onRegistered?: () => void;
}

export function RegisterButton({ onRegistered }: RegisterButtonProps) {
  const { isAdm } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  if (isAdm) return null;

  return (
    <>
      <Box onClick={handleOpenModal}>
        <img src={newAction} alt="icon de lampada" />
        <span>Criar plano de ação</span>
      </Box>

      <RegisterModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        onRegistered={onRegistered}
      />
    </>
  );
}
