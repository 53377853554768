import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

export const Box = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 45px;
  right: 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 1600px) {
    right: 0;
    bottom: 0;

    .feedback-button {
      width: 45px;
      height: 45px;
    }
  }
`;

export const SenderButton = styled.button.attrs({
  className: 'feedback-button'
})`
  border: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.yellowDark : colors.ciano700};
  align-self: flex-end;
  z-index: 99999999;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme.darkMode &&
    css`
      img {
        filter: invert(1);
      }
    `}
`;
