import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { CountLabel } from '@/pages/Analytics/components';
import { homeVisits } from '@/services';
import { DataWithProfile } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineHome } from 'react-icons/hi';

export function VisitsCount() {
  const [visited, setVisited] = useState<DataWithProfile[]>([]);
  const [loadingVisited, setLoadingVisited] = useState(false);
  const { profilePopulation, city } = useAnalyticsFilter();
  useEffect(() => {
    setLoadingVisited(true);
    homeVisits
      .visitedAmount()
      .then((data) => setVisited(data))
      .finally(() => setLoadingVisited(false));
  }, []);

  const visitedAmount = useMemo(() => {
    return visited
      .filter((visit) =>
        !!profilePopulation ? visit.profile === profilePopulation : visit
      )
      .filter((visit) => (!!city ? visit.name === city : visit))
      .reduce((acc, cur) => acc + cur.value, 0);
  }, [visited, profilePopulation, city]);

  return (
    <CountLabel
      value={visitedAmount}
      label="visitas"
      loading={loadingVisited}
      size={30}
      icon={HiOutlineHome}
    />
  );
}
