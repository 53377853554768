import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

interface BoxProps {
  disabled?: boolean;
}

export const Box = styled.div<BoxProps>`
  height: 100%;
  overflow-x: hidden;
  max-width: 43vw;
  border-radius: 7px;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        border: 1px solid ${colors.gray600};
        background-color: ${colors.gray500 + '4D'};
      `;
    }

    return css`
      border: 1px solid ${colors.yellow300};
      background-color: ${colors.yellow200 + '4D'};
    `;
  }}

  /* border: 1px solid ${colors.yellow300};
  background-color: ${colors.yellow200 + '4D'}; */

  @media (max-width: 1024px) {
    max-width: 90vw;
  }

  @media (max-width: 400px) {
    max-width: 95vw;
  }
`;

interface ViewProps {
  page?: number;
}

export const View = styled.div<ViewProps>`
  display: flex;
  height: 100%;
  width: 400%;
  transition: transform 0.5s;

  transform: translateX(${({ page }) => (page ? `-${page * 25}%` : '0')});
`;

export const Question = styled.div`
  border-radius: 7px;
  width: 25%;
  padding: 0.8rem 0 0.8rem 2rem;

  .question-text {
    color: ${(props) => (!props.theme.darkMode ? colors.green700 : '#fff')};
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .block-text,
  .done-text {
    height: 100%;
  }

  .done-text .text {
    max-width: 280px;
  }

  .question-text .icon {
    background-color: ${colors.yellow600};
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }

  .block-text .block-icon,
  .done-text .done-icon {
    width: 30px;
    height: 30px;
  }

  .done-text .done-icon {
    background-color: ${colors.gray500};
  }

  .buttons {
    margin-top: 0.3rem;
    margin-left: calc(20px + 0.5rem);

    display: flex;
    gap: 1rem;
  }

  .buttons button {
    padding: 1px 0.8rem;
    border: none;
    color: ${colors.white};
    font-weight: 700;
    border-radius: 2px;

    transition: filter 0.3s;

    &:hover {
      filter: brightness(1.1);
    }
  }

  .buttons .confirm {
    background-color: ${colors.ciano700};
  }

  .buttons .cancel {
    background-color: ${colors.red500};
  }
`;
