import styled from 'styled-components';

export const Box = styled.div`
  background-color: ${(props) => (!props.theme.darkMode ? '#fff' : '#000')};
  border: 1px solid #fff;

  min-height: 84vh;
  margin: 1rem 0;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);

  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;

    .info-box {
      width: 100% !important;
      margin-bottom: 5rem;

      .select {
        width: 100% !important;
      }
    }

    .map-box {
      height: 100vh;
      width: 100% !important;
      padding: 0;
      margin: 0 !important;
    }
  }
  .info-box {
    width: 360px;
  }

  .map-box {
    margin-left: 1rem;
    width: calc(100% - 360px);

    position: relative;

    .loading {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      z-index: 400;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: #00000008;
    }

    .leaflet-container {
      height: 100%;
    }
  }

  .marker-cluster {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    border-width: 3px;
    border-style: solid;

    transition: all 0.2s linear;
    span {
      color: white;
    }
  }



  .insecurity-food-marker-cluster {
    border-color: #fbc34a80;
    background-color: #fbc34a;
    span {
      color: black;
    }
  }

  .social-assistance-marker-cluster {
    border-color: #0ba2d380;
    background-color: #0ba2d3;
  }

  .education-marker-cluster {
    border-color: #cc37e480;
    background-color: #cc37e4;
  }

  .health-marker-cluster {
    border-color: #57ac4b80;
    background-color: #57ac4b;
  }

  .income-marker-cluster {
    border-color: #324be180;
    background-color: #324be1;
  }

  .work-marker-cluster {
    border-color: #324be180;
    background-color: #324be1;
  }
`;
