import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.section``;

export const NavigateButton = styled.button`
  background-color: transparent;
  border: 0;
  background: ${colors.ciano700};
  border-radius: 10px;
  padding: 0 0.5rem;
  color: #ffffff;
  font-weight: 500;
`;

interface ColumnProps {
  direction: string;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: 1rem;
  height: 100%;

  ${mediaQuery.mediumScreen} {
    flex-direction: column;
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;
  }
`;
