import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { tabItems } from '../utils';

interface TabContextProviderProps {
  children: ReactNode;
}

type Tab = {
  name: string;
  value: string;
};

interface TabContextData {
  tab?: Tab;
  handleChangeTab(tab?: Tab): void;
}

const TabContext = createContext({} as TabContextData);

const defaultTabOpen: Tab | undefined = tabItems.find(
  (tabItem) => tabItem.id === 2
);

export function TabContextProvider({ children }: TabContextProviderProps) {
  const [tab, setTab] = useState<Tab | undefined>(defaultTabOpen);

  function handleChangeTab(tab?: Tab) {
    setTab(tab);
  }

  const contextProviderValue = useMemo(() => ({ tab, handleChangeTab }), [tab])

  return (
    <TabContext.Provider value={contextProviderValue}>
      {children}
    </TabContext.Provider>
  );
}

export function useTabContext() {
  return useContext(TabContext);
}
