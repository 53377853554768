import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

import { Show } from '@/components/shared';
import { useAuth } from '@/hooks';

import { Box } from './styles';
import { Avatar } from '../Avatar';
import { LogoutButton } from '../LogoutButton';

interface LogoutProps {
  collapsed?: boolean;
  onSiderCollapse?: () => void;
}

export function Logout({ collapsed = false, onSiderCollapse }: LogoutProps) {
  const { user } = useAuth();

  const client = user?.client;

  return (
    <Box $collapsed={collapsed}>
      <button onClick={onSiderCollapse} className="collapse-menu-button">
        <MdOutlineKeyboardArrowRight size={36} color="#fff" />
      </button>
      <div className="logout-box">
        <Show when={!!client} fallback={null}>
          <Avatar client={client} collapsed={collapsed} />

          <LogoutButton />
        </Show>
      </div>
    </Box>
  );
}
