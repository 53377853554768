import { CzrmIcon } from '@/assets/CzrmIcon';
import { useDashboardFilter } from '@/hooks';
import { ReactNode } from 'react';
import { Box } from './styles';
import { DataTree } from 'big-data';

interface SunBurstTextProps {
  value: string;
  label: string;
  buttonLabel: string | ReactNode;
  color: string;
  isButtonCzrm: boolean;
  selected: DataTree | undefined;
  handleOpenFamilyTable: () => void;
}

export function SunBurstText({
  value,
  label,
  buttonLabel,
  color,
  isButtonCzrm,
  selected,
  handleOpenFamilyTable
}: SunBurstTextProps) {
  const { city, yearSemester, zone } = useDashboardFilter();
  const semesterText = yearSemester ? ` / ${yearSemester}` : '';
  const zoneText = zone ? ` / ${zone}` : '';

  const chartTag = `${semesterText}${zoneText}`;

  const formatter = new Intl.NumberFormat('pt-br');

  return (
    <Box color={color}>
      <div className="value-tag">
        <span>{city ?? 'CEARÁ'}</span>
        <span>{chartTag}</span>
      </div>
      <p className="value-text">{value}</p>
      <p className="label-text">
        <p className="label-value">
          {!!selected && `${formatter.format(selected.value)} `}
        </p>
        {label}
      </p>

      <span className="button-text">{buttonLabel}</span>
      {isButtonCzrm && (
        <div className="btn-czrm">
          <button onClick={() => handleOpenFamilyTable()}>
            <CzrmIcon color={color} />

            <span>Visualizar </span>
            <span>Famílias</span>
          </button>
        </div>
      )}
    </Box>
  );
}
