export function FontNormalIcon({ color = '#465564' }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      width="24"
      height="24"
      viewBox="0, 0, 24, 24"
    >
      <path
        d="M9.051,13.475 L11.929,5.554 L11.976,5.554 L14.807,13.475 z M10.739,3.509 L4.127,20.491 L6.434,20.491 L8.337,15.377 L15.52,15.377 L17.375,20.491 L19.873,20.491 L13.237,3.509 z"
        fill={color}
      />
    </svg>
  );
}
