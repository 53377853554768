import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const TableBox = styled.table`
  width: 100%;
  text-align: center;
  height: 100%;
  flex: 1;

  overflow-x: auto;

  thead,
  tr:not(:last-child) {
    border-bottom: 1px solid #f1f5f9;
  }

  thead tr td {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray700)};
  }

  td {
    padding: 10px;
  }

  ${mediaQuery.smallScreen} {
    &.responsive {
      thead tr td,
      tbody tr td {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
`;

interface TrProps {
  $highlight?: boolean;
  $clickable?: boolean;
  amountCells: number;
}

export const Tr = styled.tr<TrProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 0.5rem;

  span {
    width: max-content;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  td {
    font-size: 1rem;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    justify-content: flex-start;
    text-align: left;

    gap: 8px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray700)};

    ${({ amountCells }) => css`
      width: ${amountCells <= 2
        ? `max-content`
        : Math.floor(100 / amountCells)}%;

      &:first-child {
        width: ${amountCells <= 2
          ? `max-content`
          : Math.floor(100 / amountCells) * 1.8}%;
      }
    `}

    &:last-child {
      justify-content: flex-end;
    }
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      td {
        font-weight: bold;
        color: ${colors.ciano600};
      }
    `};

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
      transition: background-color 0.2s;
      overflow: hidden;
      position: relative;

      .arrow-icon {
        transform: translateX(20px);
        position: absolute;
        top: calc(50% - 0.6rem);
        right: 0;

        svg {
          color: ${colors.ciano600};
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transform: translate(0);
        transition: transform 0.3s;
        transform: translate(-1rem);

        .arrow-icon {
          right: 25px;
        }

        td {
          color: ${colors.ciano600};
          font-weight: 700;
          &:last-child {
            transform: translate(-20px);
          }
        }
      }
    `};
`;
