import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useData } from './useData';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

interface SchoolingDegreeProps {
  color: string;
}

export function SchoolingDegree({ color }: SchoolingDegreeProps) {
  const { data, loading, error } = useData();
  const { city, gender, colorSkinType } = useAnalyticsFilter();

  return (
    <Section>
      <Card
        title="Última série que frequentou - pessoas acima de 18 anos"
        loading={loading}
        error={error}
        empty={data.length === 0}
        date="2021"
        tags={[city ?? 'Ceará', gender ?? '', colorSkinType ?? '']}
      >
        <HorizontalBarSimple
          sizeRatio={0.7}
          maxValue={5}
          color={color}
          data={data}
          isPercentage
          hasPagination
        />
      </Card>
    </Section>
  );
}
