import { useRef } from 'react';

import { AnimatedTitle } from '@/layout';
import { useConnectObserver, useObserverVisibility } from '@/hooks';

import { ImpactsDetailsMessage } from '../ImpactsDetailsMessage';
import { Wrapper, Columns } from './styles';

export function ImpactsDetails() {
  const refDom = useRef<any>();
  const visible = useObserverVisibility(refDom);

  const impactsRef = useConnectObserver<HTMLElement>();

  return (
    <section id="Impacts" ref={impactsRef}>
      <Wrapper>
        <Columns ref={refDom} visible={visible}>
          <AnimatedTitle text="Impactos esperados" />
          <ImpactsDetailsMessage />
        </Columns>
      </Wrapper>
    </section>
  );
}
