import styled from 'styled-components';
import { Select } from 'antd';

export const AntSelect = styled(Select)<{ width?: number }>`
  &.ant-select {
    width: 100%;
    min-width: ${(props) => (props.width ? props.width : 200)}px;
    border-radius: 7px;
    background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 7px;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    span.ant-select-selection-item {
      color: ${({ theme }) => theme.text};
    }

    .ant-select-arrow {
      svg {
        fill: ${({ theme }) => (theme.darkMode ? `#fff` : theme.greenDark)};
      }
    }
  }
`;
