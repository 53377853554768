import { DataIbge, DataWithCity } from 'big-data';
import { useMemo, useState } from 'react';

export function usePagination(data: DataWithCity[] | DataIbge[], pageSize = 5) {
  const [page, setPage] = useState(1);

  const totalOfPages = useMemo(
    () => Math.ceil(data.length / pageSize),
    [data, pageSize]
  );

  return useMemo(
    () => ({
      page,
      totalOfPages,
      changePage: (p: number) => setPage(p),
      nextPage: () => setPage((p) => (p >= totalOfPages ? p : p + 1)),
      backPage: () => setPage((p) => (p <= 1 ? p : p - 1))
    }),
    [page, totalOfPages]
  );
}
