import {
  Observation,
  ObservationNote,
  ObservationParams,
  PaginatedObservation
} from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { UserInfo } from 'acesso-cidadao-oauth2-lib';

const service = () => {
  const resource = `/observation`;
  const wsUrl = process.env.REACT_APP_MAIN_API;
  const wsResource = `${wsUrl}/bds-ws`;

  async function create(observationNote: ObservationNote) {
    const { file, actionId, note, answerTo } = observationNote;
    const path = `${resource}/${actionId}`;

    const formData = new FormData();
    const mimeJsonBlob = { type: 'application/json' };
    const json = JSON.stringify({ note, answerTo });

    const jsonBlob = new Blob([json], mimeJsonBlob);

    formData.append('observation', jsonBlob);
    file && formData.append('file', file);

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };

    const response = await callService(() =>
      mainApi.post<Observation>(path, formData, config)
    );

    return response.data;
  }

  async function getObservations({
    id,
    page = 0,
    size = 10
  }: ObservationParams) {
    const path = `${resource}/${id}`;

    const config = {
      params: { page, size }
    };

    const response = await callService(() =>
      mainApi.get<PaginatedObservation>(path, config)
    );

    return response.data;
  }

  async function getObservationFile(id: number, actionId: number) {
    const path = `${resource}/${actionId}/file/${id}`;

    const response = await callService(() =>
      mainApi.get<BlobPart>(path, { responseType: 'blob' })
    );

    return response.data;
  }

  function createMessageSocket(actionId: number) {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_BIG_DATA_APP_KEY}_token`
    );

    const socketUrl = `${wsResource}?token=${token}&actionId=${actionId}`;
    const socket = new SockJS(socketUrl);

    return Stomp.over(socket);
  }

  return {
    create,
    createMessageSocket,
    getObservations,
    getObservationFile
  };
};

export interface ISocketMessage {
  type: 'ERROR' | 'TYPING' | 'NOT_TYPING';
  sender: string;
  content?: string;
  actionId: number;
}

export interface IActionSocket {
  socket: Stomp.Client;
  CONNECTED: boolean;
  connect: () => Promise<Stomp.Frame | undefined>;
  disconnect: () => Promise<void>;
  typing: (user?: UserInfo) => void;
  notTyping: (user?: UserInfo) => void;
  listenMessage: (fn: (data: Observation) => void) => void;
  listenTyping: (fn: (data: ISocketMessage) => void) => void;
}

export const ObservationSocket = (
  socket: Stomp.Client,
  actionId: number
): IActionSocket => {
  let CONNECTED = false;

  function connect(): Promise<Stomp.Frame | undefined> {
    return new Promise((res, rej) => {
      socket.connect(
        {},
        (frame) => {
          CONNECTED = true;
          res(frame);
        },
        (err) => rej(err)
      );
    });
  }

  function disconnect(): Promise<void> {
    return new Promise((res, rej) => {
      socket.disconnect(() => {
        CONNECTED = false;
        res();
      });
    });
  }

  function typing(user?: UserInfo) {
    const username = user?.name ?? 'User';
    const typingMsg = { type: 'TYPING', sender: username, actionId };

    socket.send(
      `/observation/typing/${actionId}`,
      {},
      JSON.stringify(typingMsg)
    );
  }

  function notTyping(user?: UserInfo) {
    const username = user?.name ?? 'User';
    const typingMsg = { type: 'NOT_TYPING', sender: username, actionId };

    socket.send(
      `/observation/typing/${actionId}`,
      {},
      JSON.stringify(typingMsg)
    );
  }

  function listenMessage(fn: (data: Observation) => void) {
    socket.subscribe(`/topic/message/${actionId}`, (msg) => {
      const body = JSON.parse(msg.body);
      fn(body);
    });
  }

  function listenTyping(fn: (data: ISocketMessage) => void) {
    socket.subscribe(`/topic/typing/${actionId}`, (msg) => {
      const body = JSON.parse(msg.body);
      fn(body);
    });
  }

  return {
    CONNECTED,
    socket,
    connect,
    disconnect,
    typing,
    notTyping,
    listenMessage,
    listenTyping
  };
};

export const observation = service();
