import { useDashboardFilter } from '@/hooks';
import { foodInsecurity } from '@/services/foodInsecurity';
import { DataWithCity } from 'big-data';
import { useEffect, useState } from 'react';

export function useFamilyCities(name?: string) {
  const [cities, setCities] = useState<DataWithCity[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    foodInsecurity
      .getCities(name, ibge, year, semester, zone)
      .then((data) => {
        setCities(data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [name, ibge, year, semester, zone]);

  return { cities, loading, error };
}
