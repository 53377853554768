import { Data } from 'big-data';
import { useMemo } from 'react';

import { DashboardCard, Show } from '@/components/shared';
import { ColorText } from '@/components/styled';
import { useDemographyData } from '@/servicesHooks/socialAssistance';
import { BdsRural, BdsUrban } from '@/bdsIcons';

interface InformationBodyRightProps {
  creasHidden?: boolean;
  color: string;
}

function resolveZone(data: Data[], name: string) {
  return data
    .filter((d) => d.name.toLowerCase().includes(name))
    .reduce((acc, cur) => acc + cur.value, 0);
}

const formatter = new Intl.NumberFormat('pt-br');

export function SocialAssistanceCREASMonitoring({
  creasHidden,
  color
}: InformationBodyRightProps) {
  const { familiesCREAS, loadingFamilies } = useDemographyData();

  const creas = useMemo(() => {
    const total = familiesCREAS.reduce((acc, cur) => acc + cur.value, 0);
    const urbanTotal = resolveZone(familiesCREAS, 'rural');
    const ruralTotal = resolveZone(familiesCREAS, 'urbana');

    const urbanPercentage = (urbanTotal / total) * 100;
    const ruralPerncetage = (ruralTotal / total) * 100;

    return {
      total,
      urbanPercentage: urbanPercentage.toFixed(2) + '%',
      ruralPerncetage: ruralPerncetage.toFixed(2) + '%'
    };
  }, [familiesCREAS]);

  if (creasHidden) return null;

  return (
    <DashboardCard loading={loadingFamilies} color={color}>
      <Show when={!creasHidden} fallback={null}>
        <p className="information-about">
          Das{' '}
          <ColorText color={color}>{formatter.format(creas.total)}</ColorText>{' '}
          famílias não acompanhadas pelo CREAS nos últimos 3 meses:
        </p>
        <ul>
          <li>
            <BdsRural color={'#fff'} />
            <p>
              <ColorText color={color}>{creas.ruralPerncetage}</ColorText> moram
              na <strong>Zona Rural</strong>
            </p>
          </li>
          <li>
            <BdsUrban color={'#fff'} />
            <p>
              {' '}
              <ColorText color={color}>{creas.urbanPercentage}</ColorText> moram
              na <strong>Zona Urbana</strong>
            </p>
          </li>
        </ul>
      </Show>
    </DashboardCard>
  );
}
