import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
  padding: 24px;
  border-radius: 7px;

  border: 1px solid #dfe6e9;
  width: 100%;

  gap: 1rem;

  .icon-text-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    font-weight: 400;
    font-size: 0.875rem; // 14px
    line-height: 16px;

    color: ${(props) => props.theme.text};

    letter-spacing: 0.03rem;
  }

  .select-wrapper {
    display: flex;
    gap: 10px;

    .select-group {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    button.clear-filter {
      justify-self: flex-start;

      background: none;
      border: none;
      color: ${({ color, theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
      text-decoration: ${({ theme }) =>
        theme.darkMode ? 'underline' : 'none'};

      display: flex;
      align-items: center;
      transition: filter 0.2s;

      span {
        font-weight: 700;
        font-size: 0.8rem;
        margin-top: 3px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media (max-width: 1300px) {
    .select-wrapper {
      width: 100%;
      flex-direction: column;

      .ant-select {
        width: 100%;
      }
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    gap: 20px;

    .select-group {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 660px) {
    .ant-select {
      width: 50%;
    }
  }

  @media (max-width: 550px) {
    .ant-select {
      width: 100%;
    }

    .select-group {
      flex-direction: column;
    }
  }
`;
