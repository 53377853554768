import { getQuery } from '../config/utils';
import { baseRequest, baseResource } from './baseResource';

const service = () => {
  const resource = baseResource;

  async function getThemes(ibge?: string) {
    const path = `${resource}/temas-organizacao`;

    const data = await baseRequest<{
      publico_temas_organizacao: string;
      porcentagem: number;
    }>(path, { ibge });

    return data.map((d) => ({
      name: d.publico_temas_organizacao,
      value: d.porcentagem
    }));
  }

  async function getWomenPositions(ibge?: string) {
    const aggregations = ['PUBLICO_MULHERES_CHEFES'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d[aggregations[0].toLowerCase()],
      value: d.quantidade
    }));
  }

  async function getGenderIdentity(ibge?: string) {
    const path = `${resource}/identidade-genero-beneficiadas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.identidade_genero_beneficiadas,
      value: d.quantidade
    }));
  }

  async function getSexualOrientation(ibge?: string) {
    const path = `${resource}/orientacao-sexual-beneficiadas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.orientacao_sexual_beneficiadas,
      value: d.porcentagem
    }));
  }

  async function getRacialEthnics(ibge?: string) {
    const path = `${resource}/raca-cor-beneficiadas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.raca_cor_beneficiadas,
      value: d.porcentagem
    }));
  }

  async function getEducationLevel(ibge?: string) {
    const path = `${resource}/nivel-escolaridade-beneficiadas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.escolaridade_beneficiadas,
      value: d.porcentagem
    }));
  }

  async function getChildren(ibge?: string) {
    const aggregations = ['FILHOS_BENEFICIARIAS'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.filhos_beneficiadas,
      value: d.porcentagem
    }));
  }

  async function getStudentChildren(ibge?: string) {
    const aggregations = ['FILHOS_ESTUDAM_BENEFICIARIAS'];

    const data = await baseRequest<any>(resource, {
      ibge,
      aggregations: getQuery(aggregations)
    });

    return data.map((d) => ({
      name: d.filhos_estudam_beneficiadas,
      value: d.porcentagem
    }));
  }

  async function getIncome(ibge?: string) {
    const path = `${resource}/renda-beneficiadas`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.renda_beneficiadas,
      value: d.quantidade
    }));
  }

  async function getAgeGroup(ibge?: string) {
    const path = `${resource}/beneficiadas-faixa-etaria`;

    const data = await baseRequest<any>(path, { ibge });

    return data.map((d) => ({
      name: d.beneficiada_faixa_etaria,
      value: d.porcentagem
    }));
  }

  return {
    getThemes,
    getWomenPositions,
    getGenderIdentity,
    getSexualOrientation,
    getRacialEthnics,
    getEducationLevel,
    getChildren,
    getStudentChildren,
    getIncome,
    getAgeGroup
  };
};

export const targetAudienceService = service();
