import { boot } from '@/hooks';
import axios from 'axios';

export const mainApi = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API
});

export const ibgeApi = axios.create({
  baseURL: process.env.REACT_APP_IBGE_API
});

const MESSAGE_409 =
  'Suas informações não foram encontrados no Big Data Social! Por favor, contate seu administrador da Plataforma Mais Infância para realizar o seu cadastro.';

mainApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const response = error?.response ?? {};

    const { status, data = {} } = response;

    if (status === 403 && data?.mensagem === MESSAGE_409) {
      const url = `${process.env.REACT_APP_REDIRECT_URL}info/error?message=${data.mensagem}`;
      window.location.assign(url);
    }

    return Promise.reject(error);
  }
);

mainApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data.status === 401) boot();
    return Promise.reject(error);
  }
);

mainApi.interceptors.request.use((config) => {
  const token = localStorage.getItem(
    `${process.env.REACT_APP_BIG_DATA_APP_KEY}_token`
  );
  if (token) {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        Authorization: token,
        'Referrer-Policy': 'no-referrer-when-downgrade'
      }
    };
  } else {
    return {
      ...config,
      headers: {
        ...(config.headers ?? {}),
        'Referrer-Policy': 'no-referrer-when-downgrade'
      }
    };
  }
});
