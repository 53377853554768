import { animated, useTransition, to } from 'react-spring';
import { Pie as VisxPie } from '@visx/shape';
import { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import * as R from 'ramda';
import { MouseEvent, useMemo, useState } from 'react';

import { Svg, Group, Text, LegendItem, Box } from './styles';
import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';

import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useTheme } from 'styled-components';
import { colors } from '@/theme/colors';

export interface DonutData {
  name: string;
  value: number;
  color: string;
  percentage: number;
}

interface DonutProps {
  data: DonutData[];
  selectedData?: DonutData;
  width: number;
  height?: number;
  animate?: boolean;
  onClick?: (data: DonutData) => void;
  midValue?: number;
  legend?: boolean;
}

const calculateRadius = R.pipe<[R.Ord, R.Ord], number, number>(
  R.min,
  R.divide(R.__, 2)
);

export function Donut({
  data,
  selectedData,
  width,
  height = width,
  animate = true,
  onClick,
  midValue,
  legend = false
}: DonutProps) {
  const { darkMode, ...theme } = useTheme();
  const [selectedPiece, setSelectedPiece] = useState<DonutData | null>(null);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });

  const chartData = useMemo(
    () => (R.isNil(selectedData) ? data : [selectedData]),
    [data, selectedData]
  );

  const radius = calculateRadius(width, height);
  const total = sumDataTotal(data);
  const dataPercentage = data.map((d) => {
    const percentage = (d.value / total) * 100;
    return {
      ...d,
      percentage,
      percentageFormatted: percentageFormatter(percentage)
    };
  });
  const middleValue = dataPercentage.find((d) => d.name === 'promoter');

  function handleMouseEvents(event: any, data: any) {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords ? coords.x : 0,
      tooltipTop: coords ? coords.y : 0,
      tooltipData: percentageFormatter(data.percentage)
    });
  }

  function tooltipContent(content?: string) {
    return (
      <strong style={{ color: darkMode ? colors.white : `` }}>
        {content ?? 0}
      </strong>
    );
  }

  return (
    <Box>
      <Svg
        width={width}
        height={height}
        viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
        ref={containerRef}
      >
        <Group>
          {!!middleValue && midValue && (
            <Text textAnchor="middle">
              {!!selectedPiece
                ? percentageFormatter(selectedPiece?.percentage)
                : midValue.toFixed(2)}
            </Text>
          )}
          {!!midValue && (
            <>
              <Text textAnchor="middle" y={20} className="small-text">
                Net promoter score
              </Text>
              <Text textAnchor="middle" y={40} className="small-text">
                Big data social
              </Text>
            </>
          )}
        </Group>
        <VisxPie
          data={
            selectedPiece
              ? chartData.filter((data) => data.name === selectedPiece.name)
              : chartData
          }
          pieValue={(d) => d.value}
          outerRadius={radius - 10}
          innerRadius={radius - 50}
          top={height}
          left={width}
        >
          {(pie) =>
            animate && (
              <Group className="pie">
                <AnimatedPie<DonutData>
                  {...pie}
                  animate
                  getKey={(arc) => arc.data.name}
                  onClickDatum={({ data }) => {
                    setSelectedPiece((value) => (value ? null : data));
                    onClick?.(data);
                  }}
                  getColor={(arc) => arc.data.color}
                  onMouseOver={handleMouseEvents}
                  onMouseLeave={hideTooltip}
                  onMouseMove={handleMouseEvents}
                />
              </Group>
            )
          }
        </VisxPie>
      </Svg>
      {tooltipOpen &&
        !selectedPiece &&
        (darkMode ? (
          <TooltipInPortal
            style={{
              backgroundColor: theme.background,
              width: `max-content`,
              borderRadius: 5,
              borderColor: colors.white,
              borderWidth: 1,
              borderStyle: `solid`,
              padding: `0 1rem`
            }}
            applyPositionStyle
            top={tooltipTop}
            left={tooltipLeft}
          >
            {tooltipContent(String(tooltipData))}
          </TooltipInPortal>
        ) : (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
            {tooltipContent(String(tooltipData))}
          </TooltipInPortal>
        ))}

      {legend && (
        <ul>
          {data.map((d) => (
            <LegendItem key={`${d.name}${d.color}`} color={d.color}>
              <strong>{percentageFormatter(d.percentage)}</strong>
              <span>{d.name}</span>
            </LegendItem>
          ))}
        </ul>
      )}
    </Box>
  );
}

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  onMouseOver: (
    event: MouseEvent<SVGPathElement | MouseEvent>,
    data: any
  ) => void;
  onMouseLeave: () => void;
  onMouseMove: (event: MouseEvent<SVGPathElement | MouseEvent>, d: any) => void;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum,
  onMouseOver,
  onMouseLeave,
  onMouseMove
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle,
            endAngle
          })
        )}
        fill={getColor(arc)}
        onClick={() => onClickDatum(arc)}
        onTouchStart={() => onClickDatum(arc)}
        onMouseOver={(event) => onMouseOver(event, arc.data)}
        onMouseLeave={onMouseLeave}
        onMouseMove={(event) => onMouseMove(event, arc.data)}
      />
    </g>
  ));
}
