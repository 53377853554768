import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Box = styled.div`
  z-index: 2;

  @media (max-width: 1199px) {
    position: initial;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  h2,
  p {
    text-rendering: geometricPrecision;
    color: #fff;
    text-align: left;
  }

  p {
    span {
      display: block;
      font-weight: 400;
    }
  }

  .title {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.3;

    ${mediaQuery.smallScreen} {
      text-align: center;
    }
  }

  .subtitle {
    font-size: 2.5rem;
    line-height: 1.3;

    @media (max-width: 1199px) {
      text-align: center;
    }
  }

  .description {
    font-size: 1.4rem;
    line-height: 1.5;
    max-width: 650px;

    ${mediaQuery.smallScreen} {
      display: none;
    }

    @media (max-width: 1199px) {
      text-align: center;
    }
  }
`;

export const ThermometerLink = styled(Link)`
  background: ${({ theme }) =>
    theme.darkMode ? theme.yellowDark : colors.yellow500};
  border: 0;
  border-radius: 26px;
  width: max-content;
  height: 52px;
  padding: 17px;
  margin-top: 20px;

  color: ${({ theme }) => (theme.darkMode ? theme.background : colors.gray700)};
  text-decoration: ${({ theme }) => (theme.darkMode ? `underline` : ``)};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 15px;
  transition: filter 0.2s;

  svg path {
    fill: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.gray700};
  }

  ${mediaQuery.smallScreen} {
    margin-block: 2rem;
  }

  &:hover {
    filter: brightness(0.8);
    color: inherit;
  }
`;
