import { RankingModel } from 'big-data';

import { CardTagged, RankingDashboard, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useImpairmentChildData } from '@/servicesHooks/healthAgents';
import { getDashboardRanking } from '@/utils/data';

import { MultipleIcon } from '@/components/shared/RankingDashboard/Icons/Multiple.icon';
import { NoProgramIcon } from '@/components/shared/RankingDashboard/Icons/NoProgramIcon.icon';
import { AuditoryImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/AuditoryImpairment.icon';
import { FisicalImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/FisicalImpairment.icon';
import { MentalImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/MentalImpairment.icon';
import { VisualImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/VisualImpairment.icon';

const DESCRIPTION_PREFIX = 'das crianças possuem ';

const rankingModel = new Map<string, RankingModel>([
  [
    'visual',
    {
      description: () => DESCRIPTION_PREFIX,
      img: <VisualImpairmentIcon />,
      name: 'deficiência visual',
      key: 'deficiencia_visual'
    }
  ],
  [
    'auditiva',
    {
      description: () => DESCRIPTION_PREFIX,
      img: <AuditoryImpairmentIcon />,
      name: 'deficiência auditiva',
      key: 'deficiencia_auditiva'
    }
  ],
  [
    'física',
    {
      description: () => DESCRIPTION_PREFIX,
      img: <FisicalImpairmentIcon />,
      name: 'deficiência física',
      key: 'deficiencia_fisica'
    }
  ],
  [
    'mental ou intelectual',
    {
      description: () => DESCRIPTION_PREFIX,
      img: <MentalImpairmentIcon />,
      name: 'deficiência mental',
      key: 'deficiencia_mental'
    }
  ],
  [
    'múltiplas',
    {
      description: () => DESCRIPTION_PREFIX,
      img: <MultipleIcon />,
      name: 'deficiência múltipla',
      key: 'deficiencia_multipla'
    }
  ],
  [
    'não possui nenhuma deficiência',
    {
      description: () => 'das crianças ',
      img: <NoProgramIcon />,
      name: 'nenhuma deficiência',
      key: 'nenhuma_deficiencia'
    }
  ]
]);

export function CardChildImpairmentRanking() {
  const { tag } = useDashboardFilter();

  const { impairmentData, impairmentError, impairmentLoading } =
    useImpairmentChildData();

  const data = getDashboardRanking(impairmentData, rankingModel).map((data) => {
    const formatName = data.name.includes('nenhuma')
      ? data.name.replace('nenhuma', 'não possuem')
      : data.name;

    return {
      ...data,
      name: formatName
    };
  });

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={impairmentData.length === 0}
        error={impairmentError}
        loading={impairmentLoading}
      >
        <TitleText>
          Qual a porcentagem de crianças de até 8 anos com alguma deficiência
          congênita?
        </TitleText>

        <RankingDashboard
          data={data}
          loadingBenefits={false}
          selected={undefined}
          total={data[0]?.total ?? 0}
        />
      </ServiceBox>
    </CardTagged>
  );
}
