import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.button`
  color: white;
  background: ${(props) =>
    props.theme.darkMode ? colors.black : colors.ciano700};
  border: 1px solid
    ${(props) => (props.theme.darkMode ? '#dfe6e9' : colors.ciano700)};
  border-radius: 7px;
  padding: 0.8rem 2rem;
  margin: 1.05rem 0;

  display: flex;
  align-items: center;
  gap: 0.7rem;

  transition: all 0.2s;
  @media (max-width: 1230px) {
    justify-content: center;
  }

  &:hover {
    filter: brightness(1.1);

    &:active {
      transform: scale(0.98);
    }
  }

  span {
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  @media (max-width: 400px) {
    align-self: center;
    width: 100%;
  }
`;
