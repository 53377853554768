import styled, { css } from 'styled-components';

interface BoxProps {
  highlight?: boolean;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  ${({ highlight }) =>
    highlight !== undefined &&
    !highlight &&
    css`
      opacity: 0.3;
    `};

  .container-up {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 8px;
    }

    span {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.text};
    }
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 18px;
    color: ${({ theme }) => theme.text};
  }
`;

interface LineProps {
  fill: string;
  percentage: string;
}

export const Line = styled.div<LineProps>`
  background: ${({ theme }) =>
    theme.darkMode ? `rgba(45, 52, 54, 0.9)` : `rgba(45, 52, 54, 0.11)`};
  border-radius: 100px;
  height: 10px;

  /* @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform:
    }
  } */

  .line-fill {
    transition: width 0.5s;
    background: ${({ fill }) => fill};
    width: ${({ percentage }) => percentage};
    height: inherit;
    border-radius: inherit;
  }
`;
