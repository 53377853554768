import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

export const InsightNormalCard = styled.div`
  /* padding: 1.25rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .card-icon-value {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    color: ${colors.ciano700};
    padding: 0 1rem;

    .value {
      font-weight: 700;
      font-size: 2rem;
      line-height: 1.5rem;
      color: inherit;
    }
  }

  .description {
    padding: 0 1rem 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 21px;
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      .card-icon-value {
        .value {
          color: #fff !important;
        }
      }

      .description {
        color: #fff;
      }
    `}
`;
