import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { googleAnalyticsResource } from './utils/resource';

export const service = () => {
  const resource = googleAnalyticsResource + '/top-paginas';

  async function get(
    minimumDate?: string,
    ibge?: string,
    order?: string,
    pageNumber?: number
  ) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        pageNumber,
        pageSize: 5,
        minimumDate,
        ibge,
        order,
        fromCeara: true
      }
    });
    const response = await callService(() =>
      mainApi.get<{ content: any[]; numberOfPages: number }>(path, options)
    );
    const { content, numberOfPages } = response.data;
    return {
      totalPages: numberOfPages,
      content: content.map((data) => ({
        name: data.nome_pagina,
        value: data.sessoes
      }))
    };
  }

  return { get };
};

export const topPages = service();
