import styled from 'styled-components';

export const Box = styled.button`
  background: transparent;
  border: none;
  margin-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .logout-text {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 500;
    margin-left: 10px;
  }
`;
