import { CardTagged, ServiceBox } from '@/components/shared';
import { ColorText, TitleText, Text } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantByHomeData } from '@/servicesHooks/healthPregnant';
import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';

export function CardPregnantPercentageByHome() {
  const { tag } = useDashboardFilter();

  const { data, ...rest } = usePregnantByHomeData();

  const total = sumDataTotal(data);
  const isPregnantPercentage = percentageFormatter(
    (sumDataTotal(data?.filter((d) => d.name === 'Sim')) / total) * 100
  );

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>Qual a porcentagem de grávidas por domicílio?</TitleText>
        <Text>
          Das famílias visitadas,{' '}
          <ColorText>
            {isPregnantPercentage} dos domicílios possuem uma grávida.
          </ColorText>
        </Text>
      </ServiceBox>
    </CardTagged>
  );
}
