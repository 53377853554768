import { Route } from 'react-router-dom';

import { BigDataRoute } from '@/types/routes';
import { AxiosRequestConfig } from 'axios';

export function mountRoutes(routes: BigDataRoute[]) {
  function mount(routes: BigDataRoute[]): any {
    return routes.map((route) => {
      const { path, Element } = route;

      if (!route.children) {
        return path ? (
          <Route key={route.name} path={route.path} element={<Element />} />
        ) : (
          <Route key={route.name} index element={<Element />} />
        );
      }

      return (
        <Route key={route.name} path={route.path} element={<Element />}>
          {mount(route.children)}
        </Route>
      );
    });
  }

  return mount(routes);
}

export function getLinkMounterByLevel(routes: BigDataRoute[]) {
  function mount(route: BigDataRoute, level: number) {
    if (!route.children || level === 0)
      return [{ ...route, path: route.path ?? '' }];

    const paths: BigDataRoute[] = route.children.flatMap((child) =>
      mount(child, level - 1)?.map((ch: BigDataRoute) => ({
        ...ch,
        path: `${route.path}/${ch.path ?? ''}`
      }))
    );

    return paths;
  }

  return (level: number) => {
    return routes.flatMap((route) => mount(route, level));
  };
}

export function mountLinks(routes: BigDataRoute[]) {
  function mount(route: BigDataRoute) {
    if (!route.children || route.path === 'gestores-acoes')
      return [{ ...route, path: route.path ?? '' }];

    const paths: BigDataRoute[] = route.children
      .filter((child) => !!child.Element)
      .flatMap((child) =>
        mount(child)?.map((ch: BigDataRoute) => {
          const icon =
            child.iconImg({}) === null ? route.iconImg : child.iconImg;
          return {
            ...ch,
            iconImg: icon,
            path: `${route.path}${ch.path ? '/' : ''}${ch.path ?? ''}`
          };
        })
      );

    return paths;
  }

  return routes.flatMap((route) => mount(route));
}

export function getRolesChecker(routeRoles?: string[]) {
  return (userRoles?: string[]) => {
    if (!routeRoles) return true;

    return (routeRoles ?? []).some((role) => (userRoles ?? []).includes(role));
  };
}

export function getNameBySlug(slug: string, routes: BigDataRoute[]) {
  return slug === 'analiticos'
    ? 'Analíticos e Anuários'
    : routes
        .map((route) => {
          const { path } = route;
          const paths = path?.split('/');
          const lastPath = paths ? paths[paths?.length - 1] : '';
          return {
            ...route,
            path: lastPath
          };
        })
        .find((route) => route.path === slug)?.name ?? '';
}

export function ensureAxiosParamOptions(options: AxiosRequestConfig) {
  const params = options.params || {};
  const keys = Object.keys(params);
  const newParams = keys.reduce((acc, key) => {
    const param = params[key];
    if (typeof param === 'number' || !!param) {
      acc[key] = params[key];
    }
    return acc;
  }, {} as Record<string, string>);
  return { ...options, params: newParams };
}

export function showRouteByEnv<T>(env: string, obj: T) {
  if (env !== 'true' && env !== 'false') {
    throw new Error(
      "This environment variable only accepts 'true' or 'false' value!"
    );
  }

  if (env === 'true') return obj;

  return {} as T;
}
