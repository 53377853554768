import { Tabs } from '@/components/shared';
import { cpfReplacer } from '@/utils/string';
import { TabFilterBox as TabBox } from '../../components/tab-filter-box.styles';
import { useManager } from '../../contexts/ManagerContext';
import { useTabContext } from '../../contexts/TabContext';
import { tabItems } from '../../utils/tabs';
import { CardAccessedPages } from './CardAccessedPages';
import { CardRankingAccess } from './CardRankingAccess';
import { CardUserPlanActions } from './CardUserPlanActions';
import { Box, InsightsBox, UserBox } from './styles';

export function SectionContent() {
  const { manager } = useManager();
  const { handleChangeTab, tab } = useTabContext();

  return (
    <Box>
      <UserBox>
        <div className="avatar">
          <span>{manager?.name?.charAt(0)}</span>
        </div>
        <div className="user">
          <span className="name">{manager?.name}</span>
          <span>
            <span className="cpf">CPF:</span>{' '}
            {cpfReplacer(manager?.cpf ?? '', 'secret')}
          </span>
        </div>
      </UserBox>
      <TabBox>
        <Tabs
          items={tabItems}
          activeTab={tab?.value}
          onClickTab={handleChangeTab}
        />
      </TabBox>

      <InsightsBox>
        <CardRankingAccess />
        <CardAccessedPages />
        <CardUserPlanActions />
      </InsightsBox>
    </Box>
  );
}
