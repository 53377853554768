import styled from 'styled-components';
import {
  Select as AntdSelect,
  Input,
  Divider as AntdDivider,
} from 'antd';

const WIDTH = 100;

export const Box = styled.div`
  width: ${WIDTH}%;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const Select = styled(AntdSelect)`
  margin: 0 10px;
  width: calc((${WIDTH}% / 3) - 20px);
`;

export const DropdownBox = styled.div`
  margin: 0 5px;
`;

export const Search = styled(Input.Search)`
`

export const Divider = styled(AntdDivider)`
  margin: 15px 0;
`