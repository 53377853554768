import { useState } from 'react';

import { CzrmCard, CzRMTableModal, FilterDashboard } from '@/components/shared';
import { AxeDescriptionText } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';

import { LivingConditionInsights } from '../components';
import { MainCards } from '../style';
import { Box } from './styles';

const description =
  'Uma moradia adequada é aquela com condição de salubridade, de segurança e com um tamanho mínimo para que possa ser considerada habitável. Deve ser dotada das instalações sanitárias adequadas, atendida pelos serviços públicos essenciais, entre os quais água, esgoto, energia elétrica, iluminação pública, coleta de lixo, pavimentação e transporte coletivo, e com acesso aos equipamentos sociais e comunitários básicos (postos de saúde, praças de lazer, escolas públicas, etc.).';

export function LivingConditions() {
  useDashboardTitle('Pesquisa dos Agentes Sociais sobre Moradia');
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenCzRMModal() {
    setIsModalOpen(true);
  }
  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>

      <FilterDashboard />
      <MainCards>
        <CzrmCard
          color="#1D696F"
          title="das condições das famílias em "
          name="Moradia"
          onClick={handleOpenCzRMModal}
        />
      </MainCards>
      <LivingConditionInsights />

      <CzRMTableModal
        hasIndicators
        axe="livingConditions"
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={`Moradia`}
      />
    </Box>
  );
}
