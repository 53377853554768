import { Data, RankingBind, RankingModel } from 'big-data';
import { useMemo } from 'react';

import { CartaoIcon } from '@/components/shared/RankingDashboard/Icons/CartaoIcon.icon';
import { CestaBasica } from '@/components/shared/RankingDashboard/Icons/CestaBasica.icon';
import { TarifaAgua } from '@/components/shared/RankingDashboard/Icons/TarifaAgua.icon';
import { TarifaEnergia } from '@/components/shared/RankingDashboard/Icons/TarifaEnergia.icon';
import { ValeGas } from '@/components/shared/RankingDashboard/Icons/ValeGas.icon';

export const rankingModel = new Map<string, RankingModel>([
  [
    'ticketGas',
    {
      name: 'Vale Gás',
      key: 'ticketGas',
      description: () => `das famílias recebem o `,
      img: <ValeGas />
    }
  ],
  [
    'energyTax',
    {
      name: 'Isenção de tarifa de energia',
      key: 'energyTax',
      description: () => `das famílias recebem a `,
      img: <TarifaEnergia />
    }
  ],
  [
    'waterTax',
    {
      name: 'Isenção da tarifa de água',
      key: 'waterTax',
      description: () => `das famílias recebem a `,
      img: <TarifaAgua />
    }
  ],
  [
    'foodPackage',
    {
      name: 'Cesta básica',
      key: 'foodPackage',
      description: () => `das famílias recebem o `,
      img: <CestaBasica />
    }
  ],
  [
    'cmic',
    {
      name: 'Cartão Mais Infância',
      key: 'changingGame',
      description: () => `das famílias recebem o `,
      img: <CartaoIcon />
    }
  ]
]);

export function useRanking(data: Data[]): RankingBind[] {
  const analyzedBenefits = useMemo(
    () =>
      data.map((d) => ({
        ...d,
        ticketGas: d.name.includes('Vale gás'),
        cmic: d.name.includes('Pagamento do Cartão Mais Infância'),
        foodPackage: d.name.includes('Cesta básica'),
        energyTax: d.name.includes('Isenção da tarifa de energia'),
        waterTax: d.name.includes('Isenção da tarifa de água')
      })),
    [data]
  );

  const ticketGasBenefit = useMemo(
    () =>
      analyzedBenefits
        .filter((benefit) => benefit.ticketGas)
        .reduce((acc, cur) => ({ ...acc, value: acc.value + cur.value }), {
          name: 'ticketGas',
          value: 0
        }),
    [analyzedBenefits]
  );

  const cmicBenefit = useMemo(
    () =>
      analyzedBenefits
        .filter((benefit) => benefit.cmic)
        .reduce((acc, cur) => ({ ...acc, value: acc.value + cur.value }), {
          name: 'cmic',
          value: 0
        }),
    [analyzedBenefits]
  );

  const foodPackageBenefit = useMemo(
    () =>
      analyzedBenefits
        .filter((benefit) => benefit.foodPackage)
        .reduce((acc, cur) => ({ ...acc, value: acc.value + cur.value }), {
          name: 'foodPackage',
          value: 0
        }),
    [analyzedBenefits]
  );

  const energyTaxBenefit = useMemo(
    () =>
      analyzedBenefits
        .filter((benefit) => benefit.energyTax)
        .reduce((acc, cur) => ({ ...acc, value: acc.value + cur.value }), {
          name: 'energyTax',
          value: 0
        }),
    [analyzedBenefits]
  );

  const waterTaxBenefit = useMemo(
    () =>
      analyzedBenefits
        .filter((benefit) => benefit.waterTax)
        .reduce((acc, cur) => ({ ...acc, value: acc.value + cur.value }), {
          name: 'waterTax',
          value: 0
        }),
    [analyzedBenefits]
  );

  const allBenefits = useMemo(
    () => [
      ticketGasBenefit,
      cmicBenefit,
      foodPackageBenefit,
      energyTaxBenefit,
      waterTaxBenefit
    ],
    [
      ticketGasBenefit,
      cmicBenefit,
      foodPackageBenefit,
      energyTaxBenefit,
      waterTaxBenefit
    ]
  );

  return useMemo(
    () =>
      allBenefits
        .sort((a, b) => b.value - a.value)
        .map((benefit) => ({
          ...benefit,
          ...(rankingModel.get(benefit.name) as RankingModel)
        })),
    [allBenefits]
  );
}
