import { AnimatedTitle, AnimatedTopic } from '@/layout';
import { Collapse } from 'antd';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Accordion } from '../components/accordion';
import {
  Box,
  Title,
  Text,
  BoxText,
  CountList,
  List,
  ListStyled
} from '../styles';
const { Panel } = Collapse;

export function UseTerms() {
  return (
    <Box>
      <Title>Termos e Condições de Uso do Big Data Social</Title>
      <Text>
        Olá, seja bem-vinda(o) e obrigado por fazer uso do{' '}
        <b>BIG DATA SOCIAL</b>. Temos a missão de melhorar a gestão pública ao
        integrar e disponibilizar, de forma transparente, informações
        estatísticas da área de Proteção Social do Estado do Ceará.
      </Text>
      <Text>
        Assim, estes Termos e Condições de Uso (“Termos”) são o acordo formal
        entre Você e o <b>BIG DATA SOCIAL</b>, plataforma gerenciada pela
        Secretaria da Proteção Social, Justiça, Cidadania, Mulheres e Direitos
        Humanos do Estado do Ceará (“SPS”), inscrita no CNPJ n°
        08.675.169/0001-53, com sede na Rua Soriano Albuquerque, n° 230, Bairro
        Joaquim Távora, CEP 60160-130, a qual figura como Controladora de Dados
        para todos os fins relacionados à Plataforma.
      </Text>
      <article>
        <AnimatedTitle text="Qual o objetivo dos termos de uso?" />
        <article>
          <Text>
            O objetivo deste documento é estabelecer regras claras para o uso do
            <b> BIG DATA SOCIAL</b>, bem como a descrição dos seus direitos e
            obrigações, enquanto usuário, para o uso da Plataforma. É importante
            que Você leia os Termos com a máxima atenção, utilizando o{' '}
            <b> BIG DATA SOCIAL</b> tão somente se concordar expressamente com
            as disposições aqui estabelecidas.
          </Text>
          <Text>
            Informamos que sua relação com a Plataforma <b>BIG DATA SOCIAL</b>{' '}
            inclui estes Termos, o{' '}
            <Link to="/aviso-privacidade">Aviso de Privacidade</Link> e
            quaisquer outros termos ou contratos adicionais que regulamentem ou
            venham a regulamentar os Serviços Ofertados.
          </Text>
          <BoxText>
            Saiba que ao utilizar o <b>BIG DATA SOCIAL</b> você garante, de
            forma expressa, que é maior de 18 anos e é plenamente capaz ou,
            sendo menor de 18 anos, que está devidamente autorizado por seus
            pais ou representantes legais, nos termos da legislação vigente,
            para exercer e gozar de todos os serviços ofertados.
          </BoxText>
          <Text>
            Ainda, esteja ciente de que a utilização ampla do{' '}
            <b>BIG DATA SOCIAL</b>é disponibilizada apenas aos gestores públicos
            ("Usuário Gestor Público") devidamente autorizados pela SPS,
            conforme melhor esclarecemos abaixo.
          </Text>
          <Text>
            Por fim, saiba que o uso do <b>BIG DATA SOCIAL</b> está condicionado
            ao aceite inequívoco destes Termos e Condições de Uso. Portanto, se
            está utilizando o aplicativo, quer dizer que Você leu e concordou
            com o que está escrito aqui!
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Definições importantes" />
        <Text>
          Antes de iniciar o uso do aplicativo e para facilitar a leitura dos
          seguintes termos, saiba que:
        </Text>
        <article>
          <List>
            <li>
              <AnimatedTopic delay={0} number={1}>
                <b>
                  “<b>BIG DATA SOCIAL</b>”
                </b>
                : é uma plataforma analítica que tem a missão de melhorar a
                gestão pública ao integrar informações estatísticas da área de
                Proteção Social do Estado do Ceará. Para isso, ele usa
                tecnologia de big data (“computação em nuvem, banco de dados e
                analíticos”), mostrando indicadores de categorias como auxílios
                sociais, educação, moradia, trabalho e renda. Com o{' '}
                <b>BIG DATA SOCIAL</b>, Você terá acesso a esses indicadores de
                forma transparente, prática e segura;
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={2}>
                <b>“Você”</b>: é o usuário do <b>BIG DATA SOCIAL</b>;
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={3}>
                <b>“Usuário”</b>: significa a pessoa física ou representante,
                preposto ou funcionário autorizado de pessoa jurídica que, em
                geral, efetivamente utiliza o <b>BIG DATA SOCIAL</b>. O Usuário
                pode ser “Usuário Cidadão” ou “Usuário Gestor Público”, tendo,
                de acordo com seu tipo de acesso, navegabilidade mais ou menos
                restrita, conforme explicaremos a seguir. Quando mencionam
                apenas “Usuários”, estes Termos referem-se tanto ao Usuário
                Cidadão quanto ao Usuário Gestor Público.
              </AnimatedTopic>
            </li>
            <li>
              <AnimatedTopic delay={0} number={4}>
                <b>“Serviços Ofertados”</b> ou “Serviços”: significa todos os
                serviços ofertados pela SPS por meio do <b>BIG DATA SOCIAL</b>.
              </AnimatedTopic>
            </li>
          </List>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Aceitação dos termos e condições de uso" />
        <article>
          <Text>
            Ao acessar e utilizar o <b>BIG DATA SOCIAL</b>, você manifesta
            concordância integral com as disposições destes Termos, de forma
            expressa, voluntária, irrevogável e irretratável, declarando estar
            ciente de todo o seu conteúdo, tendo lido, entendido e aceitado
            todas as regras, condições e obrigações aqui estabelecidas.
          </Text>
          <Text>
            O Usuário deverá ler, entender e concordar expressamente (ou
            manifestar ciência, quando for o caso) a todos os manuais, regras,
            políticas e contratos em vigor aplicáveis aos Serviços antes de
            efetivamente utilizá-los. Fica reservado ao <b>BIG DATA SOCIAL</b>.
            o direito de instituir novas diretrizes em tais instrumentos,
            visando ao aprimoramento, a melhoria dos Serviços Ofertados, ou a
            incrementação de novas funções e recursos para assegurar a
            operacionalidade ou a segurança dos Serviços Ofertados, que serão
            devidamente divulgadas aos Usuários.
          </Text>
          <Text>
            Qualquer nova versão dos Termos entrará em vigor a partir do momento
            em que for publicada. Os Usuários serão informados com antecedência
            acerca da nova versão dos Termos, por meio de notificação e/ou
            e-mail, sendo que a continuidade de utilização dos Serviços
            Ofertados implica na aceitação, por parte dos Usuários, das novas
            disposições.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Sobre os serviços do Big Data Social" />
        <article>
          <Text>
            Antes de conhecer os Serviços Ofertados, saiba que o{' '}
            <b>BIG DATA SOCIAL</b>
            funciona, única e exclusivamente, como uma Plataforma demonstrativa
            de informações:
          </Text>
          <Text>
            {`1) `} Apenas estatísticas para o público geral (Usuário Cidadão),
            tais como índices, percentuais, números de cidadão em situações de
            vulnerabilidade social (sem identificar diretamente nenhum deles!)
            etc.;
          </Text>
          <Text>
            {`2) `} Com informações detalhadas e compartilhamento de dados
            pessoais, destinada exclusivamente a gestores públicos do Ceará
            (Usuário Gestor Público).
          </Text>
          <Text>
            Sendo assim, conheça os Serviços Ofertados pelo{' '}
            <b>BIG DATA SOCIAL</b>, categorizados por tipo de acesso/Usuário
            (clique em cada área para expandir o conteúdo):
          </Text>
          <Accordion>
            <Panel header="PARA O USUÁRIO CIDADÃO" key="1">
              <Text>
                <b>Conheça a Plataforma:</b> breve explicação sobre a Plataforma
                do <b>BIG DATA SOCIAL</b> e seus painéis visuais demonstrados.
              </Text>
              <Text>
                <b>Impactos:</b> explanação dos impactos esperados pela
                Plataforma do <b>BIG DATA SOCIAL</b>, que são:
              </Text>
              <CountList>
                <li>Entender as necessidades das famílias mais vulneráveis;</li>
                <li>
                  Orientar a gestão pública na tomada de decisão baseada em
                  dados;
                </li>
                <li>Dar transparência aos dados da Assistência Social; </li>
                <li>Aproximar governo e cidadãos pelos meios digitais; </li>
                <li>
                  Monitorar o impacto dos auxílios públicos fornecidos para a
                  sociedade.
                </li>
              </CountList>
              <Text>
                <b>Mapa de famílias beneficiadas pelo CMIC</b>: Mapa do Ceará
                com os números, por municípios, de famílias beneficiadas com o
                Cartão Mais Infância (CMIC), que é um benefício do Governo do
                Estado do Ceará destinado às famílias em situação de
                vulnerabilidade social, que tenham crianças de zero a 5 anos e
                11 meses.
              </Text>

              <Text>
                <b>Dados da Proteção Social</b>: painel visual de números de:
              </Text>
              <CountList>
                <li>Famílias beneficiadas pelo Cartão Mais Infância Ceará; </li>
                <li>
                  Número aproximado de crianças já beneficiadas pelo Programa;
                </li>
                <li>
                  Valor investido para melhorar a qualidade de vida dessas
                  famílias;
                </li>
                <li>
                  Número de famílias já beneficiadas pelo Programa Vale Gás
                  Social.
                </li>
              </CountList>
            </Panel>
            <Panel header="PARA O USUÁRIO GESTOR PÚBLICO" key="2">
              <li>
                <Text>
                  <b>Termômetro de Dados da Proteção Social</b>: painel que
                  apresenta visualização e estimativa de ações, cuidados,
                  atenções, benefícios e auxílios para enfrentamento da pobreza
                  e da desigualdade, promoção de bem-estar e proteção social a
                  famílias, crianças, adolescentes e jovens, pessoas com
                  deficiência e idosos, entre outros, no Estado do Ceará.
                </Text>
                <Text>
                  O Usuário Gestor Público terá acesso a informações gerais
                  sobre as famílias em estado de vulnerabilidade social do
                  Ceará, tais como: quantas famílias já foram entrevistadas;
                  quantas famílias já foram entrevistadas por município; como
                  está a meta de visitação (inclusive por município); qual o
                  perfil dos entrevistados na pesquisa dos agentes do Cartão
                  Mais Infância; etc.
                </Text>
                <Text>
                  Além disso, poderá obter informações específicas e detalhadas
                  sobre cada um dos seguintes Eixos:
                </Text>
                <CountList>
                  <li>Insegurança Alimentar;</li>
                  <li>Assistência Social;</li>
                  <li>Saúde;</li>
                  <li>Educação;</li>
                  <li>Moradia;</li>
                  <li>Trabalho e Renda</li>
                </CountList>
                <Text>Abaixo, especificamos do que se trata cada eixo.</Text>
                <CountList>
                  <li>
                    <Text>
                      <b>Insegurança Alimentar</b>: visualização de dimensões da
                      segurança alimentar e nutricional de famílias cearenses em
                      estado de vulnerabilidade, por meio da percepção e
                      experiência com a fome. É possível filtrar as informações
                      por:
                    </Text>
                    <CountList>
                      <li>Fora de Insegurança Alimentar;</li>
                      <li>Insegurança Alimentar Leve;</li>
                      <li>Insegurança Alimentar Moderada; </li>
                      <li>Insegurança Alimentar Grave.</li>
                    </CountList>
                  </li>
                  <li>
                    <Text>
                      <b>Assistência Social</b>: visualização de dados sobre
                      monitoramento e avaliação das ofertas socioassistenciais,
                      integrado com demais políticas públicas. É possível
                      visualizar informações de acordo com os centros de
                      referência básicos e especializados Centro de Referência
                      da Assistência Social (CRAS) e Centro de Referência
                      Especializado de Assistência Social (CREAS).
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <b>Saúde</b>: visualização de dados sobre doenças e outros
                      agravos, com o fim de proteção e recuperação, tais como
                      deficiência, vacinação, se há gestação etc. Caso o Usuário
                      Gestor Público deseje ter acesso a uma visão micro sobre
                      as informações de Saúde, poderá acessar o Integra Social,
                      conforme explicado no sub-tópico 2.4. abaixo.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <b>Educação</b>: visualização de dados sobre o atingimento
                      de metas dos investimentos públicos em educação conforme o
                      Plano Estadual de Educação do Ceará (PEE). É possível
                      filtrar as informações por
                    </Text>
                    <CountList>
                      <li>Creche;</li>
                      <li>Pré-Escola;</li>
                      <li>Escola.</li>
                    </CountList>
                  </li>
                  <li>
                    <Text>
                      <b>Moradia</b>: visualização de dados para aferição de
                      (in)existência de moradia com condição de salubridade, de
                      segurança e com um tamanho mínimo para que possa ser
                      considerada habitável – ou seja, que detenha instalações
                      sanitárias adequadas, atendida pelos serviços públicos
                      essenciais. Caso o Usuário Gestor Público deseje ter
                      acesso a uma visão micro sobre as informações das
                      Condições de Moradia, poderá acessar o Integra Social,
                      conforme explicado no sub-tópico 2.4. abaixo.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <b>Trabalho e Renda</b>: visualização de dados sobre
                      trabalho e renda, tais como realização ou não de curso de
                      qualificação, número de trabalhadores nos membros da
                      família, despesa total da família etc. Caso o Usuário
                      Gestor Público deseje ter acesso a uma visão micro sobre
                      as informações de Trabalho e Renda, poderá acessar o
                      Integra Social, conforme explicado no sub-tópico 2.4.
                      abaixo.
                    </Text>
                  </li>
                </CountList>
              </li>
            </Panel>
          </Accordion>
          <Text>
            Por fim, o Usuário Gestor Público também poderá, com base nos dados
            visualizados através dos vários painéis/indicadores do{' '}
            <b>BIG DATA SOCIAL</b>, criar e enviar Recomendações a um agente ou
            instituição pública, que será direcionada ao e-mail institucional ao
            qual o Usuário Gestor Público irá endereçar a recomendação.
          </Text>
          <BoxText>
            O <b>BIG DATA SOCIAL</b> não inclui acesso à “internet”. Desta
            forma, será de integral e exclusiva responsabilidade dos Usuários
            obter, de forma independente e às suas custas, o equipamento,
            softwares e os serviços necessários para garantir sua conexão à
            Internet e ao <b>BIG DATA SOCIAL</b>.
          </BoxText>
          <Text>
            Os Serviços Ofertados poderão incluir links que remetam a páginas de
            Internet, conteúdos ou recursos de terceiros, que não estão sob o
            controle do <b>BIG DATA SOCIAL</b>. Em razão disto, os Usuários
            reconhecem e concordam expressamente que o <b>BIG DATA SOCIAL</b>{' '}
            não terá qualquer responsabilidade sobre referidas páginas,
            conteúdos ou recursos de terceiros.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Suporte técnico referente aos serviços" />
        <article>
          <Text>
            Os Usuários entendem e expressamente concordam que o uso do{' '}
            <b>BIG DATA SOCIAL</b> não lhes dá direito a suporte, assistência,
            atendimento ou qualquer outra forma de auxílio técnico com relação
            aos Serviços Ofertados, à exceção de instruções para navegação. A
            utilização dos Serviço será de inteiro risco dos Usuários, sendo que
            o <b>BIG DATA SOCIAL</b> apenas concorda em fornecê-lo.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Obrigações e responsabilidade dos usuários" />
        <article>
          <Text>
            Os Usuários assumem integralmente todos os ônus e responsabilidades
            decorrentes de seus atos no <b>BIG DATA SOCIAL</b>, respondendo,
            ainda, pelos atos praticados por terceiros que se valerem do uso da
            Plataforma.
          </Text>
          <Text>
            Os Usuários deverão indenizar o <b>BIG DATA SOCIAL</b> ou terceiros
            por quaisquer ações ou omissões que causem prejuízos advindos do
            descumprimento das regras estabelecidas nestes Termos, nas demais
            diretrizes e políticas do <b>BIG DATA SOCIAL</b> ou constantes da
            lei vigente.
          </Text>
          <Text>
            Os Usuários concordam em indenizar o <b>BIG DATA SOCIAL</b> por todo
            e qualquer dano ou perda que possa eventualmente surgir decorrente
            do seu acesso e uso da Plataforma ou por qualquer demanda promovida
            por outros Usuários ou terceiros decorrentes da violação de qualquer
            cláusula destes Termos, de outras políticas e regras estabelecidas
            pelo <b>BIG DATA SOCIAL</b>, ou da legislação vigente.
          </Text>
          <Accordion>
            <Panel
              header="Compromentimento dos Usuários para usufruir os Serviços Ofertados"
              key="1"
            >
              <ListStyled>
                <li>Manter sob proteção o acesso à Plataforma;</li>
                <li>
                  Não veicular conteúdos considerados ofensivos, abusivos,
                  difamatórios, pornográficos, ameaçadores, obscenos, ilegais,
                  ou que se destinem a promover ou cometer ato ilegal de
                  qualquer natureza, que incluam conteúdo malicioso, tais como
                  vírus, cavalos de Troia ou malwares, ou que de qualquer forma
                  interfiram no acesso de qualquer Usuário aos Serviços
                  Ofertados;{' '}
                </li>
                <li>
                  Não agredir, caluniar, injuriar ou difamar outros Usuários;
                </li>
                <li>
                  Ser maior de 18 anos, e gozando de plena capacidade nos termos
                  da lei, caso seja pessoa física, ou, sendo menor de 18 anos,
                  que estar devidamente autorizado por seus pais ou
                  representantes legais, nos termos da legislação vigente, para
                  exercer e gozar de todos os serviços ofertados;
                </li>
                <li>
                  Não utilizar os Serviços para fins ilegais, ilegítimos ou que
                  contrariem a moral e a boa-fé objetiva;
                </li>
                <li>
                  Não utilizar os Serviços para se engajar em atividade
                  comercial, o que inclui, mas não se limita, a oferecer
                  qualquer tipo de produto para venda, procurar anunciantes e
                  patrocinadores e inserir banners de qualquer tipo;
                </li>
                <li>
                  Não veicular informação sobre atividades ilegais e de
                  incitação ao crime ou que ponham em risco a saúde ou
                  integridade física dos Usuários ou de terceiros;
                </li>
                <li>
                  Não divulgar mensagens, páginas criptografadas ou protegidas
                  por senhas;
                </li>
                <li>
                  Não veicular informação relativa à pirataria de software;
                </li>
                <li>
                  Não divulgar material protegido por direitos autorais ou por
                  sigilo confidencial sem a autorização dos seus titulares,
                  incluindo fotos, textos e programas de computador, além de
                  outros;
                </li>
                <li>
                  Não se fazer passar por outra pessoa, empresa, órgão ou
                  instituição;
                </li>
                <li>
                  Não tratar dados pessoais de demais Usuários da Plataforma;
                </li>
                <li>Não veicular páginas ou imagens ocultas;</li>
                <li>
                  Não modificar, adaptar, traduzir ou fazer engenharia reversa,
                  no todo ou em parte, de Software utilizado pelo{' '}
                  <b>BIG DATA SOCIAL</b> ou por parceiro na prestação dos
                  Serviços;
                </li>
                <li>
                  Não transmitir qualquer material que viole direitos de
                  terceiro, incluindo direitos de propriedade intelectual;
                </li>
                <li>
                  Não enviar mensagens não solicitadas, reconhecidas como
                  “spam”, “junk mail” ou correntes de correspondência (“chain
                  letters”);
                </li>
                <li>
                  Não utilizar os Serviços para, diretamente, enviar vírus ou,
                  de maneira indireta, enviar arquivos contendo vírus (“Cavalos
                  de Tróia”), ou outros, de qualquer natureza, ou, de modo
                  geral, enviar conteúdo que possa causar danos ao destinatário
                  e/ou a terceiros;
                </li>
                <li>
                  Não praticar atos que violem a lei, seja no âmbito local,
                  estadual, nacional ou internacional, aplicável ao caso
                  concreto;
                </li>
                <li>
                  Não obter ou tentar obter acesso não autorizado a outros
                  sistemas ou redes de computadores conectados aos Serviços;
                </li>
                <li>
                  Serem os únicos responsáveis pelo conteúdo que transmitirem ou
                  retransmitirem, bem como pelo conteúdo e informações que
                  disponibilizarem relacionados ao Serviço;
                </li>
                <li>
                  Não interferir ou interromper os Serviços, os servidores ou as
                  redes a eles conectados;
                </li>
                <li>
                  Cumprir todos os requerimentos, procedimentos, políticas, e
                  regulamentos de redes conectadas aos Serviços;
                </li>
                <li>
                  Reconhecer que estes Termos se formalizam, vinculando as
                  partes, através da assinatura eletrônica do usuário, que se
                  dará pelo clique no checkbox com o texto “Eu estou de acordo
                  com os termos de uso”;
                </li>
                <li>
                  Leram e estão cientes e de pleno acordo com todos os termos e
                  condições deste instrumento;
                </li>
                <li>
                  Os pais ou os representantes legais do menor de idade
                  responderão pelos atos por ele praticados caso porventura
                  venha a utilizar, irregularmente, os Serviços objeto deste
                  instrumento, dentre os quais eventuais danos causados a
                  terceiros e pela prática de atos vedados por lei ou pelas
                  disposições deste contrato;
                </li>
                <li>
                  Não poderão usar os Serviços como local de armazenamento para
                  download remoto de arquivos por múltiplos usuários;
                </li>
                <li>
                  Os Usuários expressamente declaram e comprometerem-se a não
                  levar a cabo atividades que interfiram, interrompam ou
                  danifiquem os Serviços (ou os servidores, equipamentos e as
                  redes que estejam relacionados com os Serviços, sejam do BIG
                  DATA SOCIAL ou de terceiros) ou os documentos, arquivos e toda
                  classe de conteúdo armazenados nos equipamentos informáticos
                  (“hacking”);{' '}
                </li>
                <li>
                  Os Usuários comprometem-se a utilizar os Serviços em
                  conformidade com a lei, a moral e os bons costumes,
                  observando, ainda, todos os termos e condições contidos nos
                  presentes Termos de Uso e nos demais documentos a que aderiram
                  ou manifestaram ciência. Os Usuários comprometem-se, ainda, a
                  abster-se de utilizá-los com objetivos ou efeitos ilícitos,
                  lesivos aos direitos e interesses de terceiros.
                </li>
              </ListStyled>
            </Panel>
            <Panel
              header="Compromentimento do Usuário Gestor Público para usufruir os Serviços Ofertados"
              key="2"
            >
              <ListStyled>
                <li>
                  Ser efetivamente Gestor Público, assim considerados os chefes
                  dos Poderes Executivos Municipais e Estadual e todos os
                  representantes, secretários e hierarquicamente vinculados; os
                  agentes vinculados a instituições em geral da Administração
                  Pública nesses âmbitos; além dos gestores de empresas públicas
                  e de eventuais projetos de instituições privadas desenvolvidas
                  em parceria com o Poder Público.
                </li>
                <li>
                  Ter estrita observância ao dever de confidencialidade quanto
                  às informações por si acessadas através do{' '}
                  <b>BIG DATA SOCIAL</b>, assim como ao dever de utilização de
                  tais informações tão somente para os fins de execução de
                  políticas públicas almejados.
                </li>
                <li>
                  Não utilizar as informações por si acessadas através do{' '}
                  <b>BIG DATA SOCIAL</b> com fins discriminatórios ou pessoais,
                  em qualquer hipótese.
                </li>
                <li>
                  Arcar com a devida responsabilização em caso de fraude à
                  alegação de identidade de “Gestor Público”, nos termos das
                  leis de Direito Público aplicáveis, sem prejuízo de eventual
                  indenização por perdas e danos causados, conforme explanado no
                  início deste tópico 5.
                </li>
              </ListStyled>
            </Panel>
            <Panel
              header="Ciência dos Usuários sobre o Estado do Ceará"
              key="3"
            >
              <ListStyled>
                <li>
                  Não garante, declara, nem assegura que esta Plataforma esteja
                  livre de perda, interrupção, ataque, vírus, interferência,
                  pirataria ou outra invasão de segurança, e isenta-se de
                  qualquer responsabilidade em relação a essas questões, sendo o
                  Usuário o único responsável pelo backup do seu próprio
                  dispositivo;
                </li>

                <li>
                  Em hipótese alguma irá se responsabilizar por perdas ou danos
                  causados pelo uso da Plataforma;
                </li>

                <li>
                  Os Serviços são fornecidos na forma como estão
                  disponibilizados, e que o <b>BIG DATA SOCIAL</b> não é (e nem
                  será) responsável pela exclusão, não entrega ou falha no
                  arquivamento de qualquer dado ou informação cadastrada pelos
                  Usuários;
                </li>

                <li>
                  Os Serviços são fornecidos da forma em que estiverem
                  disponíveis, sem garantias de performance ou alcance de
                  resultados específicos. O <b>BIG DATA SOCIAL</b> envidará os
                  melhores esforços para que os Serviços funcionem da melhor
                  maneira possível.
                </li>
              </ListStyled>
            </Panel>
          </Accordion>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Sanções" />
        <article>
          <Text>
            Sem prejuízo de outras medidas cabíveis, o <b>BIG DATA SOCIAL</b>{' '}
            poderá advertir, suspender e/ou cancelar, temporária ou
            definitivamente, a conta de um Usuário, Cidadão ou Gestor Público,
            bem como excluir ou censurar conteúdos veiculados por este na
            Plataforma, iniciando as ações legais cabíveis e/ou cancelando a
            prestação dos Serviços Ofertados se:
          </Text>
          <ListStyled>
            <li>
              O Usuário descumprir quaisquer cláusulas destes Termos, o Aviso de
              Privacidade, as demais políticas do <b>BIG DATA SOCIAL</b> ou a
              legislação vigente;
            </li>
            <li>
              O Usuário descumprir quaisquer de seus deveres e
              responsabilidades;
            </li>
            <li>O Usuário praticar atos fraudulentos ou dolosos;</li>
            <li>
              Não puder ser verificada a identidade do Usuário ou se qualquer
              informação fornecida por ele estiver incorreta, incompleta ou
              falsa; e/ou
            </li>
            <li>
              O <b>BIG DATA SOCIAL</b> entender que a atitude do Usuário possa
              ou tenha causado algum dano a ele ou a terceiros.
            </li>
          </ListStyled>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Propriedade intelectual" />
        <article>
          <Text>
            O <b>BIG DATA SOCIAL</b> concede aos Usuários uma permissão
            limitada, não exclusiva e revogável para fazer uso pessoal e não
            comercial do BIG DATA SOCIAL e dos Serviços Ofertados.
          </Text>
          <Text>
            Os Usuários reconhecem que o <b>BIG DATA SOCIAL</b> é o exclusivo
            titular dos direitos de propriedade intelectual e industrial
            associados aos Serviços Ofertados, sua Plataforma, os conteúdos de
            suas telas, programas, base de dados, redes, códigos, software,
            hardware, informação, tecnologia, funcionalidades, arquivos, marcas,
            patentes, direitos autorais, modelos industriais, nomes comerciais,
            dentre outros direitos protegidos pelas leis nacionais e
            internacionais em vigor.
          </Text>
          <Text>
            Os Usuários não poderão fazer cópias não autorizadas do{' '}
            <b>BIG DATA SOCIAL</b> – no todo ou em parte – vez que todos os
            direitos autorais incidentes sobre a Plataforma (código fonte,
            interface, layout, “look and feel” e outros) são de propriedade
            exclusiva do <b>BIG DATA SOCIAL</b>.
          </Text>
          <Text>
            Não é permitido aos Usuários reconfigurar, desmontar ou praticar, de
            qualquer forma, engenharia reversa no <b>BIG DATA SOCIAL</b>.
          </Text>
          <Text>
            Os Usuários não poderão utilizar a marca e/ou quaisquer sinais
            distintivos utilizados pelo <b>BIG DATA SOCIAL</b>.
          </Text>
          <Text>
            Todos os direitos e licenças não concedidos expressamente nestes
            Termos são reservados aos proprietários dos conteúdos ou serviços,
            vez que os presentes Termos não concedem quaisquer licenças
            implícitas.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Prazos" />
        <article>
          <Text>
            Estes Termos permanecerão vigentes por tempo indeterminado, desde a
            sua adesão até a sua rescisão, podendo esta última ser realizada a
            qualquer tempo por Você ou pelo <b>BIG DATA SOCIAL</b>.
          </Text>
          <Text>
            O <b>BIG DATA SOCIAL</b> poderá rescindir estes Termos, suspendendo
            ou cancelando seu acesso à Plataforma a qualquer momento: em caso de
            descontinuidade do aplicativo pelo Estado do Ceará ou em caso de
            descumprimento, pelo Usuário, dos termos e condições do presente
            Termo.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Como entrar em contato para esclarecimentos?" />
        <article>
          <Text>
            Para enviar qualquer dúvida, problema, sugestão ou elogio sobre o
            <b> BIG DATA SOCIAL</b>, Você pode entrar em contato por meio de:
          </Text>

          <div className="box-icon">
            <div className="icon">
              <HiOutlineMail size={50} />
            </div>

            <Text>assessoria.gabinete@sps.ce.gov.br</Text>
          </div>

          <div className="box-icon">
            <div className="icon">
              <BsTelephone size={50} />
            </div>

            <Text>(85) 3101.4597 / (85) 3101.4601</Text>
          </div>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Disposições gerais" />
        <article>
          <Text>
            Se qualquer cláusula destes Termos for considerada inválida ou
            inaplicável, deverá ser excluída, e as cláusulas remanescentes
            continuarão válidas e plenamente aplicáveis.
          </Text>
          <Text>
            Estes Termos, assim como o Aviso de Privacidade e quaisquer outras
            diretrizes e regras divulgadas pelo <b>BIG DATA SOCIAL</b>{' '}
            constituem o acordo integral das Partes com relação ao objeto aqui
            tratado, substituindo todos os acordos anteriores, sejam escritos ou
            verbais.
          </Text>
          <Text>
            A tolerância ou o não exercício, por qualquer das Partes, de
            quaisquer direitos a ela assegurados nestes Termos de Uso ou na lei
            em geral não importará em novação ou em renúncia a qualquer desses
            direitos, podendo a referida Parte exercê-los durante a vigência
            destes Termos de Uso.
          </Text>
          <Text>
            Ao clicar em “Eu Aceito” ou ao utilizar efetivamente os Serviços, os
            Usuários reconhecem e aceitam que isto significará uma concordância
            com o presente Termo de Uso. Os Usuários declaram e garantem que
            leram e concordaram com a versão mais recente dos Termos de Uso, que
            vinculará automaticamente os Usuários.
          </Text>
          <Text>
            Os Usuários reconhecem e aceitam que o <b>BIG DATA SOCIAL</b>{' '}
            poderá, a seu exclusivo critério, parar (permanente ou
            temporariamente) de prestar os Serviços (ou qualquer parte dos
            Serviços) a si ou à generalidade dos utilizadores, sem avisar
            previamente.
          </Text>
        </article>
      </article>
      <article>
        <AnimatedTitle text="Foro e soluções de conflitos" />
        <article>
          <Text>
            A relação estabelecida entre Você e o <b>BIG DATA SOCIAL</b> será
            regida exclusivamente pelas Leis da República Federativa do Brasil,
            sendo estas as únicas aplicáveis em caso de qualquer discussão ou
            litígio relativo a estes Termos ou a quaisquer outras
            políticas/regulamentações do <b>BIG DATA SOCIAL</b>.
          </Text>
          <Text>
            Caso seja necessário dirimir qualquer dúvida ou controvérsia
            decorrente destes Termos, fica eleito o foro da Comarca de
            Fortaleza/CE, com renúncia de todos os outros, por mais
            privilegiados que sejam.
          </Text>
        </article>
      </article>
    </Box>
  );
}
