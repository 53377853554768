import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataGroup } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const {
  getQualificationCourseInTheLast12Monthes,
  getQualificationCourseInterests
} = profileSearch;

const labels = [
  `Pessoas que fizeram cursos de qualificação no último ano`,
  `Pessoas interessadas em fazer cursos de qualificação`
];

export function useQualificationCourse() {
  const [qualificationData, setQualificationData] = useState<DataGroup[]>([]);
  const [qualificationLoading, setQualificationLoading] = useState(false);
  const [qualificationError, setQualificationError] = useState<Error | null>(
    null
  );

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setQualificationLoading(true);
    const requests = [
      getQualificationCourseInTheLast12Monthes,
      getQualificationCourseInterests
    ];
    Promise.all(requests.map((request) => request(ibge, year, semester, zone)))
      .then((data) => data.map((d) => getDataWithPercentage(d)))
      .then((dataPercentage) =>
        dataPercentage.map((d, i) => ({ label: labels[i], data: d }))
      )
      .then((data) => setQualificationData(data))
      .catch((error) => setQualificationError(error))
      .finally(() => setQualificationLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      qualificationData,
      qualificationLoading,
      qualificationError
    }),
    [qualificationData, qualificationLoading, qualificationError]
  );
}
