import { BrowserRouter } from 'react-router-dom';
import {
  BigDataRoutes,
  CookieWarning,
  ErrorBoundary,
  FeedbackWidget
} from '@/components/shared';

import { routesDashboard } from './routes.dashboard';
import { routesAnalytics } from './routes.analytics';
import { routes } from './routes';
import { LoginProvider } from '@/hooks';
import { ActionProvider } from '@/hooks/useAction';

export function PageManager() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <LoginProvider>
        <FeedbackWidget />

        <ActionProvider>
          <ErrorBoundary>
            <BigDataRoutes
              routes={[routes, routesAnalytics, routesDashboard]}
            />
            <CookieWarning />
          </ErrorBoundary>
        </ActionProvider>
      </LoginProvider>
    </BrowserRouter>
  );
}
