export function AuditoryImpairmentIcon() {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 19C13.71 19 13.44 18.94 13.24 18.85C12.53 18.5 12.03 17.97 11.53 16.47C11 14.91 10.06 14.18 9.14002 13.47C8.35002 12.86 7.53001 12.23 6.82001 10.94C6.29001 9.99997 6.00001 8.92997 6.00001 7.99997C6.00001 5.19997 8.20002 2.99997 11 2.99997C13.8 2.99997 16 5.19997 16 7.99997H18C18 4.06997 14.93 0.999971 11 0.999971C7.07001 0.999971 4.00001 4.06997 4.00001 7.99997C4.00001 9.25997 4.38001 10.65 5.07001 11.9C6.00001 13.55 7.05001 14.38 7.92001 15.05C8.73001 15.67 9.31002 16.12 9.63002 17.1C10.23 18.92 11 19.94 12.36 20.65C12.87 20.88 13.43 21 14 21C15.0609 21 16.0783 20.5785 16.8284 19.8284C17.5786 19.0783 18 18.0608 18 17H16C16 17.5304 15.7893 18.0391 15.4142 18.4142C15.0392 18.7893 14.5304 19 14 19ZM4.64001 1.63997L3.22001 0.219971C2.19713 1.24063 1.38607 2.45341 0.833473 3.78857C0.280871 5.12374 -0.00237587 6.55497 1.50104e-05 7.99997C1.50104e-05 11.04 1.23001 13.79 3.22001 15.78L4.63001 14.37C3.79252 13.5346 3.12889 12.5416 2.67747 11.4482C2.22605 10.3548 1.9958 9.18285 2.00002 7.99997C2.00002 5.49997 3.00001 3.25997 4.64001 1.63997ZM8.50002 7.99997C8.50002 8.32827 8.56468 8.65337 8.69032 8.95668C8.81595 9.25999 9.0001 9.53559 9.23225 9.76774C9.70109 10.2366 10.337 10.5 11 10.5C11.3283 10.5 11.6534 10.4353 11.9567 10.3097C12.26 10.184 12.5356 9.99988 12.7678 9.76774C12.9999 9.53559 13.1841 9.25999 13.3097 8.95668C13.4354 8.65337 13.5 8.32827 13.5 7.99997C13.5 7.33693 13.2366 6.70104 12.7678 6.2322C12.2989 5.76336 11.6631 5.49997 11 5.49997C10.337 5.49997 9.70109 5.76336 9.23225 6.2322C8.76341 6.70104 8.50002 7.33693 8.50002 7.99997Z"
        fill="white"
      />
    </svg>
  );
}
