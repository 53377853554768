import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

type ItemType = {
  text: string;
  percentage: number;
  color: string;
};

interface LegendProps {
  items: ItemType[];
  selected: string | null;
}

export function Legend({ items, selected }: LegendProps) {
  const total = items.reduce((acc, cur) => acc + cur.percentage, 0);
  const withPercentage = items
    .map((item) => {
      return {
        ...item,
        percentage: Number(((item.percentage / total) * 100).toFixed(2))
      };
    })
    .sort((a, b) => {
      if (a.percentage < b.percentage) return -1;
      if (a.percentage > b.percentage) return 1;
      return 0;
    });

  return (
    <Box>
      {withPercentage.map((item) => (
        <Item
          key={`${item.color}${item.text}`}
          afterBackgroundColor={item.color}
          opacityMinus={selected !== null && item.text !== selected}
        >
          <span className="legend-text">
            {item.text} <HighlightedItem> {item.percentage}% </HighlightedItem>
          </span>
        </Item>
      ))}
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 47%;
  height: 100%;

  ${mediaQuery.smallScreen} {
    align-self: flex-end;
    width: 100%;
  }
`;

const Item = styled.span<{
  afterBackgroundColor: string;
  opacityMinus: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${(props) => (props.theme.darkMode ? `#fffa` : `#555`)};
  font-weight: 600;
  text-align: right;

  ${mediaQuery.smallScreen} {
    justify-content: center;
  }

  ${(props) =>
    props.opacityMinus &&
    css`
      opacity: 0.1;
    `};

  &::${({ theme }) => (theme.screen.small ? 'before' : 'after')} {
    content: '';
    width: 20px;
    height: 20px;
    background: ${(props) => props.afterBackgroundColor};
    ${({ theme }) =>
      theme.screen.small ? 'margin-right: 8px;' : 'margin-left: 8px;'}
  }

  .legend-text {
    width: 12rem;

    ${mediaQuery.smallScreen} {
      text-align: left;
    }
  }
`;

const HighlightedItem = styled.strong`
  font-weight: bold;
  margin-left: 6px;
`;
