import styled from 'styled-components';

export const Box = styled.div`
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  z-index: 400;

  list-style: none;
  font-size: 0.8rem;

  display: flex;
  gap: 1rem;

  button {
    padding: 0.1rem 0.5rem 0.1rem 0.2rem;
    display: flex;
    align-items: center;

    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 2px #0000001a;
    border: none;

    cursor: pointer;

    img {
      width: 20px;
    }

    span {
      margin-left: 5px;
      height: 20px;
      padding-top: 1px;
    }
  }
`;
