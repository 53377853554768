import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  .content {
    display: flex;
    gap: 1rem;
    svg {
      min-width: 21px;
      path {
        fill: ${(props) =>
          props.theme.darkMode ? colors.yellow500 : colors.ciano700} !important;
      }
    }
  }
  a {
    color: ${(props) =>
      props.theme.darkMode ? colors.yellow500 : '#4C5469'} !important;
    text-decoration: underline;
  }

  .CookieConsent {
    background: ${(props) =>
      props.theme.darkMode ? 'black' : 'white'} !important;
    span {
      color: ${(props) =>
        props.theme.darkMode ? 'white' : '#4C5469'} !important;
    }
  }

  .btn {
    background: ${(props) =>
      props.theme.darkMode ? colors.yellow500 : colors.ciano700} !important;
    color: ${(props) => (props.theme.darkMode ? 'black' : 'white')} !important;
  }
`;
