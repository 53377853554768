import * as R from 'ramda';
import {
  Axes,
  educationColors,
  educationEnumKeys,
  healthColors,
  healthIndicatorsKeys,
  healthLabels,
  healthOptionsKeys,
  insecurityFoodColors,
  insecurityFoodEnumKeys,
  JobIncomeColors,
  JobIncomeLabels,
  JobIncomeOptionsKeys,
  JobIncomingType,
  socialAssistanceColors,
  socialAssistanceLabels,
  socialAssistanceOptionsKeys
} from '../LastGoalsBoard/constants';
import { DataWithType } from '../LastGoalsBoard/types';

export interface RefinedData extends DataWithType {
  color: string;
}

export function refineInsecurityFoodData(data: DataWithType[]): RefinedData[] {
  return data.map((d) => {
    const colorKey = insecurityFoodEnumKeys[d.name];
    const color = insecurityFoodColors[colorKey];

    return { ...d, color };
  });
}

export function refineEducationData(messyData: DataWithType[]) {
  const expectedTypes = ['não', 'pública', 'particular'];
  const returnedTypes = messyData.map((d) => d.type as string);

  const missingData = expectedTypes
    .filter((data) => !returnedTypes.includes(data))
    .map((data, idx) => ({
      name: educationEnumKeys[data],
      value: 0,
      color: educationColors[data],
      type: data
    }));

  return messyData
    .map((data: DataWithType, idx: number) => ({
      ...data,
      name: educationEnumKeys[data.type as string],
      color: educationColors[data.type as string]
    }))
    .concat(missingData)
    .sort((a, b) => b.value - a.value);
}

export function refineSocialAssistanceData(data: DataWithType[]) {
  return data.map((d) => {
    const key = socialAssistanceOptionsKeys[d.name];
    const color = socialAssistanceColors[key];

    const name = `${socialAssistanceLabels[key]} ${
      key === 'DONTKNOW' ? '' : d.type
    }`;

    return { ...d, name, color };
  });
}

export function refineHealthData(data: DataWithType[]) {
  return data.map((d) => {
    const type = healthIndicatorsKeys[d.type ?? ''];

    const key = healthOptionsKeys[d.name];
    const color = healthColors[key];

    const name = `${healthLabels[type][key]}`;
    return { ...d, name, color };
  });
}

export function refineJobIncomingData(data: DataWithType[]) {
  return data.map((d) => {
    const key = JobIncomeOptionsKeys[d.name];
    const type = JobIncomingType[d.type ?? ''];
    const color = JobIncomeColors[key];

    const name = `${JobIncomeLabels[type][key]}`;
    return { ...d, name, color };
  });
}

export const refineData = R.curry(
  (dataType: string, data: DataWithType[][]) => {
    const sortByFirstItem = R.sortBy(R.prop('value'));
    const sortData = data.map((d) => sortByFirstItem(d).reverse());

    switch (dataType) {
      case Axes.InsecurityFood:
        return sortData.map((d) => refineInsecurityFoodData(d));
      case Axes.Education:
        return sortData.map((d) => refineEducationData(d));
      case Axes.SocialAssistance:
        return sortData.map((d) => refineSocialAssistanceData(d));
      case Axes.Health:
        return sortData.map((d) => refineHealthData(d));
      case Axes.JobIncoming:
        return sortData.map((d) => refineJobIncomingData(d));
      default:
        return [];
    }
  }
);

const concatCity = (city: string) => (R.isEmpty(city) ? '' : `em ${city}`);

export const capitalize = (value: string) => {
  const capitalizeUnit = R.replace(/^./, R.toUpper);
  return R.pipe(R.split(' '), R.map(capitalizeUnit), R.join(' '))(value);
};

export const getChartTitle = (
  axle: string | null,
  city: string,
  userCity?: string,
  indicator?: string
) => {
  if (R.isNil(axle)) return `Gráfico de Donut`;
  if (axle === 'Assistência Social')
    return `Cobertura de ${indicator !== '' ? indicator : 'CRAS e de CREAS'}`;
  if (axle === 'Trabalho e Renda')
    return `${axle} ${concatCity(userCity ? capitalize(userCity) : city)}`;

  return `${capitalize(axle)} ${concatCity(
    userCity ? capitalize(userCity) : city
  )}`;
};

export const getLegendTitle = (axle: string | null, type?: string) => {
  if (R.isNil(axle)) return `Legenda do Donut`;

  switch (axle) {
    case Axes.InsecurityFood:
      return `Total de famílias em ${capitalize(axle)}`;
    case Axes.Education:
      return `Total de famílias`;
    case Axes.SocialAssistance:
      return 'Total de famílias entrevistadas';
    case Axes.Health:
      return `${type}`;
    case Axes.JobIncoming:
      return 'Total de famílias entrevistadas';
    default:
      return '';
  }
};
