import { Card, D3Map } from '@/components/shared';
import { D3MapMacroregions } from '@/components/shared/D3MapMacroregions';
import { RadioGroup } from '@/components/styled';

import { useService } from '@/hooks';
import { leaderProfileService } from '@/services/womenMapping';
import { RadioChangeEvent } from 'antd';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { radioOptions } from '../../constants/radio';
import { useFilter } from '../../hooks/useFilter';

interface DataService extends Data {
  city: string;
  codIbge: string;
}

export function Card2() {
  const [mapType, setMapType] = useState(radioOptions[0].value);

  const { city, ibge, handleChangeCity } = useFilter();

  const { data, error, loading, request } = useService<DataService>();

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setMapType(value);
  };

  const mapData = useMemo(() => {
    if (mapType === 'MUNICIPIO') {
      return data.map((d) => ({
        name: d.city,
        value: d.value,
        codIbge: d.codIbge
      }));
    }

    const dataWithNoRepetitions = data.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.name]: (acc[cur.name] ?? 0) + cur.value
      };
    }, {} as Record<string, number>);

    return Object.entries(dataWithNoRepetitions).map(([key, value]) => ({
      name: key,
      value
    }));
  }, [data, mapType]);

  useEffect(() => {
    request(() => leaderProfileService.getCities(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Distribuição dos líderes das organizações por município"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <RadioGroup
        optionType="button"
        options={radioOptions}
        onChange={onChange}
        defaultValue={mapType}
      />
      {mapType === 'MACRORREGIAO' ? (
        <D3MapMacroregions data={mapData} color="#1D696F" />
      ) : (
        <D3Map
          data={mapData}
          color="#1D696F"
          mapDescription="Passe o mouse pelo mapa para visualizar a quantidade de sedes por município no Ceará"
          filteringCity={ibge}
          handleSelectedCity={handleChangeCity}
        />
      )}
    </Card>
  );
}
