import { ReactNode } from 'react';

interface ShowProps {
  when: boolean;
  children?: ReactNode | null;
  fallback?: ReactNode | null;
}

export function Show({ when, children = null, fallback = null }: ShowProps) {
  return <>{when === true ? children : fallback}</>;
}
