import styled from 'styled-components';

export const Box = styled.div`
  /* border: 1px solid #d8d8d8; */
  border-radius: 0.5rem;

  margin-top: 1rem;
  /* padding: 1.2rem; */

  h4 {
    color: #979797;
    font-size: 0.8rem;
  }

  p {
    color: #a3a3a3;
  }

  /* .gradient {
    margin-top: 1rem;

    &-label-list {
      display: flex;
      list-style: none;
      margin: 1rem 0 0;

      li {
        border-top: 0.3px solid #a3a3a3;
        color: #979797;

        display: flex;
        position: relative;

        span {
          width: 100%;
          text-align: right;

          &.zero {
            position: absolute;
            left: -92%;
          }
        }

        &::after {
          content: '';
          display: inline-block;

          width: 1px;
          height: 10px;

          background-color: #979797;
          transform: translate(0, -5px);
        }

        &:first-child::before {
          content: '';
          display: inline-block;

          width: 1px;
          height: 10px;

          background-color: #979797;
          transform: translate(0, -5px);
        }

        &:last-child {
        }
      }
    }
  } */
`;

interface GradientBarProps {
  gradient: string[];
}

export const GradientBar = styled.div<GradientBarProps>`
  background-image: linear-gradient(
    to right,
    ${({ gradient }) => gradient.join(', ')}
  );

  height: 20px;
  width: 100%;
`;

interface GradientLabelProps {
  quantity: number;
}

export const GradientLabel = styled.li<GradientLabelProps>`
  width: ${({ quantity }) => `calc(100% / ${quantity})`};
`;
