import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { Age } from './Age';
import { ByZone } from './ByZone';
import { DeficiencyType } from './DeficiencyType';
import { Documents } from './Documents';
import { EthnicGroup } from './EthnicGroup';
import { GenderIdentity } from './GenderIdentity';
import { LivingWithType } from './LivingWithType';
import { MaritalStatus } from './MaritalStatus';
import { Nacionality } from './Nacionality';
import { SexualOrientation } from './SexualOrientation';
import { StateMap } from './StateMap';
import { Box } from './style';

export function GeneralData() {
  return (
    <Box>
      <ThirdTitle>Características da população LGBTQIA+</ThirdTitle>
      <GroupBlockBox>
        <StateMap />
        <Age />
      </GroupBlockBox>
      <GroupBlockBox>
        <GenderIdentity />
        <SexualOrientation />
      </GroupBlockBox>
      <GroupBlockBox>
        <EthnicGroup />
        <DeficiencyType />
      </GroupBlockBox>
      <GroupBlockBox>
        <Documents />
        <ByZone />
      </GroupBlockBox>
      <GroupBlockBox>
        <Nacionality />
        <MaritalStatus />
      </GroupBlockBox>
      <GroupBlockBox>
        <LivingWithType />
      </GroupBlockBox>
    </Box>
  );
}
