import { Data, DataWithCity } from 'big-data';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Label, LabelMain, Text } from '@/components/styled';

import { getCityRecord } from './data';
import { createSVG, createMap, Path, onHover } from './map';
import { MapColors, legendByColor } from './colors';

import { Box, LegendText } from './styles';
import { useTheme } from 'styled-components';

interface D3MapProps {
  data: Data[];
  color: keyof typeof MapColors;
  percentage?: boolean;
  hasCreas?: boolean;
  filteringCity?: string;
  handleSelectedCity?: (city?: string) => void;
  maxValue?: number;
  width?: number;
  mapDescription?: string;
  rotule?: string;
  spaceBetweenMapAndLegend?: string;
}

const percentFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const DARKMODE_KEY_COLOR = `#FFCA15`;

export function D3Map({
  data,
  color,
  percentage,
  hasCreas,
  filteringCity,
  handleSelectedCity,
  maxValue,
  width = 300,
  rotule,
  mapDescription,
  spaceBetweenMapAndLegend
}: D3MapProps) {
  const [map, setMap] = useState<Path>();
  const [selectedCity, setSelectedCity] = useState<Data>();

  const boxRef = useRef<HTMLDivElement>(null);

  const cityRecord = useMemo(() => getCityRecord(data), [data]);
  const {
    screen: { small },
    darkMode
  } = useTheme();
  const trueColor = darkMode ? DARKMODE_KEY_COLOR : color;

  useEffect(() => {
    if (filteringCity) {
      const currentData = data.map((d: any) => {
        return { ...d, name: d.city ?? d.name };
      })[0] as DataWithCity;
      setSelectedCity(currentData);
    }
  }, [filteringCity, data]);

  useEffect(() => {
    const { current } = boxRef;

    if (!current) return;

    const svg = createSVG(current, width);
    setMap(createMap(svg, cityRecord, trueColor, maxValue));

    return () => {
      setMap(undefined);
      svg.remove();
    };
  }, [boxRef, cityRecord, trueColor, maxValue, width]);

  useEffect(() => {
    if (map) {
      onHover(
        map,
        filteringCity,
        small,
        (d) => setSelectedCity(d),
        handleSelectedCity
      );
    }
  }, [map, trueColor, filteringCity, handleSelectedCity, small]);

  const legendItems = useMemo(
    () => legendByColor(data, trueColor, percentage, maxValue),
    [data, trueColor, percentage, maxValue]
  );

  const isSelected = (min: number, max: number) => {
    if (selectedCity === undefined) return undefined;
    return selectedCity.value >= min && selectedCity.value <= max + 1;
  };

  const value = percentage
    ? percentFormatter.format((selectedCity?.value ?? 0) / 100)
    : selectedCity?.value;

  const pluralSuffix = value !== 1 ? `${rotule}s` : rotule;

  function getLegendValues(item: { min: string, max: string, color: string }) {
    if (item.min === '0') {
      return 'Nenhum'
    } else if (percentage) {
      return `${item.min} - ${item.max}%`
    } else {
      return `${item.min} - ${item.max}`
    }
  }

  return (
    <Box
      ref={boxRef}
      style={
        !!spaceBetweenMapAndLegend ? { gap: spaceBetweenMapAndLegend } : {}
      }
    >
      <div className="right-map-content">
        <LabelMain align={small ? 'left' : 'right'}>
          <Label
            filterCity={filteringCity ? !!filteringCity : undefined}
            selected={selectedCity ? !!selectedCity : undefined}
          >
            <span className="label">{selectedCity?.name}</span>
            <span className="value">
              {value} {rotule ? pluralSuffix : ''}
            </span>
          </Label>
          {hasCreas && (
            <Text className="tips">O município não possui CREAS</Text>
          )}
        </LabelMain>

        <Text className="tips">{!!!filteringCity && mapDescription}</Text>

        <div className="legend">
          <h3>LEGENDA</h3>
          <ul>
            {legendItems.map((item, i) => (
              <li key={item.color}>
                <LegendText
                  $stroke={i === 0}
                  color={item.color.toString()}
                  selected={isSelected(
                    Number(item.min.replace('.', '')),
                    Number(item.max.replace('.', ''))
                  )}
                >
                  {getLegendValues(item)}
                </LegendText>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}
