import { ensureAxiosParamOptions } from '@/utils/routes';
import { Data, DataWithTotal } from 'big-data';
import { DashboardRequestParams } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

interface HealthRequestParams extends DashboardRequestParams {
  aggregations: string[];
}

const cleanData = (data: Data[]) => {
  return data.filter((d) => d.name !== 'null');
};

const service = () => {
  const resource = `/saude-agentes`;

  async function request(path: string, params: HealthRequestParams) {
    const { aggregations, ibge, year, semester, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        ibge,
        year,
        semester,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data;
  }

  async function getCities({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = `${resource}`;
    const aggregations = [
      'IBGE',
      'CRIANCA_ACOMPANHADA_EQUIPE_SAUDE_FAMILIA',
      'MUNICIPIO'
    ];

    const data = await request(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return data.map((d) => ({
      name: d.ibge,
      value: d.quantidade,
      option: d[aggregations[1].toLowerCase()],
      city: d.municipio
    }));
  }

  async function getChildrenVaccinalActionRegistration({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = `${resource}`;
    const aggregations = [
      'IBGE',
      'CRIANCA_ACOMPANHADA_EQUIPE_SAUDE_FAMILIA',
      'MUNICIPIO'
    ];

    const data = await request(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return data.map((d) => ({
      name: d[aggregations[1].toLowerCase()],
      value: d.quantidade
    }));
  }

  async function getVaccinationBook(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const path = `${resource}`;
    const aggregations = ['CRIANCA_POSSUI_CADERNETA'];

    const data = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return cleanData(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getImpairmentChild(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const path = "criancas-deficientes";


    const response = await callService(() => mainApi.get<any[]>(path, ensureAxiosParamOptions({
      params: {
        ibge,
        year,
        semester,
        zone
      }
    })))

    const data = await response.data;

    return cleanData(
      data.map((d) => ({
        name: d.crianca_possui_deficiencia.toLowerCase(),
        value: d.quantidade,
        total: d.quantidade_registros
      }))
    ) as unknown as DataWithTotal[];
  }

  async function getChildHealth(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const path = `${resource}`;
    const aggregations = ['CRIANCA_ACOMPANHADA_EQUIPE_SAUDE_FAMILIA'];

    const data = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return cleanData(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade,
        type: 'Crianças acompanhadas por equipes de saúde da família'
      }))
    );
  }

  async function getVaccineUpToDate({
    ibge,
    year,
    semester,
    zone
  }: DashboardRequestParams) {
    const path = `${resource}`;
    const aggregations = ['CRIANCA_ESTA_VACINA_EM_DIA'];

    const data = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return cleanData(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade,
        type: 'Crianças com o esquema vacinal em dia'
      }))
    );
  }

  async function getBreastfeed(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const path = `${resource}`;
    const aggregations = ['CRIANCA_MAMOU_PRIMEIRAS_HORAS_DE_VIDA'];

    const data = await request(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return cleanData(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  return {
    getCities,
    getVaccinationBook,
    getImpairmentChild,
    getVaccineUpToDate,
    getBreastfeed,
    getChildHealth,
    getChildrenVaccinalActionRegistration
  };
};

export const healthAgents = service();
