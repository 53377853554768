export function AgricultorIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.46306 2.17475C9.34829 2.17475 10.0659 2.86363 10.0659 3.71345C10.0659 4.56328 9.34829 5.25215 8.46306 5.25215C7.57782 5.25215 6.86017 4.56328 6.86017 3.71345C6.86017 2.86363 7.57782 2.17475 8.46306 2.17475ZM2.58353 11.4992L2.56876 11.485C2.50228 11.4425 2.44322 11.407 2.3915 11.3503L2.56876 13.3003L0.338059 16.3352C0.0204806 16.7749 0.131262 17.3705 0.574387 17.6754C1.03236 17.9803 1.65282 17.8739 1.9704 17.4485L4.37837 14.1158C4.50392 13.9456 4.58517 13.7187 4.55564 13.4918L4.47439 12.5488L2.58353 11.4992ZM9.20907 17.8456H18.767L16.9795 12.492C16.9795 12.492 15.9675 12.7331 13.9659 14.7752C13.9216 14.8177 13.8699 14.8674 13.8256 14.9099L8.99493 12.2296C9.07618 12.0807 9.09095 11.9104 9.06142 11.7261L7.34767 5.62798C7.20736 5.03233 6.65337 4.5785 6.00337 4.5785H2.80509C2.56876 4.5785 2.33236 4.69903 2.19939 4.89763L0.603996 7.55668C0.397199 7.8758 0.522746 8.28703 0.855168 8.47138L2.64267 9.45703L2.40634 9.8399C2.11829 10.2937 2.28079 10.8752 2.76829 11.1304L6.77892 13.3499V16.867C6.77892 17.3988 7.22212 17.8243 7.77611 17.8243C8.33009 17.8243 8.77329 17.3988 8.77329 16.867V12.8536L13.2124 15.3212C12.2965 15.8247 11.2477 15.9169 10.5017 16.3423C9.58572 16.867 9.20907 17.8456 9.20907 17.8456ZM2.98236 8.89685L1.8079 8.2445L3.20392 5.93285H4.76986L2.98236 8.89685ZM5.56025 10.3292L6.66821 8.47138L7.49548 11.3928L5.56025 10.3292ZM18.2975 5.34928C19.5393 4.1648 19.8438 0.149902 19.8438 0.149902C19.8438 0.149902 18.9865 0.672952 17.6405 0.780577C16.3746 0.888277 15.1969 1.05748 14.5078 1.7189C13.4423 2.74183 13.1778 4.18783 13.7867 5.21848C14.628 3.84943 17.392 2.64185 17.392 2.64185C15.4292 3.98015 14.1392 5.25695 12.7932 7.10285L13.5383 7.18745C13.5383 7.18745 14.027 6.33373 14.4596 5.9261C15.5334 6.6491 17.1678 6.43378 18.2975 5.34928Z"
        fill="white"
      />
    </svg>
  );
}
