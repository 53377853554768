import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';
import { CardBase } from '../../../components/Card/styles';

export const Box = styled(CardBase)`
  ${mediaQuery.smallScreen} {
    .map-table {
      flex-direction: column;
    }
  }
`;

export const TableModalTitle = styled.h1``;

export const TableModalContent = styled.div`
  margin-top: 20px;
`;

export const TableModalPagination = styled.div`
  display: flex;
  justify-content: center;
`;
