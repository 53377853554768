import { Observation } from 'big-data';
import { Tooltip } from 'antd';
import { AiOutlinePaperClip } from 'react-icons/ai';

import { useAuth } from '@/hooks';

import { Box } from './styles';
import dayjs from 'dayjs';
import { observation } from '@/services';
import { CommentDetails } from '../CommentDetails';
import { CommentNote } from '../CommentNote';

interface CommentProps {
  comment: Observation;
  onClickToAnswer?: (comment: Observation) => void;
}

export function Comment({ comment, onClickToAnswer }: CommentProps) {
  const { user } = useAuth();

  const clientId = user?.client.userId ?? { userId: '' };
  const isClientComment = clientId === comment.author.cpf;

  const iconText = comment.author.name.charAt(0).toUpperCase();

  function handleDownloadFile() {
    const { id, actionId } = comment;

    observation.getObservationFile(id, actionId).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');

      const today = dayjs().format('DD-MM-YYYY_HH-mm-ss-SSS');

      link.setAttribute('href', url);
      link.setAttribute('download', `anexo-${today}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  function handleClickToAnswer() {
    if (onClickToAnswer) {
      onClickToAnswer(comment);
    }
  }

  const tooltipPlacement = isClientComment ? 'left' : 'right';

  return (
    <Box isClientComment={isClientComment} id={`${comment.id}`}>
      <div className="comment-content">
        <Tooltip title="Responder mensagem" placement={tooltipPlacement}>
          <svg
            className="answer-icon"
            viewBox="0 0 22 22"
            onClick={handleClickToAnswer}
          >
            <path
              d="M11,4c-4.4,0-8,2.9-8,6.5c0,1.6,0.7,3,1.8,4.1c-0.4,1.6-1.7,3-1.7,3C3,17.6,3,17.8,3,17.8c0,0.1,0.1,0.1,0.2,0.1
    c2.1,0,3.6-1,4.4-1.6c1,0.4,2.2,0.6,3.4,0.6c4.4,0,8-2.9,8-6.5S15.4,4,11,4z"
            />
          </svg>
        </Tooltip>
        <CommentNote comment={comment} isClientComment={isClientComment} />
        <div className="icon">{iconText}</div>
      </div>

      {comment.filePath && (
        <div className="file">
          <button onClick={handleDownloadFile}>
            <AiOutlinePaperClip />
            Anexo.pdf
          </button>
        </div>
      )}

      <CommentDetails
        comment={comment}
        side={isClientComment ? 'right' : 'left'}
      />
    </Box>
  );
}
