import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useFilter } from '../../hooks/useFilter';

export function Card7() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getEducationLevel(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Nível de escolaridade das mulheres atendidas"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <ChartBox>
        <HorizontalBarSimple
          data={data}
          grid
          isPercentage
          color={colors.ciano700}
          sizeRatio={0.7}
        />
      </ChartBox>
    </Card>
  );
}

const ChartBox = styled.div`
  padding: 0 1rem;
`;
