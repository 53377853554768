import { useTheme } from 'styled-components';
import { IconButton } from '../IconButton';

export const ButtonTheme = () => {
  const theme = useTheme();

  return (
    <IconButton
      mode="theme"
      onClick={theme.toggleTheme}
      aria-label="Trocar tema"
    />
  );
};
