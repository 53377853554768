import { cois } from '@/services';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

export function useDeedLineFilterData() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    cois
      .getDeedLines()
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return { actionTypeData: data, actionTypeLoading: loading, error };
}
