import { Section } from '@/layout';
import { Card, WordCloud } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { leaderMovements } from '@/services/leaderMovements';
import { useEffect } from 'react';

export function LeaderByEthnicGroup() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderMovements.ethnicGroups());
  }, [request]);

  return (
    <Section>
      <Card
        title="Liderança por grupos étnicos"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <WordCloud percentageSymbol words={data} scaleSize={[30, 100]} />
      </Card>
    </Section>
  );
}
