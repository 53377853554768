import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useStudyDegree() {
  const [studyDegrees, setStudyDegrees] = useState<Data[]>([]);
  const [loadingStudyDegrees, setLoadingStudyDegrees] = useState(false);
  const [errorStudyDegrees, setErrorStudyDegrees] = useState<Error | null>(
    null
  );

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingStudyDegrees(true);
    profileSearch
      .getStudyDegree(ibge, year, semester, zone)
      .then((data) => setStudyDegrees(data))
      .catch((err) => setErrorStudyDegrees(err))
      .finally(() => setLoadingStudyDegrees(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      studyDegrees,
      loadingStudyDegrees,
      errorStudyDegrees
    }),
    [studyDegrees, loadingStudyDegrees, errorStudyDegrees]
  );
}
