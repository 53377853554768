import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useSchoolData() {
  const [school, setSchool] = useState<Data[]>([]);
  const [schoolError, setSchoolError] = useState<Error | null>(null);
  const [schoolLoading, setSchoolLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setSchoolLoading(true);
    education
      .getSchool({
        hasFilter: false,
        variants: ['pública', 'particular'],
        ibge,
        year,
        semester,
        zone
      })
      .then((response) => {
        const total = response.reduce((acc, cur) => acc + cur.value, 0);

        const sumSchoolMode = response.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.schoolMode.toLowerCase()]:
              acc[cur.schoolMode.toLowerCase()] ?? 0 + cur.value
          };
        }, {} as Record<string, number>);

        setSchool(
          Object.entries(sumSchoolMode)
            .map(([key, value]) => ({
              name: 'estão matriculados na escola ' + key,
              value: value / total
            }))
            .sort((a, b) => b.value - a.value)
        );
      })
      .catch((error) => setSchoolError(error))
      .finally(() => setSchoolLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      school,
      schoolError,
      schoolLoading
    }),
    [school, schoolError, schoolLoading]
  );
}
