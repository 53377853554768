import { Tooltip } from 'antd';
import { BiLock } from 'react-icons/bi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Box } from './styles';

interface PageCardProps {
  title: string;
  icon: () => JSX.Element;
  description?: string;
  link: string;
  linkType: string;
  isPrincipal?: boolean;
}

export function PageCard({
  title,
  icon,
  description,
  link,
  linkType,
  isPrincipal
}: PageCardProps) {
  const Icon = icon;
  return (
    <Box>
      <div className="icon-card">
        <Icon />
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
      {linkType === 'external' ? (
        <Tooltip
          title="Acesso restrito a gestores municipais
        e estaduais."
          placement="bottomLeft"
          zIndex={isPrincipal ? 999 : -9999999}
        >
          <a href={link} target="_blank" rel="noreferrer">
            {isPrincipal && <BiLock />}
            <span> Acessar</span>
            <MdKeyboardArrowRight size={22} />
          </a>
        </Tooltip>
      ) : (
        <Tooltip
          title="Acesso restrito a gestores municipais
      e estaduais."
          placement="bottomLeft"
          zIndex={isPrincipal ? 999 : -9999999}
        >
          <Link to={link}>
            {isPrincipal && <BiLock />}
            <span> Acessar</span>
            <MdKeyboardArrowRight size={22} />
          </Link>
        </Tooltip>
      )}
    </Box>
  );
}
