import { colors } from '@/theme/colors';
import { useCityTaxes } from '@/servicesHooks/googleAnalytics';
import { ServiceBox } from '@/components/shared';

import { NormalCard, CardBase } from '../../components';
import { TimeIcon } from '../../components/icons/Time';
import { useTabContext, useFilterContext } from '../../contexts/';
import { generateTag, generateTime } from '../../utils';

export function CardPermanencyTime() {
  const { tab } = useTabContext();
  const { city } = useFilterContext();
  const { data, error, loading } = useCityTaxes(tab?.value, city?.ibge);
  const { hours, minutes, seconds } = generateTime(data, 'permanencyTime');

  return (
    <CardBase>
      <ServiceBox empty={data.length === 0} loading={loading} error={error}>
        <NormalCard
          title="Tempo médio de permanência"
          subtitle={generateTag([city?.name, tab?.name])}
          number={{
            color: colors.ciano700,
            value: `${hours}:${minutes}:${seconds}`,
            icon: TimeIcon
          }}
          description={
            'Duração média de uma sessão. Por padrão, uma sessão é encerrada após 30 minutos de inatividade do usuário.  '
          }
        />
      </ServiceBox>
    </CardBase>
  );
}
