export const CITIES = [
  {
    ibge: '2300101',
    municipio: 'Abaiara'
  },
  {
    ibge: '2300150',
    municipio: 'Acarape'
  },
  {
    ibge: '2300200',
    municipio: 'Acaraú'
  },
  {
    ibge: '2300309',
    municipio: 'Acopiara'
  },
  {
    ibge: '2300408',
    municipio: 'Aiuaba'
  },
  {
    ibge: '2300507',
    municipio: 'Alcântaras'
  },
  {
    ibge: '2300606',
    municipio: 'Altaneira'
  },
  {
    ibge: '2300705',
    municipio: 'Alto Santo'
  },
  {
    ibge: '2300754',
    municipio: 'Amontada'
  },
  {
    ibge: '2300804',
    municipio: 'Antonina do Norte'
  },
  {
    ibge: '2300903',
    municipio: 'Apuiarés'
  },
  {
    ibge: '2301000',
    municipio: 'Aquiraz'
  },
  {
    ibge: '2301109',
    municipio: 'Aracati'
  },
  {
    ibge: '2301208',
    municipio: 'Aracoiaba'
  },
  {
    ibge: '2301257',
    municipio: 'Ararendá'
  },
  {
    ibge: '2301307',
    municipio: 'Araripe'
  },
  {
    ibge: '2301406',
    municipio: 'Aratuba'
  },
  {
    ibge: '2301505',
    municipio: 'Arneiroz'
  },
  {
    ibge: '2301604',
    municipio: 'Assaré'
  },
  {
    ibge: '2301703',
    municipio: 'Aurora'
  },
  {
    ibge: '2301802',
    municipio: 'Baixio'
  },
  {
    ibge: '2301851',
    municipio: 'Banabuiú'
  },
  {
    ibge: '2301901',
    municipio: 'Barbalha'
  },
  {
    ibge: '2301950',
    municipio: 'Barreira'
  },
  {
    ibge: '2302008',
    municipio: 'Barro'
  },
  {
    ibge: '2302057',
    municipio: 'Barroquinha'
  },
  {
    ibge: '2302107',
    municipio: 'Baturité'
  },
  {
    ibge: '2302206',
    municipio: 'Beberibe'
  },
  {
    ibge: '2302305',
    municipio: 'Bela Cruz'
  },
  {
    ibge: '2302404',
    municipio: 'Boa Viagem'
  },
  {
    ibge: '2302503',
    municipio: 'Brejo Santo'
  },
  {
    ibge: '2302602',
    municipio: 'Camocim'
  },
  {
    ibge: '2302701',
    municipio: 'Campos Sales'
  },
  {
    ibge: '2302800',
    municipio: 'Canindé'
  },
  {
    ibge: '2302909',
    municipio: 'Capistrano'
  },
  {
    ibge: '2303006',
    municipio: 'Caridade'
  },
  {
    ibge: '2303105',
    municipio: 'Cariré'
  },
  {
    ibge: '2303204',
    municipio: 'Caririaçu'
  },
  {
    ibge: '2303303',
    municipio: 'Cariús'
  },
  {
    ibge: '2303402',
    municipio: 'Carnaubal'
  },
  {
    ibge: '2303501',
    municipio: 'Cascavel'
  },
  {
    ibge: '2303600',
    municipio: 'Catarina'
  },
  {
    ibge: '2303659',
    municipio: 'Catunda'
  },
  {
    ibge: '2303709',
    municipio: 'Caucaia'
  },
  {
    ibge: '2303808',
    municipio: 'Cedro'
  },
  {
    ibge: '2303907',
    municipio: 'Chaval'
  },
  {
    ibge: '2303931',
    municipio: 'Choró'
  },
  {
    ibge: '2303956',
    municipio: 'Chorozinho'
  },
  {
    ibge: '2304004',
    municipio: 'Coreaú'
  },
  {
    ibge: '2304103',
    municipio: 'Crateús'
  },
  {
    ibge: '2304202',
    municipio: 'Crato'
  },
  {
    ibge: '2304236',
    municipio: 'Croatá'
  },
  {
    ibge: '2304251',
    municipio: 'Cruz'
  },
  {
    ibge: '2304269',
    municipio: 'Deputado Irapuan Pinheiro'
  },
  {
    ibge: '2304277',
    municipio: 'Ereré'
  },
  {
    ibge: '2304285',
    municipio: 'Eusébio'
  },
  {
    ibge: '2304301',
    municipio: 'Farias Brito'
  },
  {
    ibge: '2304350',
    municipio: 'Forquilha'
  },
  {
    ibge: '2304400',
    municipio: 'Fortaleza'
  },
  {
    ibge: '2304459',
    municipio: 'Fortim'
  },
  {
    ibge: '2304509',
    municipio: 'Frecheirinha'
  },
  {
    ibge: '2304608',
    municipio: 'General Sampaio'
  },
  {
    ibge: '2304657',
    municipio: 'Graça'
  },
  {
    ibge: '2304707',
    municipio: 'Granja'
  },
  {
    ibge: '2304806',
    municipio: 'Granjeiro'
  },
  {
    ibge: '2304905',
    municipio: 'Groaíras'
  },
  {
    ibge: '2304954',
    municipio: 'Guaiúba'
  },
  {
    ibge: '2305001',
    municipio: 'Guaraciaba do Norte'
  },
  {
    ibge: '2305100',
    municipio: 'Guaramiranga'
  },
  {
    ibge: '2305209',
    municipio: 'Hidrolândia'
  },
  {
    ibge: '2305233',
    municipio: 'Horizonte'
  },
  {
    ibge: '2305266',
    municipio: 'Ibaretama'
  },
  {
    ibge: '2305308',
    municipio: 'Ibiapina'
  },
  {
    ibge: '2305332',
    municipio: 'Ibicuitinga'
  },
  {
    ibge: '2305357',
    municipio: 'Icapuí'
  },
  {
    ibge: '2305407',
    municipio: 'Icó'
  },
  {
    ibge: '2305506',
    municipio: 'Iguatu'
  },
  {
    ibge: '2305605',
    municipio: 'Independência'
  },
  {
    ibge: '2305654',
    municipio: 'Ipaporanga'
  },
  {
    ibge: '2305704',
    municipio: 'Ipaumirim'
  },
  {
    ibge: '2305803',
    municipio: 'Ipu'
  },
  {
    ibge: '2305902',
    municipio: 'Ipueiras'
  },
  {
    ibge: '2306009',
    municipio: 'Iracema'
  },
  {
    ibge: '2306108',
    municipio: 'Irauçuba'
  },
  {
    ibge: '2306207',
    municipio: 'Itaiçaba'
  },
  {
    ibge: '2306256',
    municipio: 'Itaitinga'
  },
  {
    ibge: '2306306',
    municipio: 'Itapajé'
  },
  {
    ibge: '2306405',
    municipio: 'Itapipoca'
  },
  {
    ibge: '2306504',
    municipio: 'Itapiúna'
  },
  {
    ibge: '2306553',
    municipio: 'Itarema'
  },
  {
    ibge: '2306603',
    municipio: 'Itatira'
  },
  {
    ibge: '2306702',
    municipio: 'Jaguaretama'
  },
  {
    ibge: '2306801',
    municipio: 'Jaguaribara'
  },
  {
    ibge: '2306900',
    municipio: 'Jaguaribe'
  },
  {
    ibge: '2307007',
    municipio: 'Jaguaruana'
  },
  {
    ibge: '2307106',
    municipio: 'Jardim'
  },
  {
    ibge: '2307205',
    municipio: 'Jati'
  },
  {
    ibge: '2307254',
    municipio: 'Jijoca de Jericoacoara'
  },
  {
    ibge: '2307304',
    municipio: 'Juazeiro do Norte'
  },
  {
    ibge: '2307403',
    municipio: 'Jucás'
  },
  {
    ibge: '2307502',
    municipio: 'Lavras da Mangabeira'
  },
  {
    ibge: '2307601',
    municipio: 'Limoeiro do Norte'
  },
  {
    ibge: '2307635',
    municipio: 'Madalena'
  },
  {
    ibge: '2307650',
    municipio: 'Maracanaú'
  },
  {
    ibge: '2307700',
    municipio: 'Maranguape'
  },
  {
    ibge: '2307809',
    municipio: 'Marco'
  },
  {
    ibge: '2307908',
    municipio: 'Martinópole'
  },
  {
    ibge: '2308005',
    municipio: 'Massapê'
  },
  {
    ibge: '2308104',
    municipio: 'Mauriti'
  },
  {
    ibge: '2308203',
    municipio: 'Meruoca'
  },
  {
    ibge: '2308302',
    municipio: 'Milagres'
  },
  {
    ibge: '2308351',
    municipio: 'Milhã'
  },
  {
    ibge: '2308377',
    municipio: 'Miraíma'
  },
  {
    ibge: '2308401',
    municipio: 'Missão Velha'
  },
  {
    ibge: '2308500',
    municipio: 'Mombaça'
  },
  {
    ibge: '2308609',
    municipio: 'Monsenhor Tabosa'
  },
  {
    ibge: '2308708',
    municipio: 'Morada Nova'
  },
  {
    ibge: '2308807',
    municipio: 'Moraújo'
  },
  {
    ibge: '2308906',
    municipio: 'Morrinhos'
  },
  {
    ibge: '2309003',
    municipio: 'Mucambo'
  },
  {
    ibge: '2309102',
    municipio: 'Mulungu'
  },
  {
    ibge: '2309201',
    municipio: 'Nova Olinda'
  },
  {
    ibge: '2309300',
    municipio: 'Nova Russas'
  },
  {
    ibge: '2309409',
    municipio: 'Novo Oriente'
  },
  {
    ibge: '2309458',
    municipio: 'Ocara'
  },
  {
    ibge: '2309508',
    municipio: 'Orós'
  },
  {
    ibge: '2309607',
    municipio: 'Pacajus'
  },
  {
    ibge: '2309706',
    municipio: 'Pacatuba'
  },
  {
    ibge: '2309805',
    municipio: 'Pacoti'
  },
  {
    ibge: '2309904',
    municipio: 'Pacujá'
  },
  {
    ibge: '2310001',
    municipio: 'Palhano'
  },
  {
    ibge: '2310100',
    municipio: 'Palmácia'
  },
  {
    ibge: '2310209',
    municipio: 'Paracuru'
  },
  {
    ibge: '2310258',
    municipio: 'Paraipaba'
  },
  {
    ibge: '2310308',
    municipio: 'Parambu'
  },
  {
    ibge: '2310407',
    municipio: 'Paramoti'
  },
  {
    ibge: '2310506',
    municipio: 'Pedra Branca'
  },
  {
    ibge: '2310605',
    municipio: 'Penaforte'
  },
  {
    ibge: '2310704',
    municipio: 'Pentecoste'
  },
  {
    ibge: '2310803',
    municipio: 'Pereiro'
  },
  {
    ibge: '2310852',
    municipio: 'Pindoretama'
  },
  {
    ibge: '2310902',
    municipio: 'Piquet Carneiro'
  },
  {
    ibge: '2310951',
    municipio: 'Pires Ferreira'
  },
  {
    ibge: '2311009',
    municipio: 'Poranga'
  },
  {
    ibge: '2311108',
    municipio: 'Porteiras'
  },
  {
    ibge: '2311207',
    municipio: 'Potengi'
  },
  {
    ibge: '2311231',
    municipio: 'Potiretama'
  },
  {
    ibge: '2311264',
    municipio: 'Quiterianópolis'
  },
  {
    ibge: '2311306',
    municipio: 'Quixadá'
  },
  {
    ibge: '2311355',
    municipio: 'Quixelô'
  },
  {
    ibge: '2311405',
    municipio: 'Quixeramobim'
  },
  {
    ibge: '2311504',
    municipio: 'Quixeré'
  },
  {
    ibge: '2311603',
    municipio: 'Redenção'
  },
  {
    ibge: '2311702',
    municipio: 'Reriutaba'
  },
  {
    ibge: '2311801',
    municipio: 'Russas'
  },
  {
    ibge: '2311900',
    municipio: 'Saboeiro'
  },
  {
    ibge: '2311959',
    municipio: 'Salitre'
  },
  {
    ibge: '2312007',
    municipio: 'Santana do Acaraú'
  },
  {
    ibge: '2312106',
    municipio: 'Santana do Cariri'
  },
  {
    ibge: '2312205',
    municipio: 'Santa Quitéria'
  },
  {
    ibge: '2312304',
    municipio: 'São Benedito'
  },
  {
    ibge: '2312403',
    municipio: 'São Gonçalo do Amarante'
  },
  {
    ibge: '2312502',
    municipio: 'São João do Jaguaribe'
  },
  {
    ibge: '2312601',
    municipio: 'São Luís do Curu'
  },
  {
    ibge: '2312700',
    municipio: 'Senador Pompeu'
  },
  {
    ibge: '2312809',
    municipio: 'Senador Sá'
  },
  {
    ibge: '2312908',
    municipio: 'Sobral'
  },
  {
    ibge: '2313005',
    municipio: 'Solonópole'
  },
  {
    ibge: '2313104',
    municipio: 'Tabuleiro do Norte'
  },
  {
    ibge: '2313203',
    municipio: 'Tamboril'
  },
  {
    ibge: '2313252',
    municipio: 'Tarrafas'
  },
  {
    ibge: '2313302',
    municipio: 'Tauá'
  },
  {
    ibge: '2313351',
    municipio: 'Tejuçuoca'
  },
  {
    ibge: '2313401',
    municipio: 'Tianguá'
  },
  {
    ibge: '2313500',
    municipio: 'Trairi'
  },
  {
    ibge: '2313559',
    municipio: 'Tururu'
  },
  {
    ibge: '2313609',
    municipio: 'Ubajara'
  },
  {
    ibge: '2313708',
    municipio: 'Umari'
  },
  {
    ibge: '2313757',
    municipio: 'Umirim'
  },
  {
    ibge: '2313807',
    municipio: 'Uruburetama'
  },
  {
    ibge: '2313906',
    municipio: 'Uruoca'
  },
  {
    ibge: '2313955',
    municipio: 'Varjota'
  },
  {
    ibge: '2314003',
    municipio: 'Várzea Alegre'
  },
  {
    ibge: '2314102',
    municipio: 'Viçosa do Ceará'
  }
];
