import { bdsManager } from '@/services/manager';
import { Manager } from 'big-data';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

interface ManagerContextProviderProps {
  children: ReactNode;
  cpfManager: string;
}

interface ManagerContextData {
  manager?: Manager;
}

const ManagerContext = createContext({} as ManagerContextData);

export function ManagerContextProvider({
  children,
  cpfManager
}: ManagerContextProviderProps) {
  const navigate = useNavigate();

  const [manager, setManager] = useState<Manager>();

  function goBack() {
    navigate('/dashboard/dados-de-acesso');
  }

  useEffect(() => {
    if (cpfManager) {
      bdsManager
        .get(cpfManager)
        .then((data) => setManager(data))
        .catch(() => goBack());
    } else {
      goBack();
    }
  }, [cpfManager]); // eslint-disable-line

  const contextProviderValue = useMemo(() => ({ manager }), [manager]);

  return (
    <ManagerContext.Provider value={contextProviderValue}>
      {children}
    </ManagerContext.Provider>
  );
}

export function useManager() {
  return useContext(ManagerContext);
}
