import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { googleAnalyticsResource } from './utils/resource';

export const service = () => {
  const resource = googleAnalyticsResource + '/tempo-paginas';

  async function get(
    minimumDate?: string,
    ibge?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        fromCeara: true,
        order,
        pageNumber,
        pageSize
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((data) => ({
      name: data.nome_pagina,
      value: data.sessoes
    }));
  }

  return { get };
};

export const pageSessionTime = service();
