import styled from 'styled-components';
import { useRef } from 'react';
import { useObserverVisibility } from '@/hooks';
import { colors } from '@/theme/colors';

interface Props {
  text: string;
  align?: `left` | `center` | `right`;
}

export const AnimatedTitle = ({ text, align = `left` }: Props) => {
  const refDom = useRef<any>();
  const visible = useObserverVisibility(refDom);

  return (
    <Wrapper ref={refDom} align={align}>
      <TextContainer>
        <Effects>
          <Circle visible={visible} />
        </Effects>
        <Text>{text}</Text>
      </TextContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ align: string }>`
  text-align: ${(props) => props.align};
`;

const TextContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 32px;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 70px;
  margin: 0.5rem 0;
  color: ${colors.green400};
`;

const Text = styled.div`
  z-index: 100;
  position: relative;
  text-align: left;
  color: ${(props) => (props.theme.darkMode ? `#fff` : `#2D4040`)};
  margin-left: 20px;
`;

const Effects = styled.div`
  position: absolute;

  z-index: 1;
  top: 0;
  left: 0;
  font-size: 8px;
  text-align: left;
`;

const Circle = styled.div<{ visible: boolean }>`
  display: inline-block;
  margin-right: 8px;
  width: 70px;
  height: 70px;
  background: ${colors.yellow200};
  border-radius: 50%;
  transition: 1.2s;
  transition-delay: 0.2s;
  transform: ${(props) =>
    props.visible ? `translate(0,0)` : `translate(30px,0)`};
`;
