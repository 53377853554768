import { useMemo } from 'react';

import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { GridColumns, GridRows } from '@visx/grid';
import { Data } from 'big-data';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { format } from 'd3';

import { Box } from './styles';
import { ParentSize } from '@visx/responsive';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { percentageFormatter } from '@/utils/string';
import { Annotation, Label } from "@visx/annotation";
import { useTheme } from 'styled-components';

const verticalMargin = 120;

// accessors

const getName = (d: Data) => d.name;
const getValue = (d: Data) => d.value;

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#FFF',
  color: '#000'
};

export type BarsProps = {
  width: number;
  height?: number;
  events?: boolean;
  barColor?: string;
  data: Data[];
  makeValuesBetweenZeroAndOne?: boolean;
  alwaysShowValue?: boolean;
};

export function BarChart({
  data,
  width,
  height = 500,
  events = false,
  barColor = '#1D696F',
  makeValuesBetweenZeroAndOne = false,
  alwaysShowValue = false
}: BarsProps) {

  const { darkMode, ...theme } = useTheme();

  // bounds
  const xMax = width;
  const yMax = height - verticalMargin;

  const parsedData = useMemo(() => {
    if (makeValuesBetweenZeroAndOne)
      return data.map((d) => ({ ...d, value: d.value / 100 }));
    return data;
  }, [data, makeValuesBetweenZeroAndOne]);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip<Data>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true
  });

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map(getName),
        padding: 0.4
      }),
    [xMax, data]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, 1]
      }),
    [yMax]
  );

  return width < 10 ? null : (
    <Box>
      <svg ref={containerRef} width={width} height={height}>
        <rect width={width} height={height} fill="url(#teal)" rx={14} />
        <Group top={verticalMargin / 2}>
          <GridRows
            left={0}
            scale={yScale}
            width={width}
            strokeDasharray="1,3"
            stroke={'#000'}
            strokeOpacity={1}
            pointerEvents="none"
          />

          <GridColumns
            top={0}
            scale={xScale}
            height={height}
            strokeDasharray="1,3"
            stroke={'transparent'}
            strokeOpacity={1}
            pointerEvents="none"
            // numTicks={5}
          />
          <AxisLeft
            scale={yScale}
            tickFormat={format('~%')}
            left={0}
            top={0}
            stroke={'transparent'}
            tickStroke={'transparent'}
            tickLength={2}
            tickClassName="axis-left-bar-label"
            // numTicks={4}
          />

          <AxisBottom
            scale={xScale}
            top={380}
            numTicks={5}
            stroke={'#ABAEAF'}
            tickStroke={'#ABAEAF'}
            tickLength={0}
            tickFormat={(label) => {
              return label;
            }}
            tickClassName="axis-bottom-bar-label"
          />

          {parsedData.map((d) => {
            const name = getName(d);
            const porcentage = percentageFormatter(getValue(d), true);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getValue(d)) ?? 0);
            const barX = xScale(name);
            const barY = yMax - barHeight;

            return (
              <>
                <Bar
                  key={`bar-${name}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={barColor}
                  onClick={() => {
                    if (events)
                      alert(`clicked: ${JSON.stringify(Object.values(d))}`);
                  }}
                  onMouseLeave={hideTooltip}
                  onMouseMove={(event) => {
                    const eventSvgCoords = localPoint(event);
                    const left = barX ?? 0 + barWidth / 2;
                    showTooltip({
                      tooltipData: d,
                      tooltipTop: eventSvgCoords?.y,
                      tooltipLeft: left
                    });
                  }}
                />
                {
                  alwaysShowValue && (
                    <Annotation x={(barX ?? 0 ) + barWidth / 2} y={barY} dx={0} dy={0}>
                      <Label
                        showBackground={true}
                        backgroundFill={darkMode ? theme.background : '#fff'}
                        fontColor={theme.text}
                        showAnchorLine={false}
                        backgroundPadding={2}
                        title={porcentage}
                        titleFontSize={'12px'}
                        titleFontWeight={'bold'}
                        horizontalAnchor={"middle"}
                      />
                    </Annotation>
                  )
                }
              </>
            );
          })}
        </Group>
      </svg>

      {!alwaysShowValue && tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>
            <span>{percentageFormatter(tooltipData.value, true)}</span>
          </div>
        </TooltipInPortal>
      )}
    </Box>
  );
}

export function BarSimple(props: Omit<BarsProps, 'width'>) {
  return (
    <ParentSize>
      {({ width }) => <BarChart {...props} width={width - 50} height={500} />}
    </ParentSize>
  );
}
