import { ApresentationCard, LastUpdateMessage } from '@/components/shared';

import { useAnalyticsTitle } from '@/hooks';
import { TotalInterviews } from './components/TotalInterviews';
import { FilterProvider } from './contexts/FilterContext';
import { Insights } from './Insights';
import { Box } from './styles';

const apresentationCardText =
  'Essa é uma pesquisa Estadual do Mapeamento da Rede de Apoio a Mulheres no Ceará, idealizada a partir das diferentes demandas do público-alvo e pensada para melhor conhecer nossa população e planejar, realizar políticas e ações públicas mais eficientes, voltadas para o enfrentamento da violência, a promoção da igualdade de gênero, o empoderamento da mulher e sua autonomia econômica. ';

export function WomenMappingAnalytics() {
  useAnalyticsTitle('Mapeamento da Rede de Apoio a Mulheres no Ceará');

  return (
    <Box>
      <FilterProvider>
        <LastUpdateMessage
          message="Ano de referência: "
          year="2023"
          loading={false}
          error={null}
        />

        <ApresentationCard
          title="Apresentação"
          source="Secretaria das mulheres"
          text={apresentationCardText}
        >
          <TotalInterviews />
        </ApresentationCard>
        
        <Insights />
      </FilterProvider>
    </Box>
  );
}
