import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const requests = [
  education.getNurseryParentInterests,
  education.getPreschoolParentInterests,
  education.getSchoolParentInterests
];

const requestMap = {
  0: 'CRECHE',
  1: 'PRÉ-ESCOLA',
  2: 'ESCOLA'
}

export function useParentInterestsData() {
  const [parentInterests, setParentInterests] = useState<Data[]>([]);
  const [parentInterestError, setParentInterestsError] = useState<Error | null>(
    null
  );
  const [parentInterestLoading, setParentInterestLoading] = useState(false);
  const [parentInterestRejecteds, setParentInterestRejecteds] = useState<string[]>([])

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setParentInterestLoading(true);
    Promise.allSettled(
      requests.map((request) => request({ ibge, year, semester, zone }))
    )
      .then((data) => data.flat())
      .then((data) => {
        const rejecteds = data.map((d, i) => {
          if (d.status === "rejected") return requestMap[i as 0 | 1 | 2]
          return null
        }).filter(d => d) as string[]

        setParentInterestRejecteds(rejecteds)

        const dataFulfilleds = data.map(d => {
          if (d.status === 'fulfilled') return d.value;
          return null;
        }).filter(d => d) as unknown as any

        const dataFlat = dataFulfilleds.flat()

        const sortedData = [...dataFlat].sort((a, b) => b.value - a.value);
        setParentInterests(sortedData);
      })
      .catch((error) => setParentInterestsError(error))
      .finally(() => setParentInterestLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      parentInterests,
      parentInterestLoading,
      parentInterestError,
      parentInterestRejecteds
    }),
    [parentInterests, parentInterestLoading, parentInterestError, parentInterestRejecteds]
  );
}
