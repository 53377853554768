import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { googleAnalyticsResource } from './utils/resource';

export const service = () => {
  const resource = googleAnalyticsResource + '/media-pagina';

  async function get(minimumDate?: string, ibge?: string) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        fromCeara: true
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((data) => ({
      meanTimePerPage: data.duracao_media
    }));
  }

  return { get };
};

export const meanTimePage = service();
