import { GeolocationBack, Geolocation } from './@types';

export function getGeolocationFront(
  locations: GeolocationBack[]
): Geolocation[] {
  return locations
    .map((location) => ({
      latitude: parseFloat(location.latitude),
      longitude: parseFloat(location.longitude),
      heat_level: location.heat_level
    }))
    .filter(
      (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
    );
}

export function getCleanedGeolocation(locations: Geolocation[]) {
  return locations.filter(
    (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
  );
}
