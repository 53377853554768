import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  async function healthCareType(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ACESSO_SISTEMA_SAUDE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.acesso_sistema_saude,
        value: d.porcentagem
      };
    });
  }

  async function attendance(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['FAZ_TRATAMENTO_MEDICO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.faz_tratamento_medico,
          value: d.quantidade
        };
      })
      .filter((d) => d.name !== 'Invalida');
  }

  async function healRespect(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['RESPEITO_IDENTIDADE_ORIENTACAO_REDE_SAUDE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.respeito_identidade_orientacao_rede_saude,
        value: d.porcentagem
      };
    });
  }

  async function healthLgbtPhobia(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['VITIMA_LGBTFOBIA_REDE_SAUDE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.vitima_lgbtfobia_rede_saude,
        value: d.porcentagem
      };
    });
  }

  async function healthServices(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['SERVICO_PROMOCAO_SAUDE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.servico_promocao_saude,
        value: d.porcentagem
      };
    });
  }

  async function professionalPreparation(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['PREPARO_PROFISSIONAIS_SAUDE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.preparo_profissionais_saude,
        value: d.porcentagem
      };
    });
  }

  async function preventionTypes(params: LgbtParams) {
    const path = `${resource}/metodos-prevencao`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.metodos_prevencao,
        value: d.porcentagem
      };
    });
  }

  async function mainAttendance(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['QUAL_ACOMPANHAMENTO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.qual_acompanhamento,
          value: d.porcentagem
        };
      })
      .filter((d) => d.name);
  }

  return {
    healthCareType,
    attendance,
    healRespect,
    healthLgbtPhobia,
    healthServices,
    professionalPreparation,
    preventionTypes,
    mainAttendance
  };
};

export const useHealth = service();
