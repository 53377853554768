import { Notification } from 'big-data';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  sort,
  pipe,
  replace,
  map,
  assoc,
  toLower,
  equals,
  isEmpty
} from 'ramda';

import { BoxList } from './styles';
import { NotificationItem } from '../NotificationItem';
import { actionNotification } from '@/services';
import { debounce } from '@/utils/function';

interface NotificationsProps {
  notifications: Notification[];
  markAsRead: (ids: number[]) => void;
  updateNotifications: (page?: number, size?: number) => void;
  loading?: boolean;
}

const byDate = sort((a: Notification, b: Notification) => {
  return b.createdDate.getTime() - a.createdDate.getTime();
});

const putAuthor = (notification: Notification) => {
  const author = replace('$GESTOR', notification.author);
  const updateMessage = assoc('message', author(notification.message));

  return updateMessage(notification) as Notification;
};

const putActionTitle = (notification: Notification) => {
  const action = replace('$PLANO_ACAO', `"${toLower(notification.title)}"`);
  const updateMessage = assoc('message', action(notification.message));

  return updateMessage(notification) as Notification;
};

const getCompleteNotifications = pipe(
  byDate,
  map(putAuthor),
  map(putActionTitle)
);

const INITIAL_READ_SIZE = 2;

export function Notifications({
  notifications,
  loading,
  markAsRead,
  updateNotifications
}: NotificationsProps) {
  const listRef = useRef<HTMLUListElement>(null);
  const intersectionRef = useRef<HTMLLIElement>(null);

  const [readNotifications, setReadNotifications] = useState<number[]>([]);
  const [page, setPage] = useState(0);

  const observer = useMemo(() => {
    const config: IntersectionObserverInit = {
      root: listRef.current,
      rootMargin: '0px',
      threshold: 0.95
    };

    return new IntersectionObserver((entries) => {
      const intersected = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => Number(entry.target.getAttribute('data-id')));

      const size = intersected.length;

      if (size > INITIAL_READ_SIZE || equals(size, 0)) return;

      if (INITIAL_READ_SIZE === size) {
        setReadNotifications((current) =>
          Array.from(new Set([...current, ...intersected]))
        );
      }

      const [id] = intersected;

      setReadNotifications((current) =>
        current.includes(id) ? current : [...current, id]
      );
    }, config);
  }, []);

  const intersectionObserver = useMemo(() => {
    const config = {
      root: listRef.current,
      rootMargin: '0px',
      threshold: 0.95
    };

    return new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        setPage((current) => current + 1);
      }
    }, config);
  }, []);

  useEffect(() => {
    if (isEmpty(readNotifications)) return;

    const read = debounce(() => {
      actionNotification
        .readNotification(readNotifications)
        .then(() => markAsRead(readNotifications));
    }, 200);

    read();
  }, [readNotifications, markAsRead]);

  useEffect(() => {
    if (page === 0) return;

    updateNotifications(page);
  }, [page, updateNotifications]);

  useEffect(() => {
    const { current } = intersectionRef;
    if (!current) return;

    intersectionObserver.observe(current);

    return () => intersectionObserver.unobserve(current);
  }, [intersectionObserver]);

  useEffect(() => {
    const { current } = intersectionRef;
    if (!current) return;

    if (notifications.length < page * 10) {
      intersectionObserver.unobserve(current);
    }
  }, [page, notifications, intersectionObserver]);

  const sortedNotifications = useMemo(
    () => getCompleteNotifications(notifications),
    [notifications]
  );

  const getDomItem = map((notification: Notification) => (
    <NotificationItem
      key={notification.notificationId}
      item={notification}
      observer={observer}
    />
  ));

  return (
    <BoxList ref={listRef}>
      {
        sortedNotifications.length === 0 ? (
          <span className="empty">Sem notificações no momento</span>
        ) : (
          <>
            {getDomItem(sortedNotifications)}
            <li className="intersection" ref={intersectionRef}></li>
          </>
        )
      }
    </BoxList>
  );
}
