import { Theme } from 'big-data/theme';
import { colors } from './colors';

export const ThemeBase: Theme = {
  darkMode: false,

  font: {
    level: 0,
    limit: {}
  },

  screen: {
    small: false,
    medium: false,
    large: false
  },

  greenDark: colors.green700,
  greenLight: colors.green200,

  text: colors.black,
  textDark: `#3D5164`,
  background: colors.white,
  yellowDark: colors.yellow500,

  greenDefault: '#4e7345',
  redDefault: '#8b1b1b',
  yellowDefault: '#604c17'
};
