import { colors } from '@/theme/colors';
import { useEffect } from 'react';
import { Root } from '../Arcs';
import { useChart } from '../Provider';

interface LabelsProps {
  labelWithInitials?: boolean;
}

const TEXTS: any = [];
const LETTER_WIDTH = 1.7; // 1.7px for letter;

export function Labels({ labelWithInitials }: LabelsProps) {
  const { chart, arcs } = useChart();

  useEffect(() => {
    if (!arcs || !chart) return;

    const id = setTimeout(() => {
      const text = arcs
        .append('text')
        .attr('class', 'legend-label')
        .attr('dy', 30)
        .attr('preserveAspectRatio', 'xMinYMin meet');

      text
        .append('textPath')
        .attr('xlink:href', (_, i) => `#path-${i}`)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('fill', colors.white)
        .attr('startOffset', (d: Root) => {
          const before = d.before as number[];
          const diffX0andX1 = before[1] - before[0];
          return diffX0andX1 >= 0.25 ? '25%' : '20.5%';
        })
        .attr('font-family', 'IBM Plex Sans')
        .attr('font-weight', '900')
        .attr('font-size', '0.8rem')
        .attr('text-align', 'center')
        .attr('letter-spacing', '2px')
        .attr('line-height', '13px')
        .attr('cursor', 'pointer')
        .text((d) => {
          const name = labelWithInitials ? d.data.nameInitials : d.data.name;
          const before = d.before as number[];
          const diffX0andX1 = before[1] - before[0];
          const labelWidth =
            (name?.replaceAll(' ', '').length ?? 0 * LETTER_WIDTH) / 100;
          return diffX0andX1 > labelWidth ? name?.toUpperCase() ?? '' : '';
        });

      TEXTS.push(text);
      clearTimeout(id);
    }, 710);

    return () => {
      while (TEXTS.length > 0) {
        TEXTS.pop().remove();
      }
    };
  }, [arcs, chart, labelWithInitials]);

  return null;
}
