import { Data } from 'big-data';
import * as d3 from 'd3';

import ceara from './ceara-geo.json';
import { maxValue } from './utils';
import { ColorScale } from './colors';
import { getTooltipNameCity } from './tooltipNameCity';

type GeoJson = {
  properties: {
    center: [number, number];
    id: number;
    name: string;
    total: number;
    color: string;
  };
  type: string;
  geometry: {
    type: string;
    coordinates: number[][][];
  };
};
type SVG = d3.Selection<SVGSVGElement, unknown, null, undefined>;
export type Path = d3.Selection<SVGPathElement, GeoJson, SVGGElement, unknown>;

const RATIO = 1.2;

export function createSVG(container: HTMLDivElement, width: number): SVG {
  const height = width * RATIO;

  return d3
    .select(container)
    .append('svg')
    .attr('class', 'map')
    .attr('width', width)
    .attr('height', height)
    .attr(`preserveAspectRatio`, `xMidYMid meet`)
    .attr(`viewBox`, `0 0 ${width} ${height}`);
}

export function createMap(
  svg: SVG,
  data: Record<string, number>,
  color: string,
  maxValueData?: number
): Path | undefined {
  const node = svg.node();
  if (!node) return;

  const widthAttr = node.getAttribute('width');
  const heightAttr = node.getAttribute('height');
  const width = widthAttr ? parseInt(widthAttr, 10) : 0;
  const height = heightAttr ? parseInt(heightAttr, 10) : 0;

  const values = Object.values(data);
  const baseMax = values.length === 1 ? 100 : maxValue(values);
  const max = maxValueData ? maxValueData : baseMax;

  const colorScale = ColorScale(max, color);

  const geoJson = {
    ...ceara,
    features: ceara.features
      .map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          center: d3.geoCentroid(feature as any),
          total: data[feature.properties.id] ?? 0,
          color: colorScale(data[feature.properties.id]) as any
        }
      }))
      .sort((a, b) =>
        d3.descending(a.properties.center as any, b.properties.center as any)
      )
  };

  const projection = d3.geoMercator().fitSize([width, height], geoJson as any);
  const path = d3.geoPath().projection(projection);

  return svg
    .append('g')
    .selectAll(`path.city`)
    .data(geoJson.features)
    .enter()
    .append('path')
    .attr('d', path as any)
    .attr('class', 'city')
    .style('fill', (d) => d.properties.color);
}

export function onHover(path: Path | undefined, filteringCity: string | undefined, isSmall: boolean, handleHover: (data?: Data) => void, handleClick: ((city?: string) => void) | undefined) {

  if (!path) return;

  if (filteringCity) {
    const tooltip = getTooltipNameCity();

    path.on('mouseenter', function (e: any, d: GeoJson) {
      if (handleClick === undefined || isSmall) return
      tooltip.showTooltip(d.properties.name);
    });

    path.on('mousemove', (event, node) => {
      tooltip.setPosition(event.clientX + 1, event.clientY + 30);
    })

    path.on('mouseleave', () => {
      tooltip.hiddenTooltip();
    });

    path.on('click', function (e: any, d: GeoJson) {
      tooltip.hiddenTooltip()
      if (handleClick && !isSmall) handleClick(`${d.properties.name}_${d.properties.id}`)
    })
  } else {
    const nodes = path.nodes();
    const colors = path.data();

    path.on('mouseenter', function (e: any, d: GeoJson) {
      const currentIdx = nodes.indexOf(this);

      nodes
        .filter((_, i) => i !== currentIdx)
        .forEach((node) => {
          node.style.fill = '#fff';
        });

      nodes
        .filter((_, i) => i === currentIdx)
        .forEach((node) => {
          node.style.fill = d.properties.color;
        });


      handleHover({ name: d.properties.name, value: d.properties.total });
    });

    path.on('mouseleave', () => {
      nodes.forEach((node, i) => {
        node.style.fill = colors[i].properties.color;
      });
      handleHover();
    });

    path.on('click', function (e: any, d: GeoJson) {
      if (handleClick && !isSmall) handleClick(`${d.properties.name}_${d.properties.id}`)
    })
  }
}
