import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '@/theme/colors';

export const Box = styled(Link)`
  border-bottom: 1px solid #dfe6e0;
  padding: 1rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 0.5s;

  &:last-child {
    border-bottom: none;
  }

  position: relative;

  &::before {
    content: '';
    display: block;
    width: 0px;
    height: 100%;

    position: absolute;
    left: 0;
    pointer-events: none;

    background-color: ${({ theme }) =>
      theme.darkMode ? '#ffffff1a' : colors.ciano700};
    opacity: 0.1;

    transition: width 0.5s;
  }

  &:hover {
    &&::before {
      width: 100%;
    }
  }

  .action-main {
    color: ${({ theme }) => (theme.darkMode ? colors.gray400 : colors.gray600)};

    h4 {
      max-width: 400px;
      margin-bottom: 0.3rem;
      font-size: 1rem;
      text-transform: capitalize;
      font-weight: 500;
      text-align: left;
      color: ${(props) =>
        props.theme.darkMode ? colors.white : colors.green700};
    }

    .action-tags {
      margin: 0.25rem 0;
      gap: 0.8rem;
      display: flex;

      @media (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .comments-warning {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .forum-icon {
    width: 24px;
    height: 24px;

    stroke-width: 1px;
    stroke: ${({ theme }) => (theme.darkMode ? '#fff' : colors.green600)};
    fill: transparent;
    transition: all 0.2s;

    @media (max-width: 400px) {
      display: none;
    }
  }
`;
