import { Box } from './styles';

interface TextIconProps {
  icon?: () => JSX.Element;
  text: () => JSX.Element;
}

export function TextIcon({ icon, text }: TextIconProps) {
  return (
    <Box>
      {icon && icon()}
      {text()}
    </Box>
  );
}
