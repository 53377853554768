import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useLocation } from 'react-router-dom';
import { UserData } from 'acesso-cidadao-oauth2-lib';

import { oauth2Agent } from '../services/auth';

import cities from '@/constants/cities.json';
import { capitalize } from '@/utils/string';

type AuthContextProps = {
  user?: UserData;
  authenticated: boolean;
  defineAuth: (authenticated: boolean, user?: UserData) => void;
  isAdm: boolean;
  userCity: string | undefined;
  userIbge: string;
};

const LoginContext = createContext<AuthContextProps>({} as AuthContextProps);

interface LoginProviderProps {
  children: ReactNode;
}

const APP_TOKEN = `${process.env.REACT_APP_BIG_DATA_APP_KEY}_token`;

export const boot = async () => {
  try {
    await oauth2Agent.boot();

    const me = await oauth2Agent.me();

    return { user: me, authenticated: true };
  } catch (err) {
    return Promise.reject(err);
  }
};

export function LoginProvider({ children }: LoginProviderProps) {
  const [user, setUser] = useState<UserData>();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [userCity, setUserCity] = useState<string>();
  const [userIbge, setUserIbge] = useState<string>('');

  useEffect(() => {
    async function checkLogin() {
      try {
        const me = await oauth2Agent.me();
        setUser(me);
        setAuthenticated(true);
      } catch (err: any) {
        if (err?.status === 401) {
          setUser(undefined);
          setAuthenticated(false);
        }
      }
    }

    checkLogin();
  }, []);

  const defineAuth = useCallback((authenticated: boolean, user?: UserData) => {
    setUser(user);
    setAuthenticated(authenticated);
    if (!user?.roles.includes(`${process.env.REACT_APP_ROLE_PREFIX}ADMIN`)) {
      const roles = user?.roles ?? [];

      const mainCityRole = roles.length > 0 ? roles[0] : undefined;
      const city_role = user ? mainCityRole : undefined;
      const city = city_role
        ?.split(`${process.env.REACT_APP_ROLE_PREFIX}GESTOR_`)[1]
        .replaceAll('_', ' ');
      setUserCity(capitalize(city ? city.toLowerCase() : ''));
      const ibge = cities.find(
        (c) => c.municipio.toLowerCase() === city?.toLowerCase()
      )?.ibge;
      setUserIbge(ibge ?? '');
    }
  }, []);

  const isAdm = useMemo(() => {
    return user
      ? user.roles.includes(`${process.env.REACT_APP_ROLE_PREFIX}ADMIN`)
      : false;
  }, [user]);

  const auth = useMemo(
    () => ({
      authenticated,
      userCity,
      user,
      defineAuth,
      isAdm,
      userIbge
    }),
    [authenticated, userCity, user, defineAuth, isAdm, userIbge]
  );

  return <LoginContext.Provider value={auth}>{children}</LoginContext.Provider>;
}

export function useLoginLoader() {
  const [loading, setLoading] = useState(true);
  const { defineAuth } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const response = await boot();

        defineAuth(response.authenticated, response.user);
        setLoading(false);
      } catch (err: any) {
        if (err.status === 401) {
          localStorage.removeItem(APP_TOKEN);
          const response = await boot();

          defineAuth(response.authenticated, response.user);
        }
        setLoading(false);
      }
    };

    checkLogin();
  }, [defineAuth, pathname]);

  return {
    loading
  };
}

export function useAuth() {
  return useContext(LoginContext);
}
