import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';
import { CardBase } from '../../../components/Card/styles';

export const Box = styled(CardBase)`
  ${mediaQuery.smallScreen} {
    .map-table {
      flex-direction: column;
    }
  }
`;
