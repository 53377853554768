import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Text = styled.div`
  font-weight: 700;
  padding: 2rem;
  span {
    font-size: 1.8rem;
    color: ${(props) => (props.theme.darkMode ? `#fff` : colors.ciano700)};
  }

  p {
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#000`)};

    font-size: 1.2rem;
    font-weight: 700;
  }
`;

export const IconBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .icon {
    width: 90px;
    height: 90px;
    margin: 10px;
    fill: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};

    &.less {
      fill: ${colors.gray100};
    }
  }
`;
