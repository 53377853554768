import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  .profile {
    margin: 32px 1rem;
  }
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: 700px repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;

  ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr;
  }

  .grid-2 {
    width: calc(200% + 1rem);
  }

  @media (max-width: 1450px) {
    grid-template-columns: 60% 40%;

    .grid-2 {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ChartBox = styled.div<{ selected?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    props.selected ? 'auto' : 'repeat(auto-fill, minmax(174px, 1fr))'};

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;
