import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  position: relative;
  z-index: 2;

  ${mediaQuery.largeScreen} {
    align-self: flex-end;
  }

  .image-container {
    position: relative;

    img {
      width: 500px;

      @media (max-width: 500px) {
        width: 95vw;
      }
    }
  }

  ${mediaQuery.smallScreen} {
    .image-container {
      margin-top: 120px;
    }
  }
`;
