import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services/profileSearch';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useAges() {
  const [ages, setAges] = useState<Data[]>([]);
  const [loadingAges, setLoadingAges] = useState(false);
  const [errorAges, setErrorAges] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingAges(true);
    profileSearch
      .getAges(ibge, year, semester, zone)
      .then((data) => setAges(data))
      .catch((err) => setErrorAges(err))
      .finally(() => setLoadingAges(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      ages,
      loadingAges,
      errorAges
    }),
    [ages, loadingAges, errorAges]
  );
}
