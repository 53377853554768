import { AntSelect } from '@/components/styled';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

import { useDeedLineFilterData, useYearFilterData } from '../../hooks';

const { Option } = AntSelect;

export function Filter() {
  const { actionTypeData, actionTypeLoading } = useDeedLineFilterData();
  const { yearData, yearLoading } = useYearFilterData();
  const { actionType, onChangeActionType, year, onChangeYear } =
    useAnalyticsFilter();

  return (
    <>
      <BdSelect
        placeholder="Selecione uma linha de ação"
        onChange={onChangeActionType}
        value={!!actionType ? actionType : undefined}
        loading={actionTypeLoading}
      >
        {actionTypeData?.map((p) => (
          <Option key={p.name} value={p.name}>
            {p.name}
          </Option>
        ))}
      </BdSelect>

      <BdSelect
        placeholder="Selecione um ano"
        onChange={onChangeYear}
        value={!!year ? year : undefined}
        loading={yearLoading}
      >
        {yearData?.map((p) => (
          <Option key={p.name} value={p.name}>
            {p.name}
          </Option>
        ))}
      </BdSelect>
    </>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
