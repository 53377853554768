type UrbanIconProps = {
  color: string;
};

export function BdsUrban({ color }: UrbanIconProps) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 7.10526V2.36842L7 0L4.66667 2.36842V3.94737H0V15H14V7.10526H9.33333ZM3.11111 13.4211H1.55556V11.8421H3.11111V13.4211ZM3.11111 10.2632H1.55556V8.68421H3.11111V10.2632ZM3.11111 7.10526H1.55556V5.52632H3.11111V7.10526ZM7.77778 13.4211H6.22222V11.8421H7.77778V13.4211ZM7.77778 10.2632H6.22222V8.68421H7.77778V10.2632ZM7.77778 7.10526H6.22222V5.52632H7.77778V7.10526ZM7.77778 3.94737H6.22222V2.36842H7.77778V3.94737ZM12.4444 13.4211H10.8889V11.8421H12.4444V13.4211ZM12.4444 10.2632H10.8889V8.68421H12.4444V10.2632Z"
        fill={color}
      />
    </svg>
  );
}
