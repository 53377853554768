import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

const ObserverContext = createContext<IntersectionObserver | undefined>(
  undefined
);

interface ObserverProviderProps {
  children: ReactNode;
  onObserver?: (entries: IntersectionObserverEntry[]) => void;
}

export function ObserverProvider({
  children,
  onObserver
}: ObserverProviderProps) {
  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    const config: IntersectionObserverInit = {
      rootMargin: '0px',
      threshold: 0.8
    };

    const observer = new IntersectionObserver((entries) => {
      if (onObserver) {
        onObserver(entries);
      }
    }, config);

    setObserver(observer);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ObserverContext.Provider value={observer}>
      {children}
    </ObserverContext.Provider>
  );
}

export function useObserver() {
  return useContext(ObserverContext);
}

export function useConnectObserver<T extends Element>() {
  const elementRef = useRef<T>(null);

  const observer = useObserver();

  useEffect(() => {
    const { current } = elementRef;

    if (!current || !observer) return;

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [observer]);

  return elementRef;
}
