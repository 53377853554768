import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useData } from './useData';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

interface HighIlliterateByCityProps {
  color: string;
}

export function HighIlliterateByCity({ color }: HighIlliterateByCityProps) {
  const { top17, error, loading } = useData();
  const { city, gender, colorSkinType } = useAnalyticsFilter();

  return (
    <Section>
      <Card
        title="Municípios com o maior número de analfabetos entre pessoas de 18 anos ou mais"
        loading={loading}
        empty={top17.length === 0}
        error={error}
        date="2021"
        tags={[city ?? 'Ceará', gender ?? '', colorSkinType ?? '']}
      >
        <HorizontalBarSimple
          sizeRatio={0.7}
          maxValue={5}
          data={top17}
          isPercentage
          color={color}
          hasPagination
        />
      </Card>
    </Section>
  );
}
