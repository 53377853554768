import styled, { keyframes } from 'styled-components';

import { colors } from '@/theme/colors';
import { Card } from '../styles';

interface HistoryProps {
  order: number;
}

export const Box = styled(Card)`
  height: 100%;
`;

export const Histories = styled.ul`
  list-style: none;
  padding: 1rem 2rem;
  margin: 0;

  max-height: 680px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;

    &:hover {
      background-color: #555;
    }
  }
`;

const showPoint = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const growLine = keyframes`
  0% {
    height: 0
  }
  100% {
    height: 30px;
  }
`;

export const History = styled.li<HistoryProps>`
  display: flex;
  gap: 2rem;
  align-items: center;

  & + & {
    margin-top: 2rem;

    @media (max-width: 400px) {
      margin-top: 1.8rem;
    }
  }

  .point {
    animation: ${showPoint} ${({ order }) => `${(order + 2) / 10}s`} forwards;
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }

  &:not(li:last-of-type) .point {
    &::after {
      content: '';
      display: block;
      width: 2px;
      background-color: ${colors.gray500};

      position: absolute;
      top: 36px;

      animation: ${growLine}
        ${({ order }) => `${(order + 2) / 10}s ${(order + 2) / 10}s`} forwards;
    }
  }

  .history-content {
    animation: ${showPoint} ${({ order }) => `${(order + 2) / 10}s`} forwards;
  }

  .description {
    font-weight: 700;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};

    @media (max-width: 400px) {
      max-width: 196px;
    }
  }

  .details {
    font-size: 0.7rem;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};

    .author {
      font-weight: 700;
      margin-right: 0.5rem;
    }
  }
`;

export const HistoryPoint = styled.span.attrs({ className: 'point' })`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  color: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
  background-color: ${({ theme }) =>
    theme.darkMode ? colors.yellow500 : colors.ciano700};

  position: relative;
`;

export const Intersection = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
`;
