import { useState, useEffect, useCallback } from 'react';
import { Theme } from 'big-data/theme';

import { StyleStorage } from './styleStorage';
import { ThemeDark } from './ThemeDark';
import { ThemeLight } from './ThemeLight';

const QUERY_DARK_MODE = `(prefers-color-scheme: dark)`;

export function useDarkMode() {
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [theme, setTheme] = useState<Theme>(ThemeLight);

  useEffect(() => {
    const systemDarkMode = window.matchMedia(QUERY_DARK_MODE);

    function onChangeDarkMode() {
      const systemDark = systemDarkMode.matches;
      StyleStorage.setDarkMode(systemDark);
      setDarkMode(systemDark);
    }

    systemDarkMode.addEventListener(`change`, onChangeDarkMode);

    return () => {
      systemDarkMode.removeEventListener(`change`, onChangeDarkMode);
    };
  }, []);

  useEffect(() => {
    setTheme(darkMode ? ThemeDark : ThemeLight);
  }, [darkMode]);

  const toggle = useCallback(() => {
    setDarkMode((prev) => {
      const dark = !prev;
      StyleStorage.setDarkMode(dark);

      return dark;
    });
  }, []);

  return { theme, darkMode, toggle };
}
