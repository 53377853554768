import { Loading } from '@/components/shared';
import { Box, ContentBox, Text, Image, TextIcon } from './style';

interface InfoCardProps {
  label: string;
  value: number;
  image?: string;
  suffix?: string;
  loading?: boolean;
  float?: number;
  textIcon?: string;
}

export function InfoCard({
  label,
  value,
  image,
  suffix,
  loading = false,
  float = 2,
  textIcon
}: InfoCardProps) {
  function renderContent() {
    return (
      <ContentBox>
        {image ? (
          <Image src={image} alt={label} />
        ) : (
          <TextIcon>{textIcon}</TextIcon>
        )}

        <Text>
          <span className="percentage">
            {value.toFixed(float)}
            {suffix}
          </span>{' '}
          <span className="label">{label}.</span>
        </Text>
      </ContentBox>
    );
  }

  return (
    <Box className={loading ? 'loading' : ''}>
      {loading ? <Loading /> : renderContent()}
    </Box>
  );
}
