import { colors } from '@/theme/colors';

const resolveColor = new Map([
  ['Sim', '#55A546'],
  ['Não', '#D4282A']
]);

const resolveColorInverted = new Map([
  ['Não', '#55A546'],
  ['Sim', '#D4282A']
]);

export function colorGenerator(
  key: string,
  invertColor = false,
  fallbackColor = colors.gray500
) {
  let color: string | undefined = '';
  if (invertColor) color = resolveColorInverted.get(key);
  else color = resolveColor.get(key);
  return color || fallbackColor;
}
