import { colors } from '@/theme/colors';
import styled from 'styled-components';
import { Card } from '../styles';

export const Box = styled(Card)`
  margin: 1rem 0;
  width: 100%;

  .board-content {
    margin: 1rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-input-affix-wrapper {
      max-width: 80vw;
    }

    .input-container {
      display: flex;
      width: 100%;
      gap: 1rem;
      height: 38px;

      .ant-input-affix-wrapper {
        background-color: ${({ theme }) =>
          theme.darkMode ? theme.background : ''};

        input {
          background-color: inherit;
          color: ${({ theme }) => (theme.darkMode ? colors.white : '')};
        }
      }

      a.see-complete-list-Link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0 1rem;

        white-space: nowrap;
        background: ${({ theme }) =>
          theme.darkMode ? theme.yellowDark : colors.ciano700};
        border: 1px solid
          ${({ theme }) =>
            theme.darkMode ? theme.yellowDark : colors.ciano700};
        border-radius: 7px;
        color: ${({ theme }) =>
          theme.darkMode ? theme.background : colors.white};

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.7);
        }
      }
    }

    &-tabs {
      padding: 1rem 0;
      margin: 1.5rem 0;
      max-width: 1320px;
      width: 80vw;
      height: max-content;
      overflow-y: hidden;
      overflow-x: auto;
      scroll-behavior: smooth;

      display: grid;
      grid-template-columns: repeat(4, 338px);
      grid-gap: 2rem;

      @media (max-width: 1024px) {
        width: 75vw;
      }

      @media (max-width: 768px) {
        max-width: 722px;
      }

      @media (max-width: 360px) {
        grid-template-columns: repeat(4, 260px);
      }

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;

        &:hover {
          background-color: #555;
        }
      }
    }
  }
`;
