import { useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Theme, TScreenType } from 'big-data/theme';

import { GlobalStyle } from './global';
import { calcCurrentMediaType, ScreenType } from './mediaQuery';
import { useFontLevel } from './useFontLevel';
import { useDarkMode } from './useDarkMode';

export const ThemeProvider = ({ children }: any) => {
  const { theme, toggle } = useDarkMode();

  const [screen, setScreen] = useState<TScreenType>(ScreenType.small);

  const font = useFontLevel(screen);

  useEffect(() => {
    const x = 0; // fix horizontal scroll
    const y = window.scrollY || 0;
    window.scrollTo(x, y);
  }, []);

  useEffect(() => {
    function resize() {
      const type = calcCurrentMediaType();
      setScreen(type);
    }
    resize();
    window.addEventListener(`resize`, resize, false);
    return () => window.removeEventListener(`resize`, resize);
  }, []);

  const context: Theme = useMemo(() => {
    return {
      ...theme,
      screen: {
        small: screen === ScreenType.small,
        medium: screen === ScreenType.medium,
        large: screen === ScreenType.large
      },
      font,
      toggleTheme: toggle
    };
  }, [theme, screen, toggle, font]);

  return (
    <StyledThemeProvider theme={context}>
      <GlobalStyle />
      {children}
    </StyledThemeProvider>
  );
};
