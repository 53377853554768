import { colors } from '@/theme/colors';
import { Svg, Box } from './styles';

interface LoadingProps {
  size?: number;
  color?: string;
  display?: 'inline' | 'block';
}

export function Loading({
  size = 50,
  color = colors.ciano700,
  display
}: LoadingProps) {
  function renderSpinner() {
    return (
      <Svg
        color={color}
        width={size}
        height={size}
        version="1.1"
        viewBox="0 0 52 52"
      >
        <g
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            className="circle circle-1"
            cx="26"
            cy="26"
            r="24"
            strokeDasharray="80"
            strokeWidth="2"
          />
          <circle
            className="circle circle-2"
            cx="26"
            cy="26"
            r="20"
            strokeDasharray="60"
            strokeWidth="2"
          />
          <circle
            className="circle circle-3"
            cx="26"
            cy="26"
            r="16"
            strokeDasharray="45"
            strokeWidth="2"
          />
        </g>
      </Svg>
    );
  }

  function renderBlockSpinner() {
    return <Box>{renderSpinner()}</Box>;
  }

  return display === 'block' ? renderBlockSpinner() : renderSpinner();
}
