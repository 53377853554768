export function SalariedWorkIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1667 2.83325C12.1667 2.17021 11.9033 1.53433 11.4344 1.06549C10.9656 0.596644 10.3297 0.333252 9.66667 0.333252H6.33333C5.67029 0.333252 5.03441 0.596644 4.56557 1.06549C4.09673 1.53433 3.83333 2.17021 3.83333 2.83325H3C2.33696 2.83325 1.70107 3.09664 1.23223 3.56549C0.763392 4.03433 0.5 4.67021 0.5 5.33325V11.9999C0.5 12.663 0.763392 13.2988 1.23223 13.7677C1.70107 14.2365 2.33696 14.4999 3 14.4999H13C13.663 14.4999 14.2989 14.2365 14.7678 13.7677C15.2366 13.2988 15.5 12.663 15.5 11.9999V5.33325C15.5 4.67021 15.2366 4.03433 14.7678 3.56549C14.2989 3.09664 13.663 2.83325 13 2.83325H12.1667ZM9.66667 1.99992H6.33333C6.11232 1.99992 5.90036 2.08772 5.74408 2.244C5.5878 2.40028 5.5 2.61224 5.5 2.83325H10.5C10.5 2.61224 10.4122 2.40028 10.2559 2.244C10.0996 2.08772 9.88768 1.99992 9.66667 1.99992ZM3 4.49992H13C13.221 4.49992 13.433 4.58772 13.5893 4.744C13.7455 4.90028 13.8333 5.11224 13.8333 5.33325V11.9999C13.8333 12.2209 13.7455 12.4329 13.5893 12.5892C13.433 12.7455 13.221 12.8333 13 12.8333H3C2.77899 12.8333 2.56702 12.7455 2.41074 12.5892C2.25446 12.4329 2.16667 12.2209 2.16667 11.9999V5.33325C2.16667 5.11224 2.25446 4.90028 2.41074 4.744C2.56702 4.58772 2.77899 4.49992 3 4.49992Z"
        fill="white"
      />
    </svg>
  );
}
