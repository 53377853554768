import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card5() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getSexualOrientation(ibge));
  }, [request, ibge]);

  const barSimpleData = useMemo(() => {
    return data.map((d) => ({
      ...d,
      value: d.value / 100
    }));
  }, [data]);

  return (
    <Card
      title="Distribuição das mulheres atendidas por orientação sexual"
      tags={[city ?? 'CEARÁ']}
      empty={data.length === 0}
      loading={loading}
      error={error}
    >
      <HorizontalBarSimple
        data={barSimpleData}
        color={colors.ciano700}
        isPercentage
        grid
      />
    </Card>
  );
}
