import { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';

interface BoundBox {
  northEast: number[];
  southWest: number[];
}

interface ComponentProps {
  reseted?: boolean;
  onZoom?: (zoom: number, boundBox: BoundBox) => void;
  onDrag?: (zoom: number, boundBox: BoundBox) => void;
  onZoomEnd?: () => void;
}

function Component({ reseted, onZoom, onZoomEnd, onDrag }: ComponentProps) {
  const map = useMapEvents({
    zoom: (e) => {
      if (onZoom) {
        const bounds = map.getBounds();
        const northEastCoords = bounds.getNorthEast();
        const southWestCoords = bounds.getSouthWest();
        const northEast = [northEastCoords.lat, northEastCoords.lng];
        const southWest = [southWestCoords.lat, southWestCoords.lng];
        onZoom(e.target.getZoom(), { northEast, southWest });
      }
    },
    zoomend: (e) => {
      if (onZoomEnd) {
        onZoomEnd();
      }
    },
    dragend: (e) => {
      if (onDrag) {
        const bounds = map.getBounds();
        const northEastCoords = bounds.getNorthEast();
        const southWestCoords = bounds.getSouthWest();
        const northEast = [northEastCoords.lat, northEastCoords.lng];
        const southWest = [southWestCoords.lat, southWestCoords.lng];
        onDrag(e.target.getZoom(), { northEast, southWest });
      }
    }
  });

  useEffect(() => {
    if (reseted) {
      const minZoom = map.getMinZoom();

      map.flyTo([-5.300195, -39.52694], minZoom);
    }
  }, [reseted, map]);

  return null;
}

export const EventLayer = (() => {
  const events: Record<string, ((...params: any[]) => void)[]> = {};

  function emit(event: string, ...params: any[]) {
    const fns = events[event];

    if (!fns) return;

    fns.forEach((fn) => fn(params));
  }

  function subscribe(event: string, fn: (...params: any[]) => void) {
    if (events[event]) {
      events[event].push(fn);
    } else {
      events[event] = [fn];
    }
  }

  function clear(event: string) {
    events[event] = [];
  }

  return {
    emit,
    subscribe,
    clear,
    Component
  };
})();
