import { Section } from '@/layout';
import { Card, D3Map } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useData } from './useData';

interface IlliterateByCityProps {
  color: string;
}

export function IlliterateByCity({ color }: IlliterateByCityProps) {
  const { filteredData, error, loading } = useData();
  const { ibge, city, gender, colorSkinType, onChangeCity } =
    useAnalyticsFilter();

  return (
    <Section>
      <Card
        title="Número de analfabetos entre pessoas de 18 anos ou mais por município"
        loading={loading}
        empty={filteredData.length === 0}
        error={error}
        date="2021"
        tags={[city ?? 'Ceará', gender ?? '', colorSkinType ?? '']}
      >
        <D3Map
          data={filteredData}
          color={color ?? '#FFF'}
          filteringCity={ibge}
          handleSelectedCity={onChangeCity}
        />
      </Card>
    </Section>
  );
}
