import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  // education

  async function schoolingData(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['NIVEL_ESCOLARIDADE']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.nivel_escolaridade,
        value: d.porcentagem
      };
    });
  }

  async function lgbtPhobiaSchool(params: LgbtParams) {
    const path = `${resource}/agregacao-lgbtfobia-escola`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.sofreu_lgbtfobia_escola,
        value: d.porcentagem
      };
    });
  }

  async function schoolPreparation(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['PREPARO_INSTITUICOES_ENSINO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.preparo_instituicoes_ensino,
        value: d.porcentagem
      };
    });
  }

  async function schoolLgbtPhobiaTypes(params: LgbtParams) {
    const path = `${resource}/nuvem-palavras-lgbtfobia-escola`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.qual_tipo_lgbtfobia_escola,
        value: d.porcentagem
      };
    });
  }

  return {
    schoolingData,
    lgbtPhobiaSchool,
    schoolPreparation,
    schoolLgbtPhobiaTypes
  };
};

export const useEducation = service();
