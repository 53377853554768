export const colors = Object.freeze({
  black: '#000',
  white: '#fff',

  gray100: '#F7FAFC',
  gray200: '#F2F2F2',
  gray300: '#F0F3F5',
  gray400: '#DFE6E9',
  gray500: '#979797',
  gray600: '#636E72',
  gray700: '#2D3436',
  gray800: '#161616',

  green100: '#dbf2dd',
  green200: '#57AC4B',
  green300: '#3CA52D',
  green400: '#55900E',
  green500: '#187209',
  green600: '#4e7345',
  green700: '#2D4040',
  green800: '#1E3131',

  yellow200: '#FFE86F',
  yellow300: '#F9CE70',
  yellow400: '#FFDE32',
  yellow500: '#FFCA16',
  yellow600: '#F8BB36',
  yellow700: '#EBAD29',
  yellow800: '#604c17',

  blue400: '#A9D2F9',
  blue500: '#1890FF',
  blue600: '#172755',

  ming: '#16DDF3',
  ming100: '#D8F9FD',

  ciano500: '#3CC3CD',
  ciano600: '#2A9AA2',
  ciano700: '#1D696F',

  orange300: '#EDA155',
  orange400: '#EA9753',
  orange500: '#ff9800',

  red200: '#FC5B6F',
  red300: '#ff4d4f',
  red400: '#D4282A',
  red500: '#DA2A2A',
  red600: '#561011',

  coral: '#ff8c8b',
  rose: '#f04983',
  purple: '#81459e',
  brown: '#BC6117'
} as const);
