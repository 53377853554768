import { DataWithCity } from 'big-data';
import { useEffect, useMemo, useState } from 'react';
import { HorizontalBarBuilder } from './HorizontalBarBuilder';
import { useBoxWidth } from './useBoxWidth';
import { Box, SvgBox, Wrapper } from './styles';
import { usePagination } from './usePagination';
import { Pagination } from './Pagination';
import { useTheme } from 'styled-components';
interface HorizontalBarProps {
  data: DataWithCity[];
  grid?: boolean;
  color?: string;
  isPercentage?: boolean;
  sizeRatio?: number;
  percentageCalculatedOut?: boolean;
  hasPagination?: boolean;
  maxValue?: number;
  showEntireData?: boolean;
}

export function HorizontalGridBar({
  data,
  color,
  grid = false,
  isPercentage = false,
  sizeRatio = 0.5,
  percentageCalculatedOut = false,
  hasPagination,
  showEntireData = false,
  maxValue = 5
}: HorizontalBarProps) {
  const { boxRef, width } = useBoxWidth();

  const { darkMode, ...theme } = useTheme();
  const trueColor = darkMode ? theme.yellowDark : color

  const [selected, setSelected] = useState<DataWithCity>();
  const { page, totalOfPages, changePage, backPage, nextPage } = usePagination(
    data,
    maxValue
  );

  const total = useMemo(() => {
    return data.reduce((acc, cur) => acc + cur.value, 0);
  }, [data]);

  const sorttedData = useMemo(() => {
    return data.sort((a, b) => b.value - a.value);
  }, [data]);

  const dataPagination = useMemo(() => {
    if (showEntireData) return sorttedData;
    return sorttedData.slice((page - 1) * maxValue, page * maxValue);
  }, [page, sorttedData, showEntireData, maxValue]);

  const onChangeSelect = (label?: string) => {
    const findData = data.find(
      (d) => d.name?.toUpperCase() === label?.toUpperCase()
    );
    setSelected(findData);
  };

  useEffect(() => {
    const { current } = boxRef;
    if (current) {
      const builder = new HorizontalBarBuilder(current, {
        width,
        sizeRatio,
        isPercentage,
        percentageCalculatedOut,
        selected: onChangeSelect,
        selectedValue: selected,
        total
      });

      builder
        .putData(dataPagination)
        .putMargin({ left: 20, right: 22, top: 0, bottom: 20 })
        .buildScales()
        .plotAxis(5)
        .plotData(trueColor, 4)
        .disableXAxisLine()
        .animateBar();

      if (grid) {
        builder.putGrid();
      }

      return () => {
        builder.unmount();
      };
    }
    // eslint-disable-next-line
  }, [
    boxRef,
    dataPagination,
    width,
    sizeRatio,
    trueColor,
    isPercentage,
    grid,
    percentageCalculatedOut,
    selected,
    total
  ]);

  return (
    <Box>
      <SvgBox ref={boxRef}></SvgBox>
      {totalOfPages > 1 && hasPagination && (
        <Wrapper>
          <Pagination
            page={page}
            size={totalOfPages}
            onChange={changePage}
            onBack={backPage}
            onNext={nextPage}
          />
        </Wrapper>
      )}
    </Box>
  );
}
