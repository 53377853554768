import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 1.5rem;

  ${mediaQuery.smallScreen} {
    grid-template-columns: minmax(300px, 1fr);
  }
`;
