import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  img {
    width: 36px;
    height: 36px;
  }

  p {
    color: ${colors.green600};
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;

    &.error {
      color: #d52029;
    }
  }

  span {
    font-size: 2rem;
  }
`;
