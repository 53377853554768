import { AgeGroupType } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

const service = () => {
  const resource = `/analfabetos-adultos`;

  async function getAdultIlliteratesByCityAndAmount(codIbge?: string) {
    const aggregations = ['FAIXA_ETARIA', 'QUANTIDADE_ANALFABETOS', 'IBGE'];

    const path = `${resource}?aggregations=${getQuery(aggregations)}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    const data = response.data;
    const result = data
      .map((res) => ({
        city: res.municipio ?? null,
        value: res.quantidade_analfabetos,
        name: String(res.faixa_etaria),
        codIbge: res.ibge ?? 0
      }))
      .filter((res) => (!!codIbge ? res.codIbge === Number(codIbge) : res))
      .reduce(
        (acc, cur) => {
          return {
            ...acc,
            [cur.name]: cur.value + acc[cur.name as AgeGroupType]
          };
        },
        {
          '18-40': 0,
          '40-60': 0,
          '60+': 0
        }
      );

    return Object.entries(result).map(([key, value]) => ({ name: key, value }));
  }

  return {
    getAdultIlliteratesByCityAndAmount
  };
};

export const illiterateAdults = service();
