import { Section } from '@/layout';
import { Card, DonutPopulated } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useEffect, useMemo } from 'react';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { socialMovements } from '@/services/socialMovements';

export function SocialControlParticipation() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.participationMovements());
  }, [request]);

  const formatedData = useMemo(() => {
    return data.map((d) => {
      let color = '';

      if (d.name.includes('Sim')) color = colors.green200;
      else if (d.name.includes('nunca')) color = colors.red500;
      else if (d.name.includes('Prefiro')) color = colors.gray600;
      else color = colors.yellow500;

      return {
        ...d,
        color
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Participação em espaços de controle social"
        empty={formatedData.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <DonutPopulated
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedData}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
