import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { colors } from '@/theme/colors';

export const Box = styled.form`
  textarea,
  .ant-input[disabled] {
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.black)};
    &:hover {
      border: 1px solid white;
      background: none;
    }
  }

  .ant-input {
    &:focus {
      border-color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.ciano700};
    }
  }
  .label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};
  }

  .buttons {
    margin-top: 1rem;

    display: flex;
    gap: 1rem;
    justify-content: space-between;

    .button-file {
      input[type='file'] {
        display: none;
      }
    }
    @media (max-width: 450px) {
      flex-direction: column;
    }

    .send {
      background-color: ${colors.ciano700};
      color: ${colors.white};
      padding: 0.5rem 1.5rem;
      border: none;
      border-radius: 7px;

      display: flex;
      gap: 0.5rem;

      svg {
        margin-top: 2.5px;
      }

      transition: all 0.2s;

      &:hover {
        filter: brightness(1.1);

        &:active {
          transform: scale(0.99);
        }
      }
    }
  }
`;

interface FileButtonProps {
  disabled?: boolean;
}

export const FileButton = styled.label<FileButtonProps>`
  padding: 0.5rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  border-radius: 7px;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  border: 1px solid
    ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};

  svg {
    margin-top: 2.5px;
  }

  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.gray300};
  }
`;

export const FileRemove = styled.button`
  padding: 0.5rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  border-radius: 7px;
  background-color: transparent;

  border: 1px solid
    ${({ theme }) => (theme.darkMode ? colors.white : colors.red500)};
  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.red500)};

  svg {
    margin-top: 2.5px;
  }

  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.gray300};
  }
`;

export const Button = styled(AntdButton)`
  &.ant-btn {
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.yellow500 : colors.ciano700};
    color: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
    padding: 0.5rem 1.5rem;
    border: 1px solid
      ${({ theme }) => (theme.darkMode ? colors.yellow500 : colors.ciano700)};
    border-radius: 7px;
    height: 100%;

    display: flex;
    gap: 0.5rem;

    svg {
      margin-top: 2.5px;
    }

    transition: all 0.2s;

    &:hover {
      filter: brightness(1.1);

      &:active {
        transform: scale(0.99);
      }
    }

    &[disabled]:hover {
      background-color: ${colors.ciano700};
      color: ${colors.white};
      border: 1px solid ${colors.ciano700};
      filter: none;
    }
  }
`;
