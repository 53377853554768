import { useDashboardFilter } from '@/hooks';
import { livingConditionAgents } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataPercentageComputed } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useZonesData() {
  const [zones, setZones] = useState<DataPercentageComputed[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    livingConditionAgents
      .getZone(ibge, year, semester, zone)
      .then((data) =>
        data.map((d) =>
          d.name === 'null' ? { ...d, name: 'não informada' } : d
        )
      )
      .then((data) => setZones(getDataWithPercentage(data)))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      zones,
      loading,
      error
    }),
    [zones, loading, error]
  );
}
