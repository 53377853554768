import { AnalyticsFilterProvider } from '@/hooks/useAnalyticsFilter';
import { AnalyticsTitleProvider } from '@/hooks/useAnalyticsTitle';
import { Outlet } from 'react-router-dom';
import { HeaderAccessibility } from '../HeaderAccessibility';
import { HeaderAnalytics } from '../HeaderAnalytics';
import { MenuAnalytics } from '../MenuAnalytics';
import { Box } from './styles';

export function Analytics() {
  return (
    <AnalyticsFilterProvider>
      <AnalyticsTitleProvider>
        <Box>
          <HeaderAccessibility />
          <MenuAnalytics />
          <div className="content">
            <HeaderAnalytics />
            <Outlet />
          </div>
        </Box>
      </AnalyticsTitleProvider>
    </AnalyticsFilterProvider>
  );
}
