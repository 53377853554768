import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card3() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getLevels(ibge));
  }, [request, ibge]);
  return (
    <Card
      title="Níveis de atuação das organizações (níveis por quantidade de organizações)"
      tags={[city ?? 'CEARÁ']}
      error={error}
      empty={data.length === 0}
      loading={loading}
    >
      <HorizontalBarSimple data={data} grid color="#1D696F" />
    </Card>
  );
}
