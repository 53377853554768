import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 200px;

  max-width: 200px;

  color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray700)};

  .value-tag {
    text-align: center;
  }

  .btn-czrm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    button {
      border: none;
      display: flex;
      background: none;
      flex-direction: column;
      align-items: center;
    }
  }

  span {
    color: ${(props) => {
    const baseColor = props.color ? props.color : props.theme.greenDefault;
    return props.theme.darkMode ? '#fff' : baseColor;
  }};
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    font-size: 0.7rem;
    cursor: pointer;
    z-index: 999;
  }

  .value-text {
    font-weight: 700;
    font-size: 2rem;
  }

  .label-text {
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
  }
  
  .label-value {
    color: ${(props) => {
    const baseColor = props.color ? props.color : props.theme.greenDefault;
    return props.theme.darkMode ? '#fff' : baseColor;
  }};
    
    display: inline;
    font-weight: 800;
  }

  .button-text {
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    letter-spacing: 0.05rem;
    text-transform: uppercase;

    margin-top: 0.8rem;
    border: none;
    font-size: 0.7rem;
    width: 150px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.gray500)};

    background-color: transparent;
    cursor: pointer;
    z-index: 999;
  }
`;
