import { useDashboardFilter } from '@/hooks';
import { healthAgents } from '@/services/healthAgents';
import { DataWithTotal } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useImpairmentChildData() {
  const [impairmentData, setImpairmentData] = useState<DataWithTotal[]>([]);
  const [impairmentError, setImpairmentError] = useState<Error | null>(null);
  const [impairmentLoading, setImpairmentLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setImpairmentLoading(true);
    healthAgents
      .getImpairmentChild(ibge, year, semester, zone)
      .then((data) => {
        const sortedData = [...data].sort((a, b) => b.value - a.value);
        setImpairmentData(sortedData);
      })
      .catch((error) => setImpairmentError(error))
      .finally(() => setImpairmentLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      impairmentData,
      impairmentError,
      impairmentLoading
    }),
    [impairmentData, impairmentError, impairmentLoading]
  );
}
