import { useState, useCallback } from 'react';
import { Observation } from 'big-data';
import { sort, uniqWith, eqProps } from 'ramda';
import dayjs from 'dayjs';

import { observation } from '@/services';

const sortComments = sort<Observation>((a, b) =>
  dayjs(a.createdDate).isAfter(dayjs(b.createdDate)) ? 1 : -1
);

export function useComments() {
  const [comments, setComments] = useState<Observation[]>([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [errorComments, setErrorComments] = useState('');

  const refreshComments = useCallback((id: number, page: number = 0) => {
    setLoadingComments(true);
    return observation
      .getObservations({ id, page })
      .then((response) => {
        setComments((current) => {
          const newComments = [...response.content, ...current];
          const uniqueComments = uniqWith(eqProps('id'), newComments);

          return sortComments(uniqueComments);
        });
      })
      .catch((err) =>
        setErrorComments(
          err.response ?? 'Houve um erro interno. Tente novamente mais tarde'
        )
      )
      .finally(() => setLoadingComments(false));
  }, []);

  return {
    comments,
    setComments,
    loadingComments,
    errorComments,
    refreshComments
  };
}
