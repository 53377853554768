import { ThirdTitle, GroupBlockBox } from '@/components/styled';
import { FilterAnalytic } from '@/components/shared';
import { colors } from '@/theme/colors';

import { FamiliesMap } from './GeoSection/FamiliesMap';
import { FamiliesBarChart } from './GeoSection/FamiliesBarChart';
import { SanitaryInfo } from './SanitaryInfo';
import { WallMaterial } from './WallMaterial/WallMaterial';
import { UserSex } from './UserSex/UserSex';
import { Box } from './styles';
import { useTheme } from 'styled-components';

export function ChildhoodInsights() {
  const { darkMode } = useTheme();
  const familiesMapColor = darkMode ? colors.ming100 : colors.ciano700;
  const familiesBarChart = darkMode ? colors.ming : colors.ciano700;

  return (
    <Box>
      <ThirdTitle>
        Distribuição Geográfica do programa Cartão Mais Infância
      </ThirdTitle>
      <FilterAnalytic />

      <GroupBlockBox>
        <FamiliesMap color={familiesMapColor} />
        <FamiliesBarChart color={familiesBarChart} />
      </GroupBlockBox>

      <ThirdTitle>
        Indicadores sociais e domiciliares dos beneficiados do programa Cartão
        Mais Infância
      </ThirdTitle>

      <SanitaryInfo />

      <GroupBlockBox>
        <WallMaterial />
        <UserSex />
      </GroupBlockBox>
    </Box>
  );
}
