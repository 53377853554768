import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .block-content {
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    gap: 1.5rem;
    margin: 1.8rem 0;

    width: 100%;

    img {
      filter: ${({ theme }) =>
        theme.darkMode ? `brightness(0) invert(1)` : ``};
    }

    .text {
      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 21px;

        letter-spacing: 0.02em;
        color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
      }

      p {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: ${(props) => (props.theme.darkMode ? `#fff` : colors.green700)};
      }
    }
  }
`;
