import { useService } from '@/hooks';
import { CountLabel } from '@/pages/Analytics/components';
import { useGeneral } from '@/services/lgbt/general';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilterContext } from '../../contexts/FilterContext';

export function TotalPopulationInterviewed() {
  const { ibge, orientation, city, identity } = useFilterContext();
  const { data, loading, request, error } = useService<Data>();

  const label = useMemo(() => {
    if (city) return `pessoas entrevistadas em ${city}`;
    return 'pessoas entrevistadas em todo o Ceará';
  }, [city]);

  const isFiltring =
    city !== undefined || orientation !== undefined || identity !== undefined;

  useEffect(() => {
    request(() =>
      useGeneral.city({ ibge, orientation, genderIdentity: identity })
    );
  }, [ibge, identity, orientation, request]);

  const interviewed = useMemo(() => {
    if (city) {
      const cityFind = data.find(
        (d) => d.name.toUpperCase() === city.toUpperCase()
      );
      return cityFind ? cityFind.value : 0;
    }

    return data.reduce((acc, cur) => acc + cur.value, 0);
  }, [city, data]);

  return (
    <CountLabel
      value={interviewed}
      error={error}
      loading={loading}
      isFiltring={isFiltring}
      size={30}
      label={label}
    />
  );
}
