import { DataPercentageComputed } from 'big-data';

import garbageNoFrequency from '@/assets/icons/garbage-no-frequency.svg';
import garbageTwoFrequency from '@/assets/icons/garbage-2-frequency.svg';
import garbageThreeFrequency from '@/assets/icons/garbage-3-frequency.svg';

const imgs: Record<string, string> = {
  'nenhuma vez': garbageNoFrequency,
  'de uma a duas vezes': garbageTwoFrequency,
  'mais de duas vezes': garbageThreeFrequency
};

export function getGarbageWithImages(data: DataPercentageComputed[]) {
  return data.map((d) => ({
    ...d,
    img: imgs[d.name.toLowerCase()],
    color: d.name === 'Nenhuma vez' ? '#df2320' : undefined
  }));
}
