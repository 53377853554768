import { css } from 'styled-components';
import { colors } from './colors';

import linkIcon from '@/assets/icons/link.svg';

export const antdOverrides = css`
  // popu submenu
  .menu-dashboard {
    .ant-menu-sub {
      background: ${({ theme }) =>
        theme.darkMode ? theme.background : colors.green700} !important;
      border: ${({ theme }) =>
        theme.darkMode ? `1px solid ${colors.white}` : ''};
    }
  }
  // popup submneu styles antd
  .ant-menu-submenu-popup {
    li {
      display: flex;
      align-items: center;

      &.ant-menu-item-selected {
        border: ${({ theme }) =>
          theme.darkMode ? `1px solid ${colors.white}` : ''};
        background-color: ${({ theme }) =>
          theme.darkMode ? theme.background : ''};
      }

      span {
        margin-left: 10px;
        font-weight: 500;
        font-size: 1rem;
        ${({ theme }) =>
          theme.darkMode &&
          css`
            color: ${theme.yellowDark};
            text-decoration: underline;
          `}
      }

      svg,
      svg path,
      svg stroke {
        fill: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
        stroke: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
        stroke-width: 0;
      }

      &.ant-menu-item {
        transition: background 0.2s;
        &:hover {
          background: ${({ theme }) => (theme.darkMode ? '' : colors.green200)};
        }
      }
    }
  }

  .ant-popconfirm {
    .ant-popover-message {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        margin-top: -2px;
      }

      &-title {
        padding-left: 0;
      }
    }

    .ant-popover-buttons {
      .ant-btn-primary {
        background-color: ${colors.red500};
        border-color: ${colors.red500};
      }
    }
  }

  .ant-popover-inner-content {
    padding: 5px;
  }

  .ant-dropdown.acessibility-menu {
    width: 100vw;
    z-index: 10000;

    .ant-dropdown-menu {
      background-color: ${({ theme }) =>
        theme.darkMode ? colors.black : colors.gray200};
      padding: 0;

      &-item {
        padding: 5px max(15px, 3vw);

        a {
          font-size: 1rem;
          color: ${({ theme }) =>
            theme.darkMode ? theme.yellowDark : theme.textDark};
          background: url(${linkIcon}) no-repeat 0 40%;
          padding-left: 20px;
          background-size: 15px;
        }

        &:hover {
          background-color: ${({ theme }) =>
            theme.darkMode ? colors.gray700 : colors.gray400};
        }
      }
    }
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      .ant-select {
        &-selector {
          background-color: ${({ theme }) => theme.background} !important;
          color: ${colors.white};

          svg {
            fill: ${colors.white} !important;
          }
        }

        // apply to all antd dropdowns
        &-dropdown {
          background-color: ${theme.background};
          border: 1px solid ${colors.white};

          .ant-select-item {
            &:hover {
              border: 1px solid white;
              background-color: transparent;
            }

            &-option-selected,
            &-option-active {
              background-color: transparent;
              border: 1px solid ${colors.white};

              .ant-select-item-option-content {
                color: ${colors.white};
              }
            }
          }

          .ant-select-item-option-content {
            color: ${theme.yellowDark};
          }
        }
      }
    `};
`;
