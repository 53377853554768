import { dotsNumber } from '@/utils/string';
import { Data } from 'big-data';
import { TableBox } from './styles';

interface Column {
  name: string;
  key: string;
}

interface TableProps {
  data: Data[];
  columns: Column[];
  title?: string;
  showColumnsTitle?: boolean;
  percentage?: boolean;
}

function formatValue(
  value: string | number,
  total: number,
  percentage = false
) {
  if (typeof value === 'number') {
    if (percentage) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }).format(value / total);
    }
    return dotsNumber(value);
  } else return value;
}

export function Table({
  title,
  data,
  columns,
  showColumnsTitle = true,
  percentage
}: TableProps) {
  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const dataRecord = data
    .toSorted((a, b) => b.value - a.value)
    .map((d) => ({ ...d } as Record<string, string | number>));

  return (
    <TableBox>
      {title && (
        <thead>
          <tr>
            <th colSpan={2}>{title}</th>
          </tr>
        </thead>
      )}

      <tbody>
        {showColumnsTitle && (
          <tr className="titles">
            {columns.map((column) => (
              <th key={column.key}>{column.name}</th>
            ))}
          </tr>
        )}

        {dataRecord.map((d) => (
          <tr key={d.name} className="rows">
            {columns.map((column) => {
              const value = d[column.key];

              return (
                <td key={`${d.name}-${column.key}`}>
                  {formatValue(value, total, percentage)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </TableBox>
  );
}
