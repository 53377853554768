import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
  padding: 16px;
  border-radius: 7px;

  border: 1px solid #dfe6e9;
  width: 100%;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
  }

  .search-input {
    border: 1px solid #d8d8d8;
    border-radius: 7px;
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    width: 300px;

    input {
      color: ${({ theme }) => (theme.darkMode ? colors.white : colors.black)};
    }

    span {
      color: ${({ theme }) => (theme.darkMode ? colors.white : '#ccc')};
    }

    .ant-input-group-addon {
      overflow: hidden;
      background-color: transparent;

      button {
        background-color: transparent;
        border: 0;

        svg {
          fill: ${colors.ciano700};
        }
      }

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }

  .icon-text-wrapper {
    font-weight: 400;
    font-size: 0.875rem; // 14px
    line-height: 16px;

    color: ${(props) => props.theme.text};

    display: flex;
    align-items: center;

    .filter-explanation {
      line-height: 20px;
      margin-left: 1rem;

      color: ${({ theme }) => (theme.darkMode ? '#fff' : '#4C5469')};
    }
  }

  .select-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .select-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .ant-select {
        width: 292px;

        &-selector {
          border-radius: 8px;
        }
      }
    }

    button.clear-filter {
      justify-self: flex-start;

      background: none;
      border: none;
      color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.ciano700};
      text-decoration: ${({ theme }) =>
        theme.darkMode ? 'underline' : 'none'};

      display: flex;
      align-items: center;
      transition: filter 0.2s;

      span {
        font-weight: 700;
        font-size: 0.8rem;
        margin-top: 3px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  @media (max-width: 790px) {
    .select-wrapper {
      flex-direction: column;
      width: 100%;
    }

    .select-wrapper .select-group .ant-select,
    .select-wrapper .select-group .ant-input-search {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  @media (max-width: 400px) {
    .select-wrapper {
      flex-direction: column;
    }
  }
`;
