import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const CitySelected = ({ visible, name, total }: ICity) => {
  return (
    <Wrapper visible={visible}>
      <Total>{total}</Total>
      <MultiLine>{name}</MultiLine>
    </Wrapper>
  );
};

const Total = styled.b``;

const MultiLine = styled.div`
  line-height: 1;
`;

const Wrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 300px;
  text-align: right;
  pointer-events: none;
  font-size: 44px;
  line-height: 1.2;
  color: ${(props) => (props.theme.darkMode ? `#fff5` : `#2d404055`)};
  transition: 0.2s transform, 0.2s opacity;
  ${(props) =>
    props.visible
      ? null
      : css`
          opacity: 0;
          transform: translate(0, 5px);
        `};
  ${Total} {
    display: block;
    font-size: 26px;
    color: ${(props) => (props.theme.darkMode ? `#fffa` : `#2d4040`)};
  }
  ${mediaQuery.mediumScreen} {
    right: 10px;
    bottom: 0;
  }
  ${mediaQuery.smallScreen} {
    right: 5px;
    bottom: 0;
    max-width: 200px;
    font-size: 28px;
    line-height: 1;
    ${Total} {
      font-size: 20px;
    }
  }
`;
