import { useDashboardFilter } from '@/hooks';
import { livingConditionAgents } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataGroup } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const labels = [
  'Lugares públicos para brincar próximo às residências',
  'Atividades culturas próximo às residências',
  'Atividades esportivas próximo às residências'
];

export function useActivitiesData() {
  const [activities, setActivities] = useState<DataGroup[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    const requests = [
      livingConditionAgents.getKidsRecreation,
      livingConditionAgents.getKidsCulturalActivities,
      livingConditionAgents.getSportActivities
    ];

    setLoading(true);
    Promise.all(requests.map((request) => request(ibge, year, semester, zone)))
      .then((data) =>
        data.map((d) => d.filter((v) => v.name.toLowerCase() !== 'não sei'))
      )
      .then((data) => data.map((d) => getDataWithPercentage(d)))
      .then((data) => data.map((d, i) => ({ label: labels[i], data: d })))
      .then((data) => setActivities(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      activities,
      loading,
      error
    }),
    [activities, loading, error]
  );
}
