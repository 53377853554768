import { AiOutlineWarning } from 'react-icons/ai';
import { Box } from './styles';

interface AlertCityModalProps {
  onCancel: () => void;
  text: string
}

export function AlertCityModal({ onCancel, text }: AlertCityModalProps) {
  return (
    <Box>
      <AiOutlineWarning size={64} color="#FEC93E" />
      <h2>{text}</h2>
      <button onClick={onCancel}>
        <span>OK</span>
      </button>
    </Box>
  );
}
