import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

export const Box = styled.ul`
  list-style: none;
  max-width: 500px;
  margin: 1.2rem 0;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: end;
  align-items: flex-start;

  .legend-button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    word-break: break-word;

    span.ball {
      align-self: flex-start;
    }

    span.text {
      text-align: left;
      flex: 1;
    }
  }

  @media (max-width: 1115px) {
    grid-template-columns: 1fr;
  }
`;

interface LegendItemProps {
  active?: boolean;
}

const getOpacity = (active?: boolean) => {
  if (active === undefined)
    return css`
      opacity: 1;
    `;

  return active
    ? css`
        opacity: 1;
      `
    : css`
        opacity: 0.4;
      `;
};

export const LegendItem = styled.li<LegendItemProps>`
  display: flex;
  align-items: center;

  transition: opacity 0.2s;

  ${({ active }) => getOpacity(active)}

  .text {
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
    font-size: 0.8rem;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.7;
  }
`;

interface BallProps {
  fill: string;
}

export const Ball = styled.span<BallProps>`
  display: inline-block;
  background-color: ${({ fill }) => fill};
  border-radius: 50%;
  margin-right: 0.3rem;
  width: 16px;
  height: 16px;
`;
