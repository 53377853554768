export function resolveFilters(_filters?: string[]) {
  let myFilters: string[] = [];
  if (_filters) {
    const [sex, skinColor, city] = _filters;
    if (!!sex) myFilters.push('SEXO_PESSOA');
    if (!!skinColor) myFilters.push('RACA_COR_PESSOA');
    if (!!city) myFilters.push('IBGE');
  }
  return myFilters;
}
