type Event<T> = {
  event: string;
  element: T;
};

export class EventStack<T> {
  private stack: Event<T>[] = [];

  push(event: string, element: T) {
    this.stack.push({ event, element });
  }

  pop() {
    return this.stack.pop();
  }

  popIfTopIs(event: string) {
    const topEvent = this.topEvent();

    if (topEvent && topEvent === event) {
      return this.pop();
    }
  }

  top() {
    const topIndex = this.stack.length;

    return this.stack[topIndex - 1];
  }

  topEvent() {
    const topIndex = this.stack.length;

    return this.stack[topIndex - 1]?.event;
  }

  topElement() {
    const topIndex = this.stack.length;

    return this.stack[topIndex - 1]?.element;
  }

  clear() {
    const size = this.stack.length;

    return Array(size)
      .fill(0)
      .map(() => this.stack.pop());
  }

  print() {
    console.log(this.stack);
  }
}
