import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { googleAnalyticsResource } from './utils/resource';

export const service = () => {
  const resource = googleAnalyticsResource + '/temporal-rejeicao';

  async function get(minimumDate?: string, ibge?: string) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        fromCeara: true,
        order: 'desc'
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((data) => ({
      name: data.data,
      value: data.rejeicao
    }));
  }

  return { get };
};

export const temporalRejection = service();
