import { toAxlesD3MapFormat } from '@/utils/map';
import { strToInt } from '@/utils/types';
import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';
import { DashboardRequestParams } from 'big-data/service';

interface EducationParams extends DashboardRequestParams {
  variants?: EducationVariant[];
  hasFilter?: boolean;
  filter?: string;
  name?: string;
}
export interface EducationResolver {
  name: string;
  value: number;
  codIbge: string | null;
  city: string | null;
  schoolMode: string;
}

type EducationVariant = 'particular' | 'pública' | 'não' | undefined;

interface EducationQueryParams extends DashboardRequestParams {
  aggregations: string[];
  ageGroupFilter?: string[];
}

const ageRecord: Record<string, string> = {
  CRECHE: 'ZERO_A_TRES',
  PRE_ESCOLA: 'TRES_A_SEIS',
  ESCOLA: 'SEIS_A_OITO'
};

function removeSpecialCharAndAddUnderscore(str: string) {
  return str
    .replace(/[^\w]+/g, '_')
    .replace(/(_$)/, '')
    .toLowerCase();
}

function resolveEducationVariants(
  data: EducationResolver[],
  variants?: EducationVariant[]
) {
  if (!variants) return data;

  return data.filter((d) =>
    variants.includes(
      d.schoolMode.toLowerCase().replace('sim, ', '') as EducationVariant
    )
  );
}

const textReplaced = `sim, `;

const replacer = (str: string) => {
  return str
    .toLowerCase()
    .replace(textReplaced, '')
    .replace('gratuita', 'pública');
};

const service = () => {
  const resource = `/educacao-agentes`;

  async function generateGeneralRequest(
    path: string,
    params: EducationQueryParams
  ) {
    const { aggregations, ageGroupFilter, ibge, year, semester, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        ageGroupFilter: getQuery(!!ageGroupFilter ? ageGroupFilter : []),
        ibge,
        year,
        semester,
        zone
      }
    });

    return await callService(() => mainApi.get<any[]>(path, options));
  }

  async function getQuantitative(name?: string, city?: string) {
    const adjustedName = !!name
      ? name.toLowerCase().replace('é', 'e').trim()
      : '';

    const aggregations = [
      !!name
        ? removeSpecialCharAndAddUnderscore(adjustedName).toUpperCase()
        : ''
    ].filter(Boolean);
    const path = `${resource}`;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        city
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return strToInt(response.data[0].quantidade);
  }

  async function getNursery({ hasFilter, variants, ...rest }: EducationParams) {
    const path = resource;

    const schoolingOption = 'CRECHE';
    const aggregations = hasFilter
      ? ['IBGE', 'MUNICIPIO', schoolingOption]
      : [schoolingOption];
    const ageGroupFilter = [ageRecord[schoolingOption]];

    const response = await generateGeneralRequest(path, {
      aggregations,
      ageGroupFilter,
      ...rest
    });

    if (response.status === 204) return [];

    const result = response.data.map((data) => ({
      name: `creche`,
      value: data.quantidade,
      codIbge: data?.ibge ?? null,
      city: data?.municipio ?? null,
      schoolMode: replacer(data.creche)
    }));

    return resolveEducationVariants(result, variants);
  }

  async function getPreSchool({
    hasFilter,
    variants,
    ...rest
  }: EducationParams) {
    const path = resource;

    const schoolingOption = 'PRE_ESCOLA';
    const aggregations = hasFilter
      ? ['IBGE', 'MUNICIPIO', schoolingOption]
      : [schoolingOption];

    const ageGroupFilter = [ageRecord[schoolingOption]];

    const response = await generateGeneralRequest(path, {
      aggregations,
      ageGroupFilter,
      ...rest
    });

    if (response.status === 204) return [];

    const result = response.data.map((data) => ({
      name: `pré-escola`,
      value: data.quantidade,
      codIbge: data?.ibge ?? null,
      city: data?.municipio ?? null,
      schoolMode: replacer(data.pre_escola)
    }));

    return resolveEducationVariants(result, variants);
  }

  async function getSchool({ hasFilter, variants, ...rest }: EducationParams) {
    const path = resource;

    const schoolingOption = 'ESCOLA';
    const aggregations = hasFilter
      ? ['IBGE', 'MUNICIPIO', schoolingOption]
      : [schoolingOption];

    const ageGroupFilter = [ageRecord[schoolingOption]];

    const response = await generateGeneralRequest(path, {
      aggregations,
      ageGroupFilter,
      ...rest
    });

    if (response.status === 204) return [];

    const result = response.data.map((data) => ({
      name: `escola`,
      value: data.quantidade,
      codIbge: data?.ibge ?? null,
      city: data?.municipio ?? null,
      schoolMode: replacer(data.escola)
    }));

    return resolveEducationVariants(result, variants);
  }

  async function getTotalValue(
    ibge?: string,
    year?: string,
    semester?: string
  ) {
    const path = `${resource}`;
    const aggregations: any[] = [];

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester
    });

    return strToInt(response.data[0].quantidade);
  }

  async function getCities({
    filter,
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const filterWithoutSpace = !!filter ? filter.replace('é', 'e').trim() : '';

    const specialFilter = removeSpecialCharAndAddUnderscore(filterWithoutSpace);

    const aggregations = [
      'MUNICIPIO',
      'IBGE',
      !filter
        ? ['CRECHE', 'PRE_ESCOLA'].join(', ')
        : specialFilter.toUpperCase()
    ].filter(Boolean);

    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    const result = response.data.map((data) => ({
      codIbge: data.ibge,
      name: data.municipio,
      filter: data[specialFilter],
      value: data.quantidade
    }));

    return toAxlesD3MapFormat(result, `não`);
  }

  async function getQuantitativeByFilter(filter?: string) {
    const aggregations = ['FAIXA_ETARIA', 'PROGRAMA_VISITACAO_DOMICILIAR'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));
    const parsedResponse = response.data.map((res) => ({
      visitationHomeProgram: res.programa_visitacao_domiciliar,
      value: res.quantidade,
      ageGroup: res.faixa_etaria
    }));

    if (!!filter) {
      return parsedResponse.filter(
        (res) => res.ageGroup === filter
      );
    }
    return parsedResponse;
  }

  function getFrequencyKey(name?: string) {
    if (!name) return undefined;

    return name.toLowerCase();
  }

  async function getRankingInfo({
    name,
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = ['PROGRAMA_VISITACAO_DOMICILIAR', name ?? ''];
    const ageGroup = [name ? ageRecord[name] : ''];

    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ageGroupFilter: ageGroup,
      ibge,
      year,
      semester,
      zone
    });

    const key = getFrequencyKey(name);

    const result = response.data.map((d) => ({
      name: d.programa_visitacao_domiciliar,
      nofrequency: key ? d[key].toLowerCase().includes('não') : false,
      value: d.quantidade
    }));

    if (!!key) {
      return result.filter((res) => res.nofrequency);
    }

    return result;
  }

  async function getNurseryPeriod({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = ['PERIODO_MATRICULA_CRECHE'];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getPreschoolPeriod({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = ['PERIODO_MATRICULA_PRE_ESCOLA'];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getSchoolPeriod({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = ['PERIODO_MATRICULA_ESCOLA'];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getNurseryParentInterests({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = [
      'SE_NAO_PAIS_TEM_INTERESSE_MATRICULAR_CRECHE_PRE_ESCOLA',
      'CRECHE'
    ];
    const ageGroupFilter = [ageRecord['CRECHE']];

    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone,
      ageGroupFilter
    });

    return response.data
      .filter((data) => data[aggregations[1].toLowerCase()] === 'Não')
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade
      }));
  }

  async function getPreschoolParentInterests({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = [
      'SE_NAO_PAIS_TEM_INTERESSE_MATRICULAR_CRECHE_PRE_ESCOLA',
      'PRE_ESCOLA'
    ];
    const ageGroupFilter = [ageRecord['PRE_ESCOLA']];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone,
      ageGroupFilter
    });

    return response.data
      .filter((data) => data[aggregations[1].toLowerCase()] === 'Não')
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade
      }));
  }

  async function getSchoolParentInterests({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const aggregations = [
      'SE_NAO_PAIS_TEM_INTERESSE_MATRICULAR_CRECHE_PRE_ESCOLA',
      'ESCOLA'
    ];
    const ageGroupFilter = [ageRecord['ESCOLA']];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone,
      ageGroupFilter
    });

    return response.data
      .filter((data) => data[aggregations[1].toLowerCase()] === 'Não')
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade
      }));
  }

  async function getOutSchoolReason(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MOTIVO_CRIANCA_NAO_ESCOLA_CRECHE'];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getChildrenEnrolledPeriod({
    ibge,
    year,
    semester,
    zone
  }: EducationParams) {
    const path = '/criancas-matriculadas-periodo';

    const response = await callService(() => mainApi.get<any[]>(path, {
      params: {
        ibge,
        year,
        semester,
        zone
      }
    }))

    return response.data
  }

  return {
    getQuantitative,
    getQuantitativeByFilter,
    getCities,
    getRankingInfo,
    getNursery,
    getPreSchool,
    getSchool,
    getNurseryPeriod,
    getPreschoolPeriod,
    getSchoolPeriod,
    getNurseryParentInterests,
    getPreschoolParentInterests,
    getSchoolParentInterests,
    getOutSchoolReason,
    getTotalValue,
    getChildrenEnrolledPeriod
  };
};

export const education = service();
