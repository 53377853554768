import { cois } from '@/services';
import { DataWithCity } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

interface UseDataProps {
  codeIbge?: string;
  deedLine?: string;
  year?: string;
}

interface DataWithDeedline extends DataWithCity {
  deedLine: string;
}

export function useData({ codeIbge, deedLine, year }: UseDataProps) {
  const [data, setData] = useState<DataWithDeedline[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    cois
      .getCities(codeIbge, deedLine, year)
      .then((response) => setData(response))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [codeIbge, deedLine, year]);

  const agregatedFilterByDeedline = useMemo(
    () =>
      data.filter((item) => (!!deedLine ? item.deedLine === deedLine : true)),
    [data, deedLine]
  );

  return { data: agregatedFilterByDeedline, error, loading };
}
