import { termsLinks } from '@/pages/links';
import { Outlet } from 'react-router-dom';
import { Footer } from '../Footer';
import { HeaderAccessibility } from '../HeaderAccessibility';
import { HeaderApresentation } from '../HeaderApresentation';
import { Box } from './styles';

const links = termsLinks.map((link) => ({ ...link, isActive: false }));

export function Apresentation() {
  return (
    <Box>
      <div className="header">
        <HeaderAccessibility />
        <HeaderApresentation links={links} />
      </div>
      <Outlet />
      <Footer />
    </Box>
  );
}
