import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';

const dataObj = {
  sim: { name: 'Receberam prêmios', color: '#55A546' },
  não: { name: 'Não receberam prêmios', color: '#D4282A' }
};

export function Card9() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getHasAwards(ibge));
  }, [request, ibge]);

  const donutData = useMemo(() => {
    return data.map((d) => {
      const { color, name } = dataObj[d.name.toLowerCase() as 'sim' | 'não'];

      return {
        name,
        color,
        value: d.value
      };
    });
  }, [data]);

  return (
    <Card
      title="Organizações de apoio a mulheres com prêmios de reconhecimento"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <DonutPopulated
        data={donutData}
        thickness={40}
        percentage
        flexDirection="column"
        showPercentage
        donutHeight={300}
        donutWidth={300}
      />
    </Card>
  );
}
