import { ibgeApi } from '../config/api';
import { getRequestObject } from '../config/utils';

const getCentroid = (arr: number[][]) => {
  let twoTimesSignedArea = 0;
  let cxTimes6SignedArea = 0;
  let cyTimes6SignedArea = 0;

  const length = arr.length;

  const x = (i: number) => arr[i % length][0];
  const y = (i: number) => arr[i % length][1];

  for (let i = 0; i < length; i++) {
    const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
    twoTimesSignedArea += twoSA;
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
  }

  const sixSignedArea = 3 * twoTimesSignedArea;
  return [
    cyTimes6SignedArea / sixSignedArea,
    cxTimes6SignedArea / sixSignedArea
  ];
};

const service = () => {
  const geoJson = () =>
    getRequestObject(async (signal, codIbge) => {
      const path = `/v3/malhas/municipios`;
      const options = {
        params: {
          formato: 'application/vnd.geo+json'
        },
        signal
      };

      const response = await ibgeApi.get(`${path}/${codIbge}`, options);

      const { data } = response;

      const centroid = getCentroid(data.features[0].geometry.coordinates[0]);

      return { centroid, geoJson: data };
    });

  return Object.freeze({
    geoJson
  });
};

export const ibge = service();
