import { FilterAnalytic } from '@/components/shared';
import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { colors } from '@/theme/colors';
import { useTheme } from 'styled-components';

import { BeneficiariesCount } from './Count/BeneficiariesCount';
import { VisitsCount } from './Count/VisitsCount';
import { Filter } from './Filter';
import { IndicatorSection } from './IndicatorSection';
import { Box } from './styles';

const { Beneficiaries, BeneficiariesMap, Visited, VisitedKids } =
  IndicatorSection;

export function HomeVisitInsights() {
  const { darkMode } = useTheme();
  const mapColor = darkMode ? colors.ming100 : colors.ciano700;
  const barChartColor = darkMode ? colors.ming : colors.ciano700;

  return (
    <Box>
      <ThirdTitle>
        Indicadores dos beneficiados dos programas de visita domiciliares
      </ThirdTitle>
      <FilterAnalytic>
        <Filter />
      </FilterAnalytic>
      <div className="count-labels">
        <BeneficiariesCount />
        <VisitsCount />
      </div>

      <GroupBlockBox>
        <BeneficiariesMap color={mapColor} />
        <Beneficiaries color={barChartColor} />
      </GroupBlockBox>

      <GroupBlockBox>
        <Visited color={barChartColor} />
        <VisitedKids />
      </GroupBlockBox>
    </Box>
  );
}
