export const chartOptions: Record<string, any> = {
  'Assistência Social': [
    { label: 'CREAS', value: 0 },
    { label: 'CRAS', value: 1 }
  ],
  Saúde: [
    { label: 'Gestantes', value: 0 },
    { label: 'Vacina', value: 1 },
    { label: 'Assistência Médica', value: 2 }
  ],
  'Trabalho e Renda': [
    { label: 'Renda', value: 0 },
    { label: 'Trabalho', value: 1 }
  ],
  Educação: [
    { label: 'Creche', value: 0 },
    { label: 'Pré Escola', value: 1 },
    { label: 'Escola', value: 2 }
  ]
};
