import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${(props) => props.theme.background};

  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content 1fr;

  grid-template-areas:
    'menu acessibility'
    'menu content';

  margin-bottom: -20px;
  position: relative;

  overflow-x: auto;
  height: 100vh;

  .content {
    grid-area: content;
    grid-column: 2/3;

    padding: 2rem 1.5rem;

    max-width: 1440px;
    width: 100%;

    margin: 0 auto;
    position: relative;
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'acessibility'
      'menu'
      'content';

    .content {
      padding: 0.7rem;
      grid-column: initial;
    }
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 72px);

  .message {
    font-size: 1rem;
    font-weight: bold;
    color: ${({ theme }) =>
      theme.darkMode ? theme.yellowDark : colors.ciano700};
  }
`;
