import styled, { useTheme } from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';
import { colors } from '@/theme/colors';

export const ColumnText = () => {
  const theme = useTheme();

  const defaultText = () => <strong>Cartão Mais Infância</strong>;

  if (!theme.screen.large) {
    return (
      <WrapperSmall>
        <b>Quantidade de famílias beneficiadas com o {defaultText()}</b>
      </WrapperSmall>
    );
  }
  return (
    <WrapperBigScreen>
      <b>
        Acompanhe, em tempo real, a quantidade de famílias beneficiadas com o{' '}
        {defaultText()}
      </b>
      <p>
        O cartão é um benefício do Governo do Estado, destinado às famílias em
        situação de vulnerabilidade social, que tenham crianças de zero a 5 anos
        e 11 meses.
      </p>
    </WrapperBigScreen>
  );
};

const TextColorContainer = styled.div`
  color: ${(props) => (props.theme.darkMode ? `#fffa` : `#555`)};
  text-rendering: geometricPrecision;
  b {
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#0E243A`)};
  }

  strong {
    color: ${colors.green200};
  }
`;

const WrapperBigScreen = styled(TextColorContainer)`
  width: 30%;
  font-size: 1.5rem;
  b {
    font-size: 1.2em;
    line-height: 1.3;
  }
  p {
    padding: 5px 0;
    font-size: 1.2rem;
  }
`;

const WrapperSmall = styled(TextColorContainer)`
  padding: 10px 0 30px 0;
  font-size: 18px;
  line-height: 1.3;
  b {
    font-size: 1.2em;
    line-height: 1.1;
  }
  ${mediaQuery.mediumScreen} {
    br {
      display: none;
    }
  }
`;
