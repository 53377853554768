import L from 'leaflet';
import {
  isHealth,
  isIncome,
  isInsecurityFood,
  isSocialAssistence,
  isWork
} from '../../utils/functions';

function getIconClassName(key: string) {
  if (isInsecurityFood(key)) {
    return `marker-cluster insecurity-food-marker-cluster`;
  }

  if (isSocialAssistence(key)) {
    return `marker-cluster social-assistance-marker-cluster`;
  }

  if (isHealth(key)) return `marker-cluster health-marker-cluster`;
  if (isIncome(key)) return `marker-cluster income-marker-cluster`;
  if (isWork(key)) return `marker-cluster work-marker-cluster`;

  return `marker-cluster education-marker-cluster`;
}

export function getClusterIcon(key: string) {
  const className = getIconClassName(key);

  return (cluster: any) => {
    const values = cluster
      .getAllChildMarkers()
      .map((marker: any) => Number(marker.options.title))
      .reduce((acc: number, cur: number) => acc + cur, 0);

    return L.divIcon({
      html: `<span>${values}</span>`,
      className,
      iconSize: L.point(33, 33, true)
    });
  };
}

export function getUnitIcon(key: string) {
  const className = getIconClassName(key);

  return (value: number) => {
    return L.divIcon({
      html: `<span>${value}</span>`,
      className,
      iconSize: L.point(33, 33, true)
    });
  };
}
