import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  .content-top {
    margin: 1rem;
    .downloadButton {
      background: ${(props) =>
        props.theme.darkMode ? '#000' : colors.ciano700};
      border-radius: 7px;
      width: 180px;
      height: fit-content;
      border: 1px solid #dfe6e9;
      justify-content: center;
      padding: 0.3rem 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      svg {
        fill: #fff;
      }

      span {
        color: #fff;
        font-weight: 700;
      }
    }
  }
`;
