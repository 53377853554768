import { ReactElement, useState } from 'react';
import { Select as AntdSelect } from 'antd';
import { Dropdown } from './Dropdown';

import { Box, Select } from './style';

interface SearchValues {
  region: string;
  district: string;
  neighborhood: string;
}

interface OptionsValues {
  region: string[];
  district: string[];
  neighborhood: string[];
}

interface LocalsProps {
  onChange?: (values: OptionsValues) => void;
}

const { Option } = AntdSelect;

const initialSearch: SearchValues = {
  region: '',
  district: '',
  neighborhood: ''
};

const initialOptions: OptionsValues = {
  region: [],
  district: [],
  neighborhood: []
};

export function Locals({ onChange }: LocalsProps) {
  const [values, setValues] = useState(initialOptions);
  const [options] = useState(initialOptions);
  const [search, setSearch] = useState(initialSearch);

  const selectProps = {
    mode: 'multiple' as const,
    maxTagCount: 'responsive' as const,
    allowClear: true,
    showArrow: true
  };

  function filter(options: string[], search: string) {
    if (search === '') return options;

    return options.filter((opt) => opt === search);
  }

  function handleSearch(v: string, type: keyof SearchValues) {
    setSearch((s) => ({ ...s, [type]: v }));
  }

  function handleChange(v: string[], type: keyof SearchValues) {
    setValues((value) => {
      const modified = { ...value, [type]: v };

      onChange && onChange(modified);

      return modified;
    });
  }

  function renderOptions(type: keyof SearchValues) {
    const s = search[type];
    const opts = options[type];

    return filter(opts, s).map((item) => (
      <Option key={item} value={item}>
        {item}
      </Option>
    ));
  }

  function renderRegionDropdown(menu: ReactElement) {
    return <Dropdown menu={menu} onSearch={(v) => handleSearch(v, 'region')} />;
  }

  function renderDistrictDropdown(menu: ReactElement) {
    return (
      <Dropdown menu={menu} onSearch={(v) => handleSearch(v, 'district')} />
    );
  }

  function renderNeighborhoodDropdown(menu: ReactElement) {
    return (
      <Dropdown menu={menu} onSearch={(v) => handleSearch(v, 'neighborhood')} />
    );
  }

  return (
    <Box>
      <Select
        {...selectProps}
        placeholder="Selecione as regiões"
        dropdownRender={renderRegionDropdown}
        value={values.region}
        onChange={(value) => handleChange(value as string[], 'region')}
      >
        {renderOptions('region')}
      </Select>

      <Select
        {...selectProps}
        placeholder="Selecione os distritos"
        dropdownRender={renderDistrictDropdown}
        value={values.district}
        onChange={(value) => handleChange(value as string[], 'district')}
      >
        {renderOptions('district')}
      </Select>

      <Select
        {...selectProps}
        placeholder="Selecione os bairros"
        dropdownRender={renderNeighborhoodDropdown}
        value={values.neighborhood}
        onChange={(value) => handleChange(value as string[], 'neighborhood')}
      >
        {renderOptions('neighborhood')}
      </Select>
    </Box>
  );
}
