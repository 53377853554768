import { FiMenu } from 'react-icons/fi';

import { Box } from './styles';

interface OpenDrawerProps {
  onOpen(): void;
}

export function OpenDrawer({ onOpen }: OpenDrawerProps) {
  return (
    <Box onClick={onOpen}>
      <FiMenu color="#fff" size={24} />
      <span>MENU</span>
    </Box>
  );
}
