import { useMemo } from 'react';

import { useAnalyticsTitle } from '@/hooks';
import { ApresentationCard, LastUpdateMessage } from '@/components/shared';

import { CoisInsights, CountLabel } from '../components';
import { useLastUpdate, useTotalCoisData } from '../hooks';
import { Box } from './styles';

export function Cois() {
  const { lastUpdate, loading, error } = useLastUpdate('cois');
  useAnalyticsTitle('Beneficiados com Cursos de Capacitação');

  const { totalData, totalLoading, totalError } = useTotalCoisData();

  const totalDeedLines = useMemo(
    () => totalData.reduce((acc, cur) => acc + Number(cur.value), 0),
    [totalData]
  );

  return (
    <Box>
      <LastUpdateMessage
        value={lastUpdate?.insercao}
        loading={loading}
        error={error}
      />

      <ApresentationCard
        title="Número de beneficiados"
        source="Secretaria de Proteção Social (2015 a 2020)"
        text="Os cursos promovidos pela Secretaria de Proteção Social (SPS) fazem parte das seguintes linhas de ações: Jovem Aprendiz, Jovem Bolsista, Jovem Estagiário (1 ano), Jovem Estagiário TRE, Jovem Estagiário 6 meses, CITS, Qualificação Profissional (QP), Capacitação Profissional (CP), Oficinas de Aperfeiçoamento (OF. de AP.), Oficinas de Inclusão Produtiva (OF, de INC.), Atividades Socio Esportivas (ATIV.SOCIO.), Criando Oportunidades, Transformando Vidas, Centro de Profissionalização Inclusiva para Pessoas com Deficiência (CEPID), Escola de Vida, Sabor e Arte (EVISA - 2009 até 2018) e Programa Garantindo Acessibilidade (2009 até 2016)."
      >
        <CountLabel
          value={totalDeedLines}
          loading={totalLoading}
          error={totalError}
        />
      </ApresentationCard>
      <CoisInsights />
    </Box>
  );
}
