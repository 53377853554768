import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 6rem;
  color: ${(props) => (props.theme.darkMode ? `#fffa` : `#555`)};

  .chart-box {
    display: grid;
    align-items: center;

    svg {
      filter: ${({ theme }) =>
        theme.darkMode ? `brightness(0) invert(1)` : ''};
    }
  }
`;

export const Text = styled.div`
  font-weight: 700;
  line-height: 25px;
  padding: 2rem;
  span {
    font-size: 1.4rem;
    color: ${(props) => (props.theme.darkMode ? `#fff` : colors.ciano700)};
  }

  p {
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#000`)};
    font-size: 1rem;
    font-weight: 700;
  }
`;
