import { Outlet } from 'react-router-dom';

import { BigDataRoute } from '@/types/routes';
import { Analytics } from '@/layout/Analytics';
import {
  BdsAid,
  BdsAnalytics,
  BdsCzrm,
  BdsEducation2,
  BdsLivingCondition,
  BdsSocialMovements,
  BdsWomanMapping
} from '@/bdsIcons';

import {
  AdultSchooling,
  Childhood,
  Cois,
  Home,
  HomeVisit,
  LivingCondition,
  // TicketGas
} from './Analytics';
import { SocialMovements } from './YearBook';
import { Lgbt } from './Lgbt';
import { BdsLgbtqiaplus } from '@/bdsIcons/BdsLgbtqiaplus';
import { showRouteByEnv } from '@/utils/routes';
import { WomenMappingAnalytics } from './WomenMappingAnalytics';

const SOCIAL_HELP = 'Auxílios Sociais';

export const routesAnalytics: BigDataRoute[] = [
  {
    Element: () => <Analytics />,
    name: 'Dados',
    iconImg: () => null,
    path: `/analiticos`,
    children: [
      {
        iconImg: (props) => <BdsAnalytics {...props} />,
        name: 'Analíticos e Anuários',
        Element: () => <Home />
      },
      {
        Element: () => <Outlet />,
        name: 'Auxílios',
        iconImg: (props) => <BdsAid color="white" {...props} />,
        description:
          'Nesta seção são apresentados os indicadores sociais sobre os beneficiários de programas  de transferência de renda e medidas de auxílio financeiras a famílias socialmente vulneráveis no Ceará. Os inscritos em auxílios sociais recebem valores monetários periódicos, e cada iniciativa possui regras específicas que precisam ser atendidas para que o valor seja concedido.',
        path: `auxilios`,
        children: [
          {
            Element: () => <Childhood />,
            name: 'Cartão Mais Infância',
            iconImg: (props) => null,
            path: `cartao-mais-infancia`,
            description:
              'Dados sobre as famílias beneficiadas com o Cartão Mais Infância de acordo com a população do Estado do Ceará cadastrada no Cadastro Único para Programas Sociais (CadUNICO).',
            category: SOCIAL_HELP
          },
          // {
          //   path: `vale-gas`,
          //   Element: () => <TicketGas />,
          //   name: 'Vale Gás',
          //   iconImg: (props) => null,
          //   description:
          //     'São contempladas as famílias do Cartão Mais Infância, os jovens do programa Superação e as famílias beneficiárias do Auxílio Brasil que possuem renda per capita até R$ 150.',
          //   category: SOCIAL_HELP
          // },
          {
            path: `visitas-domiciliares`,
            Element: () => <HomeVisit />,
            name: 'Visitas Domiciliares',
            iconImg: (props) => null,
            description:
              'O Programa Mais Infância Ceará, por meio do Padin, da SEDUC, e do Primeira Infância no SUAS / Criança Feliz, do Governo Federal, beneficia famílias com crianças na primeira infância no Ceará.',
            category: SOCIAL_HELP
          }
        ]
      },
      {
        Element: () => <Outlet />,
        name: 'Educação',
        iconImg: (props) => <BdsEducation2 color="white" {...props} />,
        description:
          'Nesta seção são apresentados os principais indicadores sobre o perfil dos participantes dos programas de capacitação promovidos pela Secretaria da Proteção Social, Justiça, Cidadania, Mulheres e Direitos Humanos (SPS), além de dados sobre o nível de escolaridade e a taxa de analfabetismo entre adultos mapeados através do Cadastro Único para Programas Sociais (CadÚnico).',
        path: 'educacao',
        children: [
          {
            Element: () => <Cois />,
            name: 'Capacitação',
            iconImg: (props) => null,
            path: 'capacitacao',
            description:
              'O Programa Mais Infância Ceará, por meio do Padin, da SEDUC, e do Primeira Infância no SUAS / Criança Feliz, do Governo Federal, beneficia famílias com crianças na primeira infância no Ceará.',
            category: 'Educação'
          },
          {
            path: `escolaridade-adulto`,
            Element: () => <AdultSchooling />,
            name: 'Escolaridade Adulto',
            iconImg: (props) => null,
            description:
              'Dados acerca do grau de escolaridade e números sobre analfabetismo entre adultos do Estado do Ceará inseridos no Cadastro Único para Programas Sociais (CadUNICO).',
            category: 'Educação'
          }
        ]
      },
      {
        path: 'moradia',
        Element: () => (
          <>
            <Outlet />
            <LivingCondition />
          </>
        ),
        name: 'Moradia',
        iconImg: (props) => <BdsLivingCondition color="white" {...props} />,
        description:
          'Nesta seção são apresentados os indicadores sobre as condições de moradia, habitação e modos de morar da população cearense cadastrada no CadÚnico (Cadastro Único para Programas Sociais).',
        category: 'Moradia'
      },
      {
        path: 'anuario',
        Element: () => (
          <>
            <Outlet />
            <SocialMovements />
          </>
        ),
        name: 'Anuário dos Movimentos Sociais',
        iconImg: (props) => <BdsSocialMovements {...props} />,
        description:
          'Nesta seção são apresentados os indicadores sobre as condições de moradia, habitação e modos de morar da população cearense cadastrada no CadÚnico (Cadastro Único para Programas Sociais).',
        category: 'ASEMOV'
      },
      {
        ...showRouteByEnv(process.env.REACT_APP_SHOW_LGBT_PAGE!, {
          Element: () => (
            <>
              <Outlet />
              <Lgbt />
            </>
          ),
          iconImg: (props) => <BdsLgbtqiaplus {...props} />,
          name: 'População LGBTQIA+ no Ceará',
          description:
            'Pesquisa Estadual da População LGBTQIA+ do Ceará, idealizada a partir das diferentes demandas do público-alvo e pensada para melhor conhecer a população.',
          path: 'lgbtqia+',
          category: 'População LGBTQIA+'
        })
      },
      {
        ...showRouteByEnv(process.env.REACT_APP_SHOW_WOMAN_MAPPING_PAGE!, {
          name: 'Mapeamento da Rede de Apoio a Mulheres no Ceará',
          iconImg: (props) => <BdsWomanMapping {...props} />,
          path: 'mapeamento-de-apoio-as-mulheres-ceara',
          Element: () => <WomenMappingAnalytics />,
          description:
            'Pesquisa pensada para planejar, realizar políticas e ações públicas mais eficientes, voltadas para o enfrentamento da violência, a promoção da igualdade de gênero, o empoderamento da mulher e sua autonomia econômica. '
        })
      },
      {
        link: `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}`,
        name: 'Integra Social',
        iconImg: (props) => <BdsCzrm {...props} />,
        Element: () => null
      }
    ]
  }
];
