import { Data } from 'big-data';

import { ensureAxiosParamOptions } from '@/utils/routes';
import { filterInsecurityFoodData } from '@/utils/data';

import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

function getFilterCity(filter?: string) {
  if (!filter) return '';

  const formatedFilter = filter;

  return `city=${formatedFilter}`;
}

const service = () => {
  const resource = `inseguranca-alimentar`;

  function getFilter(filter?: string) {
    if (!filter) return '';

    const formatedFilter = filter
      .replace('ç', 'c')
      .toUpperCase()
      .replaceAll(' ', '_');

    return `classificationFilter=${formatedFilter}`;
  }

  function formatClassification(classification?: string) {
    return classification?.replace('ç', 'c').replaceAll(' ', '_').toUpperCase();
  }

  async function getAllCities(
    city?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MUNICIPIO'];
    const path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        city,
        year,
        semester,
        aggregations: getQuery(aggregations),
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    if (response.status === 204) return [];

    return response.data.map<Data>((d) => ({
      name: d.municipio,
      value: d.quantidade
    }));
  }

  async function getAllCitiesThermometerValue(
    city?: string,
    year?: string,
    semester?: string,
    zone?: string,
  ) {
    const aggregations = ['MUNICIPIO'];
    const path = "visitas-domiciliares-agentes";

    const options = ensureAxiosParamOptions({
      params: {
        city,
        year,
        semester,
        aggregations: getQuery(aggregations),
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    if (response.status === 204) return [];

    return response.data.map<Data>((d) => ({
      name: d.municipio,
      value: d.quantidade
    }));
  }

  async function getByClassification(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CLASSIFICACAO'];
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        ibge,
        aggregations: getQuery(aggregations),
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    if (response.status === 204) return [];

    return response.data.map<Data>((d) => ({
      name: d.classificacao,
      value: d.quantidade
    }));
  }

  async function getTotal() {
    const path = `${resource}`;

    const response = await callService(() => mainApi.get<any[]>(path));
    const { quantidade } = response.data[0];

    return quantidade;
  }

  async function getPandemicImpact(filter?: string, city?: string) {
    const agrregations = ['DISPONIBILIDADE_ALIMENTO_POS_COVID'];
    let path = `${resource}?aggregations=${agrregations.join(',')}`;
    if (city) {
      const filterQuery = getFilterCity(city);
      path = path + `&${filterQuery}`;
    }

    if (filter) {
      const filterQuery = getFilter(filter);
      path = path + `&${filterQuery}`;
    }
    const response = await callService(() => mainApi.get<any[]>(path));

    const decreaseImpact = response.data.find(
      (d) =>
        d.disponibilidade_alimento_pos_covid.toLowerCase() === 'sim, diminuiu'
    );

    return decreaseImpact?.quantidade ?? 0;
  }

  async function getFiveBiggestByCity(classification: string) {
    const aggregations = ['MUNICIPIO', 'CLASSIFICACAO'];
    const path = `${resource}?aggregations=${aggregations.join(',')}`;
    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter(
        (d) => d.classificacao.toLowerCase() === classification.toLowerCase()
      )
      .sort((a, b) => b.quantidade - a.quantidade)
      .map<Data>((d) => ({ name: d.municipio, value: d.quantidade }))
      .slice(0, 10);
  }

  async function getAnimalsConsume(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string
  ) {
    const aggregations = ['CLASSIFICACAO', 'CRIA_ANIMAIS_CONSUMO'];
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations: getQuery(aggregations),
        ibge,
        classificationFilter: formatClassification(classification)
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((data) => ({
      name: data.cria_animais_consumo,
      value: data.quantidade,
      classification: data.classificacao
    }));
  }

  async function getPlantsConsume(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CLASSIFICACAO', 'PLANTA_ALIMENTOS_CONSUMO'];
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations: getQuery(aggregations),
        ibge,
        classificationFilter: formatClassification(classification),
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.map((data) => ({
      name: data.planta_alimentos_consumo,
      value: data.quantidade,
      classification: data.classificacao
    }));
  }

  async function getCities(
    classification?: string,
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MUNICIPIO', 'CLASSIFICACAO', 'IBGE'];
    let path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        year,
        semester,
        aggregations: getQuery(aggregations),
        ibge,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    const data = response.data.map((data) => ({
      name: data.municipio,
      value: data.quantidade,
      codIbge: data.ibge,
      classification: data.classificacao
    }));

    return filterInsecurityFoodData(data, classification);
  }

  return {
    getByClassification,
    getTotal,
    getPandemicImpact,
    getFiveBiggestByCity,
    getCities,
    getAllCities,
    getAnimalsConsume,
    getPlantsConsume,
    getAllCitiesThermometerValue
  };
};

export const foodInsecurity = service();
