import { ReactNode } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import 'moment/locale/pt-br';
import localeBr from 'antd/lib/locale/pt_BR';

import { Container, DropdownContainer, StyledButton } from './style';

interface FilterProps {
  children: ReactNode;
  loading: boolean;
  onEraseFilter: () => void;
  width?: string;
  hasDateFilter?: boolean;
}

const { RangePicker } = DatePicker;

export function Filter({
  onEraseFilter,
  loading = false,
  width = '',
  hasDateFilter,
  children
}: FilterProps) {
  return (
    <ConfigProvider locale={localeBr}>
      <Container width={width ?? '100%'}>
        <DropdownContainer className="filter-select-container">
          {children}
          {hasDateFilter && (
            <RangePicker
              format="DD/MM/YYYY"
              style={{ minWidth: 200, alignSelf: 'flex-end' }}
            />
          )}
        </DropdownContainer>

        <StyledButton
          type="link"
          style={{
            alignSelf: 'flex-start',
            color: '#00B894',
            padding: 0
          }}
          onClick={onEraseFilter}
        >
          Limpar
        </StyledButton>
      </Container>
    </ConfigProvider>
  );
}
