import { Action, ActionRegister } from 'big-data';
import * as R from 'ramda';

import { odsDetails } from '@/constants/ods';
import { actions } from '@/constants/actions';
import { actions as actionsService } from '@/services';
import { capitalize } from '@/utils/string';
import { ServiceBox2 } from '@/components/shared';

import { Box, Label, Answer, Horizontal, DownloadButton } from './styles';
import { Title3 } from '../styles';
import dayjs from 'dayjs';

interface ForumDetailsProps {
  details: Action;
  loading?: boolean;
  error?: string;
}

const getOds = R.curry((actions: ActionRegister[], axle?: string) =>
  R.pipe(
    R.find<ActionRegister>((action) => action.name.toUpperCase() === axle),
    (action?: ActionRegister) => action?.ods ?? ([] as number[]),
    R.map((odsId) => odsDetails[odsId])
  )(actions)
);

const getOdsByAction = getOds(actions);

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  maximumFractionDigits: 2
});

export function ForumDetails({ details, loading, error }: ForumDetailsProps) {
  const ods = getOdsByAction(details.axle.toUpperCase());

  const goal = formatter.format(details.goal / 100);
  const currentValue = formatter.format((details.actualValue ?? 0) / 100);
  const indicator = capitalize(details.indicator);

  function handleDownloadFile() {
    const { id } = details;

    actionsService.getActionFile(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');

      const today = dayjs().format('DD-MM-YYYY_HH-mm-ss-SSS');

      link.setAttribute('href', url);
      link.setAttribute('download', `anexo-${today}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  return (
    <Box>
      <Title3>Detalhes do plano de ação</Title3>

      <ServiceBox2 empty={R.isEmpty(details)} loading={loading} error={error}>
        <div className="forum-details-body">
          <ul className="ods">
            {ods.map((value) => (
              <li key={value.id}>
                <img src={value.img} alt={value.description} />
                <span>{value.name}</span>
              </li>
            ))}
          </ul>

          <Horizontal>
            <div>
              <Label>Indicador:</Label>
              <Answer>{indicator}</Answer>
            </div>

            <div>
              <Label>Quantos % o indicador está atualmente?</Label>
              <Answer>{details.actualValue ? currentValue : '-'}</Answer>
            </div>
          </Horizontal>

          <Label>Onde o gestor deseja que o indicador chegue?</Label>
          <Answer>{goal}</Answer>

          <Label>Em qual semestre o gestor pretende atingir essa meta?</Label>
          <Answer>
            {details.yearDeadline}.{details.semesterDeadline}
          </Answer>

          <Label>Detalhes do plano de ação</Label>
          <Answer>{details.description}</Answer>

          <Label>Anexos:</Label>
          <DownloadButton
            onClick={handleDownloadFile}
            disabled={!details.actionPlanFilePath}
          >
            {details.actionPlanFilePath ? 'Anexo.pdf' : 'Nenhum anexo!'}
          </DownloadButton>
        </div>
      </ServiceBox2>
    </Box>
  );
}
