import {
  ApresentationCard,
  LastUpdateMessage,
  TextIcon
} from '@/components/shared';
import { useAnalyticsTitle } from '@/hooks';
import { Tabs } from 'antd';
import { IoMdAlert } from 'react-icons/io';

import {
  EducationData,
  Filter,
  GeneralData,
  HealthData,
  IncomeJobData,
  LgbtPhobiaData,
  SocialAssistanceData
} from './components';
import { TotalPopulationInterviewed } from './components/TotalPopulationInterviewed';
import { FilterContextProvider } from './contexts/FilterContext';
import { Alert, Box } from './styles';

const { TabPane } = Tabs;

const text = () => (
  <p>
    Conheça nesta página os dados da primeira Pesquisa Estadual de Mapeamento da
    População LGBTQIA+ nos 184 municípios cearenses (ano 2022). Esses dados
    foram coletados entre dezembro de 2021 e maio de 2022. São importantes no
    desenvolvimento de políticas públicas para um Ceará cada vez mais diverso.
  </p>
);

const alertText =
  'Os dados apresentados estão restritos ao público que respondeu à pesquisa, não representando, necessariamente, a realidade da população LGBTQIA+ do estado do Ceará.';

export function Lgbt() {
  useAnalyticsTitle('População LGBTQIA+ no Ceará');

  return (
    <Box>
      <FilterContextProvider>
        <LastUpdateMessage
          message="Ano de referência: "
          year="2022"
          loading={false}
          error={null}
        />
        <ApresentationCard>
          <TotalPopulationInterviewed />
          <TextIcon text={text} />
          <Alert>
            <IoMdAlert size={20} />

            <span>{alertText}</span>
          </Alert>
        </ApresentationCard>
        <div className="tabs">
          <Tabs
            tabBarExtraContent={{
              right: <Filter />
            }}
            destroyInactiveTabPane
          >
            <TabPane tab="Dados gerais" key={1}>
              <GeneralData />
            </TabPane>
            <TabPane tab="Educação" key={2}>
              <EducationData />
            </TabPane>
            <TabPane tab="Trabalho e Renda" key={3}>
              <IncomeJobData />
            </TabPane>
            <TabPane tab="Saúde" key={4}>
              <HealthData />
            </TabPane>
            <TabPane tab="Assistência Social" key={5}>
              <SocialAssistanceData />
            </TabPane>
            <TabPane tab="LGBTfobia" key={6}>
              <LgbtPhobiaData />
            </TabPane>
          </Tabs>
        </div>
      </FilterContextProvider>
    </Box>
  );
}
