import { useState } from 'react';

import { getLegendTitle, RefinedData } from '../ActionChart/utils';
import { Donut, DonutData } from '../Donut';
import { Legend } from '../Legend';

import { Box } from './styles';

interface DonutGraphProps {
  data: RefinedData[];
  axle: string | null;
}

export function DonutGraph({ data, axle }: DonutGraphProps) {
  const dataType = data && data.length > 0 ? data[0].type : '';
  const legendTitle = getLegendTitle(axle, dataType);
  const [selectedArcDonut, setSelectedArcDonut] = useState<DonutData>();

  function handleDonutClick(data: DonutData) {
    setSelectedArcDonut((current) =>
      current?.name === data.name ? undefined : data
    );
  }

  return (
    <Box>
      <div className="donut-content">
        <Donut
          data={data}
          width={200}
          selectedData={selectedArcDonut}
          onClick={handleDonutClick}
        />
      </div>

      <Legend
        data={data}
        title={legendTitle}
        selected={selectedArcDonut}
        onClick={handleDonutClick}
      />
    </Box>
  );
}
