import { CardTagged, HorizontalGridBar, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantExams } from '@/servicesHooks/healthPregnant';
import { colors } from '@/theme/colors';
import { Legend, LegendContainer, Margin } from '@/theme/utils';
import { useTheme } from 'styled-components';

export function CardPregnantExamsBar() {
  const { darkMode } = useTheme();
  const { tag } = useDashboardFilter();

  const { data, ...rest } = usePregnantExams();

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a porcentagem de exames que as grávidas que responderam a
          pesquisa mais realizaram durante a gestação?
        </TitleText>

        <Margin top={50} />
        <HorizontalGridBar
          data={data}
          grid
          isPercentage
          percentageCalculatedOut
          color={darkMode ? colors.ming : colors.ciano700}
          showEntireData
          sizeRatio={data.length / 10 - 0.2}
        />
        <LegendContainer>
          <Legend>
            Uma gestante pode ter realizado um ou mais exames durante a gestação.
          </Legend>
        </LegendContainer>
      </ServiceBox>
    </CardTagged>
  );
}
