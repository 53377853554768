import { ImpactsDetails } from '../ImpactsDetails';
import { ImpactsMap } from '../ImpactsMap';
import { Box } from './styles';

export function Impacts() {
  return (
    <Box>
      <ImpactsDetails />
      <ImpactsMap />
    </Box>
  );
}
