import { ReactNode } from 'react';
import { AnimatedTextFadeIn } from '@/layout';

import { Box } from './BenefitsCard.styles';

interface BenefitsCardProps {
  amount: string;
  prefix?: string;
  text: string;
  children: ReactNode;
}

export function BenefitsCard({
  amount,
  prefix,
  text,
  children
}: BenefitsCardProps) {
  return (
    <Box>
      <AnimatedTextFadeIn
        title={amount}
        text={text}
        prefix={prefix}
        delay={0}
      />
      {children}
    </Box>
  );
}
