import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services/profileSearch';
import { DataIbge } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useInterviewedByCities() {
  const [interviewedCities, setInterviewedCities] = useState<DataIbge[]>([]);
  const [loadingInterviewedCities, setLoadingInterviewedCities] =
    useState(false);
  const [errorInterviewedCities, setErrorInterviewedCities] =
    useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingInterviewedCities(true);
    profileSearch
      .getInterviewedByCity(ibge, year, semester, zone)
      .then((data) => {
        return [...data].sort((a, b) => b.value - a.value);
      })
      .then((data) => setInterviewedCities(data))
      .catch((err) => setErrorInterviewedCities(err))
      .finally(() => setLoadingInterviewedCities(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      interviewedCities,
      loadingInterviewedCities,
      errorInterviewedCities
    }),
    [interviewedCities, loadingInterviewedCities, errorInterviewedCities]
  );
}
