import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { Attendance } from './Attendance';
import { CadUnico } from './CadUnico';
import { HumanAttendance } from './HumanAttendance';
import { MainServices } from './MainServices';
import { RespectSocial } from './RespectSocial';
import { ServiceAcess } from './ServiceAcess';
import { Box } from './style';

export function SocialAssistanceData() {
  return (
    <Box>
      <ThirdTitle>Assistência social da população LGBTQIA+</ThirdTitle>
      <GroupBlockBox>
        <CadUnico />
        <ServiceAcess />
      </GroupBlockBox>
      <GroupBlockBox>
        <MainServices />
        <Attendance />
      </GroupBlockBox>
      <GroupBlockBox>
        <HumanAttendance />
        <RespectSocial />
      </GroupBlockBox>
    </Box>
  );
}
