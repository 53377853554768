import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useGeneral } from '@/services/lgbt/general';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';

const columns = [
  {
    name: 'Documento',
    key: 'name'
  },
  {
    name: 'Percentual',
    key: 'value'
  }
];

export function Documents() {
  const { ibge, orientation, city, identity } = useFilterContext();
  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useGeneral.documents({ ibge, orientation, genderIdentity: identity })
    );
  }, [request, ibge, orientation, identity]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return { ...d, value: (d.value + '%').replaceAll('.', ',') };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Documentos da população LGBTQIA+ entrevistada"
        loading={loading}
        description="A população pode ter mais de um dos documentos listados."
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <ListTable
          numberValues
          pageSize={7}
          columnTitle={columns}
          data={formattedData}
        />
      </Card>
    </Section>
  );
}
