import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { HasJob } from './HasJob';
import { JobActions } from './JobActions';
import { JobAreas } from './JobAreas';
import { JobChallenges } from './JobChallenges';
import { JobLifeCovid } from './JobLifeCovid';
import { JobType } from './JobType';
import { MonthlyIncome } from './MonthlyIncome';
import { Box } from './style';

export function IncomeJobData() {
  return (
    <Box>
      <ThirdTitle>
        Condições de trabalho e renda da população LGBTQIA+
      </ThirdTitle>
      <GroupBlockBox>
        <HasJob />
        <JobType />
      </GroupBlockBox>
      <GroupBlockBox>
        <JobChallenges />
        <MonthlyIncome />
      </GroupBlockBox>
      <GroupBlockBox>
        <JobLifeCovid />
        <JobAreas />
      </GroupBlockBox>
      <GroupBlockBox>
        <JobActions />
      </GroupBlockBox>
    </Box>
  );
}
