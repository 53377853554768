import { Show } from '@/components/shared';
import { geolocation } from '@/services';
import { GeoJSONCentroid } from '@/services/geolocation';
import { LatLngTuple } from 'leaflet';
import { useEffect, useState } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';

interface GeoJSONCityProps {
  codIbge: string;
  loading?: boolean;
}

const geoJsonLayerStyle = {
  fill: false,
  weight: 2,
  color: '#ab1e11'
};

export function GeoJSONCity({ codIbge, loading }: GeoJSONCityProps) {
  const map = useMap();

  const [geoJson, setGeoJson] = useState({} as GeoJSONCentroid);
  const [loadingGeoJson, setLoadingGeoJson] = useState(false);

  useEffect(() => {
    if (!codIbge) return;

    const geoJsonApi = geolocation.ibge.geoJson();

    setLoadingGeoJson(true);
    geoJsonApi
      .request(codIbge)
      .then((data) => {
        map.flyTo(data.centroid as LatLngTuple, 10);
        setGeoJson(data);
      })
      .finally(() => setLoadingGeoJson(false));

    return () => geoJsonApi.abort();
  }, [map, codIbge]);

  return (
    <Show when={!loading && !loadingGeoJson && !!codIbge}>
      <GeoJSON data={geoJson.geoJson} style={geoJsonLayerStyle} />
    </Show>
  );
}
