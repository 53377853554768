import { livingConditionAgents } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

function getFamiliesByTotal(total: number, data: Data[]) {
  return data.map((d) => ({
    ...d,
    value: (d.value / total) * 100
  }));
}

export function useHousingConditionData(total: number) {
  const [zone, setZone] = useState<Data[]>();
  const [houseCondition, setHouseCondition] = useState<Data[]>();
  const [areaCondition, setAreaCondition] = useState<Data[]>();
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (total !== 0) {
      const promises = [
        livingConditionAgents.getZone(),
        livingConditionAgents.getHouseType(),
        livingConditionAgents.getHouseViolenceSituation()
      ];
      setLoadingPerfil(true);
      Promise.all(promises)
        .then(([zone, house, situation]) => {
          const houseT = getFamiliesByTotal(total, house);

          const zoneFilter = zone.filter(
            (d) => d.name === 'Rural' || d.name === 'Urbana'
          );
          const zoneT = getFamiliesByTotal(total, zoneFilter);

          const situationFilter = situation.filter((d) => d.name === 'Sim');
          const situationT = getFamiliesByTotal(total, situationFilter);

          setZone(zoneT);
          setHouseCondition(houseT);
          setAreaCondition(situationT);
        })
        .catch((err) => setError(err))
        .finally(() => setLoadingPerfil(false));
    }
  }, [total]);

  return useMemo(
    () => ({
      areaCondition,
      houseCondition,
      loadingPerfil,
      error,
      zone
    }),
    [areaCondition, houseCondition, loadingPerfil, error, zone]
  );
}
