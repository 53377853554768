export function SearchIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7066 12.7301C7.99931 15.4475 3.78046 14.3033 1.6584 11.7209C0.535976 10.3755 -0.0532519 8.66556 0.00238893 6.9152C0.0580298 5.16484 0.754672 3.49569 1.96029 2.22407C4.37918 -0.359554 8.42373 -0.731708 11.3416 1.31703C13.9247 3.13112 15.7928 7.4872 12.7815 11.6932L17.5562 15.5055C17.8353 15.7276 18.1182 15.9471 18.3974 16.1704C19.2323 16.8377 19.1919 17.9693 18.3128 18.6228C17.5372 19.198 16.797 19.1135 16.2134 18.3705L11.7066 12.7301ZM12.9078 7.15668C12.9101 6.02267 12.5756 4.91345 11.9466 3.96931C11.3177 3.02517 10.4225 2.2885 9.37437 1.85246C8.32622 1.41642 7.17214 1.30059 6.05807 1.51962C4.94401 1.73865 3.91999 2.28271 3.11552 3.08299C2.31105 3.88327 1.76225 4.90383 1.53853 6.01562C1.31481 7.1274 1.42622 8.28047 1.85866 9.32903C2.29109 10.3776 3.02514 11.2745 3.96798 11.9064C4.91082 12.5383 6.0201 12.8768 7.15554 12.879C8.6766 12.877 10.1351 12.2742 11.2128 11.2022C12.2905 10.1301 12.8998 8.6758 12.9078 7.15668Z"
        fill="white"
      />
    </svg>
  );
}
