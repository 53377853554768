import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const Box = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 600px;

  @media (max-width: 1318px) {
    justify-content: center;
    max-width: unset;
  }

  ${mediaQuery.smallScreen} {
    gap: 1.2rem;
  }

  li {
    width: 150px;
    height: 150px;
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.white};
    border-radius: 7px;
    transition: filter 0.2s;

    a {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image-box {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
        background-color: ${colors.green600 + '1a'};
      }

      .image-box svg {
        width: 24px;
        height: 24px;
      }

      .label {
        color: ${colors.green700};
        text-align: center;
        height: 45px;

        display: flex;
        align-items: center;
      }
    }

    &:hover {
      filter: brightness(0.96);
    }

    @media (max-width: 400px) {
      width: 130px;
      height: 130px;

      a {
        .image-box {
          width: 30px;
          height: 30px;
        }

        .image-box svg {
          width: 18px;
          height: 18px;
        }

        .label {
          font-size: 0.8rem;
        }
      }
    }
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      li {
        border: 2px solid ${colors.white};

        a {
          .label {
            color: ${colors.white};
            text-decoration: underline;
          }

          .image-box:not(.lgbtqia-plus) svg {
            filter: brightness(0) invert(1);
          }
        }
      }
    `};
`;
