import { NOTIFICATIONS } from '@/constants/notifications';
import { Notification, Page, ResponseNotification } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `/notificacoes`;

  const getNotifications = async () => {
    const path = `${resource}`;

    const response = await callService(() =>
      mainApi.get<Page<ResponseNotification[]>>(path)
    );

    return response.data.content.map<Notification>((notification) => ({
      ...notification,
      message: NOTIFICATIONS[notification.message],
      createdDate: new Date(notification.createdDate)
    }));
  };

  const getNotReadNotifications = async (
    page: number = 0,
    size: number = 10
  ) => {
    const path = `${resource}/nao-lidas`;

    const config = {
      params: { page, size }
    };

    const response = await callService(() =>
      mainApi.get<Page<ResponseNotification[]>>(path, config)
    );

    return response.data.content.map<Notification>((notification) => ({
      ...notification,
      message: NOTIFICATIONS[notification.message],
      createdDate: new Date(notification.createdDate)
    }));
  };

  const readNotification = async (ids: number[]) => {
    const path = `${resource}`;

    const response = await callService(() =>
      mainApi.patch<number>(path, undefined, {
        params: { notificationIds: ids.join(',') }
      })
    );

    return response.data;
  };

  const readAllActionNotifications = async (actionId: number) => {
    const path = `${resource}/ler-acao`;

    const response = await callService(() =>
      mainApi.patch<number>(path, undefined, {
        params: {
          actionId
        }
      })
    );

    return response.data;
  };

  return {
    getNotifications,
    getNotReadNotifications,
    readNotification,
    readAllActionNotifications
  };
};

export const actionNotification = service();
