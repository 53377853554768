export function PensionIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.25C6.62 10.25 6.5 9.605 6.5 9.5H5C5 10.19 5.495 11.4125 7.25 11.69V12.5H8.75V11.69C10.25 11.435 11 10.4675 11 9.5C11 8.66 10.61 7.25 8 7.25C6.5 7.25 6.5 6.7775 6.5 6.5C6.5 6.2225 7.025 5.75 8 5.75C8.975 5.75 9.0425 6.23 9.05 6.5H10.55C10.5399 5.98907 10.3561 5.4968 10.0289 5.10422C9.70178 4.71163 9.25073 4.44211 8.75 4.34V3.5H7.25V4.3175C5.75 4.565 5 5.5325 5 6.5C5 7.34 5.39 8.75 8 8.75C9.5 8.75 9.5 9.26 9.5 9.5C9.5 9.74 9.035 10.25 8 10.25Z"
        fill="white"
      />
      <path
        d="M2.75 0.5H0.5V2H2V14.75C2 14.9489 2.07902 15.1397 2.21967 15.2803C2.36032 15.421 2.55109 15.5 2.75 15.5H13.25C13.4489 15.5 13.6397 15.421 13.7803 15.2803C13.921 15.1397 14 14.9489 14 14.75V2H15.5V0.5H2.75ZM12.5 14H3.5V2H12.5V14Z"
        fill="white"
      />
    </svg>
  );
}
