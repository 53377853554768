import styled from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';

interface PageSectionProps {
  flex?: boolean;
  align?: `center` | `left` | `right`;
  margin?: number;
  padding?: number;
  children: any;
}

export const PageSection = (props: PageSectionProps) => {
  return <Wrapper {...props} />;
};

const Wrapper = styled.div<PageSectionProps>`
  min-height: 40px;
  max-width: 1400px;
  display: ${(props) => (props.flex ? `flex` : null)};
  width: 90%;
  text-align: ${(props) => (props.align ? props.align : null)};
  margin: ${(props) => (props.margin ? props.margin : 0)}px auto;
  color: ${(props) => props.theme.text};
  & > * {
    flex: ${(props) => (props.flex ? 1 : null)};
    padding: ${(props) => (props.padding ? props.padding + `px` : null)};
  }
  ${mediaQuery.smallScreen} {
    width: 100%;
    max-width: 100%;
  }
  ${mediaQuery.mediumScreen} {
    width: 95%;
    max-width: 95%;
  }
`;
