import { DataPercentageComputed } from 'big-data';

import botijaoIcon from '@/assets/icons/botijao.svg';
import carvaoIcon from '@/assets/icons/carvao.svg';
import lenhaIcon from '@/assets/icons/lenha.svg';
import fosforoIcon from '@/assets/icons/fosforo.svg';

const imgs: Record<string, string> = {
  'Gás de botijão': botijaoIcon,
  Lenha: lenhaIcon,
  Carvão: carvaoIcon,
  Outros: fosforoIcon
};

export function getCookWaysWithImage(data: DataPercentageComputed[]) {
  return data.map((d) => ({
    ...d,
    img: imgs[d.name]
  }));
}
