import { DataWithCity } from 'big-data';
import * as d3 from 'd3';

import { max } from '@/utils/math';
import { percentageFormatter } from '@/utils/string';

type SVGD3 = d3.Selection<SVGSVGElement, unknown, null, undefined>;
type SVGChart = d3.Selection<SVGGElement, unknown, null, undefined>;
type TSpanD3 = d3.Selection<SVGTSpanElement, unknown, null, undefined>;
type XScale = d3.ScaleLinear<number, number, never>;
type YScale = d3.ScaleBand<string>;
type Margin = { left: number; right: number; top: number; bottom: number };
type Config = {
  width: number;
  sizeRatio: number;
  isPercentage?: boolean;
  percentageCalculatedOut?: boolean;
  selected?: (label?: string) => void;
  maxValue?: number;
  selectedValue?: DataWithCity;
  total: number;
};

const MARGIN: Margin = { left: 0, right: 0, top: 0, bottom: 0 };
const TEXT_ANCHOR_KEY = 'text-anchor';

const getHeight = (width: number, ratio: number) => {
  return width < 450 ? width * 0.8 : width * ratio;
};

export class HorizontalBarBuilder {
  private box: SVGSVGElement;
  private width: number;
  private height: number;
  private labelWidth: number;
  private margin: Margin = MARGIN;
  private isPercentage: boolean;
  private percentageCalculatedOut: boolean;
  private maxValue?: number;
  private selectedValue?: DataWithCity;

  private data: DataWithCity[] = [];
  private svg: SVGD3;
  private chart: SVGChart;

  private xScale?: XScale;
  private yScale?: YScale;
  private selected?: (label?: string) => void;
  private total: number;

  constructor(box: SVGSVGElement, config: Config) {
    this.box = box;
    this.width = config.width;
    this.height = getHeight(config.width, config.sizeRatio);
    this.isPercentage = !!config.isPercentage;
    this.percentageCalculatedOut = !!config.percentageCalculatedOut;
    this.maxValue = config.maxValue;

    this.labelWidth = this.width < 450 ? 150 : 120;
    this.selected = config.selected;

    const svg = d3
      .select(box)
      .attr('width', this.width)
      .attr('height', this.height);

    const chart = svg
      .append('g')
      .attr('class', 'chart')
      .style('user-select', 'none');

    this.svg = svg;
    this.chart = chart;
    this.selectedValue = config.selectedValue;
    this.total = config.total;
  }

  getPercentage = (value: number) => {
    if (this.isPercentage) {
      if (this.percentageCalculatedOut) {
        return value;
      }
      return (value / this.total) * 100;
    }
    return 0;
  };

  putMargin(margin: Margin) {
    this.margin = margin;

    return this;
  }

  putData(data: DataWithCity[]) {
    this.data = data;

    return this;
  }

  putGrid() {
    this.chart
      .selectAll('g.x-axis g.tick + g.tick')
      .append('line')
      .attr('class', 'x-grid')
      .attr('x1', 0)
      .attr('y1', -this.height)
      .attr('x2', 0)
      .attr('y2', 0);
    return this;
  }

  buildScales() {
    const values = this.data.map((d) => d.value);

    const baseValue = this.isPercentage ? 100 : max(values);
    const xMaxValue = this.maxValue
      ? this.maxValue + (this.maxValue % 50)
      : baseValue;

    const widthWithMargins =
      this.width - this.margin.right - this.margin.left - this.labelWidth;
    const heightWithMargins =
      this.height - this.margin.bottom - this.margin.top;

    const xScale = d3
      .scaleLinear()
      .domain([0, xMaxValue])
      .range([0, widthWithMargins]);

    const yScale = d3
      .scaleBand()
      .domain(this.data.map((d) => d.name))
      .range([0, heightWithMargins])
      .padding(window.innerWidth > 1200 ? 0.4 : 0.5);

    this.xScale = xScale;
    this.yScale = yScale;

    return this;
  }

  putLabel() {
    this.chart
      .append('g')
      .attr('class', 'x-tooltip')
      .style('user-select', 'none')
      .selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .attr('x', (d) => {
        const scaleValue = this.percentageCalculatedOut || !this.isPercentage
          ? d.value
          : this.getPercentage(d.value);

        const offset = this.labelWidth === 120 ? 165 : 195;

        return this.xScale ? this.xScale(scaleValue) + offset : 0;
      })
      .attr('y', (d, i) =>
        this.yScale
          ? (this.yScale(d.name) ?? 0) + this.yScale.bandwidth() / 2 + 2
          : 0
      )
      .text((d) =>
        {
          if (!this.isPercentage) return d.value
          else if (this.percentageCalculatedOut) return percentageFormatter(d.value)
          return percentageFormatter((d.value * 100) / this.total)
        }
      )
      .style('font-family', 'Arial')
      .style('font-size', '11px')
      .style(TEXT_ANCHOR_KEY, 'middle')
      .style('font-weight', '700');

    return this;
  }

  plotAxis(ticks: number = 5) {
    if (this.xScale && this.yScale) {
      const xAxis = d3
        .axisBottom(this.xScale)
        .tickSizeOuter(0)
        .ticks(ticks)
        .tickFormat((d) => (this.isPercentage ? `${d}%` : `${d}`));

      const yAxis = d3.axisLeft(this.yScale);
      const finalHeight = this.height - this.margin.top - this.margin.bottom;
      const left = this.margin.left + this.labelWidth;

      this.chart
        .append('g')
        .attr('transform', `translate(${left}, ${finalHeight})`)
        .attr('class', 'axis x-axis')
        .call(xAxis)
        .selectAll('text')
        .attr('class', 'axis-text x-axis-text');

      this.chart
        .append('g')
        .attr('transform', `translate(${left}, ${0})`)
        .attr('class', 'axis y-axis')
        .call(yAxis)
        .selectAll('text')
        .attr('class', 'axis-text y-axis-text')
        .call((texts, width) => {
          texts.each(function () {
            const text = d3.select(this);

            const dy = parseFloat(text.attr('dy'));

            const tspans: TSpanD3[] = [];
            const texts = text.text().split(' ');

            text.text(null);

            let tspan = text
              .append('tspan')
              .text(texts[0])
              .style(TEXT_ANCHOR_KEY, 'end')
              .attr('x', -15)
              .attr('dy', `-${dy}em`);

            texts.slice(1).forEach((t) => {
              const currentText = tspan.text();
              const newText = currentText ? `${currentText} ${t}` : `${t}`;

              tspan.text(newText);

              const textLength = (tspan.node() as any).getComputedTextLength();

              if (textLength > width - 20) {
                tspan.text(currentText);
                tspans.push(tspan);

                tspan = text
                  .append('tspan')
                  .text(t)
                  .style(TEXT_ANCHOR_KEY, 'end')
                  .style('font-family', 'IBM Plex Sans')
                  .attr('x', -15)
                  .attr('dy', `${dy + 1}em`);
              }
            });

            if (tspans.length === 0) {
              tspan.attr('y', 8.5);
            }
          });
        }, left);
    }

    return this;
  }

  plotData(color: string = '#000', rx: number = 0) {
    this.chart
      .selectAll('.rect-bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('transform', `translate(${this.labelWidth + this.margin.left}, 0)`)
      .attr('class', 'bar')
      .attr('x', this.xScale ? this.xScale(0) : 0)
      .attr('y', (d) => (this.yScale ? this.yScale(d.name) ?? 0 : 0))
      .attr('width', (d) => {
        const value = this.isPercentage ? this.getPercentage(d.value) : d.value;

        return this.xScale ? this.xScale(value) : 0;
      })
      .attr('height', this.yScale ? this.yScale.bandwidth() : 0)
      .attr('rx', rx)
      .attr('fill', (d) => d.color ?? color);

    return this;
  }

  animateBar() {
    const bars = this.chart.selectAll('.bar').attr('class', 'bar init');

    const id = setTimeout(() => {
      bars.attr('class', 'bar');

      clearTimeout(id);
    }, 0);

    return this;
  }

  disableAxisLine() {
    d3.select('.axis .domain').style('display', 'none');

    return this;
  }

  disableXAxisLine() {
    this.chart.selectAll('.x-axis .domain').style('display', 'none');

    return this;
  }

  disableYAxisLine() {
    this.chart.selectAll('.y-axis .domain').style('display', 'none');

    return this;
  }

  unmount() {
    this.chart.remove();

    return this;
  }
}
