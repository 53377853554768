import { Observation } from 'big-data';
import dayjs from 'dayjs';

import { Box } from './styles';

interface CommentDetailsProps {
  comment: Observation;
  side?: 'left' | 'right';
}

export function CommentDetails({ comment, side }: CommentDetailsProps) {
  const formattedDate = getCommentDate(comment.createdDate);

  return (
    <Box side={side}>
      <span className="author">{comment.author.name}</span>
      <span className="date">{formattedDate}</span>
    </Box>
  );
}

function getCommentDate(date: Date) {
  const commentDate = dayjs(date);

  const today = dayjs();
  const yesterday = today.add(-1, 'day');

  const hour = commentDate.format('HH:mm');

  if (commentDate.isSame(today, 'day')) {
    return `Hoje às ${hour}`;
  }

  if (commentDate.isSame(yesterday, 'day')) {
    return `Ontem às ${hour}`;
  }

  const formattedDate = commentDate.format('DD/MM/YYYY');

  return `${formattedDate} às ${hour}`;
}
