import { colors } from '@/theme/colors';
import { DefaultTheme } from 'styled-components';

export const modifierColors = new Map([
  ['#EBAD29', '#B47C07'],
  ['#A71416', '#A71416'],
  ['#57AC4B', '#4E7345'],
  ['#EA9753', '#BC6117']
]);

export function resolveMapColor(
  selected: boolean,
  color: string,
  theme: DefaultTheme
) {
  if (selected) return color;
  else if (theme.darkMode) return colors.ming100;
  else return colors.ciano700;
}
