import { Section } from '@/layout';
import { Card, Donut } from '@/components/shared';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { homeVisits } from '@/services';
import { DataWithCity } from 'big-data';
import { useEffect, useMemo } from 'react';

export function VisitedKids() {
  const { data, loading, error, request } = useService<DataWithCity>();

  const { city, profilePopulation } = useAnalyticsFilter();
  useEffect(() => {
    request(() => homeVisits.getProfiles('beneficiaries', city));
  }, [request, city]);

  const filteredData = useMemo(
    () =>
      data
        .filter((d) =>
          profilePopulation ? d.name === profilePopulation : true
        )
        .filter((d) => (city ? d.city === city : true)),
    [data, city, profilePopulation]
  );

  return (
    <Section>
      <Card
        title="Fases visitadas: crescimento intra-uterino e primeira infância"
        empty={filteredData.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[
          city ? city : 'Ceará',
          profilePopulation
            ? profilePopulation.replace('+', ' e ')
            : 'Todos os perfis'
        ]}
      >
        <Donut
          data={filteredData}
          thickness={50}
          colors={['#55A546', '#FFCA16', '#ab1e11']}
          exactColors
        />
      </Card>
    </Section>
  );
}
