import { DataTree } from 'big-data';

import assistanceSocialIcon from '@/assets/icons/axes/assistanceSocial.svg';
import educationIcon from '@/assets/icons/axes/education.svg';
import healthIcon from '@/assets/icons/axes/health.svg';
import houseIcon from '@/assets/icons/axes/house.svg';
import insecurityFoodIcon from '@/assets/icons/axes/insecurityFood.svg';
import jobIncomeIcon from '@/assets/icons/axes/jobIncome.svg';

const value = 100 / 6;

const WORK_LABEL = 'Trabalho e Renda';

export const axes: DataTree = {
  name: 'Proteção Social',
  value: 0,
  percentageValue: 0,
  color: 'transparent',
  children: [
    {
      name: 'Ins. Alimentar',
      value,
      percentageValue: 20,
      color: '#F9D178',
      legend: 'Insegurança Alimentar',
      tooltip: 'Insegurança Alimentar',
      img: insecurityFoodIcon,
      path: 'inseguranca-alimentar',
      children: []
    },
    {
      name: 'Educação',
      value,
      percentageValue: 25,
      color: '#CC37E4',
      legend: 'Educação',
      tooltip: 'Educação',
      img: educationIcon,
      path: 'educacao',
      children: []
    },
    {
      name: 'Assist. Social',
      value,
      percentageValue: 20,
      color: '#F4881e',
      legend: 'Assistência Social',
      tooltip: 'Assistência Social',
      img: assistanceSocialIcon,
      path: 'assistencia-social',
      children: []
    },
    {
      name: 'Moradia',
      value,
      percentageValue: 20,
      color: '#FE6074',
      legend: 'Moradia',
      tooltip: 'Moradia',
      img: houseIcon,
      path: 'condicoes-de-moradia',
      children: []
    },
    {
      name: 'Saude',
      value,
      percentageValue: 20,
      color: '#57AC4B',
      legend: 'Saúde',
      tooltip: 'Saúde',
      img: healthIcon,
      path: 'saude',
      children: []
    },
    {
      name: WORK_LABEL,
      value,
      percentageValue: 20,
      color: '#324BE1',
      legend: WORK_LABEL,
      tooltip: WORK_LABEL,
      img: jobIncomeIcon,
      path: 'trabalho-e-renda',
      children: []
    }
  ]
};
