import { useEffect, useState } from 'react';

import { AxeEnum } from '@/constants/axes';
import {
  actionPercentage,
  EducationEnum,
  HealthEnum,
  LivingConditionEnum,
  SocialAssistanceEnum,
  WorkEnum,
  IActionPercentage
} from '@/services';
import { COLUMN_BY_INDICATOR } from './columnsByIndicator';

interface IndicatorValueProps {
  axe: string;
  indicator: string;
}

const getCleanResponse = (data: IActionPercentage[]) => {
  if (data.length === 0) return null;

  return data.reduce(
    (acc, cur) => ({
      ...acc,
      value: acc.value + cur.value,
      percentage: acc.percentage + cur.percentage,
      group: acc.group ? `${acc.group}; ${cur.group}` : `${cur.group}`
    }),
    { value: 0, percentage: 0, group: '' } as IActionPercentage
  );
};

const request = ({ axe, indicator }: IndicatorValueProps) => {
  const columnValues = COLUMN_BY_INDICATOR[indicator];

  if (axe === AxeEnum.EDUCACAO) {
    return actionPercentage.getEducationValues({
      column: indicator as EducationEnum,
      columnValues
    });
  }

  if (axe === AxeEnum.ASSISTENCIA_SOCIAL) {
    return actionPercentage.getSocialAssistanceValues({
      column: indicator as SocialAssistanceEnum,
      columnValues
    });
  }

  if (axe === AxeEnum.SAUDE) {
    return actionPercentage.getHealthValues({
      column: indicator as HealthEnum,
      columnValues
    });
  }

  if (axe === AxeEnum.TRABALHO_RENDA) {
    return actionPercentage.getWorkValues({
      column: indicator as WorkEnum,
      columnValues
    });
  }

  if (axe === AxeEnum.MORADIA) {
    return actionPercentage.getLivingConditionValues({
      column: indicator as LivingConditionEnum,
      columnValues
    });
  }

  return actionPercentage.getInsecurityFoodValues({
    columnValues
  });
};

export function useIndicatorValue({ axe, indicator }: IndicatorValueProps) {
  const [indicatorValue, setIndicatorValue] =
    useState<IActionPercentage | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (indicator === '' || indicator === undefined) return;

    setLoading(true);
    request({ axe, indicator })
      .then((data) => getCleanResponse(data))
      .then((data) => (console.log(data) as undefined) ?? data)
      .then((data) => setIndicatorValue(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [axe, indicator]);

  return {
    indicatorValue,
    loading,
    error
  };
}
