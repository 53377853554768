import { Card, DonutPopulated, H4 } from '@/components/shared';
import { useService } from '@/hooks';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useSocialAssistance } from '@/services/lgbt/socialAssistance';
import { colors } from '@/theme/colors';
import { Tooltip } from 'antd';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { colorGenerator } from '../../../utils/colors';

import { Box } from './styles';

const cadUnico =
  'CadÚnico: Cadastro Único para Programas Sociais do Governo Federal';

const title = () => (
  <H4 color={colors.ciano700}>
    Pessoas LGBTQIA+ entrevistadas que estão no{' '}
    <Tooltip title={cadUnico} placement="right">
      <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
        CadÚnico
      </span>
    </Tooltip>
  </H4>
);

export function CadUnico() {
  const { city, orientation, ibge, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useSocialAssistance.cadUnico({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, orientation, ibge, identity]);

  const formatedDataDonut = useMemo(() => {
    return data.map((d) => {
      const color = colorGenerator(d.name);
      return {
        ...d,
        color
      };
    });
  }, [data]);

  return (
    <Box>
      <Card
        title={title()}
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
        noMargin
      >
        <DonutPopulated
          calculatedOut
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedDataDonut}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Box>
  );
}
