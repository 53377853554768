import * as d3 from 'd3';

function getRoundNumber(num: number) {
  return d3.scaleLinear().domain([0, num]).nice().invert(1);
}

export function formatNumber(num: string | number): string {
  const value = typeof num === 'number' ? num : Number(num);
  const formatter = new Intl.NumberFormat('pt-BR');

  return formatter.format(value);
}

export function sortFeaturesByCentroid(geojson: any) {
  geojson.features.forEach((feature: any) => {
    feature.properties.center = d3.geoCentroid(feature);
  });
  geojson.features.sort((a: any, b: any) => {
    return d3.descending(a.properties.center, b.properties.center);
  });
}

export function calcLegendValues(maxNumber: number, numTicks = 5) {
  const ticks: number[] = [];
  let base = maxNumber;
  for (let i = 0; i < numTicks; i++) {
    const value = getRoundNumber(base);
    ticks.unshift(value);
    base = base / 2.0;
  }
  return ticks;
}

export function createLegendItems(
  colorPalette: string[],
  values: number[]
): LegendItem[] {
  return colorPalette.map((color: string, index: number) => {
    const a = values[index - 1] || -1;
    const b = values[index];
    return {
      title: formatNumber(a + 1) + ` - ` + formatNumber(b),
      color
    };
  });
}

export function onInitByVisibility(node: HTMLElement, cbVisible: () => void) {
  let initialized = false;
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      if (initialized) {
        observer.disconnect();
      } else {
        initialized = true;
        cbVisible();
      }
    }
  });
  observer.observe(node);
}
