import {
  PaginatedAction,
  Action,
  ObservationParams,
  PaginatedActionHistory,
  ActionCreation
} from 'big-data';
import * as R from 'ramda';

import { ensureAxiosParamOptions } from '@/utils/routes';

import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `acoes`;

  async function getLastActive(indicator: string) {
    const path = `${resource}/last-active`;
    const options = ensureAxiosParamOptions({
      params: {
        indicator: indicator
      }
    });

    const { data } = await callService(() =>
      mainApi.get<Action>(path, options)
    );

    return typeof data === 'string' ? null : data;
  }

  async function getActions(
    axle?: string | null,
    ibge?: string,
    indicator?: string,
    page?: number,
    size?: number,
    status?: string,
    searchTitle?: string
  ) {
    const path = `${resource}`;

    const options = ensureAxiosParamOptions({
      params: {
        axle: R.isNil(axle) ? undefined : R.toUpper(axle),
        ibge,
        indicator,
        page,
        size,
        status,
        title: searchTitle
      }
    });

    const response = await callService(() =>
      mainApi.get<PaginatedAction>(path, options)
    );

    return response.data;
  }

  async function getAction(id: number) {
    const path = `${resource}/${id}`;

    const response = await callService(() => mainApi.get<Action>(path));

    return response.data;
  }

  async function postAction(action: ActionCreation, file?: File) {
    const path = `${resource}`;
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };

    const formData = new FormData();
    const mimeJsonBlob = { type: 'application/json' };
    const json = JSON.stringify(action);
    const jsonBlob = new Blob([json], mimeJsonBlob);

    formData.append('action', jsonBlob);
    file && formData.append('file', file);

    const response = await callService(() =>
      mainApi.post<Action>(path, formData, config)
    );

    return response.data;
  }

  async function getActionFile(id: number) {
    const path = `${resource}/${id}/download`;

    const response = await callService(() =>
      mainApi.get<BlobPart>(path, { responseType: 'blob' })
    );

    return response.data;
  }

  async function getHistory({ id, page = 0, size = 10 }: ObservationParams) {
    const path = `${resource}/${id}/history`;

    const options = {
      params: { page, size }
    };

    const response = await callService(() =>
      mainApi.get<PaginatedActionHistory>(path, options)
    );

    return response.data;
  }

  async function blockAction(id: number) {
    const path = `${resource}/${id}/block`;

    const response = await callService(() => mainApi.patch(path));

    return response.data;
  }

  async function getActionAnalytics(
    minimumDate?: string,
    ibge?: string,
    status?: string
  ) {
    const path = `${resource}/analiticos-acoes`;

    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        status
      }
    });

    const response = await callService(() =>
      mainApi.get<{ quantidade: number; ibge: string; cidade: string }[]>(
        path,
        options
      )
    );

    return response.data.map((data) => ({
      name: data.cidade,
      ibge: data.ibge,
      value: data.quantidade
    }));
  }

  return {
    getActions,
    getAction,
    postAction,
    getActionFile,
    getHistory,
    getLastActive,
    blockAction,
    getActionAnalytics
  };
};

export const actions = service();
