import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  max-width: 170px;
  width: 100%;
  max-height: 130px;
  padding: 16px;

  background: ${({ theme }) =>
    theme.darkMode ? theme.background : colors.white};
  border: 0.564767px solid
    ${({ theme }) => (theme.darkMode ? theme.background : `#dfe6e9`)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px;

  position: absolute;
  top: 92px;
  right: -40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  strong {
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green600)};
    font-weight: 700;
    font-size: 1.875rem; // 30px
    line-height: 36px;
  }

  p {
    font-weight: 700;
    font-size: 0.7rem;
    line-height: 13px;
    text-transform: uppercase;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
  }

  ${mediaQuery.smallScreen} {
    top: 45%;
    left: calc(50% + 15px);
  }
`;

export const ImageBox = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme.darkMode ? colors.white : colors.green200};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -21.5px;
  left: -20px;

  img {
    margin-inline-start: 4px;
    filter: ${({ theme }) => (theme.darkMode ? 'brightness(0)' : '')};
  }
`;
