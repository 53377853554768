import * as R from 'ramda';
import { Action } from 'big-data';
import { useEffect, useRef, useState } from 'react';

import { Loading, ServiceBox2 } from '@/components/shared';
import { GoalsItem } from '../GoalsItem';
import { Box, Ball } from './styles';

interface GoalsColumnProps {
  title: string;
  color: string;
  actions: Action[];
  searchText: string;
  loading?: boolean;
  error?: string;
  isSelected?: boolean;
  onClick?: () => void;
  onClickItem?: (action: Action) => void;
}

export function GoalsColumn({
  title,
  color,
  actions,
  searchText,
  loading,
  error,
  isSelected,
  onClick,
  onClickItem
}: GoalsColumnProps) {
  const [page, setPage] = useState(-1);

  const scrollRef = useRef<HTMLDivElement>(null);
  const intersectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: scrollCurrent } = scrollRef;
    const { current: intersectionCurrent } = intersectionRef;

    if (!intersectionCurrent) return;

    const options = {
      root: scrollCurrent,
      rootMargin: '0px',
      threshold: 0.75
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        setPage((current) => current + 1);
      }
    }, options);

    observer.observe(intersectionCurrent);

    return () => observer.disconnect();
  }, []);

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  const filteredActions = R.isEmpty(searchText)
    ? [...actions]
    : actions.filter((action) => action.title.includes(searchText));

  return (
    <Box selected={isSelected}>
      <header className="goals-column-header" onClick={handleClick}>
        <Ball color={color} />
        <h2>{title}</h2>
      </header>

      <div className="goals-column-body" ref={scrollRef}>
        <ServiceBox2
          empty={actions.length === 0}
          loading={loading && page === 0}
          error={error}
        >
          {filteredActions?.map((action) => (
            <GoalsItem key={action.id} action={action} onClick={onClickItem} />
          ))}
        </ServiceBox2>
        <div className="intersection" ref={intersectionRef}>
          {loading && page !== 0 && <Loading size={25} />}
        </div>
      </div>
    </Box>
  );
}
