import styled from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';

export const Box = styled.div`
  position: relative;
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  background: ${({ theme }) => (theme.darkMode ? '#000' : '#fff')};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  margin-top: 24px;

  .tabs-box-nav {
    width: 50%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 10px;
    gap: 20px;
    padding: 1rem 0;

    .tabs-box-nav {
      width: 100%;
    }

    .tab-select-container {
      justify-self: flex-start;
    }
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;
    padding-bottom: 10px;
    height: auto;

    .tabs-box-nav {
      width: 100%;
      height: 100%;
    }
  }
`;
