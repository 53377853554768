import styled, { useTheme } from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';

interface LegendItem {
  title: string;
  color: string;
}

interface Props {
  items: LegendItem[];
}

const TEXT_MOBILE = `Interaja com o mapa para detalhar:`;
const TEXT_DESKTOP = `Interaja com o mapa para visualizar o número de famílias atendidas por município:`;

export const LegendPanel = ({ items }: Props) => {
  const theme = useTheme();

  if (items.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <Title>{theme.screen.small ? TEXT_MOBILE : TEXT_DESKTOP}</Title>
      {items.map((i) => (
        <Row key={i.color}>
          <Label>{i.title}</Label>
          <GridCell color={i.color} />
        </Row>
      ))}
    </Wrapper>
  );
};

const Title = styled.div`
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  color: ${(props) => (props.theme.darkMode ? `#fffc` : `#555c`)};
  line-height: 1.3;
  text-rendering: geometricPrecision;
  text-align: right;
  ${mediaQuery.smallScreen} {
    padding: 0 0 5px 0;
    margin-left: -40px;
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2px;
  color: ${(props) => (props.theme.darkMode ? `#fffd` : `#2d4040cc`)};
`;

const Label = styled.span`
  white-space: nowrap;
  font-size: 1rem;
`;

const GridCell = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  margin: 0 5px;
  background: ${(props) => props.color};
  border-radius: 4px;
  ${mediaQuery.smallScreen} {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -20px;
  right: 0;
  max-width: 50%;
  padding: 20px 0;
  ${mediaQuery.mediumScreen} {
    right: 10px;
    padding: 20px 0;
  }
  ${mediaQuery.smallScreen} {
    right: 0;
    padding: 20px 0 0 0;
  }
`;
