import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

const infoMapping = new Map([
  [
    'Todas são',
    {
      color: '#55A546'
    }
  ],
  [
    'Raramente são',
    {
      color: '#1D696F'
    }
  ],
  [
    'Geralmente são',
    {
      color: '#F6A726'
    }
  ],
  [
    'Nunca foram atendidas chefes de família',
    {
      color: '#D4282A'
    }
  ]
]);

export function Card2() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getWomenPositions(ibge));
  }, [request, ibge]);

  const donutData = data.map((d) => {
    const { color } = infoMapping.get(d.name)!;

    return {
      ...d,
      color
    };
  });

  return (
    <Card
      title="Posição das mulheres nas famílias das organizações"
      tags={[city ?? 'CEARÁ']}
      loading={loading}
      error={error}
      empty={data.length === 0}
    >
      <DonutPopulated
        data={donutData}
        thickness={40}
        flexDirection="column"
        donutHeight={300}
        donutWidth={300}
        showPercentage
        percentage
      />
    </Card>
  );
}
