import { AxeDescriptionText as DashboardDescription } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';
import { FilterContextProvider, TabContextProvider } from '../contexts';
import { SectionContent } from './SectionContent';
import { Box } from './styles';

const description = `O Big Data Social conta com um painel de monitoramento e análise de acessos da plataforma. Através desse painel e da integração com o Google Analytics, é possível monitorar o perfil de quem acessa o sistema, as páginas e municípios mais acessados, conversões, os tipos de dispositivos utilizados, entre outros dados. `;

export function Home() {
  useDashboardTitle('Dados de Acesso da Plataforma Big Data Social');

  return (
    <Box>
      <DashboardDescription>{description}</DashboardDescription>
      <FilterContextProvider>
        <TabContextProvider>
          <SectionContent />
        </TabContextProvider>
      </FilterContextProvider>
    </Box>
  );
}
