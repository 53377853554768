import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `/feedback`;

  async function create(data: any, name: string, email: string) {
    const path = resource;

    const options = ensureAxiosParamOptions({
      params: {
        emailSender: email,
        nameSender: name
      }
    });

    await callService(() => mainApi.post(path, data, options));
  }

  return {
    create
  };
};

export const feedback = service();
