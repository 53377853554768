import { Menu, MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { pipe, split, last, equals } from 'ramda';

import { OpenDrawer, Logo, Avatar, LogoutButton } from './components';
import { SmallBox, Drawer } from './styles';
import { useAuth } from '@/hooks';

type MenuItem = Required<MenuProps>['items'][number];

interface ScreenSmallProps {
  open: boolean;
  items?: MenuItem[];
  onClickItem?: (e: any) => void;
  onOpen: () => void;
  onClose?: () => void;
}

export function ScreenSmall({
  open,
  items,
  onClickItem,
  onOpen,
  onClose
}: ScreenSmallProps) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const client = user?.client;

  const lastPath = pipe<[string], string[], string>(split('/'), last)(pathname);
  const isLastPathAxe = equals(lastPath, 'eixos');

  return (
    <SmallBox>
      <OpenDrawer onOpen={onOpen} />
      <Logo />
      <Drawer visible={open} placement="left" onClose={onClose}>
        <div className="logo">
          <Logo />
        </div>

        <Menu
          theme="dark"
          items={items}
          mode="inline"
          onClick={onClickItem}
          rootClassName="menu-dashboard"
          selectedKeys={[isLastPathAxe ? '' : lastPath]}
        />

        <div className="logout">
          <Avatar client={client} />
          <LogoutButton showLabel={true} />
        </div>
      </Drawer>
    </SmallBox>
  );
}
