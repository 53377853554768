import { useMemo } from 'react';
import { Data } from 'big-data';

import { DashboardCard, Show } from '@/components/shared';
import { ColorText } from '@/components/styled';
import { useDemographyData } from '@/servicesHooks/socialAssistance';
import { useDashboardFilter } from '@/hooks';
import { BdsRural, BdsUrban } from '@/bdsIcons';

interface InformationBodyProps {
  crasHidden?: boolean;
  city?: string;
  color: string;
  hasCircle?: boolean;
}

function resolveZone(data: Data[], name: string) {
  return data
    .filter((d) => d.name.toLowerCase().includes(name))
    .reduce((acc, cur) => acc + cur.value, 0);
}

export function SocialAssistanceCRASMonitoring({
  crasHidden = false,
  color,
  hasCircle
}: InformationBodyProps) {
  const { ibge } = useDashboardFilter();
  const { familiesCRAS, loadingFamilies } = useDemographyData(ibge);

  const cras = useMemo(() => {
    const total = familiesCRAS.reduce((acc, cur) => acc + cur.value, 0);
    const urbanTotal = resolveZone(familiesCRAS, 'rural');
    const ruralTotal = resolveZone(familiesCRAS, 'urbana');

    const urbanPercentage = (urbanTotal / total) * 100;
    const ruralPerncetage = (ruralTotal / total) * 100;

    return {
      total,
      urbanPercentage: urbanPercentage.toFixed(2) + '%',
      ruralPerncetage: ruralPerncetage.toFixed(2) + '%'
    };
  }, [familiesCRAS]);

  const formatter = new Intl.NumberFormat('pt-br');

  if (crasHidden) return null;

  return (
    <DashboardCard loading={loadingFamilies} color={color}>
      <Show when={!crasHidden} fallback={null}>
        <p className="information-about">
          Das{' '}
          <ColorText color={color}>{formatter.format(cras.total)}</ColorText>{' '}
          famílias não acompanhadas pelo CRAS nos últimos 3 meses:
        </p>

        <ul>
          <li>
            <BdsRural color={'#fff'} />
            <p>
              <ColorText color={color}>{cras.ruralPerncetage}</ColorText> moram
              na <strong>Zona Rural</strong>
            </p>
          </li>
          <li>
            <BdsUrban color={'#fff'} />
            <p>
              {' '}
              <ColorText color={color}>{cras.urbanPercentage}</ColorText> moram
              na <strong>Zona Urbana</strong>
            </p>
          </li>
        </ul>
      </Show>
    </DashboardCard>
  );
}
