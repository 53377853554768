import { useEffect, useMemo, useState } from 'react';

import {
  CardTagged,
  D3Map,
  DonutPopulated,
  OneBar,
  Outdoor,
  PaginatedTable,
  ServiceBox
} from '@/components/shared';
import { ColorText, Text, TitleText } from '@/components/styled';

import { useTotalFamilies } from '@/servicesHooks/cmic';
import {
  useInterviewedByCities,
  useEthnicity,
  useAges,
  useStudyDegree,
  useWhoAnswerSearch,
  useWomanMaritalStatus
} from '@/servicesHooks/profileSearch';
import { useVisitedFamilies } from '@/servicesHooks/visits';
import { max } from '@/utils/math';
import { formatMax } from '@/utils/data';
import { colors } from '@/theme/colors';
import { useAuth, useDashboardFilter } from '@/hooks';

import calendarIcon from '@/assets/icons/calendar.svg';
import hatSchoolIcon from '@/assets/icons/hat-school.svg';
import woman3x4Icon from '@/assets/icons/woman-3x4.svg';
import womanWithBabyIcon from '@/assets/icons/woman-and-baby.svg';
import ringsIcon from '@/assets/icons/rings.svg';

import {
  CardQualificationCourse,
  CardPregant,
  CardWomanImpairmentRanking
} from '../HomeCards';
import { Box } from './styles';
import { useTheme } from 'styled-components';
import { ListTable } from '@/components/shared/Charts/ListTable';

const num = new Intl.NumberFormat('pt-br');
const percentNum = new Intl.NumberFormat('pt-br', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const INITIALBIGGEST = { name: '', value: 0 };

export function HomeInsights() {
  const theme = useTheme();

  const { city, yearSemester, tag, ibge } = useDashboardFilter();
  const [maxValueCities, setMaxValueCities] = useState<number>();

  const { visitedFamilies, errorVisitedFamilies, loadingVisitedFamilies } =
    useVisitedFamilies();
  const {
    interviewedCities,
    errorInterviewedCities,
    loadingInterviewedCities
  } = useInterviewedByCities();
  const { totalFamilies, loadingTotalFamilies } = useTotalFamilies();

  const { ages, loadingAges, errorAges } = useAges();
  const { ethnicities, loadingEthnicities, errorEthnicities } = useEthnicity();
  const { studyDegrees, loadingStudyDegrees, errorStudyDegrees } =
    useStudyDegree();
  const { whoAnswers, loadingWhoAnswers, errorWhoAnswers } =
    useWhoAnswerSearch();
  const { maritalStatus, loadingMaritalStatus, errorMaritalStatus } =
    useWomanMaritalStatus();

  const maxAge = formatMax(ages);
  const maxEthnicity = formatMax(ethnicities);
  const maxStudyDegree = formatMax(studyDegrees);
  const maxWhoAnswer = formatMax(whoAnswers);
  const maxMaritalStatus = formatMax(maritalStatus);

  const { isAdm } = useAuth();

  const interviewedFamiliesByCity = useMemo(() => {
    return interviewedCities.map((city) => ({
      name: city.codIbge,
      city: city.name,
      value: city.value
    }));
  }, [interviewedCities]);

  useEffect(() => {
    if (!city || !isAdm) {
      const values = interviewedFamiliesByCity.map((d) => d.value);
      const maxValue = max(values);
      setMaxValueCities(maxValue);
    }
  }, [city, interviewedFamiliesByCity, isAdm]);

  const fiveBiggestInterviewedCities = useMemo(
    () =>
      interviewedCities.map((family) => ({
        name: family.name,
        value: family.value.toString()
      })),
    [interviewedCities]
  );

  const totalInterviewed = interviewedCities.reduce(
    (acc, cur) => acc + cur.value,
    0
  );

  const interviewedFamiliesData = [
    {
      name: 'famílias foram entrevistadas',
      value: totalInterviewed / totalFamilies,
      color: '#1d696f'
    },
    {
      name: 'famílias não entrevistadas',
      value: (totalFamilies - totalInterviewed) / totalFamilies,
      color: '#e2e8f0'
    }
  ];

  const biggestInterviewed = interviewedCities[0] ?? INITIALBIGGEST;

  const oneBarData = useMemo(() => {
    const urbanTotal = visitedFamilies.cities?.reduce(
      (acc, city) => acc + city.urbanValue,
      0
    );
    const ruralTotal = visitedFamilies.cities?.reduce(
      (acc, city) => acc + city.ruralValue,
      0
    );

    return [
      {
        name: 'Zona Urbana',
        value: (urbanTotal / visitedFamilies.total) * 100
      },
      { name: 'Zona Rural', value: (ruralTotal / visitedFamilies.total) * 100 }
    ];
  }, [visitedFamilies]);

  const visitedFamiliesByCityPercentage = useMemo(() => {
    const { cities = [] } = visitedFamilies;

    return cities.map((visit) => ({
      name: visit.name,
      value: (visit.value * 100) / visit.total
    }));
  }, [visitedFamilies]);

  const visitedFamiliesPercentage = percentNum.format(
    visitedFamilies.totalDone / visitedFamilies.total || 0
  );

  const totalVisitedFamiliesPercentage = percentNum.format(
    totalInterviewed / totalFamilies
  );

  const totalInterviewedFormatted = num.format(totalInterviewed);

  const last_semester = useMemo(() => {
    const date = new Date();
    return `${date.getFullYear()}.${date.getMonth() > 6 ? 2 : 1}`;
  }, []);

  const gridTemplateAreas = isAdm ? `'map map interview goal'
  'city city profile profile'
  'q-course q-course pregnant ranking'`
    :
    `'map map interview goal'
    'ranking ranking profile profile'
    'q-course q-course pregnant pregnant'`


  return (
    <Box gridTemplateAreas={gridTemplateAreas}>
      <div className="interview-map">
        <CardTagged
          tag={tag}
          title="Famílias com CMIC entrevistadas por município"
        >
          <ServiceBox
            empty={interviewedFamiliesByCity.length === 0}
            loading={loadingInterviewedCities}
            error={errorInterviewedCities}
          >
            <Text>
              <ColorText>{totalInterviewedFormatted}</ColorText>{' '}
              {totalInterviewed > 1 ? ` famílias` : ` família`} em situação de
              vulnerabilidade social e com crianças de 0 a 5 anos e 11 meses
              estão recebendo o benefício do Cartão Mais Infância (CMIC).
            </Text>
            <div className="map-content">
              <D3Map
                data={interviewedFamiliesByCity}
                color={theme.darkMode ? '#D8F9FD' : '#1D696F'}
                filteringCity={ibge}
                maxValue={maxValueCities}
                mapDescription="Passe o mouse sobre o mapa para visualizar o número de famílias beneficiadas em cada município.
              "
              />
            </div>
          </ServiceBox>
        </CardTagged>
      </div>
      <div className="interview">
        <CardTagged tag={tag} title="Total de famílias com CMIC entrevistadas">
          <ServiceBox
            empty={
              interviewedFamiliesByCity.length === 0 || totalFamilies === 0
            }
            loading={loadingInterviewedCities || loadingTotalFamilies}
            error={errorInterviewedCities}
          >
            <Text>
              <ColorText>{totalVisitedFamiliesPercentage}</ColorText> das
              famílias beneficiadas no Ceará foram entrevistadas pelos agentes
              do Programa Mais Infância.
            </Text>
            <div className="donut-content">
              <DonutPopulated
                data={interviewedFamiliesData}
                percentage={true}
                thickness={20}
                label="famílias"
                subLabel="entrevistadas"
                flexDirection="column"
                initialRenderData={interviewedFamiliesData[0]}
                colors={
                  theme.darkMode
                    ? [colors.yellow200, colors.yellow600]
                    : ['#969CA4', colors.ciano700]
                }
                exactColors
                highlight={(data) =>
                  data.name === 'famílias foram entrevistadas'
                }
              />
            </div>
          </ServiceBox>
        </CardTagged>
      </div>

      <div className="interview-goals">
        <CardTagged
          tag={tag}
          title="Meta de visitação das famílias
"
        >
          <ServiceBox
            empty={visitedFamilies.cities?.length === 0}
            loading={loadingVisitedFamilies}
            error={errorVisitedFamilies ?? null}
          >
            <Text>
              <ColorText>{visitedFamiliesPercentage}</ColorText> da meta de
              visitação das famílias foi cumprida no semestre de{' '}
              {yearSemester ?? last_semester}.
            </Text>

            <OneBar
              data={oneBarData}
              colors={
                theme.darkMode
                  ? [colors.yellow200, colors.yellow600]
                  : [colors.ciano700, colors.blue600]
              }
            />
            <PaginatedTable
              percentage
              data={visitedFamiliesByCityPercentage}
              title={
                <TitleText>
                  Meta de visitação cumprida{' '}
                  {visitedFamiliesByCityPercentage.length > 1
                    ? `por município no`
                    : ``}{' '}
                  {yearSemester
                    ? ` semestre ${yearSemester}`
                    : `semestre ${last_semester}`}
                </TitleText>
              }
            />
          </ServiceBox>
        </CardTagged>
      </div>

      { // this card should only appear for admin profiles
        isAdm && (
          <div className="interview-city">
            <CardTagged
              tag={tag}
              title="Municípios com mais famílias entrevistadas"
            >
              <ServiceBox
                empty={interviewedCities.length === 0}
                loading={loadingInterviewedCities}
                error={errorInterviewedCities}
              >
                <Text>
                  <ColorText>{biggestInterviewed.name}</ColorText> é a cidade com
                  mais famílias beneficiadas entrevistadas atualmente.
                </Text>

                <ListTable
                  data={fiveBiggestInterviewedCities}
                  columnTitle={[
                    { name: 'Cidade', key: 'name' },
                    { name: 'Total de famílias', key: 'value' }
                  ]}
                  numberValues
                />
              </ServiceBox>
            </CardTagged>
          </div>
        )
      }

      <div className="interview-profile">
        <CardTagged
          tag={tag}
          title="Perfil das pessoas entrevistadas
"
        >
          <div className="outdoor-box">
            <Outdoor
              loading={loadingAges}
              error={errorAges}
              src={calendarIcon}
              alt="Ícone de uma mulher"
            >
              <Text>
                <ColorText>{maxAge.value}</ColorText> têm {maxAge.name} de
                idade.
              </Text>
            </Outdoor>

            <Outdoor
              loading={loadingEthnicities}
              error={errorEthnicities}
              src={woman3x4Icon}
              alt="Ícone de uma mulher em uma foto 3 por 4"
            >
              <Text>
                <ColorText>{maxEthnicity.value} </ColorText>
                declaram-se {maxEthnicity.name}.
              </Text>
            </Outdoor>

            <Outdoor
              loading={loadingStudyDegrees}
              error={errorStudyDegrees}
              src={hatSchoolIcon}
              alt="Capelo"
            >
              <Text>
                <ColorText>{maxStudyDegree.value} </ColorText> completaram o{' '}
                <ColorText>{maxStudyDegree.name}</ColorText>.
              </Text>
            </Outdoor>

            <Outdoor
              loading={loadingWhoAnswers}
              error={errorWhoAnswers}
              src={womanWithBabyIcon}
              alt="Ícone de uma mulher ao lado de um carrinho de bebê"
            >
              <Text>
                <ColorText>{maxWhoAnswer.value} </ColorText>das mulheres
                entrevistadas são as {maxWhoAnswer.name.toLowerCase()}s das
                crianças beneficiadas.
              </Text>
            </Outdoor>

            <Outdoor
              loading={loadingMaritalStatus}
              error={errorMaritalStatus}
              src={ringsIcon}
              alt="Ícone de duas alianças"
            >
              <Text>
                <ColorText>{maxMaritalStatus.value}</ColorText> são pessoas{' '}
                {maxMaritalStatus.name.toLowerCase()}s.
              </Text>
            </Outdoor>
          </div>
        </CardTagged>
      </div>

      <div className="qualification-course">
        <CardQualificationCourse />
      </div>

      <div className="pregnant">
        <CardPregant />
      </div>

      <div className="ranking">
        <CardWomanImpairmentRanking />
      </div>
    </Box>
  );
}
