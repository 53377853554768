import styled, { css } from 'styled-components';

interface BorderedCardProps {
  minWidth?: string;
}

export const BorderedCard = styled.div<BorderedCardProps>`
  background: ${({ color }) => `${color ?? '#B0D1AA'}1A`};
  border: 1px solid ${({ color }) => color ?? '#B0D1AA'};
  border-radius: 0.5rem;
  height: max-content;
  min-width: 300px;
  /* min-height: 300px; */
  overflow: hidden;

  ${({ theme }) =>
    theme.darkMode &&
    css`
      border-color: #fff;
    `}
`;
