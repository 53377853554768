import { message } from 'antd';
import { CzRMCitizen } from 'big-data';
import { BsShareFill } from 'react-icons/bs';
import { nisReplacer } from './helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { cpfReplacer } from '@/utils/string';

interface TableProps {
  data: CzRMCitizen[];
}

export function Table({ data }: TableProps) {
  const appKey = localStorage.getItem(
    `${process.env.REACT_APP_BIG_DATA_APP_KEY}_token`
  );
  const grant = localStorage.getItem('acesso_cidadao_grant_token_key');

  function copyToClipboard(text: string, result: boolean) {
    if (result) {
      message.success(
        'Link de compartilhamento copiado para área de transferência'
      );
    } else {
      message.error('Erro ao copiar link de compartilhamento!');
    }
  }

  function onRedirect(url: string) {
    if (!!appKey && !!grant) {
      const redirectUri = `${url}&token=${appKey}&grant=${grant}`;
      window.open(redirectUri, '_blank');
    }
  }

  return (
    <table className="czrm-table">
      <thead>
        <tr>
          <th>Nome da Pessoa</th>
          <th>CPF</th>
          <th>NIS</th>
          <th>Município</th>
          <th>Compartilhar</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.map((citizen, i) => {
            const url = `${process.env.REACT_APP_REDIRECT_INTEGRASOCIAL}/geral?cpf=${citizen.cpf}&nis=${citizen.nis}&bases=[{%22id%22:%22agents%22,%22name%22:%22Agentes%22},{%22id%22:%22cadunico%22,%22name%22:%22Cadastro%20%C3%9Anico%22}]`;

            return (
              <tr
                key={citizen.cpf}
                className="redirect-cell"
                onClick={() => onRedirect(url)}
              >
                <td>{citizen.name}</td>
                <td>{cpfReplacer(citizen.cpf, "normal")}</td>
                <td>{nisReplacer(citizen.nis)}</td>
                <td>{citizen.city}</td>
                <td
                  role="button"
                  className="czrm-cell-share"
                  aria-label="Copiar link de compartilhamento da família"
                  onClick={(event) => event.stopPropagation()}
                  title="Copiar link de compartilhamento da família"
                >
                  <CopyToClipboard text={url} onCopy={copyToClipboard}>
                    <span className="czrm-cell-share-icon">
                      <BsShareFill size={20} />
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
