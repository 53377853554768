import { Data, DataTree } from 'big-data';
import { groupBy } from 'ramda';

type EducationKeys = 'creche' | 'pré-escola' | 'escola';

enum TreeColors {
  RED = `#A71416`,
  GREEN = `#4E7345`
}

export const treeGoals: Record<EducationKeys, number> = {
  'pré-escola': 50,
  creche: 100,
  escola: 95
};

export const treeYearGoals: Record<EducationKeys, number> = {
  'pré-escola': 2022,
  creche: 2022,
  escola: 2022
};

const educationLegends = [
  {
    name: `Abaixo da meta`,
    color: TreeColors.RED
  },
  {
    name: `Acima da meta`,
    color: TreeColors.GREEN
  }
];

const { format: percentageFormat } = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const checkValueGoal = (name: string, value: number) =>
  1 - value >= treeGoals[name as EducationKeys];

const checkYearGoal = (name: string) =>
  new Date().getFullYear() <= treeYearGoals[name as EducationKeys];

const checkGoals = (name: string, value: number) =>
  checkValueGoal(name, value) && checkYearGoal(name);

export function getLegendTree(selected?: DataTree) {
  if (!selected) return educationLegends;

  const goals = checkGoals(selected.name, selected.percentageValue);

  return educationLegends.map((legend) => ({
    ...legend,
    selected: legend.name === 'Acima da meta' ? goals : !goals
  }));
}

const getTotals = (data: Record<string, Data[]>) =>
  Object.entries(data)
    .map((data) => [data[0], data[1].reduce((acc, cur) => acc + cur.value, 0)])
    .reduce(
      (acc, cur) => ({ ...acc, [cur[0] as string]: cur[1] as number }),
      {} as Record<string, number>
    );

const getNoCases = (data: Record<string, Data[]>) =>
  Object.entries(data)
    .map((data) => [
      data[0],
      data[1].reduce(
        (acc, cur) => ((cur as any).schoolMode === 'não' ? cur : acc),
        {} as Data
      )
    ])
    .reduce(
      (acc, cur) => ({ ...acc, [cur[0] as string]: cur[1] as Data }),
      {} as Record<string, Data>
    );

export function getTree(data: Data[]): DataTree {
  const byName = groupBy((data: Data) => data.name);
  const groupedData = byName(data);

  const totals = getTotals(groupedData);
  const noCases = getNoCases(groupedData);

  return {
    name: 'Educação',
    value: 0,
    percentageValue: 0,
    color: 'transparent',
    children: Object.entries(noCases).map((d, i) => {
      const [key, data] = d;

      const percentageValue = data.value / totals[key];
      const name = data.name.toLowerCase();

      const color = checkGoals(name, percentageValue)
        ? TreeColors.GREEN
        : TreeColors.RED;

      const tooltipPhrase = `${percentageFormat(
        percentageValue
      )} de crianças fora da ${name}`;

      return {
        ...data,
        percentageValue,
        color: color,
        tooltip: tooltipPhrase,
        renderArcByPercentage: true,
        children: []
      };
    })
  };
}
