import { Box } from './styles';

import { Tap, Toilet } from '../SanitaryCard/Icons';

interface SanitarySimpleCardProps {
  value: number;
}

export function SanitaryFalseCard({ value }: SanitarySimpleCardProps) {
  return (
    <Box>
      <div className="icons">
        <Tap />
        <Toilet />
      </div>
      <p>
        <span className="percentage">{value.toFixed(2)}%</span> das famílias
        cadastradas não possuem banheiro e/ou água encanada em suas residências
        <strong> ou não responderam.</strong>
      </p>
    </Box>
  );
}
