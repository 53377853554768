import { colors } from '@/theme/colors';
import { lighten } from 'polished';
import styled from 'styled-components';

export const Box = styled.div`
  .tabs {
    margin-top: 2rem;
    .ant-tabs-nav {
      background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
      border-radius: 7px;
      border: 1px solid #dfe6e9;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-tabs-nav-list {
      padding: 1rem;
    }

    .ant-tabs-tab-btn {
      text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};
    }

    .ant-tabs-tab-btn,
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: ${(props) =>
        props.theme.darkMode ? props.theme.yellowDark : '#2D3436'};
    }

    .ant-tabs-tab:hover {
      color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${(props) => (props.theme.darkMode ? `#ffff` : colors.ciano700)};
      font-weight: 700;
    }

    .ant-tabs-ink-bar {
      background: ${(props) =>
        props.theme.darkMode ? `#fff` : colors.ciano700};
    }

    @media (max-width: 1030px) {
      .ant-tabs-nav {
        overflow: hidden;
        flex-direction: column;
      }

      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-nav-list {
        flex-direction: column;
        width: 100%;
      }
      .ant-tabs-nav-operations {
        justify-content: center;
      }

      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        display: none;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        &::after {
          content: '';
          position: absolute;
          left: -16px;
          width: 4px;
          height: 40px;
          top: 0;
          background: ${(props) =>
            props.theme.darkMode ? `#fff` : colors.ciano700};
        }
      }
    }
  }
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;

  border: 1px solid ${({ theme }) => theme.yellowDark};
  background-color: ${lighten(0.2, colors.yellow200)};

  border-radius: 4px;

  svg {
    fill: ${({ theme }) => theme.yellowDark};
  }

  span {
    font-size: 0.875rem;
    flex: 1;
  }
`;
