import { BarGroup, Card } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useGeneral } from '@/services/lgbt/general';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';

const COLORS = ['#3CC3CD', '#1D696F'];
const DARK_COLORS = [colors.yellow200, colors.yellow600];
const LEGEND = ['Zona Rural', 'Zona Urbana'];

const labels = [`Todos os municípios`, `Municípios do interior`];

export function ByZone() {
  const { ibge, orientation, city, identity } = useFilterContext();
  const { data, loading, error, request } = useService<Data>();

  const { darkMode } = useTheme();
  const barGroupColors = darkMode ? DARK_COLORS : COLORS;

  useEffect(() => {
    request(() =>
      useGeneral.zone({ ibge, orientation, genderIdentity: identity })
    );
  }, [request, ibge, orientation, identity]);

  const dataFormatted = useMemo(() => {
    const d = data.reduce((acc: any, cur) => {
      const l = cur.name.split(': ');
      const current = { name: l[1], percentageValue: cur.value };
      return { ...acc, [l[0]]: acc[l[0]] ? [acc[l[0]], current] : current };
    }, {});

    return labels.map((label, i) => {
      return { label: labels[i], data: d[label] };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Pessoas LGBTQIA+ por zonas rural e urbana"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <BarGroup
          data={dataFormatted}
          colors={barGroupColors}
          height={37}
          legend={LEGEND}
          labelVisible
        />
      </Card>
    </Section>
  );
}
