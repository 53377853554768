import styled from 'styled-components';

export const Box = styled.div`
  margin: 1rem 0 2rem;

  .city {
    width: 100%;
    margin: 0.8rem 0;

    .label {
      margin-bottom: 0.2rem;
      display: block;

      color: #979797;
      font-weight: 700;

      .optional-text {
        color: #ab1e11;
        font-weight: 400;
      }
    }

    .city-select {
      width: 250px;
      margin-right: 0.5rem;
    }
  }

  .select {
    max-height: 250px;
    width: 360px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  .buttons {
    display: flex;
    margin-top: 1.3rem;

    button:first-child {
      margin-right: 1rem;
    }

    button {
      padding: 0.5rem 1rem;
      border: none;
      background-color: #55a546;
      color: #fff;
      font-weight: 700;
      font-size: 1rem;
      border-radius: 0.5rem;

      display: flex;
      align-items: center;

      transition: transform 0.2s, background-color 0.2s;

      svg {
        margin-top: -3px;
        margin-right: 5px;
      }

      &.remove-button {
        background-color: #ab1e11;

        &:hover {
          background-color: #b82012;
        }
      }

      &:hover {
        background-color: #5ab34b;

        &:active {
          transform: scale(0.99);
        }
      }

      &:disabled {
        background-color: #d4d4d4;
      }
    }
  }
`;
