import { Box } from './styles';

export function BannerText() {
  return (
    <Box>
      <h2 className="title">Big Data Social</h2>

      <p className="subtitle">
        <span>
          Plataforma Analítica da <br /> Proteção Social do Ceará
        </span>
      </p>

      <p className="description">
        <span>
          Ferramenta integrada e transparente da área de Proteção Social do
          Ceará para gestão pública.
        </span>
      </p>
    </Box>
  );
}
