import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { useJobIncome } from '@/services/lgbt/jobIncome';
import { colors } from '@/theme/colors';
import { Data } from 'big-data';
import { useEffect } from 'react';

export function MonthlyIncome() {
  const { city, orientation, ibge, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useJobIncome.monthlyIncome({
        ibge,
        orientation,
        genderIdentity: identity
      })
    );
  }, [request, ibge, orientation, identity]);

  return (
    <Section>
      <Card
        title="Renda mensal das pessoas LGBTQIA+ entrevistadas"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <HorizontalBarSimple
          maxValue={6}
          sizeRatio={0.7}
          grid
          hasPagination
          isPercentage
          data={data}
          percentageCalculatedOut
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
