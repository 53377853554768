import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { useEffect, useMemo, useState } from 'react';

export function useQuantity(programName?: string) {
  const [quantity, setQuantity] = useState(0);
  const [loadingQuantity, setLoadingQuantity] = useState(false);
  const [errorQuantity, setErrorQuantity] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingQuantity(true);
    const requests = {
      creche: education.getNursery,
      'pré-escola': education.getPreSchool,
      escola: education.getSchool
    };

    if (!!programName) {
      requests[programName.trim() as 'creche' | 'pré-escola' | 'escola']({
        hasFilter: false,
        variants: ['não'],
        ibge,
        year,
        semester,
        zone
      })
        .then((response) => {
          const total = response.reduce((acc, cur) => acc + cur.value, 0);

          setQuantity(total);
        })
        .catch((error) => setErrorQuantity(error))
        .finally(() => setLoadingQuantity(false));
    } else {
      Promise.all(
        Object.values(requests).map((request) =>
          request({
            hasFilter: false,
            variants: ['não'],
            ibge,
            year,
            semester,
            zone
          })
        )
      )
        .then((responses) => {
          const [nursery, preSchool, school] = responses;

          const nurseryTotal = nursery.reduce((acc, cur) => acc + cur.value, 0);
          const preSchoolTotal = preSchool.reduce(
            (acc, cur) => acc + cur.value,
            0
          );

          const schoolTotal = school.reduce((acc, cur) => acc + cur.value, 0);

          setQuantity(nurseryTotal + preSchoolTotal + schoolTotal);
        })
        .catch((error) => setErrorQuantity(error))
        .finally(() => setLoadingQuantity(false));
    }
  }, [programName, ibge, year, semester, zone]);

  return useMemo(
    () => ({
      quantity,
      loadingQuantity,
      errorQuantity
    }),
    [quantity, loadingQuantity, errorQuantity]
  );
}
