import { colors } from '@/theme/colors';
import styled from 'styled-components';

interface BoxListItemProps {
  isRead?: boolean;
}

export const BoxListItem = styled.li<BoxListItemProps>`
  transition: background-color 0.2s;
  background-color: ${({ isRead }) =>
    isRead ? `${colors.green300}66` : `${colors.yellow200}66`};
  padding: 1rem;

  & + & {
    border-top: 1px solid ${colors.white};
  }

  h4 {
    font-size: 0.9rem;
    max-width: 320px;
  }
`;
