export function GunIcon() {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1847 0.0151051C19.2033 0.317819 19.2161 0.543983 19.2324 0.807263L20.0011 0.878014V3.28697L19.2685 3.35076C19.2338 3.62217 19.1797 3.89077 19.1066 4.15452C18.9086 4.72051 18.4463 5.00351 17.7311 5.00583C15.9457 5.01278 14.1602 5.00583 12.3736 5.00583H11.726C11.6759 5.48135 11.6771 5.90933 11.5804 6.31411C11.1809 7.99701 9.79963 9.07101 7.99669 9.15103C7.48423 9.17423 6.80988 9.04665 6.50822 9.31689C6.23103 9.56625 6.39175 10.2656 6.29275 10.7539C6.08428 11.7688 5.88977 12.794 5.57764 13.7787C5.31209 14.615 4.64589 15.0302 3.7351 14.9838C3.36706 14.96 2.99786 14.96 2.62982 14.9838C0.578802 15.1462 0.0675052 13.737 0.0081062 12.4055C-0.0629397 10.9174 0.330724 9.50014 0.756999 8.09328C1.00391 7.2814 1.25781 6.46952 1.52453 5.66808C1.70971 5.10905 1.50356 4.74023 1.01323 4.46999C0.857938 4.38803 0.705365 4.30413 0.555509 4.2183C0.0896339 3.95386 -0.143304 3.57576 0.119915 3.06544C0.494944 2.35447 0.858327 1.62378 1.33818 0.985877C1.87393 0.272585 2.64263 -0.00925162 3.57903 -0.0034525C8.54759 0.0278627 13.515 0.0116258 18.487 0.0116258L19.1847 0.0151051ZM6.45698 7.4171C9.05539 7.7059 10.2632 6.85458 9.95103 5.0557H7.83946C8.11665 5.4338 8.34027 5.71332 8.53827 6.01255C8.6102 6.15484 8.66494 6.30512 8.70132 6.46025C8.53943 6.48228 8.35192 6.56579 8.21915 6.5136C7.74162 6.32338 7.28157 6.08678 6.69457 5.8177L6.45698 7.4171Z"
        fill="white"
      />
    </svg>
  );
}
