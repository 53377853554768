import { RequestFilterFunction, RequestMap, ResponseMap } from '../@types';
import { geolocation, Geolocation } from '@/services';
import { Options } from './objects';
import {
  getVisibilityRecord,
  isEducation,
  isInsecurityFood,
  isSocialAssistence,
  isHealth,
  isWork
} from './functions';

type Location = {
  data: Record<string, Geolocation[]>;
  visibilities: Record<string, boolean>;
};

type LocationParams = {
  filters: string[];
  city?: string;
  min?: number[];
  max?: number[];
};

type RequestParams = {
  filter: string;
} & Omit<LocationParams, 'filters'>;

function getInsecurityFood({ filter, city, min, max }: RequestParams) {
  const classification = geolocation.insecurityFood.classification();

  return classification.request(filter, city, min, max);
}

function getSocialAssistance({ filter, city, min, max }: RequestParams) {
  const assistance = geolocation.socialAssistance.assistance();

  return assistance.request(filter, city, min, max);
}

function getEducation({ filter, city, min, max }: RequestParams) {
  const educationLevel = geolocation.education.educationLevel();

  return educationLevel.request(filter, city, min, max);
}

function getWork({ filter, city, min, max }: RequestParams) {
  const paidWorkInfo = geolocation.work.paidWorkInfo();

  return paidWorkInfo.request(filter, city, min, max);
}

function getIncome({ filter, city, min, max }: RequestParams) {
  const incomeWorkInfo = geolocation.income.incomeWorkInfo();

  return incomeWorkInfo.request(filter, city, min, max);
}

function getHealth({ filter, city, min, max }: RequestParams) {
  if (filter === Options.NOVACCINE) {
    const vaccineInfo = geolocation.health.vaccine();
    return vaccineInfo.request(filter, city, min, max);
  }

  if (filter === Options.NOHEALTHATTENDED) {
    const healthMonitoring = geolocation.health.healthMonitoring();
    return healthMonitoring.request(filter, city, min, max);
  }

  const pregnantHealth = geolocation.pregnantHealth.prenatalCare();
  return pregnantHealth.request(filter, city, min, max);
}

export function getRequests({
  filters,
  city,
  min,
  max
}: LocationParams): RequestMap<Geolocation[]>[] {
  return filters.map((filter) => {
    if (isInsecurityFood(filter)) {
      return { filter, city, min, max, request: getInsecurityFood };
    }

    if (isEducation(filter)) {
      return { filter, city, min, max, request: getEducation };
    }

    if (isSocialAssistence(filter)) {
      return { filter, city, min, max, request: getSocialAssistance };
    }

    if (isWork(filter)) {
      return { filter, city, min, max, request: getWork };
    }

    if (isHealth(filter)) {
      return { filter, city, min, max, request: getHealth };
    }

    return { filter, city, min, max, request: getIncome };
  });
}

export const getRequestFilter = ((values: string[]) => {
  if (getRequestFilter.requestFilter === undefined) {
    getRequestFilter.requestFilter = {
      Creche: Options.BABYSCHOOL,
      'Pré-escola': Options.CHILDSCHOOL,
      Escola: Options.SCHOOL,
      'Não assistido pelo CRAS': Options.NOWATCHCRAS,
      'Não sabe se tem CRAS': Options.DONTKNOWCRAS,
      'Não assistido pelo CREAS': Options.NOWATCHCREAS,
      'Não sabe se tem CREAS': Options.DONTKNOWCREAS,
      'O município não possui CREAS': Options.NOBUILDCREAS,
      'Fora de Insegurança Alimentar': Options.NOIA,
      'Insegurança Alimentar Leve': Options.IALIGHT,
      'Insegurança Alimentar Moderada': Options.IAMODERATE,
      'Insegurança Alimentar Grave': Options.IASERIOUS,
      'Não tem trabalho remunerado': Options.NOWORK,
      'Não sabe se alguém tem trabalho remunerado': Options.DONTKNOWORK,
      'Acima de três salários': Options.MORETHREEINCOME,
      'Entre dois e três salários': Options.THREETWOINCOME,
      'Entre um e dois salários': Options.TWOONEINCOME,
      'Entre meio e um salário': Options.ONEINCOME,
      'Até meio salário mínimo': Options.HALFINCOME,
      'Crianças com calendário vacinal incompleto': Options.NOVACCINE,
      'Crianças sem acompanhamento da equipe de saúde':
        Options.NOHEALTHATTENDED,
      'Grávidas sem acompanhamento pré natal': Options.PRENATALPREGNANT
    };
  }

  return values.map((value) => getRequestFilter.requestFilter[value]);
}) as RequestFilterFunction;

export function getResponseData(
  filters: string[],
  data: Geolocation[][]
): ResponseMap<Geolocation[]>[] {
  return filters.map((filter, i) => ({ filter, data: data[i] }));
}

export function getResponseRecord(response: ResponseMap<Geolocation[]>[]) {
  return response.reduce((acc, cur) => {
    const key = cur.filter;

    return { ...acc, [key]: cur.data };
  }, {} as Record<string, Geolocation[]>);
}

export async function getLocations({
  filters,
  city,
  min,
  max
}: LocationParams): Promise<Location> {
  const requests = getRequests({ filters, city: city || undefined, min, max });
  const requestPromises = requests.map(({ request, filter, city, min, max }) =>
    request({ filter, city, min, max })
  );

  try {
    const locations = await Promise.all(requestPromises);
    const responseData = getResponseData(filters, locations);

    return {
      data: getResponseRecord(responseData),
      visibilities: getVisibilityRecord(responseData)
    };
  } catch (err) {
    return Promise.reject(err);
  }
}
