import { ensureAxiosParamOptions } from '@/utils/routes';
import { DashboardRequestParams } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

interface LivingConditionAgentsQuery extends DashboardRequestParams {
  aggregations: string[];
}

const service = () => {
  const resource = `/condicoes-moradia-agentes`;

  async function generateGeneralRequest(
    path: string,
    params: LivingConditionAgentsQuery
  ) {
    const { aggregations, ibge, semester, year, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        year,
        semester,
        ibge,
        zone
      }
    });

    return await callService(() => mainApi.get<any[]>(path, options));
  }

  async function getTotal(filter?: string) {
    const path = resource;
    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map((d) => ({
      name: 'quantidade',
      value: d.quantidade
    }));
  }

  async function getCities(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['IBGE', 'MUNICIPIO'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.municipio,
      codIbge: d.ibge,
      value: d.quantidade
    }));
  }

  async function getZone(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['ZONA', ibge ? ['MUNICIPIO', 'IBGE'].join(', ') : ''];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.zona,
      value: d.quantidade
    }));
  }

  async function getExternalWallMaterial(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'MATERIAL_PREDOMINANTE_PAREDE_EXTERNA_CASA',
      ibge ? ['MUNICIPIO', 'IBGE'].join(', ') : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.material_predominante_parede_externa_casa,
      value: d.quantidade
    }));
  }

  async function getHouseType(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['CASA_FAMILIA_MORA_E', ibge ? 'MUNICIPIO' : ''];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.casa_familia_mora_e,
      value: d.quantidade
    }));
  }

  async function getPipedWaterAnswers(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'DOMICILIO_POSSUI_AGUA_CANALIZADA_PELOMENOS_UMCOMODO',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.domicilio_possui_agua_canalizada_pelomenos_umcomodo,
      value: d.quantidade
    }));
  }

  async function getWaterSupplyTypes(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'ABASTECIMENTO_DE_AGUA_UTILIZADO',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.abastecimento_de_agua_utilizado,
      value: d.quantidade
    }));
  }

  async function getDrankWaterTypes(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'AGUA_UTILIZADA_PARA_BEBER_E',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.agua_utilizada_para_beber_e,
      value: d.quantidade
    }));
  }

  async function getBathroomAmount(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'EXISTE_BANHEIRO',
      ibge ? ['MUNICIPIO', 'IBGE'].join(', ') : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.existe_banheiro,
      value: d.quantidade
    }));
  }

  async function getGarbageCollection(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'QTS_VEZES_LIXO_PASSA_SEMANA',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });
    return response.data.map((d) => ({
      name: d.qts_vezes_lixo_passa_semana,
      value: d.quantidade
    }));
  }

  async function getLightType(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'ILUMINACAO_UTILIZADA',
      ibge ? ['MUNICIPIO', 'IBGE'].join(', ') : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.iluminacao_utilizada,
      value: d.quantidade
    }));
  }

  async function getCookWays(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'COMO_VC_COZINHA_NO_DOMICILIO',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.como_vc_cozinha_no_domicilio,
      value: d.quantidade
    }));
  }

  async function getKidsRecreation(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'EXISTEM_LUGARES_PARA_CRIANCA_BRINCAR_PROXIMA_CASA',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.existem_lugares_para_crianca_brincar_proxima_casa,
      value: d.quantidade
    }));
  }

  async function getKidsCulturalActivities(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'EXISTEM_ATIVIDADES_CULTURAIS_PARA_JOVENS_PROXIMO_CASA',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.existem_atividades_culturais_para_jovens_proximo_casa,
      value: d.quantidade
    }));
  }

  async function getSportActivities(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'EXISTEM_LOCAIS_PARA_ATIVIDADES_ESPORTIVAS_PROXIMA_CASA',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.existem_locais_para_atividades_esportivas_proxima_casa,
      value: d.quantidade
    }));
  }

  async function getHouseViolenceSituation(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = [
      'DOMICILIO_LOCALIZADO_AREA_VIOLENCIA',
      ibge ? 'MUNICIPIO' : ''
    ];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.domicilio_localizado_area_violencia,
      value: d.quantidade
    }));
  }

  return {
    getCities,
    getZone,
    getExternalWallMaterial,
    getHouseType,
    getPipedWaterAnswers,
    getWaterSupplyTypes,
    getDrankWaterTypes,
    getBathroomAmount,
    getGarbageCollection,
    getLightType,
    getCookWays,
    getKidsRecreation,
    getKidsCulturalActivities,
    getSportActivities,
    getHouseViolenceSituation,
    getTotal
  };
};

export const livingConditionAgents = service();
