import successImg from '@/assets/icons/success-feedback.svg';
import { FeedbackButton } from '@/components/styled';
import { colors } from '@/theme/colors';
import { Box } from './styles';

type Type = 'error' | 'success';

interface SuccessFeedbackBoxProps {
  type: Type;
  onClick?: () => void;
  message?: string;
}

const feedbackLayout = (errorMessage?: string): Record<Type, JSX.Element> => {
  return {
    error: (
      <>
        <span>😥</span>
        <p className="error">
          {errorMessage ?? 'Ops... Um erro foi identificado. Tente novamente.'}
        </p>
      </>
    ),

    success: (
      <>
        <img src={successImg} alt="Sucesso" />
        <p>Agradecemos pelo feedback!</p>
      </>
    )
  };
};

export function FeedbackStatusBox({
  type,
  onClick,
  message
}: SuccessFeedbackBoxProps) {
  const bgButton = type === 'success' ? colors.green600 : colors.red400;
  const feedbackMessage = feedbackLayout(message);

  return (
    <Box>
      {feedbackMessage[type]}
      {!!onClick && (
        <FeedbackButton
          style={{ background: bgButton }}
          width="max-content"
          onClick={onClick}
        >
          Quero enviar outro
        </FeedbackButton>
      )}
    </Box>
  );
}
