import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

interface BoxProps {
  isClientComment: boolean;
}

export const Box = styled.div<BoxProps>`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.background : colors.gray100};
  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};
  padding: 0.8rem 1rem;
  margin-top: 0.2rem;
  border-radius: 7px;
  max-width: 400px;
  overflow-wrap: break-word;
  border: ${({ theme }) =>
    theme.darkMode ? `1px solid ${colors.white}` : `none`};

  text-align: ${({ isClientComment }) => (isClientComment ? 'right' : 'left')};

  position: relative;
  order: 2;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    border-top: 15px solid
      ${({ theme }) => (theme.darkMode ? theme.background : colors.gray100)};

    ${({ isClientComment }) => getArrowSide(isClientComment)};
  }

  @media (max-width: 400px) {
    max-width: 180px;
  }

  .from-message {
    margin-block-end: 1rem;
    background-color: ${colors.ciano700}4D;
    border-radius: 7px;
    padding: 0.2rem;
    padding-inline: 0.7rem;
    border-left: 2px solid ${colors.ciano700};
  }
`;

const getArrowSide = (isClientComment: boolean) => {
  if (isClientComment) {
    return css`
      right: -8px;
      border-right: 15px solid transparent;
    `;
  }

  return css`
    left: -8px;
    border-left: 15px solid transparent;
  `;
};
