import { Section } from '@/layout';
import { Card, D3Map } from '@/components/shared';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { homeVisits } from '@/services';
import { max } from '@/utils/math';
import { DataWithProfile } from 'big-data';
import { useEffect, useMemo } from 'react';

interface BeneficiariesMapProps {
  color: string;
}

export function BeneficiariesMap({ color }: BeneficiariesMapProps) {
  const { data, loading, error, request } = useService<DataWithProfile>();

  const { city, profilePopulation, onChangeCity } = useAnalyticsFilter();
  useEffect(() => {
    request(() =>
      homeVisits.getCities('beneficiaries', profilePopulation || undefined)
    );
  }, [request, profilePopulation]);

  const filteredData = useMemo(() => {
    return data
      .filter((d) => (!!city ? city.toLowerCase() === d.name.toLowerCase() : d))
      .filter((d) =>
        !!profilePopulation ? profilePopulation === d.profile : d
      );
  }, [data, city, profilePopulation]);

  const maxValue = useMemo(() => max(data.map((d) => d.value)), [data]);

  return (
    <Section>
      <Card
        title="Número de famílias beneficiadas pelos programas de visitas domiciliares"
        empty={filteredData.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[
          city ? city : 'Ceará',
          profilePopulation
            ? profilePopulation.replace('+', ' e ')
            : 'Todos os perfis'
        ]}
      >
        <D3Map
          maxValue={maxValue}
          data={filteredData}
          color={color}
          filteringCity={city}
          handleSelectedCity={onChangeCity}
        />
      </Card>
    </Section>
  );
}
