import { Section } from '@/layout';
import { Card, DonutPopulated } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { leaderMovements } from '@/services/leaderMovements';
import { colors } from '@/theme/colors';

export function LeaderDeficiency() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderMovements.deficiency());
  }, [request]);

  const formatedDataDonut = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        color:
          d.name === 'Não são pessoas com deficiência'
            ? colors.yellow500
            : colors.orange300
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Pessoas com deficiência nas lideranças"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <DonutPopulated
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedDataDonut}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
