import { BeneficiariesMap } from './BeneficiariesMap';
import { Beneficiaries } from './Beneficiaries';
import { Visited } from './Visited';
import { VisitedKids } from './VisitedKids';

export const IndicatorSection = {
  Beneficiaries,
  BeneficiariesMap,
  Visited,
  VisitedKids
};
