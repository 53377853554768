import { useState, useMemo, useCallback } from 'react';

type Service<T> = () => Promise<T[]>;

export function useService<T>() {
  const [data, setData] = useState<T[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback((service: Service<T>) => {
    setLoading(true);
    setError(null);

    service()
      .then((_data) => setData(_data.filter((d: any) => d.name !== 'null')))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return useMemo(
    () => ({ data, error, loading, request }),
    [data, error, loading, request]
  );
}
