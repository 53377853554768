import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const TagText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;

  background: ${({ color }) => (color ? color : 'transparent')};
  padding: ${({ color }) => (color ? '0.2rem 1.3rem' : '0.2rem 0.5rem')};
  border-radius: 1.2rem;
  color: white;
  height: min-content;
  cursor: default;

  text-align: center;

  .block-icon {
    margin-top: 4px;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      color: ${({ theme }) =>
        theme.darkMode ? colors.gray400 : colors.blue600};
    }
  }
`;
