import styled from 'styled-components';

interface Props {
  loading: boolean;
  error?: string;
}

export const LoadingStatus = ({ loading, error }: Props) => {
  return (
    <Wrapper>
      {loading && <Text>Carregando...</Text>}
      {error && <Text>{error}</Text>}
    </Wrapper>
  );
};

const Text = styled.p``;

const Wrapper = styled.div`
  font-size: 1rem;
  text-align: center;
  color: ${(props) => (props.theme.darkMode ? `#fff5` : `#2d404055`)};
`;
