import { useDashboardFilter } from '@/hooks';
import { jobIncome } from '@/services';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

const requests = [
  jobIncome.getPaidJobActionRegistration,
  jobIncome.getIncome,
  jobIncome.getQualificationCoursesInTheLast12Months,
  jobIncome.getWouldDoQualificationCourses,
  jobIncome.getSourceOfIncome
];

function resolveJobByActionIndicator(
  data: Data[],
  indicator: string,
  { nameIncludes }: { nameIncludes: string }
) {
  return data.map((d) => {
    if (
      d.name.toLowerCase().includes(nameIncludes.toLowerCase()) &&
      !d.name.includes(';')
    ) {
      return {
        name: indicator,
        value: d.value
      };
    }

    return d;
  });
}

export function useJobIncomeActionRegistration() {
  const [data, setData] = useState<Record<string, Data[]>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    Promise.all<Data[]>(
      requests.map((request) => request({ ibge, year, semester, zone }))
    )
      .then(
        ([
          paidJob,
          getIncome,
          qualificationCourses,
          wouldDoQualificationCourses,
          sourceOfIncome
        ]) => {
          const newGetIncome = resolveJobByActionIndicator(
            getIncome,
            'Representantes familiares que possuem até meio salário mínimo',
            {
              nameIncludes: 'até meio salário mínimo'
            }
          );

          const newSourceOfIncome = resolveJobByActionIndicator(
            sourceOfIncome,
            'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância',
            {
              nameIncludes: 'bolsa família / Cartão Mais Infância'
            }
          );

          return [
            paidJob,
            newGetIncome,
            qualificationCourses,
            wouldDoQualificationCourses,
            newSourceOfIncome
          ];
        }
      )
      .then(
        ([
          paidJob,
          getIncome,
          qualificationCourses,
          wouldDoQualificationCourses,
          sourceOfIncome
        ]) => {
          const _data = {
            'Representantes familiares sem trabalho remunerado': paidJob,
            'Representantes familiares que possuem até meio salário mínimo':
              getIncome,
            'Representantes familiares que não realizaram cursos de capacitação':
              qualificationCourses,
            'Representantes familiares que têm interesse em realizar curso de capacitação':
              wouldDoQualificationCourses,
            'Representantes familiares cuja principal fonte de renda é bolsa família / Cartão Mais Infância':
              sourceOfIncome
          };

          setData(_data);
        }
      )
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return {
    granularData: data,
    loading,
    error
  };
}
