import styled from 'styled-components';

export const Box = styled.button`
  background: transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 0;
  padding: 0.5rem;
  width: 60px;
  height: 60px;

  span {
    color: #fff;
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
