import { useState } from 'react';

import { CzrmCard, CzRMTableModal, FilterDashboard } from '@/components/shared';
import { AxeDescriptionText } from '@/components/styled';
import { useDashboardTitle } from '@/hooks';

import { HealthInsights } from '../components';

import { Box } from './styles';
import { MainCards } from '../style';

const description =
  'De acordo com a Constituição de 1988, artigo 196, a saúde é direito de todos e dever do Estado, garantido mediante políticas sociais e econômicas que visem à redução do risco de doença e de outros agravos e ao acesso universal e igualitário às ações e serviços para sua promoção, proteção e recuperação. ';

export function Health() {
  useDashboardTitle('Pesquisa dos Agentes Sociais sobre Saúde');

  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenCzRMModal() {
    setIsModalOpen(true);
  }

  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>
      <FilterDashboard />
      <MainCards>
        <CzrmCard
          color="#1D696F"
          title="das famílias em "
          name="saúde"
          onClick={handleOpenCzRMModal}
        />
      </MainCards>
      <HealthInsights />

      <CzRMTableModal
        hasIndicators
        hasCategory
        axe="health"
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={`Saúde`}
      />
    </Box>
  );
}
