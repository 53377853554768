import styled from 'styled-components';

import { CardTagged, Outdoor, ServiceBox } from '@/components/shared';
import { ColorText, Text } from '@/components/styled';
import { useParentInterestsData } from '@/servicesHooks/education';
import { useDashboardFilter } from '@/hooks';
import { colors } from '@/theme/colors';
import { dotsNumber, percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';

import { Axe } from '../EducationInsights/type';

import walkingParentsIcon from '@/assets/icons/walking-parents.svg';
import xIcon from '@/assets/icons/x-icon.svg';
import dontKnowIcon from '@/assets/icons/no-program.svg';

const dataMap = new Map([
  [
    'Sim',
    {
      img: walkingParentsIcon,
      alt: 'Pessoas andando',
      description: (value: string) => (
        <Text>
          <ColorText color={colors.green600}>{value}</ColorText> têm interesse
          em matricular as crianças na escola pública.
        </Text>
      )
    }
  ],
  [
    'Não',
    {
      img: xIcon,
      alt: 'Icone de negação',
      description: (value: string) => (
        <Text>
          <ColorText color={colors.red400}>{value}</ColorText> não têm interesse
          em matricular as crianças na escola pública.
        </Text>
      )
    }
  ],
  [
    'Não respondeu',
    {
      img: dontKnowIcon,
      alt: 'Icone de interrogação',
      description: (value: string) => (
        <Text>
          <ColorText color={colors.yellow500}>{value}</ColorText> não
          responderam se têm interesse em matricular as crianças.
        </Text>
      )
    }
  ]
]);

export function EducationParentInterest({ color }: Axe) {
  const { tag } = useDashboardFilter();

  const { parentInterests, parentInterestLoading, parentInterestError, parentInterestRejecteds } =
    useParentInterestsData();

  const total = sumDataTotal(parentInterests);
  const yesPercentage =
    (sumDataTotal(parentInterests.filter((data) => data.name === 'Sim')) /
      total) *
    100;
  const noPercentage =
    (sumDataTotal(parentInterests.filter((data) => data.name === 'Não')) /
      total) *
    100;
  const noAnsweredPercentage =
    (sumDataTotal(parentInterests?.filter((data) => data.name === 'null')) /
      total) *
    100;

  const percentages = [
    {
      name: 'Não respondeu',
      value: noAnsweredPercentage,
      color: colors.yellow200
    },
    { name: 'Sim', value: yesPercentage, color: colors.green200 },
    { name: 'Não', value: noPercentage, color: colors.red400 }
  ]
    .sort((a, b) => b.value - a.value)
    .map((percentage) => ({
      ...percentage,
      value: percentageFormatter(percentage.value)
    }));

  const rejectedsText =
    parentInterestRejecteds.length === 0 ?
      "" : parentInterestRejecteds.length === 1 ?
        parentInterestRejecteds[0] : parentInterestRejecteds.length === 2 ?
          `${parentInterestRejecteds[0]} e ${parentInterestRejecteds[1]}` :
          parentInterestRejecteds.slice(0, -1).join(", ") + " e " + parentInterestRejecteds[parentInterestRejecteds.length - 1];

  return (
    <CardTagged
      tag={tag}
      color={color}
      title="Famílias interessadas em matricular as crianças na escola pública"
    >
      <ServiceBox
        loading={parentInterestLoading}
        error={parentInterestError}
        empty={parentInterests.length === 0}
      >
        <Box>
          <Text>
            Nas <ColorText color={color}>{dotsNumber(total)}</ColorText>{' '}
            famílias com crianças fora da educação básica:
          </Text>

          <ul>
            <li>
              {percentages.map((percentage) => {
                const data = dataMap.get(percentage.name);

                return (
                  <Outdoor
                    key={percentage.name}
                    alt={data?.alt ?? ''}
                    error={null}
                    src={data?.img ?? ''}
                    loading={false}
                    color={percentage.color}
                  >
                    {data?.description(percentage.value)}
                  </Outdoor>
                );
              })}
            </li>
            {
              parentInterestRejecteds.length > 0 && (
                <li style={{ color: colors.gray500 }}>
                  Houve uma falha na busca dos dados. Então não estamos considerando informações sobre {rejectedsText}.
                </li>
              )
            }
          </ul>
        </Box>
      </ServiceBox>
    </CardTagged>
  );
}

const Box = styled.div`
  ul {
    list-style: none;
    margin-top: 1.5rem;
  }
`;
