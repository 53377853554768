import { familiesService } from '@/services/families';
import { useEffect, useMemo, useState } from 'react';

export function useFamilies() {
  const [quantityTotal, setQuantityTotal] = useState(0);
  const [quantityCitiesTotal, setQuantityCitiesTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);


  useEffect(() => {
    setLoading(true);

    const promises = [
      familiesService.getTotal(),
      familiesService.getTotalCities(),
    ];
    Promise.all(promises)
      .then(([family, cities]) => {
        setQuantityTotal(family);
        setQuantityCitiesTotal(cities);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return useMemo(
    () => ({
      quantityTotal,
      quantityCitiesTotal,
      loading,
      error
    }),
    [quantityTotal, quantityCitiesTotal, loading, error]
  );
}
