import { colors } from '@/theme/colors';

export const foo = {};

// ENUMS

export enum InsecurityFoodIndicators {
  FIA = 'FORA_DE_INSEGURANCA_ALIMENTAR',
  IAL = 'INSEGURANCA_ALIMENTAR_LEVE',
  IAM = 'INSEGURANCA_ALIMENTAR_MODERADA',
  IAG = 'INSEGURANCA_ALIMENTAR_GRAVE'
}

export enum HealthIndicators {
  GSPN = 'GESTANTES SEM ACOMPANHAMENTO PRÉ-NATAL',
  CSES = 'CRIANÇAS SEM ACOMPANHAMENTO DE EQUIPE DE SAÚDE',
  CEVI = 'CRIANÇAS COM ESQUEMA VACINAL INCOMPLETO'
}

export enum EducationIndicators {
  CRECHE = 'CRECHE',
  PRE_ESCOLA = 'PRÉ-ESCOLA',
  ESCOLA = 'ESCOLA'
}

export enum SocialAssistanceIndicators {
  CRAS = 'CRAS',
  CREAS = 'CREAS'
}

export enum Axes {
  InsecurityFood = 'Insegurança Alimentar',
  SocialAssistance = 'Assistência Social',
  Education = 'Educação',
  Health = 'Saúde',
  JobIncoming = 'Trabalho e Renda'
}

export enum JobIncomingIndicators {
  Job = 'NÃO POSSUEM TRABALHO REMUNERADO',
  Salary = 'POSSUEM RENDA MÉDIA MENSAL DE ATÉ MEIO SALÁRIO MÍNIMO'
}

// RECORDS

export const insecurityFoodEnumKeys: Record<string, string> = {
  'fora de insegurança alimentar': 'FIA',
  'insegurança alimentar leve': 'IAL',
  'insegurança alimentar moderada': 'IAM',
  'insegurança alimentar grave': 'IAG'
};

export const insecurityFoodColors: Record<string, string> = {
  FIA: '#57ac4a',
  IAL: '#ebad28',
  IAM: '#ea9753',
  IAG: '#a71416'
};

export const educationEnumKeys: Record<string, string> = {
  particular: 'Tem acesso a educação particular',
  pública: 'Tem acesso a educação pública',
  não: 'Não tem acesso a educação'
};

export const socialAssistanceOptionsKeys: Record<string, string> = {
  Sim: 'YES',
  Não: 'NO',
  'Não sei': 'DONTKNOW',
  'O município não possui CREAS': 'THEREISNOCREAS'
};

export const healthOptionsKeys: Record<string, string> = {
  Sim: 'YES',
  Não: 'NO',
  'Não sei': 'DONTKNOW',
  'Não sabe': 'DONTKNOW',
  'Não, visto na caderneta': 'NO_PAPER',
  'Não, informado pela cuidador(a)': 'NO_CAREGIVER',
  'Sim, visto na caderneta': 'YES_PAPER',
  'Sim, informado pela cuidador(a)': 'YES_CAREGIVER'
};

export const healthIndicatorsKeys: Record<string, string> = {
  'Crianças acompanhadas por equipes de saúde da família': 'CHILD_ACC',
  'Crianças com o esquema vacinal em dia': 'CHILD_VACCINE',
  'Assistência Pré-Natal': 'PREGNANT'
};

export const JobIncomingType: Record<string, string> = {
  Renda: 'INCOME',
  Trabalho: 'JOB'
};

export const socialAssistanceLabels: Record<string, string> = {
  YES: 'assistido pelo ',
  NO: 'não assistido pelo ',
  DONTKNOW: 'não sei',
  THEREISNOCREAS: 'O município não possui '
};

export const socialAssistanceColors: Record<string, string> = {
  YES: '#84C574',
  NO: '#D4282A',
  DONTKNOW: '#979797',
  THEREISNOCREAS: '#561011'
};

export const healthColors: Record<string, string> = {
  YES: '#84C574',

  YES_CAREGIVER: '#84C574',
  YES_PAPER: '#4E7345',

  NO_CAREGIVER: '#D4282A',
  NO_PAPER: '#561011',

  NO: '#D4282A',
  DONTKNOW: '#979797',
  THEREISNOCREAS: '#E26567'
};

export const JobIncomeOptionsKeys: Record<string, string> = {
  'Entre um e dois salários': 'ONE_TWO',
  'Entre meio e um salário': 'HALF_ONE',
  'Acima de três salários': 'MORE_THREE',
  'Até meio salário mínimo': 'LESS_HALF',
  'Entre dois e três salários': 'TWO_THREE',
  Sim: 'YES',
  Não: 'NO',
  'Não sabe': 'DONTKNOW'
};

export const healthLabelsPregnant: Record<string, string> = {
  YES: 'GESTANTES ACOMPANHADAS',
  NO: 'GESTANTES NÃO ACOMPANHADAS',
  DONTKNOW: 'NÃO SABE'
};

export const childAccLabels: Record<string, string> = {
  YES: 'ACOMPANHADAS',
  NO: 'NÃO ACOMPANHADAS',
  DONTKNOW: 'NÃO SABE'
};

export const childVaccine: Record<string, string> = {
  NO_PAPER: 'NÃO, VISTO NA CADERNETA',
  NO_CAREGIVER: 'NÃO, INFORMADO PELO CUIDADOR(A)',
  YES_PAPER: 'SIM, VISTO NA CADERNETA',
  YES_CAREGIVER: 'SIM, INFORMADO PELO CUIDADOR(A)'
};

export const healthLabels: Record<string, Record<string, string>> = {
  CHILD_ACC: childAccLabels,
  CHILD_VACCINE: childVaccine,
  PREGNANT: healthLabelsPregnant
};

export const JobIncomeColors: Record<string, string> = {
  LESS_HALF: '#561011',
  HALF_ONE: '#D4282A',
  ONE_TWO: '#88C77F',
  TWO_THREE: '#57AC4B',
  MORE_THREE: '#2D4040',
  NO: '#D4282A',
  DONTKNOW: '#979797',
  YES: '#84C574'
};

const IncomeLabels: Record<string, string> = {
  ONE_TWO: 'Renda entre um e dois salários',
  HALF_ONE: 'Renda entre meio e um salário',
  MORE_THREE: 'Renda acima de três salários',
  LESS_HALF: 'Renda de até meio salário mínimo',
  TWO_THREE: 'Entre dois e três salários'
};

const JobLabels: Record<string, string> = {
  YES: 'TEM TRABALHO REMUNERADO',
  NO: 'NÃO TEM TRABALHO REMUNERADO',
  DONTKNOW: 'NÃO SABE'
};

export const JobIncomeLabels: Record<string, Record<string, string>> = {
  INCOME: IncomeLabels,
  JOB: JobLabels
};

// ARRAYS

export const educationColors: Record<string, string> = {
  não: colors.red400,
  pública: colors.green200,
  particular: colors.yellow700
};

// DEFAULTS

export const DEFAULT_CREAS = { name: 'CREAS', value: 0 };
export const DEFAULT_CRAS = { name: 'CRAS', value: 0 };
export const DEFAULT_NOINFO = { name: 'Sem Informação', value: 0 };
