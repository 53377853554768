import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BigDataPrivateRoute, BigDataRoute } from '@/types/routes';
import { getRolesChecker } from '@/utils/routes';
import { useAuth } from '@/hooks';

import { ScreenSmall } from './screenSmall';
import { ScreenLarge } from './screenLarge';
import { createMenu } from './utils/resolvers';

interface MenuProps {
  routes?: BigDataPrivateRoute[] | BigDataRoute[];
}

export function Menu({ routes }: MenuProps) {
  const { screen } = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [drawerOpened, setDrawerOpened] = useState(false);

  const items = useMemo(() => {
    return createMenu(
      routes
        ?.map((route) => {
          const verifyRoles = getRolesChecker(route?.roles);
          return verifyRoles(user?.roles) ? route : null;
        })
        .filter(Boolean) as BigDataRoute[]
    );
  }, [user, routes]);

  function handleOpen() {
    setDrawerOpened(true);
  }

  function handleClose() {
    setDrawerOpened(false);
  }

  function handleClickMenu(e: any) {
    setDrawerOpened(false);
    const path = e.keyPath.reverse().join('/');

    if (path.includes('integrasocial')) {
      return window.location.replace(path);
    }

    return navigate(path);
  }

  return screen.small ? (
    <ScreenSmall
      open={drawerOpened}
      items={items}
      onClickItem={handleClickMenu}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  ) : (
    <ScreenLarge
      open={drawerOpened}
      items={items}
      onClickItem={handleClickMenu}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  );
}
