import { Box } from './feedbackBox.styles';

import wormImg from '@/assets/icons/worm.svg';
import lampImg from '@/assets/icons/lamp.svg';
import cloudImg from '@/assets/icons/cloud.svg';
import { useState } from 'react';
import { Show } from '../../Show';
import { Form } from './Form';

import closeFeedback from '@/assets/icons/close-feedback.svg';
import backFeedback from '@/assets/icons/back-feedback.svg';
import { ReportOptions } from 'big-data';
import { FeedbackStatusBox } from '../../FeedbackStatusBox';

interface FeedbackBoxProps {
  onClose(): void;
}

type Data = {
  id: number;
  name: ReportOptions;
  img: string;
};

const reportOptions: Data[] = [
  {
    id: 1,
    name: 'Problema',
    img: wormImg
  },
  {
    id: 2,
    name: 'Ideia',
    img: lampImg
  },
  {
    id: 3,
    name: 'Outro',
    img: cloudImg
  }
];

export function FeedbackBox({ onClose }: FeedbackBoxProps) {
  const [selectedOption, setSelectedOption] = useState<ReportOptions | null>(
    null
  );
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  function onBack() {
    setSelectedOption(null);
    setIsSubmittedSuccessfully(false);
    setError(null);
  }

  return (
    <Box>
      <button className="close" onClick={onClose}>
        <img src={closeFeedback} alt="Fechar feedback" />
      </button>
      <Show
        when={!isSubmittedSuccessfully && !error}
        fallback={
          <Show
            when={!error}
            fallback={
              <FeedbackStatusBox type="error" onClick={() => onBack()} />
            }
          >
            <FeedbackStatusBox type="success" onClick={onBack} />
          </Show>
        }
      >
        <div className="heading">
          {!!selectedOption && (
            <button className="back" onClick={onBack}>
              <img src={backFeedback} alt="Voltar ao anterior" />
            </button>
          )}

          <h1>{selectedOption ?? `Deixe seu feedback`}</h1>
        </div>

        <Show
          when={!!selectedOption}
          fallback={
            <div className="button-group">
              {reportOptions.map((reportOption) => (
                <button
                  key={reportOption.id}
                  aria-label={`Reportar ${reportOption.name}`}
                  onClick={() => setSelectedOption(reportOption.name)}
                >
                  <img src={reportOption.img} alt={reportOption.name} />
                  <span>{reportOption.name}</span>
                </button>
              ))}
            </div>
          }
        >
          <Form
            reportOption={selectedOption}
            onSuccess={() => setIsSubmittedSuccessfully(true)}
            onError={(error) => setError(error)}
          />
        </Show>
      </Show>
    </Box>
  );
}
