import { useMemo } from 'react';
import { Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import { Show } from '@/components/shared';
import { Geolocation } from '@/services';
import { Heat } from '../Heat';
import { getID } from '../../utils/functions';
import { getClusterIcon, getUnitIcon } from './icons';

interface HeatListProps {
  locations: Record<string, Geolocation[]>;
  visibilities: Record<string, boolean>;
  max: Record<string, number>;
  gradient: Record<number, string>;
  zoom: number;
  loading?: boolean;
}

export function HeatList({
  locations,
  visibilities,
  max,
  gradient,
  zoom,
  loading
}: HeatListProps) {
  const entries = useMemo(
    () =>
      Object.entries(locations).map(([key, locations]) => [
        key,
        locations.map((loc) => ({ ...loc, id: getID() }))
      ]) as [string, (Geolocation & { id: string })[]][],
    [locations]
  );

  if (loading) return null;

  return (
    <>
      {entries.map(([key, locations]) => {
        const clusterFunction = getClusterIcon(key);
        const unitFunction = getUnitIcon(key);

        return (
          <Show when={visibilities[key]} key={key}>
            <Show
              when={zoom >= 9}
              fallback={
                <Heat
                  latLngs={locations ?? []}
                  max={max[key]}
                  options={{
                    radius: 15,
                    minOpacity: 0.3,
                    blur: 15,
                    maxZoom: 16,
                    gradient
                  }}
                />
              }
            >
              <MarkerClusterGroup
                iconCreateFunction={clusterFunction}
                chunkedLoading
                removeOutsideVisibleBounds
              >
                {locations.map((location) => (
                  <Marker
                    key={location.id}
                    position={[location.latitude, location.longitude]}
                    title={`${location.heat_level}`}
                    icon={unitFunction(location.heat_level)}
                  />
                ))}
              </MarkerClusterGroup>
            </Show>
          </Show>
        );
      })}
    </>
  );
}
