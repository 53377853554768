import { useEffect, useState } from 'react';

import { CardTagged, D3Map, ServiceBox } from '@/components/shared';
import { TitleText, Text, ColorText } from '@/components/styled';
import { useAuth, useDashboardFilter } from '@/hooks';
import { useCities } from '@/servicesHooks/healthAgents';
import { colors } from '@/theme/colors';
import { dotsNumber } from '@/utils/string';
import { max } from '@/utils/math';
import { useTheme } from 'styled-components';

const filterBy = 'Sim'; // Crianças acompanhadas

export function CardMap() {
  const [maxValueCities, setMaxValueCities] = useState<number>();
  const { darkMode } = useTheme();

  const { isAdm } = useAuth();
  const { tag, city } = useDashboardFilter();
  const { cities, citiesError, citiesLoading } = useCities(filterBy);

  const totalCities = cities.reduce((acc, city) => acc + city.value, 0);

  const cityText = !!city ? `no município de ${city}` : `por município`;

  useEffect(() => {
    if (!city || !isAdm) {
      const values = cities.map((d) => d.value);

      const maxValue = max(values);
      setMaxValueCities(maxValue);
    }
  }, [city, cities, isAdm]);

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={cities.length === 0}
        loading={citiesLoading}
        error={citiesError}
      >
        <TitleText>
          Qual a quantidade de crianças de até 8 anos, que são acompanhadas por
          equipes de saúde da família, {cityText}?
        </TitleText>
        <Text>
          <ColorText>{dotsNumber(totalCities)} crianças</ColorText> são
          acompanhadas por equipes de saúde.
        </Text>

        <D3Map
          color={darkMode ? colors.ming100 : colors.ciano700}
          data={cities}
          filteringCity={city}
          maxValue={maxValueCities}
        />
      </ServiceBox>
    </CardTagged>
  );
}
