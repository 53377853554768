import styled from 'styled-components';

import { colors } from '@/theme/colors';

export const Box = styled.div`
  display: flex;

  background: ${({ theme }) =>
    theme.darkMode ? colors.black : `${colors.ciano700}33`};
  border: 1px solid
    ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
  border-radius: 8px;

  margin: 2rem 0;

  @media (max-width: 400px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  .img-card {
    display: flex;
    justify-content: center;

    img {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      width: 400px;

      @media (max-width: 1024px) {
        height: unset;
      }

      @media (max-width: 980px) {
        display: none;
      }

      @media (max-width: 400px) {
        display: initial;
        max-width: unset;
        width: 100%;
        height: unset;

        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .text-card {
    /* width: calc(100% - 385px); */
    padding: 1.6rem;
    h2 {
      color: ${({ theme }) =>
        theme.darkMode ? colors.white : colors.ciano700};
      font-weight: 400;
      .username {
        font-weight: 700;
      }
    }

    p {
      padding-top: 1rem;
      font-size: 0.9rem;
      color: ${({ theme }) => (theme.darkMode ? colors.white : '#4c5469')};
    }
  }
`;
