import { useEffect, useRef } from 'react';
import { Notification } from 'big-data';
import { isNil } from 'ramda';

import { BoxListItem } from './styles';
import dayjs from 'dayjs';

interface NotificationItemProps {
  item: Notification;
  observer: IntersectionObserver;
}

function getDetailedDate(date: Date) {
  const commentDate = dayjs(date);

  const today = dayjs();
  const yesterday = today.add(-1, 'day');

  const hour = commentDate.format('HH:mm');

  if (commentDate.isSame(today, 'day')) {
    return `Hoje às ${hour}`;
  }

  if (commentDate.isSame(yesterday, 'day')) {
    return `Ontem às ${hour}`;
  }

  const formattedDate = commentDate.format('DD/MM/YYYY');

  return `${formattedDate} às ${hour}`;
}

export function NotificationItem({ item, observer }: NotificationItemProps) {
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const { current } = itemRef;

    if (isNil(current)) return;

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [observer]);

  return (
    <BoxListItem
      data-id={item.notificationId}
      ref={itemRef}
      isRead={item.isRead}
    >
      <h4>{item.message}</h4>
      <p>{getDetailedDate(item.createdDate)}</p>
    </BoxListItem>
  );
}
