import { useEffect } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Pagination as StyledPagination } from './styles';

export interface PaginationProps {
  page: number;
  totalPages: number;
  onPaginate(page: number): void;
  resetOnChange?: string;
}

export function Pagination({
  onPaginate,
  page,
  totalPages,
  resetOnChange
}: PaginationProps) {
  function paginatePrev() {
    onPaginate(page - 1);
  }

  function paginateNext() {
    onPaginate(page + 1);
  }

  useEffect(() => {
    if (resetOnChange) onPaginate(0);
  }, [resetOnChange]); // eslint-disable-line

  return (
    <StyledPagination>
      <button
        onClick={paginatePrev}
        disabled={page === 0}
        aria-label="Anterior"
        aria-disabled={page === 0}
      >
        <RiArrowLeftSLine size={18} />
      </button>
      <span>
        Página <strong>{page + 1}</strong> de{' '}
        <strong className="total-pages">{(totalPages ?? 0) + 1}</strong>
      </span>
      <button
        onClick={paginateNext}
        disabled={page === totalPages}
        aria-label="Próximo"
        aria-disabled={page === totalPages}
      >
        <RiArrowRightSLine size={18} />
      </button>
    </StyledPagination>
  );
}
