import ReactTsParticles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import styled from 'styled-components';

export function Particles() {
  return (
    <Box>
      <ReactTsParticles
        className="tsparticles"
        init={async (engine) => await loadFull(engine)}
        height="130vh"
        width="100vw"
        options={{
          fullScreen: {
            enable: false,
            zIndex: 2
          },
          fpsLimit: 60,
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 550
              }
            },
            color: {
              value: 'transparent',
              animation: {
                enable: false,
                speed: 20,
                sync: true
              }
            },
            size: {
              value: {
                min: 0.1,
                max: 1
              }
            },

            links: {
              enable: true,
              distance: 150,
              color: '#fbfbfb',
              opacity: 0.1,
              width: 0.7
            },
            move: {
              enable: true,
              speed: 1,
              direction: 'bottom',
              outModes: {
                default: 'out'
              }
            }
          }
        }}
      />
    </Box>
  );
}

const Box = styled.div`
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
`;
