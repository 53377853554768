import { Tooltip } from 'antd';

import eyesOpen from '@/assets/icons/eyes_open.svg';
import eyesClose from '@/assets/icons/eyes_close.svg';

import { Box } from './styles';

import { INDICATORS_LABELS } from '../../utils/objects';
import { Show } from '@/components/shared';

interface VisibilityPanelProps {
  panels: string[];
  visibilities: Record<string, boolean>;
  onClick?: (key: string) => void;
}

export function VisibilityPanel({
  panels,
  visibilities,
  onClick
}: VisibilityPanelProps) {
  function handleClick(key: string) {
    if (onClick) {
      onClick(key);
    }
  }

  return (
    <Show when={panels.length > 0} fallback={null}>
      <Box>
        {panels.map((panel) => (
          <Tooltip
            key={panel}
            title={INDICATORS_LABELS[panel]?.label}
            placement="bottom"
            autoAdjustOverflow
          >
            <button onClick={() => handleClick(panel)}>
              <img src={visibilities[panel] ? eyesOpen : eyesClose} alt="Eye" />
              <span>{INDICATORS_LABELS[panel]?.initial}</span>
            </button>
          </Tooltip>
        ))}
      </Box>
    </Show>
  );
}
