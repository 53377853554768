import { colors } from '@/theme/colors';
import { BsChat } from 'react-icons/bs';
import { CounterBox } from './styles';

interface ActionCommentCounterProps {
  counter: number;
}

export function ActionCommentCounter({ counter }: ActionCommentCounterProps) {
  return (
    <CounterBox>
      <BsChat color={colors.ciano700} size={20} />
      <span className="comments-amount">{counter}</span>
    </CounterBox>
  );
}
