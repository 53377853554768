import { useDashboardFilter } from '@/hooks';
import { healthAgents, healthPregnant } from '@/services';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

const requests = [
  healthPregnant.getPreNatalActionRegistration,
  healthAgents.getChildrenVaccinalActionRegistration,
  healthAgents.getVaccineUpToDate
];

export function useHealthActionRegistration() {
  const [data, setData] = useState<Record<string, Data[]>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    Promise.all<Data[]>(
      requests.map((request) => request({ ibge, year, semester, zone }))
    )
      .then(([preNatal, children, vaccineUpToDate]) => {
        const newVaccineUpToDate = vaccineUpToDate.reduce((acc, cur) => {
          if (cur.name.toLowerCase().includes('não,')) {
            return {
              ...acc,
              Nao: (acc['Nao'] ?? 0) + cur.value
            };
          } else if (cur.name.toLowerCase().includes('sim,')) {
            return {
              ...acc,
              Sim: (acc['Sim'] ?? 0) + cur.value
            };
          }

          return acc;
        }, {} as Record<string, number>);

        const parsedVaccineUpToDate = Object.entries(newVaccineUpToDate).map(
          ([key, value]) => ({ name: key, value })
        );

        return [preNatal, children, parsedVaccineUpToDate];
      })
      .then(([preNatal, children, vaccineUpToDate]) => {
        const newData = {
          'GESTANTES SEM ACOMPANHAMENTO PRÉ-NATAL': preNatal,
          'CRIANÇAS SEM ACOMPANHAMENTO DE EQUIPE DE SAÚDE': children,
          'CRIANÇAS COM ESQUEMA VACINAL INCOMPLETO': vaccineUpToDate
        };

        setData(newData);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return {
    data: undefined,
    granularData: data,
    loading,
    error
  };
}
