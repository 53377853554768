import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { homeVisits } from '@/services';
import { DataWithProfile } from 'big-data';
import { useEffect } from 'react';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

interface VisitedProps {
  color: string;
}

export function Visited({ color }: VisitedProps) {
  const { data, loading, error, request } = useService<DataWithProfile>();

  const { city, profilePopulation } = useAnalyticsFilter();
  useEffect(() => {
    request(() =>
      homeVisits.getCities('visited', profilePopulation || undefined)
    );
  }, [request, profilePopulation]);

  const filteredData = data
    .filter((d) => (city ? city.toLowerCase() === d.name.toLowerCase() : true))
    .filter((d) => (profilePopulation ? profilePopulation === d.profile : true))
    .sort((a, b) => b.value - a.value);

  return (
    <Section>
      <Card
        title="Número de visitas domiciliares por município"
        empty={filteredData.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[
          city ? city : 'Ceará',
          profilePopulation
            ? profilePopulation.replace('+', ' e ')
            : 'Todos os perfis'
        ]}
      >
        <HorizontalBarSimple
          sizeRatio={0.7}
          maxValue={5}
          data={filteredData.slice(0, 15)}
          isPercentage
          hasPagination
          color={color}
        />
      </Card>
    </Section>
  );
}
