import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useService } from '@/hooks';
import { Data, RankingModel } from 'big-data';
import { leaderMovements } from '@/services/leaderMovements';
import { useEffect } from 'react';

import cisWomen from '@/assets/icons/leaderMovements/mulher-cis.svg';
import cisMen from '@/assets/icons/leaderMovements/homem-cis.svg';
import noBinary from '@/assets/icons/leaderMovements/nao-binario.svg';
import noAnwser from '@/assets/icons/leaderMovements/nao-respondeu.svg';
import others from '@/assets/icons/leaderMovements/outra.svg';
import transvestite from '@/assets/icons/leaderMovements/travesti.svg';
import { getDashboardRanking } from '@/utils/data';
import { RankingDashboard } from '@/components/shared/RankingDashboard';
import { colors } from '@/theme/colors';

const DEFAULT_DESCRIPTION = ' das pessoas são ';

const rankingModel = new Map<string, RankingModel>([
  [
    'mulher cisgênero',
    {
      description: () => DEFAULT_DESCRIPTION,
      img: <img src={cisWomen} alt="Icon de mulher cis" />,
      name: 'Mulheres Cisgênero',
      key: 'cisWomen'
    }
  ],
  [
    'travesti',
    {
      description: () => DEFAULT_DESCRIPTION,
      img: <img src={transvestite} alt="Icon de travesti" />,
      name: 'Travestis',
      key: 'transvestite'
    }
  ],
  [
    'homem cisgênero',
    {
      description: () => DEFAULT_DESCRIPTION,
      img: <img src={cisMen} alt="Icon de cis homem" />,
      name: 'Homens Cisgênero',
      key: 'cisMen'
    }
  ],
  [
    'pessoa não binária',
    {
      description: () => DEFAULT_DESCRIPTION,
      img: <img src={noBinary} alt="Icon de não binário" />,
      name: 'Não Binários',
      key: 'noBinary'
    }
  ],
  [
    'hétero',
    {
      description: () => ' das pesssoas se identificam com ',
      img: <img src={others} alt="Icon de outros" />,
      name: 'Outras respostas',
      key: 'others'
    }
  ],
  [
    'prefiro não responder',
    {
      description: () => ' das pessoas preferiram ',
      img: <img src={noAnwser} alt="Icon de não respondeu" />,
      name: 'não responder',
      key: 'noAnwser'
    }
  ]
]);

export function LeaderGenderIdentity() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => leaderMovements.genderIdentity());
  }, [request]);

  const rankingData = getDashboardRanking(data, rankingModel);

  return (
    <Section>
      <Card
        title="Identidade de gênero das lideranças"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <RankingDashboard
          color={colors.ciano700}
          calculatedOut
          data={rankingData}
          loadingBenefits={loading}
          selected={undefined}
        />
      </Card>
    </Section>
  );
}
