import { Input, Select } from 'antd';
import { BiTrashAlt } from 'react-icons/bi';

import { CITIES } from '@/constants/cities';
import { indicatorsBySearchParams } from '@/constants/indicators';
import { useAuth, useAction } from '@/hooks';
import { Show } from '@/components/shared/Show';

import { Box } from './styles';
import { useSearchParams } from 'react-router-dom';
import { FilterIndicatorIcon } from '@/components/shared';

const { Option } = Select;

interface FilterProps {
  indicatorHidden?: boolean;
  hasSearchFilter?: boolean;
}

export function Filter({
  indicatorHidden = false,
  hasSearchFilter = false
}: FilterProps) {
  const { isAdm } = useAuth();
  const {
    onChangeCity,
    onChangeIndicator,
    onEraseFilter,
    indicator,
    city,
    onChangeActionTitle
  } = useAction();

  const [searchParams] = useSearchParams();
  const axe = searchParams.get('axe') ?? '';

  const indicators = indicatorsBySearchParams[axe];

  return (
    <Box>
      <div className="icon-text-wrapper">
        <FilterIndicatorIcon />
        <p className="filter-explanation">
          Para navegar, selecione o município no filtro a seguir:
        </p>
      </div>

      <div className="select-wrapper">
        <div className="select-group">
          <Show when={isAdm} fallback={null}>
            <Select
              allowClear
              showSearch
              bordered={false}
              placeholder="Selecione um município"
              onChange={onChangeCity}
              value={!!city ? city : undefined}
            >
              {CITIES.map((city) => (
                <Option 
                key={city.ibge} 
                value={`${city.municipio}_${city.ibge}`}>
                  {city.municipio}
                </Option>
              ))}
            </Select>
          </Show>

          {!indicatorHidden && (
            <Select
              placeholder="Selecione um indicador"
              onChange={onChangeIndicator}
              value={!!indicator ? indicator : undefined}
              allowClear
              bordered={false}
            >
              {indicators?.map((indicator) => (
                <Option key={indicator.label} value={indicator.value}>
                  {indicator.label}
                </Option>
              ))}
            </Select>
          )}

          {hasSearchFilter && (
            <Input.Search
              placeholder="Pesquisar por título"
              className="search-input"
              bordered={false}
              allowClear
              onSearch={(value) => onChangeActionTitle(value)}
              // value={!!actionSearchTitle ? actionSearchTitle : undefined}
            />
          )}
        </div>
        <button onClick={onEraseFilter} className="clear-filter">
          <BiTrashAlt size={15} />
          <span>LIMPAR</span>
        </button>
      </div>
    </Box>
  );
}
