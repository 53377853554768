import { colors } from '@/theme/colors';
import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';

const planActionRelations = new Map([
  [0, { name: 'Em andamento', color: colors.orange400 }],
  [1, { name: 'Concluído', color: '#2A9AA2' }],
  [2, { name: 'Concluído com atraso', color: colors.green600 }],
  [3, { name: 'Não concluído', color: colors.red400 }],
  [4, { name: 'Bloqueado', color: colors.yellow500 }]
]);

const service = () => {
  const resource = `/acoes/listagem-plano-acoes`;

  async function get(cpf?: string, minimumDate?: string) {
    const path = resource;
    const options = ensureAxiosParamOptions({
      params: {
        cpf,
        minimumDate
      }
    });
    const response = await callService(() =>
      mainApi.get<{ status: number; quantidade: number }[]>(path, options)
    );

    return response.data.map((data) => ({
      ...(planActionRelations.get(data.status) ?? { name: '', color: '' }),
      value: data.quantidade
    }));
  }

  return {
    get
  };
};

export const listPlanActions = service();
