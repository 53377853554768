import { useState } from 'react';

import childHomeImg from '@/assets/images/mais-infancia-home.png';

import { BannerCount } from '../BannerCount';
import { Box } from './styles';

export function BannerImage() {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box>
      <div className="image-container">
        <img
          src={childHomeImg}
          alt="Banner"
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(false)}
        />
      </div>

      {imageLoaded && <BannerCount />}
    </Box>
  );
}
