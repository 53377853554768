import { Observation } from 'big-data';
import { CommentDetails } from '../CommentDetails';

import { Box } from './styles';

interface CommentNoteProps {
  comment: Observation;
  isClientComment: boolean;
}

export function CommentNote({ comment, isClientComment }: CommentNoteProps) {
  const { answerTo } = comment;

  return (
    <Box isClientComment={isClientComment}>
      {answerTo && (
        <div className="from-message">
          <p>{answerTo.note}</p>
          <CommentDetails comment={answerTo} />
        </div>
      )}
      <p>{comment.note}</p>
    </Box>
  );
}
