import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';
import { Data } from 'big-data';
import { Box, PyramidSliceText } from './styles';
import { lighten } from 'polished';
import { useState } from 'react';
import { Legend } from './Legend';
import { Tooltip } from 'antd';

import { Show } from '@/components/shared';
import { colors } from '@/theme/colors';

interface PyramidProps {
  data: Data[];
  color?: string;
}

const BLUE_DEFAULT = colors.ciano700;

const lightenOriginColor = (_color: string, size: number) => {
  return Array.from({ length: size }).map((_, i) =>
    lighten((size - 1 - i) / 10, _color)
  );
};

export function Pyramid({ data, color = BLUE_DEFAULT }: PyramidProps) {
  const [hoveredPiece, setHoveredPiece] = useState<string>();

  const colors = lightenOriginColor(color, data.length);

  const total = sumDataTotal(data);
  const dataPercentage = data
    .map((d) => ({
      ...d,
      percentageValue: (d.value / total) * 100
    }))
    .sort((a, b) => a.value - b.value);

  return (
    <Box>
      <div className="pyramid">
        {dataPercentage.map((d, i) => (
          <div
            key={d.name}
            className="pyramid-slice"
            style={{
              background: colors[i],
              height: `calc(${d.percentageValue}rem + 70px)`
            }}
            onMouseEnter={() => setHoveredPiece(d.name)}
            onMouseLeave={() => setHoveredPiece(undefined)}
          >
            <Show when={hoveredPiece === d.name}>
              <Tooltip
                visible
                placement="right"
                title={percentageFormatter(d.percentageValue)}
              >
                <PyramidSliceText
                  fontSize={`${24 * (d.percentageValue / 100) + 9}px`}
                />
              </Tooltip>
            </Show>
          </div>
        ))}
      </div>

      <Legend
        data={dataPercentage}
        hoveredPiece={hoveredPiece}
        onChangeHoveredPiece={(piece) => setHoveredPiece(piece)}
        colors={colors}
      />
    </Box>
  );
}
