import { useDashboardFilter } from '@/hooks';
import { healthAgents } from '@/services/healthAgents';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useBreastfeedData() {
  const [breastfeedData, setBreastfeedData] = useState<Data[]>([]);
  const [breastfeedError, setBreastfeedError] = useState<Error | null>(null);
  const [breastfeedLoading, setBreastfeedLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setBreastfeedLoading(true);
    healthAgents
      .getBreastfeed(ibge, year, semester, zone)
      .then((data) => {
        const total = data.reduce((acc, cur) => acc + cur.value, 0);
        const dataFinal = data
          .map((d) => {
            return { ...d, value: d.value / total };
          })
          .sort((a, b) => b.value - a.value);
        setBreastfeedData(dataFinal);
      })
      .catch((error) => setBreastfeedError(error))
      .finally(() => setBreastfeedLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      breastfeedData,
      breastfeedError,
      breastfeedLoading
    }),
    [breastfeedData, breastfeedError, breastfeedLoading]
  );
}
