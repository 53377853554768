import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';
import { CardBase } from '../../../components/Card/styles';

export const Box = styled(CardBase)`
  table.rejection-table {
    width: 100%;
    text-align: left;

    tr {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px;

      td {
        font-weight: 700;
        font-size: 1rem;
        line-height: 22px;
      }
    }

    thead,
    tr:not(:last-child) {
      border-bottom: 1px solid #f1f5f9;
    }

    thead td {
      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#000`)};
    }

    tbody {
      tr {
        display: flex;
        justify-content: space-between;

        &:first-child {
          color: ${colors.green600};
        }

        &:last-child {
          color: ${colors.red400};
        }

        ${({ theme }) =>
          theme.darkMode &&
          css`
            &:first-child,
            &:last-child {
              color: #fff;
            }
          `}
      }
    }
  }
`;

interface TdProps {
  color?: string;
}

export const Td = styled.td<TdProps>`
  color: ${({ color }) => color};
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;

  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
`;
