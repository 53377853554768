export const descriptions: any = {
  'insegurança alimentar grave':
    'Na insegurança alimentar grave, há redução quantitativa severa de alimentos também entre as crianças, ou seja, ruptura nos padrões de alimentação resultante da falta de alimentos entre todos os moradores. Nessa situação, a fome passa a ser uma experiência vivida no domicílio.',
  'insegurança alimentar moderada':
    'Na insegurança alimentar moderada, há redução quantitativa de alimentos entre os adultos e/ou ruptura nos padrões de alimentação resultante da falta de alimentos entre os familiares.',
  'insegurança alimentar leve':
    'Na insegurança alimentar leve, há preocupação ou incerteza quanto ao acesso aos alimentos no futuro e a qualidade inadequada dos alimentos resultante de estratégias que visam a não comprometer a quantidade de alimentos.',
  'fora de insegurança alimentar':
    'A situação das famílias fora da insegurança alimentar mostra que há acesso regular aos alimentos mas não em qualidade e quantidade suficiente. Para as famílias, uma boa alimentação compete com o acesso a outras necessidades essenciais.'
};
