import { Breadcrumb } from '@/components/shared/Breadcrumb';
import { SecondTitle } from '@/components/styled';
import { useAnalyticsTitle } from '@/hooks/useAnalyticsTitle';
import { routesAnalytics } from '@/pages/routes.analytics';
import { getNameBySlug, mountLinks } from '@/utils/routes';
import { useLocation } from 'react-router-dom';
import { Box } from './styles';

function getHref(pathname: string, path: string) {
  const paths = pathname.replace('/', '').split('/');

  const findIndex = paths.findIndex((p) => p === path);

  return `/${paths.splice(0, findIndex + 1).join('/')}`;
}

export function HeaderAnalytics() {
  const title = useAnalyticsTitle('Analíticos');
  const { pathname } = useLocation();
  const routes = mountLinks(routesAnalytics[0]?.children ?? []);

  const paths = pathname
    .replace('/', '')
    .split('/')
    .filter((path) => path !== '')
    .map((path) => {
      return {
        label: getNameBySlug(path, routes),
        href: getHref(pathname, path)
      };
    })
    .filter((path) => !!path.label);

  return (
    <Box>
      <Breadcrumb paths={paths} />
      <SecondTitle className="header-dashboard-title">{title}</SecondTitle>
    </Box>
  );
}
