import { GenericGridItem } from '@/components/styled';

import {
  CardMap,
  CardChildImpairmentRanking,
  CardVaccinationTable,
  CardVaccinationBookTable,
  CardBreastfeedDonut,
  CardBar,
  CardPregnantTable,
  CardPregnantDonut,
  CardPregnantSupply,
  CardPregnantExamsBar,
  CardPregnantPercentageByHome
} from '../HealthCards';

import { Box } from './styles';

export function HealthInsights() {
  return (
    <Box>
      <GenericGridItem area="a">
        <CardMap />
      </GenericGridItem>

      <GenericGridItem area="b">
        <CardVaccinationTable />
      </GenericGridItem>

      <GenericGridItem area="c">
        <CardVaccinationBookTable />
      </GenericGridItem>

      <GenericGridItem area="d">
        <CardBar />
      </GenericGridItem>

      <GenericGridItem area="e">
        <CardChildImpairmentRanking />
      </GenericGridItem>

      <GenericGridItem area="f">
        <CardBreastfeedDonut />
      </GenericGridItem>

      <GenericGridItem area="g">
        <div className="g-column">
          <CardPregnantPercentageByHome />
          <CardPregnantTable />
        </div>
      </GenericGridItem>

      <GenericGridItem area="h">
        <CardPregnantDonut />
      </GenericGridItem>

      <GenericGridItem area="i">
        <CardPregnantSupply />
      </GenericGridItem>

      <GenericGridItem area="j">
        <CardPregnantExamsBar />
      </GenericGridItem>
    </Box>
  );
}
