import { Card } from '@/components/shared';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

import styled from 'styled-components';

export function Card6() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getLinkedOrgs(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Ligações das organizações de apoio a mulheres"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <ChartBox>
        <HorizontalBarSimple
          data={data}
          grid
          color="#1D696F"
          isPercentage
          hasPagination
          maxValue={4}
        />
      </ChartBox>
    </Card>
  );
}

const ChartBox = styled.div`
  padding: 0 1rem;
`;
