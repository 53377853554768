import { Box } from './styles';
import { AntSelect } from '@/components/styled';
import { useFilterContext } from '../../contexts/FilterContext';
import { BiTrashAlt } from 'react-icons/bi';
import citiesCE from '@/constants/ceara.json';
import { Select } from 'antd';
import { useService } from '@/hooks';
import { useEffect } from 'react';
import { useGeneral } from '@/services/lgbt/general';
import { FilterIndicatorIcon } from '@/components/shared';
const { Option } = Select;

export function Filter() {
  const {
    onClear,
    handleChangeCity,
    handleChangeOrientation,
    handleChangeIdentity,
    city,
    orientation,
    identity,
    ibge
  } = useFilterContext();

  const { data, loading, request } = useService<any>();

  useEffect(() => {
    request(() => useGeneral.orientacaoSexualFilter({ ibge }));
  }, [request, ibge]);

  const {
    data: identityData,
    loading: loadingIdentity,
    request: requestIdentity
  } = useService<any>();

  useEffect(() => {
    requestIdentity(() =>
      useGeneral.genderIdentityFilter({ ibge, orientation })
    );
  }, [requestIdentity, ibge, orientation]);

  return (
    <Box>
      <div className="icon-text-wrapper">
        <FilterIndicatorIcon />
        <span>Configure o filtro para obter informações detalhadas:</span>
      </div>

      <div className="select-wrapper">
        <div className="select-group">
          <BdSelect
            placeholder="Selecione um município"
            onChange={handleChangeCity}
            value={!!city ? city : undefined}
          >
            {citiesCE.features.map((city) => (
              <Option
                key={city.properties.id}
                value={`${city.properties.name}_${city.properties.id}`}
              >
                {city.properties.name}
              </Option>
            ))}
          </BdSelect>
          <BdSelect
            placeholder="Selecione uma orientação sexual"
            onChange={handleChangeOrientation}
            loading={loading}
            value={!!orientation ? orientation : undefined}
          >
            {data.map((d) => (
              <Option key={d.name} value={d.name}>
                {d.name}
              </Option>
            ))}
          </BdSelect>
          <BdSelect
            placeholder="Selecione uma identidade de gênero"
            onChange={handleChangeIdentity}
            loading={loadingIdentity}
            value={!!identity ? identity : undefined}
          >
            {identityData.map((d) => (
              <Option key={d.name} value={d.name}>
                {d.name}
              </Option>
            ))}
          </BdSelect>
        </div>
        <button onClick={onClear} className="clear-filter">
          <BiTrashAlt size={15} />
          <span>LIMPAR</span>
        </button>
      </div>
    </Box>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
