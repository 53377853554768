import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  width: 100%;

  & + div {
    margin-top: 36px;
  }

  .comment-date {
    display: flex;
    align-items: center;
    gap: 12px;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    margin-bottom: 24px;

    img {
      filter: brightness(0) invert(1);
    }
  }
`;
