import { Data, DataWithIbgeCode, Sanitation } from 'big-data';
import { strToInt } from '@/utils/types';

import { mainApi } from './config/api';
import { getQuery } from './config/utils';
import { callService } from './config/service';
import { ensureAxiosParamOptions } from '@/utils/routes';

const service = () => {
  const resource = `/cmic`;
  const abortController = new AbortController();

  function cancelRequest() {
    abortController.abort();
  }

  async function amount(ibge?: string) {
    const path = `${resource}`;
    const aggregations = !!ibge ? ['IBGE'] : [];

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations)
      },
      signal: abortController.signal
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data.filter((data) =>
      !!ibge ? data.ibge === ibge : true
    )[0].quantidade;
  }

  async function getInvestedMoney() {
    const path = `${resource}/payment-invested`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data[0].investido;
  }

  async function getCities() {
    const aggregations = ['MUNICIPIO', 'IBGE'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map<DataWithIbgeCode>((d) => ({
      name: d.municipio,
      codIbge: d.ibge,
      value: d.quantidade
    }));
  }

  async function getCitiesWithCodIbge() {
    const aggregations = ['MUNICIPIO', 'IBGE'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map<DataWithIbgeCode>((d) => ({
      name: d.municipio,
      codIbge: strToInt(d.ibge),
      value: d.quantidade,
      city: d.municipio
    }));
  }

  async function getSanitation(filter?: number) {
    const aggregations = [
      'BANHEIRO',
      'AGUA_CANALIZADA',
      filter ? ['MUNICIPIO', 'IBGE'].join(',') : undefined
    ];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => (filter ? Number(d.ibge) === filter : true))
      .map<Sanitation>((d) => ({
        value: d.quantidade,
        pipedWater: d.agua_canalizada,
        bathroom: d.banheiro
      }));
  }

  async function getWallMaterial(filter?: number) {
    const aggregations = [
      'MATERIAL_CASA',
      filter ? ['MUNICIPIO', 'IBGE'].join(',') : undefined
    ];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => (filter ? Number(d.ibge) === filter : true))
      .map<Data>((d) => ({
        value: d.quantidade,
        name: d.material_casa
      }));
  }

  async function getSexInfo(filter?: number) {
    const aggregations = [
      'SEXO',
      filter ? ['MUNICIPIO', 'IBGE'].join(',') : undefined
    ];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((d) => (filter ? Number(d.ibge) === filter : true))
      .map<Data>((d) => ({ value: d.quantidade, name: d.sexo }));
  }

  return {
    amount,
    cancelRequest,
    getInvestedMoney,
    getCities,
    getCitiesWithCodIbge,
    getSanitation,
    getSexInfo,
    getWallMaterial
  };
};

export const cmic = service();
