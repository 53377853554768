import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  b {
    color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
  }

  svg {
    width: 133px;
    height: 75px;
    background: ${(props) =>
      props.theme.darkMode ? '#000' : 'rgba(29, 105, 112, 0.1);'};
    border: 1px solid
      ${(props) => (props.theme.darkMode ? '#FFF' : 'transparent')};
    border-radius: 10px;
    padding: 1rem;
    path {
      fill: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
    }
  }
`;
