import { FaLock } from 'react-icons/fa';
import { Tooltip } from 'antd';
import { TagText } from './styles';

interface StatusTagProps {
  status: string;
}

const COLORS: Record<string, string> = {
  IN_PROGRESS: '#E0A427',
  DONE: '#4E7345',
  DONE_LATE: '#373EE4',
  UNDONE: '#D4282A'
};

const LABELS: Record<string, string> = {
  IN_PROGRESS: 'Plano em andamento',
  DONE: 'Plano concluído',
  DONE_LATE: 'Plano concluído com atraso',
  UNDONE: 'Plano não concluído'
};

export function StatusTag({ status }: StatusTagProps) {
  if (status === 'BLOCKED') {
    return (
      <TagText>
        <Tooltip title="Plano bloqueado">
          <span className="block-icon">
            <FaLock />
          </span>
        </Tooltip>
      </TagText>
    );
  }

  return (
    <TagText color={COLORS[status]}>
      <span>{LABELS[status]}</span>
    </TagText>
  );
}
