import styled from 'styled-components';

export const Box = styled.ul`
  list-style: none;

  .ranking {
    display: flex;

    margin: 1.5rem 0;

    .ranking-img-box {
      width: 50px;
      margin-right: 1rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 50px;
      filter: ${({ theme }) =>
        theme.darkMode ? `brightness(0) invert(1)` : ''};
    }

    &-0 img {
      width: 50px;
    }

    &-1 img {
      width: 46px;
    }

    &-2 img {
      width: 42px;
    }

    &-3 img {
      width: 38px;
    }

    .ranking-text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#4c5469`)};

      .value {
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1500px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
