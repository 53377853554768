import { Box } from './form.styles';

import { FormEvent, useEffect, useState } from 'react';

import cameraImg from '@/assets/icons/camera.svg';
import trashUploadImg from '@/assets/icons/trash-upload.svg';
import { ReportOptions } from 'big-data';
import { FeedbackButton } from '@/components/styled';
import { feedback } from '@/services';
import { Loading } from '../../Loading';
import { useAuth } from '@/hooks';

interface FormProps {
  reportOption: ReportOptions | null;
  onSuccess(): void;
  onError(error: Error | null): void;
}

const placeholderMap = new Map<ReportOptions, string>([
  [
    'Problema',
    'Algo não está funcionando bem? Queremos corrigir. Conte com detalhes o que está acontecendo...'
  ],
  ['Ideia', 'Alguma sugestão?'],
  ['Outro', 'Alguma outra coisa que deseja reportar?']
]);

export function Form({ reportOption, onSuccess, onError }: FormProps) {
  const [files, setFiles] = useState<FileList | null>(null);
  const [content, setContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const isSubmitButtonDisabled = !content.trim() || !reportOption?.trim();

  function upload() {
    if (files && files.length > 0) {
      const [file] = files;
      const src = URL.createObjectURL(file);
      return <img src={src} alt={file.name} className="uploaded-file" />;
    }

    return (
      <img src={cameraImg} alt="Realizar Upload" className="upload-file" />
    );
  }

  async function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (!content.trim() || !reportOption?.trim()) return;

    setIsSubmitting(true);

    const formData = new FormData();
    if (files && files[0]) formData.append('screenshot', files[0]);
    formData.append('feedbackContent', content);
    formData.append('feedbackType', reportOption);

    try {
      await feedback.create(formData, name, email);
      onSuccess();
    } catch (error) {
      onError(error as Error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const { user } = useAuth();

  useEffect(() => {
    setEmail(user?.client.email ?? '');
    setName(user?.client.name ?? '');
  }, [user]);

  return (
    <Box onSubmit={onSubmit}>
      <input
        type="text"
        placeholder="Insira seu nome"
        disabled={!!user}
        defaultValue={user ? user.client.name : undefined}
        onChange={({ target }) => setName(target.value)}
        required
      />
      <input
        type="email"
        placeholder="Insira seu e-mail"
        disabled={!!user}
        defaultValue={user ? user.client.email : undefined}
        onChange={({ target }) => setEmail(target.value)}
        required
      />
      <textarea
        placeholder={placeholderMap.get(reportOption ?? 'Ideia')}
        className="abstract"
        value={content}
        onChange={({ target }) => setContent(target.value)}
      />

      <div className="upload-submit-container">
        <div className="upload-control">
          <label htmlFor="file" role="button" className="upload-label">
            {upload()}
            {files && (
              <button
                className="remove-upload"
                onClick={(event) => {
                  event.preventDefault();
                  setFiles(null);
                }}
              >
                <img src={trashUploadImg} alt="Limpar seleção" />
              </button>
            )}
          </label>
          <input
            type="file"
            id="file"
            name="file"
            className="upload"
            onChange={({ target }) => setFiles(target.files)}
            accept="image/*"
          />
        </div>

        <FeedbackButton
          type="submit"
          className="submit"
          disabled={isSubmitButtonDisabled || isSubmitting}
        >
          {isSubmitting ? <Loading /> : 'Enviar Feedback'}
        </FeedbackButton>
      </div>
    </Box>
  );
}
