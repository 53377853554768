import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { Close } from './Close';

interface AntModalProps extends ModalProps {
  children: ReactNode;
}

export function AntModal({ children, ...rest }: AntModalProps) {
  const { darkMode } = useTheme();
  const modalBg = darkMode ? `#000` : `#fff`;

  return (
    <Modal
      {...rest}
      className="czrm-modal action-plan-modal"
      closeIcon={<Close />}
      footer={null}
      centered
      bodyStyle={{
        background: modalBg,
        borderRadius: '16px',
        border: '1px solid white'
      }}
    >
      {children}
    </Modal>
  );
}
