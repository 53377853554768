import { useDashboardFilter } from '@/hooks';
import { healthAgents } from '@/services';
import { DataCity } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useCities(filterBy: 'Sim' | 'Não' | 'Não sabe') {
  const [cities, setCities] = useState<DataCity[]>([]);
  const [citiesError, setCitiesError] = useState<Error | null>(null);
  const [citiesLoading, setCitiesLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setCitiesLoading(true);
    healthAgents
      .getCities({ ibge, year, semester, zone })
      .then((data) => setCities(data.sort((a, b) => b.value - a.value)))
      .catch((error) => setCitiesError(error))
      .finally(() => setCitiesLoading(false));
  }, [ibge, year, semester, filterBy, zone]);

  const formattedCities = useMemo(() => {
    const dataReduced = cities.reduce((acc, city) => {
      const nameIbge = city.city + '_' + city.name;

      return {
        ...acc,
        [nameIbge]: {
          ...acc[nameIbge],
          [city.option ?? '']: (acc[city.option ?? ''] ?? 0) + city.value
        }
      };
    }, {} as Record<string, any>);

    return Object.entries(dataReduced).map(([k, v]) => {
      const [cityName, cityIbge] = k.split('_');
      const total = Object.values(v as number[]).reduce(
        (acc, value) => acc + value,
        0
      );

      return {
        name: cityName,
        codIbge: cityIbge,
        value: v[filterBy] ?? 0,
        percentual: ((v[filterBy] ?? 0) / total) * 100
      };
    });
  }, [cities, filterBy]);

  return useMemo(
    () => ({
      cities: formattedCities,
      citiesError,
      citiesLoading
    }),
    [formattedCities, citiesError, citiesLoading]
  );
}
