import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { useEffect } from 'react';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { socialMovements } from '@/services/socialMovements';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';

export function SocialControlBar() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.controlSocialMovements());
  }, [request]);

  return (
    <Section>
      <Card
        title="Participação em espaços de controle social por tipo"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <HorizontalBarSimple
          maxValue={7}
          grid
          sizeRatio={0.7}
          isPercentage
          data={data}
          percentageCalculatedOut
          hasPagination
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
