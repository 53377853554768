import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { CountLabel } from '@/pages/Analytics/components';
import { livingCondition } from '@/services/livingCondition';
import { useEffect, useState } from 'react';

export function Counter() {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    setLoading(true);
    livingCondition
      .amount(city, zone)
      .then((res) => setAmount(res.quantidade))
      .finally(() => setLoading(false));
  }, [city, zone]);

  return (
    <CountLabel
      loading={loading}
      label="Famílias analisadas"
      size={30}
      value={amount}
    />
  );
}
