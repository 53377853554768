import { ThirdTitle, GroupBlockBox } from '@/components/styled';
import { FilterAnalytic } from '@/components/shared';
import { colors } from '@/theme/colors';

import { IlliterateByCity } from './IlliterateByCity';
import { HighIlliterateByCity } from './HighIlliterateByCity';
import { IlliteratesAgeByCity } from './IlliteratesAgeByCity';
import { SchoolingDegree } from './SchoolingDegree';
import { Filter } from './Filter';

import { Box } from './styles';
import { useTheme } from 'styled-components';

export function AdultSchoolingInsights() {
  const { darkMode } = useTheme();
  const mapColor = darkMode ? colors.ming100 : colors.ciano700;
  const color = darkMode ? colors.ming : colors.ciano700;

  return (
    <Box>
      <ThirdTitle>
        Grau de Escolaridade e Analfabetismo entre Adultos
      </ThirdTitle>

      <FilterAnalytic>
        <Filter />
      </FilterAnalytic>

      <GroupBlockBox>
        <IlliterateByCity color={mapColor} />
        <HighIlliterateByCity color={color} />
      </GroupBlockBox>
      <GroupBlockBox>
        <IlliteratesAgeByCity color={color} />
        <SchoolingDegree color={color} />
      </GroupBlockBox>
    </Box>
  );
}
