import { Text } from '@/components/styled';
import React, { ReactNode, useMemo, useState } from 'react';
import { Box, Container, EmptyList } from './styles';

import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { Input } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';

interface Column {
  name: string;
  key: string;
}

interface Data {
  name: string;
  value?: string;
}

interface DataGoalVisitationProps {
  data: Data[];
  title?: ReactNode;
  columnTitle?: Column[];
  pageSize?: number;
  numberValues?: boolean;
}

export function ListTable({
  data,
  title,
  columnTitle,
  pageSize = 5,
  numberValues = false
}: DataGoalVisitationProps) {
  const [page, setPage] = useState(1);

  const [result, setResult] = useState(data);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(1);
    if (e.target.value === '') setResult(data);
    else {
      const r = data.filter((d) =>
        d.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setResult(r);
    }
  };

  const dataInPercentage = useMemo(() => {
    return result.slice((page - 1) * pageSize, page * pageSize);
  }, [page, pageSize, result]);

  const originalDataLength = result.length;
  const length = dataInPercentage.length;

  function handlePaginateNext() {
    setPage((page) => page + 1);
  }

  function handlePaginatePrev() {
    setPage((page) => page - 1);
  }

  return (
    <Container>
      <div>
        {title}

        {columnTitle && (
          <Box
            numberValues={numberValues}
            size={columnTitle.length}
            key="title"
          >
            <div className="box-title">
              {columnTitle?.map((d) => (
                <React.Fragment key={d.key}>
                  <Text>{d.name}</Text>
                </React.Fragment>
              ))}
            </div>
          </Box>
        )}

        {dataInPercentage.length === 0 ? (
          <EmptyList><p>Sem resultados</p></EmptyList>
        ) : (
          dataInPercentage.map((data, i) => {
            return (
              <Box
                numberValues={numberValues}
                size={columnTitle?.length ?? 1}
                key={data.name}
              >
                <div className="box-city">
                  <div>
                    <Text>{data.name}</Text>
                  </div>
                  {data.value && (
                    <div>
                      <Text>{data.value}</Text>
                    </div>
                  )}
                </div>
              </Box>
            );
          })
        )}
      </div>

      <div className="pagination">
        {length <= 1 ? (
          <p className="show-count">
            Mostrando <strong>1</strong> resultado.
          </p>
        ) : (
          <p className="show-count">
            Mostrando de{' '}
            <strong>
              {page * pageSize - (pageSize - 1)} a{' '}
              {page * pageSize - (pageSize - 1) + length - 1}
            </strong>{' '}
            de <strong>{originalDataLength}</strong> resultados
          </p>
        )}
        <Input
          placeholder="Buscar"
          allowClear
          onChange={onChange}
          suffix={<AiOutlineSearch style={{ color: 'rgba(0,0,0,.45)' }} />}
        />
        <div className="button-group">
          <button
            disabled={page === 1}
            className={page === 1 ? 'disabled' : ''}
            onClick={handlePaginatePrev}
            aria-label="Anterior"
          >
            <BsArrowLeft />
          </button>
          <button
            disabled={page * pageSize >= originalDataLength}
            className={page * pageSize >= originalDataLength ? 'disabled' : ''}
            onClick={handlePaginateNext}
            aria-label="Pŕoximo"
          >
            <BsArrowRight />
          </button>
        </div>
      </div>
    </Container>
  );
}
