import { useDashboardFilter } from '@/hooks';
import { livingConditionAgents } from '@/services';
import { getDataWithPercentage } from '@/utils/data';
import { DataPercentageComputed } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useLightTypesData() {
  const [lightTypes, setLightTypes] = useState<DataPercentageComputed[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    livingConditionAgents
      .getLightType(ibge, year, semester, zone)
      .then((data) => getDataWithPercentage(data))
      .then((data) => setLightTypes(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      lightTypes,
      loading,
      error
    }),
    [lightTypes, loading, error]
  );
}
