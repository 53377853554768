import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useEthnicity() {
  const [ethnicities, setEthnicities] = useState<Data[]>([]);
  const [loadingEthnicities, setLoadingEthnicities] = useState(false);
  const [errorEthnicities, setErrorEthnicities] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoadingEthnicities(true);
    profileSearch
      .getEthnicity(ibge, year, semester, zone)
      .then((data) => setEthnicities(data))
      .catch((err) => setErrorEthnicities(err))
      .finally(() => setLoadingEthnicities(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      ethnicities,
      loadingEthnicities,
      errorEthnicities
    }),
    [ethnicities, loadingEthnicities, errorEthnicities]
  );
}
