import styled from 'styled-components';

export const InsightsBox = styled.div`
  margin-top: 29px;
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
  gap: 1rem;
  grid-template-areas:
    'a b c'
    'd d d'
    'e e f'
    'g g h'
    'i i i';

  @media (max-width: 1380px) {
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    grid-template-areas:
      'a b'
      'c c'
      'd d'
      'e e'
      'f f'
      'g g'
      'h h'
      'i i';
  }

  @media (max-width: 950px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g'
      'h'
      'i';
  }
`;
