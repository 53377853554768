import styled from 'styled-components';

export const SecondTitle = styled.h2`
  color: ${(props) => (props.theme.darkMode ? '#FFF' : '#2d4040')};
  font-weight: 700;
  font-size: 1.5rem;
`;

export const ThirdTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 700;
  color: ${(props) => (props.theme.darkMode ? '#FFF' : '#2d4040')};
  padding-block: 0.5rem;
  margin: 1rem 0;
`;
