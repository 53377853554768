import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function usePregnantData(withAgeGroup = false) {
  const [pregnantData, setPregnantData] = useState<Data[]>([]);
  const [pregnantError, setPregnantError] = useState<Error | null>(null);
  const [pregnantLoading, setPregnantLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setPregnantLoading(true);
    profileSearch
      .getPregnant(ibge, year, semester, zone, withAgeGroup)
      .then((data) => setPregnantData(data))
      .catch((error) => setPregnantError(error))
      .finally(() => setPregnantLoading(false));
  }, [ibge, year, semester, withAgeGroup, zone]);

  return useMemo(
    () => ({
      pregnantData,
      pregnantError,
      pregnantLoading
    }),
    [pregnantData, pregnantError, pregnantLoading]
  );
}
