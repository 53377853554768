import { DataTree, RankingBind, RankingModel } from 'big-data';
import { Loading } from '../Loading';
import { Box, Icon } from './styles';
import { ColorText, Text } from '@/components/styled';
import { colors } from '@/theme/colors';
import { percentageFormatter } from '@/utils/string';
import { useTheme } from 'styled-components';

interface ListRankingProps {
  data: RankingBind[];
  selected: DataTree | undefined;
  loadingBenefits: boolean;
  color?: string;
  inverted?: boolean;
  total?: number;
  calculatedOut?: boolean;
  rejecteds?: RankingModel[]
}

function getPercent(value: number, total: number) {
  return percentageFormatter((value / total) * 100);
}

export function RankingDashboard({
  data,
  selected,
  color,
  loadingBenefits,
  total,
  calculatedOut,
  rejecteds
}: ListRankingProps) {
  const { darkMode } = useTheme();

  if (loadingBenefits) return <Loading />;

  const lightColor = darkMode ? colors.white : color ?? colors.ciano700;

  const totalValue =
    total ?? data.reduce((acc: number, cur: any) => acc + cur.value, 0);

  const description = (benefit: RankingBind) => [
    <span key="1">{benefit.description(selected?.name ?? '')}</span>,
    <ColorText key="2" color={color}>
      {benefit.name}{' '}
    </ColorText>
  ];

  return (
    <Box color={lightColor}>
      <ul className="benefit-list">
        {data.map((benefit) => {
          const IconPath = benefit.img;
          return (
            <li key={benefit.name}>
              <Icon color={benefit.color ?? lightColor}>{IconPath}</Icon>

              <Text>
                <ColorText color={color}>
                  {calculatedOut
                    ? benefit.value.toString().replace('.', ',') + '%'
                    : getPercent(benefit.value, totalValue)}{' '}
                </ColorText>
                {benefit.inverted
                  ? description(benefit).reverse()
                  : description(benefit)}
              </Text>
            </li>
          );
        })}

        {
          rejecteds?.map((benefit) => {
            const IconPath = benefit.img;
            return (
              <li key={benefit.name}>

                <Icon color={colors.gray500}>{IconPath}</Icon>

                <Text>
                  <ColorText color={colors.gray500}>
                    Houve um erro ao buscar informações sobre {benefit.name}
                  </ColorText>
                </Text>
              </li>
            )
          })
        }
      </ul>
    </Box>
  );
}
