import { useDashboardFilter } from '@/hooks';
import { profileSearch } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useWomanImpairmentData() {
  const [womanImpairment, setWomanImpairment] = useState<Data[]>([]);
  const [womanImpairmentLoading, setWomanImpairmentLoading] = useState(false);
  const [womanImpairmentError, setWomanImpairmentError] =
    useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setWomanImpairmentLoading(true);
    profileSearch
      .getWomanImpairment(ibge, year, semester, zone)
      .then((data) =>
        setWomanImpairment(data.sort((a, b) => b.value - a.value))
      )
      .catch((error) => setWomanImpairmentError(error))
      .finally(() => setWomanImpairmentLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      womanImpairment,
      womanImpairmentLoading,
      womanImpairmentError
    }),
    [womanImpairment, womanImpairmentError, womanImpairmentLoading]
  );
}
