import * as R from 'ramda';

import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { getCleanedGeolocation } from './utils';

import { Geolocation } from './@types';

const service = () => {
  const resource = `/saude-agentes-geolocalizacao`;

  const vaccineByNotebook = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            childVaccinesUpToDate: 'Não, visto na caderneta',
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return getCleanedGeolocation(response.data);
      }
    );

  const vaccineByCareGiver = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            childVaccinesUpToDate: 'Não, informado pela cuidador(a)',
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return getCleanedGeolocation(response.data);
      }
    );

  const vaccine = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const getVaccineByNotebook = vaccineByNotebook();
        const getVaccineByCareGiver = vaccineByCareGiver();

        const response = await Promise.all([
          getVaccineByNotebook.request(filter, city, min, max),
          getVaccineByCareGiver.request(filter, city, min, max)
        ]);

        const data = R.flatten(response);

        return getCleanedGeolocation(data);
      }
    );

  const healthMonitoring = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            childHasHealthAttended: 'Não',
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return getCleanedGeolocation(response.data);
      }
    );

  return Object.freeze({ vaccine, healthMonitoring });
};

export const health = service();
