import { cmic } from '@/services';
import { useEffect, useMemo, useState } from 'react';

export function useFamilyData() {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    cmic
      .amount()
      .then((data) => setAmount(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return useMemo(() => {
    return { amount, loadingAmount: loading, errorAmount: error };
  }, [amount, loading, error]);
}
