import { colors } from '@/theme/colors';
import styled, { keyframes } from 'styled-components';

const showBottom = keyframes`
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Box = styled.div`
  /* background-color: ${colors.green700}; */
  position: relative;
  text-align: center;

  .feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    max-width: 700px;
    width: 95%;

    svg,
    h2,
    p {
      animation: ${showBottom} 1s linear forwards;
    }

    svg {
      width: 150px;
      height: 150px;

      @media (max-width: 900px) {
        width: 100px;
        height: 100px;
      }
    }

    h2 {
      font-size: 4rem;
      color: ${colors.green700};

      @media (max-width: 900px) {
        font-size: 2rem;
      }

      @media (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 1.5rem;

      @media (max-width: 500px) {
        font-size: 1.2rem;
      }
    }
  }

  .buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin-block-start: 1.4rem;

    animation: ${showBottom} 1s 0.2s linear forwards;
    width: 200px;
    margin: 1.4rem auto;

    button {
      padding: 0.5rem 4rem;
      text-decoration: underline;
      color: ${colors.green600};
      width: 100%;

      transition: all 0.2s;

      &:hover {
        background-color: ${colors.green200};

        &:active {
          transform: scale(0.98);
        }
      }
    }

    a {
      border: none;
      border-radius: 7px;
      background-color: ${colors.green300};
      color: ${colors.white};
      padding: 1rem;
      width: 100%;

      &:hover {
        filter: brightness(0.7);
      }
    }

    .logout {
      background-color: ${colors.red300};
      border: none;
      color: #fff;
      border-radius: 7px;

      display: flex;
      /* flex-basis: 1; */
      gap: 0.5rem;
      align-items: center;

      &:hover {
        background-color: ${colors.red400};

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
`;
