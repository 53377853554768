import { cmic } from '@/services';
import geoJson from './ceara-geo.json';

interface FeatureCityData {
  type: string;
  geometry: any;
  properties: { id: number; name: string; total: number };
}

function extendsGeoJSON(jsonCeara: any, jsonData: any) {
  jsonCeara.features.forEach((feature: FeatureCityData) => {
    const { properties } = feature;
    properties.total = jsonData[properties.id];
  });
}

export const loadCearaGeoJSON = async () => {
  const cities = await cmic.getCitiesWithCodIbge();

  const data = cities.reduce(
    (acc, { codIbge, value }) => ({
      ...acc,
      [codIbge]: value
    }),
    {}
  );

  extendsGeoJSON(geoJson, data);

  return geoJson;
};
