import { strToInt } from '@/utils/types';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

function cleanEmptyString<T = {}>(data: T[], field: keyof T) {
  return data.filter(
    (d) => String(d[field]).trim() !== '' && String(d[field]) !== 'null'
  );
}

const service = () => {
  const resource = `/escolaridade-adulto`;

  function resolvePath(aggregations: string[]) {
    return `${resource}?aggregations=${getQuery(aggregations)}`;
  }

  async function getAmount() {
    const path = `${resource}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data[0].quantidade;
  }

  async function getCities() {
    const aggregations = ['MUNICIPIO', 'IBGE'];
    const path = resolvePath(aggregations);
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data
      .map((res) => ({
        name: res.municipio,
        codIbge: strToInt(res.ibge),
        value: res.quantidade
      }))
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
  }

  async function getSkinPeopleColor() {
    const aggregations = ['RACA_COR_PESSOA'];
    const path = resolvePath(aggregations);
    const response = await callService(() => mainApi.get<any[]>(path));
    return cleanEmptyString(
      response.data.map((res) => ({
        name: String(res.raca_cor_pessoa),
        value: res.quantidade
      })),
      'name'
    ).sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  }

  async function getSex() {
    const aggregations = ['SEXO_PESSOA'];
    const path = resolvePath(aggregations);
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data
      .map((res) => ({
        name: res.sexo_pessoa,
        value: res.quantidade
      }))
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
  }

  async function getMapResults(filter?: string[]) {
    const aggregations = [
      'MUNICIPIO',
      'IBGE',
      'SABE_LER_ESCREVERMEMB',
      filter ? filter.join(',') : ''
    ];
    const path = resolvePath(aggregations);

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data
      .filter((r) => r.sabe_ler_escrevermemb === 'Não')
      .map((res) => ({
        name: res.municipio,
        codIbge: Number(res.ibge),
        value: res.quantidade,
        sex: res.sexo_pessoa ?? null,
        skinColor: res.raca_cor_pessoa ?? null
      }));
  }

  async function getCourses(filter?: string[]) {
    const aggregations = [
      'CURSO_FREQUENTOU_PESSOAMEMB',
      filter ? filter.join(',') : ''
    ];
    const path = resolvePath(aggregations);

    const response = await callService(() => mainApi.get<any[]>(path));
    return cleanEmptyString(
      response.data.map((res) => ({
        value: res.quantidade,
        name: res.curso_frequentou_pessoamemb,
        codIbge: strToInt(res.ibge ?? 0),
        sex: res.sexo_pessoa ?? null,
        skinColor: res.raca_cor_pessoa ?? null
      })),
      'name'
    );
  }

  async function getAdultIlliterates(filters: string[]) {
    const aggregations = [
      'CONCLUIU_FREQUENTOUMEMB',
      filters ? filters.join(',') : ''
    ];
    const path = resolvePath(aggregations);

    const response = await callService(() => mainApi.get<any[]>(path));
    const data = response.data;
    const result = data.map((d) => ({
      name: d.concluiu_frequentoumemb,
      value: d.quantidade,
      codIbge: strToInt(d.IBGE ?? 0),
      sex: d.sexo_pessoa ?? null,
      skinColor: d.raca_cor_pessoa ?? null
    }));

    return cleanEmptyString(result, 'name');
  }

  return {
    getAmount,
    getCities,
    getSkinPeopleColor,
    getSex,
    getMapResults,
    getCourses,
    getAdultIlliterates
  };
};

export const schoolingAdult = service();
