import styled from 'styled-components';

import { colors } from '@/theme/colors';

interface BoxProps {
  disabled?: boolean;
}

export const Box = styled.div<BoxProps>`
  position: relative;

  input,
  textarea,
  .ant-input[disabled],
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.black)};
    &:hover {
      border: 1px solid white;
      background: none;
    }
  }

  .ant-input {
    &:focus {
      border-color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.ciano700};
    }
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    border: 1px solid ${colors.red400} !important;
  }

  .ant-form-item-label {
    label {
      color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray600)};
      font-weight: 700;
    }

    .ant-form-item-required::before {
      content: '' !important;
      margin-right: 0 !important;
    }

    .ant-form-item-required::after {
      display: inline-block;
      content: '(Obrigatório)';
      color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.red400};
      font-weight: 700;
    }
  }

  .ant-upload {
    .ant-btn svg {
      margin-bottom: -2px;
      margin-right: 5px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    @media (max-width: 400px) {
      flex-direction: column;
      justify-content: center;
      .cancel-button {
        button {
          margin: 0 auto;
        }
      }
    }
  }

  .cancel-button {
    button {
      padding: 0.75rem 1.5rem;
      border-radius: 7px;
      border: none;
      background-color: ${colors.gray200};
      color: ${colors.black};

      display: flex;
      gap: 0.5rem;

      transition: all 0.2s;

      &:hover {
        filter: brightness(0.9);

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }

  .submit-button .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;

    button {
      padding: 0.75rem 1.5rem;
      border-radius: 7px;
      border: none;
      background-color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.ciano700};
      color: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};

      display: flex;
      gap: 0.5rem;

      transition: all 0.2s;

      &:hover {
        filter: brightness(1.1);

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
`;

export const Tooltip = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 0.7rem;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  strong {
    color: inherit;
    font-size: 0.8rem;
  }
`;
