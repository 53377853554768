import { colors } from '@/theme/colors';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ReactRouterLinkProps extends LinkProps {
  $isActive?: boolean;
  disabled?: boolean;
  hasArrow?: boolean;
  overlayVisible?: boolean;
  invisible?: boolean;
  mobile?: boolean;
}

export const ReactRouterLink = styled(Link)<ReactRouterLinkProps>`
  display: flex;
  align-items: center;

  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.color};
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: bold;
  font-style: normal;

  ${(props) =>
    props.$isActive &&
    css`
      color: ${colors.green200};
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
      pointer-events: none;
    `}
  ${(props) =>
    props.hasArrow &&
    css`
      &::after {
        position: relative;
        ${arrow(props)};

        ${props.mobile &&
        css`
          display: none;
        `}
      }
    `}
`;

const colorCondition = (cb: () => string | undefined, condition?: boolean) => {
  const callbackColor = cb();

  return condition ? colors.green200 : callbackColor ?? '#000';
};

const arrow = (props: any) => css`
  position: relative;

  content: '';
  width: 0;
  height: 0;

  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${props.overlayVisible
    ? `border-bottom: 5px solid ${colorCondition(
        () => props.color,
        props.isActive
      )}`
    : `border-top: 5px solid ${colorCondition(
        () => props.color,
        props.isActive
      )}`};
  margin-left: 5px;
`;
