import styled from 'styled-components';

export const Box = styled.ul`
  list-style: none;

  .legend-item {
    display: flex;
    align-items: center;

    & + li {
      margin-top: 1rem;
    }

    .label {
      font-size: 1rem;
      color: #a3a3a3;
    }
  }
`;

export const Ball = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border: 4px solid ${({ color }) => `${color}80`};

  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
