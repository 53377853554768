import { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { AiOutlineMenu } from 'react-icons/ai';

import { LoginButton } from '@/components/shared';
import { ILink } from '@/pages/links';

import { Box } from './styles';
import { MenuItem } from '..';
import { DefaultTheme, useTheme } from 'styled-components';

type IItem = ILink & { isActive: boolean };

interface MobileMenuProps {
  items: IItem[];
}

export function MobileMenu({ items }: MobileMenuProps) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const theme = useTheme();

  return (
    <Box>
      <Dropdown
        overlay={menu(items, theme)}
        placement="bottomRight"
        visible={dropdownVisible}
        onVisibleChange={(visible) => setDropdownVisible(visible)}
        trigger={['click']}
        destroyPopupOnHide
        overlayStyle={{
          position: 'fixed',
          zIndex: 99999999
        }}
      >
        <button type="button" className={dropdownVisible ? `active` : ``}>
          <AiOutlineMenu color="#FFF" size={27} />
        </button>
      </Dropdown>
    </Box>
  );
}

const menu = (items: IItem[], theme?: DefaultTheme) => (
  <Menu
    style={{
      marginTop: `-6px`,
      borderTopLeftRadius: 0,
      backgroundColor: theme?.darkMode ? '#000' : '',
      border: `2px solid #fff`
    }}
  >
    <Menu.ItemGroup className="dropdown-menu">
      {items.map((item) => (
        <Menu.Item key={item.name}>
          <MenuItem item={item} mobile />
        </Menu.Item>
      ))}
      <LoginButton />
    </Menu.ItemGroup>
  </Menu>
);
