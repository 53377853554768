import { colors } from '@/theme/colors';
import styled from 'styled-components';

interface BoxProps {
  title?: string;
}

export const Box = styled.div<BoxProps>`
  background: ${(props) =>
    props.theme.darkMode ? colors.black : colors.white};
  border: 1px solid #dfe6e9;
  border-radius: 7px;
  padding: 1.3rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 210px;
  justify-content: space-between;

  .header-content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .title {
      h3,
      span {
        font-weight: 700;
        line-height: 20px;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1.25625px;
      }

      span {
        color: ${(props) =>
          props.theme.darkMode ? colors.yellow500 : colors.ciano700};
      }
    }

    svg {
      width: 45px;
      height: 45px;
      background: #1d69701a;
      padding: 0.6rem;
      border-radius: 10px;
    }

    svg:not(.lgbtqia-plus) {
      path {
        fill: ${({ theme, title }) =>
          theme.darkMode && !title?.toLowerCase().includes('lgbtqia')
            ? colors.yellow500
            : colors.ciano700};
        stroke: ${({ theme, title }) =>
          theme.darkMode && !title?.toLowerCase().includes('lgbtqia')
            ? colors.yellow500
            : colors.ciano700};
        stroke-width: 0;
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    letter-spacing: 0.02em;

    color: ${(props) => (props.theme.darkMode ? colors.white : '')};
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 5px;

    width: min-content;

    text-align: center;

    background: ${(props) =>
      props.theme.darkMode ? 'transparent' : '#f0f3f5'};
    border: 1px solid
      ${(props) => (props.theme.darkMode ? colors.yellow500 : colors.ciano700)};
    border-radius: 7px;

    span {
      line-height: 22px;
      color: ${(props) =>
        props.theme.darkMode ? colors.yellow500 : colors.ciano700};
    }

    svg {
      color: ${(props) =>
        props.theme.darkMode ? colors.yellow500 : colors.ciano700};
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.darkMode ? '' : colors.ciano700};
      border: 1px solid ${(props) => (props.theme.darkMode ? colors.white : '')};

      span,
      svg {
        color: ${colors.white};
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
`;
