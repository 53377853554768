import { colors } from '@/theme/colors';
import styled from 'styled-components';
type BoxProps = {
  size?: number;
};
export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.ciano700)};
    font-weight: 700;
    font-size: ${(props) => (props.size ? props.size / 20 : 2.2)}rem;
    line-height: 24px;
  }

  span i {
    color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.gray500)};
    font-weight: 400;
    font-size: ${(props) => (props.size ? props.size / 40 : 1.1)}rem;
  }
`;
