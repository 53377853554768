import { colors } from '@/theme/colors';
import { lighten } from 'polished';
import styled from 'styled-components';
import { FilterIcon } from './icons/Filter';

export function FilterIndicatorIcon() {
  return (
    <Box className="icon-wrapper">
      <FilterIcon />
    </Box>
  );
}

const Box = styled.div`
  &.icon-wrapper {
    background-color: ${({ theme }) =>
      theme.darkMode ? lighten(0.1, theme.background) : colors.gray100};
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};

    border-radius: 9px;
    padding: 10px;
  }
`;
