import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

const boxDark = css`
  background: #000;
`;

interface BoxProps {
  height?: number;
}
export const Box = styled.div<BoxProps>`
  max-width: 320px;
  width: 100%;

  .image {
    height: ${(props) => (props.height ? props.height : 300)}px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  img {
    height: 100%;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  ${({ theme }) => theme.darkMode && boxDark}

  @media (max-width: 1240px) {
    max-width: 100%;
    width: 100%;

    display: flex;
    align-items: center;

    img {
      border-radius: 0.5rem 0 0 0.5rem;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    img {
      width: 100%;
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
`;

const contentDark = css`
  .title,
  .description {
    color: #fff;
  }
`;

export const Content = styled.div`
  padding: 2rem 2rem;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${({ color, theme }) => color ?? theme.greenDefault};
    margin-bottom: 21px;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;

    color: ${(props) => (props.theme.darkMode ? '#fff' : colors.gray700)};
  }

  .reference {
    width: fit-content;
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.darkMode && contentDark}
`;
