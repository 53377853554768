import styled from 'styled-components';
import { Select } from '@/components/shared/SelectBox';
import { useTopPages } from '@/servicesHooks/googleAnalytics';
import { ServiceBox } from '@/components/shared';

import {
  CardBase,
  CardHeader,
  CardFooter,
  CardContent,
  Table,
  Pagination
} from '../../components';
import { useTabContext, useFilterContext } from '../../contexts';
import { useState } from 'react';
import { generateTag, filterOrderOptions } from '../../utils';

export function CardAccessedPages() {
  const [page, setPage] = useState(0);
  const [orderFilter, setOrderFilter] = useState<string>();
  const { tab } = useTabContext();
  const { city } = useFilterContext();

  const { data, error, loading, totalPages } = useTopPages(
    tab?.value,
    city?.ibge,
    orderFilter,
    page
  );

  return (
    <Box>
      <CardHeader
        title="Páginas acessadas"
        subtitle={generateTag([city?.name, tab?.name])}
      />

      <ServiceBox empty={data.length === 0} loading={loading} error={error}>
        <CardContent>
          <Table
            data={data.map((d) => ({
              pages: d.name,
              numberOfExhibitions: `${d.value} exibições`
            }))}
            headers={['Páginas', 'Número de exibições']}
            countStart={page * 5}
          />
        </CardContent>
      </ServiceBox>

      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            placeholder="Selecione..."
            options={filterOrderOptions}
            value={orderFilter}
            onChange={(value) => setOrderFilter(String(value))}
          />
          <Pagination
            onPaginate={(page) => setPage(page)}
            page={page}
            totalPages={totalPages - 1}
            resetOnChange={city?.ibge || tab?.value}
          />
        </div>
      </CardFooter>
    </Box>
  );
}

const Box = styled(CardBase)``;
