import styled from 'styled-components';
import { Modal } from 'antd';
import { colors } from '@/theme/colors';

export const Box = styled(Modal).attrs({
  width: 600,
  destroyOnClose: true,
  footer: null
})`
  top: 30px;

  button.ant-btn.ant-btn-default {
    background: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    color: ${({ theme }) => (theme.darkMode ? colors.white : '')};

    &:hover {
      color: ${({ theme }) => (theme.darkMode ? colors.yellow500 : '')};
      border-color: ${({ theme }) => (theme.darkMode ? colors.yellow500 : '')};
    }
  }

  .anticon svg {
    color: ${({ theme }) =>
      theme.darkMode ? colors.white : colors.black} !important;
  }

  .ant-slider-track {
    background: ${({ theme }) =>
      theme.darkMode ? colors.yellow500 : colors.ciano700} !important;
  }

  .ant-slider-handle.ant-tooltip-open,
  .ant-slider-dot-active {
    border-color: ${({ theme }) =>
      theme.darkMode ? colors.yellow500 : colors.ciano700} !important;
  }

  span.ant-slider-mark-text {
    color: ${({ theme }) =>
      theme.darkMode ? colors.white : colors.black} !important;
  }

  .ant-modal-content {
    padding: 0 1rem;
    border-radius: 7px;

    background: ${(props) =>
      props.theme.darkMode ? colors.black : colors.white};
    border: 1px solid
      ${(props) => (props.theme.darkMode ? '#dfe6e9' : 'transparent')};
  }

  .ant-modal-header {
    background: none;
    .ant-modal-title {
      color: ${(props) =>
        props.theme.darkMode ? colors.white : colors.ciano700};
      font-weight: 700;
      font-size: 1.25rem;
    }
  }

  .ant-alert {
    margin-bottom: 1.5rem;
    align-items: flex-start;

    .ant-alert-icon {
      margin-top: 0.2rem;
    }
  }
`;
