import { foodInsecurity } from '@/services';
import { useEffect, useMemo, useState } from 'react';

export function useFamilyPandemic(name?: string) {
  const [pandemicValue, setPandemicValue] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    foodInsecurity
      .getPandemicImpact(name)
      .then((data) => setPandemicValue(data))
      .finally(() => setLoading(false));
  }, [name]);

  return useMemo(
    () => ({
      pandemicValue,
      loadingBenefits: loading
    }),
    [pandemicValue, loading]
  );
}
