import { createContext, ReactNode, useMemo, useState } from 'react';

interface FilterContextProps {
  city?: string;
  ibge?: string;
  handleChangeCity: (city?: string) => void;
  onClear: () => void;
}

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterContext = createContext({} as FilterContextProps);

export function FilterProvider(props: FilterProviderProps) {
  const [city, setCity] = useState<string>();
  const [ibge, setIbge] = useState<string>();

  function handleChangeCity(city?: string) {
    if (city) {
      const c = city.split('_');
      setCity(c[0]);
      setIbge(c[1]);
    } else {
      setCity(undefined);
      setIbge(undefined);
    }
  }

  function onClear() {
    setCity(undefined);
    setIbge(undefined);
  }

  const contextValues = useMemo(
    () => ({ city, ibge, handleChangeCity, onClear }),
    [city, ibge]
  );

  return (
    <FilterContext.Provider value={contextValues}>
      {props.children}
    </FilterContext.Provider>
  );
}
