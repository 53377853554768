import { mainApi } from './config/api';
import { callService } from './config/service';

const service = () => {
  const resource = `/cidades`;

  async function getCities() {
    const path = `${resource}`;

    const response = await callService(() => mainApi.get<any[]>(path));

    return response.data.map((d) => ({
      name: d.ibge,
      value: d.municipio
    }));
  }

  return {
    getCities
  };
};

export const citiesService = service();
