import { ensureAxiosParamOptions } from '@/utils/routes';
import { Data, DataIbge } from 'big-data';
import { DashboardRequestParams } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getAggregation, getQuery } from './config/utils';

interface ProfileSearchQueryParams extends DashboardRequestParams {
  aggregations: string[];
}

const service = () => {
  const resource = `/perfil-pesquisa-agentes`;
  const abortController = new AbortController();

  function cancelRequest() {
    abortController.abort();
  }

  async function generateGeneralRequest(
    path: string,
    params: ProfileSearchQueryParams
  ) {
    const { ibge, year, semester, aggregations, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        ibge,
        year,
        semester,
        zone
      },
      signal: abortController.signal
    });

    return callService(() => mainApi.get<any[]>(path, options));
  }

  async function getPaidWorkInfo() {
    const aggregations = ['ALGUEM_TRAB_REMUNERADO'];
    const path = `${resource}?${getAggregation(aggregations)}`;

    const configRequest = { signal: abortController.signal };
    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.alguem_trab_remunerado,
      value: d.quantidade
    }));
  }

  async function getInterviewedByYear(): Promise<Data<number, number>[]> {
    const aggregations = ['ANO'];
    const path = `${resource}?${getAggregation(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.ano,
      value: d.quantidade
    }));
  }

  async function getInterviewedBySemester() {
    const aggregations = ['SEMESTRE'];
    const path = `${resource}?${getAggregation(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.semestre,
      value: d.quantidade
    }));
  }

  async function getInsertedInDangerousArea() {
    const aggregations = ['AREA_VIOLENTA'];
    const path = `${resource}?${getAggregation(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.area_violenta,
      value: d.quantidade
    }));
  }

  async function getEthnicity(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['COR_OU_RACA'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.cor_ou_raca,
      value: d.quantidade
    }));
  }

  async function getWomanMaritalStatus(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['ESTADO_CIVIL_MULHER'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.estado_civil_mulher,
      value: d.quantidade
    }));
  }

  async function getAges(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['FAIXA_ETARIA'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.faixa_etaria,
      value: d.quantidade
    }));
  }

  async function getStudyDegree(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['GRAU_ESCOLARIDADE'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.grau_escolaridade.replace(/\w+ \((.+)\)/, 'ensino $1'),
      value: d.quantidade
    }));
  }

  async function getWomanAnswer(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MULHER_RESPONDEU_PESQUISA'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.mulher_respondeu_pesquisa,
      value: d.quantidade
    }));
  }

  async function getInterviewedByCity(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ): Promise<DataIbge[]> {
    const aggregations = ['MUNICIPIO', 'IBGE'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    return response.data.map((d) => ({
      name: d.municipio,
      codIbge: d.ibge,
      value: d.quantidade
    }));
  }

  async function getHouseType() {
    const aggregations = ['TIPO_MORADIA'];
    const path = `${resource}?${getAggregation(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.tipo_moradia,
      value: d.quantidade
    }));
  }

  async function getInterviewedByZone() {
    const aggregations = ['ZONA'];
    const path = `${resource}?${getAggregation(aggregations)}`;
    const configRequest = { signal: abortController.signal };

    const response = await callService(() =>
      mainApi.get<any[]>(path, configRequest)
    );

    return response.data.map((d) => ({
      name: d.zona,
      value: d.quantidade
    }));
  }

  async function getQualificationCourseInTheLast12Monthes(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['ULTIMOS_12MESES_ALGUEM_FEZ_CURSO_QUALIFICACAO'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getQualificationCourseInterests(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['GOSTARIA_FAZER_CURSO_QUALIFICACAO'];
    const path = resource;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data
      .map((data) => ({
        name: data[aggregations[0].toLowerCase()],
        value: data.quantidade
      }))
      .sort((a, b) => b.value - a.value);
  }

  async function getWomanImpairment(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string
  ) {
    const aggregations = ['MLR_POSSUI_DEFICIENCIA'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      semester,
      year,
      zone
    });

    return response.data.map((d) => ({
      name: d.mlr_possui_deficiencia,
      value: d.quantidade
    }));
  }

  async function getPregnant(
    ibge?: string,
    year?: string,
    semester?: string,
    zone?: string,
    withAgeGroup = false
  ) {
    const aggregations = withAgeGroup
      ? ['EXISTE_GRAVIDA_DOMICILIO', 'FAIXA_ETARIA']
      : ['EXISTE_GRAVIDA_DOMICILIO'];
    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations,
      ibge,
      year,
      semester,
      zone
    });

    const data = response.data.map((d) => ({
      name: d[aggregations[0].toLowerCase()],
      value: d.quantidade,
      ageGroup: d.faixa_etaria ?? null
    }));

    if (withAgeGroup) {
      return data
        .filter((d) => d.name === 'Sim')
        .map((d) => ({ name: d.ageGroup, value: d.value }));
    }

    return data;
  }

  async function getYearsSemesters(): Promise<Data[]> {
    const aggregations = ['SEMESTRE', 'ANO'];

    const path = `${resource}`;

    const response = await generateGeneralRequest(path, {
      aggregations
    });

    return response.data
      .map((data) => ({
        name: `${data.ano}.${data.semestre}`,
        value: data.quantidade
      }))
      .filter((data) => data.name !== 'null.null');
  }

  async function getZones() {
    const path = resource;
    const aggregations = ['ZONA'];

    const response = await generateGeneralRequest(path, {
      aggregations
    });

    return response.data
      .map((data) => ({
        name: data.zona,
        value: data.quantidade
      }))
      .filter((data) => data.name !== 'null');
  }

  return {
    cancelRequest,
    getPaidWorkInfo,
    getInterviewedByYear,
    getInterviewedBySemester,
    getInsertedInDangerousArea,
    getEthnicity,
    getWomanMaritalStatus,
    getAges,
    getStudyDegree,
    getWomanAnswer,
    getInterviewedByCity,
    getHouseType,
    getInterviewedByZone,
    getQualificationCourseInTheLast12Monthes,
    getQualificationCourseInterests,
    getWomanImpairment,
    getPregnant,
    getYearsSemesters,
    getZones
  };
};

export const profileSearch = service();
