import { InsightNormalCard } from './styles';

import { NormalCardHeader } from '../Card/styles';
import { useTheme } from 'styled-components';

interface InsightNormalCardProps {
  title: string;
  subtitle: string;
  number: {
    value: string;
    color: string;
    icon: ({ color }: { color: string }) => JSX.Element;
  };
  description: string;
}

export function NormalCard({
  description,
  subtitle,
  title,
  number
}: InsightNormalCardProps) {
  const { icon: Icon } = number;
  const { darkMode } = useTheme();

  return (
    <InsightNormalCard>
      <NormalCardHeader>
        <h4>{title}</h4>
        <span>{subtitle}</span>
      </NormalCardHeader>

      <div className="card-icon-value">
        <Icon color={darkMode ? `#fff` : number.color} />
        <strong className="value" style={{ color: number.color }}>
          {number.value}
        </strong>
      </div>

      <p className="description">{description}</p>
    </InsightNormalCard>
  );
}
