import { colors } from '@/theme/colors';
import { lighten } from 'polished';
import styled from 'styled-components';

export const Pagination = styled.div`
  margin: 20px auto 0;
  max-width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 46px;
    height: 46px;
    border: 1px solid ${colors.ciano700};
    background: transparent;
    border-radius: 10px;
    color: ${colors.ciano700};

    transition: background 0.2s;

    &:disabled {
      border: 1px solid #dfe6e9;
      color: #dfe6e9;
    }

    &:hover:not(:disabled) {
      background: ${lighten(0.45, colors.ciano700)};
    }
  }

  span {
    color: ${({ theme }) => (theme.darkMode ? `#fff` : `#979797`)};
    font-size: 1rem;
  }

  .total-pages {
    color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.ciano700)};
  }
`;
