import { Data } from 'big-data';
import { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';

import { colors as appColors } from '@/theme/colors';

import { Box, ContentBox, Svg } from './style';
import { Pie } from './Pie';
import { Legend } from './Legend';
import { useTheme } from 'styled-components';

interface DonutProps {
  data: Data[];
  thickness: number;
  animate?: boolean;
  colors?: string[];
  exactColors?: boolean;
  onClick?: (data: Data) => void;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  donutWidth?: number;
  donutHeight?: number;
}

const RANGE_COLORS = [
  appColors.yellow200,
  appColors.green500,
  appColors.orange500,
  appColors.blue500
];

const margins = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20
};

const manualColor = (data: Data[], colors?: string[]) => {
  if (!colors || data.length !== colors.length) return;

  const colorRelation = data.reduce((acc, cur, ind) => {
    acc.set(cur.name, colors[ind]);

    return acc;
  }, new Map<string, string>());

  return (d: Data) => {
    return colorRelation.get(d.name) as string;
  };
};

export function Donut({
  data,
  thickness,
  animate = true,
  colors = ['#87c878', '#2c403f'],
  exactColors,
  onClick,
  flexDirection = 'row',
  donutWidth = 500
}: DonutProps) {
  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(donutWidth);
  const [selectedPiece, setSelectedPiece] = useState<string | null>(null);

  const { screen } = useTheme();

  const values = data.map((d) => d.value);
  const max = Math.max(...values);
  const min = Math.min(...values);

  const getManualColor = manualColor(data, colors);
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: [min, max],
        range: RANGE_COLORS
      }),
    [min, max]
  );

  const getColor = (data: Data) => {
    if (exactColors && getManualColor) {
      return getManualColor(data);
    }

    return colorScale(data.value);
  };

  const resize = useCallback(() => {
    const box = boxRef.current;

    if (box) {
      const width = box.parentElement?.clientWidth;

      width && setWidth(width - margins.left - margins.right);
    }
  }, []);

  useEffect(() => {
    resize();
  }, [resize]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  const pie = useMemo(() => {
    const relativeWidth = width <= 374 ? width : width * 0.6;
    const height = relativeWidth * 1;

    const k1 = width <= 374 ? 0.75 : 0.7;
    const k2 = width <= 374 ? 1 : 0.6;

    return {
      width: relativeWidth,
      height,
      radius: Math.min(width * k1, height * k1) / 2,
      center: [(width * k2) / 2, height / 2],
      thickness
    };
  }, [width, thickness]);

  function renderChartContent() {
    return (
      <Svg width={pie.width} height={pie.height}>
        <Group top={pie.center[1]} left={pie.center[0]}>
          <Pie
            data={
              selectedPiece
                ? data.filter((d) => d.name === selectedPiece)
                : data
            }
            animate={animate}
            radius={pie.radius}
            thickness={pie.thickness}
            colorScale={getColor}
            onClick={(data) => {
              setSelectedPiece(
                selectedPiece && selectedPiece === data.name ? null : data.name
              );
            }}
          />
        </Group>
      </Svg>
    );
  }

  return (
    <Box ref={boxRef}>
      <ContentBox
        style={{ flexDirection: screen.small ? 'column' : flexDirection }}
      >
        {renderChartContent()}
        <Legend
          items={data.map((d) => ({
            percentage: d.value,
            text: d.name,
            color: getColor(d)
          }))}
          selected={selectedPiece}
        />
      </ContentBox>
    </Box>
  );
}
