import { CardTagged, DonutPopulated, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useWorkersByAmountData } from '@/servicesHooks/jobIncome';
import { colors } from '@/theme/colors';
import { useTheme } from 'styled-components';
import { darken } from 'polished';

const LIGHT_COLORS = ['#081F20','#1D696F', '#D5F9FC','#2A9AA2'];
const DARK_COLORS = Array.from({ length: 4 }).map((_, i) =>
  darken((i - 2) / 10, colors.yellow400) 
);

export function CardDonut() {
  const { tag } = useDashboardFilter();
  const { darkMode } = useTheme();
  const { data, ...rest } = useWorkersByAmountData();

  const parsedData = data
    .map((item) => {
      const number = Number(item.name.replace(/D/g, ''));

      if (number > 3) {
        return {
          name: 'Mais de 3 pessoas',
          value: item.value
        };
      }

      return {
        ...item,
        name: number + (number > 1 ? ' pessoas' : ' pessoa')
      };
    })
    .reduce((acc, item) => {
      return {
        ...acc,
        [item.name]: (acc[item.name] ?? 0) + item.value
      };
    }, {} as Record<string, number>);

  const finalData = Object.entries(parsedData).map(([key, value]) => ({
    name: `possuem ${key} trabalhando na residência`,
    value
  }));

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a quantidade de pessoas com trabalho remunerado por residência?
        </TitleText>

        <DonutPopulated
          data={finalData}
          thickness={30}
          flexDirection="column"
          percentage
          colors={darkMode ? DARK_COLORS : LIGHT_COLORS}
          exactColors
          donutHeight={200}
          donutWidth={200}
          showPercentage={false}
          initialRenderData={data[0]}
        />
      </ServiceBox>
    </CardTagged>
  );
}
