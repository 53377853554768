import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';
import { googleAnalyticsResource } from './utils/resource';

const service = () => {
  const resource = googleAnalyticsResource;

  async function getUsersDeviceTypes(minimumDate?: string, ibge?: string) {
    const path = `${resource}/tipo-dispositivo`;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        fromCeara: true
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((data) => ({
      name: data.tipo_dispositivo,
      value: data.quantidade
    }));
  }

  async function getAccessedPages() {
    const path = resource;
    const aggregations = ['PAGINA'];
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations)
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((data) => ({
      name: data[aggregations[0].toLowerCase()],
      value: data.quantidade
    }));
  }

  async function getRegionAccess() {
    const path = resource;
    const aggregations = ['REGIAO_ACESSO'];
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations)
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => ({
      name: d[aggregations[0].toLowerCase()],
      value: d.quantidade
    }));
  }

  async function getCityAccess(
    minimumDate?: string,
    ibge?: string,
    order?: string
  ) {
    const path = `${resource}/sessoes-cidade`;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        order,
        fromCeara: true
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => ({
      name: d.cidade_acesso,
      value: d.sessoes,
      ibge: d.ibge
      // date: d.data
    }));
  }

  async function getUserType(minimumDate?: string, ibge?: string) {
    const path = `${resource}/tipo-usuario`;
    const options = ensureAxiosParamOptions({
      params: {
        minimumDate,
        ibge,
        fromCeara: true
      }
    });
    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => ({
      name: d.tipo_usuario,
      value: d.quantidade
    }));
  }

  return {
    getUsersDeviceTypes,
    getAccessedPages,
    getRegionAccess,
    getCityAccess,
    getUserType
  };
};

export const home = service();
