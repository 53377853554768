import { getLegends } from './legends';
import { Box, Ball } from './styles';

interface ClusterLegendProps {
  filters: string[];
}

export function ClusterLegend({ filters }: ClusterLegendProps) {
  const legends = getLegends(filters);

  return (
    <Box>
      {legends.map((legend) => (
        <li key={legend.color} className="legend-item">
          <Ball color={legend.color}>n</Ball>
          <span className="label">{legend.label}</span>
        </li>
      ))}
    </Box>
  );
}
