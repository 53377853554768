import { profileSearch } from '@/services/profileSearch';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useZoneData() {
  const [zones, setZones] = useState<Data[]>([]);
  const [zonesLoading, setZonesLoading] = useState(false);
  const [zonesError, setZonesError] = useState<Error | null>(null);

  useEffect(() => {
    setZonesLoading(true);
    profileSearch
      .getZones()
      .then((data) => setZones(data))
      .catch((error) => setZonesError(error))
      .finally(() => setZonesLoading(false));
  }, []);

  return useMemo(
    () => ({
      zones,
      zonesError,
      zonesLoading
    }),
    [zones, zonesError, zonesLoading]
  );
}
