import ods1Img from '@/assets/icons/ods/ODS1.png';
import ods2Img from '@/assets/icons/ods/ODS2.png';
import ods3Img from '@/assets/icons/ods/ODS3.png';
import ods4Img from '@/assets/icons/ods/ODS4.png';
import ods5Img from '@/assets/icons/ods/ODS5.png';
import ods6Img from '@/assets/icons/ods/ODS6.png';
import ods8Img from '@/assets/icons/ods/ODS8.png';
import ods9Img from '@/assets/icons/ods/ODS9.png';
import ods10Img from '@/assets/icons/ods/ODS10.png';
import ods11Img from '@/assets/icons/ods/ODS11.png';
import ods16Img from '@/assets/icons/ods/ODS16.png';

interface OdsDetails {
  id: number;
  name: string;
  mainGoals: string;
  description: string;
  img: string;
  color: string;
  path: string;
}

export const odsDetails: Record<number, OdsDetails> = {
  1: {
    id: 1,
    name: 'ODS 1: Erradicação da Pobreza',
    mainGoals:
      'Acabar com a pobreza em todas as suas formas, em todos os lugares',
    description:
      'Erradicar a pobreza em todas as suas formas segue sendo um dos principais desafios que enfrenta a humanidade. Enquanto o número de pessoas vivendo em extrema pobreza caiu mais da metade em 1990 e 2015 – de 1.9 bilhão para 836 milhões –, muitos ainda sofrem para satisfazer necessidades básicas. Em nível mundial, mais de 800 milhões de pessoas ainda vivem com menos de U$ 1,25 por dia, muitos carecem de acesso a alimentos, água potável e saneamento adequados. Mulheres estão mais sujeitas a viverem na pobreza do que os homens, devido a falta de acesso a trabalhos remunerados, educação e prosperidade.',
    img: ods1Img,
    color: '#E5243B',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=1'
  },
  2: {
    id: 2,
    name: 'ODS 2: Fome zero e agricultura sustentável',
    mainGoals:
      'Acabar com a fome, alcançar a segurança alimentar e melhoria da nutrição e promover a agricultura sustentável. ',
    description:
      'Os ODS tem a meta de acabar com todas as formas de fome e a má-nutrição até 2030, garantindo que todas as pessoas – especialmente as crianças – tenham acesso suficiente a comidas nutritivas durante todo o ano. Isso envolve promover práticas agrícolas sustentáveis, apoiar pequenos agricultores e garantir acesso igualitário à terras, tecnologia e mercados. Também requer cooperação internacional para garantir investimentos em infraestrutura para apoiar a produção agrícola. Junto com outros objetivos, podemos acabar com a fome em 2030.',
    img: ods2Img,
    color: '#DDA83A',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=2'
  },
  3: {
    id: 3,
    name: 'ODS 3: Saúde e Bem-Estar',
    mainGoals:
      'Garantir o acesso à saúde de qualidade e promover o bem-estar para todos, em todas as idades. ',
    description:
      'O ODS 3 tem seu foco principal metas como: a redução drástica da mortalidade materna, o fim das mortes evitáveis de bebês e crianças, e o combate a doenças transmissíveis. Também trata da redução a um terço das mortes prematuras por doenças não transmissíveis, como hipertensão e diabetes, problemas pelo consumo de álcool, de tabaco e de entorpecentes e as mortes em situações de trânsito. O acesso aos sistemas de saúde e o sistema de prevenção e de proteção do bem estar dos cidadãos são abordados nesta ODS, assim como novos desenvolvimentos de vacinas e pesquisas para a melhoria da saúde no planeta.',
    img: ods3Img,
    color: '#4C9F38',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=3'
  },
  4: {
    id: 4,
    name: 'ODS 4: Educação de qualidade',
    mainGoals:
      'Garantir o acesso à educação inclusiva, de qualidade e equitativa, e promover oportunidades de aprendizagem ao longo da vida para todos. ',
    description:
      'Essa ODS tem como objetivo que todos os jovens concluam a educação básica e que todo o processo de educação formal garanta  o desenvolvimento das potencialidades dos indivíduos, desde a primeira infância  até a formação técnica e superior. A alfabetização de jovens e adultos e sua qualificação para o mundo do trabalho são preocupações que complementam este objetivo. Com recursos humanos motivados e capazes, as escolas poderão transmitir aos seus estudantes os princípios de cidadania global, valorização de diversidades e a educação para o desenvolvimento sustentável.',
    img: ods4Img,
    color: '#C5192D',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=4'
  },
  5: {
    id: 5,
    name: 'ODS 5: Igualdade de gênero',
    mainGoals:
      'Alcançar a igualdade de gênero e empoderar todas as mulheres e meninas. ',
    description:
      'A igualdade de gênero, com empoderamento de meninas e mulheres é o tema do ODS 5. Esse objetivo pede o fim da discriminação e da violência contra mulheres e meninas. Para além da dimensão da cidadania e do direito à vida, a meta trata também de questões econômicas, buscando reformas que levem a direitos iguais à propriedade, controle sobre a terra, recursos financeiros, herança e recursos naturais. Como estratégia para conferir mais poder as mulheres, está o uso das novas Tecnologias de Informação e Comunicação (TICs).',
    img: ods5Img,
    color: '#FF3A21',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=5'
  },
  6: {
    id: 6,
    name: 'ODS 6: Água potável e saneamento',
    mainGoals:
      'Garantir a disponibilidade e a gestão sustentável da água potável e do saneamento para todos. ',
    description:
      'A preocupação com a existência de água potável e segura para todos é o centro desse ODS. Indissociável desta temática é a oferta de saneamento e higiene, uma vez que a falta destes pode levar à contaminação do solo, de rios, mares e fontes de fontes de água para abastecimento. O aspecto social da água, recurso indispensável a vida, é apenas um dos vieses do ODS 6. Por fim, são necessários marcos institucionais para favorecer a participação social, para controle do uso da água e monitoração da proteção do meio ambiente.',
    img: ods6Img,
    color: '#26BDE2',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=6'
  },
  8: {
    id: 8,
    name: 'ODS 8: Trabalho decente e crescimento econômico',
    mainGoals:
      'Promover o crescimento econômico inclusivo e sustentável, o emprego pleno e produtivo e o trabalho digno para todos. ',
    description:
      'O ODS 8 tem como centro o mundo do trabalho e do desenvolvimento econômico. O emprego decente, o empreendedorismo e o valor à criatividade e à inovação incentivando a formalização e o crescimento de micro, pequenas e médias empresas. Uma preocupação especial é com o trabalho para grupos sociais específicos, como as mulheres, pessoas com deficiência e os jovens. Além disso, a meta propõe o incentivo ao turismo sustentável, que gera empregos e promove a cultura, e o respeito aos direitos trabalhistas, inclusive de migrantes.',
    img: ods8Img,
    color: '#A21942',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=8'
  },
  9: {
    id: 9,
    name: 'ODS 9: Indústria, inovação e infraestrutura',
    mainGoals:
      'Construir infraestruturas resilientes, promover a industrialização inclusiva e sustentável e fomentar a inovação. ',
    description:
      'Este ODS trata, principalmente, do desenvolvimento da indústria, da inovação e da geração de valor. Para tanto, a infraestrutura é questão básica de suporte. Aqui, ela deve ser entendida de modo amplo, que vai desde a construção e manutenção de portos, aeroportos, ferrovias, terminais para escoamento da produção, até parques de inovação, passando por atendimento de redes de telecomunicações. O desenvolvimento tecnológico e a diversificação industrial têm papel central neste objetivo, uma vez que possibilita o ganho de eficiência na produção.',
    img: ods9Img,
    color: '#FD6925',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=9'
  },
  10: {
    id: 10,
    name: 'ODS 10: Redução das desigualdades',
    mainGoals:
      'Reduzir as desigualdades no interior dos países e entre países. ',
    description:
      'A redução da desigualdade entre e dentro dos países é o tema deste ODS. Para tanto, é necessário assegurar renda às populações mais pobres, promover a inclusão social e política e adotar políticas de proteção salarial. Medidas institucionais, como a eliminação de leis discriminatórias e a promoção de leis adequadas fazem parte do caminho para a redução das desigualdades. Por fim, fiscalizar e regular os mercados financeiros, de forma a não concentrarem renda, e dar tratamento justo a migrantes também estão entre as metas deste ODS.',
    img: ods10Img,
    color: '#DD1367',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=10'
  },
  11: {
    id: 11,
    name: 'ODS 11: Cidades e comunidades sustentáveis',
    mainGoals:
      'Tornar as cidades e comunidades mais inclusivas, seguras, resilientes e sustentáveis.',
    description:
      'A mobilidade urbana é fundamental para que o indivíduo exerça sua cidadania, a melhoria da oferta de serviços de transporte, com atendimento para todos os tipos de grupos, incluindo os em vulnerabilidade, mulheres, pessoas com deficiência e idosos. A preservação da cultura, o fortalecimento dos espaços de participação, a redução do número de mortes, de pessoas afetadas por catástrofes e de prejuízos e a redução dos impactos ambientais pela vida e produção econômica nas cidades complementam as metas deste ODS.',
    img: ods11Img,
    color: '#BD700E',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=11'
  },
  16: {
    id: 16,
    name: 'ODS 16: Paz, Justiça e Instituições Eficazes',
    mainGoals:
      'Promover sociedades pacíficas e inclusivas para o desenvolvimento sustentável, proporcionar o acesso à justiça para todos e construir instituições eficazes, responsáveis e inclusivas a todos os níveis. ',
    description:
      'Neste objetivo, entram metas associadas à redução de mortes por violência, tráfico e tortura contra crianças e à promoção de um Estado de Direito em que todos tenham direito a se defender. Crimes internacionais, como o tráfico de armas também são abordados. A participação social nas instituições de poder, amparada por acesso público à informação, traz a dimensão da representação e da governança neste ODS. ser integrados aos processos de desenvolvimento e de redução da pobreza.',
    img: ods16Img,
    color: '#00689D',
    path: 'https://odsbrasil.gov.br/objetivo/objetivo?n=16'
  }
};
