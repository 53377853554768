import { Text } from '@/components/styled';
import React, { ReactNode, useMemo, useState } from 'react';
import { Box, Container } from './styles';

import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import { Data } from 'big-data';
import { dotsNumber, percentageFormatter } from '@/utils/string';

interface Column {
  name: string;
  key: string;
}

interface DataGoalVisitationProps {
  data: Data[];
  title?: ReactNode;
  percentage?: boolean;
  columnTitle?: Column[];
  pageSize?: number;
  label?: string;
}

export function PaginatedTable({
  data,
  title,
  percentage = false,
  columnTitle,
  pageSize = 5,
  label
}: DataGoalVisitationProps) {
  const [page, setPage] = useState(1);

  const dataFormatted = useMemo(() => {
    return data.map((d) => {
      return { ...d, name: d.name };
    });
  }, [data]);

  const dataInPercentage = useMemo(() => {
    return dataFormatted
      .sort((a, b) => b.value - a.value)
      .slice((page - 1) * pageSize, page * pageSize);
  }, [page, dataFormatted, pageSize]);

  const originalDataLength = data.length;
  const length = dataInPercentage.length;

  function handlePaginateNext() {
    setPage((page) => page + 1);
  }

  function handlePaginatePrev() {
    setPage((page) => page - 1);
  }

  return (
    <Container>
      {title}

      {columnTitle && (
        <Box key="title">
          <div className="box-title">
            {columnTitle?.map((d) => (
              <React.Fragment key={d.key}>
                <Text>{d.name}</Text>
              </React.Fragment>
            ))}
          </div>
        </Box>
      )}

      {dataInPercentage.map((data, i) => (
        <Box key={data.name}>
          <div className="box-city">
            <Text>
              {pageSize * (page - 1) + 1 + i}° {data.name}
            </Text>
            <div className="percent">
              <Text>
                {percentage
                  ? percentageFormatter(data.value)
                  : dotsNumber(data.value)}
              </Text>
            </div>
          </div>
        </Box>
      ))}

      <div className="pagination">
        {length <= 1 ? (
          <p className="show-count">
            Mostrando <strong>1</strong> resultado.
          </p>
        ) : (
          <p className="show-count">
            Mostrando de{' '}
            <strong>
              {page * pageSize - (pageSize - 1)} a{' '}
              {page * pageSize - (pageSize - 1) + length - 1}
            </strong>{' '}
            de <strong>{originalDataLength}</strong> {label ?? 'município'}
          </p>
        )}
        <div className="button-group">
          <button
            disabled={page === 1}
            className={page === 1 ? 'disabled' : ''}
            onClick={handlePaginatePrev}
            aria-label="Anterior"
          >
            <BsArrowLeft />
          </button>
          <button
            disabled={page * pageSize >= originalDataLength}
            className={page * pageSize >= originalDataLength ? 'disabled' : ''}
            onClick={handlePaginateNext}
            aria-label="Pŕoximo"
          >
            <BsArrowRight />
          </button>
        </div>
      </div>
    </Container>
  );
}
