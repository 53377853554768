import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css, keyframes } from 'styled-components';

interface BoxProps {
  $collapsed: boolean;
}

const showUp = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Box = styled.div<BoxProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  .collapse-menu-button {
    border: 0;
    background: transparent;
    width: 100%;

    svg {
      transition: transform 0.7s;
    }

    ${({ $collapsed }) =>
      !$collapsed &&
      css`
        svg {
          transform: rotate(180deg);
        }
      `};
  }

  .logout-box {
    background: ${({ theme }) =>
      theme.darkMode ? theme.background : colors.green800};
    padding: 10px;

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .logout {
      background: transparent;
      border: none;
      margin-top: 1.2rem;
      display: flex;
      align-items: center;

      .logout-text {
        font-size: 1.2rem;
        color: #fff;
        font-weight: 500;
        margin-left: 10px;
      }
    }

    .user-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: ${colors.green200};
      color: ${colors.white};
      border-radius: 50%;

      font-weight: 700;
      font-size: 1.2rem;

      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .username-expanded {
        font-size: 1rem;
        color: #fff;
      }
    }

    ${({ $collapsed }) =>
      !$collapsed &&
      css`
        .user-infos {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 15px;
          animation: ${showUp} 1s linear;

          .username-expanded {
            font-size: 1rem;
            font-weight: 500;

            color: #fff;
            text-transform: capitalize;
          }
        }
      `}
  }

  ${mediaQuery.smallScreen} {
    width: max-content;

    .collapse-menu-button {
      display: none;
    }

    .user-logout-box {
      width: 100%;
      height: 100%;

      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;

      ${({ $collapsed }) =>
        $collapsed &&
        css`
          background: inherit;
        `};

      .logout {
        margin: 0;
      }
    }
  }
`;
