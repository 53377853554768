import { Input } from 'antd';
import { ChangeEvent, FormEvent, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { AiOutlinePaperClip, AiOutlineDelete } from 'react-icons/ai';
import { isEmpty } from 'ramda';

import { Box, FileButton, FileRemove, Button } from './styles';

export interface CommentInputFormData {
  file: File | null;
  note: string;
}

interface CommentInputProps {
  loading?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmit?: (formData: CommentInputFormData) => void;
}

const { TextArea } = Input;

export function CommentInput({
  loading,
  onFocus,
  onBlur,
  onSubmit
}: CommentInputProps) {
  const [comment, setComment] = useState('');
  const [file, setFile] = useState<File | null>(null);

  function handleFile(e: ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (!files) return;

    setFile(files.item(0));
  }

  function handleRemoveFile() {
    if (file !== null) {
      setFile(null);
    }
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (onSubmit) {
      onSubmit({ file, note: comment });
    }

    setFile(null);
    setComment('');
  }

  function renderFileInput() {
    return (
      <div className="button-file">
        <FileButton htmlFor="button-file" disabled={loading}>
          {<AiOutlinePaperClip />}
          Anexar arquivo
        </FileButton>
        <input
          id="button-file"
          type="file"
          accept="application/pdf"
          onChange={handleFile}
          disabled={loading}
        />
      </div>
    );
  }

  function renderFileRemove() {
    return (
      <FileRemove onClick={handleRemoveFile}>
        <AiOutlineDelete />
        Remover arquivo {file?.name}
      </FileRemove>
    );
  }

  return (
    <Box onSubmit={handleSubmit}>
      <TextArea
        id="comment"
        value={comment}
        placeholder="Digite um comentário..."
        onChange={(e) => setComment(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        autoSize={{
          minRows: 4,
          maxRows: 4
        }}
        disabled={loading}
      />

      <div className="buttons">
        {file ? renderFileRemove() : renderFileInput()}

        <Button
          htmlType="submit"
          loading={loading}
          icon={<FiSend />}
          disabled={isEmpty(comment)}
        >
          <span>Enviar</span>
        </Button>
      </div>
    </Box>
  );
}
