import { useMemo, useState } from 'react';
import {
  Box,
  TableModalContent,
  TableModalPagination,
  TableModalTitle
} from './styles';
import { Select } from '@/components/shared/SelectBox';
import {
  useActionAnalytics,
  useDataPlanActionAnalytics
} from '@/servicesHooks/actions';
import {
  AntModal,
  ServiceBox,
  Pagination as SharedPagination,
  D3Map
} from '@/components/shared';

import {
  CardHeader,
  CardContent,
  CardFooter,
  Table,
  Pagination
} from '../../../components';
import { useFilterContext, useTabContext } from '../../../contexts';

import { generateTag, statusPlanActionsOptions } from '../../../utils';
import { usePagination } from '../../../hooks/usePagination';
import { sumDataTotal } from '@/utils/data';
import { useTheme } from 'styled-components';
import { colors } from '@/theme/colors';

export function CardPlanActionsByCity() {
  const { darkMode } = useTheme();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<string>();
  const [modalProps, setModalProps] = useState({
    open: false,
    ibge: '',
    page: 0
  });

  const { city } = useFilterContext();
  const { tab } = useTabContext();

  const { data, error, loading } = useActionAnalytics(
    tab?.value,
    city?.ibge,
    status
  );

  const dataPercentage = useMemo(() => {
    const total = sumDataTotal(data);
    return data.map((d) => ({
      name: d.ibge,
      value: (d.value / total) * 100
    }));
  }, [data]);

  const { dataPaginated, pageSize, totalPages } = usePagination(data, page);

  const {
    data: dataPlanActionAnalytics,
    loading: loadingPlanActionAnalytics,
    error: errorPlanActionAnalytics,
    totalPages: totalPagesPlanActionAnalytics
  } = useDataPlanActionAnalytics(modalProps.ibge, modalProps.page, status);

  const title = `Plano de ações ${
    !!city?.name ? `no municipio de ${city.name}` : 'no estado do Ceará'
  }`;

  return (
    <Box>
      <CardHeader
        title={title}
        subtitle={generateTag([city?.name, tab?.name])}
      />

      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent gap="3rem" className="map-table">
          <D3Map
            data={dataPercentage}
            color={darkMode ? colors.ming100 : colors.ciano600}
            width={190}
            spaceBetweenMapAndLegend="2rem"
            percentage
          />
          <Table
            data={dataPaginated.map((d) => ({
              city: d.name,
              numberOfPlanActions: `${d.value} planos de ações`,
              ibge: d.ibge
            }))}
            headers={['Município', 'Número de planos de ações']}
            countStart={(page - 1) * pageSize}
            excludesOnRender={['ibge']}
            clickableCell={{
              onClick({ ibge }) {
                setModalProps({
                  ...modalProps,
                  open: true,
                  ibge
                });
              }
            }}
          />
        </CardContent>
      </ServiceBox>

      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            placeholder="Selecione..."
            value={status}
            onChange={(value) => setStatus(String(value))}
            options={statusPlanActionsOptions}
          />
          <Pagination
            onPaginate={(page) => setPage(page)}
            page={page}
            totalPages={totalPages}
            initialPage={1}
          />
        </div>
      </CardFooter>

      <AntModal
        visible={modalProps.open}
        onCancel={() => setModalProps({ ...modalProps, open: false })}
        width={1000}
      >
        <TableModalTitle>Planos de ação</TableModalTitle>
        <TableModalContent>
          <ServiceBox
            empty={dataPlanActionAnalytics.length === 0}
            error={errorPlanActionAnalytics}
            loading={loadingPlanActionAnalytics}
          >
            <Table
              countStart={0}
              headers={['Título', 'Eixo', 'Status']}
              excludesOnRender={['indicator', 'ibge', 'city', 'id']}
              data={dataPlanActionAnalytics}
              clickableCell={{
                anchor: {
                  redirectFn({ id }) {
                    return `/dashboard/gestores-acoes/planos-de-acao/${id}`;
                  }
                }
              }}
            />
          </ServiceBox>
        </TableModalContent>
        <TableModalPagination>
          <SharedPagination
            onPaginate={(page) => setModalProps({ ...modalProps, page })}
            page={modalProps.page}
            totalPages={totalPagesPlanActionAnalytics - 1}
            resetOnChange={city?.ibge || tab?.value}
          />
        </TableModalPagination>
      </AntModal>
    </Box>
  );
}
