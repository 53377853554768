import { googleAnalytics } from '@/services/googleAnaltyics';
import { CityData } from 'big-data';
import { useEffect, useState } from 'react';

type By = 'name' | 'date';

const { home: googleAnalyticsHome } = googleAnalytics;

export function useCityAccess(
  minimumDate?: string,
  ibge?: string,
  order?: string,
  by?: By
) {
  const groupByKey = by ?? 'name';

  const [data, setData] = useState<CityData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    googleAnalyticsHome
      .getCityAccess(minimumDate, ibge, order)
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [minimumDate, ibge, order, groupByKey]);

  return {
    data,
    loading,
    error
  };
}
