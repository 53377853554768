import { max, min, interpolate } from '@/utils/math';
import { DataTree } from 'big-data';
import * as d3 from 'd3';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useChart } from '../Provider';
import { getArcFunction2, isSameNode } from '../utils';

export type Root = d3.HierarchyRectangularNode<DataTree> & {
  current?: number[];
  before?: number[];
  after?: number[];
};

interface ArcsProps {
  data: DataTree;
  selected?: boolean;
  sizeByValue?: boolean;
  onClick?: (data?: DataTree) => void;
  colorHighlight?: string;
  isOpen: (props: boolean) => void;
}

function getColor(darkMode: boolean) {
  if (darkMode) return 'transparent';
  return '#ffffff';
}

function getNodeValue(value: number, nodeValue: number, fallbackValue: number) {
  if (value === 0) return nodeValue;
  return fallbackValue;
}

function getPercentageResolver(
  percentageValue: number,
  value: number,
  isPercentage?: boolean
) {
  if (isPercentage) return percentageValue;
  return value;
}

function getValidValue(value: number, testValue?: number) {
  return testValue ?? value;
}

export function Arcs({
  data,
  selected,
  sizeByValue,
  onClick,
  colorHighlight,
  isOpen
}: ArcsProps) {
  const { darkMode } = useTheme();
  const { chart, paths, setPaths, setArcs } = useChart();

  const [clickedRoot, setClickedRoot] = useState<Root>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!chart) return;

    const width = Number(chart.attr('width'));
    const height = Number(chart.attr('height'));
    const radius = Math.max(width, height) / 2;

    const arcGroup = chart
      .attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
      .append('g')
      .attr('class', 'arc-group');

    const rootFn = d3.partition<DataTree>();

    const root: Root = rootFn(
      d3
        .hierarchy(data)
        .sum((d) => {
          const { value, percentageValue, renderArcByPercentage } = d;

          return getPercentageResolver(
            percentageValue,
            value,
            renderArcByPercentage
          );
        })
        .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
        .sum((d) => (d.renderArcByPercentage ? d.percentageValue : d.value))
        .sort((a, b) => getValidValue(0, b.value) - getValidValue(0, a.value))
    );

    root.each((d) => {
      d.current = [d.x0, d.x1];
      d.before = [d.x0, d.x1];
    });

    const dFn = getArcFunction2(radius);
    const descendants = root.descendants();
    const pathname = '/dashboard/eixos/';

    if (sizeByValue) {
      const values = descendants
        .filter((descendant) => descendant.parent !== null)
        .map((descendant) => {
          const { value, percentageValue, renderArcByPercentage } =
            descendant.data;

          return getPercentageResolver(
            percentageValue,
            value,
            renderArcByPercentage
          );
        });

      const minData = min(values);
      const maxData = max(values);

      const getArcSize = interpolate([minData, maxData], [0.8, 1]);

      root.each((node) => {
        const { value, percentageValue, renderArcByPercentage } = node.data;
        const arcValue = renderArcByPercentage ? percentageValue : value;
        const arcSize = getArcSize(arcValue);

        node.y0 = getNodeValue(value, node.y0, 0.45);
        node.y1 = getNodeValue(value, node.y1, arcSize);
      });
    }

    const arcs = arcGroup
      .selectAll('.arc-group')
      .data(descendants)
      .join('g')
      .attr('class', 'arc')
      .on('click', (e, node) => {
        if (node.data.disabled) {
          isOpen(true);
        } else if (node.parent !== null && !node.data.disabled) {
          const doublePI = 2 * Math.PI;

          root.each((d) => {
            const first =
              Math.max(0, Math.min(1, (d.x0 - node.x0) / (node.x1 - node.x0))) *
              doublePI;
            const second =
              Math.max(0, Math.min(1, (d.x1 - node.x0) / (node.x1 - node.x0))) *
              doublePI;

            d.after = [first, second];
            return d;
          });

          setClickedRoot((root) => {
            const sameNode = isSameNode(root, node);
            if (sameNode) return undefined;
            return node;
          });
        } else if (location.pathname !== pathname) navigate(pathname);
        else return null;
      });

    const paths = arcs
      .data(descendants)
      .append('path')
      .attr('class', '.arc')
      .attr('id', (_, i) => `path-${i}`)
      .attr('fill', (d, i) => {
        const mainColor = getColor(darkMode);

        return i === 0 ? mainColor : d.data.color;
      })
      .attr('d', dFn)
      .attr('opacity', (d) => {
        if (!colorHighlight) return 1;
        if (d.data.color === colorHighlight) {
          return 1;
        } else {
          return 0.3;
        }
      })
      .attr('style', 'cursor: pointer');

    setArcs(arcs);

    setPaths(paths);

    return () => {
      arcGroup.remove();
    };
  }, [
    chart,
    setArcs,
    data,
    sizeByValue,
    setPaths,
    darkMode,
    colorHighlight,
    navigate,
    location,
    isOpen
  ]);

  useEffect(() => {
    if (!chart || !paths) return;

    const width = Number(chart.attr('width'));
    const height = Number(chart.attr('height'));
    const radius = Math.max(width, height) / 2;
    const dFn = getArcFunction2(radius);

    paths
      .transition()
      .duration(700)
      .tween('data', (d: Root) => {
        const xd =
          selected === false
            ? d3.interpolate(d.after, d.before as number[])
            : d3.interpolate(d.before, d.after as number[]);

        return (t: number) => {
          const points = xd(t);

          if (!points) return;

          d.current = points;
          d.x0 = points[0];
          d.x1 = points[1];
        };
      })
      .attrTween('d', (d: any, i) => () => dFn(d) as string);
  }, [selected, paths, chart]);

  useEffect(() => {
    if (onClick) {
      onClick(clickedRoot?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedRoot]);

  return null;
}
