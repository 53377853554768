import { RankingModel } from 'big-data';
import { useMemo } from 'react';

import { CardTagged, RankingDashboard, ServiceBox } from '@/components/shared';
import { Text } from '@/components/styled';

import { useDashboardFilter } from '@/hooks';
import { splitNames, getDashboardRanking } from '@/utils/data';

import { MultipleIcon } from '@/components/shared/RankingDashboard/Icons/Multiple.icon';
import { NoProgramIcon } from '@/components/shared/RankingDashboard/Icons/NoProgramIcon.icon';
import { AuditoryImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/AuditoryImpairment.icon';
import { FisicalImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/FisicalImpairment.icon';
import { MentalImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/MentalImpairment.icon';
import { VisualImpairmentIcon } from '@/components/shared/RankingDashboard/Icons/VisualImpairment.icon';
import { useWomanImpairmentData } from '@/servicesHooks/profileSearch';

const rankingModel = new Map<string, RankingModel>([
  [
    'visual',
    {
      description: () => '',
      img: <VisualImpairmentIcon />,
      name: 'possuem deficiência visual',
      key: 'deficiencia_visual'
    }
  ],
  [
    'auditiva',
    {
      description: () => '',
      img: <AuditoryImpairmentIcon />,
      name: 'possuem deficiência auditiva',
      key: 'deficiencia_auditiva'
    }
  ],
  [
    'física',
    {
      description: () => '',
      img: <FisicalImpairmentIcon />,
      name: 'possuem deficiência física',
      key: 'deficiencia_fisica'
    }
  ],
  [
    'mental ou intelectual',
    {
      description: () => '',
      img: <MentalImpairmentIcon />,
      name: 'possuem deficiência mental',
      key: 'deficiencia_mental'
    }
  ],
  [
    'múltiplas',
    {
      description: () => '',
      img: <MultipleIcon />,
      name: 'possuem deficiência múltipla',
      key: 'deficiencia_multipla'
    }
  ],
  [
    'não possui nenhuma deficiência',
    {
      description: () => '',
      img: <NoProgramIcon />,
      name: 'não possuem deficiência',
      key: 'nenhuma_deficiencia'
    }
  ]
]);

export function CardWomanImpairmentRanking() {
  const { tag } = useDashboardFilter();

  const { womanImpairment, womanImpairmentError, womanImpairmentLoading } =
    useWomanImpairmentData();

  const splitedNames = splitNames(womanImpairment);

  const womanImpairmentData = useMemo(() => {
    return getDashboardRanking(splitedNames, rankingModel).map((data) => {
      const formatName = data.name.includes('nenhuma')
        ? data.name.replace('nenhuma', 'não possuem')
        : data.name;

      return {
        ...data,
        name: formatName
      };
    });
  }, [splitedNames]);

  return (
    <CardTagged tag={tag} title="Mulheres com deficiência por domicílio">
      <ServiceBox
        empty={womanImpairment.length === 0}
        error={womanImpairmentError}
        loading={womanImpairmentLoading}
      >
        <Text>
          Entre as mães, cuidadoras das crianças ou mulheres com deficiência nos
          domicílios das famílias entrevistadas:
        </Text>

        <RankingDashboard
          data={womanImpairmentData}
          loadingBenefits={false}
          selected={undefined}
        />
      </ServiceBox>
    </CardTagged>
  );
}
