import { CestaBasica } from '@/components/shared/RankingDashboard/Icons/CestaBasica.icon';
import { TarifaAgua } from '@/components/shared/RankingDashboard/Icons/TarifaAgua.icon';
import { TarifaEnergia } from '@/components/shared/RankingDashboard/Icons/TarifaEnergia.icon';
import { ValeGas } from '@/components/shared/RankingDashboard/Icons/ValeGas.icon';
import { VirandoOJogo } from '@/components/shared/RankingDashboard/Icons/VirandoOJogo.icon';
import { Data, RankingModel, RankingBind } from 'big-data';

export const rankingModel = new Map<string, RankingModel>([
  [
    'ticketGas',
    {
      name: 'Vale Gás',
      key: 'ticketGas',
      description: () => 'recebem o ',
      img: <ValeGas />
    }
  ],
  [
    'energyTax',
    {
      name: 'Isenção de tarifa de energia',
      key: 'energyTax',
      description: () => 'recebem a ',
      img: <TarifaEnergia />
    }
  ],
  [
    'waterTax',
    {
      name: ' Isenção da tarifa de água ',
      key: 'waterTax',
      description: () => 'recebem a ',
      img: <TarifaAgua />
    }
  ],
  [
    'foodPackage',
    {
      name: 'Cesta básica',
      key: 'foodPackage',
      description: () => 'recebem a ',
      img: <CestaBasica />
    }
  ],
  [
    'changingGame',
    {
      name: 'Virando o jogo',
      key: 'changingGame',
      description: () => 'recebem o ',
      img: <VirandoOJogo />
    }
  ]
]);

export function getInsecurityFoodRanking(benefits: Data[]): RankingBind[] {
  return benefits.map((benefit) => {
    const currentModel = rankingModel.get(benefit.name) as RankingModel;

    return {
      ...benefit,
      ...currentModel
    };
  });
}
