import styled from 'styled-components';

export const Box = styled.div`
  margin-bottom: 1.5rem;

  .label {
    margin-bottom: 0.2rem;
    display: block;

    color: #979797;
    font-weight: 700;
  }

  .axe {
    display: flex;

    .field {
      width: 250px;
    }

    .add,
    .remove {
      width: 32px;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      margin-left: 0.5rem;
      background-color: transparent;

      transition: border-color 0.2s;
    }

    .add {
      & line {
        transition: stroke 0.2s;
      }

      &:hover {
        border-color: #6bab57;
      }

      &:hover line {
        stroke: #6bab57;
      }
    }

    .remove {
      & path,
      & polyline {
        transition: stroke 0.2s;
      }

      &:hover {
        border-color: #ab1e11;
      }

      &:hover path,
      &:hover polyline {
        stroke: #ab1e11;
      }
    }
  }

  .indicator {
    margin-top: 0.2rem;

    .field {
      width: 250px;
    }
  }
`;
