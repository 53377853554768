import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: 300px;
  button {
    border: none;
    span {
      background: #636e72;
      color: white;
      padding: 0.6rem 1.6rem;
      border-radius: 7px;
      font-weight: bold;
    }
  }
`;
