import { DataTree } from 'big-data';
import { useMemo, useState } from 'react';

import {
  CzRMTableModal,
  FilterDashboard,
  SunBurstReact,
  SunBurstText
} from '@/components/shared';
import {
  AxeDescriptionText,
  ThermometerWrapper,
  ThirdTitle
} from '@/components/styled';
import { useDashboardTitle } from '@/hooks';
import { useEducation } from '@/servicesHooks/education';
import { colors } from '@/theme/colors';
import { dotsNumber, capitalize } from '@/utils/string';

import { modifierColors } from '../utils';
import { EducationInsights, EducationGoals } from '../components';

import { descriptions } from './description';
import { Box } from './styles';
import { getTree, getLegendTree } from './tree';

const { format } = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});
const DEFAULT_DESCRIPTION = `Os investimentos públicos em educação são de extrema importância para a redução da pobreza, criminalidade e ampliação do crescimento econômico, bem-estar e acesso aos direitos fundamentais pela população. O Plano Estadual de Educação do Ceará (PEE), possui metas fixadas para o período de 2016 a 2022.`;

export function Education() {
  useDashboardTitle('Termômetro de Dados – Eixo Educação');
  const [selected, setSelected] = useState<DataTree>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, loading } = useEducation();

  const totalThermometerCenter = data
    .filter((data) => data.schoolMode === 'não')
    .reduce((acc, cur) => acc + cur.value, 0);

  function handleSunburstClick(data?: DataTree) {
    if (!data || data.name === 'Educação') {
      setSelected(undefined);
    } else {
      setSelected(data);
    }
  }

  function handleOpenCzRMModal() {
    if (!selected) return;
    setIsModalOpen(true);
  }

  const frequenciesTree = useMemo(() => {
    return getTree(data);
  }, [data]);

  const legend = useMemo(() => getLegendTree(selected), [selected]);

  const sunburstValue = selected
    ? format(selected.percentageValue)
    : dotsNumber(totalThermometerCenter);

  const sunburstLabel = selected?.name
    ? 'CRIANÇAS FORA DA ' + selected?.name.toUpperCase()
    : `CRIANÇAS SEM ACESSO A EDUCAÇÃO`;

  const sunburstBtnLabel = selected
    ? null
    : 'CLIQUE EM CADA EIXO PARA DETALHAR';

  const name = selected
    ? selected.name.toLowerCase().replace('não frequenta ', '')
    : 'Educação';

  const description = selected ? descriptions[name] : DEFAULT_DESCRIPTION;
  const color = selected
    ? (modifierColors.get(selected.color) as string)
    : colors.ciano700;

  return (
    <Box>
      <AxeDescriptionText>{description}</AxeDescriptionText>
      <FilterDashboard hidden={!!selected} />
      <ThermometerWrapper>
        <div className="left-cards">
          <EducationGoals color={color} />
        </div>
        <SunBurstReact
          data={frequenciesTree}
          dataLegend={legend}
          loading={loading}
          source="Metas do plano estadual de educação (PEE)"
          selected={selected !== undefined}
          onClick={handleSunburstClick}
          sizeByValue
        >
          <SunBurstText
            value={sunburstValue}
            label={sunburstLabel}
            color={color}
            isButtonCzrm={!!selected}
            handleOpenFamilyTable={handleOpenCzRMModal}
            buttonLabel={sunburstBtnLabel}
          />
        </SunBurstReact>
      </ThermometerWrapper>

      <div className="profile">
        <ThirdTitle>
          Pesquisa dos Agentes Sociais sobre {capitalize(name)}
        </ThirdTitle>

        <EducationInsights selected={selected} />
      </div>

      <CzRMTableModal
        axe="education"
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        title={`Famílias com crianças fora de ${selected?.name || 'Educação'}`}
        selectedSubAxe={selected?.name}
      />
    </Box>
  );
}
