import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  position: relative;

  .tabs {
    list-style: none;
    display: flex;
    border-bottom: 1px solid #dfe6e9;
    padding: 0 1rem;
    margin-bottom: 0;

    @media (max-width: 1200px) {
      flex-direction: column;
      padding-left: 0;
      margin-top: 8px;
    }

    li {
      padding: 0.6rem;
      width: 200px;
      height: 42px;
      text-align: center;
      color: ${(props) => (props.theme.darkMode ? '#fff' : '#636E72')};
      font-size: 1rem;

      cursor: pointer;
      user-select: none;
      text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};

      &[data-state='inactive'] {
        color: ${({ theme }) => (theme.darkMode ? theme.yellowDark : '')};
      }

      @media (max-width: 1200px) {
        width: 100%;
        text-align: left;
        padding-left: 1rem;
      }

      @media (max-width: 1024px) {
        width: 168px;
      }
    }
  }
`;

interface BarProps {
  left: number;
}

export const Bar = styled.div<BarProps>`
  height: 3px;
  width: 200px;
  background-color: ${({ theme }) =>
    theme.darkMode ? colors.white : colors.ciano700};

  position: relative;
  top: -3px;
  left: 16px;

  transition: transform 0.3s;
  transform: ${({ left }) => `translateX(${left}%)`};

  @media (max-width: 1200px) {
    width: 3px;
    height: 42px;

    top: -211px;
    left: 0;

    transform: ${({ left }) => `translateY(${left}%)`};
  }
`;
