import { RankingModel } from 'big-data';

import { CardTagged, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useOutSchoolReason } from '@/servicesHooks/education';
import { getDashboardRanking } from '@/utils/data';

import { RankingDashboard } from '@/components/shared/RankingDashboard';
import { HandIcon } from '@/components/shared/RankingDashboard/Icons/Hand.icon';
import { SearchIcon } from '@/components/shared/RankingDashboard/Icons/Search.icon';
import { GunIcon } from '@/components/shared/RankingDashboard/Icons/GunIcon.icon';
import { BusIcon } from '@/components/shared/RankingDashboard/Icons/Bus.icon';
import { QuestionIcon } from '@/components/shared/RankingDashboard/Icons/Question.icon';
import { NoProgramIcon } from '@/components/shared/RankingDashboard/Icons/NoProgramIcon.icon';

import { Axe } from '../EducationInsights/type';

const rankingModel = new Map<string, RankingModel>([
  [
    'os pais ou responsáveis preferem que a criança não frequente',
    {
      description: () => '',
      img: <HandIcon />,
      name: 'preferem que a criança não vá à escola',
      key: 'no_preference'
    }
  ],
  [
    'não existe creche perto da casa',
    {
      description: () => '',
      img: <NoProgramIcon />,
      name: 'não têm creche perto de casa.',
      key: 'no_creche',
      color: '#F53C3E'
    }
  ],
  [
    'falta vaga na creche',
    {
      description: () => '',
      img: <SearchIcon />,
      name: 'não conseguiram vaga nas creches',
      key: 'no_vaga'
    }
  ],
  [
    'medo da violência',
    {
      description: () => '',
      img: <GunIcon />,
      name: 'têm medo da violência.',
      key: 'no_violencia'
    }
  ],
  [
    'não tem transporte',
    {
      description: () => '',
      img: <BusIcon />,
      name: 'não têm transporte para chegar à escola pública',
      key: 'no_transporte'
    }
  ],
  [
    'outro motivo',
    {
      description: () => '',
      img: <QuestionIcon />,
      name: 'deram outro motivo.',
      key: 'outro_motivo'
    }
  ]
]);

export function OutSchoolReason({ color }: Axe) {
  const { tag } = useDashboardFilter();

  const { outSchoolReason, outSchoolReasonLoading, outSchoolReasonError } =
    useOutSchoolReason();
  const data = getDashboardRanking(outSchoolReason, rankingModel);

  return (
    <CardTagged
      tag={tag}
      color={color}
      title="Causas das crianças não estarem matriculadas
    "
    >
      <ServiceBox
        empty={outSchoolReason.length === 0}
        error={outSchoolReasonError}
        loading={outSchoolReasonLoading}
      >
        <TitleText>
          Quais os motivos das crianças estarem fora da escola pública dados pelas
          famílias?
        </TitleText>

        <RankingDashboard
          color={color}
          data={data}
          loadingBenefits={false}
          selected={undefined}
        />
      </ServiceBox>
    </CardTagged>
  );
}
