import { animated, useTransition, to } from 'react-spring';
import { Pie as VisxPie } from '@visx/shape';
import { Group } from '@visx/group';
import { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import * as R from 'ramda';
import { useMemo } from 'react';

import { Svg } from './styles';

export interface DonutData {
  name: string;
  value: number;
  color: string;
}

interface DonutProps {
  data: DonutData[];
  selectedData?: DonutData;
  width: number;
  height?: number;
  animate?: boolean;
  onClick?: (data: DonutData) => void;
}

const calculateRadius = R.pipe<[R.Ord, R.Ord], number, number>(
  R.min,
  R.divide(R.__, 2)
);

export function Donut({
  data,
  selectedData,
  width,
  height = width,
  animate = true,
  onClick
}: DonutProps) {
  const chartData = useMemo(
    () => (R.isNil(selectedData) ? data : [selectedData]),
    [data, selectedData]
  );

  const radius = calculateRadius(width, height);

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
    >
      <VisxPie
        data={chartData}
        pieValue={(d) => d.value}
        outerRadius={radius - 10}
        innerRadius={radius - 40}
        cornerRadius={3}
        padAngle={0.03}
        top={height}
        left={width}
      >
        {(pie) =>
          animate && (
            <Group className="pie">
              <AnimatedPie<DonutData>
                {...pie}
                animate
                getKey={(arc) => arc.data.name}
                onClickDatum={({ data }) => {
                  if (onClick) {
                    onClick(data);
                  }
                }}
                getColor={(arc) => arc.data.color}
              />
            </Group>
          )
        }
      </VisxPie>
    </Svg>
  );
}

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0
});

const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getColor,
  onClickDatum
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  return transitions((props, arc, { key }) => (
    <g key={key}>
      <animated.path
        // compute interpolated path d attribute from intermediate angle values
        d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
          path({
            ...arc,
            startAngle,
            endAngle
          })
        )}
        fill={getColor(arc)}
        onClick={() => onClickDatum(arc)}
        onTouchStart={() => onClickDatum(arc)}
      />
    </g>
  ));
}
