export function getQuery(query: (string | undefined)[]) {
  return query
    .map((q) => {
      return q?.includes('ZONA') ? 'ZONA' : q;
    })
    .filter(Boolean)
    .join(',');
}

export function getAggregation(aggregations: string[]) {
  return `aggregations=${aggregations
    .filter((value) => value !== '')
    .join(',')}`;
}

type RequestFunction<T> = (signal: AbortSignal, ...params: any[]) => Promise<T>;

export function getRequestObject<T>(request: RequestFunction<T>) {
  const abortController = new AbortController();

  return Object.freeze({
    abort: () => abortController.abort(),
    request: (...params: any[]) => request(abortController.signal, ...params)
  });
}
