import { useEffect, useMemo, useRef, useState } from 'react';

type Callback = (value: number) => void;

function handleResize(box: SVGElement | null, callback: Callback) {
  if (box) {
    const parent = box.parentElement;
    const width = parent?.clientWidth;

    width && callback(width);
  }
}

export function useBoxWidth<T extends SVGSVGElement>(ratio: number = 0.6) {
  const boxRef = useRef<T>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const { current } = boxRef;

    handleResize(current, (w) => setWidth(w - w * 0.05));
  }, []);

  useEffect(() => {
    const { current } = boxRef;

    const resizeFn = () => handleResize(current, (w) => setWidth(w - w * 0.05));

    window.addEventListener('resize', resizeFn);

    return () => {
      window.removeEventListener('resize', resizeFn);
    };
  }, []);

  return useMemo(
    () => ({
      boxRef,
      width,
      height: width * ratio
    }),
    [width, ratio]
  );
}
