import { colors } from '@/theme/colors';

const colorNameMap = new Map([
  [
    'Sim',
    {
      color: '#55A546',
      name: 'Sim, está trabalhando'
    }
  ],
  [
    'Não',
    {
      color: '#D4282A',
      name: 'Não está trabalhando'
    }
  ],
  [
    'Desisti de procurar emprego',
    {
      color: '#FFB02E',
      name: 'Desistiu de procurar emprego'
    }
  ]
]);

export function resolveColorName(name: string) {
  const fallbackObject = {
    color: colors.gray500,
    name: 'Desistiu de procurar emprego'
  };
  return colorNameMap.get(name) || fallbackObject;
}
