import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { CitizenAccessUserParams } from './types';

const service = () => {
  const resource = `/dados-acesso-usuario/pagina-rank`;

  async function get(params?: CitizenAccessUserParams) {
    const path = resource;
    const options = ensureAxiosParamOptions({ params });

    const response = await callService(() =>
      mainApi.get<{ page: string; quantidade: number }[]>(path, options)
    );

    return response.data.map((data) => ({
      name: data.page,
      value: data.quantidade
    }));
  }

  return {
    get
  };
};

export const citizenAccessUserPageRank = service();
