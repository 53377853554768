import * as d3 from 'd3';

export function getTooltip() {
  let tooltip: any = undefined;

  const showTooltip = (value: string) => {
    tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .text(value)
      .style('font-size', '12px')
      .style('font-family', 'IBM Plex Sans')
      .style('font-weight', 'bold')
      .style('line-height', '16px')
      .style('text-transform', 'uppercase')

      .style('z-index', 100)
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border-radius', '3px')
      .style('padding', '7px');
  };

  const setPosition = (x: number, y: number) => {
    if (!tooltip) return;

    tooltip.style('left', `${x}px`).style('top', `${y}px`);
  };

  const hiddenTooltip = () => {
    if (tooltip) {
      tooltip.remove();
    }
  };

  return {
    showTooltip,
    hiddenTooltip,
    setPosition
  };
}
