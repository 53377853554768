import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { getCleanedGeolocation } from './utils';

import { Geolocation } from './@types';

const service = () => {
  const resource = `/gestante-saude-geolocalizacao`;

  const prenatalCare = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            hadPrenatalCare: 'Não',
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return getCleanedGeolocation(response.data);
      }
    );

  return Object.freeze({ prenatalCare });
};

export const pregnantHealth = service();
