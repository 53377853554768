import { AntSelect } from '@/components/styled';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { schoolingAdult } from '@/services';
import { useEffect } from 'react';

const { Option } = AntSelect;

export function Filter() {
  const { data, loading, request } = useService<{ name: string }>();

  useEffect(() => {
    request(() => schoolingAdult.getSkinPeopleColor());
  }, [request]);

  const { colorSkinType, onChangeColorSkinType, gender, onChangeGender } =
    useAnalyticsFilter();
  return (
    <>
      <BdSelect
        placeholder="Filtrar dados por cor ou raça"
        onChange={onChangeColorSkinType}
        value={!!colorSkinType ? colorSkinType : undefined}
        loading={loading}
      >
        {data?.map((p) => (
          <Option key={p.name} value={p.name}>
            {p.name}
          </Option>
        ))}
      </BdSelect>

      <BdSelect
        placeholder="Filtrar dados por sexo"
        onChange={onChangeGender}
        value={!!gender ? gender : undefined}
      >
        <Option value="Feminino">Feminino</Option>
        <Option value="Masculino">Masculino</Option>
      </BdSelect>
    </>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
