import { useMemo, useState } from 'react';

import { Box } from './styles';
import { Select } from '@/components/shared/SelectBox';
import { useCityAccess } from '@/servicesHooks/googleAnalytics';
import { D3Map, ServiceBox } from '@/components/shared';

import {
  CardHeader,
  CardContent,
  CardFooter,
  Table,
  Pagination
} from '../../../components';
import { useTabContext, useFilterContext } from '../../../contexts';
import { generateTag, filterOrderOptions } from '../../../utils';
import { usePagination } from '../../../hooks/usePagination';
import { sumDataTotal } from '@/utils/data';
import { useTheme } from 'styled-components';
import { colors } from '@/theme/colors';

export function CardSessionsByCityMap() {
  const { darkMode } = useTheme();
  const [page, setPage] = useState(1);
  const [orderFilter, setOrderFilter] = useState<string>();

  const { tab } = useTabContext();
  const { city } = useFilterContext();

  const { data, error, loading } = useCityAccess(
    tab?.value,
    city?.ibge,
    orderFilter
  );

  const dataPercentage = useMemo(() => {
    const total = sumDataTotal(data);

    return data.map((d) => ({
      name: d.ibge,
      value: (d.value / total) * 100
    }));
  }, [data]);

  const { dataPaginated, pageSize, totalPages } = usePagination(data, page);

  const title = `Sessões ${
    !!city?.name ? `no municipio de ${city.name}` : 'no estado do Ceará'
  }`;

  return (
    <Box>
      <CardHeader
        title={title}
        subtitle={generateTag([city?.name, tab?.name])}
      />

      <ServiceBox empty={data.length === 0} error={error} loading={loading}>
        <CardContent gap="3rem" className="map-table">
          <D3Map
            data={dataPercentage}
            color={darkMode ? colors.ming100 : colors.ciano600}
            width={190}
            percentage
            spaceBetweenMapAndLegend="2rem"
          />
          <Table
            data={dataPaginated.map((d) => ({
              city: d.name,
              numberOfAccess: `${d.value} acessos`
            }))}
            headers={['Município', 'Número de acessos']}
            highlight={{
              by: 'city',
              value: city?.name ?? ''
            }}
            countStart={(page - 1) * pageSize}
          />
        </CardContent>
      </ServiceBox>

      <CardFooter>
        <span>Categoria de exibição:</span>
        <div className="search-pagination">
          <Select
            placeholder="Selecione..."
            value={orderFilter}
            onChange={(value) => setOrderFilter(String(value))}
            options={filterOrderOptions}
          />
          <Pagination
            onPaginate={(page) => setPage(page)}
            page={page}
            totalPages={totalPages}
            initialPage={1}
            resetOnChange={city?.ibge || tab?.value}
          />
        </div>
      </CardFooter>
    </Box>
  );
}
