import { DataPercentageComputed } from 'big-data';

import { Box, ImgColor } from './styles';

interface DataWithImage extends DataPercentageComputed {
  img: string;
  color?: string;
}

interface InlineImageRankingProps {
  data: DataWithImage[];
  color: string;
}

export function InlineImageRanking({ data, color }: InlineImageRankingProps) {
  return (
    <Box color={color}>
      {data.map((d, idx) => (
        <li className="ranking-item" key={d.name}>
          <ImgColor color={d.color ?? color}>
            <img className={`ranking-image-${idx}`} src={d.img} alt={d.name} />
          </ImgColor>

          <div className="ranking-text-box">
            <p>{d.name}</p>
            <p>{d.percentageValue.toFixed(2)}%</p>
          </div>
        </li>
      ))}
    </Box>
  );
}
