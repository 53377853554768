import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.header`
  display: flex;
  flex-direction: column;

  ${mediaQuery.smallScreen} {
    flex-direction: column;

    .header-breadcumb {
      align-self: flex-start;
      order: -1;
      margin-bottom: 20px;
    }

    .header-dashboard-title {
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .divider {
      display: none;
    }
  }
`;
