import { FilterAnalytic } from '@/components/shared';
import { GroupBlockBox, ThirdTitle } from '@/components/styled';
import { colors } from '@/theme/colors';

import { Box } from './styles';
import { HouseSection } from './HouseSection';
import { WaterSection } from './WaterSection';
import { Counter } from './Count/Counter';
import { useTheme } from 'styled-components';

const {
  Demography,
  Eletricity,
  ExternalSituation,
  FloorMaterial,
  HouseInfo,
  WallMaterial
} = HouseSection;

const { SanitaryInfo, WaterSupply, WaterOutFlow } = WaterSection;

export function LivingConditionInsights() {
  const { darkMode } = useTheme();
  const color = darkMode ? colors.ming : colors.ciano700;

  return (
    <Box>
      <FilterAnalytic />

      <Counter />

      <ThirdTitle>Cobertura de Água e Esgoto</ThirdTitle>
      <GroupBlockBox>
        <WaterSupply color={color} />
        <WaterOutFlow color={color} />
      </GroupBlockBox>
      <SanitaryInfo />
      <ThirdTitle>
        Características Urbanísticas e Estruturais dos Domicílios
      </ThirdTitle>
      <GroupBlockBox>
        <ExternalSituation />
        <Eletricity />
      </GroupBlockBox>
      <GroupBlockBox>
        <FloorMaterial />
        <WallMaterial />
      </GroupBlockBox>
      <GroupBlockBox>
        <HouseInfo />
        <Demography />
      </GroupBlockBox>
    </Box>
  );
}
