export function TimeIcon({ color = '#1D696F' }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0712 0C5.40125 0 0 5.41333 0 12.0833C0 18.7533 5.40125 24.1667 12.0712 24.1667C18.7533 24.1667 24.1667 18.7533 24.1667 12.0833C24.1667 5.41333 18.7533 0 12.0712 0ZM12.0833 21.75C6.7425 21.75 2.41667 17.4242 2.41667 12.0833C2.41667 6.7425 6.7425 2.41667 12.0833 2.41667C17.4242 2.41667 21.75 6.7425 21.75 12.0833C21.75 17.4242 17.4242 21.75 12.0833 21.75ZM12.6875 6.04167H10.875V13.2917L17.2187 17.0979L18.125 15.6117L12.6875 12.3854V6.04167Z"
        fill={color}
      />
    </svg>
  );
}
