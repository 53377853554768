import styled from 'styled-components';

import { mediaQuery } from '@/theme/mediaQuery';

import facebookImg from '@/assets/icons/facebook.svg';
import instagramImg from '@/assets/icons/instagram.svg';
import twitterImg from '@/assets/icons/twitter.svg';
import youtubeImg from '@/assets/icons/youtube.svg';

const SOCIAL_LINKS = [
  {
    image: facebookImg,
    link: `https://www.facebook.com/governodoceara`
  },
  {
    image: instagramImg,
    link: `https://www.instagram.com/governodoceara/`
  },
  {
    image: twitterImg,
    link: `https://twitter.com/governodoceara`
  },
  {
    image: youtubeImg,
    link: `https://www.youtube.com/channel/UCkpDYTqJkGp66nHngzZVzAw`
  }
];

export const ColumnSocial = () => {
  return (
    <Wrapper>
      <GroupIcons>
        {SOCIAL_LINKS.map((d, i) => (
          <a
            data-test={`social-media-${i}`}
            key={d.image}
            href={d.link}
            target="_blank"
            rel="noreferrer"
          >
            <img src={d.image} alt={d.image.split(/\W/).reverse()[1]} />
          </a>
        ))}
      </GroupIcons>
      <TextRow>
        <span>Central de atendimento - </span>
        <b>155</b>
      </TextRow>
      <TextRow>
        <span>Acessibilidade - </span>
        <b>0800 275 0022</b>
      </TextRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${mediaQuery.largeScreen} {
    width: fit-content;
    justify-self: end;
  }
  ${mediaQuery.mediumScreen} {
    width: fit-content;
    justify-self: center;
  }
`;

const GroupIcons = styled.div`
  margin-bottom: 5px;
  & > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${(props) => (props.theme.darkMode ? `#444` : `#2d4040`)};
    &:hover {
      box-shadow: 0 0 1px 1px #000;
    }
    & > img {
      width: 24px;
      height: 24px;
      filter: invert(100%);
    }
  }
`;

const TextRow = styled.div`
  line-height: 1.4;
  font-size: 0.91rem;
  text-rendering: geometricPrecision;
  color: ${(props) => props.theme.text};
  & > span {
    opacity: 0.7;
  }
  & > b {
    font-size: var(--font-size);
    opacity: 0.9;
  }
`;
