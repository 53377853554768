import { RichedAction } from 'big-data';
import { useState, useCallback } from 'react';
import { getActions as requestActions } from '../components/LastGoalsBoard/requests';

interface GetActionsParams {
  axle: string;
  ibge: string;
  indicator: string;
  page?: number;
  size?: number;
}

export function useActions() {
  const [actions, setLastFiveActions] = useState<RichedAction[]>([]);
  const [loadingActions, setLoadingLastFiveActions] = useState(false);
  const [errorActions, setErrorLastFiveActions] = useState('');

  const refreshActions = useCallback((params: GetActionsParams) => {
    const { axle, ibge, indicator } = params;
    const page = params.page ?? 0;
    const size = params.size ?? 5;

    setLoadingLastFiveActions(true);
    requestActions({ axle, ibge, indicator, page, size })
      .then(setLastFiveActions)
      .catch((err) => {
        if (err.response?.data) {
          setErrorLastFiveActions(err.response.data.message);
        } else {
          setErrorLastFiveActions(err.message);
        }
      })
      .finally(() => setLoadingLastFiveActions(false));
  }, []);

  return { actions, loadingActions, errorActions, refreshActions };
}
