import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';

import { useAuth, useDashboardTitle } from '@/hooks';

import { Box } from './styles';

import { CityTag, GoalsTag, StatusTag } from '../components/Tags';
import { useComments, useForumDetails, useHistories } from '../hooks';

import {
  ForumDetails,
  Timeline,
  ForumDangerZone,
  Comments
} from '../components';
import { Loading } from '@/components/shared';
import { actionNotification, observation } from '@/services';
import { ObservationSocket, IActionSocket } from '@/services/observation';
import { Observation } from 'big-data';

function useForumId() {
  const { forumId } = useParams();

  return Number(forumId ?? -1);
}

const mapReference = (observations: Observation[]) => {
  return observations
    .map((observation) => observation.id)
    .reduce((acc, cur, curIdx) => {
      return { ...acc, [cur]: curIdx };
    }, {} as Record<number, number>);
};

const uniqueIds = (observations: Observation[]) => {
  const ids = observations.map((observation) => observation.id);

  return Array.from(new Set(ids));
};

export function Forum() {
  const forumId = useForumId();
  const auth = useAuth();

  const [socket, setSocket] = useState<IActionSocket>();
  const [typing, setTyping] = useState('');

  const {
    forumDetails,
    loadingForumDetails,
    errorForumDetails,
    refreshForumDetails
  } = useForumDetails();
  const { histories, loadingHistories, errorHistories, refreshHistories } =
    useHistories();
  const {
    comments,
    setComments,
    loadingComments,
    errorComments,
    refreshComments
  } = useComments();

  const dashboardTitle = forumDetails?.title ?? 'Detalhes do plano de ação';
  useDashboardTitle(dashboardTitle);

  useEffect(() => {
    if (forumId < 0) return;

    refreshForumDetails(forumId);
  }, [forumId, refreshForumDetails]);

  useEffect(() => {
    if (forumId < 0) return;

    const socket = observation.createMessageSocket(forumId);
    const actionSocket = ObservationSocket(socket, forumId);

    setSocket(actionSocket);

    actionSocket.connect().then(() => {
      actionSocket.listenMessage((data) => {
        setComments((current) => {
          const newComments = [...current, data];

          const references = mapReference(newComments);
          const ids = uniqueIds(newComments);

          return ids.map((id) => newComments[references[id]]);
        });
      });

      actionSocket.listenTyping((data) => {
        if (data.sender !== auth.user?.client.name) {
          setTyping(data.type === 'TYPING' ? data.sender : '');
        }
      });
    });

    return () => {
      if (actionSocket.CONNECTED) {
        actionSocket
          .disconnect()
          .then(() => console.log(`Desconectado do tópico ${forumId}`));
      }
    };
  }, [forumId, auth, setComments]);

  useEffect(() => {
    actionNotification.readAllActionNotifications(forumId);
  }, [forumId]);

  function handleBlocked() {
    if (R.isNil(forumId)) return;

    const id = Number(forumId);

    refreshHistories(id, 0);
    refreshForumDetails(id);
  }

  function handleCreatedComment() {
    if (R.isNil(forumId)) return;

    const id = Number(forumId);

    refreshHistories(id, 0);
  }

  function handleCommentFocus() {
    if (socket) {
      socket.typing(auth.user?.client);
    }
  }

  function handleCommentBlur() {
    if (socket) {
      socket.notTyping(auth.user?.client);
    }
  }

  function renderContent() {
    if (R.isNil(forumDetails)) return;

    return (
      <>
        <div className="small-details">
          <CityTag city={forumDetails.city} />
          <StatusTag status={forumDetails.status} />
          <GoalsTag action={forumDetails} />
        </div>

        <div className="details">
          <ForumDetails
            details={forumDetails}
            loading={loadingForumDetails}
            error={errorForumDetails}
          />
        </div>

        <div className="danger-zone">
          <ForumDangerZone
            id={forumDetails.id}
            status={forumDetails.status}
            onBlocked={handleBlocked}
          />
        </div>

        <div className="timeline">
          <Timeline
            id={forumDetails.id}
            histories={histories}
            loading={loadingHistories}
            error={errorHistories}
            getHistories={refreshHistories}
          />
        </div>

        <div className="comments">
          <Comments
            id={forumDetails.id}
            typing={typing}
            comments={comments}
            loading={loadingComments}
            error={errorComments}
            getComments={refreshComments}
            onCreatedComment={handleCreatedComment}
            onFocus={handleCommentFocus}
            onBlur={handleCommentBlur}
          />
        </div>
      </>
    );
  }

  return (
    <Box>
      {loadingForumDetails ? (
        <div className="loading-feedback">
          <Loading />
        </div>
      ) : (
        renderContent()
      )}
    </Box>
  );
}
