export function FullPeriodIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9883 0.333374C5.54825 0.333374 0.333252 5.56004 0.333252 12C0.333252 18.44 5.54825 23.6667 11.9883 23.6667C18.4399 23.6667 23.6666 18.44 23.6666 12C23.6666 5.56004 18.4399 0.333374 11.9883 0.333374ZM11.9999 21.3334C6.84325 21.3334 2.66659 17.1567 2.66659 12C2.66659 6.84337 6.84325 2.66671 11.9999 2.66671C17.1566 2.66671 21.3333 6.84337 21.3333 12C21.3333 17.1567 17.1566 21.3334 11.9999 21.3334ZM12.5833 6.16671H10.8333V13.1667L16.9583 16.8417L17.8333 15.4067L12.5833 12.2917V6.16671Z"
        fill="white"
      />
    </svg>
  );
}
