import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

export const Box = styled.ul`
  display: flex;
  list-style: none;
  height: 100%;

  margin: 0;

  li {
    display: flex;
    align-items: center;

    .breadcrumb-divider {
      display: inline-block;
      height: 15px;
      width: 1.5px;

      margin: 0 0.8rem;

      transform-origin: 1px 7.5px;
      transform: rotate(22deg);

      background-color: ${colors.gray500};
    }

    a {
      color: ${({ theme }) =>
        theme.darkMode ? theme.yellowDefault : colors.gray500};
      text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};
      transition: filter 0.2s;

      &:hover {
        color: ${({ theme }) =>
          theme.darkMode ? theme.yellowDefault : colors.ciano700};
      }
    }

    &:last-child a {
      color: ${({ theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
      font-weight: 400;
      font-weight: 700;

      ${({ theme }) =>
        theme.darkMode &&
        css`
          color: ${colors.white};
        `}
    }
  }
`;
