import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { socialMovements } from '@/services/socialMovements';
import { useEffect, useMemo } from 'react';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { capitalize } from '@/utils/string';

const columns = [
  {
    name: 'Nome do movimento',
    key: 'name'
  }
];

export function MovementsList() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<{ name: string }>();

  useEffect(() => {
    request(() => socialMovements.listMovements());
  }, [request]);

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return { ...d, name: capitalize(d.name) };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Lista de movimentos sociais"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <ListTable columnTitle={columns} data={formattedData} />
      </Card>
    </Section>
  );
}
