import styled from 'styled-components';

interface BdsAnalyticsPanelProps {
  color?: string;
}

export function BdsAnalytics({ color = '#fff' }: BdsAnalyticsPanelProps) {
  return (
    <SVG
      width="31"
      height="29"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2H12.125V14.5H2V2ZM2 20.75H12.125V27H2V20.75ZM18.875 14.5H29V27H18.875V14.5ZM18.875 2H29V8.25H18.875V2Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
}

export const SVG = styled.svg`
  path {
    fill: none !important;
    stroke-width: 3 !important;
  }
`;
