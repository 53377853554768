import { insecurityFood } from './insecurityFood';
import { socialAssistance } from './socialAssistance';
import { education } from './education';
import { work } from './work';
import { income } from './income';
import { health } from './health';
import { pregnantHealth } from './pregnantHealth';
import { ibge } from './ibge';

export const geolocation = Object.freeze({
  insecurityFood,
  socialAssistance,
  education,
  work,
  income,
  health,
  pregnantHealth,
  ibge
});

export type { Geolocation, GeolocationBack, GeoJSONCentroid } from './@types';
