import { AxiosRequestConfig } from 'axios';
import { mainApi } from '../config/api';
import { callService } from '../config/service';

export const baseResource = `/rede-apoio-mulheres`;

export async function baseRequest<T>(
  path: string,
  params: AxiosRequestConfig['params']
) {
  const response = await callService(() =>
    mainApi.get<T[]>(path, {
      params
    })
  );

  return response.data;
}
