import { useEffect, useRef, useState } from 'react';
import { ActionHistory } from 'big-data';
import dayjs from 'dayjs';

import { Box, Histories, History, HistoryPoint, Intersection } from './styles';
import { Title3 } from '../styles';
import { Loading } from '@/components/shared';

interface TimelineProps {
  id: number;
  histories: ActionHistory[];
  loading?: boolean;
  error?: string;
  getHistories: (id: number, page?: number) => Promise<void>;
}

const PAGE_SIZE = 10;

function getHistoryDate(date: string) {
  const commentDate = dayjs(date);

  const today = dayjs();
  const yesterday = today.add(-1, 'day');

  const hour = commentDate.format('HH:mm');

  if (commentDate.isSame(today, 'day')) {
    return `Hoje às ${hour}`;
  }

  if (commentDate.isSame(yesterday, 'day')) {
    return `Ontem às ${hour}`;
  }

  const formattedDate = commentDate.format('DD/MM/YYYY');

  return `${formattedDate} às ${hour}`;
}

export function Timeline({
  id,
  histories,
  loading,
  error,
  getHistories
}: TimelineProps) {
  const [page, setPage] = useState(-1);

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  const rootRef = useRef<HTMLUListElement | null>(null);
  const intersectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { current: currentRoot } = rootRef;

    if (!currentRoot) return;

    const config = {
      root: currentRoot,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        setPage((current) => current + 1);
      }
    }, config);

    setObserver(observer);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const { current } = intersectionRef;

    if (current && observer) {
      observer.observe(current);
    }
  }, [observer]);

  useEffect(() => {
    const size = histories.length;

    const checkBestLastPageCase = size % 10 !== 0;
    const checkWorstLastPageCase = page * PAGE_SIZE > size;

    if (checkBestLastPageCase || checkWorstLastPageCase) {
      observer?.disconnect();
      setObserver(null);
    }
  }, [histories, page, observer]);

  useEffect(() => {
    if (page === -1) return;

    getHistories(id, page);
  }, [id, page, getHistories]);

  return (
    <Box>
      <Title3>Histórico de atualizações</Title3>

      <Histories ref={rootRef}>
        {histories.map((history, idx) => (
          <History key={history.id} order={idx}>
            <HistoryPoint>{idx + 1}</HistoryPoint>

            <div className="history-content">
              <p className="description">{history.description}</p>

              <div className="details">
                <span className="author">{history.author.name}</span>
                <span className="creation-date">
                  {getHistoryDate(history.creationDate)}
                </span>
              </div>
            </div>
          </History>
        ))}
        <Intersection ref={intersectionRef}>
          {loading && <Loading size={25} />}
        </Intersection>
      </Histories>
    </Box>
  );
}
