import { Card, WordCloud } from '@/components/shared';
import { useService } from '@/hooks';
import { organizationsService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card7() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => organizationsService.getInitiatives(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Principais iniciativas e ações das organizações de apoio a mulheres"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <WordCloud words={data} />
    </Card>
  );
}
