import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.p`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  span {
    font-weight: 700;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};
  }
`;
