import { ReactNode, useMemo, useState } from 'react';
import { DataTree } from 'big-data';

import { Arcs } from './Arcs';
import { Tooltip } from './Tooltip';
import { Loading } from './Loading';
import { Show } from '../Show';

import { Box, LegendBlock } from './styles';
import { Legend } from './Legend';
import { Provider } from './Provider';
import { Labels } from './Labels';
import { Icons } from './Icons';
import { AntModal } from '../AntModal';
import { AlertCityModal } from '../AlertCityModal';

type SunburstLegend = {
  name: string;
  color: string;
  selected?: boolean;
  redirect?: string;
};

interface SunBurstProps {
  data: DataTree;
  dataLegend?: SunburstLegend[];
  legendTitle?: string;
  loading?: boolean;
  children?: ReactNode;
  source?: string;
  selected?: boolean;
  labelWithInitials?: boolean;
  sizeByValue?: boolean;
  onClick?: (data?: DataTree) => void;
  renderIcon?: boolean;
}

export function SunBurstReact({
  data,
  dataLegend,
  legendTitle,
  loading = false,
  children,
  source,
  selected,
  labelWithInitials,
  sizeByValue,
  onClick,
  renderIcon = false
}: SunBurstProps) {
  const [colorHighlight, setColorHighlight] = useState<string>();

  const onChangeColor = (color?: string) => {
    if (colorHighlight) setColorHighlight(undefined);
    else setColorHighlight(color);
  };

  const [isData, setIsData] = useState(false);

  function onCancel() {
    setIsData(false);
  }

  const legend = useMemo(() => {
    if (dataLegend) return dataLegend;

    const { children } = data;

    const hasLegend = children.every((child) => child.legend !== undefined);

    if (!hasLegend) return [] as SunburstLegend[];

    return children.map((child) => {
      if (typeof child.legend === 'string') {
        return {
          name: child.legend,
          color: child.color,
          selected: child.selected,
          redirect: child.path
        };
      }

      return child.legend as SunburstLegend;
    });
  }, [data, dataLegend]);

  return (
    <Provider>
      <Box className="sunburst-box">
        <svg className="sunburst-svg">
          <Show when={!selected} fallback={null}>
            <Show
              when={!!renderIcon}
              fallback={<Labels labelWithInitials={labelWithInitials} />}
            >
              <Icons />
            </Show>
          </Show>
          <Show when={!loading} fallback={<Loading />}>
            <Arcs
              isOpen={setIsData}
              data={data}
              sizeByValue={sizeByValue}
              selected={selected}
              onClick={onClick}
              colorHighlight={colorHighlight}
            />
          </Show>

          <Tooltip />
        </svg>
        <div className="details">{children}</div>
      </Box>
      <LegendBlock>
        <Show when={!!source} fallback={null}>
          <p className="source">{source?.toUpperCase()}</p>
        </Show>
        <Show when={!!legendTitle} fallback={null}>
          <h4 className="legend-title">{legendTitle?.toUpperCase()}</h4>
        </Show>
        <Legend items={legend} onClick={(color) => onChangeColor(color)} />
      </LegendBlock>

      <AntModal
        closable={false}
        width={400}
        visible={isData}
        onCancel={onCancel}
        footer={null}
      >
        <AlertCityModal
          text="Não há dados para o indicador selecionado!"
          onCancel={onCancel}
        />
      </AntModal>
    </Provider>
  );
}
