import { ReactNode } from 'react';
import { ServiceBox } from '../..';

import { Box } from './styles';

interface OutdoorProps {
  error: Error | null;
  loading?: boolean;
  src: string;
  alt: string;
  children: ReactNode;
  color?: string;
  alignItems?: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

export function Outdoor({
  error,
  loading,
  src,
  alt,
  children,
  color,
  alignItems,
  flexDirection
}: OutdoorProps) {
  return (
    <ServiceBox empty={false} error={error} loading={loading}>
      <Box
        color={color}
        style={{
          alignItems,
          flexDirection
        }}
      >
        <div className="img-box">
          <img src={src} alt={alt} />
        </div>
        <div className="content-box">{children}</div>
      </Box>
    </ServiceBox>
  );
}
