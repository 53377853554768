import { socialAssistance } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

function getTotalFamilies(data: Data[]) {
  return data.reduce((acc, cur) => acc + cur.value, 0);
}

function getFamiliesByTotal(total: number) {
  return (data: Data[]) => {
    return data.map((d) => ({
      ...d,
      value: d.value
    }));
  };
}

export function useDemographyData(filter?: string) {
  const [cras, setCras] = useState<Data[]>([]);
  const [creas, setCreas] = useState<Data[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const promises = [
      socialAssistance.getZoneByName('CREAS', filter),
      socialAssistance.getZoneByName('CRAS', filter)
    ];

    setLoading(true);
    Promise.all(promises)
      .then(([dataCreas, dataCras]) => {
        const rural = dataCreas
          .filter((d) => d.name === 'Rural')
          .reduce(
            (acc, cur) => {
              return { ...acc, value: cur.value + acc.value };
            },
            { name: 'Rural', value: 0 }
          );
        const urbano = dataCreas
          .filter((d) => d.name === 'Urbana')
          .reduce(
            (acc, cur) => {
              return { ...acc, value: cur.value + acc.value };
            },
            { name: 'Urbana', value: 0 }
          );
        const total = [urbano, rural];
        setCreas(total);
        setCras(dataCras);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [filter]);

  const totalFamiliesCRAS = useMemo(() => {
    return getTotalFamilies(cras);
  }, [cras]);

  const familiesCRAS = useMemo(() => {
    const getFamilies = getFamiliesByTotal(totalFamiliesCRAS);
    return getFamilies(cras);
  }, [totalFamiliesCRAS, cras]);

  const totalFamiliesCREAS = useMemo(() => {
    return getTotalFamilies(creas);
  }, [creas]);

  const familiesCREAS = useMemo(() => {
    const getFamilies = getFamiliesByTotal(totalFamiliesCREAS);
    return getFamilies(creas);
  }, [totalFamiliesCREAS, creas]);

  return useMemo(
    () => ({
      familiesCRAS: familiesCRAS.filter((family) => family.name !== 'null'),
      totalFamiliesCRAS,
      familiesCREAS: familiesCREAS.filter((family) => family.name !== 'null'),
      totalFamiliesCREAS,
      loadingFamilies: loading,
      errorFamilies: error
    }),
    [
      familiesCRAS,
      totalFamiliesCRAS,
      familiesCREAS,
      totalFamiliesCREAS,
      loading,
      error
    ]
  );
}
