import { useDashboardTitle } from '@/hooks';
import { useLocation } from 'react-router-dom';
import { TabContextProvider, ManagerContextProvider } from '../contexts';
import { SectionContent } from './SectionContent';
import { Box } from './styles';

export function User() {
  const { state: cpfManager } = useLocation();

  useDashboardTitle('Dados de Acesso de Usuário');

  return (
    <ManagerContextProvider cpfManager={cpfManager as string}>
      <TabContextProvider>
        <Box>
          <SectionContent />
        </Box>
      </TabContextProvider>
    </ManagerContextProvider>
  );
}
