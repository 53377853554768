import { useEffect } from 'react';
import { ThemeProvider } from './theme/Provider';
import { PageManager } from '@/pages';
import ReactGA from 'react-ga';
import { GlobalStyle } from './theme/global';

import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.less';

export default function App() {
  useEffect(() => {
    document.body.classList.remove(`without-opacity`);
    ReactGA.initialize('UA-217154688-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider>
      <GlobalStyle />
      <PageManager />
    </ThemeProvider>
  );
}
