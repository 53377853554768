import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { useService } from '@/hooks';
import { socialMovements } from '@/services/socialMovements';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { HorizontalBarSimple } from '@/components/shared/Charts/HorizontalBarSimple';
import { capitalize } from '@/utils/string';

const dataLabels: Record<string, number> = {
  local: 0,
  municipal: 0,
  estadual: 0,
  regional: 0,
  nacional: 0,
  internacional: 0
};

export function PerformanceBar() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.levelOperationMovements());
  }, [request]);

  const dataFormatted = useMemo(() => {
    data.forEach((d) => {
      dataLabels[d.name.toLowerCase()] = d.value;
    });

    return Object.keys(dataLabels).map((d) => {
      return { name: capitalize(d), value: dataLabels[d] };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Níveis de atuação"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <HorizontalBarSimple
          maxValue={6}
          percentageCalculatedOut
          sizeRatio={0.7}
          sorted={false}
          isPercentage
          grid
          data={dataFormatted}
          color={colors.ciano700}
        />
      </Card>
    </Section>
  );
}
