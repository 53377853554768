import { Progress } from './styles';

import { DashboardCard } from '@/components/shared';
import { Text } from '@/components/styled';
import { colors } from '@/theme/colors';

import { goals } from './goals';

type InformationBodyProps = {
  color: string;
};

export function EducationGoals({ color }: InformationBodyProps) {
  const colorSelected = colors.ciano700;
  return (
    <DashboardCard color={colorSelected}>
      <p className="information-about">Metas para a educação até 2022</p>
      <ul className="information-list">
        <li>
          <div>
            <Progress
              strokeColor={colorSelected}
              strokeWidth={30}
              status="active"
              showInfo={false}
              percent={goals['creche']}
            />
            <Text>
              <strong className="numberPercent">{goals['creche']}% </strong>
              das crianças de <strong>0 a 3 anos</strong> matriculadas em{' '}
              <strong>creches</strong>
            </Text>
          </div>
        </li>
        <li>
          <div>
            <Progress
              strokeColor={colorSelected}
              strokeWidth={30}
              status="active"
              showInfo={false}
              percent={goals['pré-escola']}
            />
            <Text>
              <strong className="numberPercent">{goals['pré-escola']}% </strong>
              das crianças de <strong>4 a 6 anos</strong> matriculadas em{' '}
              <strong>pré-escolas</strong>
            </Text>
          </div>
        </li>
        <li>
          <div>
            <Progress
              strokeColor={colorSelected}
              strokeWidth={30}
              status="active"
              showInfo={false}
              percent={goals['escola']}
            />
            <Text>
              <strong className="numberPercent">{goals['escola']}% </strong> das
              crianças de <strong>7 a 8 anos</strong> matriculadas no{' '}
              <strong>Ensino Fundamental</strong>
            </Text>
          </div>
        </li>
      </ul>
    </DashboardCard>
  );
}
