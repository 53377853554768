import { useRef } from 'react';
import { Box, Bar, ListItem } from './styles';

interface TabsItem {
  id: number;
  name: string;
  value: string;
}

interface TabsProps {
  items: TabsItem[];
  tabId?: number;
  onClickTab?: (item: TabsItem, id: number) => void;
  activeTab?: string;
}

export function Tabs({ activeTab, tabId, onClickTab, items }: TabsProps) {
  const listItemRef = useRef<HTMLLIElement | null>(null);

  function handleClick(idx: number, tab?: TabsItem) {
    if (onClickTab) {
      onClickTab(items[idx], idx);
    }
  }

  const initialTabId =
    items.find((item) => item.value === activeTab)?.id ?? tabId;
  const left = (initialTabId ?? 0) * 100;

  return (
    <Box className="tabs-box-nav">
      <ul className="tabs">
        {items.map((item, idx) => (
          <ListItem
            ref={listItemRef}
            key={item.id}
            onClick={() => handleClick(idx, item)}
            selected={tabId === item.id || activeTab === item.value}
            className="tabs-box-nav-list-item"
          >
            {item.name}
          </ListItem>
        ))}
      </ul>
      <Bar
        left={left}
        width={listItemRef.current?.offsetWidth ?? 0}
        height={listItemRef.current?.offsetHeight ?? 0}
        amountItems={items.length}
      />
    </Box>
  );
}
