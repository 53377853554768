import styled from 'styled-components';
import * as R from 'ramda';
import { colors } from '@/pages/Dashboard/InsecurityFood/tree';

export const Box = styled.div`
  padding: 1rem;

  h3 {
    display: flex;
    justify-content: space-between;
    color: ${(props) => (props.theme.darkMode ? '#FFF' : colors.green700)};
    margin-bottom: 0.8rem;
  }

  ul {
    list-style: none;
    margin: 0;
    font-size: 1rem;
  }
`;

interface ListItemProps {
  selected?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  position: relative;

  padding: 0.7rem 1rem;
  border-top: 1px solid #dfe6e9;
  cursor: pointer;

  opacity: ${({ selected }) => {
    if (R.isNil(selected)) return 1;

    return selected ? 1 : 0.5;
  }};

  .value {
    position: absolute;
    right: 0;
    color: ${(props) => (props.theme.darkMode ? '#FFF' : '#636e72')};
  }

  .name {
    color: ${(props) => (props.theme.darkMode ? '#FFF' : '#636e72')};
    font-size: 0.9rem;
    line-height: 16px;

    @media (max-width: 400px) {
      max-width: 200px;
    }
  }
`;

interface BallProps {
  fill: string;
}

export const Ball = styled.span<BallProps>`
  display: inline-block;

  background-color: ${({ fill }) => fill};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.6rem;
`;
