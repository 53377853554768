import { useEffect, useMemo, useState } from 'react';
import { foodInsecurity } from '@/services';
import { Data } from 'big-data';
import { useDashboardFilter } from '@/hooks';

export function useFoodInsecurity() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Data[]>([]);

  const { ibge, year, semester, zone, isNull } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    foodInsecurity
      .getByClassification(ibge, year, semester, zone)
      .then((data) => {
        setData(data.sort((a, b) => b.value - a.value));
        if (data.length === 0) isNull();
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      error,
      loading
    }),
    [data, loading, error]
  );
}
