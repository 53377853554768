import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { mediaQuery } from '@/theme/mediaQuery';
import { PageBody, PageSection } from '@/layout';
import { CitySelected } from './CitySelected';
import { ColumnText } from './ColumnText';
import { LegendPanel } from './LegendPanel';
import { loadCearaGeoJSON } from './service';
import { createSvgMap } from './svgMap';
import { LoadingStatus } from './LoadingStatus';
import { useConnectObserver } from '@/hooks';

const EMPTY_CITY = { visible: false, name: ``, total: `` };

export function ImpactsMap() {
  const refSvg = useRef<any>(null);
  const refMap = useRef<any>(null);
  const [legendItems, setLegendItems] = useState<LegendItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [city, setCity] = useState<ICity>(EMPTY_CITY);

  useEffect(() => {
    const options = {
      onLegendItems: (items: any) => {
        setLegendItems(items);
      },
      onSelect: (city: any | undefined) => {
        const visible = !!city;
        setCity((prevCity) => {
          return city ? { ...city, visible } : { ...prevCity, visible };
        });
      }
    };
    loadCearaGeoJSON()
      .then((json) => {
        if (refSvg.current) {
          refMap.current = createSvgMap(refSvg.current, json, options);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (refSvg.current) {
          setError(`Falha ao carregar dados SectionMapa`);
          setLoading(false);
        }
        console.log(`Ops!`, err);
      });
    return () => {
      if (refMap.current) {
        refMap.current.remove();
      }
    };
  }, []);

  const familyRef = useConnectObserver<HTMLDivElement>();

  return (
    <div id="Family" ref={familyRef}>
      <PageWrapper>
        <Wrapper>
          <ColumnText />
          <MapContainer>
            <svg ref={refSvg} />
            <LegendPanel items={legendItems} />
            <LoadingStatus loading={loading} error={error} />
            <CitySelected {...city} />
          </MapContainer>
        </Wrapper>
      </PageWrapper>
    </div>
  );
}

const PageWrapper = styled((props) => <PageBody {...props} />)`
  background: inherit;
  padding: 150px 0 50px 0;
  ${mediaQuery.smallScreen} {
    padding: 30px 10px;
  }
`;

const Wrapper = styled((props) => <PageSection {...props} />)`
  min-height: 616px;
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;

  display: ${(props) => (props.theme.screen.large ? `flex` : `block`)};
  padding: 0 40px;
  ${mediaQuery.smallScreen} {
    padding: 10px;
    svg path.city {
      stroke-width: 0.5 !important;
    }

    min-height: 403px;
  }
  svg {
    &.with-animation {
      pointer-events: none;
      path.city {
        transition: 0.2s;
        transform-origin: center;
      }
    }
    path.city {
      stroke-width: 1;
      stroke: #57aa;
      &.hidden {
        opacity: 0;
        transform: scale(1.8);
      }
      &.no-fill {
        fill: #dddc !important;
        opacity: 0.4;
        transition: none;
      }
    }
  }
`;

const MapContainer = styled.div`
  position: relative;
  width: ${(props) => (props.theme.screen.large ? `65%` : null)};
  padding: 10px 20px 10px 0;
  ${mediaQuery.smallScreen} {
    padding: 10px 0;
    min-height: 250px;
    svg {
      margin-left: -15vw;
    }
  }
  ${mediaQuery.mediumScreen} {
    svg {
      margin-left: -10vw;
    }
  }
`;
