import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { dotsNumber } from '@/utils/string';
import { AgeGroupType } from 'big-data';
import { Box } from './styles';
import { useData } from './useData';

import oldMan from '@/assets/icons/old-man.svg';
import doubleMan from '@/assets/icons/double-men.svg';
import menWomen from '@/assets/icons/men-women.svg';
import { useMemo } from 'react';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

const ageGroupOptions: Record<
  AgeGroupType | any,
  { text: string; image: string }
> = {
  '18-40': {
    text: 'é a quantidade de pessoas analfabetas entre 18 e 40 anos.',
    image: doubleMan
  },
  '40-60': {
    text: 'é a quantidade de pessoas analfabetas entre 40 e 60 anos.',
    image: menWomen
  },
  '60+': {
    text: 'é a quantidade de pessoas analfabetas com idade superior a 60 anos.',
    image: oldMan
  },
  total: {
    text: 'é a quantidade total de pessoas analfabetas acima de 18 anos.',
    image: ''
  }
};

function resolveMillionNumber(number: number, first = false) {
  if (first) {
    return `${dotsNumber(number)}`;
  }

  return `${number.toFixed(1)}%`;
}

interface IlliteratesAgeByCityProps {
  color: string;
}

export function IlliteratesAgeByCity({ color }: IlliteratesAgeByCityProps) {
  const { data, loading, error } = useData();

  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const dataWithPercentage = useMemo(() => {
    return data.map((el) => {
      const select = ageGroupOptions[el.name];
      return {
        value: (el.value / total) * 100,
        name: select.text,
        image: select.image
      };
    });
  }, [data, total]);

  const { city } = useAnalyticsFilter();

  const isEmpty = useMemo(() => {
    return !!!dataWithPercentage.find((d) => d.value > 0);
  }, [dataWithPercentage]);

  return (
    <Section>
      <Card
        title="Percentual de analfabetismo entre adultos"
        empty={isEmpty}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'Ceará']}
      >
        <Box>
          {dataWithPercentage.map((d, i) => {
            return (
              <div key={d.name} className="block-content">
                <img src={d.image} alt="icon de analfabetismo" />
                <div className="text">
                  <span>{resolveMillionNumber(d.value)}</span>
                  <p>{d.name}</p>
                </div>
              </div>
            );
          })}
        </Box>
      </Card>
    </Section>
  );
}
