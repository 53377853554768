import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  gap: 10px;
  padding-right: 1rem;

  padding-top: 1rem;

  @media (max-width: 1030px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  .icon-text-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    font-weight: 400;
    font-size: 0.875rem; // 14px
    line-height: 16px;

    color: ${(props) => props.theme.text};

    letter-spacing: 0.03rem;

    span {
      display: none;
      @media (max-width: 1030px) {
        display: block;
      }
    }
  }

  .select-wrapper {
    display: flex;
    gap: 10px;
    @media (max-width: 1030px) {
      flex-direction: column;
      align-items: center;
    }

    .select-group {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 1030px) {
        flex-direction: column;
        align-items: center;
      }
    }

    button.clear-filter {
      justify-self: flex-start;

      background: none;
      border: none;
      color: ${({ color, theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
      text-decoration: ${({ theme }) =>
        theme.darkMode ? 'underline' : 'none'};

      display: flex;
      align-items: center;
      transition: filter 0.2s;

      span {
        font-weight: 700;
        font-size: 0.8rem;
        margin-top: 3px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;
