import styled from 'styled-components';

import { Card } from '../styles';

export const Box = styled(Card)`
  .comments,
  .comments-input,
  .typing-msg,
  .comment-answer {
    padding: 1.2rem 4rem;

    @media (max-width: 400px) {
      padding: 0.8rem 0.6rem;
    }
  }

  .comment-answer {
    padding-block: 0 0.5rem;
  }

  .comments-input {
    padding-block-start: 0;
  }

  .comments {
    max-height: 320px;
    overflow-y: auto;
    scroll-behavior: smooth;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 400px) {
      gap: 0.5rem;
      padding-top: 0;
    }

    .intersection {
      display: flex;
      justify-content: center;

      height: 30px;

      @media (max-width: 400px) {
        height: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;

      &:hover {
        background-color: #555;
      }
    }
  }

  .typing-msg {
    font-size: 0.8rem;
    height: 19px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
