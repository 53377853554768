import { useService } from '@/hooks';
import { CountLabel } from '@/pages/Analytics/components';
import { organizationsService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function TotalInterviews() {
  const { ibge, city } = useFilter();
  const { data, loading, request, error } = useService<Data>();

  const label = useMemo(() => {
    if (city) return `organizações entrevistadas em ${city}`;
    return 'organizações entrevistadas em todo o Ceará';
  }, [city]);

  const isFiltring = city !== undefined

  useEffect(() => {
    request(() =>
      organizationsService.getTotalInterviews(ibge)
    );
  }, [ibge, request]);

  return (
    <CountLabel
      value={data[0]?.value ?? 0}
      error={error}
      loading={loading}
      isFiltring={isFiltring}
      size={30}
      label={label}
    />
  );
}
