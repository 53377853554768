import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  .chart-box {
    display: grid;
    grid-template-columns: 1fr 7fr;
    align-items: center;
    svg {
      width: 110px;
      height: 110px;
      fill: ${colors.ciano700};
    }
  }
`;
export const Text = styled.div`
  font-weight: 700;
  line-height: 25px;
  padding: 2rem;
  span {
    font-size: 1.5rem;
    color: ${(props) => (props.theme.darkMode ? `#fff` : colors.ciano700)};
  }

  p {
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#000`)};
    font-size: 1rem;
    font-weight: 700;
  }
`;
