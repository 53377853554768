import { ReactNode } from 'react';
import { Box } from './styles';

interface ApresentationCardProps {
  source?: string;
  title?: string;
  text?: string;
  children?: ReactNode;
}

export function ApresentationCard({
  source,
  title,
  text,
  children
}: ApresentationCardProps) {
  return (
    <Box>
      <header>
        <h4>{title}</h4>
        {source && (<h4 className="source">Fonte: {source}</h4>)}
      </header>
      {children}
      <p>{text}</p>
    </Box>
  );
}
