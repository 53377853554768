import { ensureAxiosParamOptions } from '@/utils/routes';
import { Manager } from 'big-data';
import { mainApi } from '../config/api';
import { callService } from '../config/service';

const service = () => {
  const resource = `/manager/bds`;

  async function get(cpf: string) {
    const path = resource;
    const options = ensureAxiosParamOptions({ params: { cpf } });
    const response = await callService(() =>
      mainApi.get<Manager>(path, options)
    );
    return response.data;
  }

  return { get };
};

export const bdsManager = service();
