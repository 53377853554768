import styled from 'styled-components';
import { Button, Input } from 'antd';

import { colors } from '@/theme/colors';

const { TextArea: AntdTextArea } = Input;

export const Card = styled.div`
  background: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
  border: 1px solid #dfe6e9;
  border-radius: 7px;
`;

export const TextArea = styled(AntdTextArea)`
  &.ant-input {
    &:focus {
      border-color: ${({ theme }) => theme.greenDefault};
      border-width: 1px;
      box-shadow: none;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;

      &:hover {
        background-color: #555;
      }
    }
  }
`;

export const BackButton = styled(Button)`
  &.ant-btn {
    background: ${({ theme }) => (theme.darkMode ? '#000' : 'transparent')};
    color: ${({ theme }) => (theme.darkMode ? '#fff' : theme.textDark)};
    border-radius: 7px;
    padding: 0.5rem 1.5rem;
    transition: all 0.3s;

    display: flex;
    align-items: center;

    span {
      padding: 0.2rem 1.2rem;
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.darkMode ? '#fff' : theme.greenDefault};
      border-color: ${({ theme }) =>
        theme.darkMode ? '#fff' : theme.greenDefault};

      color: ${({ theme }) => (theme.darkMode ? theme.textDark : '#fff')};

      &:active {
        transform: scale(0.98);
      }
    }
  }
`;

export const SubmitButton = styled(BackButton)`
  &.ant-btn {
    background-color: ${({ theme }) =>
      theme.darkMode ? 'transparent' : theme.greenDefault};
    border-color: ${({ theme }) =>
      theme.darkMode ? '#fff' : theme.greenDefault};
    color: ${({ theme }) => (theme.darkMode ? theme.greenDefault : '#fff')};

    transition: all 0.3s;

    &:hover {
      filter: brightness(1.1);

      &:active {
        transform: scale(0.98);
      }
    }
  }
`;

export const Title3 = styled.h3`
  border-bottom: 1px solid ${colors.gray400};
  padding: 0.8rem 2rem;
  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.ciano700)};
  font-size: 1.25rem;
`;
