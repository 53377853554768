import { CardTagged, Pyramid, ServiceBox } from '@/components/shared';
import { TitleText, Text, ColorText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { useJobIncomeData } from '@/servicesHooks/jobIncome';

import { percentageFormatter } from '@/utils/string';
import { sumDataTotal } from '@/utils/data';
import { colors } from '@/theme/colors';
import { useTheme } from 'styled-components';

const DARK_MODE_COLOR = colors.yellow600;

export function CardPyramid() {
  const { darkMode } = useTheme();

  const { tag } = useDashboardFilter();
  const { data, ...rest } = useJobIncomeData();
  const total = sumDataTotal(data);
  const [bigger] = data;
  const biggerPercentage = percentageFormatter(
    ((bigger?.value ?? 0) / total) * 100
  );

  const biggerName = bigger?.name.startsWith('E')
    ? `${bigger?.name}`
    : `de ${bigger?.name}`;

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>Qual a renda mensal das famílias?</TitleText>
        <Text>
          <ColorText>{biggerPercentage} </ColorText>
          vivem com renda mensal,
          <ColorText> {biggerName.toLowerCase()}</ColorText>, como mostram os
          dados a seguir:
        </Text>
        <Pyramid
          data={data}
          color={darkMode ? DARK_MODE_COLOR : colors.ciano700}
        />
      </ServiceBox>
    </CardTagged>
  );
}
