import { useCallback } from 'react';
import { Box } from './styles';

interface LastUpdateMessageProps {
  value?: string;
  loading: boolean;
  message?: string;
  error: Error | null;
  year?: string;
}

export function LastUpdateMessage({
  value,
  loading,
  error,
  message,
  year
}: LastUpdateMessageProps) {
  const data = useCallback(() => {
    return value
      ?.split('-')
      .reverse()
      .reduce((acc, cur) => {
        return acc + cur + (cur.length === 4 ? '' : '/');
      }, '');
  }, [value]);

  function renderContent() {
    if (error === null)
      return `${message ?? 'Última atualização em'} ${year ?? data()}`;
    return 'Houve um erro ao buscar a data da ultima atualização!'
  }

  return (
    <Box>
      <h3>{loading ? 'Carregando...' : renderContent()}</h3>
    </Box>
  );
}
