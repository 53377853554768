import styled, { css, useTheme } from 'styled-components';

import { ButtonHTMLAttributes } from 'react';
import {
  FontMinusIcon,
  FontPlusIcon,
  FontNormalIcon,
  ThemeIcon
} from './icons';

type ButtonMode = `font-normal` | `font-minus` | `font-plus` | `theme`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  mode: ButtonMode;
}

function loadImage({ mode, color }: ButtonProps) {
  const images: Record<ButtonMode, JSX.Element> = {
    'font-normal': <FontNormalIcon color={color} />,
    'font-minus': <FontMinusIcon color={color} />,
    'font-plus': <FontPlusIcon color={color} />,
    theme: <ThemeIcon color={color} />
  };

  return images[mode];
}

export function IconButton({ mode, ...rest }: ButtonProps) {
  const { darkMode } = useTheme();
  const color = darkMode ? '#fff' : undefined;
  const icon = loadImage({ mode, color });

  return (
    <IconButtonStyles {...rest} mode={mode}>
      {icon}
    </IconButtonStyles>
  );
}

export const IconButtonStyles = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 26px;
  height: 26px;
  margin-left: 6px;
  opacity: 0.9;
  transform: ${(props) =>
    props.theme.darkMode && props.mode === `theme` && `scale(-1,1)`};
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 1px 1px #0001;

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover {
    opacity: 1;
    &:active {
      opacity: 0.8;
    }
  }
  ${(props) =>
    !props.disabled
      ? null
      : css`
          pointer-events: none;
          opacity: 0.4;
        `}
`;
