import { useEffect } from 'react';

import { Card } from '@/components/shared';
import { ListTable } from '@/components/shared/Charts/ListTable';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { organizationsService } from '@/services/womenMapping';
import { useFilter } from '../../hooks/useFilter';

export function Card10() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data<string, string>>();

  useEffect(() => {
    request(() => organizationsService.getAwardNames(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Principais prêmios de reconhecimento das organizações"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <ListTable
        data={data}
        columnTitle={[
          { name: 'Prêmios de reconhecimento', key: 'award' },
          { name: 'Quantidade', key: 'quantidade' }
        ]}
        numberValues
      />
    </Card>
  );
}
