import styled from 'styled-components';

export const Box = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#000' : '#FFFFFF')};
  border: 1px solid #dfe6e9;
  border-radius: 7px;

  .card-content {
    text-align: center;
    margin: 1rem 0;
  }
`;
