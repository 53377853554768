import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';
import { PageSection } from '../UI/PageSection';

export const Box = styled.div`
  overflow: visible;
  padding: 15px 20px;
  text-rendering: geometricPrecision;
  color: ${({ theme }) => (theme.screen.small ? `#ff2` : `#fff`)};
  transition: background-color 0.2s;
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.background : theme.greenDark};

  &.enabled-transparent {
    background-color: transparent;
  }

  &.disabled-transparent {
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.background : theme.greenDark};
  }

  ${mediaQuery.smallScreen} {
    min-height: 80px;
    padding: 5px 10px;
  }
  ${mediaQuery.mediumScreen} {
    padding-left: 0;
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      background-color: ${theme.background};
    `}
`;

export const ContainerHeader = styled(PageSection)`
  padding: 0 20px;

  ${mediaQuery.mediumScreen} {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;

    padding: 0;
  }

  ${mediaQuery.smallScreen} {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;

    padding: 0 2px;
  }
`;
