import { AxeDescriptionText } from '@/components/styled';
import { useAnalyticsTitle } from '@/hooks';
import { routesAnalytics } from '@/pages/routes.analytics';
import { mountLinks } from '@/utils/routes';
import { AnalyticCard } from '../components/AnalyticCard';
import { Box, Content } from './styles';

export function Home() {
  useAnalyticsTitle('Analíticos e Anuários');
  function mountRoutes() {
    return mountLinks(routesAnalytics)
      .filter((r) => r.description)
      .map((route) => {
        const Icon = route.iconImg;
        const iconProp = () => <Icon />;
        return (
          <AnalyticCard
            key={route.name}
            title={route.name}
            category={route.category}
            icon={iconProp}
            description={route.description}
            path={route.path}
          />
        );
      });
  }

  return (
    <Box>
      <AxeDescriptionText className="axe-description-text">
        Painéis visuais apresentam indicadores de categorias como auxílios
        sociais, educação, moradia e trabalho e renda, além de anuários e outros
        dados sobre o perfil de movimentos sociais. Com isso, gestores públicos
        podem tomar decisões de forma mais eficiente e acompanhar programas e
        auxílios públicos.
      </AxeDescriptionText>

      <Content>{mountRoutes()}</Content>
    </Box>
  );
}
