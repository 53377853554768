import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  padding: 2rem;
  /* max-width: 320px; */
  width: 100%;

  @media (max-width: 1250px) {
    max-width: 100%;
  }

  h2 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: ${({ color, theme }) => {
      const baseColor = color ?? '#000';

      return theme.darkMode ? colors.white : baseColor;
    }};
    margin-bottom: 20px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
    text-align: left;
  }

  b {
    color: ${({ color, theme }) => {
      const baseColor = color ?? '#000';

      return theme.darkMode ? colors.white : baseColor;
    }};
  }

  button {
    background: ${({ theme }) =>
      theme.darkMode ? theme.yellowDark : 'transparent'};
    padding: 0.5rem 0.8rem;
    border: 1px solid
      ${({ color, theme }) => {
        const baseColor = color ?? '#000';
        return theme.darkMode ? theme.yellowDark : baseColor;
      }};
    border-radius: 8px;
    margin-top: 1rem;

    display: flex;
    gap: 0.5rem;
    align-items: center;

    svg path {
      fill: ${({ theme }) => (theme.darkMode ? theme.background : '')};
    }

    span {
      color: ${({ color, theme }) => {
        const baseColor = color ?? '#000';
        return theme.darkMode ? theme.background : baseColor;
      }};
    }

    &:hover {
      background: ${({ color, theme }) => {
        const baseColor = color ?? '#000';
        return theme.darkMode ? 'initial' : baseColor;
      }};

      path,
      span {
        fill: white;
        color: white;
      }
    }
  }
`;
