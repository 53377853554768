import { DataWithCity } from 'big-data';

export function getCityRecord(data: DataWithCity[]) {
  return data.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name.toLocaleLowerCase()]: cur.value
    };
  }, {} as Record<string, number>);
}
