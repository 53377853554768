import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: visible;

  svg {
    overflow: visible;

    .axis-bottom-bar-label {
      tspan {
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: 700;
        font-family: 'IBM Plex Sans';
        line-height: 1rem;
        fill: ${({ theme }) =>
          theme.darkMode ? colors.white : colors.green700};
      }
    }

    .axis-left-bar-label {
      tspan {
        font-size: 0.75rem;
        line-height: 0.875rem;
        font-weight: 400;
        font-family: 'IBM Plex Sans';
        line-height: 1rem;
        fill: ${({ theme }) =>
          theme.darkMode ? colors.white : colors.green700};
      }
    }
  }
`;
