import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';

interface ColorBoxProps {
  bg: string;
  selected?: boolean;
}

export const Box = styled.div`
  h1 {
    text-align: center;
    color: ${colors.gray700};
    font-weight: 700;
    font-size: 1.25rem; // 20px
    line-height: 1.5rem; // 24px
  }

  .satisfaction-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      display: block;
      margin: 20px 0;
      color: ${({ theme }) => (theme.darkMode ? `#fff` : `#000`)};
    }

    .satisfaction-values {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      gap: 10px;

      label.faithfulness-text {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 140%;
        text-align: left;
      }
    }

    .control {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;

      textarea.satisfaction-answer {
        border-radius: 4px;
        border-color: ${colors.ciano700};
        padding: 12px;
        min-height: 58px;
        width: 100%;
        color: ${({ theme }) => (theme.darkMode ? `#fff` : `#000`)};

        &:focus {
          outline-color: ${colors.ciano700};
        }
      }
    }
  }
`;

export const ColorBoxButton = styled.input<ColorBoxProps>`
  border: 0;

  background: ${({ bg }) => bg};
  width: 40px;
  height: 40px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem; // 14px
  color: #fff;
  font-weight: 900;

  transition: opacity 0.2s, transform 0.3s;

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid #000;
      transform: scale(1.2);
    `};
`;
