import { useState } from 'react';
import { AiOutlineExclamation } from 'react-icons/ai';
import { FaLock } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { notification } from 'antd';

import { actions } from '@/services';
import { colors } from '@/theme/colors';

import { Box, View, Question } from './styles';

interface ForumDangerZoneProps {
  id: number;
  status: string;
  onBlocked?: () => void;
}

const getViewPage = (status: string, dflt: number = 0) => {
  if (status === 'BLOCKED') return 2;
  if (status === 'DONE') return 3;

  return dflt;
};

export function ForumDangerZone({
  id,
  status,
  onBlocked
}: ForumDangerZoneProps) {
  const [page, setPage] = useState(0);

  function goToSecondQuestion() {
    setPage(1);
  }

  function goToFirstQuestion() {
    setPage(0);
  }

  function handleBlock() {
    actions
      .blockAction(id)
      .then(() => {
        notification.success({
          message: 'Plano de ação bloqueado com sucesso',
          top: 50
        });
        setPage(2);
      })
      .then(() => {
        if (onBlocked) {
          onBlocked();
        }
      })
      .catch((err) => {
        const data = err?.response.data;

        notification.error({
          message: 'Houve um problema ao bloquear o plano de ação',
          description: data?.message ?? 'Tente novamente mais tarde',
          top: 50
        });

        setPage(0);
      });
  }

  return (
    <Box disabled={status === 'DONE'}>
      <View page={getViewPage(status, page)}>
        <Question>
          <p className="question-text">
            <span className="icon">
              <AiOutlineExclamation color={colors.white} />
            </span>
            <span>Deseja bloquear o plano de ação?</span>
          </p>

          <div className="buttons">
            <button className="confirm" onClick={goToSecondQuestion}>
              Sim
            </button>
          </div>
        </Question>

        <Question>
          <p className="question-text">
            <span className="icon">
              <AiOutlineExclamation color={colors.white} />
            </span>
            <span>Você tem certeza que deseja bloquear o plano de ação?</span>
          </p>

          <div className="buttons">
            <button className="confirm" onClick={handleBlock}>
              Sim, tenho certeza
            </button>
            <button className="cancel" onClick={goToFirstQuestion}>
              Não tenho certeza
            </button>
          </div>
        </Question>

        <Question>
          <p className="question-text block-text">
            <span className="icon block-icon">
              <FaLock color={colors.white} size={14} />
            </span>
            <span>O plano de ação está bloqueado</span>
          </p>
        </Question>

        <Question>
          <p className="question-text done-text">
            <span className="icon done-icon">
              <MdClose color={colors.white} size={14} />
            </span>
            <span className="text">
              O plano de ação não pode ser bloqueado pois está concluído
            </span>
          </p>
        </Question>
      </View>
    </Box>
  );
}
