import * as d3 from 'd3';
import { useEffect } from 'react';

import { loadingTree, LoadingTree } from './tree';
import { useChart } from '../Provider';
import { getArcFunction } from '../utils';

type Root = d3.HierarchyRectangularNode<LoadingTree>;

export function Loading() {
  const { chart } = useChart();

  useEffect(() => {
    if (!chart) return;

    const width = Number(chart.attr('width'));
    const height = Number(chart.attr('height'));
    const radius = Math.max(width, height) / 2;

    const rootFn = d3.partition<LoadingTree>();
    const root: Root = rootFn(
      d3
        .hierarchy(loadingTree)
        .sum((d) => d.value)
        .sort((a, b) => (b.value ?? 0) - (a.value ?? 0))
    );
    const dFn = getArcFunction<LoadingTree>(radius);
    const descendants = root.descendants();

    const arcGroup = chart
      .attr('viewBox', '-200 -200 400 400')
      .append('g')
      .attr('class', 'arc-loading-group');

    arcGroup
      .selectAll('path')
      .data(descendants)
      .join('path')
      .attr('class', 'arc-loading')
      .attr('id', (_, i) => `path-${i}`)
      .attr('fill', (d) => d.data.color)
      .attr('d', dFn);

    return () => {
      arcGroup.remove();
    };
  }, [chart]);

  return null;
}
