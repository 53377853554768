import { googleAnalytics } from '@/services/googleAnaltyics';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

export function useTemporalRejection(minimumDate?: string, ibge?: string) {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    googleAnalytics.temporalRejection
      .get(minimumDate, ibge)
      .then((data) => setData(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [minimumDate, ibge]);

  return {
    data,
    loading,
    error
  };
}
