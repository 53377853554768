import { ReactNode } from 'react';
import * as R from 'ramda';
import { Loading } from '../Loading';
import { Show } from '../Show';
import { Box, ContentBox } from './styles';

interface ServiceBoxProps {
  empty: boolean;
  children: ReactNode;
  className?: string;
  error?: string;
  emptyMessage?: string;
  loading?: boolean;
  minHeight?: number;
  minWidth?: number;
}

export function ServiceBox2({
  empty,
  error,
  children,
  className,
  emptyMessage,
  loading,
  minHeight,
  minWidth
}: ServiceBoxProps) {
  function renderError() {
    return (
      <ContentBox color="#ab1e11">
        <p>Não foi possível carregar a informação</p>
        <p>{error}</p>
      </ContentBox>
    );
  }

  function renderLoading() {
    return (
      <ContentBox>
        <Loading />
      </ContentBox>
    );
  }

  function renderEmpty() {
    return (
      <ContentBox color="#bababa">
        <p>
          {emptyMessage ?? 'Não existem informações para serem visualizadas'}
        </p>
      </ContentBox>
    );
  }

  return (
    <Box className={className} minHeight={minHeight} minWidth={minWidth}>
      <Show when={R.isEmpty(error) || R.isNil(error)} fallback={renderError()}>
        <Show when={!loading} fallback={renderLoading()}>
          <Show when={!empty} fallback={renderEmpty()}>
            {children}
          </Show>
        </Show>
      </Show>
    </Box>
  );
}
