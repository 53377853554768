import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  .graph {
    grid-column: span 2;
  }

  .map {
    grid-column: span 2;
  }

  @media (max-width: 1000px) {
    .graph {
      grid-column: span 1;
    }

    .map {
      grid-column: span 1;
    }
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr;
  }
`;
