import { Box, Radio } from './styles';

interface Option<T> {
  label: string;
  value: T;
}

interface FixedRadioProps<T> {
  options: Option<T>[];
  value: T;
}

export function FixedRadio<T>({ options, value }: FixedRadioProps<T>) {
  return (
    <Box>
      {options.map((option, idx) => (
        <Radio key={option.label} order={idx + 1} selected={option.value === value}>
          {option.label}
        </Radio>
      ))}
    </Box>
  );
}
