import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.section`
  background: ${colors.green700};

  .benefits-box {
    max-width: 1400px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding: 50px 36px;
    overflow: hidden;
    text-align: center;

    @media (max-width: 700px) {
      display: block;
      max-width: 100vw;
      overflow: hidden;
    }

    ${mediaQuery.mediumScreen} {
      display: block;
    }
  }

  .content-box {
    flex: 2;
    ${mediaQuery.mediumScreen} {
      padding-right: 50px;
      padding-bottom: 0;
    }

    @media (max-width: 700px) {
      flex: 1;
      padding: 0 0 20px 0;
    }

    h2 {
      color: #fff;
      font-size: 2.2rem;
      font-weight: bold;
      margin-top: 1rem;
      text-align: left;
    }
  }
`;
