import {
  CardTagged,
  DonutPopulated as Donut,
  ServiceBox,
  Table
} from '@/components/shared';
import { Text, ColorText } from '@/components/styled';
import { useBiggest, useDashboardFilter } from '@/hooks';
import {
  useZonesData,
  useExternalWallData
} from '@/servicesHooks/livingConditionAgents';

import { colors } from '@/theme/colors';
import { useMemo } from 'react';

import { Grid, GridItem } from './styles';

const THICKNESS = 20;
const { yellow500, red400, green200 } = colors;

export function LivingConditionRightSection() {
  const { tag } = useDashboardFilter();

  const { zones, loading: loadingZones, error: errorZones } = useZonesData();

  const dataZones = useMemo(() => {
    return zones.map(d => {return {...d, name:`Zona ${d.name}` }})
                .filter((zone) => zone.name !== 'null')
  }, [zones])

  const {
    externalWallMaterials,
    loading: loadingExternalWallMaterials,
    error: errorExternalWallMaterials
  } = useExternalWallData();

  const tableColumns = [
    { name: 'Material', key: 'name' },
    { name: 'Porcentagem', key: 'value' }
  ];

  const biggestZone = useBiggest(zones);
  const biggestExternalWallMaterial = useBiggest(externalWallMaterials);

  return (
    <Grid>
      <GridItem area="a">
        <CardTagged tag={tag}>
          <Text>
            <strong>Onde estão localizadas essas famílias?</strong>
          </Text>

          <ServiceBox
            empty={zones.length === 0}
            loading={loadingZones}
            error={errorZones}
          >
            <Text>
              Entre as famílias visitadas,{' '}
              <ColorText>
                {biggestZone?.value}% moram em zona{' '}
                {biggestZone?.name.toLowerCase()}
              </ColorText>
              , como mostra o gráfico a seguir
            </Text>

            <Donut
              percentage
              data={dataZones}
              colors={[green200, yellow500, red400]}
              exactColors={true}
              label="famílias"
              thickness={THICKNESS}
              flexDirection="column"
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>

      <GridItem area="b">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              Quais os materiais predominantes na construção das paredes
              externas nos domicílios?
            </strong>
          </Text>

          <ServiceBox
            empty={externalWallMaterials.length === 0}
            loading={loadingExternalWallMaterials}
            error={errorExternalWallMaterials}
          >
            <Text>
              Entre as familias visitadas,{' '}
              <ColorText>
                {biggestExternalWallMaterial?.value}% moram em imóveis de
                {' ' + biggestExternalWallMaterial?.name}
              </ColorText>{' '}
              , como mostra a tabela a seguir.
            </Text>

            <Table data={externalWallMaterials} columns={tableColumns} percentage/>
          </ServiceBox>
        </CardTagged>
      </GridItem>
    </Grid>
  );
}
