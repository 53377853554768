import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

const CURRENT_YEAR = new Date().getFullYear();
const CREDITS = `Todos os direitos reservados`;

interface CopyrightProps {
  application?: string;
}

export const Copyright = (props: CopyrightProps) => {
  const appText = props.application ? props.application + `. ` : ``;
  return (
    <Wrapper>
      {CURRENT_YEAR} &copy; {appText}
      <Credits>{CREDITS}</Credits>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px 0 30px 0;
  font-size: 0.8rem;
  text-align: center;
  text-rendering: geometricPrecision;
  opacity: 0.6;
  color: ${(props) => props.theme.text};
`;

const Credits = styled.span`
  ${mediaQuery.smallScreen} {
    display: block;
  }
`;
