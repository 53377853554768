import { Action } from 'big-data';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { actions as actionsService } from '@/services';
import { useAction } from '@/hooks';

type ActionStatus = 'UNDONE' | 'BLOCKED' | 'IN_PROGRESS' | 'DONE';

type refreshActionByStatusParams = {
  axle: string;
  indicator: string;
  city: string;
  page: number;
  searchTitle?: string;
};

export function useUnifiedActions(status: ActionStatus, searchTitle?: string) {
  const { ibge, indicator } = useAction();

  const [actions, setActions] = useState<Action[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [planActionsTotal, setPlanActionsTotal] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [searchParams] = useSearchParams();
  const axle = searchParams.get('axe') ?? '';

  const refresh = useCallback(
    ({ axle, city, indicator, page }: refreshActionByStatusParams) => {
      setLoading(true);
      actionsService
        .getActions(axle, city, indicator, page, 5, status, searchTitle)
        .then((action) => {
          if (page === 0) {
            setActions(action.content);
          } else {
            setActions((current) => [...current, ...action.content]);
          }
          setPlanActionsTotal(action.total);
          setNumberOfPages(action.numberOfPages);
        })
        .catch((err) => setError(err.message))
        .finally(() => setLoading(false));
    },
    [status, searchTitle]
  );

  useEffect(() => {
    refresh({ axle, city: ibge, indicator, page: 0 });
  }, [axle, ibge, indicator, refresh, searchTitle]);

  return {
    actions,
    loading,
    error,
    refresh,
    planActionsTotal,
    numberOfPages
  };
}
