export function VisualImpairmentIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.06404 0C5.85747 0 4.87933 0.97812 4.87933 2.18471C4.87933 3.39128 5.85747 4.36942 7.06404 4.36942C8.27063 4.36942 9.24877 3.39128 9.24877 2.18471C9.24877 0.97812 8.27063 0 7.06404 0ZM5.24857 5.49474L2.90084 8.66453C2.80693 8.78973 2.76011 8.92217 2.76011 9.06303L2.47866 11.6692C2.35164 12.8062 3.97764 13.069 4.16877 11.8336L4.40355 9.41552L5.01383 8.59415L5.03751 11.998L3.88733 16.1292L0.952993 19.8619C0.0998154 21.1899 1.86319 22.1788 2.76015 21.1298L5.81221 17.2091C5.89417 17.0987 5.94492 16.9511 5.99961 16.8106L6.91572 13.5704L8.98134 15.8944L10.1078 21.1537C10.4188 22.5577 12.5783 22.0742 12.3148 20.7309L11.1182 15.261C11.0897 15.1201 11.0587 14.9639 10.9308 14.8618L8.39561 11.9742V8.40585L9.38139 9.65008C9.49095 9.79093 9.65553 9.89261 9.87461 9.95522L12.1987 10.4011L20.8138 21.7399C20.9676 21.9538 21.4029 21.7407 21.2366 21.4585L13.2081 10.4243C14.1521 10.1078 13.6425 9.03243 13.02 8.92162L10.5315 8.42908L7.97268 5.21257C7.16222 4.4077 5.80683 4.60552 5.24945 5.49402L5.24857 5.49474Z"
        fill="white"
      />
    </svg>
  );
}
