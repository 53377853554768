import { Action } from 'big-data';

import { Box } from './styles';
import { CityTag } from '../Tags';

interface GoalsItemProps {
  action: Action;
  axe?: string;
  onClick?: (action: Action) => void;
}

export function GoalsItem({ action, axe, onClick }: GoalsItemProps) {
  function handleClick() {
    if (onClick) {
      onClick(action);
    }
  }

  return (
    <Box
      onClick={handleClick}
      to={`/dashboard/gestores-acoes/planos-de-acao/${action.id}`}
    >
      <h3 className="title">{action.title}</h3>

      <span className="indicator">{action.indicator}</span>

      <CityTag city={action.city} />
    </Box>
  );
}
