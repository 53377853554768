import { useEffect } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Pagination as StyledPagination } from './styles';

export interface PaginationProps {
  page: number;
  initialPage?: number;
  totalPages: number;
  onPaginate(page: number): void;
  resetOnChange?: string;
}

export function SimplePagination({
  onPaginate,
  page,
  initialPage = 0,
  totalPages,
  resetOnChange
}: PaginationProps) {
  const nextButtonDisabled = totalPages === 0 || page === totalPages;
  const prevButtonDisabled = page === initialPage;

  function paginatePrev() {
    onPaginate(page - 1);
  }

  function paginateNext() {
    onPaginate(page + 1);
  }

  useEffect(() => {
    if (resetOnChange) onPaginate(initialPage);
  }, [resetOnChange]); // eslint-disable-line

  return (
    <StyledPagination>
      <button
        onClick={paginatePrev}
        disabled={prevButtonDisabled}
        aria-label="Anterior"
        aria-disabled={prevButtonDisabled}
      >
        <RiArrowLeftSLine size={18} />
      </button>

      <button
        onClick={paginateNext}
        disabled={nextButtonDisabled}
        aria-label="Próximo"
        aria-disabled={nextButtonDisabled}
      >
        <RiArrowRightSLine size={18} />
      </button>
    </StyledPagination>
  );
}
