import { FeedbackButton } from '@/components/styled';
import { FormEvent, useEffect, useState } from 'react';
import { Show } from '..';
import { AntModal } from '../AntModal';
import { FeedbackStatusBox } from '../FeedbackStatusBox';

import { Box, ColorBoxButton } from './styles';

import closeImg from '@/assets/icons/close-feedback.svg';
import { platformSatisfaction, ManagerLoginProps } from '@/services';
import { Loading } from '../Loading';
import dayjs from 'dayjs';
import type { AxiosError } from 'axios';
import axios from 'axios';

interface PlatformSatisfactionProps {
  data?: ManagerLoginProps;
  error: Error | null;
}

const colorBoxes = [
  '#B72224',
  '#D52029',
  '#E85223',
  '#EA6F22',
  '#F6A726',
  '#FDC729',
  '#EBDB0A',
  '#E5E044',
  '#C3D137',
  '#AECB3A',
  '#68B354'
];

export function PlatformSatisfaction({
  data,
  error
}: PlatformSatisfactionProps) {
  const [isSatisfactionModalOpen, setIsSatisfactionModalOpen] = useState(false);
  const [satisfactionValue, setSatisfactionValue] = useState<number>();
  const [satisfactionReason, setSatisfactionReason] = useState<string>();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reportError, setReportError] = useState<AxiosError | null>(null);

  const submitButtonDisabled = satisfactionValue === undefined;

  async function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (satisfactionValue === undefined) return;

    setIsSubmitting(true);

    const data = {
      comment: satisfactionReason,
      rate: satisfactionValue
    };

    try {
      await platformSatisfaction.report(data);
      setIsSubmittedSuccessfully(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setReportError(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function onLoginCount() {
    if (error) {
      setIsSatisfactionModalOpen(false);
      return;
    }

    const numberOfLogins = data?.numberOfLogins ?? 0;
    const ratingDate = data?.ratingDate;

    const firstAppear = numberOfLogins >= 10 && !ratingDate;

    if (firstAppear) setIsSatisfactionModalOpen(true);

    if (ratingDate) {
      const appearasons = dayjs().diff(ratingDate, 'month');

      if (appearasons >= 3) setIsSatisfactionModalOpen(true);
    }
  }

  useEffect(() => {
    onLoginCount();
  }, []); // eslint-disable-line

  return (
    <AntModal
      visible={isSatisfactionModalOpen}
      onCancel={() => setIsSatisfactionModalOpen(false)}
      width={600}
      closeIcon={<img src={closeImg} alt="Fechar" />}
    >
      <Show
        when={!isSubmittedSuccessfully && !error}
        fallback={
          <Show
            when={!error}
            fallback={
              <FeedbackStatusBox
                type="error"
                message={reportError?.response?.data.message}
                onClick={() => {
                  setIsSubmittedSuccessfully(false);
                  setReportError(null);
                }}
              />
            }
          >
            <FeedbackStatusBox type="success" />
          </Show>
        }
      >
        <Box>
          <h1>Fidelidade da Plataforma</h1>

          <form className="satisfaction-form" onSubmit={onSubmit}>
            <div className="control-satisfaction-values">
              <label htmlFor="satisfaction-value" className="faithfulness-text">
                Considerando a sua experiência com o Big Data Social, o quanto
                você recomendaria a plataforma para um amigo ou colega de
                trabalho?
              </label>

              <div className="satisfaction-values">
                {colorBoxes.map((color, index) => (
                  <ColorBoxButton
                    name="satisfaction-value"
                    key={color}
                    type="button"
                    bg={color}
                    value={index}
                    onClick={() => setSatisfactionValue(index)}
                    readOnly={true}
                    selected={satisfactionValue === index}
                  />
                ))}
              </div>
            </div>

            <div className="control">
              <label htmlFor="satisfaction-note-reason">
                Em poucas palavras, descreva o que motivou a sua nota
              </label>
              <textarea
                name="satisfaction-note-reason"
                placeholder="Escreva aqui sua resposta..."
                className="satisfaction-answer"
                value={satisfactionReason}
                onChange={({ target }) => setSatisfactionReason(target.value)}
              />
            </div>

            <FeedbackButton
              type="submit"
              className="submit-reason"
              disabled={submitButtonDisabled || isSubmitting}
            >
              {isSubmitting ? (
                <Loading color="white" size={24} />
              ) : (
                `Enviar resposta`
              )}
            </FeedbackButton>
          </form>
        </Box>
      </Show>
    </AntModal>
  );
}
