import { useDashboardFilter } from '@/hooks';
import { visits, Visit } from '@/services/visits';
import { useEffect, useMemo, useState } from 'react';

export function useVisitedFamilies() {
  const [visitedFamilies, setVisitedFamilies] = useState<Visit>({} as Visit);
  const [loadingVisitedFamilies, setLoadingVisitedFamilies] = useState(false);
  const [errorVisitedFamilies, setErrorVisitedFamilies] =
    useState<Error | null>();

  const { ibge, year, semester } = useDashboardFilter();

  useEffect(() => {
    setLoadingVisitedFamilies(true);
    visits
      .getVisitedFamilies(ibge, year, semester)
      .then((data) => {
        setVisitedFamilies(data);
      })
      .catch((err) => setErrorVisitedFamilies(err))
      .finally(() => setLoadingVisitedFamilies(false));
  }, [ibge, year, semester]);

  return useMemo(
    () => ({
      visitedFamilies,
      loadingVisitedFamilies,
      errorVisitedFamilies
    }),
    [visitedFamilies, loadingVisitedFamilies, errorVisitedFamilies]
  );
}
