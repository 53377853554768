import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { GeolocationBack } from './@types';
import { getGeolocationFront } from './utils';

const ageGroupFilter: Record<string, string> = {
  CRECHE: 'ZERO_A_TRES',
  PRE_ESCOLA: 'TRES_A_SEIS',
  ESCOLA: 'SEIS_A_OITO'
};

const service = () => {
  const resource = `/educacao-agentes-geolocalizacao`;

  const educationLevel = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            ageGroupFilter: ageGroupFilter[filter],
            daycareSituation: 'Não',
            preSchoolSituation: 'Não',
            schoolSituation: 'Não',
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<GeolocationBack[]>(() =>
          mainApi.get(path, options)
        );

        return getGeolocationFront(response.data);
      }
    );

  return Object.freeze({ educationLevel });
};

export const education = service();
