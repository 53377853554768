import { actions } from '@/services/actions';
import { useEffect, useMemo, useState } from 'react';

type PlanActionAnalytics = {
  title: string;
  status: string;
  axle: string;
  indicator: string;
  city: string;
  ibge: string;
  id: number;
};

const PAGE_SIZE = 10;

export function useDataPlanActionAnalytics(
  ibge: string,
  page: number,
  status?: string
) {
  const [data, setData] = useState<PlanActionAnalytics[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (!!ibge) {
      setLoading(true);
      actions
        .getActions(null, ibge, undefined, page, PAGE_SIZE, status)
        .then((data) => {
          setTotalPages(data.numberOfPages);
          return data;
        })
        .then((data) => data.content)
        .then((contents) => {
          const parsedContent = contents.map((content) => {
            return {
              id: content.id,
              title: content.title,
              axle: content.axle,
              status: content.status,
              indicator: content.indicator,
              city: content.city,
              ibge: content.ibge
            };
          });

          setData(parsedContent);
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ibge, page, status]);

  return useMemo(
    () => ({
      data,
      loading,
      error,
      totalPages
    }),
    [data, loading, error, totalPages]
  );
}
