import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Image = styled.img`
  margin-right: 20px;

  width: 60px;
  border-radius: 100%;
  height: 60px;
  background: ${(props) => (props.theme.darkMode ? '#000' : colors.ciano700)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
`;

export const TextIcon = styled.span`
  width: 60px;
  border-radius: 100%;
  height: 60px;
  background: ${(props) => (props.theme.darkMode ? '#000' : colors.ciano700)};
  margin-right: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
`;

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
`;

export const Text = styled.p`
  margin-top: 8px;
  color: ${(props) => (props.theme.darkMode ? `#fff` : colors.gray700)};

  font-weight: 700;
  line-height: 21px;

  .percentage {
    font-size: 1.2rem;
    color: ${(props) => (props.theme.darkMode ? `#fff` : colors.ciano700)};
  }

  .label {
    font-size: 1rem;
  }
`;
