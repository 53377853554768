export function MultipleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.525C9.47381 21.525 7.05109 20.5215 5.26481 18.7352C3.47852 16.9489 2.475 14.5262 2.475 12C2.475 9.47381 3.47852 7.05109 5.26481 5.26481C7.05109 3.47852 9.47381 2.475 12 2.475C14.5262 2.475 16.9489 3.47852 18.7352 5.26481C20.5215 7.05109 21.525 9.47381 21.525 12C21.525 14.5262 20.5215 16.9489 18.7352 18.7352C16.9489 20.5215 14.5262 21.525 12 21.525ZM12 23.1C14.9439 23.1 17.7672 21.9305 19.8489 19.8489C21.9305 17.7672 23.1 14.9439 23.1 12C23.1 9.0561 21.9305 6.23277 19.8489 4.15111C17.7672 2.06946 14.9439 0.9 12 0.9C9.0561 0.9 6.23277 2.06946 4.15111 4.15111C2.06946 6.23277 0.9 9.0561 0.9 12C0.9 14.9439 2.06946 17.7672 4.15111 19.8489C6.23277 21.9305 9.0561 23.1 12 23.1Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M12.5568 6.63065C12.4092 6.48297 12.2089 6.4 12 6.4C11.7911 6.4 11.5908 6.48297 11.4432 6.63065C11.2955 6.77834 11.2125 6.97864 11.2125 7.1875V11.2125H7.1875C6.97864 11.2125 6.77834 11.2955 6.63065 11.4432C6.48297 11.5908 6.4 11.7911 6.4 12C6.4 12.2089 6.48297 12.4092 6.63065 12.5568C6.77834 12.7045 6.97864 12.7875 7.1875 12.7875H11.2125V16.8125C11.2125 17.0214 11.2955 17.2217 11.4432 17.3693C11.5908 17.517 11.7911 17.6 12 17.6C12.2089 17.6 12.4092 17.517 12.5568 17.3693C12.7045 17.2217 12.7875 17.0214 12.7875 16.8125V12.7875H16.8125C17.0214 12.7875 17.2217 12.7045 17.3693 12.5568C17.517 12.4092 17.6 12.2089 17.6 12C17.6 11.7911 17.517 11.5908 17.3693 11.4432C17.2217 11.2955 17.0214 11.2125 16.8125 11.2125H12.7875V7.1875C12.7875 6.97864 12.7045 6.77834 12.5568 6.63065Z"
        fill="white"
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
}
