import { createGlobalStyle } from 'styled-components';
import { antdOverrides } from './antd-overrides';
import { fontFaces } from './fontFaces';
import { fontFamily } from './fontsFamily';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
  }

  // to prevent IOS issues
  ${fontFaces.IBMSans}
  ${fontFaces.Anton}

  html {
    font-size: ${({ theme }) => `${16 + theme.font.level}px`};
    scroll-behavior: smooth;

    @media (max-width: 1080px) {
      font-size: 93.75%
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  p {
    margin: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    height: 100%;
  }

  body, input, textarea, button {
    ${fontFamily.IBMSans};
    background: ${({ theme }) => theme.background};
    font-weight: 500;
  }


  button { 
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#2D4040`)};
    margin: 0;
  }

  [disabled] { 
    opacity: 0.6;
    cursor: not-allowed;
  }

  .czrm-modal, .action-plan-modal, .use-terms-modal  {
    overflow: hidden;
    
    .ant-modal-content {
      border-radius: 16px;
    }
  }

  ${antdOverrides}
`;
