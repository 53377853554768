import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

// #4c5469
// #3d5164
// #2d4040
// #2d4040ad

export const Box = styled.div`
  display: flex;
  margin-top: 1.5rem;

  justify-content: space-around;

  .map {
    order: 1;
    position: relative;
    left: 20px;
    z-index: 1;

    .city {
      stroke: #3d5164;
      fill: #fff;
      stroke-width: 0.2px;
      cursor: pointer;
    }
  }

  .right-map-content {
    order: 2;
    position: relative;
    text-align: right;
    max-width: 250px;

    .tips {
      color: ${(props) => (props.theme.darkMode ? `#FFF` : `#3D5164`)};
      font-size: 0.9rem;
    }

    .legend {
      color: ${(props) => (props.theme.darkMode ? `#FFF` : `#3D5164`)};
      margin-top: 1.5rem;

      h3 {
        color: inherit;
      }

      ul {
        list-style: none;

        li {
          span {
            color: ${(props) => (props.theme.darkMode ? `#FFF` : `#3D5164`)};
          }
        }
      }
    }
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;

    .right-map-content {
      align-self: flex-end;
      max-width: initial;
      width: 100%;

      .label-main {
        left: 0;
      }
    }
  }

  @media (max-width: 400px) {
    .map {
      width: 250px;
      height: 250px;
    }
  }
`;

const legendTextDark = css`
  color: #fff;
`;

interface LegendTextProps {
  selected?: boolean;
  color: string;
  $stroke?: boolean;
}

export const LegendText = styled.span<LegendTextProps>`
  transition: opacity 0.2s;

  opacity: ${({ selected }) => {
    if (selected === undefined) return 1;

    return selected ? 1 : 0.2;
  }};

  &::after {
    content: '';
    display: inline-block;
    background-color: ${({ color }) => color};
    width: 15px;
    height: 15px;

    border-radius: 0.2rem;
    transform: translateY(3px);
    margin-left: 0.5rem;

    border: ${(props) => (props.$stroke ? '#d2d2d2 solid 1px' : 'none')};
  }

  ${({ theme }) => theme.darkMode && legendTextDark}
`;
