import { useEffect } from 'react';
import { Card, Donut } from '@/components/shared';
import { Section } from '@/layout';
import { useService } from '@/hooks';
import { Data } from 'big-data';
import { livingCondition } from '@/services/livingCondition';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function ExternalSituation() {
  const { data, loading, error, request } = useService<Data>();
  const { city, zone } = useAnalyticsFilter();

  useEffect(() => {
    request(() => livingCondition.getExternalFloor(city, zone));
  }, [request, city, zone]);

  return (
    <Section>
      <Card
        title="Existência e situação do calçamento das residências"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ?? 'CEARÁ', 'INSCRITOS NO CADÚNICO']}
      >
        <Donut
          thickness={30}
          data={data}
          colors={['#55A546', '#FFCA16', '#ff9800']}
        />
      </Card>
    </Section>
  );
}
