import { useDashboardFilter } from '@/hooks';
import { education } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useNurseryData() {
  const [nursery, setNursery] = useState<Data[]>([]);
  const [nurseryError, setNurseryError] = useState<Error | null>(null);
  const [nurseryLoading, setNurseryLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    const hasFilter = false;
    setNurseryLoading(true);

    education
      .getNursery({
        hasFilter,
        variants: ['pública', 'particular'],
        ibge,
        year,
        semester,
        zone
      })
      .then((response) => {
        const total = response.reduce((acc, cur) => acc + cur.value, 0);

        const sumSchoolMode = response.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.schoolMode.toLowerCase()]:
              (acc[cur.schoolMode.toLowerCase()] ?? 0) + cur.value
          };
        }, {} as Record<string, number>);

        setNursery(
          Object.entries(sumSchoolMode)
            .map(([key, value]) => ({
              name: 'estão matriculados na creche ' + key,
              value: value / total
            }))
            .sort((a, b) => b.value - a.value)
        );
      })
      .catch((error) => setNurseryError(error))
      .finally(() => setNurseryLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(() => {
    return {
      nursery,
      nurseryError,
      nurseryLoading
    };
  }, [nursery, nurseryError, nurseryLoading]);
}
