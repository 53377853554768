import {
  CardTagged,
  DonutPopulated,
  HorizontalGridBar,
  ServiceBox,
  Show,
  D3Map
} from '@/components/shared';

import { DashboardInsightProps, Data, DataWithCity } from 'big-data';
import { useMemo } from 'react';

import { ColorText, Text, TextI } from '@/components/styled';
import { useAuth, useDashboardFilter } from '@/hooks';
import {
  // useBenefitsData,
  useCityData,
  useNurseryData,
  usePreschoolData,
  useQuantity,
  useSchoolData,
  // useTotalValueData
} from '@/servicesHooks/education';
import { colors } from '@/theme/colors';
import { Margin } from '@/theme/utils';
import { dotsNumber } from '@/utils/string';

import { modifierColors, resolveMapColor } from '../../utils';

import { OutSchoolReason, EducationPeriod, EducationParentInterest } from '..';

import { Box, ChartBox } from './styles';
import { useTheme } from 'styled-components';

const DONUT_THICKNESS = 20;
const DONUT_HIGHLIGHT = (data: Data) => data.name === 'pública';

export function EducationInsights({ selected }: DashboardInsightProps) {
  const theme = useTheme();
  const color = selected ? modifierColors.get(selected.color) : undefined;
  const nameSelected = selected?.name
    .toLowerCase()
    .replace('não frequenta', '');

  const COLORS = [color ?? colors.green200, '#969CA4'];

  const { city, tag } = useDashboardFilter();

  const { isAdm } = useAuth()

  const { cities, citiesError, citiesLoading } = useCityData(
    nameSelected,
    true
  );

  const { quantity } = useQuantity(nameSelected);

  const { nursery, nurseryError, nurseryLoading } = useNurseryData();
  const { preschool, preschoolError, preschoolLoading } = usePreschoolData();

  const { school, schoolError, schoolLoading } = useSchoolData();

  const citiesData = useMemo(() => {
    return cities.map((d) => {
      return {
        name: d.name,
        value: d.value,
        city: d.city
      } as DataWithCity;
    });
  }, [cities]);

  return (
    <Box>
      <CardTagged
        tag={tag}
        color={color}
        title="Crianças fora da escola pública
"
      >
        <ServiceBox
          empty={cities.length === 0}
          loading={citiesLoading}
          error={citiesError}
        >
          <Text>
            Qual a porcentagem de crianças fora da escola pública nas famílias
            visitadas?
          </Text>
          <Text>
            <ColorText color={color}>{dotsNumber(quantity)}</ColorText> famílias
            em situação de vulnerabilidade social têm crianças que não vão à
            escola pública.
          </Text>

          <D3Map
            color={resolveMapColor(!!selected, selected?.color ?? '', theme)}
            filteringCity={city}
            data={cities}
            percentage={true}
            mapDescription="Passe o mouse pelo mapa para visualizar a porcentagem de famílias visitadas com crianças fora da escola pública por município.
            "
          />
        </ServiceBox>
      </CardTagged>

      <Show when={!selected}>
        <OutSchoolReason color={color} />
      </Show>

      <Show when={!selected}>
        <EducationParentInterest color={color} />
      </Show>

      <CardTagged
        tag={tag}
        color={color}
        title="Instituições de ensino das crianças matriculadas
"
      >
        {!nurseryLoading && !preschoolLoading && (
          <Text>
            <strong>
              Qual a porcentagem de crianças matriculadas na creche, pré-escola
              ou escola pública?
            </strong>
          </Text>
        )}

        <ChartBox selected={!!selected}>
          <Show when={!nameSelected || nameSelected === 'creche'}>
            <ServiceBox
              empty={nursery.length === 0}
              error={nurseryError}
              loading={nurseryLoading}
            >
              <DonutPopulated
                data={nursery}
                thickness={DONUT_THICKNESS}
                label={`matriculados`}
                subLabel="na creche"
                flexDirection="column"
                donutWidth={250}
                colors={COLORS}
                initialRenderData={nursery[0]}
                exactColors
                percentage
                highlight={DONUT_HIGHLIGHT}
                noFilter
              />
            </ServiceBox>
          </Show>
          <Show when={!nameSelected || nameSelected === 'pré-escola'}>
            <ServiceBox
              empty={preschool.length === 0}
              error={preschoolError}
              loading={preschoolLoading}
            >
              <DonutPopulated
                data={preschool}
                thickness={DONUT_THICKNESS}
                label={`matriculados`}
                subLabel="na pré-escola"
                flexDirection="column"
                colors={COLORS}
                initialRenderData={preschool[0]}
                exactColors
                donutWidth={250}
                percentage
                highlight={DONUT_HIGHLIGHT}
                noFilter
              />
            </ServiceBox>
          </Show>

          <Show when={!nameSelected || nameSelected === 'escola'}>
            <ServiceBox
              empty={school.length === 0}
              error={schoolError}
              loading={schoolLoading}
            >
              <DonutPopulated
                data={school}
                thickness={DONUT_THICKNESS}
                label={`matriculados`}
                subLabel="na escola"
                flexDirection="column"
                colors={COLORS}
                initialRenderData={school[0]}
                exactColors
                donutWidth={250}
                percentage
                highlight={DONUT_HIGHLIGHT}
                noFilter
              />
            </ServiceBox>
          </Show>
        </ChartBox>
      </CardTagged>

      <div
        className={
          !!nameSelected && nameSelected !== 'escola' ? 'h-bar grid-2' : 'h-bar'
        }
      >
        <CardTagged
          tag={tag}
          color={color}
          title={isAdm ? "Municípios com mais crianças fora da escola pública" : "Crianças fora da escola pública"}
        >
          <ServiceBox
            empty={cities.length === 0}
            error={citiesError}
            loading={citiesLoading}
          >
            <Text>
              {
                isAdm ? (
                  "Quais os municípios com mais crianças fora da educação básica?"
                ) : (
                  "Qual a porcentagem de crianças fora da educação básica?"
                )
              }
            </Text>

            <TextI>
              {
                isAdm ? (
                  "Passe o mouse pelo gráfico de barras para visualizar a porcentagem por município."
                ) : (
                  "Passe o mouse pelo gráfico de barras para visualizar a porcentagem do município."
                )
              }
            </TextI>
            <Margin top={50} left={40} right={40} />
            <div
              style={{
                padding: '0 0 20px 0'
              }}
            >
              <HorizontalGridBar
                data={citiesData}
                grid
                color={theme.darkMode ? colors.ming : color ?? colors.ciano700}
                isPercentage
                percentageCalculatedOut
                hasPagination
              />
            </div>
          </ServiceBox>
        </CardTagged>
      </div>

      <Show when={nameSelected !== 'escola'}>
        <EducationPeriod color={color} selected={nameSelected as any} />
      </Show>
    </Box>
  );
}
