import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled, { keyframes } from 'styled-components';

const showBottom = keyframes`
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70vh;

  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.background};
  z-index: 10;

  .feedback {
    height: inherit;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg,
    h2,
    p {
      animation: ${showBottom} 1s linear forwards;
    }

    h2 {
      text-align: center;
    }

    svg {
      width: 150px;
      height: 150px;

      @media (max-width: 900px) {
        width: 100px;
        height: 100px;
      }
    }

    h2 {
      font-size: 4rem;
      color: ${colors.green700};

      @media (max-width: 900px) {
        font-size: 2rem;
      }

      @media (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 1.5rem;

      @media (max-width: 500px) {
        font-size: 1.2rem;
      }
    }
  }

  .buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    margin-block-start: 1.4rem;

    animation: ${showBottom} 1s 0.2s linear forwards;

    button {
      border: none;
      border-radius: 7px;
      color: ${colors.green600};
      text-decoration: underline;

      padding: 0.5rem 4rem;

      transition: all 0.2s;

      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        color: ${colors.green200};

        &:active {
          transform: scale(0.98);
        }
      }
    }

    a {
      border: none;
      border-radius: 7px;
      background-color: ${colors.green300};
      color: ${colors.white};
      padding: 0.5rem 4rem;

      transition: all 0.2s;

      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        filter: brightness(0.9);
        color: ${colors.white};

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }

  ${mediaQuery.smallScreen} {
    height: 100vh;

    .feedback {
      position: absolute;
      top: -50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
