import { Section } from '@/layout';
import { Card, DonutPopulated } from '@/components/shared';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { useEffect, useMemo } from 'react';

import { useService } from '@/hooks';
import { Data } from 'big-data';
import { socialMovements } from '@/services/socialMovements';

export function MovementsLinks() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.bondMovements());
  }, [request]);

  const formatedData = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        color:
          d.name === 'Estão vinculados a alguma rede ou fórum'
            ? colors.green200
            : colors.red500
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Vínculos dos movimentos sociais: fóruns, redes e outros"
        empty={data.length === 0}
        loading={loading}
        error={error}
        tags={[city ?? 'Ceará']}
      >
        <DonutPopulated
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedData}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
