import { colors } from '@/theme/colors';
import styled, { css } from 'styled-components';
import { Select as AntdSelect } from 'antd';
import { mediaQuery } from '@/theme/mediaQuery';

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;

  .icon-container {
    background: #fafafc;
    border-radius: 7px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  button.clear {
    display: flex;
    align-items: center;
    gap: 4px;

    background: transparent;
    border: 0;
    color: ${colors.ciano700};
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: bold;

    ${({ theme }) =>
      theme.darkMode &&
      css`
        color: ${theme.yellowDark};
        text-decoration: underline;

        svg path {
          fill: ${theme.yellowDark};
        }
      `}
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  ${mediaQuery.smallScreen} {
    flex-wrap: wrap;
  }
`;

export const Select = styled(AntdSelect).attrs({
  bordered: false,
  allowClear: true
})`
  border: 1px solid #d8d8d8;
  border-radius: 7px;

  min-width: 150px;
  height: 32px;
`;
