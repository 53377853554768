import { DataWithCity } from 'big-data';

export function getCityRecord(data: DataWithCity[]) {
  return data.reduce((acc, cur) => {
    const ibge = cur.codIbge ?? cur.name;
    return {
      ...acc,
      [ibge]: cur.value
    };
  }, {} as Record<string, number>);
}
