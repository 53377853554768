import styled from 'styled-components';
import { Data } from 'big-data';

import { CardTagged, DonutPopulated, ServiceBox } from '@/components/shared';
import { TitleText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import { usePregnantBook } from '@/servicesHooks/healthPregnant';
import { colors } from '@/theme/colors';

const COLORS = [colors.green200, colors.red400, colors.yellow200, colors.brown];

const DONUT_THICKNESS = 20;
const DONUT_HEIGHT = 300;

const generateColorsBySize = (data?: Data[]) => {
  if (!data) return COLORS;
  return data.map((_, i) => COLORS[i]);
};

export function CardPregnantDonut() {
  const { tag } = useDashboardFilter();

  const { data, ...rest } = usePregnantBook();
  const [pregnantBookData, prenatalData] = data;
  const getPregnantYes = pregnantBookData?.find((data) => data.name === 'Sim');
  const getPrenatalYes = prenatalData?.find((data) => data.name === 'Sim');

  return (
    <CardTagged tag={tag}>
      <ServiceBox {...rest}>
        <TitleText>
          Qual a porcentagem de grávidas que possuem a caderneta da gestante nos
          domicílios visitados? E qual a porcentagem de grávidas que fizeram o
          acompanhamento pré-natal?
        </TitleText>

        <ChartBox>
          <DonutPopulated
            data={pregnantBookData}
            label="Possuem caderneta"
            subLabel="da gestante"
            thickness={DONUT_THICKNESS}
            flexDirection="column"
            percentage={true}
            highlight={(data) => data.name === 'Sim'}
            exactColors={true}
            initialRenderData={getPregnantYes}
            donutWidth={250}
            donutHeight={DONUT_HEIGHT}
          />

          <DonutPopulated
            data={prenatalData}
            label="Acompanhadas no"
            subLabel="pré-natal"
            thickness={DONUT_THICKNESS}
            flexDirection="column"
            percentage={true}
            highlight={(data) => data.name === 'Sim'}
            initialRenderData={getPrenatalYes}
            colors={generateColorsBySize(prenatalData)}
            exactColors
            donutWidth={250}
            donutHeight={DONUT_HEIGHT}
          />
        </ChartBox>
      </ServiceBox>
    </CardTagged>
  );
}

const ChartBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 890px) {
    flex-wrap: wrap;
  }
`;
