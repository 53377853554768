import { BarSimple, Card } from '@/components/shared';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';

export function Card9() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getChildren(ibge));
  }, [request, ibge]);

  return (
    <Card
      title="Quantidade de filhos das mulheres atendidas"
      tags={[city ?? 'CEARÁ']}
      error={error}
      empty={data.length === 0}
      loading={loading}
    >
      <BarSimple data={data} makeValuesBetweenZeroAndOne alwaysShowValue />
    </Card>
  );
}
