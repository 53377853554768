import { useEffect, useMemo, useState } from 'react';

import { schoolingAdult } from '@/services';
import { DataProps } from 'big-data';
import { resolveFilters } from '../resolvedFilter';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function useData() {
  const { gender, colorSkinType, ibge } = useAnalyticsFilter();

  const [data, setData] = useState<DataProps[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const slicedDataWithtotal = useMemo(() => {
    const slicedData = data
      .sort((a, b) => b.value - a.value)
      .filter((d) => (!!gender ? d.sex === gender : d))
      .filter((d) => (!!colorSkinType ? d.skinColor === colorSkinType : d))
      .filter((d) => (!!ibge ? String(d.codIbge) === ibge : d));

    return { slicedData };
  }, [data, ibge, gender, colorSkinType]);

  const { slicedData } = slicedDataWithtotal;

  useEffect(() => {
    setDataLoading(true);
    schoolingAdult
      .getMapResults(resolveFilters([gender ?? '', colorSkinType ?? '']))
      .then((res) => setData(res))
      .catch((err) => setError(err))
      .finally(() => setDataLoading(false));
  }, [gender, colorSkinType]);

  return {
    top17: slicedData,
    loading: dataLoading,
    error
  };
}
