import styled, { css } from 'styled-components';

import { colors } from '@/theme/colors';
import { Card } from '../styles';

export const Box = styled(Card)`
  height: 100%;

  .forum-details-body {
    margin: 1rem 2rem;

    ul.ods {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      background-color: ${colors.gray100};
      border-radius: 7px;
      border-left: 5px solid ${colors.yellow600};
      padding: 1rem;

      list-style: none;

      @media (max-width: 590px) {
        flex-direction: column;
        align-items: baseline;
      }

      li {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        span {
          display: inline-block;
          max-width: 180px;
        }
      }
    }
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      .forum-details-body {
        ul.ods {
          background-color: ${({ theme }) => theme.background};
          color: ${colors.white};
          border-color: ${colors.white};
        }
      }
    `}
`;

export const Label = styled.span`
  display: block;
  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray700)};
  font-weight: 700;
  margin: 1rem 0 0;
`;

export const Answer = styled.span`
  display: inline-block;
  background-color: ${colors.gray100};
  color: ${colors.gray700};
  padding: 0.4rem 1rem;
  border-radius: 7px;

  ${({ theme }) =>
    theme.darkMode &&
    css`
      background-color: ${({ theme }) => theme.background};
      color: ${colors.white};
      border: 1px solid ${colors.white};
    `}
`;

export const DownloadButton = styled.button`
  display: inline-block;
  border: none;
  background-color: ${colors.gray100};
  color: ${colors.gray700};
  padding: 0.4rem 1rem;
  border-radius: 7px;
`;

export const Horizontal = styled.div`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;
