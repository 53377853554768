import { Apresentation } from '@/layout';
import { BigDataRoute } from '@/types/routes';
import { Error } from './Error';

import { Home } from './Home';
import { MapSite, PrivacityWarn, UseTerms } from './Infos';

export const routes: BigDataRoute[] = [
  {
    path: '/',
    Element: () => <Home />,
    iconImg: () => null,
    name: 'Início'
  },
  {
    path: `/info`,
    Element: () => <Apresentation />,
    name: 'Início',
    iconImg: () => null,
    children: [
      {
        Element: () => <UseTerms />,
        iconImg: () => null,
        name: 'Termos e Condições de Uso do Big Data Social',
        path: 'termos'
      },
      {
        Element: () => <PrivacityWarn />,
        iconImg: () => null,
        name: 'Aviso de Privacidade',
        path: 'aviso-privacidade'
      },
      {
        Element: () => <MapSite />,
        iconImg: () => null,
        name: 'Mapa do Site',
        path: 'mapa-site'
      },
      {
        Element: () => <Error />,
        iconImg: () => null,
        name: 'Erro',
        path: 'error'
      }
    ]
  }
];
