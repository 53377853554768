import { colors } from '@/theme/colors';
import styled from 'styled-components';
import { Link as RRLink } from 'react-router-dom';

export const AccordionContainer = styled.div`
  .accordion-button-control {
    position: sticky;
    top: 0;

    width: 100%;
    border: 0;
    background-color: ${({ theme }) =>
      theme.darkMode ? colors.black : colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      transition: transform 1s;
    }

    &.expanded {
      svg {
        transform: rotate(180deg);
      }
    }

    .indicators {
      display: flex;
      align-items: center;

      span {
        color: ${({ theme }) => (theme.darkMode ? colors.white : colors.black)};
      }
    }
  }

  .loader-container {
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const AccordionItem = styled(RRLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  background-color: ${({ theme }) =>
    theme.darkMode ? colors.black : colors.white};
  border: 1px solid #dfe6e9;
  border-radius: 7px;

  transition: filter 0.2s;

  .left-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    strong {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${({ theme }) =>
        theme.darkMode ? colors.white : colors.ciano700};
    }

    .status-container {
      width: max-content;

      display: flex;
      gap: 1rem;
    }
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .warning {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: #fffbe6;
      border: 1px solid #ffe58f;
      border-radius: 2px;

      padding: 0.5rem;
      color: rgba(0, 0, 0, 0.85);

      font-weight: 400;
      font-size: 0.875rem;
      line-height: 160%;
    }
  }

  &:hover {
    filter: brightness(0.9);

    .left-container {
      strong {
        text-decoration: underline;
      }
    }
  }

  & + a {
    margin-top: 1rem;
  }
`;

interface PointerProps {
  color: string;
}

export const Pointer = styled.span.attrs({
  className: 'pointer'
})<PointerProps>`
  font-weight: 700;
  line-height: 160%;
  font-size: 1.25rem;
  margin: 30px 0;

  color: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};

  display: flex;
  align-items: center;
  gap: 0.8rem;

  &:before {
    content: '';
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
  }
`;

export const CounterBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .comments-amount {
    color: ${colors.ciano700};

    font-size: 1rem;
    font-weight: 500;
    line-height: 160%;
  }
`;
