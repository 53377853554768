import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  width: 100%;

  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
  }

  strong {
    color: ${({ color }) => color};

    margin-right: 5px;
  }
`;

export const Icon = styled.div`
  g {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 45px;
  min-height: 45px;
  max-width: 45px;
  max-height: 45px;

  background-color: ${({ color, theme }) =>
    theme.darkMode ? colors.white : color};

  margin-right: 1rem;

  svg {
    width: 25px;
    height: 25px;

    path {
      fill: ${({ theme }) => (theme.darkMode ? theme.background : '#fff')};
    }
  }

  img {
    width: 25px;
    height: 25px;
    filter: ${({ theme }) => (theme.darkMode ? `invert(1)` : 'initial')};
  }
`;
