import { cois } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useTotalCoisData() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    cois
      .getTotal()
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);

  return useMemo(() => {
    return { totalData: data, totalLoading: loading, totalError: error };
  }, [data, loading, error]);
}
