import styled from 'styled-components';

export const Box = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.background : `#fff`};
`;
