import { googleAnalytics } from '@/services/googleAnaltyics';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';
const { rejection } = googleAnalytics;

type ColorProps = {
  type: 'up' | 'down';
  name: string;
};

export function useRejections(
  minimuDate?: string,
  ibge?: string,
  order = 'desc',
  page = 0,
  pageSize = 5
) {
  const [data, setData] = useState<Data<string, number, ColorProps>[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    rejection
      .get(minimuDate, ibge, order, page, pageSize)
      .then((_data) => {
        setData(_data.content);
        setTotalPages(_data.totalPages);
      })

      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [minimuDate, ibge, order, page, pageSize]);

  return {
    data,
    loading,
    error,
    totalPages
  };
}
