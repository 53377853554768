import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { illiterateAdults } from '@/services';
import { Data } from 'big-data';
import { useEffect, useState } from 'react';

export function useData() {
  const [data, setData] = useState<Data[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { ibge } = useAnalyticsFilter();
  useEffect(() => {
    setLoading(true);
    illiterateAdults
      .getAdultIlliteratesByCityAndAmount(ibge)
      .then((res) => {
        setData(res);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [ibge]);

  return {
    data,
    loading,
    error
  };
}
