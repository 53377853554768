import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { Section } from '@/layout';
import { useFilterContext } from '@/pages/Lgbt/contexts/FilterContext';
import { colorGenerator } from '@/pages/Lgbt/utils/colors';
import { useHealth } from '@/services/lgbt/health';
import { Data } from 'big-data';
import { useEffect, useMemo } from 'react';

export function HealthService() {
  const { city, orientation, ibge, identity } = useFilterContext();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() =>
      useHealth.healthServices({ ibge, orientation, genderIdentity: identity })
    );
  }, [request, orientation, ibge, identity]);

  const formatedDataDonut = useMemo(() => {
    return data.map((d) => {
      const color = colorGenerator(d.name);

      return {
        ...d,
        color
      };
    });
  }, [data]);

  return (
    <Section>
      <Card
        title="Existência de serviços municipais de promoção de saúde para pessoas LGBTQIA+"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[
          city ?? 'Ceará',
          orientation ?? 'Todas as orientações sexuais',
          identity ?? 'Todas as identidades de gênero'
        ]}
      >
        <DonutPopulated
          calculatedOut
          hiddenValue
          labelOnDonut
          donutHeight={300}
          data={formatedDataDonut}
          thickness={40}
          flexDirection="column"
          percentage
        />
      </Card>
    </Section>
  );
}
