type DebounceFn = (...args: any[]) => void;

export function debounce(fn: DebounceFn, timeout: number = 500) {
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, timeout);
  };
}
