import { colors } from '@/theme/colors';
import styled, { keyframes } from 'styled-components';

const rotate = (angle: string) => keyframes`
  100% {
    transform: rotate(${angle});
  }
`;

interface SVGProps {
  color: string;
}

export const Svg = styled.svg<SVGProps>`
  .circle {
    transform-origin: center center;
  }

  .circle-1 {
    animation: ${rotate('360deg')} 2.5s linear forwards infinite;
  }

  .circle-2 {
    animation: ${rotate('-360deg')} 3s linear forwards infinite;
  }

  .circle-3 {
    animation: ${rotate('360deg')} 5s linear forwards infinite;
  }

  circle {
    stroke: ${({ theme, color }) =>
      theme.darkMode ? colors.yellow500 : color};
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
