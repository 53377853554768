import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getQuery } from '../config/utils';

type LgbtParams = {
  ibge?: string;
  orientation?: string;
  genderIdentity?: string;
};

const service = () => {
  const resource = `/populacao-lgbt`;

  async function hasJob(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ESTA_TRABALHANDO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data
      .map((d) => {
        return {
          name: d.esta_trabalhando,
          value: d.quantidade
        };
      })
      .filter((d) => d.name !== 'Resposta invalida');
  }

  async function jobActions(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ACOES_LGBT_MUNICIPIO']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.acoes_lgbt_municipio,
        value: d.porcentagem
      };
    });
  }

  async function jobLifeCovid(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['ALTERACAO_RENDA_PANDEMIA']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.alteracao_renda_pandemia,
        value: d.porcentagem
      };
    });
  }

  async function jobAreas(params: LgbtParams) {
    const path = `${resource}/procurando-emprego`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.procurando_emprego,
        value: d.porcentagem
      };
    });
  }

  async function jobTypes(params: LgbtParams) {
    const path = `${resource}/tipos-emprego`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.tipo_emprego,
        value: d.porcentagem
      };
    });
  }

  async function monthlyIncome(params: LgbtParams) {
    const path = `${resource}`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(['RENDA_MENSAL']),
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.renda_mensal,
        value: d.porcentagem
      };
    });
  }

  async function jobChallenges(params: LgbtParams) {
    const path = `${resource}//desafios-mercado`;
    const { orientation, ibge, genderIdentity } = params;
    const options = ensureAxiosParamOptions({
      params: {
        sexualOrientation: orientation,
        ibge,
        genderIdentity
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    return response.data.map((d) => {
      return {
        name: d.desafios_insercao_emprego,
        value: d.porcentagem
      };
    });
  }

  return {
    hasJob,
    jobActions,
    jobLifeCovid,
    monthlyIncome,
    jobTypes,
    jobAreas,
    jobChallenges
  };
};

export const useJobIncome = service();
