import { useRef } from 'react';
import { scaleLog, scaleQuantize } from '@visx/scale';
import { ScaleSVG } from '@visx/responsive';
import { Wordcloud as VisxWordcloud } from '@visx/wordcloud';

import { Box, Legend, LegendContainer, SText, ValueBox } from './styles';
import { Data } from 'big-data';
import { dotsNumber, percentageFormatter } from '@/utils/string';
import { fixedValueGenerator, getDataParsed, getWordFreq } from './config';
import { useTheme } from 'styled-components';

type SpiralType = 'archimedean' | 'rectangular';

interface WordCloudProps {
  width?: number;
  height?: number;
  words: Data[];
  percentage?: boolean;
  spiralType?: SpiralType;
  withRotation?: boolean;
  rangeBreakpoints?: number[];
  scaleSize?: number[];
  padding?: number;
  percentageSymbol?: boolean;
}

const DEFAULT_PALLETE = ['#8DDCE2', '#4DC8D1', '#2A9AA2', '#113E41'];
const DARK_MODE_PALLETE = ['#FFECAD', '#FFE285', '#FFCA15', '#E0AC00'];

export function WordCloud({
  width = 1000,
  height = 700,
  words,
  percentage,
  spiralType = 'rectangular',
  withRotation = true,
  scaleSize = [10, 100],
  padding = 5,
  percentageSymbol
}: WordCloudProps) {
  const valueBoxRef = useRef<HTMLParagraphElement>(null);
  const { darkMode } = useTheme();
  const colors = darkMode ? DARK_MODE_PALLETE : DEFAULT_PALLETE;

  const total = words.reduce((acc, cur) => acc + cur.value, 0);

  const mapWords = getWordFreq(words);
  const dataWords = getDataParsed(mapWords, {
    isPercentage: percentage,
    total
  });

  const values = dataWords.map((w) => w.value);
  const min = Math.min(...values);
  const domain = [min > 0 ? min : 0.1, Math.max(...values)];
  const fontScale = scaleLog({
    domain,
    range: scaleSize
  });

  const colorScale = scaleQuantize({
    domain: [Math.min(...values), Math.max(...values)],
    range: colors,
    nice: true
  });

  return (
    <Box>
      <ScaleSVG width={width} height={height}>
        <VisxWordcloud
          words={dataWords}
          width={width}
          height={height}
          fontSize={({ value }) => fontScale(value)}
          font="Anton, sans-serif"
          padding={padding}
          spiral={spiralType}
          rotate={0}
          random={fixedValueGenerator}
        >
          {(cloudWords) =>
            cloudWords.map((w: any, i: number) => {
              return (
                <SText
                  key={w.text}
                  transform={`translate(${w.x}, ${w.y}) rotate(0)`}
                  fill={colorScale(w.value)}
                  textAnchor="middle"
                  fontSize={w.size}
                  fontFamily={w.font}
                  fontWeight={w.weight}
                  preserveAspectRatio="xMidYMid meet"
                  onMouseOver={() => {
                    if (valueBoxRef.current) {
                      const baseValue = percentageSymbol
                        ? percentageFormatter(w.value)
                        : dotsNumber(w.value);

                      valueBoxRef.current.textContent = percentage
                        ? percentageFormatter(w.value)
                        : baseValue;
                    }
                  }}
                  onMouseOut={() => {
                    if (valueBoxRef.current) {
                      valueBoxRef.current.textContent = '';
                    }
                  }}
                >
                  {w.text}
                </SText>
              );
            })
          }
        </VisxWordcloud>
      </ScaleSVG>
      <LegendContainer>
        <Legend>
          Palavras maiores, com cores mais escuras, foram as mais citadas na
          pesquisa
        </Legend>
      </LegendContainer>
      <ValueBox ref={valueBoxRef} />
    </Box>
  );
}
