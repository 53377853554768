import { useDashboardFilter } from '@/hooks';
import { socialAssistance } from '@/services';
import { DataWithCity } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function useFamilyCities(name?: string) {
  const [cities, setCities] = useState<DataWithCity[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorCities, setErrorCities] = useState<Error | null>(null);

  const { ibge, year, semester, zone } = useDashboardFilter();

  useEffect(() => {
    setLoading(true);
    socialAssistance
      .getByCity(name, ibge, year, semester, zone)
      .then((data) => {
        setCities(data);
      })
      .catch((err) => setErrorCities(err))
      .finally(() => setLoading(false));
  }, [name, ibge, year, semester, zone]);

  return useMemo(
    () => ({
      cities,
      loadingCities: loading,
      errorCities
    }),
    [cities, loading, errorCities]
  );
}
