import { useState, useEffect, useMemo } from 'react';
import { DataWithProfile } from 'big-data';
import { homeVisits } from '@/services';
import { CountLabel } from '@/pages/Analytics/components';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

export function BeneficiariesCount() {
  const [beneficiaries, setBeneficiaries] = useState<DataWithProfile[]>([]);
  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const { city, profilePopulation } = useAnalyticsFilter();
  useEffect(() => {
    setLoadingBeneficiaries(true);
    homeVisits
      .beneficiariesAmount()
      .then((data) => setBeneficiaries(data))
      .finally(() => setLoadingBeneficiaries(false));
  }, []);

  const beneficiariesAmount = useMemo(() => {
    return beneficiaries
      .filter((beneficiary) =>
        !!profilePopulation
          ? beneficiary.profile === profilePopulation
          : beneficiary
      )
      .filter((beneficiary) =>
        !!city ? beneficiary.name === city : beneficiary
      )
      .reduce((acc, cur) => acc + cur.value, 0);
  }, [beneficiaries, profilePopulation, city]);

  return (
    <CountLabel
      value={beneficiariesAmount}
      label="beneficiados"
      loading={loadingBeneficiaries}
      size={30}
    />
  );
}
