import { GenericGridItem } from '@/components/styled';

import {
  CardBarGroup,
  CardMap,
  CardPyramid,
  CardRanking,
  CardDonut,
  CardBarGrid
} from '../JobIncomeCards';

import { Box } from './styles';

export function JobIncomeInsights() {
  return (
    <Box>
      <GenericGridItem area="a">
        <CardMap />
      </GenericGridItem>

      <GenericGridItem area="b">
        <CardPyramid />
      </GenericGridItem>

      <GenericGridItem area="c">
        <CardBarGrid />
      </GenericGridItem>

      <GenericGridItem area="d">
        <CardRanking />
      </GenericGridItem>

      <GenericGridItem area="e">
        <CardDonut />
      </GenericGridItem>

      <GenericGridItem area="f">
        <CardBarGroup />
      </GenericGridItem>
    </Box>
  );
}
