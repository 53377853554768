import { Content, Footer, Modal, Title } from './styles';

interface RefuseUseTermsModalProps {
  visible: boolean;
  onConfirm(): void;
  onCancel(): void;
}

export function RefuseUseTermsModal({
  visible,
  onConfirm,
  onCancel
}: RefuseUseTermsModalProps) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      zIndex={99999}
      centered
      className="use-terms-modal"
      style={{ borderRadius: 16 }}
      title={<Title>Tem certeza?</Title>}
      footer={
        <Footer>
          <button className="deny" onClick={onCancel}>
            Cancelar
          </button>
          <button className="accept" onClick={onConfirm}>
            Confirmar
          </button>
        </Footer>
      }
    >
      <Content>
        <p>Se recusar os termos de uso você será deslogado.</p>
      </Content>
    </Modal>
  );
}
