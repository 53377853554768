export function BdsWomanMapping({ color = '#fff' }: { color?: string }) {
  return (
    <svg
      width="19"
      height="30"
      viewBox="0 0 19 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.7831 16.2321C0.990141 14.4375 8.9407e-08 12.0469 8.9407e-08 9.50893C8.9407e-08 6.96763 0.986796 4.58036 2.7831 2.78571C4.57606 0.991071 6.96444 0 9.5 0C12.0356 0 14.4239 0.987723 16.2169 2.78571C18.0099 4.58371 19 6.96763 19 9.50893C19 11.6451 18.3009 13.6741 17.013 15.3315C16.7688 15.6462 16.5046 15.9442 16.2202 16.2321C15.9359 16.5167 15.6349 16.7812 15.3238 17.0257C13.9991 18.0569 12.437 18.7132 10.7711 18.9342V22.5H14.5845C14.7317 22.5 14.8521 22.6205 14.8521 22.7679V24.7768C14.8521 24.9241 14.7317 25.0446 14.5845 25.0446H10.7711V29.7321C10.7711 29.8795 10.6507 30 10.5035 30H8.49648C8.3493 30 8.22887 29.8795 8.22887 29.7321V25.0446H4.41549C4.26831 25.0446 4.14789 24.9241 4.14789 24.7768V22.7679C4.14789 22.6205 4.26831 22.5 4.41549 22.5H8.22887V18.9342C6.17165 18.6596 4.275 17.7254 2.7831 16.2321ZM9.5 16.4732C11.3599 16.4732 13.1026 15.75 14.4206 14.4342C15.7352 13.1183 16.4577 11.3705 16.4577 9.50893C16.4577 7.64732 15.7352 5.9029 14.4206 4.58371C13.106 3.26451 11.3599 2.54464 9.5 2.54464C7.64014 2.54464 5.89736 3.26786 4.5794 4.58371C3.26479 5.89955 2.54225 7.64732 2.54225 9.50893C2.54225 11.3705 3.26479 13.115 4.5794 14.4342C5.89736 15.75 7.64014 16.4732 9.5 16.4732Z"
        fill={color}
      />
    </svg>
  );
}
