import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQuery } from '@/theme/mediaQuery';

const ringRotate = keyframes`
  from { transform: rotate(0) }
  to { transform: rotate(360deg) }
`;

export const Box = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;

  flex-wrap: wrap;
  margin: 50px 0 100px;
`;

export const ItemBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

export const ImageBox = styled(Link)`
  width: 300px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;
  min-width: 200px;
`;

export const Image = styled.div`
  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;

    ${mediaQuery.smallScreen} {
      width: 200px;
      height: 200px;
    }
  }
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  color: ${(props) => (props.theme.darkMode ? `#fff` : `#2d4040`)};
  font-weight: 700;
`;

export const BaseRing = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 5;
  top: -35px;
  width: 370px;
  height: 370px;
  border: 10px solid #bfd730aa;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${ringRotate} 80s linear infinite;
  transition: opacity 0.2s linear;

  ${mediaQuery.smallScreen} {
    width: 270px;
    height: 270px;
  }
`;

export const RingA = styled(BaseRing)`
  top: -15px;
  right: -15px;
  width: 330px;
  height: 330px;
  border-width: 15px;
  border-right-color: #5e9b44dd;
  animation-delay: -3s;
  animation-duration: 50s;
  opacity: 0.8;

  ${mediaQuery.smallScreen} {
    width: 230px;
    height: 230px;
    right: 33px;
  }
`;

export const RingB = styled(BaseRing)`
  animation-duration: 40s;

  opacity: 0;

  &.hovered {
    opacity: 0.8;
  }
`;

export const RingC = styled(BaseRing)`
  top: -90px;
  width: 480px;
  height: 480px;
  border-width: 15px;
  animation-delay: -21s;
  animation-duration: 90s;
  opacity: 0;

  &.hovered {
    opacity: 0.7;
  }
`;
