import { useEffect, useRef, useState } from 'react';
import { Observation } from 'big-data';

import { Box } from './styles';
import { Title3 } from '../styles';
import { observation } from '@/services';
import { Comment } from '../Comment';
import { CommentInput, CommentInputFormData } from '../CommentInput';
import { Loading } from '@/components/shared';
import { capitalize } from '@/utils/string';
import { CommentToAnswer } from '../CommentToAnswer';

interface CommentsProps {
  id: number;
  typing: string;
  comments: Observation[];
  loading?: boolean;
  error?: string;
  getComments: (id: number, page?: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCreatedComment?: () => void;
}

const PAGE_SIZE = 10;

export function Comments({
  id,
  typing,
  comments,
  loading,
  error,
  getComments,
  onFocus,
  onBlur,
  onCreatedComment
}: CommentsProps) {
  const [submitting, setSubmitting] = useState(false);
  const [answerTo, setAnswerTo] = useState<Observation>();

  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [page, setPage] = useState(-1);

  const commentsRef = useRef<HTMLDivElement>(null);
  const intersectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const config = {
      root: commentsRef.current,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (entry.isIntersecting) {
        setPage((current) => current + 1);

        const { current } = commentsRef;
        if (current) {
          current.scrollTo(0, 40);
        }
      }
    }, config);

    setObserver(observer);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const { current } = intersectionRef;

    if (observer && current) {
      observer.observe(current);
    }
  }, [observer]);

  useEffect(() => {
    if (!observer) return;

    const commentsSize = comments.length;
    const checkWorstLastPageCase = page * PAGE_SIZE > commentsSize;
    const checkBestLasPageCase = commentsSize % 10 !== 0;

    if (checkWorstLastPageCase || checkBestLasPageCase) {
      observer.disconnect();
      setObserver(null);
    }
  }, [comments, page, observer]);

  useEffect(() => {
    if (page === -1) return;

    getComments(id, page);
  }, [id, getComments, page]);

  useEffect(() => {
    const { current } = commentsRef;

    if (current && comments.length > 0 && page === 0) {
      current.scrollTo(0, current.scrollHeight);
    }
  }, [comments, page]);

  function handleAnswerTo(comment: Observation) {
    setAnswerTo(comment);
  }

  function handleRemoveAnswerTo() {
    setAnswerTo(undefined);
  }

  function handleSubmitComment(formData: CommentInputFormData) {
    setSubmitting(true);
    observation
      .create({ ...formData, actionId: id, answerTo: answerTo?.id })
      .then(() => getComments(id))
      .then(() => {
        if (onCreatedComment) {
          onCreatedComment();
        }
      })
      .finally(() => {
        const { current } = commentsRef;

        current && current.scrollTo(0, current.scrollHeight);
        setAnswerTo(undefined);
        setSubmitting(false);
      });
  }

  return (
    <Box>
      <Title3>Observações sobre o plano de ação</Title3>

      <div className="comments" ref={commentsRef}>
        <div ref={intersectionRef} className="intersection">
          {loading && <Loading size={25} />}
        </div>

        {comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            onClickToAnswer={handleAnswerTo}
          />
        ))}
      </div>

      <p className="typing-msg">
        {typing && `${capitalize(typing)} está digitando...`}
      </p>

      <div className="comment-answer">
        <CommentToAnswer comment={answerTo} onRemove={handleRemoveAnswerTo} />
      </div>

      <div className="comments-input">
        <CommentInput
          loading={submitting}
          onFocus={onFocus}
          onBlur={onBlur}
          onSubmit={handleSubmitComment}
        />
      </div>
    </Box>
  );
}
