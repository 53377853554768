import { ensureAxiosParamOptions } from '@/utils/routes';
import { removeSpecialCharAndAddUnderscore } from '@/utils/string';
import { DashboardCzrmTableProps } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

const PAGE_SIZE = 10;

const service = () => {
  const resource = `/inseguranca-alimentar-czrm`;

  async function getCitizens(params: DashboardCzrmTableProps) {
    const {
      pageNumber,
      cpf,
      nis,
      ibge,
      semester,
      subAxeName,
      year,
      zone,
      pageSize
    } = params;

    const path = resource;
    const aggregations = [''];
    const subAxeNameFormatted = removeSpecialCharAndAddUnderscore(
      subAxeName ?? ''
    ).toUpperCase();
    const classificationFilter = subAxeNameFormatted;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        pageSize: pageSize ?? PAGE_SIZE,
        pageNumber,
        classificationFilter,
        ibge,
        year,
        semester,
        zone,
        cpf,
        nis
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));
    const data = response.data.map((data) => ({
      name: data.nome_responsavel,
      cpf: data.cpf_entrevistado,
      nis: data.nis,
      city: data.municipio
    }));

    const totalPages = response.data[0]?.totalpages || 0;

    return {
      totalPages,
      data,
      path
    };
  }

  return {
    getCitizens
  };
};

export const insecurityFoodCzRM = service();
