import { Data } from 'big-data';

import { CardTagged, ServiceBox, Table } from '@/components/shared';
import { TitleText, Text, ColorText } from '@/components/styled';
import { useDashboardFilter } from '@/hooks';
import {
  useVaccinationBookData,
  useVaccineUpToDateData
} from '@/servicesHooks/healthAgents';
import { percentageFormatter } from '@/utils/string';

const tableColumns1 = [
  { name: 'Possui?', key: 'name' },
  { name: 'Porcentagem', key: 'value' }
];

const tableColumns2 = [
  { name: 'Vacinação', key: 'name' },
  { name: 'Porcentagem', key: 'value' }
];

const vaccinationLabelMap = new Map<string, string>([
  ['nunca teve', 'nunca tiveram'],
  ['não, perdeu', 'perderam a'],
  ['não sabe', 'não sabem se tiveram a'],
  ['sim, não visto', 'tem a'],
  ['sim, visto', 'tem a'],
  ['default', '']
]);

const getYesData = (data: Data[]) => {
  return data
    .filter((data) => data.name.includes('Sim, visto'))
    .reduce((acc, cur) => acc + cur.value, 0);
};

function CardVaccinationBookTable() {
  const { tag } = useDashboardFilter();
  const { vaccinationData, vaccinationLoading, vaccinatonError } =
    useVaccinationBookData();

  const total = vaccinationData.reduce((acc, cur) => acc + cur.value, 0);
  const totalYesData = getYesData(vaccinationData);
  const [maxData] =
    [...vaccinationData].sort((a, b) => b.value - a.value) ?? {};
  const percentage = percentageFormatter((totalYesData / total) * 100);
  const label = vaccinationLabelMap.get(
    maxData?.name.toLowerCase() ?? 'default'
  );

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={vaccinationData.length === 0}
        loading={vaccinationLoading}
        error={vaccinatonError}
      >
        <TitleText>
          Qual a quantidade de crianças, com até 8 anos de idade, estão com a
          caderneta de vacinação vista?
        </TitleText>
        <Text>
          <ColorText>{percentage}</ColorText> crianças de até 8 anos{' '}
          <ColorText>{label} caderneta da criança vista, </ColorText>
          como mostra a tabela a seguir:
        </Text>

        <Table data={vaccinationData} columns={tableColumns1} percentage/>
      </ServiceBox>
    </CardTagged>
  );
}

function CardVaccinationTable() {
  const { tag } = useDashboardFilter();
  const { vaccinationData, vaccinationLoading, vaccinatonError } =
    useVaccineUpToDateData();

  const total = vaccinationData.reduce((acc, cur) => acc + cur.value, 0);
  const totalYesData = getYesData(vaccinationData);

  const percentage = percentageFormatter((totalYesData / total) * 100);

  return (
    <CardTagged tag={tag}>
      <ServiceBox
        empty={vaccinationData.length === 0}
        loading={vaccinationLoading}
        error={vaccinatonError}
      >
        <TitleText>
          Qual a quantidade de crianças de até 8 anos, estão com o esquema
          vacinal em dia?
        </TitleText>
        <Text>
          <ColorText>{percentage}</ColorText> crianças de até 8 anos estão com a
          vacina em dia, como mostra a tabela a seguir:
        </Text>

        <Table data={vaccinationData} columns={tableColumns2} percentage/>
      </ServiceBox>
    </CardTagged>
  );
}

export { CardVaccinationTable, CardVaccinationBookTable };
