export function RetirimentIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6.5C11.5967 6.5 12.169 6.26295 12.591 5.84099C13.0129 5.41903 13.25 4.84674 13.25 4.25C13.25 3.65326 13.0129 3.08097 12.591 2.65901C12.169 2.23705 11.5967 2 11 2C10.4033 2 9.83097 2.23705 9.40901 2.65901C8.98705 3.08097 8.75 3.65326 8.75 4.25C8.75 4.84674 8.98705 5.41903 9.40901 5.84099C9.83097 6.26295 10.4033 6.5 11 6.5ZM17.5 12.5C17.1022 12.5 16.7206 12.658 16.4393 12.9393C16.158 13.2206 16 13.6022 16 14V14.1715C16 14.3041 16.0527 14.4313 16.1464 14.5251C16.2402 14.6188 16.3674 14.6715 16.5 14.6715C16.6326 14.6715 16.7598 14.6188 16.8536 14.5251C16.9473 14.4313 17 14.3041 17 14.1715V14C17 13.8674 17.0527 13.7402 17.1464 13.6464C17.2402 13.5527 17.3674 13.5 17.5 13.5C17.6326 13.5 17.7598 13.5527 17.8536 13.6464C17.9473 13.7402 18 13.8674 18 14V21.473C18 21.6056 18.0527 21.7328 18.1464 21.8266C18.2402 21.9203 18.3674 21.973 18.5 21.973C18.6326 21.973 18.7598 21.9203 18.8536 21.8266C18.9473 21.7328 19 21.6056 19 21.473V14C19 13.6022 18.842 13.2206 18.5607 12.9393C18.2794 12.658 17.8978 12.5 17.5 12.5ZM14.8545 10.0895L14 9.7165L14.7975 10.055L14.8545 10.0895ZM15.4675 10.6205C15.2936 10.4111 15.0866 10.2317 14.8545 10.0895L18 11.464V11.416V11.417C18.0475 11.967 17.6225 12.4505 17.0505 12.497C16.4835 12.542 15.9845 12.14 15.93 11.5965V11.5955C15.915 11.5073 15.8933 11.4204 15.865 11.3355C15.7774 11.0748 15.6427 10.8325 15.4675 10.6205Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11.4155L14.7975 10.0555C14.5468 9.90961 14.2789 9.79556 14 9.716V21C13.9999 21.2565 13.9012 21.5032 13.7244 21.689C13.5475 21.8749 13.306 21.9856 13.0498 21.9984C12.7936 22.0112 12.5423 21.925 12.3478 21.7577C12.1533 21.5904 12.0306 21.3547 12.005 21.0995L11.505 16.0995C11.5017 16.0664 11.5 16.0332 11.5 16H10.5C10.5 16.033 10.4985 16.0665 10.495 16.0995L9.99498 21.0995C9.96936 21.3547 9.84663 21.5904 9.65217 21.7577C9.4577 21.925 9.20638 22.0112 8.95017 21.9984C8.69396 21.9856 8.45246 21.8749 8.27561 21.689C8.09875 21.5032 8.00008 21.2565 7.99998 21V13.9595C7.16048 13.848 6.45498 13.5105 5.93198 12.997C5.63231 12.7033 5.39524 12.3519 5.23506 11.9641C5.07488 11.5763 4.99491 11.1601 4.99998 10.7405C5.00413 10.3222 5.0911 9.90878 5.25587 9.52423C5.42065 9.13967 5.65995 8.79157 5.95998 8.5C6.60498 7.8705 7.52448 7.5 8.62748 7.5H12.2875C14.774 7.5 16.2595 8.361 17.0975 9.3795C17.427 9.77904 17.6803 10.2358 17.8445 10.727C17.9175 10.947 17.9745 11.1735 17.999 11.4035V11.411L18 11.414V11.4155ZM7.41548 11.5965C7.54398 11.7225 7.73098 11.8445 7.99998 11.9205V9.585C7.74998 9.661 7.56798 9.7795 7.43848 9.9055C7.32515 10.0171 7.235 10.15 7.17321 10.2966C7.11143 10.4431 7.07924 10.6004 7.07848 10.7595C7.07548 11.0795 7.19448 11.3795 7.41548 11.5965Z"
        fill="white"
      />
    </svg>
  );
}
