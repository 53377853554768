import {
  EducationEnum,
  HealthEnum,
  InsecurityFoodEnum,
  LivingConditionEnum,
  SocialAssistanceEnum,
  WorkEnum
} from '@/services';

const NO_ANSWER = 'Não';

export const COLUMN_BY_INDICATOR: Record<string, string[]> = {
  [InsecurityFoodEnum.FORA_DE_INSEGURANCA_ALIMENTAR]: [
    'Fora de insegurança alimentar'
  ],
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_LEVE]: [
    'Insegurança alimentar leve'
  ],
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_MODERADA]: [
    'Insegurança alimentar moderada'
  ],
  [InsecurityFoodEnum.INSEGURANCA_ALIMENTAR_GRAVE]: [
    'Insegurança alimentar grave'
  ],
  [EducationEnum.CRECHE]: [NO_ANSWER],
  [EducationEnum.PRE_ESCOLA]: [NO_ANSWER],
  [EducationEnum.ESCOLA]: [NO_ANSWER],
  [SocialAssistanceEnum.CRAS]: [NO_ANSWER],
  [SocialAssistanceEnum.CREAS]: [NO_ANSWER],
  [HealthEnum.GESTANTE_PRE_NATAL]: [NO_ANSWER],
  [HealthEnum.ACOMPANHAMENTO_CRIANCA]: [NO_ANSWER],
  [HealthEnum.CRIANCA_VACINA_EM_DIA]: [
    'Não. visto na caderneta',
    'Não. informado pela cuidador(a)'
  ], // Ver no back como tratar esse caso
  [WorkEnum.TRABALHO_REMUNERADO]: [NO_ANSWER],
  [WorkEnum.SALARIO_MINIMO]: ['Até meio salário mínimo'],
  [WorkEnum.FAZ_CURSO]: [NO_ANSWER],
  [WorkEnum.QUER_FAZER_CURSO]: [NO_ANSWER],
  [WorkEnum.RENDA]: [
    'Bolsa família / Cartão Mais Infância',
    'Bolsa família / Cartão Mais Infância; Outros'
  ], // considerar o 'Bolsa família / Cartão Mais Infância; Outros?'

  [LivingConditionEnum.AGUA_CANALIZADA]: [NO_ANSWER],
  [LivingConditionEnum.BANHEIRO]: [NO_ANSWER],
  [LivingConditionEnum.COLETA_SELETIVA]: [NO_ANSWER],
  [LivingConditionEnum.LUGARES_PUBLICOS_CRIANCA_BRINCAR]: [NO_ANSWER],
  [LivingConditionEnum.ATIVIDADE_CULTURAL]: [NO_ANSWER],
  [LivingConditionEnum.ATIVIDADE_ESPORTIVA]: [NO_ANSWER],
  [LivingConditionEnum.AREA_CONFLITO]: ['Sim'] // Tem que ser o sim?,
};
