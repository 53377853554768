import styled from 'styled-components';

export const Box = styled.div`
  .count-labels {
    display: flex;
    gap: 2rem;
    @media (max-width: 500px) {
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;
