import { colors } from '@/theme/colors';
import styled from 'styled-components';

interface Props {
  delay?: number;
  paddingLeft?: number;
  children: any[];
  number?: number;
}

const colorNumbers = [
  colors.green200,
  '#87C878',
  colors.yellow500,
  colors.yellow400,
  colors.green500
];

export const AnimatedTopic = ({
  delay = 0,
  paddingLeft = 0,
  number,
  children
}: Props) => {
  return (
    <Wrapper paddingLeft={paddingLeft}>
      <TextContainer>
        <Number
          color={
            number !== undefined && number > 0
              ? colorNumbers[number - 1]
              : undefined
          }
        >
          {number}
        </Number>
        <Description>{children}</Description>
      </TextContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ paddingLeft: number }>`
  margin-left: ${(props) => props.paddingLeft + `vw`};
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;

  align-items: center;
  gap: 40px;
  padding-right: 10px;
  text-align: left;
  font-size: 1.5rem;
  line-height: 1.5;
`;

const Description = styled.div`
  position: relative;
  z-index: 2;
  text-align: left;
  text-rendering: geometricPrecision;
  font-size: 1rem;
  color: ${(props) => (props.theme.darkMode ? `#fff` : `#333`)};
  opacity: 0.85;
  transition: 0.6s transform;
  b {
    display: inline-block;
    color: ${(props) => (props.theme.darkMode ? `#fff` : `#333`)};
  }
`;

const Number = styled.span<{ color?: string }>`
  font-size: 5rem;
  font-weight: 900;
  line-height: 6rem;
  color: ${({ color }) => (!!color ? color : colors.green200)};
`;
