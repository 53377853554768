import { CzrmIcon } from '@/assets/CzrmIcon';
import { BorderedCard } from '@/components/styled';
import { Box } from './styles';

interface CzrmCardProps {
  color?: string;
  name: string;
  onClick: () => void;
  title: string;
}
export function CzrmCard({ color, name, onClick, title }: CzrmCardProps) {
  return (
    <BorderedCard color={color}>
      <Box color={color}>
        <h2>
          O que fazer para ter uma visão micro, com informações
          {' ' + title + ' ' + name}?
        </h2>
        <p>
          Através do <b>Integra Social</b>, com dados das bases do sistema de
          agentes sociais do Ceará e do Cadastro Único (CadÚnico).
        </p>
        <button onClick={() => onClick()}>
          <CzrmIcon color={color} />
          <span>Acessar Integra Social</span>
        </button>
      </Box>
    </BorderedCard>
  );
}
