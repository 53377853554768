import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { Geolocation } from './@types';

const service = () => {
  const resource = `/inseguranca-alimentar-geolocalizacao`;

  const classification = () =>
    getRequestObject(
      async (
        signal: AbortSignal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            aggregations: 'CLASSIFICACAO',
            classificationFilter: filter,
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return response.data.filter((d) => d.latitude && d.longitude);
      }
    );

  return Object.freeze({
    classification
  });
};

export const insecurityFood = service();
