import { ensureAxiosParamOptions } from '@/utils/routes';
import { mainApi } from '../config/api';
import { callService } from '../config/service';

export type UserPlanActionAccessData = {
  name: string;
  planAction: boolean;
  cpf: string;
  city: string;
  numberOfAccess: number;
};

const service = () => {
  const resource = `/acoes/listagem-gestores`;

  async function get(
    cpf?: string,
    ibge?: string,
    minimumDate?: string,
    order?: string
  ) {
    const path = resource;
    const opts = ensureAxiosParamOptions({
      params: {
        cpf,
        ibge,
        minimumDate,
        order
      }
    });
    const response = await callService(() =>
      mainApi.get<
        {
          nome: string;
          cpf: string;
          plano_acao: boolean;
          cidade: string;
          numero_logins: number;
        }[]
      >(path, opts)
    );
    return response.data.map<UserPlanActionAccessData>((data) => ({
      name: data.nome,
      cpf: data.cpf,
      planAction: data.plano_acao,
      city: data.cidade,
      numberOfAccess: data.numero_logins
    }));
  }

  return {
    get
  };
};

export const listManagerDataActions = service();
