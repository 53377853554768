import { BigDataRoute as BDRoute } from '@/types/routes';
import { Routes } from 'react-router-dom';
import { mountRoutes } from '@/utils/routes';
import { useGoTop } from '@/hooks';

interface RouteProps {
  routes: BDRoute[][];
}

export function BigDataRoutes({ routes }: RouteProps) {
  useGoTop();
  return <Routes>{routes.map((route) => mountRoutes(route))}</Routes>;
}
