import { useMemo, useState } from 'react';
import { DataTree } from 'big-data';
import { useNavigate } from 'react-router-dom';

import {
  FilterDashboard,
  SunBurstReact,
  SunBurstText
} from '@/components/shared';
import {
  AxeDescriptionText,
  ThermometerWrapper,
  ThirdTitle
} from '@/components/styled';
import { useTotalCities } from '@/servicesHooks/foodInsecurity';
import { useDashboardFilter, useDashboardTitle } from '@/hooks';
import { colors } from '@/theme/colors';

import { CearaStatistics, HomeInsights } from '../components';

import { Box } from './styles';
import { axes } from './axes';

const description = `Gestor público, conheça indicadores, ações e auxílios para enfrentar a pobreza e a desigualdade no estado e promover o bem-estar das famílias cearenses.  `;

export function DashboardHome() {
  useDashboardTitle('Termômetro de Dados da Proteção Social do Ceará');
  const { city } = useDashboardFilter();

  const { total, totalCities, loadingTotal } = useTotalCities();
  const navigate = useNavigate();
  const [, setGeneralTotal] = useState<string>();

  const totalCitiesText = totalCities > 1 ? `MUNICÍPIOS` : `MUNICÍPIO`;
  const sunburstLabel = city
    ? `FAMÍLIAS VISITADAS NO MUNICÍPIO DE ${city.toUpperCase()}`
    : `FAMÍLIAS VISITADAS EM ${totalCities} ${totalCitiesText}`;

  const sunburstValue = useMemo(() => {
    const value = new Intl.NumberFormat('pt-br').format(total);
    if (!city) setGeneralTotal(value);
    return value;
  }, [total, city]);

  function handleSunburstClick(data?: DataTree) {
    if (!data) return;

    const redirectPath = data?.path;

    if (redirectPath) {
      navigate(redirectPath);
    }
  }

  return (
    <Box>
      <AxeDescriptionText className="axe-description-text">
        {description}
      </AxeDescriptionText>
      <FilterDashboard />
      <ThermometerWrapper position="end">
        <div className="left-cards">
          <CearaStatistics />
        </div>
        <SunBurstReact
          data={axes}
          onClick={handleSunburstClick}
          renderIcon
          loading={loadingTotal}
        >
          <SunBurstText
            color={colors.green600}
            value={sunburstValue}
            label={sunburstLabel}
            buttonLabel="CLIQUE EM CADA EIXO PARA DETALHAR"
            handleOpenFamilyTable={() => { }}
            isButtonCzrm={false}
          />
        </SunBurstReact>
      </ThermometerWrapper>

      <div className="profile">
        <ThirdTitle>Dados gerais sobre as famílias no Ceará</ThirdTitle>

        <HomeInsights />
      </div>
    </Box>
  );
}
