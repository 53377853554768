import { useTheme } from 'styled-components';

import { ILink } from '@/pages/links';

import { Logo, DesktopMenu, MobileMenu } from './components';
import { ContainerHeader, Box } from './styles';

type IHeaderLink = ILink & { isActive: boolean };

interface HeaderApresentationProps {
  links: IHeaderLink[];
  animateColor?: boolean;
}

export function HeaderApresentation({
  links,
  animateColor
}: HeaderApresentationProps) {
  const theme = useTheme();

  const isMobile = theme.screen.medium || theme.screen.small;

  return (
    <Box>
      <ContainerHeader flex={!isMobile}>
        <Logo />
        {isMobile ? (
          <MobileMenu items={links} />
        ) : (
          <DesktopMenu items={links} />
        )}
      </ContainerHeader>
    </Box>
  );
}
