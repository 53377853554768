import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { mediaQuery } from '@/theme/mediaQuery';
import { CitySelected } from './CitySelected';
import { LegendPanel } from './LegendPanel';
import { cearaGeoJsonData, cearaIndexedData } from './data';
import { SvgMap } from './SvgMap';
import { Data } from 'big-data';

interface DataWithIbgeCode extends Data {
  id?: number;
}
interface D3MapProps {
  data: DataWithIbgeCode[];
  maxWidth?: string;
  numTicks?: number;
}

const EMPTY_CITY = { visible: false, name: ``, total: ``, id: '' };

export const D3Map = ({ data, maxWidth, numTicks = 8 }: D3MapProps) => {
  const [legendItems, setLegendItems] = useState<LegendItem[]>([]);
  const [city, setCity] = useState<ICity>(EMPTY_CITY);

  const { indexedCities, hasIbgeCode } = useMemo(
    () => cearaIndexedData(data),
    [data]
  );
  const geoJsonData = useMemo(
    () => cearaGeoJsonData(indexedCities, hasIbgeCode),
    [indexedCities, hasIbgeCode]
  );

  const options = useMemo(() => {
    return {
      onLegendItems: (items: any) => {
        setLegendItems(items);
      },
      onSelect: (city: any | undefined) => {
        const visible = !!city;
        setCity((prevCity) => {
          return city ? { ...city, visible } : { ...prevCity, visible };
        });
      },
      numTicks
    };
  }, [numTicks]);

  return (
    <Box>
      <Map maxWidth={maxWidth}>
        <SvgMap data={geoJsonData} options={options} />
        <LegendPanel items={legendItems} />
        <CitySelected {...city} />
      </Map>
    </Box>
  );
};

const Box = styled.div``;

interface MapProps {
  maxWidth?: string;
}

const Map = styled.div<MapProps>`
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 240px;
  max-width: ${({ maxWidth }) => maxWidth ?? null};

  ${mediaQuery.smallScreen} {
    padding: 10px;
    svg path.city {
      stroke-width: 0.5 !important;
    }
  }

  svg {
    &.with-animation {
      pointer-events: none;
      path.city {
        transition: 0.2s;
        transform-origin: center;
      }
    }
    path.city {
      stroke-width: 1;
      stroke: #57aa;
      &.hidden {
        opacity: 0;
        transform: scale(1.8);
      }
      &.no-fill {
        fill: #dddc !important;
        opacity: 0.4;
        transition: none;
      }
    }
  }
`;
