import { yesOrNoToBool } from '@/utils/types';
import { Data } from 'big-data';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

function cleanNullData(data: Data[]) {
  return data.filter((d) => d.name !== 'null' && d.name !== null);
}

const service = () => {
  const resource = `/condicoes-moradia`;

  async function amount(filter?: string, zone?: string) {
    const path = `${resource}`;
    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          city: filter,
          zone
        }
      })
    );
    return response.data[0];
  }

  async function getCities() {
    const aggregations = ['MUNICIPIO'];
    const path = `${resource}?aggregations=${getQuery(aggregations)}`;
    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data.map((res) => ({
      name: res.municipio,
      value: res.quantidade
    }));
  }

  async function getElectricity(city?: string, zone?: string) {
    const aggregations = ['ILUMINACAO_DOMIC_FAM'];
    const path = resource;
    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        value: res.quantidade,
        name: res.iluminacao_domic_fam
      }))
    );
  }

  async function getInternalFloor(city?: string, zone?: string) {
    const aggregations = ['MATERIAL_PISO_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.material_piso_domic_fam,
        value: res.quantidade
      }))
    );
  }

  async function getLocation(city?: string, zone?: string) {
    const aggregations = ['LOCAL_DOMIC_FAM'];
    const path = resource;
    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return response.data.map<Data>((res) => ({
      name: res.local_domic_fam,
      value: res.quantidade
    }));
  }

  async function getRooms(city?: string, zone?: string) {
    const aggregations = ['ESPECIE_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.especie_domic_fam,
        value: res.quantidade
      }))
    );
  }

  async function getRoomsAverage(filter?: string) {
    const avg = ['QTD_COMODOS_DOMIC_FAM'];
    const path = `${resource}?avg=${getQuery(avg)}${
      filter ? `&city=${filter}` : ''
    }`;

    const response = await callService(() => mainApi.get<any[]>(path));
    return response.data[0].media;
  }

  async function getSanitation(city?: string, zone?: string) {
    const aggregations = ['AGUA_CANALIZADA_DOMIC_FAM', 'BANHEIRO_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          city,
          zone,
          aggregations: getQuery(aggregations)
        }
      })
    );
    return response.data.map((res) => ({
      value: res.quantidade,
      pipedWater: yesOrNoToBool(res.agua_canalizada_domic_fam)!,
      bathroom: yesOrNoToBool(res.banheiro_domic_fam)!
    }));
  }

  async function getExternalWallMaterial(city?: string, zone?: string) {
    const aggregations = ['MATERIAL_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );

    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.material_domic_fam,
        value: res.quantidade
      }))
    );
  }

  async function getWaterOutFlow(city?: string, zone?: string) {
    const aggregations = ['ESCOA_SANITARIO_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.escoa_sanitario_domic_fam,
        value: res.quantidade
      }))
    );
  }

  async function getWaterSupply(city?: string, zone?: string) {
    const aggregations = ['ABASTECIMENTO_AGUA_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.abastecimento_agua_domic_fam,
        value: res.quantidade
      }))
    );
  }

  async function getExternalFloor(city?: string, zone?: string) {
    const aggregations = ['CALCAMENTO_DOMIC_FAM'];
    const path = resource;

    const response = await callService(() =>
      mainApi.get<any[]>(path, {
        params: {
          aggregations: getQuery(aggregations),
          city: city,
          zone
        }
      })
    );
    return cleanNullData(
      response.data.map<Data>((res) => ({
        name: res.calcamento_domic_fam,
        value: res.quantidade
      }))
    );
  }

  return {
    amount,
    getCities,
    getElectricity,
    getExternalFloor,
    getInternalFloor,
    getLocation,
    getRooms,
    getRoomsAverage,
    getSanitation,
    getExternalWallMaterial,
    getWaterOutFlow,
    getWaterSupply
  };
};

export const livingCondition = service();
