import { useDashboardFilter } from '@/hooks';
import { socialAssistance } from '@/services/socialAssistance';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

const defaultCreas = { name: 'CREAS', value: 0 };
const defaultCras = { name: 'CRAS', value: 0 };
const defaultNoInfo = { name: 'Sem Informação', value: 0 };

export function useSocialAssistance() {
  const [assistances, setAssistances] = useState<Data[]>([]);
  const [loadingAssistances, setLoadingAssistances] = useState(false);
  const [errorAssistances, setErrorAssistances] = useState<Error | null>(null);
  const [total, setTotal] = useState<Data[]>([]);

  const { ibge, year, semester, zone, isNull } = useDashboardFilter();

  useEffect(() => {
    const promises = [
      socialAssistance.getByCREAS,
      socialAssistance.getByCRAS,
      socialAssistance.getTotalByBoth
    ];

    setLoadingAssistances(true);
    Promise.all(promises.map((promise) => promise(ibge, year, semester, zone)))
      .then(([creas, cras, total]) => {
        setTotal(total);

        if (total.length === 0) isNull();

        const noCreas = creas
          .filter(
            (c) => c.name === 'Não' || c.name === 'O município não possui CREAS'
          )
          .reduce(
            (acc, cur) => ({ ...acc, value: acc.value + cur.value }),
            defaultCreas
          );

        const noCras = cras
          .filter((c) => c.name === 'Não')
          .reduce(
            (acc, cur) => ({ ...acc, value: acc.value + cur.value }),
            defaultCras
          );

        const noInfo = [...creas, ...cras]
          .filter((c) => /não sei/.test(c.name.toLowerCase()))
          .reduce(
            (acc, cur) => ({ ...acc, value: acc.value + cur.value }),
            defaultNoInfo
          );

        return [noCreas, noCras, noInfo];
      })
      .then((data) => {
        setAssistances(data);
      })
      .catch((err) => setErrorAssistances(err))
      .finally(() => setLoadingAssistances(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data: assistances,
      total,
      loading: loadingAssistances,
      error: errorAssistances
    }),
    [assistances, loadingAssistances, errorAssistances, total]
  );
}
