import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  svg {
    width: 100%;
  }
  tspan {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    fill: ${({ theme }) => (theme.darkMode ? colors.white : colors.green700)};
  }

  .area-tooltip:after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) =>
        theme.darkMode ? theme.background : `#ffffff`}
      transparent transparent transparent;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  ${mediaQuery.mediumScreen} {
  }

  ${mediaQuery.smallScreen} {
  }
`;
