import styled from 'styled-components';

import { mediaQuery } from '@/theme/mediaQuery';
import { colors } from '@/theme/colors';

export const Box = styled.div`
  background: rgba(78, 115, 69, 0.1);

  .layout {
    max-width: 1400px;
    margin: 0 auto;
    width: 90%;
    padding-inline: 20px;
    padding-block: 135px;

    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 1318px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .layout .summary {
    max-width: 600px;

    @media (max-width: 1318px) {
      max-width: unset;
    }

    h2 {
      font-size: 2.2rem;
      color: ${({ theme }) =>
        theme.darkMode ? colors.white : colors.green600};

      ${mediaQuery.mediumScreen} {
        text-align: center;
      }
    }

    p {
      margin-bottom: 2rem;
      font-size: 1.2rem;
      line-height: 1.5;
      text-rendering: geometricPrecision;
      font-weight: 400;
      color: ${({ theme }) => (theme.darkMode ? `#fff` : colors.black)};
    }
  }
`;
