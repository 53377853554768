import { css } from 'styled-components';

// google fonts adicionadas em ~/public/index.html
const IBMSans = css`
  font-family: 'IBM Plex Sans', sans-serif !important;
`;

const Roboto = css`
  font-family: Roboto, sans-serif;
`;

const OpenSans = css`
  font-family: 'Open Sans', sans-serif;
`;

export const fontFamily = {
  IBMSans,
  Roboto,
  OpenSans
};
