import { colors } from '@/theme/colors';
import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.nav`
  width: 100%;
  align-self: flex-end;

  .tabs {
    list-style: none;
    display: flex;
    padding: 0 1rem;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    .tabs {
      flex-direction: column;
      width: 100%;
    }
  }

  ${mediaQuery.mediumScreen} {
    align-self: start;
  }
`;

interface ListItemProps {
  selected?: boolean;
}

const onListItemSelected = (selected?: boolean, darkMode?: boolean) => {
  if (darkMode) {
    if (selected) return colors.white;
    return colors.yellow500;
  } else if (selected) return colors.ciano700;
  return colors.gray700;
};

export const ListItem = styled.li<ListItemProps>`
  padding: 0.6rem;
  min-width: 150px;
  width: max-content;
  text-align: center;
  color: ${({ theme, selected }) =>
    onListItemSelected(selected, theme.darkMode)};
  text-decoration: ${({ theme }) => (theme.darkMode ? 'underline' : '')};
  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? 700 : 500)};

  cursor: pointer;
  user-select: none;

  @media (max-width: 1200px) {
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    height: 42px;

    text-transform: uppercase;
  }

  ${mediaQuery.mediumScreen} {
    width: 100%;
  }

  ${mediaQuery.smallScreen} {
    width: 100%;
  }
`;

interface BarProps {
  left: number;
  width: number;
  amountItems: number;
  height?: number;
}

export const Bar = styled.div<BarProps>`
  height: 3px;
  width: ${({ width }) => width}px;
  background-color: ${(props) =>
    props.theme.darkMode ? '#fff' : colors.ciano700};

  position: relative;
  top: 0;
  left: 16px;

  transition: transform 0.3s;
  transform: ${({ left }) => `translateX(${left}%)`};

  @media (max-width: 1200px) {
    width: 3px;
    height: 42px;

    top: ${({ amountItems }) => -42 * amountItems}px;
    left: 0;

    transform: ${({ left }) => `translateY(${left}%)`};
  }
`;

// 211 - 42 =
