import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

export const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;

  .map,
  .graph {
    grid-column: span 2;
  }

  @media (max-width: 1530px) {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    .map,
    .graph {
      grid-column: span 1;
    }
  }

  @media (max-width: 1000px) {
    .map,
    .graph {
      grid-column: span 1;
    }
  }

  ${mediaQuery.smallScreen} {
    grid-template-columns: 100%;

    .map {
      grid-column: initial;
    }
  }
`;
