import * as d3 from 'd3';
import * as utils from './svgMap.utils';

const COLORS = [`#DEE2C0`, `#D3E173`, `#b1c72d`, `#344E56`];

type CityPaths = SVGPathElement[];

interface Options {
  onLegendItems?: (items: LegendItem[]) => void;
  onSelect?: (city: CityTotal | null) => void;
}

interface SVGMap {
  remove: () => void;
}

export const createSvgMap = (
  container: HTMLElement,
  geojson: any,
  options: Options
): SVGMap => {
  const width = 750;
  const height = 500;
  const viewBoxRatio = height / width;
  let pathCities: CityPaths = [];

  utils.sortFeaturesByCentroid(geojson);
  const values = geojson.features.map((i: any) => i.properties.total);
  const max = d3.max<number>(values) ?? 0;
  const legendValues = utils.calcLegendValues(max, 4);
  const colorScale = d3
    .scaleThreshold()
    .domain(legendValues)
    .range(COLORS as any[]);

  if (options.onLegendItems) {
    const legendList = utils.createLegendItems(COLORS, legendValues);
    options.onLegendItems(legendList);
  }

  const svg = d3.select(container);
  svg.attr(`width`, width).attr(`height`, height);
  svg.attr(`preserveAspectRatio`, `xMidYMid meet`);
  svg.attr(`viewBox`, `0 0 ${width} ${height}`);

  const projection = d3.geoMercator().fitSize([width, height], geojson);
  const path = d3.geoPath().projection(projection);

  function resizeWindow() {
    if (!container || !container.parentNode) {
      return;
    }
    const area = (container.parentNode as any).getBoundingClientRect();
    const width = Math.ceil(area.width - 40);
    const height = Math.min(550, Math.floor(width * viewBoxRatio));
    svg.attr(`width`, width).attr(`height`, height);
    svg.attr(`viewBox`, `0 0 ${width} ${height}`);
    const projection = d3.geoMercator().fitSize([width, height], geojson);
    const path = d3.geoPath().projection(projection);
    svg.selectAll(`path.city`).attr(`d`, path as any);
  }
  setTimeout(resizeWindow, 0);
  window.addEventListener(`resize`, resizeWindow, false);

  let elemTimer: any = 0;
  function clearHighlight() {
    for (let path of pathCities) {
      path.setAttribute(`class`, `city`);
    }
    if (options.onSelect) {
      options.onSelect(null);
    }
  }
  function highlightCity(element: any, properties?: any) {
    clearTimeout(elemTimer);

    if (!element && !properties) {
      elemTimer = setTimeout(clearHighlight, 100);
      return;
    }
    for (let path of pathCities) {
      path.setAttribute(`class`, path === element ? `city` : `city no-fill`);
    }
    if (options.onSelect) {
      const { name } = properties;
      const total = utils.formatNumber(properties.total ?? `-`);
      options.onSelect({ name, total });
    }
  }

  svg
    .attr(`class`, `with-animation`)
    .selectAll(`path.city`)
    .data(geojson.features)
    .enter()
    .append(`path`)
    .attr(`class`, `city hidden`)
    .attr(`d`, path as any)
    .on(`mouseenter`, (event: any, data: any) => {
      highlightCity(event.target, data.properties);
    })
    .on(`mouseleave`, () => {
      highlightCity(null);
    })
    .style(`fill`, (d: any) => {
      return colorScale(d.properties.total) || `transparent`;
    });

  const pathCityList = container.querySelectorAll(`path.city`) || [];
  for (let path of pathCityList) {
    pathCities.push(path as SVGPathElement);
  }

  utils.onInitByVisibility(container, startsAnimation);

  function startsAnimation() {
    let itemsByFrame = 20;
    let t: any = setInterval(() => {
      const items = pathCities.filter((i: SVGPathElement) =>
        i.classList.contains(`hidden`)
      );
      if (items.length === 0) {
        container.setAttribute(`class`, ``);
        return clearInterval(t);
      }
      const list = items.map((item) => item);

      itemsByFrame = Math.floor(itemsByFrame * 0.99);
      const selected = list.slice(0, itemsByFrame);

      selected.forEach((i) => {
        i.classList.remove(`hidden`);
      });
    }, 150);
  }

  return {
    remove: () => window.removeEventListener(`resize`, resizeWindow)
  };
};
