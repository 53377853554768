import { colors } from '@/theme/colors';
import { Radio } from 'antd';
import styled, { css } from 'styled-components';

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    &:first-of-type {
      border-radius: 7px 0 0 7px;
    }
    &:last-of-type {
      border-radius: 0 7px 7px 0;
    }
  }

  ${({ theme }) =>
    theme.darkMode &&
    css`
      .ant-radio-button-wrapper {
        background-color: ${theme.background};
        border-color: ${theme.yellowDark};
        color: ${theme.yellowDark};
        text-decoration: underline;

        &-checked {
          border-color: ${colors.white} !important;
          color: ${colors.white};

          &::before {
            display: none;
          }
        }
      }
    `}
`;
