import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.section``;

export const InsightsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;

  margin-top: 29px;
`;

export const UserBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 0;

  .avatar {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${colors.green200};
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5rem;
      color: #fff;
    }
  }

  .user {
    & > span {
      display: block;
      color: ${({ theme }) => (theme.darkMode ? colors.white : ``)};
    }

    span {
      font-size: 1rem;
    }

    .name,
    .cpf {
      font-weight: 700;
      line-height: 1rem;
    }
  }
`;
