import { mainApi } from '../config/api';
import { callService } from '../config/service';
import { getRequestObject } from '../config/utils';
import { Geolocation } from './@types';
import { getCleanedGeolocation } from './utils';

const paidWorkOption: Record<string, string> = {
  NO_WORK: 'Não',
  DONT_KNOW_WORK: 'Não sabe'
};

const service = () => {
  const resource = `/trabalho-renda-geolocalizacao`;

  const paidWorkInfo = () =>
    getRequestObject(
      async (
        signal,
        filter: string,
        city?: string,
        min: number[] = [],
        max: number[] = []
      ) => {
        const path = `${resource}`;

        const options = {
          params: {
            hasPaidWork: paidWorkOption[filter],
            ibge: city,
            minLat: min[0],
            minLong: min[1],
            maxLat: max[0],
            maxLong: max[1]
          },
          signal
        };

        const response = await callService<Geolocation[]>(() =>
          mainApi.get(path, options)
        );

        return getCleanedGeolocation(response.data);
      }
    );

  return Object.freeze({
    paidWorkInfo
  });
};

export const work = service();
