import { useCallback, useEffect, useMemo, useState } from 'react';
import { TScreenType, FontConfig } from 'big-data/theme';
import { StyleStorage } from './styleStorage';

const LEVEL_DEFAULT = 0;

export const useFontLevel = (screen: TScreenType): FontConfig => {
  const [level, setLevel] = useState(0);

  const limit = useMemo(
    () => ({
      isIncrease: level < 2,
      isDecrease: level > -1,
      isNormalized: level !== LEVEL_DEFAULT
    }),
    [level]
  );

  useEffect(() => {
    setLevel(StyleStorage.getFontLevel(LEVEL_DEFAULT));
  }, []);

  useEffect(() => {
    StyleStorage.setFontLevel(level);
  }, [level]);

  const increase = useCallback(() => {
    setLevel((current) => (current < 2 ? current + 1 : current));
  }, []);

  const decrease = useCallback(() => {
    setLevel((current) => (current > -1 ? current - 1 : current));
  }, []);

  const normalize = useCallback(() => {
    setLevel((current) =>
      current !== LEVEL_DEFAULT ? LEVEL_DEFAULT : current
    );
  }, []);

  return useMemo(() => {
    return {
      level,
      limit,
      increase,
      decrease,
      normalize
    };
  }, [level, limit, increase, decrease, normalize]);
};
