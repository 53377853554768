interface BdsFoodProps {
  width?: number;
  height?: number;
  color?: string;
}

export function BdsFood({ width = 24, height = 24, color }: BdsFoodProps) {
  const selectedColor = color ?? '#fff';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={selectedColor}
        d="M6.75 3.75417C6.3 3.75417 6 4.20467 6 4.505V8.25917H4.5V4.505C4.5 4.0545 4.2 3.75417 3.75 3.75417C3.3 3.75417 3 4.20467 3 4.505V8.25917H1.5V4.505C1.5 4.0545 1.2 3.75417 0.75 3.75417C0.3 3.75417 0 4.20467 0 4.505V9.4605C0 10.812 1.05 11.8632 2.25 12.0133V22.525C2.25 23.426 2.85 24.0267 3.75 24.0267C4.65 24.0267 5.25 23.426 5.25 22.525V12.0133C6.45 11.8632 7.5 10.812 7.5 9.4605V4.505C7.5 4.20467 7.2 3.75417 6.75 3.75417ZM9.75 4.505V13.515H11.25V22.525C11.25 23.426 11.85 24.0267 12.75 24.0267C13.65 24.0267 14.25 23.426 14.25 22.525V0C11.7 0 9.75 1.95217 9.75 4.505ZM20.25 3.00333C18.15 3.00333 16.5 5.25583 16.5 7.95884C16.35 9.76084 17.25 11.4127 18.75 12.4638V22.525C18.75 23.426 19.35 24.0267 20.25 24.0267C21.15 24.0267 21.75 23.426 21.75 22.525V12.4638C23.25 11.4127 24.15 9.76084 24 7.95884C24 5.25583 22.35 3.00333 20.25 3.00333Z"
      />
    </svg>
  );
}
