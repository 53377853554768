import {
  listManagerDataActions,
  UserPlanActionAccessData
} from '@/services/accessDataActions';
import { capitalize } from '@/utils/string';
import { useEffect, useState } from 'react';

export function useAccessDataListManagers(
  cpf?: string,
  ibge?: string,
  minimumDate?: string,
  order?: string
) {
  const [data, setData] = useState<UserPlanActionAccessData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    listManagerDataActions
      .get(cpf, ibge, minimumDate, order)
      .then((data) => {
        const da = data.map((d) => {
          return { ...d, name: capitalize(d.name) };
        });
        setData(da);
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [cpf, ibge, minimumDate, order]);

  return {
    data,
    loading,
    error
  };
}
