import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  background: ${(props) => (props.theme.darkMode ? '#000' : '#FFF')};
  border: 1px solid #dfe6e9;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 1.5rem;

  width: 100%;
  height: 100%;

  .box-tag {
    font-weight: 700;
    font-size: 16px;

    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 0.8rem;

    color: ${({ theme, color }) => {
      if (theme.darkMode) return colors.white;

      return color ?? colors.ciano700;
    }};
  }
`;
