import { AntSelect } from '@/components/styled';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

const { Option } = AntSelect;

const profileData = [
  { name: 'CMIC', value: 'cmic' },
  { name: 'JVJ', value: 'jvj' },
  { name: 'CMIC e JVJ', value: 'cmic+jvj' }
];

export function Filter() {
  const { profile, onChangeProfile } = useAnalyticsFilter();

  return (
    <BdSelect
      placeholder="Selecione o perfil de beneficiados"
      onChange={onChangeProfile}
      value={!!profile ? profile : undefined}
    >
      {profileData?.map((p) => (
        <Option key={p.name} value={`${p.value}`}>
          {p.name}
        </Option>
      ))}
    </BdSelect>
  );
}

const BdSelect = (props: any) => (
  <AntSelect {...props} allowClear showSearch bordered={false} />
);
