import { oauth2Agent } from '@/services/auth';
import { AiOutlineLogout } from 'react-icons/ai';

import { Box } from './styles';

interface LogoutButtonProps {
  showLabel?: boolean;
}

export function LogoutButton({ showLabel }: LogoutButtonProps) {
  const { logout } = oauth2Agent;

  return (
    <Box
      onClick={() =>
        logout({ redirectUrl: process.env.REACT_APP_REDIRECT_URL })
      }
    >
      <AiOutlineLogout size="26" color="#fff" />
      {showLabel && <span className="logout-text">Sair</span>}
    </Box>
  );
}
