import { useAction, useDashboardTitle } from '@/hooks';
import { Box } from './styles';
import { Filter, RegisterButton } from '../components';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Action } from 'big-data';
import * as R from 'ramda';

import { AccordionRoot, AccordionItem } from '../components/Accordion';
import { useUnifiedActions } from '../hooks/useUnifiedActions';

type RefreshActionByStatusParams = {
  axle: string;
  indicator: string;
  city: string;
  page: number;
  actionSearchTitle: string;
};

type ActionStatus = 'UNDONE' | 'BLOCKED' | 'IN_PROGRESS' | 'DONE';

const description =
  'A organização dos planos de ações é definida de acordo com o seu status. Planos de ações não concluídos são aqueles que passaram do prazo para execução e não atingiram a meta. Os planos em andamento são aqueles que estão em execução e poderão ser bloqueados mediante justificativa. Os planos concluídos são aqueles que cumpriram com suas metas.';

export function UnifiedList() {
  const [params] = useSearchParams();
  const axe = params.get('axe') ?? '';
  useDashboardTitle(`Lista de Planos do Município sobre ${axe}`);

  const [currentAccordionOpened, setCurrentAccordionOpened] =
    useState<ActionStatus>();
  const [page, setPage] = useState(-1);
  const [intersectionVisible, setIntersectionVisible] = useState(false);
  const [hasMorePlanActions, setHasMorePlanActions] = useState(true);

  const { indicator, ibge, actionSearchTitle } = useAction();

  const actionsUndone = useUnifiedActions('UNDONE', actionSearchTitle);
  const actionsBlocked = useUnifiedActions('BLOCKED', actionSearchTitle);
  const actionsInProgress = useUnifiedActions('IN_PROGRESS', actionSearchTitle);
  const actionsDone = useUnifiedActions('DONE', actionSearchTitle);

  const allActions: Record<
    ActionStatus,
    {
      name: string;
      color: string;
      actions: Action[];
      refresh: (params: RefreshActionByStatusParams) => void;
      loading: boolean;
      error: string;
      planActionsTotal: number;
      numberOfPages: number;
    }
  > = {
    IN_PROGRESS: {
      ...actionsInProgress,
      name: 'Planos em andamento',
      color: '#DDA83A'
    },
    BLOCKED: {
      ...actionsBlocked,
      name: 'Planos bloqueados',
      color: '#FF7800'
    },
    DONE: {
      ...actionsDone,
      name: 'Planos concluídos',
      color: '#55A546'
    },
    UNDONE: {
      ...actionsUndone,
      name: 'Planos não concluídos',
      color: '#D4282A'
    }
  };

  const allActionsEntries = Object.entries(allActions);

  function toggleAccordion(status: ActionStatus) {
    setCurrentAccordionOpened((current) => {
      if (current === status) return undefined;
      return status;
    });
  }

  function onRefreshActions() {
    if (page >= 0) {
      const currentOpenedAction = currentAccordionOpened
        ? allActions[currentAccordionOpened]
        : '';

      if (currentOpenedAction) {
        const { refresh, numberOfPages } = currentOpenedAction;
        const hasMore = numberOfPages > page;
        setHasMorePlanActions(hasMore);
        refresh({ axle: axe, city: ibge, indicator, page, actionSearchTitle });
      }
    }
  }

  function handleRegistered() {
    if (R.isEmpty(axe)) return;

    actionsDone.refresh({ axle: axe, indicator, city: ibge, page: 0 });
    actionsBlocked.refresh({ axle: axe, indicator, city: ibge, page: 0 });
    actionsInProgress.refresh({ axle: axe, indicator, city: ibge, page: 0 });
    actionsDone.refresh({ axle: axe, indicator, city: ibge, page: 0 });
  }

  function onResetPage() {
    setPage(-1);
  }

  useEffect(() => {
    onResetPage();
  }, [currentAccordionOpened, actionSearchTitle]);

  useEffect(() => {
    setPage((oldPage) => oldPage + 1);
  }, [intersectionVisible, hasMorePlanActions]);

  useEffect(() => {
    onRefreshActions();
  }, [page, currentAccordionOpened, axe, ibge, indicator, actionSearchTitle]); // eslint-disable-line

  return (
    <Box>
      <p>{description}</p>

      <div className="panels">
        <Filter indicatorHidden hasSearchFilter />
        <RegisterButton onRegistered={handleRegistered} />
      </div>

      <AccordionRoot>
        {allActionsEntries.map(([status, data]) => (
          <AccordionItem
            key={status}
            {...data}
            opened={currentAccordionOpened}
            toggle={toggleAccordion}
            status={status as ActionStatus}
            onEndReached={(visible) => setIntersectionVisible(visible)}
            hasMore={hasMorePlanActions}
          />
        ))}
      </AccordionRoot>
    </Box>
  );
}
