import styled from 'styled-components';
import { useRef } from 'react';
import { useObserverVisibility } from '@/hooks';
import { mediaQuery } from '@/theme/mediaQuery';
import { colors } from '@/theme/colors';

interface Props {
  title: string;
  text: string;
  delay: number;
  prefix?: string;
}

export const AnimatedTextFadeIn = ({
  title,
  prefix,
  text,
  delay = 0
}: Props) => {
  const refDom = useRef<any>();
  const visible = useObserverVisibility(refDom);

  return (
    <Wrapper ref={refDom}>
      {prefix && <Text>{prefix}</Text>}

      <TitleContainer>
        <Title visible={visible} delay={delay}>
          {title}
        </Title>
      </TitleContainer>
      <Text>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 0 0 0;
`;

const TitleContainer = styled.div`
  display: block;
  height: 36px;
  overflow-y: hidden;
  font-size: 38px;
  font-weight: bold;
  line-height: 1;
  color: ${colors.yellow200};
  ${mediaQuery.smallScreen} {
    font-size: 32px;
    height: 30px;
    text-align: center;
  }
`;

const Title = styled.div<{ visible: boolean; delay: number }>`
  display: block;
  transition: 0.6s transform;
  transition-delay: ${(props) => (props.delay || 0) + 300 + `ms`};
  transform: ${(props) => !props.visible && `translate(0,110%)`};
`;

const Text = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
  color: ${colors.yellow200};
  ${mediaQuery.smallScreen} {
    text-align: center;
    font-size: 1.1rem;
  }
`;
