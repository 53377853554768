import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div<{ noStyle?: boolean }>`
  margin: ${(props) => (props.noStyle ? 0 : `${1}rem ${0}rem`)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ noStyle, theme }) => {
    const baseColor = theme.darkMode ? colors.black : colors.white;
    return noStyle ? 'transparent' : baseColor;
  }};
  padding: 20px;
  border-radius: 7px;

  border: ${(props) => (props.noStyle ? 'none' : '1px solid #dfe6e9;')};
  width: 100%;

  .icon-text-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    font-weight: 400;
    font-size: 0.875rem; // 14px
    line-height: 16px;

    color: ${(props) => props.theme.text};
  }

  .select-wrapper {
    display: flex;
    gap: 10px;

    .select-group {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    button.clear-filter {
      justify-self: flex-start;

      background: none;
      border: none;
      color: ${({ color, theme }) =>
        theme.darkMode ? theme.yellowDark : colors.ciano700};
      text-decoration: ${({ theme }) =>
        theme.darkMode ? 'underline' : 'none'};

      display: flex;
      align-items: center;
      transition: filter 0.2s;

      span {
        font-weight: 700;
        font-size: 0.8rem;
        margin-top: 3px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media (max-width: 1300px) {
    .select-wrapper {
      flex-direction: column;
    }

    .ant-tabs-nav-wrap {
      justify-content: center;
    }
  }

  @media (max-width: 1329px) {
    flex-direction: column;
    gap: 20px;

    .select-group {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 660px) {
    .ant-select {
      width: 50%;
    }
  }

  @media (max-width: 550px) {
    .ant-select {
      width: 100%;
    }

    .select-group {
      flex-direction: column;
    }
  }
`;
