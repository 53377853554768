import React from 'react';

interface BdsAnalyticsPanelProps {
  color?: string;
}

export function BdsAnalyticsPanel({
  color = '#ffffff'
}: BdsAnalyticsPanelProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M3.75 21h6.75v-3a3 3 0 014.5-2.598V12a3 3 0 016 0V6H0v11.25A3.75 3.75 0 003.75 21zm13.5-21A3.75 3.75 0 0121 3.75v.75H0v-.75A3.75 3.75 0 013.75 0h13.5zM18 10.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 103 0V12a1.5 1.5 0 00-1.5-1.5zm-4.5 6A1.5 1.5 0 0012 18v4.5a1.5 1.5 0 103 0V18a1.5 1.5 0 00-1.5-1.5zM21 15a1.5 1.5 0 113 0v7.5a1.5 1.5 0 11-3 0V15z"
      ></path>
    </svg>
  );
}
