import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from '@/theme/colors';

const buttonDarkStyles = css`
  border: 1px solid ${({ theme }) => theme.yellowDark};
  color: ${({ theme }) => theme.yellowDark};

  &:not(:disabled):hover {
    background: ${({ theme }) => darken(0.3, theme.yellowDark)};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => darken(0.3, theme.yellowDark)};
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.text};

  .ant-input-affix-wrapper {
    background: ${(props) =>
    props.theme.darkMode ? props.theme.background : '#FFF'};
    border-radius: 7px;
    border: 1px solid #dfe6e9;
    width: 240px;
    margin: 0 1rem;

    .ant-input {
      background-color: inherit;
      color: ${({ theme }) => (theme.darkMode ? colors.white : ``)};
    }

    svg path {
      fill: ${({ theme }) => (theme.darkMode ? colors.white : ``)};
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid #dfe6e9;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;

    user-select: none;

    @media (max-width: 470px) {
      flex-direction: column;
      gap: 1rem;
    }

    .show-count {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    .button-group {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        width: 36px;
        height: 36px;
        border: 1px solid ${colors.ciano700};
        background: transparent;
        border-radius: 4px;
        color: ${colors.gray700};

        display: flex;
        align-items: center;
        padding: 10px;
        transition: background 0.2s;

        &:not(:disabled):hover {
          background: #969CA4;
        }

        &:disabled {
          cursor: not-allowed;
          color: #cbd5e1;
          border: 1px solid #cbd5e1;
        }

        ${({ theme }) => theme.darkMode && buttonDarkStyles}
      }
    }
  }
`;

export const Box = styled.div<{ size: number; numberValues: boolean }>`
  border-bottom: 1px solid #f1f5f9;
  padding: 1rem 0;
  padding-bottom: 0rem;

  .box-city,
  .box-title {
    display: ${(props) => (props.numberValues ? 'flex' : 'grid')};
    grid-template-columns: ${(props) => `repeat(${props.size}, 1fr)`};
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    div {
      height: 100%;
    }

    p {
      text-align: left;
    }

    .percent {
      min-width: 80px;
      text-align: right;
    }
  }

  .box-title {
    p {
      font-weight: 700;
    }
  }
`;

export const EmptyList = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #bababa;
  }
`;