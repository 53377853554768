import styled from 'styled-components';
import { Group as VisxGroup } from '@visx/group';
import { Text as VisxText } from '@visx/text';
import { colors } from '@/theme/colors';

export const Svg = styled.svg`
  .pie {
    cursor: pointer;
  }

  .tooltip-content-data {
    color: ${({ theme }) => (theme.darkMode ? theme.background : ``)};
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    margin-top: 20px;

    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;

    li {
      text-transform: capitalize;
    }
  }
`;

export const Group = styled(VisxGroup)`
  display: flex;
  flex-direction: column;

  .small-text {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
`;

export const Text = styled(VisxText)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1rem;

  text-align: center;
  letter-spacing: 1.25625px;
  text-transform: uppercase;

  fill: ${({ theme }) => theme.text};
`;

interface LegendItemProps {
  color: string;
}

export const LegendItem = styled.li<LegendItemProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => (theme.darkMode ? colors.white : ``)};

  strong,
  span {
    font-size: 0.9rem;
    line-height: 1rem;
  }

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: ${({ color }) => color};
    flex: 1;
  }
`;
