import { useEffect } from 'react';
import { IoMan, IoWoman } from 'react-icons/io5';
import { Data } from 'big-data';

import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { useService } from '@/hooks';
import { cmic } from '@/services';

import { IconBox, Text } from './UserSex.styles';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';

const sexWord = (value: string) =>
  value === 'Feminino' ? 'mulheres' : 'homens';

export function UserSex() {
  const { data, loading, error, request } = useService<Data>();
  const { ibge, city } = useAnalyticsFilter();

  useEffect(() => {
    request(() => cmic.getSexInfo(ibge ? Number(ibge) : undefined));
  }, [request, ibge]);

  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const counts = data.map(({ name, value }) => ({
    name,
    value: Number(((10 * value) / total).toFixed(2))
  }));

  const max = counts.reduce(
    (acc, cur) => (cur.value >= acc.value ? cur : acc),
    { name: 'example', value: 0 }
  );

  const minSex = max.name === 'Feminino' ? 'Masculino' : 'Feminino';

  const numberRound = Math.round(max.value);

  return (
    <Section>
      <Card
        title="Gênero dos(as) responsáveis familiares cadastrados(as) no programa Cartão Mais Infância"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ? city : 'Ceará']}
      >
        <IconBox>
          {Array(numberRound)
            .map((_, i) => ({ id: i }))
            .map((item) =>
              max.name === 'Feminino' ? (
                <IoWoman key={item.id} className="icon" />
              ) : (
                <IoMan key={item.id} className="icon" />
              )
            )}

          {Array(10 - numberRound)
            .map((_, i) => ({ id: i }))
            .map((item) =>
              minSex === 'Feminino' ? (
                <IoWoman key={item.id} className="icon less" />
              ) : (
                <IoMan key={item.id} className="icon less" />
              )
            )}
        </IconBox>

        <Text>
          <span>{max.value} em cada 10</span>
          <p>
            responsáveis familiares cadastrados(as) no Mais Infância são{' '}
            {sexWord(max.name)}
          </p>
        </Text>
      </Card>
    </Section>
  );
}
