import { Section } from '@/layout';
import { Card, WordCloud } from '@/components/shared';

import { useService } from '@/hooks';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { cmic } from '@/services';
import { Data } from 'big-data';
import { useEffect } from 'react';

export function WallMaterial() {
  const { ibge, city } = useAnalyticsFilter();
  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => cmic.getWallMaterial(ibge ? Number(ibge) : undefined));
  }, [request, ibge]);

  return (
    <Section>
      <Card
        title="Tipo de material das paredes externas dos domicílios dos beneficiados"
        empty={data.length === 0}
        loading={loading}
        error={error}
        date="2021"
        tags={[city ? city : 'Ceará']}
      >
        <WordCloud words={data} />
      </Card>
    </Section>
  );
}
