import { signTermManager } from '@/services/manager';
import { Checkbox, ModalProps } from 'antd';
import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Show } from '..';
import { Content, Title, Footer, Modal } from './styles';
import { Loading } from '@/components/shared';

import { oauth2Agent } from '@/services/auth';
import { RefuseUseTermsModal } from './RefuseUseTermsModal';

interface UseTermsModalProps extends ModalProps {
  loginError: Error | AxiosError | null;
}

const linkProps: LinkProps = {
  to: '/info/termos',
  target: '_blank',
  referrerPolicy: 'no-referrer'
};

const LOGIN_ERROR_MESSAGE =
  'Por favor, aceite os termos de uso do Big Data Social.';

export function UseTermsModal({ loginError, ...rest }: UseTermsModalProps) {
  const { logout } = oauth2Agent;
  const { darkMode } = useTheme();

  const [isAgreed, setIsAgreed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState<
    Error['message'] | AxiosError['message'] | null
  >(null);
  const [acceptTermsLoading, setAcceptTermsLoading] = useState(false);
  const [refuseUseTermsModalOpen, setRefuseUseTermsModalOpen] = useState(false);

  async function handleAcceptTerms() {
    setAcceptTermsLoading(true);

    try {
      await signTermManager.post();
      closeModal();
      window.location.reload();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response)
        setAcceptTermsError(error.response.data.mensagem);
      else if (error instanceof Error) setAcceptTermsError(error.message);
    } finally {
      setAcceptTermsLoading(false);
    }
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  function onDeny() {
    logout({ redirectUrl: process.env.REACT_APP_REDIRECT_URL });
  }

  useEffect(() => {
    if (!loginError) return;

    if (
      axios.isAxiosError(loginError) &&
      loginError.response &&
      loginError.response.data.message === LOGIN_ERROR_MESSAGE
    ) {
      const loginErrorStatus = loginError.response.status === 403;
      setIsModalVisible(loginErrorStatus);
    }
  }, [loginError]);

  return (
    <Modal
      {...rest}
      visible={isModalVisible}
      centered
      zIndex={99999}
      className="use-terms-modal"
      closable={false}
      title={<Title>Termos e Condições de Uso</Title>}
      footer={
        <Footer>
          <button
            className="deny"
            onClick={() => setRefuseUseTermsModalOpen(true)}
          >
            Recusar
          </button>
          <button
            className="accept"
            onClick={handleAcceptTerms}
            disabled={!isAgreed || acceptTermsLoading}
          >
            Aceitar
            <Show when={acceptTermsLoading}>
              <Loading
                color={darkMode ? `#000` : `#fff`}
                display="inline"
                size={20}
              />
            </Show>
          </button>
        </Footer>
      }
      style={{ borderRadius: 16 }}
      width={572}
      afterClose={() => setIsAgreed(false)}
    >
      <Content>
        <p>
          Por favor, leia atentamente nossos{' '}
          <Link {...linkProps}>Termos de Uso</Link> antes de continuar. Ao
          prosseguir, você concorda em cumprir com as políticas e condições
          estabelecidas. Esteja ciente de que podemos atualizar esses termos a
          qualquer momento e é responsabilidade do usuário revisá-los
          periodicamente.
        </p>

        <div className="agreement">
          <Checkbox
            checked={isAgreed}
            onChange={(e) => setIsAgreed(e.target.checked)}
          >
            <span>
              Eu li e concordo com os{' '}
              <Link {...linkProps}>termos e condições</Link> do site.
            </span>
          </Checkbox>
        </div>
        <Show when={!!acceptTermsError}>
          <p className="error">Algum erro ocorreu: {acceptTermsError}</p>
        </Show>
      </Content>

      <RefuseUseTermsModal
        visible={refuseUseTermsModalOpen}
        onConfirm={onDeny}
        onCancel={() => setRefuseUseTermsModalOpen(false)}
      />
    </Modal>
  );
}
