import { useEffect, useMemo, useState } from 'react';

import { CardTagged, D3Map, ServiceBox } from '@/components/shared';
import { Text, ColorText } from '@/components/styled';
import { useAuth, useDashboardFilter } from '@/hooks';
import { useCitiesData } from '@/servicesHooks/livingConditionAgents';
import { max } from '@/utils/math';
import { colors } from '@/theme/colors';

import { Grid, GridItem } from './styles';
import { useTheme } from 'styled-components';

const format = new Intl.NumberFormat('pt-BR').format;

const color = colors.green700;

export function LivingConditionLeftSection() {
  const theme = useTheme();
  const { city, tag, ibge } = useDashboardFilter();
  const [maxValueCities, setMaxValueCities] = useState<number>();

  const cityText = !!city ? (
    <>
      no município de<ColorText color={color}> {city}?</ColorText>
    </>
  ) : (
    `por município?`
  );

  const {
    cities,
    loading: loadingCities,
    error: errorCities
  } = useCitiesData();

  const { isAdm } = useAuth();

  useEffect(() => {
    if (!city || !isAdm) {
      const values = cities.map((d) => d.value);
      const maxValue = max(values);
      setMaxValueCities(maxValue);
    }
  }, [city, cities, isAdm]);

  const total = useMemo(
    () => format(cities.reduce((acc, cur) => acc + cur.value, 0)),
    [cities]
  );

  return (
    <Grid>
      <GridItem area="a">
        <CardTagged tag={tag}>
          <Text>
            <strong>
              Qual a quantidade de famílias com CMIC visitadas {cityText}
            </strong>
          </Text>

          <ServiceBox
            empty={cities.length === 0}
            loading={loadingCities}
            error={errorCities}
          >
            <Text>
              <ColorText>{total} famílias</ColorText> em situação de
              vulnerabilidade social, com crianças de 0 a 5 anos e 11 meses,
              recebem o benefício
            </Text>

            <D3Map
              data={cities}
              color={theme.darkMode ? colors.ming100 : colors.ciano700}
              filteringCity={ibge}
              maxValue={maxValueCities}
            />
          </ServiceBox>
        </CardTagged>
      </GridItem>
    </Grid>
  );
}
