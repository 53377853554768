import { PyramidTextWrapper } from './styles';
import { Text } from '@/components/styled';
import { percentageFormatter } from '@/utils/string';
import { DataPercentageComputed } from 'big-data';

interface LegendProps {
  data: DataPercentageComputed[];
  colors: string[];
  hoveredPiece?: string;
  onChangeHoveredPiece: (hoveredPiece?: string) => void;
}

export function Legend({
  data,
  colors,
  hoveredPiece,
  onChangeHoveredPiece
}: LegendProps) {
  return (
    <div className="pyramid-legend">
      {data.map((d, i) => (
        <PyramidTextWrapper
          key={d.name}
          bg={colors[i]}
          highlight={!!hoveredPiece ? hoveredPiece === d.name : undefined}
          onMouseEnter={() => onChangeHoveredPiece(d.name)}
          onMouseLeave={() => onChangeHoveredPiece(undefined)}
        >
          <div className="text-wrapper">
            <Text className="highlighted-label">
              <strong>
                {percentageFormatter(d.percentageValue)} das famílias
              </strong>
            </Text>
            <Text mb={'0'}>{d.name}</Text>
          </div>
        </PyramidTextWrapper>
      ))}
    </div>
  );
}
