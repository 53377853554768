import styled, { keyframes, css } from 'styled-components';
import { colors } from '@/theme/colors';

interface BoxProps {
  isClientComment: boolean;
}

const GAP = '1.2rem';
const PADDING_HORIZONTAL = '1.2rem';
const ICON_SIZE = '40px';

const showMessage = keyframes`
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const getPaddingCommentDetails = (isClientComment: boolean) => {
  if (isClientComment) {
    return css`
      padding-right: calc(${ICON_SIZE} + ${GAP} + ${PADDING_HORIZONTAL});
    `;
  }

  return css`
    padding-left: calc(${ICON_SIZE} + ${GAP} + ${PADDING_HORIZONTAL});
  `;
};

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;

  width: 100%;

  animation: ${showMessage} 0.2s forwards;

  .comment-content,
  .file {
    display: flex;

    justify-content: ${({ isClientComment }) =>
      isClientComment ? 'flex-end' : 'flex-start'};
  }

  .comment-content {
    gap: ${GAP};
    padding: 0 ${PADDING_HORIZONTAL};
    width: 100%;

    .answer-icon {
      width: 25px;
      height: 25px;
      margin-block-start: 16px;
      order: ${({ isClientComment }) => (isClientComment ? '1' : '3')};
      order: ${({ isClientComment }) => (isClientComment ? '1' : '3')};
      transform: scaleX(${({ isClientComment }) => (isClientComment ? -1 : 1)});
      fill: none;
      stroke: ${colors.ciano700};
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        fill: ${colors.ciano700};
      }
    }

    .icon {
      background-color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.ciano700};
      color: ${({ theme }) => (theme.darkMode ? colors.black : colors.white)};
      font-weight: 700;
      width: ${ICON_SIZE};
      height: ${ICON_SIZE};
      border-radius: 50%;

      order: ${({ isClientComment }) => (isClientComment ? '3' : '1')};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  svg {
    stroke: ${({ theme }) =>
      theme.darkMode ? colors.yellow500 : colors.ciano700} !important;
  }

  .file {
    ${({ isClientComment }) => getPaddingCommentDetails(isClientComment)};

    margin-top: 0.3rem;
    button {
      background-color: ${colors.gray100};
      color: ${colors.green700};

      padding: 0.4rem 1rem;
      border: none;
      border-radius: 7px;
      display: flex;
      gap: 0.3rem;

      svg {
        margin-top: 2.5px;
        path {
          fill: ${({ theme }) =>
            theme.darkMode ? colors.yellow500 : colors.ciano700};
        }
      }
    }
  }
`;
