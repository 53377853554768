import { Data } from 'big-data';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

interface ItemType extends Data {
  name: string;
  value: number;
  color: string;
  formatted?: string;
}

interface ItemTypeFormatted extends Omit<ItemType, 'value'> {
  value: number | string;
}

interface LegendProps {
  items: ItemType[];
  selected: Data | null;
  percentage?: boolean;
  highlight?: (data: ItemTypeFormatted) => boolean;
  onClick?: (data: Data) => void;
  hiddenValue?: boolean;
  noFilter: boolean;
}

const percentFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const numFormatter = new Intl.NumberFormat('pt-BR');

export function Legend({
  items,
  selected,
  percentage,
  highlight,
  onClick,
  hiddenValue,
  noFilter
}: LegendProps) {
  const total = useMemo(
    () => items.reduce((acc, cur) => acc + cur.value, 0),
    [items]
  );

  const legendItems = useMemo<ItemTypeFormatted[]>(() => {
    if (!percentage)
      return items.map((item) => ({
        ...item,
        value: numFormatter.format(item.value)
      }));

    return items
      .map((item) => ({ ...item, value: item.value / total }))
      .map((item) => ({
        ...item,
        formatted: percentFormatter.format(item.value)
      }));
  }, [percentage, items, total]);

  return (
    <Box>
      {legendItems.map((item) => {
        const value = percentage ? item.formatted : item.value;

        return (
          <Item
            key={item.name}
            beforeBackgroundColor={item.color}
            opacityMinus={selected !== null && item.name !== selected.name}
            highlight={
              !!highlight ? highlight(item) : item.name === selected?.name
            }
            onClick={() => {
              if (noFilter) return
              onClick?.({ name: item.name, value: Number(item.value) })
            }
            }
            isClickable={!noFilter}
          >
            {!hiddenValue ? value : ''} {item.name}
          </Item>
        );
      })}
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Item = styled.span<{
  beforeBackgroundColor: string;
  opacityMinus: boolean;
  highlight: boolean;
  isClickable: boolean;
}>`
  display: flex;
  font-size: 1rem;
  align-items: center;
  align-self: flex-start;
  cursor: ${(props) => (props.isClickable ? 'point' : 'default')};

  color: ${(props) => (props.theme.darkMode ? `#fff` : `#3d5164`)};

  ${(props) =>
    props.opacityMinus &&
    css`
      opacity: 0.1;
    `};

  &::before {
    content: '';
    display: inline-block;
    min-width: 12px;
    min-height: 12px;
    max-width: 12px;
    max-height: 12px;
    border-radius: 100%;

    background: ${(props) => props.beforeBackgroundColor};
    margin-right: 10px;
  }

  &:hover {
    opacity: ${(props) => props.isClickable ? '0.6' : '1'};
  }

  ${({ highlight }) =>
    highlight &&
    css`
      font-weight: bold;
    `}
`;
