import { mediaQuery } from '@/theme/mediaQuery';
import styled from 'styled-components';

const COLORS = [
  `#FFE86F`,
  `#FFDE32`,
  `#FFCA16`,
  `#55900E`,
  `#55A546`,
  `#87C878`
];

export const StripColors = () => {
  return (
    <Wrapper>
      {COLORS.map((color) => (
        <Column key={color} color={color} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 10px;
  ${mediaQuery.smallScreen} {
    height: 7px;
  }
`;

const Column = styled.div`
  flex: 1;
  background: ${(props) => props.color};
`;
