import { Data, RichedAction } from 'big-data';
import * as R from 'ramda';

import {
  education,
  foodInsecurity,
  healthAgents,
  healthPregnant,
  jobIncome,
  socialAssistance,
  actions as actionsService
} from '@/services';
import {
  Axes,
  EducationIndicators,
  HealthIndicators,
  JobIncomingIndicators,
  SocialAssistanceIndicators
} from './constants';
import { getCurrentResults } from './utils';

import {
  RequestConfig,
  RequestDataConfig,
  EducationResponse,
  DataWithType
} from './types';

const getFoodInsecurity = async ({
  ibge,
  classificationType
}: RequestConfig) => {
  const foodInsecurityData = await foodInsecurity.getByClassification(ibge);

  const cleanData = R.pipe<[Data[]], Data[], DataWithType[]>(
    R.filter((data: Data) =>
      classificationType
        ? data.name.toLowerCase() === classificationType.toLowerCase()
        : true
    ),
    R.map((data: Data) => ({ ...data, name: R.toLower(data.name) }))
  );
  return [cleanData(foodInsecurityData)];
};

const getEducationNursery = async (ibge?: string) =>
  education.getNursery({ ibge }).then((data) => data as EducationResponse[]);

const getEducationPreSchool = async (ibge?: string) =>
  education.getPreSchool({ ibge }).then((data) => data as EducationResponse[]);

const getEducationSchool = async (ibge?: string) =>
  education.getSchool({ ibge }).then((data) => data as EducationResponse[]);

const getEducation = async ({ ibge, classificationType }: RequestConfig) => {
  const getCleanData = R.pipe<[EducationResponse[]], any[], DataWithType[]>(
    R.filter<EducationResponse>((data) => data.schoolMode !== 'null'),
    R.map((data) => ({
      name: R.toLower(data.name),
      value: data.value,
      type: data.schoolMode
    }))
  );

  switch (classificationType) {
    case EducationIndicators.CRECHE:
      return getEducationNursery(ibge).then(
        (data) => {
          const d = getCleanData(data)
          return d.length > 0 ? [d] as DataWithType[][] : []
        }
      );
    case EducationIndicators.PRE_ESCOLA:
      return getEducationPreSchool(ibge).then(
        (data) => {
          const d = getCleanData(data)
          return d.length > 0 ? [d] as DataWithType[][] : []
        }
      );
    case EducationIndicators.ESCOLA:
      return getEducationSchool(ibge).then(
        (data) => {
          const d = getCleanData(data)
          return d.length > 0 ? [d] as DataWithType[][] : []
        }
      );
    default:
      return Promise.all([
        getEducationNursery(ibge),
        getEducationPreSchool(ibge),
        getEducationSchool(ibge)
      ]).then((data) => data.map(getCleanData).filter(d => d.length > 0));
  }
};

const getCREAS = (ibge?: string) => socialAssistance.getByCREAS(ibge);
const getCRAS = (ibge?: string) => socialAssistance.getByCRAS(ibge);

const getSocialAssistance = async ({
  ibge,
  classificationType
}: RequestConfig) => {
  switch (classificationType) {
    case SocialAssistanceIndicators.CREAS:
      return getCREAS(ibge).then((data) => [data] as DataWithType[][]);
    case SocialAssistanceIndicators.CRAS:
      return getCRAS(ibge).then((data) => [data] as DataWithType[][]);
    default:
      return Promise.all([getCREAS(ibge), getCRAS(ibge)]).then(
        (data) => data as DataWithType[][]
      );
  }
};

const getPreNatal = (ibge?: string) => healthPregnant.getPreNatal({ ibge });
const getVaccineUpToDate = (ibge?: string) =>
  healthAgents.getVaccineUpToDate({ ibge });
const getChildHealth = (ibge?: string) => healthAgents.getChildHealth(ibge);

const getHealth = async ({ ibge, classificationType }: RequestConfig) => {
  switch (classificationType) {
    case HealthIndicators.CEVI:
      return getVaccineUpToDate(ibge).then(
        (data) => [data] as DataWithType[][]
      );
    case HealthIndicators.GSPN:
      return getPreNatal(ibge).then((data) => [data] as DataWithType[][]);
    case HealthIndicators.CSES:
      return getChildHealth(ibge).then((data) => [data] as DataWithType[][]);
    default:
      return Promise.all([
        getPreNatal(ibge),
        getVaccineUpToDate(ibge),
        getChildHealth(ibge)
      ]).then((data) => data as DataWithType[][]);
  }
};

const getIncome = (ibge?: string) => jobIncome.getIncome({ ibge });
const getNoJob = (ibge?: string) => jobIncome.getNoJob({ ibge });

const getJobIncoming = async ({ ibge, classificationType }: RequestConfig) => {
  switch (classificationType) {
    case JobIncomingIndicators.Job:
      return getIncome(ibge).then((data) => [data] as DataWithType[][]);
    case JobIncomingIndicators.Salary:
      return getNoJob(ibge).then((data) => [data] as DataWithType[][]);
    default:
      return Promise.all([getIncome(ibge), getNoJob(ibge)]).then(
        (data) => data as DataWithType[][]
      );
  }
};

export const getData = ({
  axle,
  ibge,
  classificationType
}: RequestDataConfig) => {
  switch (axle) {
    case Axes.InsecurityFood:
      return getFoodInsecurity({ ibge, classificationType });
    case Axes.Education:
      return getEducation({ ibge, classificationType });
    case Axes.SocialAssistance:
      return getSocialAssistance({ ibge, classificationType });
    case Axes.Health:
      return getHealth({ ibge, classificationType });
    case Axes.JobIncoming:
      return getJobIncoming({ ibge, classificationType });
    default:
      return Promise.resolve([] as DataWithType[][]);
  }
};

interface ActionParams {
  axle?: string;
  ibge: string;
  indicator: string;
  page: number;
  size: number;
}

export async function getActions({
  axle,
  ibge,
  indicator,
  page,
  size
}: ActionParams): Promise<RichedAction[]> {
  try {
    const results = await getCurrentResults({ axle: axle ?? '', ibge });
    const { content: actions } = await actionsService.getActions(
      axle,
      ibge,
      indicator,
      page,
      size
    );

    return actions.map((action) => ({
      ...action,
      value: results[action.indicator]
    }));
  } catch (err) {
    return Promise.reject(err);
  }
}
