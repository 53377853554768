import { ensureAxiosParamOptions } from '@/utils/routes';
import { sumDataTotal } from '@/utils/data';
import { Data } from 'big-data';
import { DashboardRequestParams } from 'big-data/service';
import { mainApi } from './config/api';
import { callService } from './config/service';
import { getQuery } from './config/utils';

interface DataBool extends Data {
  bool: boolean;
}

function changeNull(data: Data[]) {
  return data.filter((d) => d.name !== 'null');
}

function calcPercentageExams(data: DataBool[]): Data[] {
  const total = sumDataTotal(data);
  return data
    .filter((d) => d.bool)
    .map((d) => {
      return {
        name: d.name,
        value: (d.value / total) * 100
      };
    });
}

const service = () => {
  const resource = `/gestante-saude`;

  async function request(
    path: string,
    params: DashboardRequestParams & { aggregations: string[] }
  ) {
    const { aggregations, ibge, year, semester, zone } = params;

    const options = ensureAxiosParamOptions({
      params: {
        aggregations: getQuery(aggregations),
        ibge,
        year,
        semester,
        zone
      }
    });

    const response = await callService(() => mainApi.get<any[]>(path, options));

    return response.data;
  }

  async function getPregnantAgeGroupByHome(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['FAIXA_ETARIA_GRAVIDA'];

    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getPregnantBook(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['POSSUI_CADERNETE_GESTANTE'];

    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getPreNatal(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['FAZ_ACOMPANHAMENTO_PRE_NATAL'];

    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade,
        type: 'Assistência Pré-Natal'
      }))
    );
  }

  async function getPreNatalActionRegistration(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['FAZ_ACOMPANHAMENTO_PRE_NATAL'];

    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getPregnantSupply(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['RECEBEU_SUPLEMENTACAO_ACIDOFOLICO_SULFATOFERROSO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getPregnantByHome(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    return changeNull(
      data.map((d) => ({
        name: d[aggregations[0].toLowerCase()],
        value: d.quantidade
      }))
    );
  }

  async function getBloodGlucoseExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_GLICEMIA', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Glicemia',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getBloodCountExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_HEMOGRAMA', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Hemograma',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getHepatitsBExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_HEPATITEB', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Hepatite B',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getHIVExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_HIV', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Testes de HIV',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getSyphilisExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_SIFILIS_VDRL', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Testagem para sífilis e VDRL',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getToxoplasmosisExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_TAXOPLASMOSE', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Toxoplasmose',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getBloodTypingExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = [
      'EXAME_TIPAGEM_SAGUINEA_FATORH',
      'EXISTE_GRAVIDA_DOMICILIO'
    ];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Tipagem sanguínea e fator Rh',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getUltrasoundExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_ULTRASSONOGRAFIA', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Ultrassonografia',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  async function getNoneExam(params: DashboardRequestParams) {
    const path = resource;
    const aggregations = ['EXAME_ULTRASSONOGRAFIA', 'EXISTE_GRAVIDA_DOMICILIO'];
    const newParams = {
      aggregations,
      ...params
    };

    const data = await request(path, newParams);

    const result = data
      .filter((d) => d[aggregations[1].toLowerCase()] === 'Sim')
      .map((d) => ({
        name: 'Nenhum',
        value: d.quantidade,
        bool: d[aggregations[0].toLowerCase()]
      }));

    return calcPercentageExams(result);
  }

  return {
    getPregnantAgeGroupByHome,
    getPregnantBook,
    getPreNatal,
    getPregnantSupply,
    getPregnantByHome,
    getBloodGlucoseExam,
    getBloodCountExam,
    getHepatitsBExam,
    getHIVExam,
    getSyphilisExam,
    getToxoplasmosisExam,
    getBloodTypingExam,
    getUltrasoundExam,
    getNoneExam,
    getPreNatalActionRegistration
  };
};

export const healthPregnant = service();
