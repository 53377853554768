import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.div`
  margin-bottom: 1.5rem;

  label.ant-radio-wrapper {
    span {
      color: ${({ theme }) => (theme.darkMode ? colors.white : colors.black)};
    }
  }

  .label {
    font-weight: 700;
    color: ${({ theme }) => (theme.darkMode ? colors.white : colors.gray600)};
    padding-bottom: 0.5rem;

    .required {
      color: ${({ theme }) =>
        theme.darkMode ? colors.yellow500 : colors.red400};
    }
  }
`;
