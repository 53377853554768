import {
  Loading as LoadingComponent,
  PlatformSatisfaction,
  Show
} from '@/components/shared';
import { UseTermsModal } from '@/components/shared/UseTermsModal';
import {
  DashboardFilterProvider,
  DashboardTitleProvider,
  useLoginLoader
} from '@/hooks';
import { useManagerLogin } from '@/servicesHooks/manager/useManagerLogin';
import { Outlet } from 'react-router-dom';
import { HeaderAccessibility } from '../HeaderAccessibility';
import { HeaderDashboard } from '../HeaderDashboard';
import { MenuDashboard } from '../MenuDashboard';
import { Box, LoadingBox } from './styles';

function Loading() {
  return (
    <LoadingBox>
      <LoadingComponent size={70} />
      <p className="message">Carregando dados, por favor aguarde...</p>
    </LoadingBox>
  );
}

export function Dashboard() {
  const { loading } = useLoginLoader();
  const { data, error } = useManagerLogin();

  return (
    <DashboardFilterProvider>
      <DashboardTitleProvider>
        <Box>
          <HeaderAccessibility />
          <MenuDashboard />
          <Show when={!loading} fallback={<Loading />}>
            <div className="content">
              <HeaderDashboard />
              <Outlet />
            </div>
          </Show>

          <PlatformSatisfaction data={data} error={error} />
          <UseTermsModal loginError={error} />
        </Box>
      </DashboardTitleProvider>
    </DashboardFilterProvider>
  );
}
