import { Box } from '../styles';

import { Card1 } from './Card1';
import { Card2 } from './Card2';
import { Card3 } from './Card3';
import { Card4 } from './Card4';
import { ThirdTitle } from '@/components/styled';

export function LeaderProfile() {
  return (
    <>
      <ThirdTitle>Perfil das lideranças das organizações</ThirdTitle>
      <Box>
        <Card2 />
        <Card1 />
        <Card3 />
        <Card4 />
      </Box>
    </>
  );
}
