export function NoProgramIcon() {
  return (
    <svg
      width="46"
      height="45"
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2453_6035)">
        <path
          d="M23.4512 0C11.0447 0 0.951172 10.0935 0.951172 22.5C0.951172 34.9065 11.0447 45 23.4512 45C35.8577 45 45.9512 34.9065 45.9512 22.5C45.9512 10.0935 35.8577 0 23.4512 0ZM23.4512 40.5C13.5258 40.5 5.45117 32.4252 5.45117 22.5C5.45117 12.5748 13.5258 4.5 23.4512 4.5C33.3765 4.5 41.4512 12.5748 41.4512 22.5C41.4512 32.4252 33.3765 40.5 23.4512 40.5Z"
          fill="#D42929"
        />
        <path
          d="M33.2921 12.6589C32.4136 11.7804 30.9889 11.7804 30.1102 12.6589L23.4512 19.3179L16.7921 12.6589C15.9136 11.7804 14.4889 11.7804 13.6102 12.6589C12.7315 13.5375 12.7315 14.9622 13.6102 15.8409L20.2693 22.4998L13.6103 29.1589C12.7316 30.0375 12.7316 31.4622 13.6103 32.3409C14.0495 32.7801 14.6254 32.9998 15.2012 32.9998C15.7771 32.9998 16.3529 32.7801 16.7921 32.3407L23.4512 25.6818L30.1103 32.3407C30.5495 32.7801 31.1254 32.9998 31.7012 32.9998C32.2771 32.9998 32.8529 32.7801 33.2921 32.3407C34.1708 31.4622 34.1708 30.0375 33.2921 29.1588L26.6332 22.4998L33.2921 15.8407C34.1708 14.9622 34.1708 13.5375 33.2921 12.6589Z"
          fill="#D42929"
        />
      </g>
      <defs>
        <clipPath id="clip0_2453_6035">
          <rect
            width="45"
            height="45"
            fill="white"
            transform="translate(0.951172)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
