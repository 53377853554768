import { FilterAnalytic } from '@/components/shared/FilterAnalytic';
import { ThirdTitle } from '@/components/styled';
import { colors } from '@/theme/colors';
import { useTheme } from 'styled-components';
import { Filter } from './Filter';
import { SectionMap } from './SectionMap';
import { Box } from './styles';

export function CoisInsights() {
  const { darkMode } = useTheme();
  const color = darkMode ? colors.ming100 : colors.ciano700;

  return (
    <Box>
      <ThirdTitle>
        Disitribuição geográfica dos cursos de capacitação
      </ThirdTitle>

      <FilterAnalytic>
        <Filter />
      </FilterAnalytic>

      <SectionMap color={color} />
    </Box>
  );
}
