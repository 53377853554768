import { Card, DonutPopulated } from '@/components/shared';
import { useService } from '@/hooks';
import { targetAudienceService } from '@/services/womenMapping';
import { Data } from 'big-data';
import { useEffect } from 'react';
import { useFilter } from '../../hooks/useFilter';
import { colors } from '@/theme/colors';

const infoMapping = new Map([
  ['Recebem salário por trabalho formal', { color: colors.ciano700 }],
  ['Recebem salário por trabalho informal', { color: '#55A546' }],
  ['Recebem benefício do governo', { color: '#FFB02E' }],
  ['Não têm renda própria', { color: colors.red400 }]
]);

export function Card8() {
  const { city, ibge } = useFilter();

  const { data, error, loading, request } = useService<Data>();

  useEffect(() => {
    request(() => targetAudienceService.getIncome(ibge));
  }, [request, ibge]);

  const donutData = data.map((d) => {
    const { color } = infoMapping.get(d.name) || {};

    return {
      ...d,
      color
    };
  });

  return (
    <Card
      title="Porcentagem das mulheres atendidas que possuem renda"
      tags={[city ?? 'CEARÁ']}
      error={error}
      loading={loading}
      empty={data.length === 0}
    >
      <DonutPopulated
        data={donutData}
        thickness={40}
        donutHeight={300}
        donutWidth={300}
        flexDirection="column"
      />
    </Card>
  );
}
