import { Box } from './styles';

import { FilterIndicatorIcon } from '@/components/shared';

interface HeaderProps {
  title: string;
  description: string;
}

export function Header({ title, description }: HeaderProps) {
  return (
    <Box>
      <div className="title">
        <FilterIndicatorIcon />
        <h3>{title}</h3>
      </div>

      <p className="description">{description}</p>
    </Box>
  );
}
