import { colors } from '@/theme/colors';
import { ReactNode } from 'react';
import { H4 } from '..';

import { Box } from './styles';

interface CardTaggedProps {
  title?: string;
  tag: string;
  children: ReactNode;
  color?: string;
}

export function CardTagged({ title, tag, children, color }: CardTaggedProps) {
  const colorSelected = color ?? colors.ciano700;
  return (
    <Box color={colorSelected}>
      {title && <H4 color={colorSelected}>{title}</H4>}

      <p className="box-tag">{tag.toUpperCase()}</p>

      {children}
    </Box>
  );
}
