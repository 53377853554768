import { useDashboardFilter } from '@/hooks';
import { healthPregnant } from '@/services';
import { Data } from 'big-data';
import { useEffect, useMemo, useState } from 'react';

export function usePregnantAgeGroupData() {
  const [data, setData] = useState<Data[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const { ibge, year, semester, zone } = useDashboardFilter();

  const empty = data.length === 0;

  useEffect(() => {
    setLoading(true);
    healthPregnant
      .getPregnantAgeGroupByHome({ ibge, year, semester, zone })
      .then((data) => {
        const sortedData = [...data].sort((a, b) => b.value - a.value);
        setData(sortedData);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [ibge, year, semester, zone]);

  return useMemo(
    () => ({
      data,
      error,
      empty,
      loading
    }),
    [data, error, empty, loading]
  );
}
