import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as R from 'ramda';

import { useAction as useActionFilter, useDashboardTitle } from '@/hooks';

import { Box } from './styles';

import {
  ActionChart,
  Filter,
  GoalsBoard,
  LastGoalsBoard,
  RegisterButton
} from '../components';
import { useActions, useActionsByStatus } from '../hooks/';

export function Indicator() {
  const [searchParams] = useSearchParams();
  const axle = searchParams.get('axe') ?? '';

  useDashboardTitle(`Plano de Ação dos Gestores em ${axle}`);

  const { indicator, ibge } = useActionFilter();

  const { actions, loadingActions, errorActions, refreshActions } =
    useActions();

  const {
    actions: actionsUndone,
    loading: loadingActionsUndone,
    error: errorActionsUndone,
    refresh: refreshActionsUndone
  } = useActionsByStatus('UNDONE');

  const {
    actions: actionsBlocked,
    loading: loadingActionsBlocked,
    error: errorActionsBlocked,
    refresh: refreshActionsBlocked
  } = useActionsByStatus('BLOCKED');

  const {
    actions: actionsInProgress,
    loading: loadingActionsInProgress,
    error: errorActionsInProgress,
    refresh: refreshActionsInProgress
  } = useActionsByStatus('IN_PROGRESS');

  const {
    actions: actionsDone,
    loading: loadingActionsDone,
    error: errorActionsDone,
    refresh: refreshActionsDone
  } = useActionsByStatus('DONE');

  useEffect(() => {
    if (R.isEmpty(axle)) return;

    refreshActions({ axle, ibge, indicator, page: 0, size: 5 });
  }, [axle, ibge, indicator, refreshActions]);

  function handleRegistered() {
    if (R.isEmpty(axle)) return;

    refreshActions({ axle, ibge, indicator, page: 0, size: 5 });
    refreshActionsUndone({ axle, indicator, city: ibge, page: 0 });
    refreshActionsBlocked({ axle, indicator, city: ibge, page: 0 });
    refreshActionsInProgress({ axle, indicator, city: ibge, page: 0 });
    refreshActionsDone({ axle, indicator, city: ibge, page: 0 });
  }

  return (
    <Box>
      <p className="explanation">
        Os planos de ação são divididos em campos básicos primordiais, como
        ação, meta, tempo, descrição e organizados em categorias de acordo com a
        sua polaridade (melhorias que dependem de aumento ou redução de níveis),
        além de observação, detalhes e possíveis impedimentos para conclusão do
        plano.
      </p>

      <div className="panels">
        <Filter />
        <RegisterButton onRegistered={handleRegistered} />
      </div>

      <div className="details">
        <ActionChart />
        <LastGoalsBoard
          actions={actions}
          loading={loadingActions}
          error={errorActions}
        />
      </div>

      <div className="board">
        <h3 className="board-title">Organização dos Planos de Ações</h3>
        <p className="board-description">
          A organização dos planos de ações é definida de acordo com o seu
          status. Planos de ações não concluídos são aqueles que passaram do
          prazo para execução e não atingiram a meta. Os planos em andamento são
          aqueles que estão em execução e poderão ser bloqueados mediante
          justificativa. Os planos concluídos são aqueles que cumpriram com suas
          metas.
        </p>

        <GoalsBoard
          actionsUndone={actionsUndone}
          loadingUndone={loadingActionsUndone}
          errorUndone={errorActionsUndone}
          actionsBlocked={actionsBlocked}
          loadingBlocked={loadingActionsBlocked}
          errorBlocked={errorActionsBlocked}
          actionsInProgress={actionsInProgress}
          loadingInProgress={loadingActionsInProgress}
          errorInProgress={errorActionsInProgress}
          actionsDone={actionsDone}
          loadingDone={loadingActionsDone}
          errorDone={errorActionsDone}
        />
      </div>
    </Box>
  );
}
