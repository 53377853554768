import { mediaQuery } from '@/theme/mediaQuery';
import styled, { css } from 'styled-components';

export const Box = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 650px) {
    align-items: center;
  }

  .pyramid {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 400px;
    min-width: 270px;
    margin-right: 150px;

    clip-path: polygon(50% 0, 0 100%, 100% 100%);

    .pyramid-slice {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .pyramid-legend {
    width: 40%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: -30%;

    @media (max-width: 650px) {
      margin-left: 0;
      width: 100%;
      height: auto;
      margin-top: 1rem;
    }
  }

  ${mediaQuery.smallScreen} {
    flex-direction: column;

    .pyramid-labels {
      width: 100%;
      margin: 20px 0;
      height: max-content;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;
    }

    .pyramid {
      margin: 0;
      min-width: initial;
      width: 250px;
      height: 250px;
    }
  }
`;

export const PyramidSliceText = styled.span<{ fontSize: string }>`
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize};
  cursor: default;

  transition: all 0.5s ease;
`;

export const PyramidTextWrapper = styled.div<{
  bg: string;
  highlight?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  transition: opacity 0.2s ease-in;
  cursor: default;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  ${({ highlight }) =>
    highlight !== undefined &&
    highlight === false &&
    css`
      opacity: 0.1;
    `}

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background: ${({ bg }) => bg};
    border-radius: 2px;
    margin-right: 10px;
  }
`;
