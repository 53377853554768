import { colors } from '@/theme/colors';
import styled from 'styled-components';

export const Box = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;

  .abstract,
  input {
    width: 100%;
    resize: none;
    border-radius: 4px;
    outline-color: ${colors.ciano700};
    border-color: ${colors.ciano700};
    padding: 8px;
    color: ${({ theme }) => (theme.darkMode ? `#fff` : `#000`)};
  }

  .abstract {
    height: 112px;
  }

  .upload-submit-container {
    display: flex;
    align-items: center;

    .upload-control {
      label.upload-label {
        background: #f4f4f5;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        position: relative;

        width: 40px;
        height: 40px;

        .uploaded-file {
          object-fit: fill;
          width: 40px;
          height: 40px;
          border-radius: inherit;
        }

        .remove-upload {
          position: absolute;
          bottom: 0;
          right: 10px;
          background: transparent;
          border: 0;
          width: max-content;
          height: max-content;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      input.upload {
        display: none;
      }
    }

    button.submit {
      height: 40px;
      margin-left: 8px;
    }
  }
`;
