import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from 'react';

interface AnalyticsFilterContextProviderProps {
  children: ReactNode;
}

interface AnalyticsFilterContextDataProps {
  city?: string;
  ibge?: string;
  profile?: string;
  profilePopulation?: string;
  year?: string;
  actionType?: string;
  colorSkinType?: string;
  gender?: string;
  movementType?: string;
  zone?: string;
  onEraseFilter: () => void;
  onChangeCity?: (city?: string) => void;
  onChangeProfile?: (profile: string) => void;
  onChangeYear?: (year: string) => void;
  onChangeColorSkinType: (colorSkin: string) => void;
  onChangeGender?: (gender: string) => void;
  onChangeProfilePopulation?: (profile: string) => void;
  onChangeActionType?: (action: string) => void;
  onChangeMovementType?: (movement: string) => void;
  onChangeZone?: (zone: string) => void;
}

export const AnalyticsFilterContext = createContext(
  {} as AnalyticsFilterContextDataProps
);

export function AnalyticsFilterProvider({
  children
}: AnalyticsFilterContextProviderProps) {
  const [city, setCity] = useState<string>();
  const [ibge, setIbge] = useState<string>();
  const [profile, setProfile] = useState<string>();
  const [profilePopulation, setProfilePopulation] = useState<string>();
  const [year, setYear] = useState<string>();
  const [actionType, setActionType] = useState<string>();
  const [colorSkinType, setColorSkinType] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [movementType, setMovementType] = useState<string>();
  const [zone, setZone] = useState<string>();

  const onEraseFilter = useCallback(() => {
    setCity(undefined);
    setIbge(undefined);
    setProfile(undefined);
    setYear(undefined);
    setActionType(undefined);
    setColorSkinType(undefined);
    setGender(undefined);
    setProfilePopulation(undefined);
    setMovementType(undefined);
    setZone(undefined);
  }, []);

  const onChangeCity = useCallback((city?: string) => {
    if (city) {
      const c = city.split('_');
      setCity(c[0]);
      setIbge(c[1]);
    } else {
      setCity(undefined);
      setIbge(undefined);
    }
  }, []);

  const onChangeZone = useCallback((_zone: string) => {
    setZone(_zone);
  }, []);

  const onChangeMovementType = useCallback((movement: string) => {
    setMovementType(movement);
  }, []);

  const onChangeProfilePopulation = useCallback((profilePeople: string) => {
    setProfilePopulation(profilePeople);
  }, []);

  const onChangeProfile = useCallback((profile: string) => {
    setProfile(profile);
  }, []);

  const onChangeActionType = useCallback((action: string) => {
    setActionType(action);
  }, []);

  const onChangeYear = useCallback((year: string) => {
    setYear(year);
  }, []);

  const onChangeColorSkinType = useCallback((skinType: string) => {
    setColorSkinType(skinType);
  }, []);

  const onChangeGender = useCallback((gender: string) => {
    setGender(gender);
  }, []);

  const providerValues = useMemo(
    () => ({
      city,
      ibge,
      profile,
      profilePopulation,
      year,
      actionType,
      colorSkinType,
      gender,
      movementType,
      zone,
      onEraseFilter,
      onChangeCity,
      onChangeProfile,
      onChangeYear,
      onChangeColorSkinType,
      onChangeGender,
      onChangeProfilePopulation,
      onChangeActionType,
      onChangeMovementType,
      onChangeZone
    }),
    [
      city,
      ibge,
      profile,
      profilePopulation,
      year,
      actionType,
      colorSkinType,
      gender,
      movementType,
      zone,
      onEraseFilter,
      onChangeCity,
      onChangeProfile,
      onChangeYear,
      onChangeColorSkinType,
      onChangeGender,
      onChangeProfilePopulation,
      onChangeActionType,
      onChangeMovementType,
      onChangeZone
    ]
  );

  return (
    <AnalyticsFilterContext.Provider value={providerValues}>
      {children}
    </AnalyticsFilterContext.Provider>
  );
}

export function useAnalyticsFilter() {
  return useContext(AnalyticsFilterContext);
}
