import { CityTaxes } from '@/services/googleAnaltyics';
import dayjs, { Dayjs } from 'dayjs';

function formatter(date: Dayjs) {
  return date.format('YYYY-MM-DD');
}

export function timeInterval(
  interval: 'yesterday' | 'week' | 'month' | 'semester'
) {
  const date = dayjs();

  switch (interval) {
    case 'yesterday': {
      const yesterday = date.subtract(1, 'day');
      return formatter(yesterday);
    }
    case 'week': {
      const weekAgo = date.subtract(1, 'w');
      return formatter(weekAgo);
    }
    case 'month': {
      const monthAgo = date.subtract(1, 'month');
      return formatter(monthAgo);
    }
    case 'semester': {
      const semesterAgo = date.subtract(6, 'months');
      return formatter(semesterAgo);
    }
  }
}

function transformMinutesIntoSeconds(minutes: number) {
  return minutes * 60;
}

function toTimeFormat(time: number) {
  return String(Math.floor(time)).padStart(2, '0');
}

export function generateTime(
  data: Partial<CityTaxes>[],
  timer: keyof Pick<CityTaxes, 'meanTimePerPage' | 'permanencyTime'>
) {
  const meanTimeInSeconds = transformMinutesIntoSeconds(
    data[0]?.permanencyTime || data[0]?.meanTimePerPage || 0
  );

  const hours = toTimeFormat(meanTimeInSeconds / 360);
  const minutes = toTimeFormat(meanTimeInSeconds / 60);
  const seconds = toTimeFormat(meanTimeInSeconds % 60);

  return { hours, minutes, seconds };
}

export function generateTag(
  tags: [city: string | undefined, date: string | undefined],
  initialBar?: boolean
) {
  const tagArray = ['Ceará', 'Hoje'];

  return tags
    .map((tag, i) => {
      if (!tag) return tagArray[i];
      return tag;
    })
    .map((tag, i) => {
      if (i === 0 && !initialBar) return tag;
      return `\\ ${tag}`;
    })
    .join(' ');
}
