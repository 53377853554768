import { Section } from '@/layout';
import { Card } from '@/components/shared';
import { RankingDashboard } from '@/components/shared/RankingDashboard';
import { useAnalyticsFilter } from '@/hooks/useAnalyticsFilter';
import { colors } from '@/theme/colors';
import { getDashboardRanking } from '@/utils/data';
import { Data, RankingModel } from 'big-data';
import associacaoIcon from '@/assets/icons/socialMovements/associacao.svg';
import coletivoIcon from '@/assets/icons/socialMovements/coletivo.svg';
import forumIcon from '@/assets/icons/socialMovements/forum.svg';
import redeIcon from '@/assets/icons/socialMovements/rede.svg';
import sindicatoIcon from '@/assets/icons/socialMovements/sindicato.svg';
import outrosTiposIcon from '@/assets/icons/socialMovements/outros.svg';
import institutoIcon from '@/assets/icons/socialMovements/instituto.svg';
import ongIcon from '@/assets/icons/socialMovements/ong.svg';
import { useService } from '@/hooks';
import { socialMovements } from '@/services/socialMovements';
import { useEffect } from 'react';

const DESCRIPTION = 'dos movimentos sociais são classificados como ';

const rankingModel = new Map<string, RankingModel>([
  [
    'associação',
    {
      description: () => DESCRIPTION,
      img: <img src={associacaoIcon} alt="Icon de associação" />,
      name: 'Associação',
      key: 'associacao'
    }
  ],
  [
    'instituto',
    {
      description: () => DESCRIPTION,
      img: <img src={institutoIcon} alt="Icon de instituo" />,
      name: 'Instituto',
      key: 'instituto',
      nested: false
    }
  ],
  [
    'osc/ong/oscip',
    {
      description: () => DESCRIPTION,
      img: <img src={ongIcon} alt="Icon de ong" />,
      name: 'OSC/ONG/OSCIP',
      key: 'ongs',
      nested: false
    }
  ],
  [
    'coletivo',
    {
      description: () => DESCRIPTION,
      img: <img src={coletivoIcon} alt="Icon de coletivo" />,
      name: 'Coletivo',
      key: 'coletivo'
    }
  ],
  [
    'fórum',
    {
      description: () => DESCRIPTION,
      img: <img src={forumIcon} alt="Icon de forum" />,
      name: 'Fórum',
      key: 'fórum'
    }
  ],
  [
    'rede',
    {
      description: () => DESCRIPTION,
      img: <img src={redeIcon} alt="Icon de rede" />,
      name: 'Rede',
      key: 'rede'
    }
  ],
  [
    'sindicato',
    {
      description: () => DESCRIPTION,
      img: <img src={sindicatoIcon} alt="Icon de sindicato" />,
      name: 'Sindicato',
      key: 'sindicato'
    }
  ],
  [
    'outros tipos',
    {
      description: () => DESCRIPTION,
      img: <img src={outrosTiposIcon} alt="Icon de outros tipos" />,
      name: 'Outros tipos',
      key: 'outros_tipos'
    }
  ]
]);

export function MovementsTypesRanking() {
  const { city } = useAnalyticsFilter();

  const { data, loading, error, request } = useService<Data>();

  useEffect(() => {
    request(() => socialMovements.getTypeMovements());
  }, [request]);

  const rankingData = getDashboardRanking(data, rankingModel);

  return (
    <Section>
      <Card
        title="Movimentos sociais organizados por tipos"
        loading={loading}
        error={error}
        empty={data.length === 0}
        tags={[city ?? 'Ceará']}
      >
        <RankingDashboard
          color={colors.ciano700}
          data={rankingData}
          loadingBenefits={loading}
          selected={undefined}
          calculatedOut
        />
      </Card>
    </Section>
  );
}
