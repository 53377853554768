import { colors } from '@/theme/colors';
import styled, { css, keyframes } from 'styled-components';

interface AcomplishedProps {
  percent: number;
}

const grow = (width: number) => keyframes`
  0% {
    width: 0;
  }

  100% {
    width: ${width}%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding: 10px;

  // popover antd styles
  .ant-popover-content {
    width: max-content;

    .ant-popover-inner {
      background: ${({ theme }) => (theme.darkMode ? `#333` : `#fff`)};
    }
    strong.bar-name {
      color: ${({ theme }) => (theme.darkMode ? colors.white : theme.text)};
    }

    .ant-popover-arrow {
      span::before {
        background: ${(props) => (props.theme.darkMode ? `#333` : `#FFF`)};
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  background-color: #969CA4;
  position: relative;

  margin-top: 3.5rem;

  &:before {
    content: '';
    height: 120%;
    width: 1.2px;
    background-color: #979797;
    position: absolute;
    top: 0;
  }

  &:after {
    content: '';
    height: 120%;
    width: 1px;
    background-color: #979797;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const ViewBox = styled.div<AcomplishedProps>`
  display: flex;
  width: ${({ percent }) => `${percent}%`};
  background-color: ${({ color }) => color ?? '#1D696F'};

  cursor: pointer;

  position: relative;

  ${({ percent }) => css`
    animation: ${grow(percent)} 1s ease-in-out;
  `};
`;

export const Percentage = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:before {
    content: '0%';
    color: ${(props) => props.theme.text};
  }

  &:after {
    content: '100%';
    color: ${(props) => props.theme.text};
  }
`;
